import React from 'react';
import { useSelector } from "react-redux";
import {
    Modal,
  } from "reactstrap"

const PreviewInvoice = props => {

  const dollarUSLocale = Intl.NumberFormat('en-US');
  
  const { admInfo } = useSelector(state => ({
    admInfo: state.AdmInvoiceManagement.admInfo,
  }));

  return (
    <React.Fragment>
          {props.isOpenPreview ? (
            <Modal
              size="lg"
              isOpen={true}
              toggle={() => {
                props.setIsOpenPreview(false);
              }}
            >
              <div className="modal-header">
                <h5
                  className="modal-title mt-0"
                  id="myExtraLargeModalLabel"
                >
                </h5>
                <button
                  onClick={() => {
                    props.setIsOpenPreview(false);
                  }}
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <table border="0" cellpadding="0" cellspacing="0" align="center" width="620px" bgcolor="#FFFFFF">
                  <tbody>
                    <tr>
                      <td colspan="1" style={{fontSize:"0px", lineHeight:"0", padding:"0px 10px", verticalAlign:"top"}}>
                        <img src="https://app.wecrest.com/resources/images/registration-logo.png" width="150px" alt="logo"/>
                      </td>
                      <td colspan="2" style={{fontSize:"0px", padding:"0px 10px", textAlign: "right"}}>
                        <p style={{fontSize:"13px"}}><br/>WeCrest Ltd<br/>{admInfo.addressLine1}<br/>{admInfo.addressLine2}<br/>{admInfo.addressLine3}<br/><br/>Registration number: 462-86-00861<br/><br/><a href="mailto:accounts@wecrest.com" style={{color:"blue", textDecoration:"underline"}}>accounts@wecrest.com</a></p>
                      </td>
                    </tr>
                    <tr>
                        <td colspan="3" style={{fontSize:"45px"}}>&nbsp;</td>
                    </tr>
                    <tr>
                        <td colspan="3" align="center">
                            <p style={{fontSize:"20px", color:"#000000", fontWeight:"bold", textAlign:"center"}}>INVOICE</p>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="3" style={{fontSize:"30px"}}>&nbsp;</td>
                    </tr>
                    <tr>
                        <td colspan="3">
                            <p style={{fontSize:"13px", padding:"0px 10px", color:"#000000", fontWeight:"bold", marginBottom: "0px"}}>Bill to</p>
                            {
                              (props.invoiceInfo.invoiceName && props.invoiceInfo.invoiceName !== '')
                              || (props.invoiceInfo.invoiceCompany && props.invoiceInfo.invoiceCompany !== '')
                              || (props.invoiceInfo.invoiceAddressHtml && props.invoiceInfo.invoiceAddressHtml !== '')
                              || (props.invoiceInfo.invoiceTaxId && props.invoiceInfo.invoiceTaxId !== '')
                              || (props.invoiceInfo.invoiceEmail && props.invoiceInfo.invoiceEmail !== '') ?
                              <table border="0" cellpadding="0" cellspacing="3" align="center" width="620px" bgcolor="#FFFFFF">
                              {
                                props.invoiceInfo.invoiceName && props.invoiceInfo.invoiceName !== '' ?
                                <tr><td width="14%"><p style={{fontSize:"13px", padding:"0px 10px", color:"#000000", marginBottom: "0px"}}>Name: </p></td><td><p style={{fontSize:"13px", color:"#000000", marginBottom: "0px"}}>{props.invoiceInfo.invoiceName}</p></td></tr>
                                : null
                              }
                              {
                                props.invoiceInfo.invoiceCompany && props.invoiceInfo.invoiceCompany !== '' ?
                                <tr><td width="14%"><p style={{fontSize:"13px", padding:"0px 10px", color:"#000000", marginBottom: "0px"}}>Company: </p></td><td><p style={{fontSize:"13px", color:"#000000", marginBottom: "0px"}}>{props.invoiceInfo.invoiceCompany}</p></td></tr>
                                : null
                              }
                              {
                                props.invoiceInfo.invoiceAddress && props.invoiceInfo.invoiceAddress !== '' ?
                                <tr><td width="14%"><p style={{fontSize:"13px", padding:"0px 10px", color:"#000000", marginBottom: "0px"}}>Address: </p></td><td><p style={{fontSize:"13px", color:"#000000", marginBottom: "0px"}} dangerouslySetInnerHTML={{__html:props.invoiceInfo.invoiceAddress.replace(/\n/g, "<br/>")}}></p></td></tr>
                                : null
                              }
                              {
                                props.invoiceInfo.invoiceTaxId && props.invoiceInfo.invoiceTaxId !== '' ?
                                <tr><td width="14%"><p style={{fontSize:"13px", padding:"0px 10px", color:"#000000", marginBottom: "0px"}}>Tax ID: </p></td><td><p style={{fontSize:"13px", color:"#000000", marginBottom: "0px"}}>{props.invoiceInfo.invoiceTaxId}</p></td></tr>
                                : null
                              }
                              {
                                props.invoiceInfo.invoiceEmail && props.invoiceInfo.invoiceEmail !== '' ?
                                <tr><td width="14%"><p style={{fontSize:"13px", padding:"0px 10px", color:"#000000", marginBottom: "0px"}}>Email: </p></td><td><p style={{fontSize:"13px", color:"#000000", marginBottom: "0px"}}>{props.invoiceInfo.invoiceEmail}</p></td></tr>
                                : null
                              }
                              </table>
                              : null
                            }
                        </td>
                    </tr>
                    <tr>
                        <td colspan="3" style={{fontSize:"20px"}}>&nbsp;</td>
                    </tr>
                    <tr>
                        <td colspan="3">
                            <p style={{fontSize:"13px", padding:"0px 10px", color:"#000000", fontWeight:"bold", marginBottom:"0px"}}>Details</p>
                            {
                              (props.invoiceInfo.invoiceNo && props.invoiceInfo.invoiceNo !== '')
                              || (props.invoiceInfo.invoiceDate && props.invoiceInfo.invoiceDate !== '') ?
                              <table border="0" cellpadding="0" cellspacing="3" align="center" width="620px" bgcolor="#FFFFFF">
                                {
                                props.invoiceInfo.invoiceNo && props.invoiceInfo.invoiceNo !== '' ?
                                <tr><td width="20%"><p style={{fontSize:"13px", padding:"0px 10px", color:"#000000", marginBottom: "0px"}}>Invoice number: </p></td><td><p style={{fontSize:"13px", color:"#000000", marginBottom: "0px"}}>{props.invoiceInfo.invoiceNo}</p></td></tr>
                                : null
                                }
                                {
                                props.invoiceInfo.invoiceDate && props.invoiceInfo.invoiceDate !== '' ?
                                <tr><td width="20%"><p style={{fontSize:"13px", padding:"0px 10px", color:"#000000", marginBottom: "0px"}}>Invoice date: </p></td><td><p style={{fontSize:"13px", color:"#000000", marginBottom: "0px"}}>{props.invoiceInfo.invoiceDate.replace(/-/gi, "/")}</p></td></tr>
                                : null
                                }
                              </table>
                              : null
                            }
                        </td>
                    </tr>
                    {
                    props.invoiceInfo.invoiceInfo && props.invoiceInfo.invoiceInfo !== '' ?
                    <>
                    <tr>
                        <td colspan="3" style={{fontSize:"20px"}}>&nbsp;</td>
                    </tr>
                    <tr>
                        <td colspan='3'>
                        <p style={{fontSize:"13px", padding:"0px 10px", color:"#000000", fontWeight:"bold", marginBottom:"0px"}}>Important information</p>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="3">
                        <p style={{fontSize:"13px", padding:"0px 10px", color:"#000000", whiteSpace: "pre-wrap"}}>
                        {props.invoiceInfo.invoiceInfo}
                        </p>
                        </td>
                    </tr>
                    </>
                    :null
                    }
                    <tr>
                        <td colspan="3" style={{fontSize:"30px"}}>&nbsp;</td>
                    </tr>
                    <tr>
                        <td colspan="3">
                            <p style={{fontSize:"13px", padding:"0px 7px", color:"#000000", fontWeight:"bold"}}>Billing summary</p>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="3" style={{fontSize:"20px"}}>&nbsp;</td>
                    </tr>
                    <tr>
                        <td colspan="2" width="*" style={{fontSize:"13px", color:"#909090", fontWeight:"bold", textAlign:"left", paddingLeft:"10px"}}>Item</td>
                        <td colspan="1" width="30%" style={{fontSize:"13px", color:"#909090", fontWeight:"bold", textAlign:"right", paddingRight:"10px"}}><b>WeCrest fees ({props.invoiceInfo.currency })</b></td>
                    </tr>
                    <tr>
                        <td colspan="3" style={{fontSize:"1px", height:"5px"}}>&nbsp;</td>
                    </tr>
                    <tr>
                        <td colspan="3" style={{fontSize:"1px"}}><hr style={{color:"#909090", height:"1px"}}/></td>
                    </tr>
                    {
                    props.itemList ?
                    props.itemList.map((item, index) => {
                      if (item.crCode && item.crCode !== '') {
                        return (
                          <>
                          <tr><td colspan="3" style={{fontSize:"3px"}}>&nbsp;</td></tr>
                          <tr>
                            <td colspan="2" style={{fontSize:"13px", color:"#000000", paddingLeft:"10px", whiteSpace: "pre-wrap"}}>
                              {("JN" === item.subscriptionType ? 'WeCrest Madrid subscription ' + item.crCode + ' ' + item.crName 
                                : "HN" === item.subscriptionType ? 'WeCrest Hague subscription ' + item.crCode + ' ' + item.crName 
                                : "UC" === item.subscriptionType ? 'Unlock credits ' + item.crCode  + ' credits' 
                                : 'WeCrest Executive ' + item.crCode) 
                              + " " + item.startDate.replace(/-/gi, "/") 
                              + " - " + item.endDate.replace(/-/gi, "/")}
                            </td>
                            <td colspan="1" style={{fontSize:"13px", color:"#000000", textAlign:"right", paddingRight:"10px"}}>
                              {dollarUSLocale.format(item.cost)}
                            </td>
                          </tr>
                          {
                            item.dcAmt && item.dcAmt !== '' && item.dcAmt !== '0' ?
                            <tr>
                              <td colspan="2" style={{fontSize:"13px", color:"#32a852", paddingLeft:"10px"}}>
                                Discount: {item.dcDesc } {item.dcRate }%
                              </td>
                              <td colspan="1" style={{fontSize:"13px", color:"#32a852", textAlign:"right", paddingRight:"10px"}}>
                                -{dollarUSLocale.format(item.dcAmt)}
                              </td>
                            </tr>   
                            : null
                          }
                          {
                            item.addDcAmt && item.addDcAmt !== '' && item.addDcAmt !== '0' ?
                            <tr>
                              <td colspan="2" style={{fontSize:"13px", color:"#32a852", paddingLeft:"10px"}}>
                              Additional discount for {item.crCode }: {item.addDcDesc } {item.addDcRate }%
                              </td>
                              <td colspan="1" style={{fontSize:"13px", color:"#32a852", textAlign:"right", paddingRight:"10px"}}>
                                -{dollarUSLocale.format(item.addDcAmt)}
                              </td>
                            </tr>
                            : null
                          }
                          </>
                        )
                      }
                    })
                    : null
                    }
                    
                    {
                    props.invoiceInfo.finalDcAmt && props.invoiceInfo.finalDcAmt !== '' && props.invoiceInfo.finalDcAmt !== '0' ?
                    <>
                    <tr><br/><td colspan="3" style={{fontSize:"3px"}}>&nbsp;</td></tr>
                    <tr><br/><td colspan="3" style={{fontSize:"3px"}}>&nbsp;</td></tr>
                    <tr><td colspan="2" style={{fontSize:"13px", color:"#32a852", paddingLeft:"10px"}}>
                            Additional total discount : {props.invoiceInfo.finalDcDesc} {dollarUSLocale.format(props.invoiceInfo.finalDcRate)}%
                        </td>
                        <td colspan="1" style={{fontSize:"13px", color:"#32a852", textAlign:"right", paddingRight:"10px"}}>
                          -{dollarUSLocale.format(props.invoiceInfo.finalDcAmt)}
                        </td>
                    </tr>      
                    </>
                    : null
                    }

                    {
                    props.invoiceInfo.addFee && props.invoiceInfo.addFee !== '' && props.invoiceInfo.addFee !== '0' ?
                    <>
                    <tr><br/><td colspan="3" style={{fontSize:"3px"}}>&nbsp;</td></tr>
                    <tr><br/><td colspan="3" style={{fontSize:"3px"}}>&nbsp;</td></tr>
                    <tr><td colspan="2" style={{fontSize:"13px", color:"#000000", paddingLeft:"10px"}}>
                          {props.invoiceInfo.addFeeDesc}
                        </td>
                        <td colspan="1" style={{fontSize:"13px", color:"#000000", textAlign:"right", paddingRight:"10px"}}>
                          {dollarUSLocale.format(props.invoiceInfo.addFee)}
                        </td>
                    </tr>    
                    </>
                    : null
                    }                 
                    
                    <tr><td colspan="3" style={{fontSize:"5px"}}>&nbsp;</td></tr>
                    <tr><td colspan="3" style={{fontSize:"5px"}}>&nbsp;</td></tr>
                    <tr>
                        <td colspan="2" style={{fontSize:"13px", color:"#ff0000", fontWeight:"bold", textAlign:"left", paddingLeft:"10px"}}>Total</td>
                        <td colspan="1" style={{fontSize:"13px", color:"#ff0000", fontWeight:"bold", textAlign:"right", paddingRight:"10px"}}>{dollarUSLocale.format(props.invoiceInfo.total)}</td>     
                    </tr>
                    <tr>
                        <td colspan="3" style={{fontSize:"40px"}}>&nbsp;</td>
                    </tr>
                  </tbody>
                </table>
                {
                props.invoiceInfo.paymentMethod && props.invoiceInfo.paymentMethod === 'BankTransfer'?
                <table border="0" cellpadding="0" cellspacing="1" align="center" width="570px" bgcolor="#FFFFFF">
                  <tbody>
                    <tr>
                        <td colspan="2" style={{fontSize:"13px", color:"#ff0000", fontWeight:"bold", textAlign:"center"}}>Payment deadline: {props.invoiceInfo.deadline}</td>
                    </tr>
                    
                    <tr>
                        <td colspan="2" style={{fontSize:"20px"}}>&nbsp;</td>
                    </tr>
                    <tr>
                        <td colspan="2" style={{fontSize:"10px", color:"#000000", fontWeight:"bold", textAlign:"left", paddingLeft:"10px"}}>Please pay this invoice by bank transfer to the following account:<br/></td>
                    </tr>
                    <tr>
                        <td colspan="2" style={{fontSize:"1px"}}><hr style={{color:"#909090", width:"97%", height:"1px"}}/></td>
                    </tr>
                    <tr>
                        <td width="20%" style={{fontSize:"10px", color:"#000000", fontWeight:"bold", textAlign:"left", paddingLeft:"10px"}}>Account number:</td>
                        <td width="*" style={{fontSize:"10px", color:"#909090", textAlign:"left", paddingLeft:"10px"}}>180-008-064834</td>
                    </tr>
                    <tr>
                        <td width="20%" style={{fontSize:"10px", color:"#000000", fontWeight:"bold", textAlign:"left", paddingLeft:"10px"}}>Beneficiary name:</td>
                        <td width="*" style={{fontSize:"10px", color:"#909090", textAlign:"left", paddingLeft:"10px"}}>WeCrest Ltd</td>
                    </tr>
                    <tr>
                        <td width="20%" style={{fontSize:"10px", color:"#000000", fontWeight:"bold", textAlign:"left", paddingLeft:"10px"}}>Beneficiary address:</td>
                        <td width="*" style={{fontSize:"10px", color:"#909090", textAlign:"left", paddingLeft:"10px"}}>${admInfo.addressLine1}, ${admInfo.addressLine2}, ${admInfo.addressLine3}</td>
                    </tr>
                    <tr>
                        <td width="20%" style={{fontSize:"10px", color:"#000000", fontWeight:"bold", textAlign:"left", paddingLeft:"10px"}}>Beneficiary phone:</td>
                        <td width="*" style={{fontSize:"10px", color:"#909090", textAlign:"left", paddingLeft:"10px"}}>+82 10 3224 8560</td>
                    </tr>
                    <tr>
                        <td width="20%" style={{fontSize:"10px", color:"#000000", fontWeight:"bold", textAlign:"left", paddingLeft:"10px"}}>Bank name:</td>
                        <td width="*" style={{fontSize:"10px", color:"#909090", textAlign:"left", paddingLeft:"10px"}}>Shinhan Bank</td>
                    </tr>
                    <tr>
                        <td width="20%" style={{fontSize:"10px", color:"#000000", fontWeight:"bold", textAlign:"left", paddingLeft:"10px"}}>Bank branch:</td>
                        <td width="*" style={{fontSize:"10px", color:"#909090", textAlign:"left", paddingLeft:"10px"}}>Nonhyeon-dong Corporate Business Banking Center</td>
                    </tr>
                    <tr>
                        <td width="20%" style={{fontSize:"10px", color:"#000000", fontWeight:"bold", textAlign:"left", paddingLeft:"10px"}}>Bank address:</td>
                        <td width="*" style={{fontSize:"10px", color:"#909090", textAlign:"left", paddingLeft:"10px"}}>468, Gangnam-daero, Gangnam-gu, Seoul, Korea (phone: +82 2 3452 7900)</td>
                    </tr>
                    <tr>
                        <td width="20%" style={{fontSize:"10px", color:"#000000", fontWeight:"bold", textAlign:"left", paddingLeft:"10px"}}>SWIFT code:</td>
                        <td width="*" style={{fontSize:"10px", color:"#909090", textAlign:"left", paddingLeft:"10px"}}>SHBKKRSE</td>
                    </tr>
                  </tbody>
                </table>
                : null
                }
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  onClick={() => {
                    props.setIsOpenPreview(false);
                  }}
                  className="btn btn-secondary waves-effect"
                  data-dismiss="modal"
                >
                  Close
                </button>
              </div>
            </Modal>
          ) : null}
    </React.Fragment>
  )
}

export default PreviewInvoice;