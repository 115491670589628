import React, {useState, useEffect} from 'react';
import { useSelector, useDispatch } from "react-redux";

import {
    Row,
    Tooltip,
    Col,
    Label,
    Modal,
} from "reactstrap"

import {
    getAllJurisdictionsList as onGetAllJurisdictionsList,
    changeJurisdictionsList as onChangeJurisdictionsList,
} from "../../store/actions";
import { cloneDeep } from 'lodash';
  
const SelectAllJurisdictions = props => {
    const dispatch = useDispatch();

    const { jurisdictionList } = useSelector(state => ({
      jurisdictionList: state.GeneralStatistics.jurisdictionList,
    }));

    const [tright, settright] = useState(false);
  
    const [modal_standard, setmodal_standard] = useState(false);
    const [chkAllFlag, setChkAllFlag] = useState(false);
    const [nonEUFlag, setNonEUFlag] = useState(false);
    /**
     * selectedJurList[1] : {'jurType' : str}
     * selectedJurList[2] : {'jurList' : [{countryVO},{countryVO},{}...]}
     */
    const [selectedJurList, setSelectedJurList] = useState([]);
    let chkAll = "All";
    let allSubs = "All-subs";
    let nonEU = "Non-eu";
    let individual = "Individual";

    function removeBodyCss() {
        document.body.classList.add("no_padding")
    }
    
    const openCountryModal = () => {
        let _jurisdictionList = cloneDeep(jurisdictionList);

        if(selectedJurList.length !== 0){
            let chkCnt = 0;
            let nonEuCnt = 0;
            let chkNonEuCnt = 0;

            setChkAllFlag(false);
            setNonEUFlag(false);

            if(selectedJurList.length == 2){
                _jurisdictionList = _jurisdictionList.map((jur) => {
                    let checkedFlag = false;
                    selectedJurList[1].jurList.map((selectedJur, index) =>{
                    if(jur.crCode == selectedJur.crCode){
                        checkedFlag = true;
                        chkCnt++;
                        if(jur.euYn == 'N'){
                            chkNonEuCnt++;
                        }
                    }
                    });

                    jur.checked = checkedFlag;

                    if(jur.euYn == 'N'){
                        nonEuCnt++; 
                    }
                    return jur;
                });

                if(nonEuCnt == chkNonEuCnt && chkNonEuCnt == chkCnt){
                    setNonEUFlag(true);
                }else{
                    setNonEUFlag(false);
                }
                
                if(jurisdictionList.length == chkCnt){
                    setChkAllFlag(true);
                }else{
                    setChkAllFlag(false);
                }

                dispatch(onChangeJurisdictionsList(_jurisdictionList));
            }
        }

        setmodal_standard(!modal_standard);
        removeBodyCss();
    }

    const search = (keyword) => {
        keyword = keyword.toLowerCase();

        const _jurisdictionList = [];
        jurisdictionList.map((jur, index) =>{
            let _jur = cloneDeep(jur);

            if(jur.crCode.toLowerCase().includes(keyword) == true || jur.crName.toLowerCase().includes(keyword) == true){
            _jur.show = true;
            }else{
            _jur.show = false;
            }

            _jurisdictionList.push(_jur);
        }); 

        dispatch(onChangeJurisdictionsList(_jurisdictionList));
    }

    const jurCheck = (str, checked) => {
        let chkCnt = 0;
        let nonEuCnt = 0;
        let chkNonEuCnt = 0;

        const _jurisdictionList = [];
        jurisdictionList.map((jur, index) =>{
            let _jur = cloneDeep(jur);

            if((str == chkAll 
                || (str == nonEU && checked == false))
                && jur.show){
                _jur.checked = checked;
            }else if(str == nonEU && checked == true && jur.show){
                if(jur.euYn == 'N'){
                    _jur.checked = true;
                }else{
                    _jur.checked = false;
                }
            }else{
                if(jur.crCode == str){
                    _jur.checked = checked;
                }
            }

            if(_jur.checked == true){
                chkCnt++;
                if(jur.euYn == 'N'){
                    chkNonEuCnt++;
                }
            }

            if(jur.euYn == 'N'){
                nonEuCnt++;
            }

            _jurisdictionList.push(_jur);
        }); 

        if(nonEuCnt == chkNonEuCnt && chkNonEuCnt == chkCnt){
            setNonEUFlag(true);
        }else{
            setNonEUFlag(false);
        }
        
        if(jurisdictionList.length == chkCnt){
            setChkAllFlag(true);
        }else{
            setChkAllFlag(false);
        }

        dispatch(onChangeJurisdictionsList(_jurisdictionList));
    }

    const jurConfirm = () => {
        let _jrCdList = [];

        jurisdictionList.map((jur, index) =>{
            if(jur.checked == true){
                _jrCdList.push(jur.crCode);
            }
        });

        let _values = cloneDeep(props.formik.values);
        _values.jrCdList = _jrCdList;
        props.formik.setValues(_values);

        openCountryModal();

    }

    const htmlSetting = (jrCdList) => {
        if(jrCdList && jrCdList.length && !(jrCdList.length === 1 && jrCdList[0] === "")){
            let _selectedJurList = [];
            let _tempList = [];
            let jurType = "";
            let nonEuCnt = 0;
            let chkNonEuCnt = 0;
            
            jurisdictionList.map((jur, index) =>{
                if(jrCdList.includes(jur.crCode)){
                    _tempList.push(jur);
                    if(jur.euYn == 'N'){
                        chkNonEuCnt++;
                    }
                }
                
                if(jur.euYn == 'N'){
                    nonEuCnt++;
                }
            });
            
            //console.log("htmlSetting jrCdList.length : "+ jrCdList.length + "//// jurisdictionList.length : " + jurisdictionList.length + "//// props.formik.values.inOutType : "+ props.formik.values.inOutType);
            if(jrCdList.length === jurisdictionList.length){
                if(props.formik.values.inOutType && "IN" === props.formik.values.inOutType){
                    jurType = allSubs;
                }else{
                    jurType = chkAll;
                }
            }else if(nonEuCnt === chkNonEuCnt && chkNonEuCnt === jrCdList.length){
                jurType = nonEU;
            }else {
                jurType = individual;
            }
            
            _selectedJurList.push({'jurType' : jurType});
            _selectedJurList.push({'jurList' : _tempList});

            //saveSetting 기능에 필요
            if(props.formik.values.jurType != undefined){
                let _values = cloneDeep(props.formik.values);
                _values.jurType = jurType;
                props.formik.setValues(_values);
            }

            setSelectedJurList(_selectedJurList);
            
        }else{
            setSelectedJurList([]);

        }
    }

    useEffect(()=> {
        //inOutType이 undefined여도 호출됨
        dispatch(onGetAllJurisdictionsList({inOutType : props.formik.values.inOutType}));
    },[props.formik.values.inOutType]);
    
    useEffect(()=> {
        if(jurisdictionList.length > 0){
            htmlSetting(props.formik.values.jrCdList);
        }
    },[JSON.stringify(props.formik.values.jrCdList)]);

    useEffect(()=> {
        //search settings 불러올때 inOutType하고 jrCdList가 동시에 변경됨
        //onGetAllJurisdictionsList()이 완료되기 전에 htmlSetting()이 호출되면서 html이 잘못 나오는 이슈 보완
        if(jurisdictionList.length > 0){
            htmlSetting(props.formik.values.jrCdList);
        }
    },[jurisdictionList && jurisdictionList.length]);

    return (
        <>
        <Row className="mb-3">
            <Col md={props.firstColMd ? props.firstColMd : 3}>
                <label
                    className="col-form-label"
                >
                    Jurisdictions
                </label>
            </Col>
            <Col md={3}>
                <label
                    className="col-form-label"
                >
                {
                    (()=> {
                        if (selectedJurList && selectedJurList.length != 0) {
                            if(selectedJurList[0].jurType === chkAll){
                                return 'All (default)'
                            } else if(selectedJurList[0].jurType === allSubs){
                                return 'All subscribed (default)'
                            } else if(selectedJurList[0].jurType === nonEU){
                                return 'Non-EU'
                            } else {
                                return selectedJurList[1].jurList.map((selectedJur, index) => {
                                    return (<>{selectedJur.crCode} {selectedJur.crName} <br/></>)
                                });
                            }
                        }else{
                            if(props.formik.values.inOutType && "IN" === props.formik.values.inOutType){
                                return 'All subscribed (default)'
                            }else{
                                return 'All (default)'
                            }
                        }
                    })()
                }
                </label>
            </Col>
            <Col md={props.secondColMd ? props.secondColMd : 3}>
                <Tooltip
                    placement="right"
                    isOpen={tright}
                    target="selectJurisdictions"
                    toggle={() => {
                        settright(!tright)
                    }}
                >
                    Jurisdictions where you<br/> practice
                </Tooltip>
                <button
                    type="button"
                    className="btn btn-primary waves-effect waves-light"
                    id="selectJurisdictions"
                    onClick={() => {
                        openCountryModal();
                    }}
                    data-toggle="modal"
                    data-target="#myModal"
                >
                    Select jurisdictions
                </button>
            </Col>
        </Row>

        <Modal
            isOpen={modal_standard}
            toggle={() => {
            openCountryModal()
            }}
            scrollable={true}
        >
            <div className="modal-header" style={{marginRight: "20px"}}>
                <h5 className="modal-title mt-0" id="myModalLabel">
                    Select jurisdictions
                </h5>
                <div className="app-search" style={{padding : '0'}}>
                    <div className="position-relative">
                    <input
                        type="text"
                        className="form-control"
                        style={{border: "solid 1px"}}
                        placeholder="Search..."
                        onKeyPress={(e) => {
                        if(e.key === 'Enter'){
                            search(e.target.value);
                        }                      
                        }}
                    />
                    <span className="uil-search"></span>
                    </div>
                </div>
                <button
                    type="button"
                    onClick={() => {
                        openCountryModal(false)
                    }}
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                >
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div className="modal-body">
                <Row style={{margin : 'auto'}}>
                    <div className="col-md-3 form-check mb-2">
                        <input
                        type="checkbox"
                        className="form-check-input"
                        id={chkAll}
                        checked={chkAllFlag}
                        onClick={(e)=>jurCheck(chkAll, e.target.checked)}
                        />
                        <Label
                        className="form-check-label"
                        htmlFor={chkAll}
                        >
                        Select all
                        </Label>
                    </div>
                    <div className="col-md-3 form-check mb-2">
                        <input
                        type="checkbox"
                        className="form-check-input"
                        id={nonEU}
                        checked={nonEUFlag}
                        onClick={(e)=>jurCheck(nonEU, e.target.checked)}
                        />
                        <Label
                        className="form-check-label"
                        htmlFor={nonEU}
                        >
                        Non-EU
                        </Label>
                    </div>
                </Row>
                {
                    jurisdictionList && jurisdictionList.length ?
                    jurisdictionList.map((jur, index) => {
                        return (
                            jur.show? 
                            <div className="form-check mb-2">
                                <input
                                type="checkbox"
                                className="form-check-input"
                                id={jur.crCode}
                                checked={jur.checked}
                                onClick={(e)=>jurCheck(jur.crCode, e.target.checked)}
                                />
                                <Label
                                className="form-check-label"
                                htmlFor={jur.crCode}
                                >
                                {jur.crCode} {jur.crName}
                                </Label>
                            </div>
                            
                            : null
                        )
                    })
                    :
                    null
                }
            
            </div>
            <div className="modal-footer">
                <button
                    type="button"
                    onClick={() => {
                        openCountryModal()
                    }}
                    className="btn btn-secondary waves-effect"
                    data-dismiss="modal"
                >
                    Cancel
                </button>
                <button
                    type="button"
                    className="btn btn-primary waves-effect waves-light"
                    onClick={()=> jurConfirm()}
                >
                    Confirm
                </button>
            </div>
        </Modal>
        </>
    )
};

export default SelectAllJurisdictions;