export const GET_SETTINGS_INFO = "GET_SETTINGS_INFO"
export const GET_SETTINGS_INFO_SUCCESS = "GET_SETTINGS_INFO_SUCCESS"
export const UPDATE_ACCOUNT_INFO = "UPDATE_ACCOUNT_INFO"
export const UPDATE_ACCOUNT_INFO_SUCCESS = "UPDATE_ACCOUNT_INFO_SUCCESS"
export const UPDATE_ACCOUNT_PASSWORD = "UPDATE_ACCOUNT_PASSWORD"
export const UPDATE_ACCOUNT_INVOICE_INFO = "UPDATE_ACCOUNT_INVOICE_INFO"
export const UPDATE_ACCOUNT_UNSUBSCRIBE_INFO = "UPDATE_ACCOUNT_UNSUBSCRIBE_INFO"
export const UPDATE_ACCOUNT_UPTODATE_INFO = "UPDATE_ACCOUNT_UPTODATE_INFO"
export const GET_SETTINGS_EMAIL_TEMPLATES = "GET_SETTINGS_EMAIL_TEMPLATES"
export const GET_SETTINGS_EMAIL_TEMPLATES_SUCCESS = "GET_SETTINGS_EMAIL_TEMPLATES_SUCCESS"
export const DELETE_EMAIL_TEMPLATE = "DELETE_EMAIL_TEMPLATE"
export const DELETE_EMAIL_TEMPLATE_SUCCESS = "DELETE_EMAIL_TEMPLATE_SUCCESS"
export const UPDATE_SENDER_EMAIL = "UPDATE_SENDER_EMAIL"
export const UPDATE_SENDER_EMAIL_SUCCESS = "UPDATE_SENDER_EMAIL_SUCCESS"
export const UPDATE_SUBSCRIPTION_LIST = "UPDATE_SUBSCRIPTION_LIST"
export const UPDATE_SUBSCRIPTION_LIST_SUCCESS = "UPDATE_SUBSCRIPTION_LIST_SUCCESS"
export const SET_REDUCER_VALUE = "SET_REDUCER_VALUE"
export const GET_USER_SIGNATURE_SAVE_POPUP_APPEAR_INFO = "GET_USER_SIGNATURE_SAVE_POPUP_APPEAR_INFO"
export const GET_USER_SIGNATURE_SAVE_POPUP_APPEAR_INFO_SUCCESS = "GET_USER_SIGNATURE_SAVE_POPUP_APPEAR_INFO_SUCCESS"
export const ADD_USER_SIGNATURE_SAVE_POPUP_APPEAR_LOG = "ADD_USER_SIGNATURE_SAVE_POPUP_APPEAR_LOG"
export const ADD_USER_SIGNATURE_SAVE_POPUP_APPEAR_LOG_SUCCESS = "ADD_USER_SIGNATURE_SAVE_POPUP_APPEAR_LOG_SUCCESS"
export const GET_USER_SIGNATURE_INFO = "GET_USER_SIGNATURE_INFO"
export const GET_USER_SIGNATURE_INFO_SUCCESS = "GET_USER_SIGNATURE_INFO_SUCCESS"
export const UPDATE_USER_SIGNATURE_INFO = "UPDATE_USER_SIGNATURE_INFO"
export const UPDATE_USER_SIGNATURE_INFO_SUCCESS = "UPDATE_USER_SIGNATURE_INFO_SUCCESS"

export const GET_EMAILING_SETTINGS = "GET_EMAILING_SETTINGS"
export const GET_EMAILING_SETTINGS_SUCCESS = "GET_EMAILING_SETTINGS_SUCCESS"
export const ADD_SENDER_EMAIL = "ADD_SENDER_EMAIL"
export const ADD_SENDER_EMAIL_SUCCESS = "ADD_SENDER_EMAIL_SUCCESS"
export const DELETE_SENDER_EMAIL = "DELETE_SENDER_EMAIL"
export const DELETE_SENDER_EMAIL_SUCCESS = "DELETE_SENDER_EMAIL_SUCCESS"
export const GET_USER_SIGNATURE = "GET_USER_SIGNATURE"
export const GET_USER_SIGNATURE_SUCCESS = "GET_USER_SIGNATURE_SUCCESS"
export const ADD_USER_SIGNATURE = "ADD_USER_SIGNATURE"
export const ADD_USER_SIGNATURE_SUCCESS = "ADD_USER_SIGNATURE_SUCCESS"
export const UPDATE_USER_SIGNATURE = "UPDATE_USER_SIGNATURE"
export const UPDATE_USER_SIGNATURE_SUCCESS = "UPDATE_USER_SIGNATURE_SUCCESS"
export const DELETE_USER_SIGNATURE = "DELETE_USER_SIGNATURE"
export const DELETE_USER_SIGNATURE_SUCCESS = "DELETE_USER_SIGNATURE_SUCCESS"
export const ADD_USER_JURISDICTION_EMAIL_SETTING = "ADD_USER_JURISDICTION_EMAIL_SETTING"
export const ADD_USER_JURISDICTION_EMAIL_SETTING_SUCCESS = "ADD_USER_JURISDICTION_EMAIL_SETTING_SUCCESS"
export const UPDATE_USER_JURISDICTION_EMAIL_SETTING = "UPDATE_USER_JURISDICTION_EMAIL_SETTING"
export const UPDATE_USER_JURISDICTION_EMAIL_SETTING_SUCCESS = "UPDATE_USER_JURISDICTION_EMAIL_SETTING_SUCCESS"
export const DELETE_USER_JURISDICTION_EMAIL_SETTING = "DELETE_USER_JURISDICTION_EMAIL_SETTING"
export const DELETE_USER_JURISDICTION_EMAIL_SETTING_SUCCESS = "DELETE_USER_JURISDICTION_EMAIL_SETTING_SUCCESS"
export const UPDATE_DISPLAY_NAME = "UPDATE_DISPLAY_NAME"
export const UPDATE_DISPLAY_NAME_SUCCESS = "UPDATE_DISPLAY_NAME_SUCCESS"
export const UPDATE_SENDER_EMAIL_ADDRESS = "UPDATE_SENDER_EMAIL_ADDRESS"
export const UPDATE_SENDER_EMAIL_ADDRESS_SUCCESS = "UPDATE_SENDER_EMAIL_ADDRESS_SUCCESS"

export const GET_OCMN_SHOW_STARTUP = "GET_OCMN_SHOW_STARTUP"
export const GET_OCMN_SHOW_STARTUP_SUCCESS = "GET_OCMN_SHOW_STARTUP_SUCCESS"
export const SAVE_OCMN_SETTINGS = "SAVE_OCMN_SETTINGS"
export const SAVE_OCMN_SETTINGS_SUCCESS = "SAVE_OCMN_SETTINGS_SUCCESS"

export const ADD_USER_INVOICE_INFO = "ADD_USER_INVOICE_INFO"
export const ADD_USER_INVOICE_INFO_SUCCESS = "ADD_USER_INVOICE_INFO_SUCCESS"
export const MODIFY_USER_INVOICE_INFO = "MODIFY_USER_INVOICE_INFO"
export const MODIFY_USER_INVOICE_INFO_SUCCESS = "MODIFY_USER_INVOICE_INFO_SUCCESS"
export const REMOVE_USER_INVOICE_INFO = "REMOVE_USER_INVOICE_INFO"
export const REMOVE_USER_INVOICE_INFO_SUCCESS = "REMOVE_USER_INVOICE_INFO_SUCCESS"
export const GET_USER_SUBSCRIPTION_LIST = "GET_USER_SUBSCRIPTION_LIST"
export const GET_USER_SUBSCRIPTION_LIST_SUCCESS = "GET_USER_SUBSCRIPTION_LIST_SUCCESS"
export const MODIFY_USER_SUBSCRIPTION_INVOICE_INFO = "MODIFY_USER_SUBSCRIPTION_INVOICE_INFO"
export const MODIFY_USER_SUBSCRIPTION_INVOICE_INFO_SUCCESS = "MODIFY_USER_SUBSCRIPTION_INVOICE_INFO_SUCCESS"