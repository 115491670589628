import {
  SET_ADM_INVOICE_MANAGEMENT_LOADING,
  CHANGE_ADM_INVOICE_MANAGEMENT_SEARCH_LIST,
  GET_ADM_INVOICE_MANAGEMENT_SEARCH_LIST,
  GET_ADM_INVOICE_MANAGEMENT_SEARCH_LIST_SUCCESS,
  DOWN_INVOICE_PDF,
  DOWN_INVOICE_PDF_SUCCESS,
  UPDATE_INVOICE_STATUS,
  UPDATE_INVOICE_STATUS_SUCCESS,
  SET_SUCCESS,
  SET_RESULT,
  CONFIRM_PAYMENT,
  CONFIRM_PAYMENT_SUCCESS,
  GET_SEARCH_USER_LIST,
  GET_SEARCH_USER_LIST_SUCCESS,
  CHANGE_SEARCH_USER_LIST,
  CHANGE_ADM_INVOICE_MANAGEMENT_USER_INFO,
  CHANGE_ADM_INVOICE_MANAGEMENT_ITEM_LIST,
  CHANGE_USER_INVOICE_INFO_LIST,
  GET_SUBSCRIPTION_ITEM_ALL_LIST,
  GET_SUBSCRIPTION_ITEM_ALL_LIST_SUCCESS,
  CHANGE_SUBSCRIPTION_ITEM_ALL_LIST,
  GET_CRESTER_LIST,
  GET_CRESTER_LIST_SUCCESS,
  SET_ADM_INVOICE_MANAGEMENT_SUCCESS,
  SET_ADM_INVOICE_MANAGEMENT_ERROR,
  CREATE_ADMIN_INVOICE,
  CREATE_ADMIN_INVOICE_SUCCESS,
  GET_USER_INVOICE_INFO_LIST,
  GET_USER_INVOICE_INFO_LIST_SUCCESS,
  PREVIEW_INVOICE_ITEM,
  PREVIEW_INVOICE_ITEM_SUCCESS,
  GET_INVOICE_ITEM,
  GET_INVOICE_ITEM_SUCCESS,
} from "./actionTypes"

const initialState = {
  error: "",
  loading: false,
  success: "",
  result: -1,
  totalPage: 1,
  noticeStr: "",
  admInvoiceManagementSearchList: [],
  searchUserList: [],
  searchUserMessage: "",
  admInvoiceManagementUserInfo: {},
  admInvoiceManagementItemList: [],
  admInvoiceManagementModifyInvoiceForm: {},
  subscriptionItemAllList: [],
  cresterList: [],
  admInfo: {},
  userInvoiceInfoList: [],
  previewInvoiceItem: {},
}

const AdmInvoiceManagement = (state = initialState, action) => {
  switch (action.type) {
    case SET_ADM_INVOICE_MANAGEMENT_LOADING:
      state = {
        ...state,
        loading: action.payload,
      }
      break
    case CHANGE_ADM_INVOICE_MANAGEMENT_SEARCH_LIST:
      state =  {
        ...state,
        admInvoiceManagementSearchList: action.payload,
      }
      break
    case GET_ADM_INVOICE_MANAGEMENT_SEARCH_LIST:
      state = {
        ...state,
        loading: true,
      }
      break
    case GET_ADM_INVOICE_MANAGEMENT_SEARCH_LIST_SUCCESS:
      state =  {
        ...state,
        admInvoiceManagementSearchList: action.payload.admInvoiceManagementSearchList,
        totalPage: action.payload.totalPage,
        noticeStr: action.payload.noticeStr,
        loading: false,
      }
      break
    case DOWN_INVOICE_PDF:
      return {
        ...state,
        loading: true
      }
    case DOWN_INVOICE_PDF_SUCCESS:
      return {
        ...state,
        loading: false
      }
    case UPDATE_INVOICE_STATUS:
      state = {
        ...state,
        loading: true,
      }
      break
    case UPDATE_INVOICE_STATUS_SUCCESS:
      state =  {
        ...state,
        loading: false,
        success: action.payload.result,
      }
      break
    case SET_SUCCESS:
      return {
        ...state,
        success: action.payload,
      }
    case SET_RESULT:
      return {
        ...state,
        result: action.payload,
      }
    case CONFIRM_PAYMENT:
      state = {
        ...state,
        loading: true,
      }
      break
    case CONFIRM_PAYMENT_SUCCESS:
      state =  {
        ...state,
        loading: false,
        success: action.payload,
      }
      break
    case GET_SEARCH_USER_LIST:
      state = {
        ...state,
        loading: true,
      }
      break
    case GET_SEARCH_USER_LIST_SUCCESS:
      state =  {
        ...state,
        loading: false,
        searchUserList: action.payload.result,
        searchUserMessage: action.payload.message,
      }
      break
    case CHANGE_SEARCH_USER_LIST:
      state =  {
        ...state,
        searchUserList: action.payload,
      }
      break
    case CHANGE_ADM_INVOICE_MANAGEMENT_USER_INFO:
      state =  {
        ...state,
        admInvoiceManagementUserInfo: action.payload,
      }
      break
    case CHANGE_ADM_INVOICE_MANAGEMENT_ITEM_LIST:
      state =  {
        ...state,
        admInvoiceManagementItemList: action.payload,
      }
      break
    case CHANGE_USER_INVOICE_INFO_LIST:
      state =  {
        ...state,
        userInvoiceInfoList: action.payload,
      }
      break
    
    case GET_SUBSCRIPTION_ITEM_ALL_LIST:
      state = {
        ...state,
        loading: true,
      }
      break
    case GET_SUBSCRIPTION_ITEM_ALL_LIST_SUCCESS:
      state =  {
        ...state,
        loading: false,
        subscriptionItemAllList: action.payload.subscriptionItemAllList,
      }
      break
    case CHANGE_SUBSCRIPTION_ITEM_ALL_LIST:
      state =  {
        ...state,
        subscriptionItemAllList: action.payload,
      }
      break
    case GET_CRESTER_LIST:
      state = {
        ...state,
        loading: true,
      }
      break
    case GET_CRESTER_LIST_SUCCESS:
      state =  {
        ...state,
        loading: false,
        cresterList: action.payload.result,
        admInfo: action.payload.admInfo,
      }
      break
    case SET_ADM_INVOICE_MANAGEMENT_SUCCESS:
      return {
        ...state,
        success: action.payload,
      }
    case SET_ADM_INVOICE_MANAGEMENT_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      }
    case CREATE_ADMIN_INVOICE:
      state = {
        ...state,
        loading: true,
      }
      break
    case CREATE_ADMIN_INVOICE_SUCCESS:
      return {
        ...state,
        loading: false,
        success: action.payload,
      }
    case GET_USER_INVOICE_INFO_LIST:
      state = {
        ...state,
        loading: true,
        userInvoiceInfoList: [],
      }
      break
    case GET_USER_INVOICE_INFO_LIST_SUCCESS:
      state =  {
        ...state,
        loading: false,
        userInvoiceInfoList: action.payload.userInvoiceInfoList,
      }
      break
    case PREVIEW_INVOICE_ITEM:
      state = {
        ...state,
        loading: true,
        previewInvoiceItem: {},
      }
      break
    case PREVIEW_INVOICE_ITEM_SUCCESS:
      state =  {
        ...state,
        loading: false,
        previewInvoiceItem: action.payload.invoiceItemInfo,
      }
      break
    case GET_INVOICE_ITEM:
      state = {
        ...state,
        loading: true,
      }
      break
    case GET_INVOICE_ITEM_SUCCESS:
      state =  {
        ...state,
        loading: false,
        admInvoiceManagementUserInfo: action.payload.invoiceItemInfo,
        admInvoiceManagementItemList: action.payload.invoiceItemInfo.itemList,
      }
      break
    default:
      state = { ...state }
      break
  }
  return state
}

export default AdmInvoiceManagement
