import React, {useState, useEffect, useRef} from 'react';
import { useSelector, useDispatch } from "react-redux";
import {
    Table,
    Row,
    Col,
    Card,
    CardBody,
    Media,
    Collapse,
    Form,
    Input,
    Label,
    Button,
    UncontrolledTooltip,
  } from "reactstrap"
import LoadingModal from "../../components/Common/LoadingModal"
import SweetAlert from "react-bootstrap-sweetalert";
import { Link } from "react-router-dom";
import { useFormik } from "formik";
import CommunityEditorModal from "./CommunityEditorModal"

import {
  setCommunityLoadingEditor,
  setCommunityLoading,
  setCommunitySuccess,
  getCommunityList,
  removeCommunity as onRemoveCommunity,
} from "../../store/actions";
import { cloneDeep } from 'lodash';

const CommunityList = props => {
  const dispatch = useDispatch();

  const { communityList, loading, totalPage, success } = useSelector(state => ({
    communityList: state.Community.communityList,
    loading: state.Community.loading,
    totalPage: state.Community.totalPage,
    success: state.Community.success,
  }));

  const [confirmType, setConfirmType] = useState("");
  const [confirmMsg, setConfirmMsg] = useState("");
  const [confirmAlert, setConfirmAlert] = useState(false);
  const [confirmBtnText, setConfirmBtnText] = useState("");
  const [cancelBtnText, setCancelBtnText] = useState("");
  const [removeCommunityIdx, setRemoveCommunityIdx] = useState(-1);

  const confirmRemoveCommunity = (idx) => {
    setRemoveCommunityIdx(idx);
    setConfirmMsg("Are you sure you want to delete this content?");
    setConfirmBtnText("Confirm");
    setCancelBtnText("Cancel");
    setConfirmAlert(true);
  }

  // [S] Email Editor
  const editorModaRef = useRef(null);
  const editorModal = useRef(null);

  const [isOpenEditor, setIsOpenEditor] = useState(false);
  const [isInitEditor, setIsInitEditor] = useState(false);
  const [loadedEditor, setLoadedEditor] = useState(false);
  const [communityIdx, setCommunityIdx] = useState(-2);
  
  function openEditor(idx) {
    if (!isInitEditor) {
      console.log('editor init start');
      setIsInitEditor(true);
    } else {
      console.log('editor inited');
    }
    
    setCommunityLoadingEditor(true);
    setCommunityIdx(idx);
    setIsOpenEditor(true);
    removeBodyCss();
  }

  function closeEditor() {
    console.log('closeEditor')
    setIsOpenEditor(false);
    setCommunityIdx(-2);
    editorModaRef.current.clearEditorContent();
    document.body.classList.remove("modal-open");
    document.body.classList.remove("no_padding");
    document.body.style.overflow = 'visible';
    document.body.style.paddingRight = '';
  }

  function removeBodyCss() {
    document.body.classList.add("no_padding");
    document.body.classList.add("modal-open");
    document.body.style.overflow = 'hidden';
  }
  
  
  const handleCloseEmailSettingModal = (e) => {
    if (e.target.id === 'CommunityEditorModal' && (!editorModal.current || !editorModal.current.contains(e.target))) {
      closeEditor()
    }
  }
  // [E] Editor

  const [isOpen, setIsOpen] = useState(true);

  const toggle = () => {
    setIsOpen(!isOpen);
  }

  const [columns, setColumns] = useState([
    {label: "Categoty", asc:"CTGA", desc:"CTGD", ascActive:"", descActive:""},
    {label: "Title", asc:"TITA", desc:"TITD", ascActive:"", descActive:""},
    {label: "Registration date", asc:"REGA", desc:"REGD", ascActive:"", descActive:""},
  ]);

  const [curPageInput, setCurPageInput] = useState(1);
  const removeCommunity = (idx) => {
    dispatch(onRemoveCommunity({idx:idx}));
  }

  // events validation
  const filterValidation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    //enableReinitialize: true,

    initialValues: {
      communityCtg: "",
      title: "",
      sortType: "REGD",
      pageSize: 50,
      curPage: 1,
      submitType: "",
    },
    validateOnChange: false, // this one
    validateOnBlur: false, // and this one
    onSubmit: (values) => {
      const filterParam = {
        communityCtg: values["communityCtg"],
        title: values["title"],
        sortType: values["sortType"],
        pageSize: values["pageSize"],
        curPage: values["curPage"],
      };

      let submitType = values["submitType"];
      
      if(submitType === "view"){
        dispatch(getCommunityList(filterParam));
      }
    },
  });

  const viewCommunityList = (sortType, curPage, pageSize) => {
    //curPage 유효성 체크
    if(curPage){
      if(curPage > totalPage) {
        curPage = totalPage;
      } else if (curPage <= 0) {
        curPage = 1;
      }
    }
    //빨간색으로 변경
    if(sortType){
      const _columns = [];
      columns.map((col, index)=>{
        let _col = cloneDeep(col);
        if(col.asc && col.asc === sortType){
          _col.ascActive = "text-danger";
          _col.descActive = "";
        }else if(col.desc && col.desc === sortType ){
          _col.ascActive = "";
          _col.descActive = "text-danger";
        }else{
          _col.ascActive = "";
          _col.descActive = "";
        }
        _columns.push(_col);
      });

      setColumns(_columns);
    }

    filterValidation.setFieldValue("submitType", "view");
    if(sortType){
      filterValidation.setFieldValue("sortType", sortType);
    }
    if(curPage){
      filterValidation.setFieldValue("curPage", curPage);
      setCurPageInput(curPage);
    }else{
      filterValidation.setFieldValue("curPage", 1);
      setCurPageInput(1);
    }
    if(pageSize){
      filterValidation.setFieldValue("pageSize", pageSize);
    }
    
    filterValidation.handleSubmit(filterValidation.values);
  }

  useEffect(() => {
    filterValidation.setFieldValue("submitType", "view");
    filterValidation.handleSubmit(filterValidation.values);

    window.addEventListener('click', handleCloseEmailSettingModal);
    return () => {
      window.removeEventListener('click', handleCloseEmailSettingModal);
    }
  },[])
  
  return (
    <React.Fragment>
      <Form onSubmit={filterValidation.handleSubmit}>
        <div className="page-content">
          <div className="container-fluid">
            <Row>
              <Col lg={12}>
                <Card>
                  <Link onClick={toggle} className="text-dark" to="#">
                    <div className="p-4">
                      <Media className="d-flex align-items-center">
                        <div className="flex-shrink-0 me-3">
                          <i className="uil uil-users-alt text-primary h2"></i>
                        </div>
                        <div className="flex-grow-1 overflow-hidden">
                          <h5 className="font-size-16 mb-1">Community</h5>
                        </div>
                        <i className="uil uil-filter font-size-24 text-muted"></i>
                        {/* 
                        <i className={isOpen ? "mdi mdi-chevron-up accor-down-icon font-size-24" : "mdi mdi-chevron-down accor-down-icon font-size-24"}></i>
                        */}
                      </Media>
                    </div>
                  </Link>
                  <Collapse isOpen={isOpen}>
                    <div className="p-4 border-top">
                      <Form onSubmit={filterValidation.handleSubmit}>
                        <div>
                          
                          <Row className="mb-3">
                            <label
                            htmlFor="memberKeyword"
                            className="col-md-2 col-form-label"
                            >
                            Category
                            </label>
                            <div className="col-md-3">
                              <select 
                                className="form-select" 
                                name="communityCtg"
                                onChange={e => {filterValidation.setFieldValue("communityCtg", e.target.value)}}
                                value={filterValidation.values.communityCtg}
                              >
                                <option value="">All</option>
                                <option value="Chitchat">Chitchat</option>
                                <option value="SelfPromotion">Self promotion</option>
                                <option value="CaseRequest">Case request</option>
                              </select>
                            </div>
                          </Row>
                          <Row className="mb-3">
                            <label
                            htmlFor="title"
                            className="col-md-2 col-form-label"
                            >
                            Title
                            </label>
                            <div className="col-md-3">
                            <Input
                                className="form-control"
                                type="text"
                                placeholder=""
                                id="title"
                                name="title"
                                onChange={filterValidation.handleChange}
                                value={filterValidation.values.title}
                                onKeyDown={(e)=>{
                                  if (e.key === 'Enter') {
                                    filterValidation.setFieldValue("submitType", "view");
                                    filterValidation.handleSubmit(filterValidation.values);
                                  }
                                }}
                            />
                            </div>
                          </Row>
                          
                          <Row className="mb-3">
                            <span className="col-md-3 button-items">
                              <Button
                                type="button"
                                color="outline-primary"
                                className="w-md"
                                style={{width : '148.58px'}}
                                onClick={()=>{
                                  openEditor(-1)
                                }}
                              >
                                Create new
                              </Button>
                            </span>
                            <Col md={3}>&nbsp;</Col>
                            <Col md={3}>
                              <button
                                type="button"
                                className="waves-effect waves-light btn btn-primary"
                                style={{width : '148.58px'}}
                                onClick={()=>{
                                  filterValidation.setFieldValue("submitType", "view");
                                  filterValidation.handleSubmit(filterValidation.values);
                                }}
                              >
                                Search
                              </button>
                            </Col>
                          </Row>
                        </div>
                      </Form>
                    </div>
                  </Collapse>
                </Card>
              {
              loading ?
                <LoadingModal loading={loading} setLoading={setCommunityLoading}></LoadingModal>
              : null
              }

              {success? (
                <SweetAlert
                  title={success}
                  timeout={2000}
                  showCloseButton={false}
                  showConfirm={false}
                  success
                  onConfirm={() => {
                    dispatch(setCommunitySuccess(""));
                  }}
                ></SweetAlert>
              ) : null}

              {confirmAlert ? (
                <SweetAlert
                  title={confirmMsg}
                  showCancel
                  confirmBtnText={confirmBtnText}
                  cancelBtnText={cancelBtnText}
                  confirmBtnBsStyle="success"
                  cancelBtnBsStyle="danger"
                  onConfirm={() => {
                    removeCommunity(removeCommunityIdx);
                    setRemoveCommunityIdx(-1);
                    setConfirmAlert(false);
                    setConfirmType("")
                  }}
                  onCancel={() => {
                    setRemoveCommunityIdx(-1);
                    setConfirmAlert(false)
                  }}
                >
                </SweetAlert>
              ) : null}

              {communityList && communityList.length ?
                <Row>
                  <Col lg={12}>
                    <Card>
                      <CardBody>
                        <div className="table-responsive">
                          <Table className="table mb-0">
                            <thead>
                              <tr>
                              {
                                columns && columns.length ?
                                columns.map((col, index) => {
                                    return (
                                      <th style={{position: "relative", whiteSpace: "pre-line", textAlign: "center", verticalAlign: "middle"}}>
                                        {col.label}
                                        {col.asc && col.desc? 
                                        <>
                                        <Link to='#' onClick={()=> {viewCommunityList(col.asc)}} className={col.ascActive}><i className="btn-asc-40 uil uil-angle-up font-size-18"></i></Link>
                                        <Link to='#' onClick={()=> {viewCommunityList(col.desc)}} className={col.descActive}><i className="btn-desc-40 uil uil-angle-down font-size-18"></i></Link>
                                        </>
                                        :null}
                                      </th>
                                    )
                                })
                                :null
                              }
                              </tr>
                            </thead>
                            <tbody>
                              {
                                communityList.map((community, index) => {
                                  return (
                                      <tr key={index} >
                                        <td style={{textAlign: "center", verticalAlign: "middle"}} onClick={() => {openEditor(community.idx)}}>{community.communityCtgDesc}</td>
                                        <td style={{textAlign: "center", verticalAlign: "middle"}} onClick={() => {openEditor(community.idx)}}>{community.title}</td>
                                        <td style={{textAlign: "center", verticalAlign: "middle"}}>
                                          {community.regDate}
                                          {community.writerYn === 'Y' ?
                                          <>
                                          &nbsp;
                                          <Link to="#" 
                                              onClick={() => {confirmRemoveCommunity(community.idx)}}>
                                              <i className="uil-trash-alt" id={"deleteContent"+index} style={{color:"red", fontSize:"18px"}}></i>
                                              <UncontrolledTooltip placement="top" target={"deleteContent"+index}>
                                              Delete content
                                              </UncontrolledTooltip>
                                          </Link>
                                          </>
                                          : null
                                          }
                                        </td>
                                      </tr>
                                  )})
                              }
                            </tbody>
                          </Table>
                        </div>
                        <div className="d-flex justify-content-between">
                          <span className="d-flex align-items-center gap-2">
                            <label
                              htmlFor="pageSize"
                              >
                              Entries per page
                            </label>
                            <div style={{width: "100px"}}>
                              <select 
                                className="form-select" 
                                name="pageSize"
                                onChange={e => {viewCommunityList(null, 1, e.target.value);}}
                                value={filterValidation.values.pageSize}
                              >
                                <option value="50">50</option>
                                <option value="100">100</option>
                                <option value="250">250</option>
                                <option value="500">500</option>
                                <option value="1000">1000</option>
                              </select>
                            </div>
                          </span>
                          <span className="d-flex align-items-center gap-2">
                            <div className="d-flex gap-1">
                              <Button
                                type="button"
                                color="primary"
                                onClick={()=>{viewCommunityList(null, 1);}}
                                disabled={(filterValidation.values.curPage === 1)}
                              >
                                {"<<"}
                              </Button>
                              <Button
                                type="button"
                                color="primary"
                                onClick={()=>{viewCommunityList(null, Number(filterValidation.values.curPage)-1);}}
                                disabled={(filterValidation.values.curPage === 1)}
                              >
                                {"<"}
                              </Button>
                            </div>
                            Page{" "}
                            <strong>
                              {filterValidation.values.curPage} of {totalPage}
                            </strong>
                            
                            <Input
                              type="number"
                              min={1}
                              style={{ width: 70 }}
                              max={totalPage}
                              name="curPage"
                              defaultValue={filterValidation.values.curPage}
                              value={curPageInput}
                              onChange={(e) => { setCurPageInput(e.target.value)}}
                              onKeyPress={(e) => {
                                if(e.key === 'Enter'){
                                  viewCommunityList(null, e.target.value);
                                }                      
                              }}
                            />
                            <div className="d-flex gap-1">
                              <Button 
                                type="button"
                                color="primary"
                                onClick={()=>{viewCommunityList(null, Number(filterValidation.values.curPage)+1);}}
                                disabled={(filterValidation.values.curPage === totalPage)}
                              >
                                {">"}
                              </Button>
                              <Button
                                type="button"
                                color="primary"
                                onClick={()=>{viewCommunityList(null, totalPage);}}
                                disabled={(filterValidation.values.curPage === totalPage)}
                              >
                                {">>"}
                              </Button>
                            </div>
                          </span>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              : null }
              </Col>

              <CommunityEditorModal isOpenEditor={isOpenEditor} isInitEditor={isInitEditor} closeEditor={closeEditor} 
                loadedEditor={loadedEditor} setLoadedEditor={setLoadedEditor} editorModal={editorModal} ref={editorModaRef} 
                communityIdx={communityIdx} removeCommunity={removeCommunity}/>
            </Row>
          </div>
        </div>
      </Form>
    </React.Fragment>
  )
}

export default CommunityList;