import { takeEvery, fork, put, all, call } from "redux-saga/effects"

// Login Redux States
import { FORGET_PASSWORD, RESET_PASSWORD } from "./actionTypes"
import { userForgetPasswordSuccess, userForgetPasswordError } from "./actions"

import {
  forgetPassword,
  resetPassword,
} from "../../../helpers/backend_helper"

//If user is send successfully send mail link then dispatch redux action's are directly from here.
function* forgetUser({ payload: data }) {
  try {
    const response = yield call(forgetPassword, data);
    if(response.error){
      yield put(userForgetPasswordError(response.error));
    }else{
      yield put(userForgetPasswordSuccess("Success"));
    }

  } catch (error) {
    console.log('forgetUser error >> ' + error);
    //yield put(userForgetPasswordError(error))
  }
}

function* onResetPassword({ payload: data }) {
  try {
    const response = yield call(resetPassword, data);
    if(response.error){
      yield put(userForgetPasswordError(response.error));
    }else{
      yield put(userForgetPasswordSuccess("Success"));
    }

  } catch (error) {
    console.log('resetPassword error >> ' + error);
    //yield put(userForgetPasswordError(error))
  }
}

export function* watchUserPasswordForget() {
  yield takeEvery(FORGET_PASSWORD, forgetUser)
  yield takeEvery(RESET_PASSWORD, onResetPassword)
}

function* forgetPasswordSaga() {
  yield all([fork(watchUserPasswordForget)])
}

export default forgetPasswordSaga
