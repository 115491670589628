import { call, put, takeEvery, takeLatest } from "redux-saga/effects"

// MadridNotifier Redux States
import { 
  GET_MADRID_SETTINGS,
  SAVE_MADRID_SETTINGS,
  GET_MADRID_NOTIFIER,
  GET_MADRID_NOTIFIER_INFO,
  MULTIPLE_HIDE_MADRID,
  MULTIPLE_UNHIDE_MADRID,
  MULTIPLE_STAR_MADRID,
  MULTIPLE_UNSTAR_MADRID,
  DOWN_MADRID_EXCEL,
  DOWN_MULTIPLE_MADRID_EXCEL,
  DOWN_MADRID_PDF,
  DOWN_MULTIPLE_MADRID_PDF,
  SEND_MADRID_EMAIL,
  PREVIEW_MADRID_EMAIL,
  UNLOCK_MADRID,
  STAR_TARGET,
  UN_STAR_TARGET,
  HIDE_TARGET,
  UN_HIDE_TARGET,
  GET_OCMN_LIST,
  SAVE_OCMN_SHOW_STARTUP,
  SAVE_OCMN_AUTO_SEND_YN,
  GET_OCMN_SUGGESTED_CONTACTS,
  SEND_OCMN_EMAIL,
  OCMN_PREVIEW_MADRID_EMAIL,
  UNLOCK_OCMN,
  ADD_OCMN_LOG,
} from "./actionTypes"
import { 
  getMadridNotifierInfo as getMadridNotifierInfoAction,
  getMadridSettingsSuccess,
  getMadridNotifierInfoSuccess,
  getMadridNotifierInfoSuccessUnlock,
  saveMadridSettingsSuccess,
  getMadridNotifierSuccess,
  downMadridExcelSuccess,
  downMultipleMadridExcelSuccess,
  downMadridPdfSuccess,
  downMultipleMadridPdfSuccess,
  setMadridResult,
  setMadridSuccess,
  previewMadridEmailSuccess,
  setMadridError,
  setUpgradeAlertMessage,
  changeSentResult,
  getOCMNListSuccess,
  saveOCMNShowStartupSuccess,
  saveOCMNAutoSendYnSuccess,
  getOCMNSuggestedContactsSuccess,
  sendOCMNEmailSuccess,
  ocmnPreviewMadridEmailSuccess,
  changeOcmnListUnlock,
} from "./actions"

import { 
  getUserUnlockInfo,
} from "../unlock/actions"

//Include Both Helper File with needed methods
import {
  getMadridSettings,
  saveMadridSettings,
  getMadridNotifier,
  getMadridNotifierInfo,
  multipleHideMadrid,
  multipleUnhideMadrid,
  multipleStarMadrid,
  multipleUnstarMadrid,
  downMadridExcel,
  downMultipleMadridExcel,
  downMadridPdf,
  downMultipleMadridPdf,
  sendMadridEmail,
  previewMadridEmail,
  unlockMadrid,
  multipleStarContacts,
  multipleUnstarContacts,
  multipleHideContacts,
  multipleUnhideContacts,
  getOCMNList,
  saveOCMNShowStartup,
  saveOCMNAutoSendYn,
  getOCMNSuggestedContacts,
  sendOCMNEmail,
  addOcmnLog,
} from "../../helpers/backend_helper"

import { cloneDeep } from 'lodash';

function* onGetMadridSettings() {
  try {
    const response = yield call(getMadridSettings);
    yield put(getMadridSettingsSuccess(response));
    
  } catch (error) {
    console.log('onGetMadridSettings error >> ' + error);
    //yield put(apiError(error))
  }
}

function* onSaveMadridSettings({ payload : data }) {
  try {
    const response = yield call(saveMadridSettings, data);
    yield put(saveMadridSettingsSuccess(response));
    
  } catch (error) {
    console.log('onSaveMadridSettings error >> ' + error);
    //yield put(apiError(error))
  }
}

function* onGetMadridNotifier({ payload : data }) {
  try {
    const response = yield call(getMadridNotifier, data);
    yield put(getMadridNotifierSuccess(response));
    
  } catch (error) {
    console.log('onGetMadridNotifiers error >> ' + error);
    //yield put(apiError(error))
  }
}

function* onGetMadridNotifierInfo({ payload : data }) {
  try {
    const response = yield call(getMadridNotifierInfo, data);
    yield put(getMadridNotifierInfoSuccess(response));
    
  } catch (error) {
    console.log('onGetMadridNotifierInfo error >> ' + error);
    //yield put(apiError(error))
  }
}

function* onMultipleHideMadrid({ payload : data }) {
  try {
    const response = yield call(multipleHideMadrid, data.caseList);

    if (response.result === 'Success') {
      yield put(getMadridNotifierInfoAction(data.caseList, data.filter.inOutType));
    }

    // const response2 = yield call(getMadridNotifierInfo, {madridNotifierList : data.caseList, inOutType : data.filter.inOutType});
    // yield put(getMadridNotifierInfoSuccess(response2));

    //새로고침
    // yield put(getMadridNotifierAction(data.filter));

  } catch (error) {
    console.log('onMultipleHideMadrid error >> ' + error);
    //yield put(apiError(error))
  }
}

function* onMultipleUnhideMadrid({ payload : data }) {
  try {
    const response = yield call(multipleUnhideMadrid, data.caseList);
    // yield put(getMadridNotifierAction(data.filter));
    if (response.result === 'Success') {
      yield put(getMadridNotifierInfoAction(data.caseList, data.filter.inOutType));
    }

    // const response2 = yield call(getMadridNotifierInfo, {madridNotifierList : data.caseList, inOutType : data.filter.inOutType});
    // yield put(getMadridNotifierInfoSuccess(response2));

  } catch (error) {
    console.log('onMultipleUnhideMadrid error >> ' + error);
    //yield put(apiError(error))
  }
}

function* onMultipleStarMadrid({ payload : data }) {
  try {
    const response = yield call(multipleStarMadrid, data.caseList);
    //새로고침
    // yield put(getMadridNotifierAction(data.filter));
    if (response.result === 'Success') {
      yield put(getMadridNotifierInfoAction(data.caseList, data.filter.inOutType));
    }

    // const response2 = yield call(getMadridNotifierInfo, {madridNotifierList : data.caseList, inOutType : data.filter.inOutType});
    // yield put(getMadridNotifierInfoSuccess(response2));

  } catch (error) {
    console.log('onMultipleStarMadrid error >> ' + error);
    //yield put(apiError(error))
  }
}

function* onMultipleUnstarMadrid({ payload : data }) {
  try {
    const response = yield call(multipleUnstarMadrid, data.caseList);
    // yield put(getMadridNotifierAction(data.filter));
    if (response.result === 'Success') {
      yield put(getMadridNotifierInfoAction(data.caseList, data.filter.inOutType));
    }

    // const response2 = yield call(getMadridNotifierInfo, {madridNotifierList : data.caseList, inOutType : data.filter.inOutType});
    // yield put(getMadridNotifierInfoSuccess(response2));

  } catch (error) {
    console.log('onMultipleUnstarMadrid error >> ' + error);
    //yield put(apiError(error))
  }
}

function* onDownMadridExcel({ payload : data}) {
  try {
    // console.log('onDownMadridExcel fileName : ' + data.fileName);

    yield call(downMadridExcel, data);
    yield put(downMadridExcelSuccess());

  } catch (error) {
    console.log('onDownMadridExcel error >> ' + error);
    //yield put(apiError(error))
  }
}

function* onDownMultipleMadridExcel({ payload : data }) {
  try {
    // console.log('onDownMultipleMadridExcel fileName : ' + data.fileName);

    yield call(downMultipleMadridExcel, data);
    yield put(downMultipleMadridExcelSuccess());

  } catch (error) {
    console.log('onDownMultipleMadridExcel error >> ' + error);
    //yield put(apiError(error))
  }
}

function* onDownMadridPdf({ payload : data}) {
  try {
    // console.log('onDownMadridPdf fileName : ' + data.fileName);

    yield call(downMadridPdf, data);
    yield put(downMadridPdfSuccess());

  } catch (error) {
    console.log('onDownMadridPdf error >> ' + error);
    //yield put(apiError(error))
  }
}

function* onDownMultipleMadridPdf({ payload : data }) {
  try {
    // console.log('onDownMultipleMadridPdf fileName : ' + data.fileName);

    yield call(downMultipleMadridPdf, data);
    yield put(downMultipleMadridPdfSuccess());

  } catch (error) {
    console.log('onDownMultipleMadridPdf error >> ' + error);
    //yield put(apiError(error))
  }
}

function* onSendMadridEmail({ payload : data }) {
  try {
    const response = yield call(sendMadridEmail, data.caseList);
    if(response.error !== undefined && response.error >-1){
      // console.log('onSendMadridEmail error : ' + response.error);
      // if (response.error == 10) {
      //   yield put(changeUnsubsInfo(response.result));
      // }
      yield put(setMadridResult(response.error));
      // yield put(getMadridNotifierAction(data.filter));
    }else{
      // yield put(setMadridSuccess("Success"));
      yield put(changeSentResult(response));
      // yield put(getMadridNotifierAction(data.filter));
    }

    const response2 = yield call(getMadridNotifierInfo, data.caseList);
    yield put(getMadridNotifierInfoSuccess(response2));

  } catch (error) {
    console.log('onSendMadridEmail error >> ' + error);
    //yield put(apiError(error))
  }
}

function* onPreviewMadridEmail({ payload : data }) {
  try {
    const response = yield call(previewMadridEmail, data);
    if(response.error != undefined && response.error >-1){
      yield put(setMadridResult(response.error));
    }else{
      yield put(previewMadridEmailSuccess(response));
    }

  } catch (error) {
    console.log('onPreviewMadridEmail error >> ' + error);
    //yield put(apiError(error))
  }
}

function* onUnlockMadrid({ payload : data }) {
  try {
    const response = yield call(unlockMadrid, data.caseList);
    if(response.error !== undefined && response.error >-1){
      yield put(getUserUnlockInfo());
      yield put(setMadridResult(response.error));
      // yield put(getMadridNotifierAction(data.filter));

    }else{
      if (response.result === 'Success') {
        yield put(getUserUnlockInfo());
        // yield put(setMadridSuccess("Success"));
        // yield put(getMadridNotifierAction(data.filter));

        if (response.remainUnlockCnt == 0) {
          yield put(setUpgradeAlertMessage({title:"You have just used your final credit.",text:"Upgrade your WeCrest plan to receive unlimited access for your jurisdiction, or wait until you receive more free credits on Monday."}));
        }
      } else {
        yield put(getUserUnlockInfo());
        yield put(setMadridResult(response.result));
        // yield put(getMadridNotifierAction(data.filter));
      }
    }

    // console.log('data.caseList >> ' + JSON.stringify(data.caseList));
    // console.log('response.unlockList >> ' + JSON.stringify(response.unlockList));

    if (response.unlockList) {
      let _caseList = cloneDeep(data.caseList);
      let _madridNotifierList = [];
      data.caseList.madridNotifierList.map((caseData, index) => {
        response.unlockList.map((unlockData, index2) => {
          if (caseData.repRankCode == unlockData.repRankCode) {
            // console.log('repRankCode >> ' + unlockData.repRankCode);
            let _caseData = cloneDeep(caseData);
            _caseData.irNumber = unlockData.irNumber;
            _madridNotifierList.push(_caseData);
            return;
          }
        })
      })

      _caseList.madridNotifierList = _madridNotifierList;
      // console.log('_newCaseList >> ' + JSON.stringify(_newCaseList));
      const response2 = yield call(getMadridNotifierInfo, _caseList);
      yield put(getMadridNotifierInfoSuccessUnlock(response2));
    }
  } catch (error) {
    console.log('onUnlockMadrid error >> ' + error);
    //yield put(apiError(error))
  }
}

function* onStarTarget({ payload : data }) {
  try {
    const response = yield call(multipleStarContacts, [{targetGroupNo : data.targetGroupNo}]);
    //새로고침
    if (response.result === 'Success') {
      // yield put(getMadridNotifierAction(data.filter));

      yield put(getMadridNotifierInfoAction(data.madridNotifierList, data.filter.inOutType));

      // const response2 = yield call(getMadridNotifierInfo, {madridNotifierList : data.madridNotifierList, inOutType : data.filter.inOutType});
      // yield put(getMadridNotifierInfoSuccess(response2));
    }

  } catch (error) {
    console.log('onStarTarget error >> ' + error);
  }
}

function* onUnStarTarget({ payload : data }) {
  try {
    const response = yield call(multipleUnstarContacts, [{targetGroupNo : data.targetGroupNo}]);
    //새로고침
    if (response.result === 'Success') {
      // yield put(getMadridNotifierAction(data.filter));

      yield put(getMadridNotifierInfoAction(data.madridNotifierList, data.filter.inOutType));

      // const response2 = yield call(getMadridNotifierInfo, {madridNotifierList : data.madridNotifierList, inOutType : data.filter.inOutType});
      // yield put(getMadridNotifierInfoSuccess(response2));
    }

  } catch (error) {
    console.log('onUnstarTarget error >> ' + error);
  }
}

function* onHideTarget({ payload : data }) {
  try {
    const response = yield call(multipleHideContacts, [{targetGroupNo : data.targetGroupNo}]);
    //새로고침
    if (response.result === 'Success') {
      // console.log('onHideTarget madridNotifierList : ' + JSON.stringify(data.madridNotifierList));
      // console.log('onHideTarget inOutType : ' + data.filter.inOutType);

      yield put(getMadridNotifierInfoAction(data.madridNotifierList, data.filter.inOutType));

      // const response2 = yield call(getMadridNotifierInfo, {madridNotifierList : data.madridNotifierList, inOutType : data.filter.inOutType});
      // yield put(getMadridNotifierInfoSuccess(response2));

      // onGetMadridNotifierInfo({madridNotifierList : data.madridNotifierList, inOutType : data.filter.inOutType});

      // yield put(getMadridNotifierAction(data.filter));
    }

  } catch (error) {
    console.log('onHideTarget error >> ' + error);
  }
}

function* onUnHideTarget({ payload : data }) {
  try {
    const response = yield call(multipleUnhideContacts, [{targetGroupNo : data.targetGroupNo}]);
    //새로고침
    if (response.result === 'Success') {
      // console.log('onUnHideTarget madridNotifierList : ' + JSON.stringify(data.madridNotifierList));
      // console.log('onUnHideTarget inOutType : ' + data.filter.inOutType);

      yield put(getMadridNotifierInfoAction(data.madridNotifierList, data.filter.inOutType));

      // const response2 = yield call(getMadridNotifierInfo, {madridNotifierList : data.madridNotifierList, inOutType : data.filter.inOutType});
      // yield put(getMadridNotifierInfoSuccess(response2));

      // onGetMadridNotifierInfo({madridNotifierList : data.madridNotifierList, inOutType : data.filter.inOutType});
      // yield put(getMadridNotifierAction(data.filter));
    }

  } catch (error) {
    console.log('onUnhideTarget error >> ' + error);
  }
}

function* onGetOCMNList({ payload : data }) {
  try {
    const response = yield call(getOCMNList, data);
    yield put(getOCMNListSuccess(response));
    
  } catch (error) {
    console.log('onGetOCMNList error >> ' + error);
  }
}

function* onSaveOCMNShowStartup({ payload : data }) {
  try {
    const response = yield call(saveOCMNShowStartup, data);
    yield put(saveOCMNShowStartupSuccess(response));
    
  } catch (error) {
    console.log('onSaveOCMNShowStartup error >> ' + error);
  }
}

function* onSaveOCMNAutoSendYn({ payload : data }) {
  try {
    const response = yield call(saveOCMNAutoSendYn, data);
    yield put(saveOCMNAutoSendYnSuccess(response));
    
  } catch (error) {
    console.log('onSaveOCMNAutoSendYn error >> ' + error);
  }
}

function* onGetOCMNSuggestedContacts({ payload : data }) {
  try {
    const response = yield call(getOCMNSuggestedContacts, data);
    yield put(getOCMNSuggestedContactsSuccess(response));
    
  } catch (error) {
    console.log('onGetOCMNSuggestedContacts error >> ' + error);
  }
}

function* onSendOCMNEmail({ payload : data }) {
  try {
    const response = yield call(sendOCMNEmail, data.caseList);
    if(response.error !== undefined && response.error >-1){
      yield put(setMadridResult(response.error));
    }else{
      // remove item at list
      // yield put(changeSentResult(response));
      yield put(sendOCMNEmailSuccess(response));
    }
  } catch (error) {
    console.log('onSendOCMNEmail error >> ' + error);
  }
}

function* onOcmnPreviewMadridEmail({ payload : data }) {
  try {
    const response = yield call(previewMadridEmail, data);
    if(response.error != undefined && response.error >-1){
      yield put(setMadridResult(response.error));
    }else{
      yield put(ocmnPreviewMadridEmailSuccess(response));
    }
  } catch (error) {
    console.log('onOcmnPreviewMadridEmail error >> ' + error);
  }
}

function* onUnlockOcmn({ payload : data }) {
  try {
    const response = yield call(unlockMadrid, data.caseList);

    if(response.error !== undefined && response.error >-1){
      yield put(getUserUnlockInfo());
      yield put(setMadridResult(response.error));
    }else{
      if (response.result === 'Success') {
        yield put(getUserUnlockInfo());
        if (response.remainUnlockCnt == 0) {
          yield put(setUpgradeAlertMessage({title:"You have just used your final credit.",text:"Upgrade your WeCrest plan to receive unlimited access for your jurisdiction, or wait until you receive more free credits on Monday."}));
        }
      } else {
        yield put(getUserUnlockInfo());
        yield put(setMadridResult(response.result));
      }
    }

    if (response.unlockList) {
      let _caseList = cloneDeep(data.caseList);
      let _madridNotifierList = [];
      data.caseList.madridNotifierList.map((caseData, index) => {
        response.unlockList.map((unlockData, index2) => {
          if (caseData.repRankCode == unlockData.repRankCode) {
            let _caseData = cloneDeep(caseData);
            _caseData.irNumber = unlockData.irNumber;
            _madridNotifierList.push(_caseData);
            return;
          }
        })
      })

      _caseList.madridNotifierList = _madridNotifierList;
      // console.log('_newCaseList >> ' + JSON.stringify(_newCaseList));
      const response2 = yield call(getMadridNotifierInfo, _caseList);
      yield put(changeOcmnListUnlock(response2));
    }
  } catch (error) {
    console.log('onUnlockOcmn error >> ' + error);
    //yield put(apiError(error))
  }
}


function* onAddOcmnLog({ payload : data }) {
  try {
    yield call(addOcmnLog, data);
  } catch (error) {
    console.log('onAddOcmnLog error >> ' + error);
  }
}

function* madridNotifierSaga() {
  yield takeLatest(GET_MADRID_SETTINGS, onGetMadridSettings);
  yield takeLatest(SAVE_MADRID_SETTINGS, onSaveMadridSettings);
  yield takeLatest(GET_MADRID_NOTIFIER, onGetMadridNotifier);
  yield takeLatest(GET_MADRID_NOTIFIER_INFO, onGetMadridNotifierInfo);
  yield takeLatest(MULTIPLE_HIDE_MADRID, onMultipleHideMadrid);
  yield takeLatest(MULTIPLE_UNHIDE_MADRID, onMultipleUnhideMadrid);
  yield takeLatest(MULTIPLE_STAR_MADRID, onMultipleStarMadrid);
  yield takeLatest(MULTIPLE_UNSTAR_MADRID, onMultipleUnstarMadrid);
  yield takeLatest(DOWN_MADRID_EXCEL, onDownMadridExcel);
  yield takeLatest(DOWN_MULTIPLE_MADRID_EXCEL, onDownMultipleMadridExcel);
  yield takeLatest(DOWN_MADRID_PDF, onDownMadridPdf);
  yield takeLatest(DOWN_MULTIPLE_MADRID_PDF, onDownMultipleMadridPdf);
  yield takeLatest(SEND_MADRID_EMAIL, onSendMadridEmail);
  yield takeLatest(PREVIEW_MADRID_EMAIL, onPreviewMadridEmail);
  yield takeLatest(UNLOCK_MADRID, onUnlockMadrid);
  yield takeLatest(STAR_TARGET, onStarTarget);
  yield takeLatest(UN_STAR_TARGET, onUnStarTarget);
  yield takeLatest(HIDE_TARGET, onHideTarget);
  yield takeLatest(UN_HIDE_TARGET, onUnHideTarget);

  yield takeLatest(GET_OCMN_LIST, onGetOCMNList);
  yield takeLatest(SAVE_OCMN_SHOW_STARTUP, onSaveOCMNShowStartup);
  yield takeLatest(SAVE_OCMN_AUTO_SEND_YN, onSaveOCMNAutoSendYn);
  yield takeLatest(GET_OCMN_SUGGESTED_CONTACTS, onGetOCMNSuggestedContacts);
  yield takeLatest(SEND_OCMN_EMAIL, onSendOCMNEmail);
  yield takeLatest(OCMN_PREVIEW_MADRID_EMAIL, onOcmnPreviewMadridEmail);
  yield takeLatest(UNLOCK_OCMN, onUnlockOcmn);
  yield takeLatest(ADD_OCMN_LOG, onAddOcmnLog);
  
}

export default madridNotifierSaga

