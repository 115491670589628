import {
  GET_JURISDICTIONS_MONTHLY_PRICING,
  GET_JURISDICTIONS_MONTHLY_PRICING_SUCCESS,
  CHANGE_JURISDICTIONS_MONTHLY_PRICING,
  GET_USER_MEMBERSHIP_INFO,
  GET_USER_MEMBERSHIP_INFO_SUCCESS,
  SET_MEMBERSHIP_PLAN_VALUE,
  UPDATE_SUBSCRIPTION_TO_FREE,
  UPDATE_SUBSCRIPTION_TO_FREE_SUCCESS,
  UPDATE_GOLD_MEMBERSHIP_PLAN,
  UPDATE_GOLD_MEMBERSHIP_PLAN_SUCCESS,
  GET_PAYPAL_PLAN_ID,
  GET_PAYPAL_PLAN_ID_SUCCESS,
} from "./actionTypes"

export const getJurisdictionsMonthlyPricing = inOutType => ({
  type: GET_JURISDICTIONS_MONTHLY_PRICING,
  payload: inOutType,
})

export const getJurisdictionsMonthlyPricingSuccess = data => {
  return {
    type: GET_JURISDICTIONS_MONTHLY_PRICING_SUCCESS,
    payload: data.pricingList,
  }
}

export const changeJurisdictionsMonthlyPricing = jurisdictionList => {
  return {
    type: CHANGE_JURISDICTIONS_MONTHLY_PRICING,
    payload: jurisdictionList,
  }
}

export const getUserMembershipInfo = () => ({
  type: GET_USER_MEMBERSHIP_INFO,
})

export const getUserMembershipInfoSuccess = data => {
  return {
    type: GET_USER_MEMBERSHIP_INFO_SUCCESS,
    payload: data,
  }
}

export const setMembershipPlanValue = (type, data) => {
  return {
    type: SET_MEMBERSHIP_PLAN_VALUE,
    payload: {type, data},
  }
}

export const updateSubscriptionToFree = () => ({
  type: UPDATE_SUBSCRIPTION_TO_FREE,
})

export const updateSubscriptionToFreeSuccess = data => {
  return {
    type: UPDATE_SUBSCRIPTION_TO_FREE_SUCCESS,
    payload: data,
  }
}

export const updateGoldMembershipPlan = data => {
  return {
    type: UPDATE_GOLD_MEMBERSHIP_PLAN,
    payload: data,
  }
}

export const updateGoldMembershipPlanSuccess = data => {
  return {
    type: UPDATE_GOLD_MEMBERSHIP_PLAN_SUCCESS,
    payload: data,
  }
}

export const getPaypalPlanId = data => {
  return {
    type: GET_PAYPAL_PLAN_ID,
    payload: data,
  }
}

export const getPaypalPlanIdSuccess = data => {
  return {
    type: GET_PAYPAL_PLAN_ID_SUCCESS,
    payload: data,
  }
}