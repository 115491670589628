export const SET_LOADING = "SET_LOADING"
export const SET_SUCCESS = "SET_SUCCESS"
export const SET_RESULT = "SET_RESULT"
export const SET_ERROR = "SET_ERROR"
export const CHANGE_ADM_ACCOUNT_MANAGEMENT_SEARCH_LIST = "CHANGE_ADM_ACCOUNT_MANAGEMENT_SEARCH_LIST"
export const GET_ADM_ACCOUNT_MANAGEMENT_SEARCH_LIST = "GET_ADM_ACCOUNT_MANAGEMENT_SEARCH_LIST"
export const GET_ADM_ACCOUNT_MANAGEMENT_SEARCH_LIST_SUCCESS = "GET_ADM_ACCOUNT_MANAGEMENT_SEARCH_LIST_SUCCESS"
export const DOWN_EXCEL_ADM_ACCOUNT_MANAGEMENT = "DOWN_EXCEL_ADM_ACCOUNT_MANAGEMENT"
export const DOWN_EXCEL_ADM_ACCOUNT_MANAGEMENT_SUCCESS = "DOWN_EXCEL_ADM_ACCOUNT_MANAGEMENT_SUCCESS"
export const UPDATE_ACCOUNT_BY_ADMIN = "UPDATE_ACCOUNT_BY_ADMIN"
export const UPDATE_ACCOUNT_BY_ADMIN_SUCCESS = "UPDATE_ACCOUNT_BY_ADMIN_SUCCESS"
export const UPDATE_PASSWORD_BY_ADMIN = "UPDATE_PASSWORD_BY_ADMIN"
export const UPDATE_PASSWORD_BY_ADMIN_SUCCESS = "UPDATE_PASSWORD_BY_ADMIN_SUCCESS"
export const RESET_SMTP_BY_ADMIN = "RESET_SMTP_BY_ADMIN"
export const RESET_SMTP_BY_ADMIN_SUCCESS = "RESET_SMTP_BY_ADMIN_SUCCESS"
export const VERIFY_ACCOUNT_BY_ADMIN = "VERIFY_ACCOUNT_BY_ADMIN"
export const VERIFY_ACCOUNT_BY_ADMIN_SUCCESS = "VERIFY_ACCOUNT_BY_ADMIN_SUCCESS"
export const VIRTUAL_LOG_IN_BY_ADMIN = "VIRTUAL_LOG_IN_BY_ADMIN"
export const VIRTUAL_LOG_IN_BY_ADMIN_SUCCESS = "VIRTUAL_LOG_IN_BY_ADMIN_SUCCESS"
export const CREATE_ACCOUNT_BY_ADMIN = "CREATE_ACCOUNT_BY_ADMIN"
export const CREATE_ACCOUNT_BY_ADMIN_SUCCESS = "CREATE_ACCOUNT_BY_ADMIN_SUCCESS"
export const ADD_BILLING_PROFILE_BY_ADMIN = "ADD_BILLING_PROFILE_BY_ADMIN"
export const CHANGE_BILLING_PROFILE_BY_ADMIN = "CHANGE_BILLING_PROFILE_BY_ADMIN"
export const REMOVE_BILLING_PROFILE_BY_ADMIN = "REMOVE_BILLING_PROFILE_BY_ADMIN"