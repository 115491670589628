import React from 'react';
import {
    Modal,
    Spinner,
  } from "reactstrap"

const LoadingModal = props => {
    return (
        <Modal
        isOpen={props.loading}
        centered={true}
        toggle={() => {
            props.setLoading && props.setLoading()
          }}
        >
            <div className="modal-body">
                <div class="d-flex justify-content-center">
                <Spinner className="m-1" color="primary" />
                </div>
                <p className="mb-0 text-center">
                Please wait while data loads…
                </p>
            </div>
        </Modal>
    );
};

export default LoadingModal;