import React, {useState, useEffect} from 'react';
import { useSelector, useDispatch } from "react-redux";
import {
    Table,
    Row,
    Col,
    Card,
    CardBody,
    CardTitle,
    Media,
    Collapse,
    Form,
    Input,
    Label,
    Button,
    FormFeedback,
    Modal,
  } from "reactstrap"
import SweetAlert from "react-bootstrap-sweetalert";
import LoadingModal from "../../components/Common/LoadingModal"
import { Link } from "react-router-dom";
import { useFormik } from "formik";
import classnames from "classnames"
import SearchRequester from "./SearchRequester"
import WeCrestPagination from '../../components/WeCrestPagination'

import {
  getUserIrInfoClaimList,
  confirmUserIrInfoClaim,
  getTargetGroupDetailList,
  changeUserIrInfoClaimList,
  setMyCaseManagementSuccess as onSetSuccess,
  setMyCaseManagementLoading as onSetLoading,
} from "../../store/actions";
import { cloneDeep } from 'lodash';
import dateFormat from 'dateformat';

import '../../components/Common/Common.css';

const AdmMyCaseManagement = props => {
  const dispatch = useDispatch();

  const { loading, success, userIrInfoClaimListTotalPage, userIrInfoClaimList, targetGroupDetailList } = useSelector(state => ({
    loading: state.MyCaseManagement.loading,
    success: state.MyCaseManagement.success,
    userIrInfoClaimListTotalPage: state.MyCaseManagement.userIrInfoClaimListTotalPage,
    userIrInfoClaimList: state.MyCaseManagement.userIrInfoClaimList,
    targetGroupDetailList: state.MyCaseManagement.targetGroupDetailList,
  }));

  const [curPageInput, setCurPageInput] = useState(1);
  const [chkAllFlag, setChkAllFlag] = useState(false);
  const [alertMsg, setAlertMsg] = useState("");

  const [isOpen, setIsOpen] = useState(true);
  const toggle = () => {
    setIsOpen(!isOpen);
  }

  const viewList = (sortType, curPage, pageSize) => {
    //curPage 유효성 체크
    if(curPage){
      if(curPage > userIrInfoClaimListTotalPage) {
        curPage = userIrInfoClaimListTotalPage;
      } else if (curPage <= 0) {
        curPage = 1;
      }
    }

    // console.log("viewMadridNotifier [sortType: "+sortType+", curPage: "+curPage+", pageSize: "+pageSize);
    // let _values = cloneDeep(madridValidation.values);
    // _values.submitType = "view";
    filterValidation.setFieldValue("submitType", "view");
   
    if(curPage){
      // _values.curPage = curPage;
      filterValidation.setFieldValue("curPage", curPage);
      setCurPageInput(curPage);
    }else{
      // _values.curPage = 1;
      filterValidation.setFieldValue("curPage", 1);
      setCurPageInput(1);
    }
    if(pageSize){
      // _values.pageSize = pageSize;
      filterValidation.setFieldValue("pageSize", pageSize);
    }
    // madridValidation.setValues(_values);

    if(sortType){
      filterValidation.setFieldValue("sortType", sortType);
    }
    
    filterValidation.handleSubmit(filterValidation.values);
  }

  const filterValidation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      submitType: "view",
      sortType: "",
      userNo: "",
      userName: "",
      irNumber: "",
      currentEntity: "",
      requestedEntity: "",
      status: "",
      pageSize: 20,
      curPage: 1,

      adminMemo: "",
    },
    
    onSubmit: (values) => {
      // console.log(JSON.stringify(values));

      const filterParam = {
        userNo: values["userNo"],
        irNumber: values["irNumber"],
        currentEntity: values["currentEntity"],
        requestedEntity: values["requestedEntity"],
        status: values["status"],
        pageSize: values["pageSize"],
        curPage: values["curPage"],
        sortType: values["sortType"],
      };

      let submitType = values["submitType"];
      
      if(submitType === "view"){
        dispatch(getUserIrInfoClaimList(filterParam));              
      // } else if (submitType === "confirmSelected"){
      //   let _userEntityConnectClaimList = [];
      //   userEntityConnectClaimList.map((claimInfo, entityIdx) => {
      //     if (claimInfo.checked) {
      //       let _claimInfo = cloneDeep(claimInfo);
      //       _claimInfo.status = "Confirmed";
      //       _userEntityConnectClaimList.push(_claimInfo);
      //     }
      //   })

      //   let _alertMsg = [];
      //   _userEntityConnectClaimList.map((claimInfo, entityIdx) => {
      //     if ((claimInfo.type === 'Disconnect' || claimInfo.type === 'IDNM' || claimInfo.type === 'IDC' || claimInfo.type === 'SENBDC' || claimInfo.type === 'CWAA') && (claimInfo.domain === '' || claimInfo.url === '')) {
      //       let chkStr = "";
      //       if (claimInfo.domain === '') {
      //         chkStr = 'domain'
      //       }

      //       if (claimInfo.url === '') {
      //         chkStr += (chkStr !== '' ? ', ' : '') + 'url'
      //       }
              
      //       _alertMsg.push(claimInfo.currentTargetGroupName + "'s " + chkStr + " field is empty.");
      //     }
      //   })

      //   if (_alertMsg.length > 0) {
      //     setAlertMsg(_alertMsg.toString());
      //     setSelectedButton("");
      //   } else {
      //     dispatch(confirmUserEntityConnectClaim(_userEntityConnectClaimList, filterParam));
      //     setSelectedButton("");
      //     setChkAllFlag(false);
      //   }
      // } else if (submitType === "denySelected"){
      //   let _userEntityConnectClaimList = [];
      //   userEntityConnectClaimList.map((claimInfo, entityIdx) => {
      //     if (claimInfo.checked) {
      //       let _claimInfo = cloneDeep(claimInfo);
      //       _claimInfo.status = "Denied";
      //       _claimInfo.memo = values["adminMemo"];
      //       _userEntityConnectClaimList.push(_claimInfo);
      //     }
      //   })
      //   dispatch(modifyUserEntityConnectClaimStatus(_userEntityConnectClaimList, filterParam));
      //   setSelectedButton("");
      //   setChkAllFlag(false);
      } else if (submitType === "confirm"){
        dispatch(confirmUserIrInfoClaim([cloneDeep(changeClaimInfo)], filterParam));
        setChangeClaimInfo({userNo:0})
        filterValidation.setFieldValue("adminMemo", "");
      } else if (submitType === "deny"){
        dispatch(confirmUserIrInfoClaim([cloneDeep(changeClaimInfo)], filterParam));
        setChangeClaimInfo({userNo:0})
        filterValidation.setFieldValue("adminMemo", "");
      }
    }
  })

  const [entityCheckCnt, setEntityCheckCnt] = useState(0);

  const entityCheck = (entityChkIdx, value) => {
    let _userIrInfoClaimList = cloneDeep(userIrInfoClaimList);
    let _entityCheckCnt = 0;

    if(entityChkIdx === "chkAll"){
      _userIrInfoClaimList = _userIrInfoClaimList.map((claimInfo, idx) => {
        claimInfo.checked = value;
        return claimInfo;
      })
    }else{
      _userIrInfoClaimList[entityChkIdx].checked = value
    }

    _userIrInfoClaimList.map((claimInfo, idx) => {
      if (claimInfo.checked) {
        _entityCheckCnt++;
      }
    })

    setChkAllFlag(_entityCheckCnt === _userIrInfoClaimList.length);
    setEntityCheckCnt(_entityCheckCnt);

    dispatch(changeUserIrInfoClaimList(_userIrInfoClaimList));
  }

  const setLoading = () => {
    dispatch(onSetLoading(!loading));
  }

  const [changeClaimInfo, setChangeClaimInfo] = useState({userNo:0});
  const [selectedButton, setSelectedButton] = useState("");

  return (
    <React.Fragment>
      <Form onSubmit={filterValidation.handleSubmit}>
        <div className="page-content">
          <div className="container-fluid">
            <Row>
              <Col lg={12}>
                <Card>
                  <Link onClick={toggle} className="text-dark" to="#">
                    <div className="p-4">
                      <Media className="d-flex align-items-center">
                        <div className="flex-shrink-0 me-3">
                          <i className="uil uil-layer-group text-primary h2"></i>
                        </div>
                        <div className="flex-grow-1 overflow-hidden">
                          <h5 className="font-size-16 mb-1">My case management</h5>
                        </div>
                        <i className={isOpen ? "mdi mdi-chevron-up accor-down-icon font-size-24" : "mdi mdi-chevron-down accor-down-icon font-size-24"} style={{ cursor: "pointer"}}></i>
                      </Media>
                    </div>
                  </Link>
                  <Collapse isOpen={isOpen}>
                    <div className="p-4 border-top">
                      <div>
                        <SearchRequester filterValidation={filterValidation}/>
                        <Row className="mb-3">
                          <label
                          htmlFor="irNumber"
                          className="col-md-2 col-form-label"
                          >
                          IRN
                          </label>
                          <div className="col-md-3">
                          <Input
                              className="form-control"
                              type="text"
                              placeholder="Enter entity"
                              id="entity"
                              name="entity"
                              onChange={filterValidation.handleChange}
                              value={filterValidation.values.irNumber}
                              onKeyPress={(e) => {
                                if(e.key === 'Enter'){
                                  setCurPageInput(1);
                                  filterValidation.setFieldValue("curPage", 1);
                                  filterValidation.setFieldValue("submitType", "view");
                                  filterValidation.handleSubmit(filterValidation.values);
                                }                      
                              }}
                          />
                          </div>
                        </Row>
                        <Row className="mb-3">
                          <label
                          htmlFor="currentEntity"
                          className="col-md-2 col-form-label"
                          >
                          Current Entity
                          </label>
                          <div className="col-md-3">
                          <Input
                              className="form-control"
                              type="text"
                              placeholder="Enter current entity"
                              id="currentEntity"
                              name="currentEntity"
                              onChange={filterValidation.handleChange}
                              value={filterValidation.values.currentEntity}
                              onKeyPress={(e) => {
                                if(e.key === 'Enter'){
                                  setCurPageInput(1);
                                  filterValidation.setFieldValue("curPage", 1);
                                  filterValidation.setFieldValue("submitType", "view");
                                  filterValidation.handleSubmit(filterValidation.values);
                                }                      
                              }}
                          />
                          </div>
                        </Row>
                        <Row className="mb-3">
                          <label
                          htmlFor="irNumber"
                          className="col-md-2 col-form-label"
                          >
                          Requested Entity
                          </label>
                          <div className="col-md-3">
                          <Input
                              className="form-control"
                              type="text"
                              placeholder="Enter requested entity"
                              id="requestedEntity"
                              name="requestedEntity"
                              onChange={filterValidation.handleChange}
                              value={filterValidation.values.requestedEntity}
                              onKeyPress={(e) => {
                                if(e.key === 'Enter'){
                                  setCurPageInput(1);
                                  filterValidation.setFieldValue("curPage", 1);
                                  filterValidation.setFieldValue("submitType", "view");
                                  filterValidation.handleSubmit(filterValidation.values);
                                }                      
                              }}
                          />
                          </div>
                        </Row>


                        <Row className="mb-3 align-items-center">
                          <label htmlFor="entityStatus" className="col-md-2 col-form-label">
                          Status
                          </label>
                          <div className="col-md-4 d-flex" style={{justifyContent: "space-between"}}>
                            <div className="form-check">
                                <input type="radio" className="form-check-input"
                                  id="statusAll"
                                  name="status"
                                  onChange={filterValidation.handleChange}
                                  value=""
                                  checked={filterValidation.values.status === ""}
                                />
                                <Label className="form-check-label" htmlFor="statusAll">
                                All
                                </Label>
                            </div>
                            <div className="form-check">
                                <input type="radio" className="form-check-input"
                                  id="statusPending"
                                  name="status"
                                  onChange={filterValidation.handleChange}
                                  value="Requested"
                                  checked={filterValidation.values.status === "Requested"}
                                />
                                <Label className="form-check-label" htmlFor="statusPending">
                                Pending
                                </Label>
                            </div>
                            <div className="form-check">
                                <input type="radio" className="form-check-input"
                                  id="statusConfirmed"
                                  name="status"
                                  onChange={filterValidation.handleChange}
                                  value="Confirmed"
                                  checked={filterValidation.values.status === "Confirmed"}
                                />
                                <Label className="form-check-label" htmlFor="statusConfirmed">
                                Confirmed
                                </Label>
                            </div>
                            <div className="form-check">
                                <input type="radio" className="form-check-input"
                                  id="statusDenied"
                                  name="status"
                                  onChange={filterValidation.handleChange}
                                  value="Denied"
                                  checked={filterValidation.values.status === "Denied"}
                                />
                                <Label className="form-check-label" htmlFor="statusDenied">
                                Denied
                                </Label>
                            </div>
                          </div>
                        </Row>
                      
                        <Row >
                          <Col md={2}>&nbsp;</Col>
                          <Col md={3}>&nbsp;</Col>
                          <Col md={3}>
                            <Row className="gap-2">
                              <button
                                type="button"
                                className="btn btn-primary waves-effect waves-light col-md-5"
                                onClick={()=>{
                                  setCurPageInput(1);
                                  filterValidation.setFieldValue("curPage", 1);
                                  filterValidation.setFieldValue("submitType", "view");
                                  filterValidation.handleSubmit(filterValidation.values);
                                }}
                              >
                                Search
                              </button>
                            </Row>
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </Collapse>
                </Card>
              </Col>
            </Row>
            {userIrInfoClaimList && userIrInfoClaimList.length ?
            <Row>
              <Col lg={12}>
                <Card>
                  <CardBody>
                    {/*
                    entityCheckCnt > 0 ?
                    <CardTitle className="text-muted mb-0">
                      <Row className="mb-3">
                        <span className="col-md-8 button-items">
                          <Button
                            type="button"
                            color="primary"
                            outline
                            className="waves-effect waves-light col-md-2"
                            onClick={()=>{
                              setSelectedButton("Confirm")
                            }}
                          >
                            Confirm selected
                          </Button>
                          <Button
                            type="button"
                            color="primary"
                            outline
                            className="waves-effect waves-light col-md-2"
                            onClick={()=>{
                              setSelectedButton("Deny")
                            }}
                          >
                            Deny selected
                          </Button>
                        </span>
                      </Row>
                    </CardTitle>
                    : null
                    */}
                    <div className="table-responsive">
                      <Table className="table mb-0">
                        <thead>
                          <tr>
                          <th style={{verticalAlign: "middle", textAlign: "center"}}><input type="checkbox" checked={chkAllFlag} onClick={(e)=>entityCheck("chkAll", e.target.checked)} /></th>
                          <th>Requester</th>
                          <th>IRN</th>
                          <th>Current entity</th>
                          <th>Requested entity</th>
                          <th>User Memo</th>
                          <th>Request</th>
                          <th>Admin Memo</th>
                          <th>Admin action</th>
                          </tr>
                        </thead>
                        <tbody>
                        {
                          userIrInfoClaimList.map((claimInfo, index) => {
                            return (
                                <tr key={index}>
                                  <td align="center">
                                      <input type="checkbox" 
                                        checked={claimInfo.checked}
                                        onClick={(e)=>entityCheck(index, e.target.checked)}
                                        />
                                  </td>
                                  <td>
                                    {claimInfo.userName}
                                  </td>
                                  <td>
                                    {claimInfo.irNumber}
                                  </td>
                                  <td>
                                    {claimInfo.currentTargetGroupName}
                                  </td>
                                  <td>
                                    {claimInfo.requestedTargetGroupName}
                                  </td>
                                  <td>
                                    {claimInfo.userMemo}
                                  </td>
                                  <td>
                                    {
                                      claimInfo.type === 'NotMycase' ? "Not my case"
                                      :
                                      claimInfo.type === 'Connect' ? "Connect"
                                      :
                                      "Connect : Connected with another account"
                                    }
                                  </td>
                                  <td>
                                    {claimInfo.adminMemo}
                                  </td>
                                  <td>
                                    {
                                      claimInfo.status === 'Requested' ?
                                      <select 
                                        className="form-select" 
                                        name="status"
                                        onChange={e => {
                                          if (e.target.value !== 'Requested') {
                                            let _claimInfo = cloneDeep(claimInfo);
                                            _claimInfo.status = e.target.value;

                                            // let _alertMsg = [];
                                            // if ((_claimInfo.type === 'Disconnect' || _claimInfo.type === 'IDNM' || _claimInfo.type === 'IDC' || _claimInfo.type === 'SENBDC' || claimInfo.type === 'CWAA') && e.target.value === 'Confirmed' && (_claimInfo.domain === '' || _claimInfo.url === '')) {
                                            //   let chkStr = "";
                                            //   if (_claimInfo.domain === '') {
                                            //     chkStr = 'domain'
                                            //   }

                                            //   if (_claimInfo.url === '') {
                                            //     chkStr += (chkStr !== '' ? ', ' : '') + 'url'
                                            //   }
                                                
                                            //   _alertMsg.push(_claimInfo.currentTargetGroupName + "'s " + chkStr + " field is empty.");
                                            // }

                                            // if (_alertMsg.length > 0) {
                                            //   setAlertMsg(_alertMsg.toString());
                                            // } else {
                                              setChangeClaimInfo(_claimInfo);

                                              if (claimInfo.type === 'NotMycase' && e.target.value === 'Confirmed') {
                                                dispatch(getTargetGroupDetailList({targetGroupNo: _claimInfo.currentTargetGroupNo}));
                                              }
                                            // }
                                          }
                                        }}
                                        value={claimInfo.status}
                                        style={{width:'100px'}}
                                      >
                                        <option value="Requested">Pending</option>
                                        <option value="Confirmed">Confirm</option>
                                        <option value="Denied">Deny</option>
                                      </select>
                                      :
                                      claimInfo.status
                                    }
                                  </td>
                                </tr>
                              )
                            })
                          }
                        </tbody>
                      </Table>
                    </div>
                    
                    <WeCrestPagination 
                      viewList={viewList} 
                      pageSize={filterValidation.values.pageSize} 
                      curPage={filterValidation.values.curPage}
                      totalPage={userIrInfoClaimListTotalPage}
                      curPageInput={curPageInput}
                      setCurPageInput={setCurPageInput}
                    />
                  </CardBody>
                </Card>
              </Col>
            </Row>
            :null}
          
            {
              loading ?
              <LoadingModal loading={loading} setLoading={setLoading}></LoadingModal>
              : null
            }

            {
              success ? 
              <SweetAlert
                title={success}
                timeout={2000}
                showCloseButton={false}
                showConfirm={false}
                success
                onConfirm={() => {
                  dispatch(onSetSuccess(""));
                }}
              ></SweetAlert>
              : null
            }

            {alertMsg !== '' ? (
              <SweetAlert
                warning
                onConfirm={() => {
                  setAlertMsg('');
                }}
                onCancel={() => {
                  setAlertMsg('');
                }}
              >{alertMsg}
              </SweetAlert>
            ) : null}

            {changeClaimInfo.userNo > 0 && changeClaimInfo.status === 'Denied'? (
              <SweetAlert
                showCancel
                confirmBtnText="Confirm"
                cancelBtnText="Cancel"
                confirmBtnBsStyle="success"
                cancelBtnBsStyle="danger"
                title={"Are you sure you want to " + (changeClaimInfo.status === 'Confirmed' ? 'Confirm' : 'Deny') 
                + " [" + (changeClaimInfo.type === 'NotMycase' ? "Not my case" : "Connect") + "]?"}
                onConfirm={() => {
                  if (changeClaimInfo.status === 'Confirmed') {
                    filterValidation.setFieldValue("submitType", "confirm");
                    filterValidation.handleSubmit(filterValidation.values);
                  } else {
                    if (filterValidation.values.adminMemo !== '') {
                      let _claimInfo = cloneDeep(changeClaimInfo);
                      _claimInfo.adminMemo = filterValidation.values.adminMemo;
                      setChangeClaimInfo(_claimInfo);

                      filterValidation.setFieldValue("submitType", "deny");
                      filterValidation.handleSubmit(filterValidation.values);
                    }
                  }
                }}
                onCancel={() => {
                  setChangeClaimInfo({userNo:0});
                  filterValidation.setFieldValue("adminMemo", "");
                }}
              >
                {
                changeClaimInfo.status === 'Denied' ? 
                <div>
                  <Input
                    className="form-control"
                    type="text"
                    placeholder="Enter deny reason"
                    name="adminMemo"
                    onChange={filterValidation.handleChange}
                    value={filterValidation.values.adminMemo}
                    invalid={
                      filterValidation.values.adminMemo === '' ? true : false
                    }
                  />
                  {filterValidation.values.adminMemo === '' ? (
                    <FormFeedback type="invalid">Please enter a reason for deny.</FormFeedback>
                  ) : null}
                </div>
                : null
                }
                
              </SweetAlert>
            ) : null}

            {selectedButton === 'Deny'? (
              <SweetAlert
                showCancel
                confirmBtnText="Confirm"
                cancelBtnText="Cancel"
                confirmBtnBsStyle="success"
                cancelBtnBsStyle="danger"
                title={"Are you sure you want to " + selectedButton + " selected item?"}
                onConfirm={() => {
                  if (selectedButton === 'Confirm') {
                    filterValidation.setFieldValue("submitType", "confirmSelected");
                    filterValidation.handleSubmit(filterValidation.values);
                  } else {
                    if (filterValidation.values.memo !== '') {
                      filterValidation.setFieldValue("submitType", "denySelected");
                      filterValidation.handleSubmit(filterValidation.values);
                    }
                  }
                }}
                onCancel={() => setSelectedButton("")}
              >
                {
                selectedButton === 'Deny' ? 
                <div>
                  <Input
                    className="form-control"
                    type="text"
                    placeholder="Enter a deny reason"
                    name="adminMemo"
                    onChange={filterValidation.handleChange}
                    value={filterValidation.values.adminMemo}
                    invalid={
                      filterValidation.values.adminMemo === '' ? true : false
                    }
                  />
                  {filterValidation.values.adminMemo === '' ? (
                    <FormFeedback type="invalid">Please enter a reason for deny.</FormFeedback>
                  ) : null}
                </div>
                : null
                }
              </SweetAlert>
            ) : null}

            <Modal
                isOpen={changeClaimInfo.userNo > 0 && changeClaimInfo.status === 'Confirmed'}
                toggle={() => {
                  setChangeClaimInfo({userNo:0});
                  filterValidation.setFieldValue("adminMemo", "");
                }}
                scrollable={true}
                style={{top: "20%"}}
            >
              <div className="table-responsive">
                <Table className="table mb-0">
                  <tbody>
                    <tr>
                      <td width="30%">
                        <label>Requester</label>
                      </td>
                      <td>
                        <label>{changeClaimInfo.userName}</label>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <label>Entity related to the case</label>
                      </td>
                      <td>
                        <label>{changeClaimInfo.currentTargetGroupName}</label>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <label>Current domain</label>
                      </td>
                      <td>
                        <label>{changeClaimInfo.currentDomain}</label>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <label>Current URL</label>
                      </td>
                      <td>
                        <label>{changeClaimInfo.currentUrl}</label>
                      </td>
                    </tr>
                    {
                    changeClaimInfo.type === 'NotMycase' ?
                    <tr>
                      <td>
                        <label>Requested domain</label>
                      </td>
                      <td>
                        <label>{changeClaimInfo.requestedDomain}</label>
                      </td>
                    </tr>
                    : 
                    changeClaimInfo.type === 'Claim' ?
                    <tr>
                      <td>
                        <label>Requested entity</label>
                      </td>
                      <td>
                        <label>{changeClaimInfo.requestedTargetGroupName}</label>
                      </td>
                    </tr>
                    : null
                    }
                    <tr>
                      <td>
                        <label>Requester memo</label>
                      </td>
                      <td>
                        <label>{changeClaimInfo.userMemo}</label>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <label>Request</label>
                      </td>
                      <td>
                        <label>
                                {
                                changeClaimInfo.type === 'NotMycase' ? "Not my case"
                                :
                                changeClaimInfo.type === 'Connect' ? "Connect"
                                :
                                "Connect : Connected with another account"
                                }
                        </label>
                      </td>
                    </tr>
                    {
                    changeClaimInfo.type === 'NotMycase' ?
                    <>
                    <tr>
                      <td>
                        <label>New domain</label>
                      </td>
                      <td>
                        <input
                          id="newDomain"
                          className="form-control"
                          type="text"
                          placeholder="Enter new domain"
                          onChange={(e) => {
                            if (e.target.value.indexOf("/") > -1) {
                              setAlertMsg("Domain cannot include slash('/').");
                              return;
                            } else if (e.target.value.indexOf(",") > -1) {
                              setAlertMsg("Domain cannot include comma(',').");
                              return;
                            } else {
                              let _claimInfo = cloneDeep(changeClaimInfo);
                              _claimInfo.newDomain = e.target.value;
                              setChangeClaimInfo(_claimInfo);
                            }
                          }}
                          value={changeClaimInfo.newDomain}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <label>New URL</label>
                      </td>
                      <td>
                        <input
                          id="newUrl"
                          className="form-control"
                          type="text"
                          placeholder="Enter new url"
                          onChange={(e) => {
                            if (e.target.value.indexOf(",") > -1) {
                              setAlertMsg("URL cannot include comma(',').");
                              return;
                            } else {
                              let _claimInfo = cloneDeep(changeClaimInfo);
                              _claimInfo.newUrl = e.target.value;
                              setChangeClaimInfo(_claimInfo);
                            }
                          }}
                          value={changeClaimInfo.newUrl}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <label>Select detailed address</label>
                      </td>
                      <td>
                      {
                        targetGroupDetailList && targetGroupDetailList.length > 0 ?
                        targetGroupDetailList.map((targetGroupDetail, index)=>{
                          return (
                            <Row className={targetGroupDetailList.length-1 === index ? "" : "mb-3"} style={{marginLeft: "-30px"}}>
                              <div>
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="rdotargetGroupDetail"
                                  id={"targetGroupDetail_" + index}
                                  onClick={(e) => {
                                    // console.log('targetGroupDetail.seq : ' + targetGroupDetail.seq);
                                    let _claimInfo = cloneDeep(changeClaimInfo);
                                    _claimInfo.requestedTargetGroupDetailSeq = targetGroupDetail.seq;
                                    setChangeClaimInfo(_claimInfo);
                                  }}
                                  value={targetGroupDetail.seq}
                                  checked={changeClaimInfo.requestedTargetGroupDetailSeq === targetGroupDetail.seq}
                                  style={{marginLeft: "20px"}}
                                />
                                &nbsp;
                                <label
                                  className="form-check-label"
                                  htmlFor={"targetGroupDetail_" + index}
                                >
                                  [{targetGroupDetail.country}] {targetGroupDetail.address}
                                </label>
                              </div>
                            </Row>
                          )
                        })
                        :
                        null
                      }
                      </td>
                    </tr>
                    </>
                    : null}
                    <tr>
                      <td>
                        <label>Admin Memo</label>
                      </td>
                      <td>
                        <input
                          id="claimMemo"
                          className="form-control"
                          type="text"
                          placeholder="Enter memo"
                          onChange={(e) => {
                            let _claimInfo = cloneDeep(changeClaimInfo);
                            _claimInfo.adminMemo = e.target.value;
                            setChangeClaimInfo(_claimInfo);
                          }}
                          value={changeClaimInfo.adminMemo}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <label>Admin action</label>
                      </td>
                      <td>
                        <label>{changeClaimInfo.status}</label>
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </div>
              <div className="d-flex horizon-align-center gap-4" style={{marginTop: "10px", marginBottom: "10px"}}>
                <button
                    type="button"
                    onClick={() => {
                      setChangeClaimInfo({userNo:0});
                      // filterValidation.setFieldValue("adminMemo", "");
                    }}
                    className="btn btn-lg btn-danger waves-effect"
                    data-dismiss="modal"
                >
                    Cancel
                </button>
                <button
                    type="button"
                    className="btn btn-lg btn-success waves-effect waves-light"
                    onClick={()=> {
                      if (changeClaimInfo.type === 'NotMycase' && (!changeClaimInfo.requestedTargetGroupDetailSeq || changeClaimInfo.requestedTargetGroupDetailSeq == 0)) {
                        setAlertMsg("Select detailed address.");
                      } else {
                        filterValidation.setFieldValue("submitType", "confirm");
                        filterValidation.handleSubmit(filterValidation.values);
                      }
                    }}
                >
                    Confirm
                </button>
              </div>
            </Modal>
          </div>
        </div>
      </Form>
    </React.Fragment>
  )
}

export default AdmMyCaseManagement;