export const GET_CONNECTABLE_ENTITY_LIST = "GET_CONNECTABLE_ENTITY_LIST"
export const GET_CONNECTABLE_ENTITY_LIST_SUCCESS = "GET_CONNECTABLE_ENTITY_LIST_SUCCESS"
export const GET_MY_ENTITY_LIST = "GET_MY_ENTITY_LIST"
export const GET_MY_ENTITY_LIST_SUCCESS = "GET_MY_ENTITY_LIST_SUCCESS"
export const GET_MAIN_DOMAIN = "GET_MAIN_DOMAIN"
export const GET_MAIN_DOMAIN_SUCCESS = "GET_MAIN_DOMAIN_SUCCESS"
export const GET_SEARCH_ENTITY_LIST = "GET_SEARCH_ENTITY_LIST"
export const GET_SEARCH_ENTITY_LIST_SUCCESS = "GET_SEARCH_ENTITY_LIST_SUCCESS"
export const GET_USER_ENTITY_CONNECT_LIST = "GET_USER_ENTITY_CONNECT_LIST"
export const GET_USER_ENTITY_CONNECT_LIST_SUCCESS = "GET_USER_ENTITY_CONNECT_LIST_SUCCESS"
export const DOWN_EXCEL_USER_ENTITY_CONNECT_LIST = "DOWN_EXCEL_USER_ENTITY_CONNECT_LIST"
export const DOWN_EXCEL_USER_ENTITY_CONNECT_LIST_SUCCESS = "DOWN_EXCEL_USER_ENTITY_CONNECT_LIST_SUCCESS"
export const CHANGE_USER_ENTITY_CONNECT_LIST = "CHANGE_USER_ENTITY_CONNECT_LIST"
export const CONFIRM_USER_ENTITY_CONNECT = "CONFIRM_USER_ENTITY_CONNECT"
export const CONFIRM_USER_ENTITY_CONNECT_SUCCESS = "CONFIRM_USER_ENTITY_CONNECT_SUCCESS"
export const DISCONNECT_USER_ENTITY = "DISCONNECT_USER_ENTITY"
export const DISCONNECT_USER_ENTITY_SUCCESS = "DISCONNECT_USER_ENTITY_SUCCESS"
export const GET_USER_ENTITY_CONNECT_CLAIM_LIST = "GET_USER_ENTITY_CONNECT_CLAIM_LIST"
export const GET_USER_ENTITY_CONNECT_CLAIM_LIST_SUCCESS = "GET_USER_ENTITY_CONNECT_CLAIM_LIST_SUCCESS"
export const DOWN_EXCEL_USER_ENTITY_CONNECT_CLAIM_LIST = "DOWN_EXCEL_USER_ENTITY_CONNECT_CLAIM_LIST"
export const DOWN_EXCEL_USER_ENTITY_CONNECT_CLAIM_LIST_SUCCESS = "DOWN_EXCEL_USER_ENTITY_CONNECT_CLAIM_LIST_SUCCESS"
export const ADD_USER_ENTITY_CONNECT_CLAIM = "ADD_USER_ENTITY_CONNECT_CLAIM"
export const ADD_USER_ENTITY_CONNECT_CLAIM_SUCCESS = "ADD_USER_ENTITY_CONNECT_CLAIM_SUCCESS"
export const MODIFY_USER_ENTITY_CONNECT_CLAIM_STATUS = "MODIFY_USER_ENTITY_CONNECT_CLAIM_STATUS"
export const MODIFY_USER_ENTITY_CONNECT_CLAIM_STATUS_SUCCESS = "MODIFY_USER_ENTITY_CONNECT_CLAIM_STATUS_SUCCESS"
export const CONFIRM_USER_ENTITY_CONNECT_CLAIM = "GET_BCONFIRM_USER_ENTITY_CONNECT_CLAIMLACK_LIST"
export const CONFIRM_USER_ENTITY_CONNECT_CLAIM_SUCCESS = "CONFIRM_USER_ENTITY_CONNECT_CLAIM_SUCCESS"
export const CHANGE_USER_ENTITY_CONNECT_CLAIM_LIST = "CHANGE_USER_ENTITY_CONNECT_CLAIM_LIST"
export const SET_SUCCESS = "SET_SUCCESS"
export const SET_RESULT = "SET_RESULT"
export const SET_LOADING = "SET_LOADING"
export const GET_REQUESTER_LIST = "GET_REQUESTER_LIST"
export const GET_REQUESTER_LIST_SUCCESS = "GET_REQUESTER_LIST_SUCCESS"
export const CHANGE_REQUESTER_LIST = "CHANGE_REQUESTER_LIST"
export const GET_USER_DOMAIN_LIST = "GET_USER_DOMAIN_LIST"
export const GET_USER_DOMAIN_LIST_SUCCESS = "GET_USER_DOMAIN_LIST_SUCCESS"
export const ADD_USER_DOMAIN = "ADD_USER_DOMAIN"
export const ADD_USER_DOMAIN_SUCCESS = "ADD_USER_DOMAIN_SUCCESS"
export const MODIFY_USER_DOMAIN = "MODIFY_USER_DOMAIN"
export const MODIFY_USER_DOMAIN_SUCCESS = "MODIFY_USER_DOMAIN_SUCCESS"
export const REMOVE_USER_DOMAIN = "REMOVE_USER_DOMAIN"
export const REMOVE_USER_DOMAIN_SUCCESS = "REMOVE_USER_DOMAIN_SUCCESS"
export const CHANGE_USER_DOMAIN_LIST = "CHANGE_USER_DOMAIN_LIST"