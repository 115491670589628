import React, {useState, useEffect, useRef, useCallback} from 'react';
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";
import {
    Table,
    Row,
    Col,
    Card,
    CardBody,
    CardTitle,
    Media,
    Collapse,
    Form,
    Input,
    FormGroup,
    Button,
    FormFeedback,
    UncontrolledTooltip,
    Modal,
  } from "reactstrap"
import SweetAlert from "react-bootstrap-sweetalert";
import LoadingModal from "../../components/Common/LoadingModal"
import EditorModal from "../../components/Common/EditorModal"
import SelectMultipleCountry from '../../components/Filter/SelectMultipleCountry';
import SelectMultipleJurisdictions from '../../components/Filter/SelectMultipleJurisdictions';
import PeriodBtn from '../../components/Filter/PeriodBtn';
import ContactsDetails from '../Contacts/ViewDetail';
// import UserSignatureSavePopup from '../../components/Common/UserSignatureSavePopup';
import { Link } from "react-router-dom";
import * as Yup from "yup";
import { useFormik } from "formik";

import {
  getFilerSettings as onGetFilerSettings,
  saveFilerSettings as onSaveFilerSettings,
  setSuccess as onSetSuccess,
  setResult as onSetResult,
  getFilerRankings as onGetFilerRankings,
  getFilerRankingInfo as onGetFilerRankingInfo,
  changefilerRankings as onChangefilerRankings,
  multipleHideFiler as onMultipleHideFiler,
  multipleUnhideFiler as onMultipleUnhideFiler,
  multipleStarFiler as onMultipleStarFiler,
  multipleUnstarFiler as onMultipleUnstarFiler,
  downFilerExcel as onDownFilerExcel,
  downMultipleFilerExcel as onDownMultipleFilerExcel,
  downFilerPdf as onDownFilerPdf,
  downMultipleFilerPdf as onDownMultipleFilerPdf,
  downFilerPdfDataOnly as onDownFilerPdfDataOnly,
  filerSendEmail as onFilerSendEmail,
  filerPreviewEmail as onFilerPreviewEmail,
  setPreviewEmail as onSetPreviewEmail,
  clearFilerRankings as onClearFilerRankings,
  unlockFiler as onUnlockFiler,
  setMadridSettings as onSetMadridSettings,
  setSearchNameList as onSetSearchNameList,
  setFilerLoading as onSetFilerLoading,
  starFilerTarget as onStarFilerTarget,
  unStarFilerTarget as onUnStarFilerTarget,
  hideFilerTarget as onHideFilerTarget,
  unHideFilerTarget as onUnHideFilerTarget,
} from "../../store/actions";
import { cloneDeep } from 'lodash';
import '../../components/Common/Common.css';
import dateFormat from 'dateformat';
import toastr from "toastr"
import "toastr/build/toastr.min.css"

const FilerRankings = ({history}) => {
  const dispatch = useDispatch();

  const { filerRankings, searchSettings, loading, success, result, noticeStr, totalPage, previewEmail, unlockCredit, unlockCnt, freeMemberAutosearch, userGrade, unsubsInfo } = useSelector(state => ({
    filerRankings: state.FilerRankings.filerRankings,
    searchSettings: state.FilerRankings.searchSettings,
    loading: state.FilerRankings.loading,
    success: state.FilerRankings.success,
    result: state.FilerRankings.result,
    noticeStr: state.FilerRankings.noticeStr,
    totalPage: state.FilerRankings.totalPage,
    previewEmail: state.FilerRankings.previewEmail,
    unlockCredit: state.Unlock.unlockCredit,
    unlockCnt: state.Unlock.unlockCnt,
    freeMemberAutosearch: state.FilerRankings.freeMemberAutosearch,
    userGrade: state.FilerRankings.userGrade,
    unsubsInfo: state.FilerRankings.unsubsInfo,
  }));

  const [isOpen, setIsOpen] = useState(true);
  const [modal_fullscreen, setmodal_fullscreen] = useState(false);
  const [contactsTargetGroupNo, setContactsTargetGroupNo] = useState(-1);
  function togContactsScreen(targetGroupNo) {
    if (targetGroupNo) {
      setContactsTargetGroupNo(targetGroupNo);
      // addBodyCss();
    } else {
      const _filerRankings = [];
      filerRankings.map((row, index) => {
        if(row.targetGroupNo === contactsTargetGroupNo && _filerRankings.length === 0){
          _filerRankings.push(row);
        }
      });
    
      dispatch(onGetFilerRankingInfo(
        {rankingList:_filerRankings, 
          crType:filterValidation.values.crType, 
          crCdList:filterValidation.values.crCdList, 
          jurType:filterValidation.values.jurType, 
          jrCdList:filterValidation.values.jrCdList, 
          inOutType:filterValidation.values.inOutType,
          rdoRiHi:filterValidation.values.rdoRiHi, 
          entryCnt:filterValidation.values.entryCnt, 
          startDate:filterValidation.values.fromDate, 
          endDate:filterValidation.values.toDate}
      ));

      // filterValidation.setFieldValue("submitType", "view");
      // filterValidation.handleSubmit(filterValidation.values);
      // removeBodyCss();
    }
    setmodal_fullscreen(!modal_fullscreen);
    
  }

  const setFilerLoading = () => {
    dispatch(onSetFilerLoading(!loading));
  }

  const [chkAllFlag, setChkAllFlag] = useState(false);
  const [hideOrUnhide, setHideOrUnhide] = useState("Hide");
  const [starOrUnstar, setStarOrUnstar] = useState("Star");
  const [columns, setColumns] = useState([
    {label: "Number of\nprovisional refusals", asc:"NRA", desc:"NRD", ascActive:"", descActive:"text-danger"},
    {label: "Name", asc:"FNA", desc:"FND", ascActive:"", descActive:""},
    {label: "Country", asc:"FCA", desc:"FCD", ascActive:"", descActive:""},
    {label: "Type", asc:null, desc:null, ascActive:null, descActive:null},
    {label: "Send Count", asc:"SCA", desc:"SCD", ascActive:"", descActive:""},
    {label: "Last contact date", asc:"LCA", desc:"LCD", ascActive:"", descActive:""},
    {label: "Email functions", asc:null, desc:null, ascActive:"", descActive:""},    
  ]);
  const [submitType, setSubmitType] = useState("");
  const [confirmMsg, setConfirmMsg] = useState("");
  const [confirmAlert, setConfirmAlert] = useState(false);
  const [confirmBtnText, setConfirmBtnText] = useState("");
  const [cancelBtnText, setCancelBtnText] = useState("");
  const [curPageInput, setCurPageInput] = useState(1);
  const [alertMsg, setAlertMsg] = useState("");

  /**
   * errMsg[0] : smtp server 정보 없음.
   * errMsg[1] : 선택된 case 없음.
   * errMsg[2] : 이메일 제목 혹은 내용이 비어있음.
   */
  const errMsg = [
      <span>You are one small step away from being able to use the Filer Rankings. Please complete the setup process by entering your email settings <a href='/settings#subscriptionSettings'>here</a>. Once you have completed this step, you will be able to send emails using the Filer Rankings.</span>
    , "Please select a case."
    , <span>Your email is blank. Please edit your email or select a template in your <a href='/settings#emailTemplateSetting'>Settings.</a></span>
    , "No remaining credits"
    , "Not enough credits remaining"
    , "Your emails were not sent to the following email addresses, because they have been unsubscribed:"
  ];

  let emailSentBgColor = "#ffa50069";
  let hiddenBgColor = "#ff000040";

  useEffect(() => {
    window.addEventListener('click', handleCloseFilerModal);
    dispatch(onGetFilerSettings());

    return () => {
      // dispatch(onClearFilerRankings());
      window.removeEventListener('click', handleCloseFilerModal);
    };
  }, []);

  const toggle = () => {
    setIsOpen(!isOpen);
  }
  /*
  function tog_xlarge() {
    setModal_xlarge(!modal_xlarge)
    addBodyCss()
  }*/

  // function addBodyCss() {
  //   document.body.classList.add("no_padding")
  // }

  const filterValidation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      submitType: "view",
      crType: searchSettings && searchSettings.crType? searchSettings.crType : "",
      crCdList: searchSettings && searchSettings.crCdList? searchSettings.crCdList : [],
      jurType: searchSettings && searchSettings.jurType? searchSettings.jurType : "",
      jrCdList: searchSettings && searchSettings.jurList? searchSettings.jurList : [],
      datePeriod: searchSettings && searchSettings.datePeriod? searchSettings.datePeriod : "",
      fromDate: undefined,
      toDate: undefined,
      inOutType: searchSettings && searchSettings.inOutType? searchSettings.inOutType : "IN",
      rdoRiHi: searchSettings && searchSettings.type? searchSettings.type : "RO",
      addressContain: searchSettings && searchSettings.address? searchSettings.address : "",
      entityStatus: searchSettings && searchSettings.entityStatus? searchSettings.entityStatus : "",
      entryCnt: searchSettings && searchSettings.numberOfEntries? searchSettings.numberOfEntries : userGrade !== "00" ? "20" : "10",
      screenGrade: "Gold",
      sortType: "NRD",
      perPage: 50,
      curPage: 1,
      screenName: "FilerRankings",
    },
    validationSchema: Yup.object().shape({
      fromDate: Yup.date()
        .max(Yup.ref('toDate'), "From date must be earlier than to date")
        .required("Please Select From date"),
      toDate: Yup.date()
        .max(new Date(), "Date cannot be in the future")
        .required("Please Select to date"),
    }),
    validateOnChange: false, // this one
    validateOnBlur: false, // and this one
    onSubmit: (values) => {
      // console.log('onSubmit >> ' + JSON.stringify(values, null, 2));
      
      let _fromDateStr = values["fromDate"].replace(/-/gi,"/");
      let _toDateStr = values["toDate"].replace(/-/gi,"/");
      let newEndDate = new Date(values["toDate"]);
      newEndDate.setDate(newEndDate.getDate() + 1);
      
      let period = Math.abs(newEndDate.getTime() - new Date(values["fromDate"]).getTime());
      period = Math.ceil(period / (1000 * 3600 * 24));
      
      let _periodstr = period +" day(s)";
      let _noticeStr = "Filer Rankings for period: \n"+_periodstr+" ["+_fromDateStr+" to "+_toDateStr+"]";
      let _timePeriod = _fromDateStr+" to "+_toDateStr+" ["+period +" day(s)]";

      if (values["crType"] === 'Individual' && (values["crCdList"] === '' || values["crCdList"].length === 0)) {
        if (values["inOutType"] === 'OUT') {
          values["crType"] = 'All-subs';
        } else {
          values["crType"] = 'All';
        }
        
        // console.log('values.crType Individual to >> ' + values["crType"]);
      }

      if (values["jurType"] === 'Individual' && (values["jrCdList"] === '' || values["jrCdList"].length === 0)) {
        if (values["inOutType"] === 'IN') {
          values["jurType"] = 'All-subs';
        } else {
          values["jurType"] = 'All';
        }
        
        // console.log('values.jurType Individual to >> ' + values["jurType"]);
      }

      const filterParam = {
        inOutType: values["inOutType"],
        rdoRiHi: values["rdoRiHi"],
        crType: values["crType"],
        crCdList: values["crType"] === "All" || values["crType"] === "All-subs"? [] : values["crCdList"],
        jurType: values["jurType"],
        jrCdList: values["jurType"] === "All" || values["jurType"] === "All-subs"? [] : values["jrCdList"],
        startDate: values["fromDate"],
        endDate: values["toDate"],
        addressContain: values["addressContain"].trim(),
        entityStatus: values["entityStatus"],
        entryCnt: values["entryCnt"],
        sortType: values["sortType"],
        perPage: values["perPage"],
        curPage: values["curPage"],
        noticeStr: _noticeStr,
        timePeriod: _timePeriod,
      };
      
      let fileName = dateFormat(new Date(), "yymmdd") + " WeCrest Filer Rankings (" + values["fromDate"].replace(/-/gi,"") + "-" + values["toDate"].replace(/-/gi,"") + ")"; 
      
      let submitType = values["submitType"];
      
      if(submitType == "view"){
        dispatch(onGetFilerRankings(filterParam));
        
      }else if(submitType == "excel"){
        dispatch(onDownFilerExcel(filterParam, fileName));

      }else if(submitType == "pdf"){
        dispatch(onDownFilerPdf(filterParam, fileName));

      }else if(submitType == "pdfDataOnly"){
        dispatch(onDownFilerPdfDataOnly(filterParam, fileName));

      }else if(submitType == "saveSettings"){
        const settingsParam = {
          inOutType: values["inOutType"],
          type: values["rdoRiHi"],
          crType: values["crType"],
          crCdListToStr: values["crType"] === "Individual"? values["crCdList"].join(", ") : "",
          jurType: values["jurType"],
          jurListToStr: values["jurType"] === "Individual"? values["jrCdList"].join(", ") : "",
          datePeriod: values["datePeriod"],
          address: values["addressContain"],
          entityStatus: values["entityStatus"],
          numberOfEntries: values["entryCnt"],
        };

        dispatch(onSaveFilerSettings(settingsParam));

      }else if(submitType == "Hide"){
        const _filerRankings = [];
        filerRankings.map((row, index) =>{
          if(row.checked === true && row.hideYn === 'N'){
            _filerRankings.push(row);
          }
        }); 
    
        dispatch(onMultipleHideFiler(_filerRankings, filterParam));

      }else if(submitType == "Unhide"){
        const _filerRankings = [];
        filerRankings.map((row, index) =>{
          if(row.checked === true && row.hideYn === 'Y'){
            _filerRankings.push(row);
          }
        }); 

        dispatch(onMultipleUnhideFiler(_filerRankings, filterParam));
      }else if(submitType == "Star"){
        const _filerRankings = [];
        filerRankings.map((row, index) =>{
          if(row.checked === true && row.starYn === 'N'){
            _filerRankings.push(row);
          }
        }); 
    
        dispatch(onMultipleStarFiler(_filerRankings, filterParam));

      }else if(submitType == "Unstar"){
        const _filerRankings = [];
        filerRankings.map((row, index) =>{
          if(row.checked === true && row.starYn === 'Y'){
            _filerRankings.push(row);
          }
        }); 

        dispatch(onMultipleUnstarFiler(_filerRankings, filterParam));

      }else if(submitType == "multipleExcel"){
        const _filerRankings = [];
        filerRankings.map((row, index) =>{
          if(row.checked === true){
            _filerRankings.push(row);
          }
        });
        
        dispatch(onDownMultipleFilerExcel(_filerRankings, noticeStr, fileName));

      }else if(submitType === "multiplePdf"){
        const _filerRankings = [];
        filerRankings.map((row, index) =>{
          if(row.checked === true){
            _filerRankings.push(row);
          }
        });

        const param = {
          filerRankingsList: _filerRankings,
          inOutType: values["inOutType"],
          type: values["rdoRiHi"] === "RO"? "Representatives" : "Holders",
          crCdList: values["crType"] === "All" || values["crType"] === "All-subs"? [] : values["crCdList"],
          jrCdList: values["jurType"] === "All" || values["jurType"] === "All-subs"? [] : values["jrCdList"],
          addressContain: values["addressContain"],
          timePeriod: _timePeriod,
        }
        
        dispatch(onDownMultipleFilerPdf(param, fileName));

      }else if(submitType == "multipleSend"){
        const _filerRankings = [];
        filerRankings.map((row, index) =>{
          if(row.checked === true){
            _filerRankings.push(row);
          }
        });

        let period = getPeriod();
        dispatch(onFilerSendEmail(_filerRankings, period, filterParam));

      }else if(submitType.indexOf("send_") === 0){
        let idx = submitType.replace("send_", "");
        const _filerRankings = [];
        _filerRankings.push(filerRankings[idx]);

        let period = getPeriod();
        dispatch(onFilerSendEmail(_filerRankings, period, filterParam));

      }else if(submitType.indexOf("preview_") === 0){
        let idx = submitType.replace("preview_", "");
        const _filerRankings = [];
        _filerRankings.push(filerRankings[idx]);

        let period = getPeriod();
        dispatch(onFilerPreviewEmail(_filerRankings, period));

      }else if(submitType === "multipleUnlock"){
        const _filerRankings = [];
        filerRankings.map((row, index) =>{
          if(row.checked && row.unlockYn === 'N'){
            _filerRankings.push(row);
          }
        });

        if (_filerRankings.length > 0) {
          dispatch(onUnlockFiler(_filerRankings, filterParam));
        }
      } else if (submitType.indexOf("unlock_") === 0) {
        let idx = submitType.replace("unlock_", "");
        const _filerRankings = [];
        _filerRankings.push(filerRankings[idx]);

        dispatch(onUnlockFiler(_filerRankings, filterParam));
      } else if (submitType.indexOf("goMadrid_") === 0){
        let targetGroupNo = submitType.substring(submitType.indexOf("_")+1, submitType.lastIndexOf("_"));
        let targetGroupName = submitType.substring(submitType.lastIndexOf("_")+1, submitType.length);

        let madridFilterParam = {
          inOutType: values["inOutType"],
          jurType: values["jurType"],
          jurList: values["jurType"] === "Individual"? values["jrCdList"] : [],
          groupNoList: [targetGroupNo],
          irNumber: "",
          datePeriod: values["datePeriod"],
          startDate: values["fromDate"],
          endDate: values["toDate"],
          entityStatus: "",
          emailSent: "BOTH",
        };

        if(values["rdoRiHi"] == "RO"){
          madridFilterParam.repType = values["crType"];
          madridFilterParam.repCdList = values["crType"] === "Individual"? values["crCdList"] : [];
          madridFilterParam.hasRep = "Y";
        }else {
          madridFilterParam.holderType = values["crType"];
          madridFilterParam.holderCdList = values["crType"] === "Individual"? values["crCdList"] : [];
          madridFilterParam.hasRep = "BOTH";
        }

        let searchNameList = [{no: targetGroupNo, name: targetGroupName, checked: true}];

        localStorage.setItem("madridFilterParam", JSON.stringify(madridFilterParam))
        localStorage.setItem("searchNameList", JSON.stringify(searchNameList))

        // dispatch(onSetMadridSettings(madridFilterParam, searchNameList));
        // dispatch(onSetSearchNameList(searchNameList));
        // console.log('1.' + JSON.stringify(madridFilterParam));
        // console.log('1.' + JSON.stringify(searchNameList));
        // history.push("/madridNotifier");
        window.open("/madridNotifier", "_blank");
      } else if (submitType.indexOf("star_target_") === 0) {
        let targetGroupNo = submitType.replace("star_target_", "");

        dispatch(onStarFilerTarget(targetGroupNo, filterParam));
      } else if (submitType.indexOf("unStar_target_") === 0) {
        let targetGroupNo = submitType.replace("unStar_target_", "");
        
        dispatch(onUnStarFilerTarget(targetGroupNo, filterParam));
      } else if (submitType.indexOf("hide_target_") === 0) {
        let targetGroupNo = submitType.replace("hide_target_", "");
        
        dispatch(onHideFilerTarget(targetGroupNo, filterParam));
      } else if (submitType.indexOf("unHide_target_") === 0) {
        let targetGroupNo = submitType.replace("unHide_target_", "");
        
        dispatch(onUnHideFilerTarget(targetGroupNo, filterParam));
      }
    },
  });

  const viewFilerRankings = (sortType, curPage, perPage) => {
    //curPage 유효성 체크
    // console.log("viewFilerRankings curPage: "+curPage + ', totalPage : ' + totalPage);

    if(curPage){
      if(curPage > totalPage) {
        curPage = totalPage;
      } else if (curPage <= 0) {
        curPage = 1;
      }
    }
    //빨간색으로 변경
    if(sortType){
      const _columns = [];
      columns.map((col, index)=>{
        let _col = cloneDeep(col);
        if(col.asc && col.asc === sortType){
          _col.ascActive = "text-danger";
          _col.descActive = "";
        }else if(col.desc && col.desc === sortType ){
          _col.ascActive = "";
          _col.descActive = "text-danger";
        }else{
          _col.ascActive = "";
          _col.descActive = "";
        }
        _columns.push(_col);
      });

      setColumns(_columns);
    }

    // console.log("viewFilerRankings [sortType: "+sortType+", curPage: "+curPage+", perPage: "+perPage);
    // let _values = cloneDeep(filterValidation.values);
    // _values.submitType = "view";
    filterValidation.setFieldValue("submitType", "view");
    if(sortType){
      // _values.sortType = sortType;
      filterValidation.setFieldValue("sortType", sortType);
    }
    if(curPage){
      // _values.curPage = curPage;
      filterValidation.setFieldValue("curPage", curPage);
      setCurPageInput(curPage);
    }else{
      // _values.curPage = 1;
      filterValidation.setFieldValue("curPage", 1);
      setCurPageInput(1);
    }
    if(perPage){
      // _values.perPage = perPage;
      filterValidation.setFieldValue("perPage", perPage);
    }
    // filterValidation.setValues(_values);
    filterValidation.handleSubmit(filterValidation.values);
  }

  const rowCheck = (str, checked) => {
    let chkCnt = 0;
    var hiddenCaseCnt = 0;
    var starCaseCnt = 0;

    const _filerRankings = [];
    filerRankings.map((row, index) =>{
        let _row = cloneDeep(row);

        if(str === "chkAll" || str === index){
            _row.checked = checked;
        }

        if(_row.checked === true){
          chkCnt++;
          if(row.hideYn === 'Y'){
            hiddenCaseCnt++;
          }
          if(row.starYn === 'Y'){
            starCaseCnt++;
          }
        }

        _filerRankings.push(_row);
    }); 

    if(chkCnt !== 0 && chkCnt === hiddenCaseCnt){
      setHideOrUnhide("Unhide");
    }else{
      setHideOrUnhide("Hide");
    }

    if(chkCnt !== 0 && chkCnt === starCaseCnt){
      setStarOrUnstar("Unstar");
    }else{
      setStarOrUnstar("Star");
    }

    if(filerRankings.length === chkCnt){
        setChkAllFlag(true);
    }else{
        setChkAllFlag(false);
    }

    dispatch(onChangefilerRankings(_filerRankings));
  }

  const caseChk = () => {
    const _filerRankings = [];
    filerRankings.map((row, index) =>{
      if(row.checked === true){
        _filerRankings.push(row);
      }
    }); 

    if(_filerRankings.length === 0){
      dispatch(onSetResult(1));
      return false;
    }

    return true;
  }

  const getPeriod = () => {
    let searchStr = "ranking for period: ";
    let searchIdx = noticeStr.toLowerCase().indexOf(searchStr) + searchStr.length
    let period = noticeStr.substr(searchIdx).replace("\n", "");

    return period;
  }

  useEffect(() => {
    // console.log("freeMemberAutosearch : "+freeMemberAutosearch);

    if (freeMemberAutosearch == 'Y') {
      setTimeout(function(){ 
        // console.log("freeMemberAutosearch handleSubmit : "+freeMemberAutosearch);
        toggle();
        filterValidation.setFieldValue("submitType", "view");
        filterValidation.handleSubmit(filterValidation.values);
      }, 100);  
    }
    
  },[filterValidation.values.toDate])

  // [S] Editor
  const [isOpenEditor, setIsOpenEditor] = useState(false);
  const [isInitEditor, setIsInitEditor] = useState(false);
  const [loadedEditor, setLoadedEditor] = useState(false);
  const [targetGroupNo, setTargetGroupNo] = useState(-1);
  const [inOutType, setInOutType] = useState('');
  const [editedTemplateYn, setEditedTemplateYn] = useState('N');
  
  function openEditor(targetGroupNo, inOutType, targetEmlTmpSetYn) {
    if (!isInitEditor) {
      // console.log('editor init start')
      setIsInitEditor(true)
    } else {
      // console.log('editor inited')
    }

    localStorage.setItem("filerReloadParam", 
      JSON.stringify({targetGroupNo:targetGroupNo, 
        crType:filterValidation.values.crType, 
        crCdList:filterValidation.values.crCdList, 
        jurType:filterValidation.values.jurType, 
        jrCdList:filterValidation.values.jrCdList, 
        inOutType:filterValidation.values.inOutType,
        rdoRiHi:filterValidation.values.rdoRiHi, 
        entryCnt:filterValidation.values.entryCnt, 
        fromDate:filterValidation.values.fromDate, 
        toDate:filterValidation.values.toDate}))
    
    setTargetGroupNo(targetGroupNo);
    setInOutType(inOutType);
    setEditedTemplateYn(targetEmlTmpSetYn);
    setIsOpenEditor(true);
    addBodyCss();
  }

  function closeEditor() {
    const reloadParam = JSON.parse(localStorage.getItem("filerReloadParam"));
    // console.log('Filer closeEditor >> reloadParam : ' + JSON.stringify(reloadParam));
    dispatch(onGetFilerRankingInfo(
      {rankingList:[{targetGroupNo:reloadParam.targetGroupNo}], 
        crType:reloadParam.crType, 
        crCdList:reloadParam.crCdList, 
        jurType:reloadParam.jurType, 
        jrCdList:reloadParam.jrCdList, 
        inOutType:reloadParam.inOutType,
        rdoRiHi:reloadParam.rdoRiHi, 
        entryCnt:reloadParam.entryCnt, 
        startDate:reloadParam.fromDate, 
        endDate:reloadParam.toDate}
    ));
    // dispatch(onGetFilerRankingInfo({rankingList:[{targetGroupNo:reloadParam.targetGroupNo}], inOutType:reloadParam.inOutType, rdoRiHi:reloadParam.rdoRiHi, entryCnt:reloadParam.entryCnt, startDate:reloadParam.fromDate, endDate:reloadParam.toDate}));
    localStorage.removeItem("filerReloadParam");

    setIsOpenEditor(false);
    setTargetGroupNo(-1);
    if (editorModaRef.current) {
      editorModaRef.current.clearEditorContent();
    }
    
    removeBodyCss();
    
    /*
    filterValidation.setFieldValue("submitType", "view");
    filterValidation.handleSubmit(filterValidation.values);
    */
  }

  function removeBodyCss() {
    document.body.classList.remove("no_padding");
    document.body.classList.remove("modal-open");
    document.body.style.overflow = 'visible';
    document.body.style.paddingRight = '';
  }

  function addBodyCss() {
    document.body.classList.add("no_padding");
    document.body.classList.add("modal-open");
    document.body.style.overflow = 'hidden';
  }

  const editorModaRef = useRef(null);
  const editorModal = useRef(null);
  const handleCloseFilerModal = (e) => {
    if (e.target.id === 'EditorModal' && (!editorModal.current || !editorModal.current.contains(e.target))) {
      closeEditor()
    }
  }
  // [E] Editor

  function showSuccessCopyToast() {
    const ele = "success";
    const position = "toast-bottom-left"
    const title = ""
    let message = "Copy clipboard!"

    //Close Button
    const closeButton = false

    //Debug
    const debug = false

    //Progressbar
    const progressBar = false

    //Duplicates
    const preventDuplicates = false

    //Newest on Top
    const newestOnTop = false

    //position class
    let positionClass = "toast-bottom-left"

    //Show Easing
    const showEasing = "swing";

    //Hide Easing
    const hideEasing = "linear";

    //show method
    const showMethod = "fadeIn";

    //Hide method
    const hideMethod = "fadeOut";

    //show duration
    const showDuration = 300;

    //Hide duration
    const hideDuration = 1000;

    //timeout
    const timeOut = 1000;

    //extended timeout
    const extendedTimeOut = 1000;

    toastr.options = {
      positionClass: positionClass,
      timeOut: timeOut,
      extendedTimeOut: extendedTimeOut,
      closeButton: closeButton,
      debug: debug,
      progressBar: progressBar,
      preventDuplicates: preventDuplicates,
      newestOnTop: newestOnTop,
      showEasing: showEasing,
      hideEasing: hideEasing,
      showMethod: showMethod,
      hideMethod: hideMethod,
      showDuration: showDuration,
      hideDuration: hideDuration
    }

    // setTimeout(() => toastr.success(`Settings updated `), 300)
    //Toaster Types
    toastr.success(message, title)
  }

  const unsecuredCopyToClipboard = (text, elmentId) => {
    const textArea = document.createElement("textarea");
    textArea.setAttribute("type", "hidden");
    textArea.value = text;

    // const node = document.getElementById(elmentId).lastElementChild;
    document.getElementById(elmentId).appendChild(textArea);
    // document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();
    try {
      document.execCommand('copy');
    } catch (err) {
      console.error('Unable to copy to clipboard', err);
    }
    document.getElementById(elmentId).removeChild(textArea);
  }

  return (
    <React.Fragment>
      <Form onSubmit={filterValidation.handleSubmit}>
        <div className="page-content">
          <div className="container-fluid">
            <Card>
              <Link onClick={toggle} className="text-dark" to="#">
                <div className="p-4">
                  <Media className="d-flex align-items-center">
                    <div className="flex-shrink-0 me-3">
                      <i className="uil uil-medal text-primary h2"></i>
                    </div>
                    <div className="flex-grow-1 overflow-hidden">
                      <h5 className="font-size-16 mb-1">Filer Rankings</h5>
                      <p className="text-muted mb-0">Use this tool to generate rankings of filers (holders and/or representatives) of international<br/>
                        (Madrid) trademark applications that have been provisionally refused in your jurisdictions.</p>
                    </div>
                    <i className="uil uil-filter font-size-24 text-muted"></i>
                    {/* 
                    <i className={isOpen ? "mdi mdi-chevron-up accor-down-icon font-size-24" : "mdi mdi-chevron-down accor-down-icon font-size-24"}></i>
                    */}
                  </Media>
                </div>
              </Link>
              <Collapse isOpen={isOpen}>
                <div className="p-4 border-top">
                    <div>
                      <Row className="mb-3">
                        <div className="col-md-3 form-check" style={{paddingLeft : '2em'}}>
                          <input
                            className="form-check-input"
                            type="radio"
                            name="inOutType"
                            id="showInbound"
                            //onChange={filterValidation.handleChange}
                            onClick={()=>{
                              filterValidation.setFieldValue("inOutType", "IN");
                              if (searchSettings && searchSettings.inOutType && searchSettings.inOutType === "IN") {
                                filterValidation.setFieldValue("crCdList", searchSettings && searchSettings.crCdList? searchSettings.crCdList : []);
                                filterValidation.setFieldValue("jrCdList", searchSettings && searchSettings.jurList? searchSettings.jurList : []);
                              } else {
                                filterValidation.setFieldValue("crCdList", []);
                                filterValidation.setFieldValue("jrCdList", []);
                              }
                            }}
                            value="IN"
                            checked={filterValidation.values.inOutType === 'IN'}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="showInbound"
                          >
                            Inbound
                          </label>
                        </div>
                        <div className="col-md-3 form-check" style={{paddingLeft : '2em'}}>
                          <input
                            className="form-check-input"
                            type="radio"
                            name="inOutType"
                            id="showOutbound"
                            //onChange={filterValidation.handleChange}
                            onClick={()=>{
                              filterValidation.setFieldValue("inOutType", "OUT");
                              if (searchSettings && searchSettings.inOutType && searchSettings.inOutType === "OUT") {
                                filterValidation.setFieldValue("crCdList", searchSettings && searchSettings.crCdList? searchSettings.crCdList : []);
                                filterValidation.setFieldValue("jrCdList", searchSettings && searchSettings.jurList? searchSettings.jurList : []);
                              } else {
                                filterValidation.setFieldValue("crCdList", []);
                                filterValidation.setFieldValue("jrCdList", []);
                              }
                            }}
                            value="OUT"
                            checked={filterValidation.values.inOutType === 'OUT'}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="showOutbound"
                          >
                            Outbound
                          </label>
                        </div>
                      </Row>
                      <Row className="mb-3">
                        <div className="col-md-3 form-check" style={{paddingLeft : '2em'}}>
                          <input
                            className="form-check-input"
                            type="radio"
                            name="rdoRiHi"
                            id="repOnly"
                            onChange={filterValidation.handleChange}
                            value="RO"
                            checked={filterValidation.values.rdoRiHi === 'RO'}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="repOnly"
                          >
                            Representatives
                          </label>
                        </div>
                        <div className="col-md-3 form-check" style={{paddingLeft : '2em'}}>
                          <input
                            className="form-check-input"
                            type="radio"
                            name="rdoRiHi"
                            id="holderOnly"
                            onChange={filterValidation.handleChange}
                            value="HO"
                            checked={filterValidation.values.rdoRiHi === 'HO'}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="holderOnly"
                          >
                            Holders
                          </label>
                        </div>
                      </Row>

                      <SelectMultipleCountry
                        formik={filterValidation}
                      />

                      <SelectMultipleJurisdictions
                        formik={filterValidation}
                      />

                      <Row className="mb-3">
                        <label
                        htmlFor="addressContain"
                        className="col-md-3 col-form-label"
                        >
                        Address contains (e.g. name of city)
                        </label>
                        <div className="col-md-3">
                        <Input
                            className="form-control"
                            type="text"
                            placeholder="Enter exact text"
                            id="addressContain"
                            name="addressContain"
                            onChange={filterValidation.handleChange}
                            value={filterValidation.values.addressContain}
                            onKeyPress={(e)=>{
                              if (e.key === 'Enter') {
                                filterValidation.setFieldValue("submitType", "view");
                                filterValidation.handleSubmit(filterValidation.values);
                              }
                            }}
                        />
                        </div>
                      </Row>
                      <Row>
                        <label htmlFor="inscriptionDate" className="col-md-3 col-form-label">
                        Inscription date
                        </label>
                        <div className="col-md-9">
                          <PeriodBtn
                            formik={filterValidation}
                          />
                        </div>
                      </Row>
                      <Row className="mb-3">
                        <label htmlFor="entityStatus" className="col-md-3 col-form-label">
                          Entity status
                        </label>
                        <div className="col-md-2 form-check" style={{paddingLeft : '2em'}}>
                          <input type="radio" className="form-check-input"
                            id="onlyNonHidden"
                            name="entityStatus"
                            onChange={filterValidation.handleChange}
                            value="onlyNonHidden"
                            checked={filterValidation.values.entityStatus === "onlyNonHidden"}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="onlyNonHidden"
                          >
                            Only non-hidden
                          </label>
                        </div>
                        <div className="col-md-2 form-check" style={{paddingLeft : '2em'}}>
                          <input type="radio" className="form-check-input"
                            id="onlyStarred"
                            name="entityStatus"
                            onChange={filterValidation.handleChange}
                            value="onlyStarred"
                            checked={filterValidation.values.entityStatus === "onlyStarred"}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="onlyStarred"
                          >
                            Only Starred
                          </label>
                        </div>
                        <div className="col-md-2 form-check" style={{paddingLeft : '2em'}}>
                          <input type="radio" className="form-check-input"
                            id="onlyHidden"
                            name="entityStatus"
                            onChange={filterValidation.handleChange}
                            value="onlyHidden"
                            checked={filterValidation.values.entityStatus === "onlyHidden"}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="onlyHidden"
                          >
                            Only Hidden
                          </label>
                        </div>
                        <div className="col-md-2 form-check" style={{paddingLeft : '2em'}}>
                          <input type="radio" className="form-check-input"
                            id="entityStatusAll"
                            name="entityStatus"
                            onChange={filterValidation.handleChange}
                            value=""
                            checked={filterValidation.values.entityStatus === ""}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="entityStatusAll"
                          >
                            All
                          </label>
                        </div>
                      </Row>
                      <Row className="mb-3">
                        <label
                          htmlFor="entryCnt"
                          className="col-md-3 col-form-label"
                          >
                          Number of entries to include
                        </label>
                        <div className="col-md-2">
                          <select 
                            className="form-select" 
                            name="entryCnt"
                            onChange={filterValidation.handleChange}
                            value={filterValidation.values.entryCnt}
                          >
                            <option value="10">10</option>
                            {userGrade !== "00" ?
                            <>
                            <option value="20">20</option>
                            <option value="30">30</option>
                            <option value="40">40</option>
                            <option value="50">50</option>
                            <option value="60">60</option>
                            <option value="70">70</option>
                            <option value="80">80</option>
                            <option value="90">90</option>
                            <option value="100">100</option>
                            <option value="200">200</option>
                            <option value="300">300</option>
                            <option value="400">400</option>
                            <option value="500">500</option>
                            <option value="1000">1000</option>
                            </>
                            : null}
                          </select>
                        </div>
                      </Row>

                      <Row className="mb-3">
                        <span className="col-md-6 button-items">
                          <Button
                            type="button"
                            color="primary"
                            outline
                            className="waves-effect waves-light"
                            onClick={()=>{
                              // let _values = cloneDeep(filterValidation.values);
                              // _values.submitType = "saveSettings";
                              // filterValidation.setValues(_values);
                              filterValidation.setFieldValue("submitType", "saveSettings");
                              filterValidation.handleSubmit(filterValidation.values);
                            }}
                          >
                            Save settings
                          </Button>
                          {userGrade === "00" ?
                          <>
                            <Button
                              type="button"
                              color="disable"
                              outline
                              className="waves-effect waves-light"
                              id="btn_down_excel_1"
                            >
                              Download Excel
                            </Button>
                            <UncontrolledTooltip placement="top" target="btn_down_excel_1">
                            Contact us at global@wecrest.com to access all features.
                            </UncontrolledTooltip>
                            <Button
                              type="button"
                              color="disable"
                              outline
                              className="waves-effect waves-light"
                              id="btn_down_pdf_1"
                            >
                              Download PDF
                            </Button>
                            <UncontrolledTooltip placement="top" target="btn_down_pdf_1">
                            Contact us at global@wecrest.com to access all features.
                            </UncontrolledTooltip>
                            <Button
                              type="button"
                              color="disable"
                              outline
                              className="waves-effect waves-light"
                              id="btn_down_pdfh_1"
                            >
                              Download PDF w/o email history
                            </Button>
                            <UncontrolledTooltip placement="top" target="btn_down_pdfh_1">
                            Contact us at global@wecrest.com to access all features.
                            </UncontrolledTooltip>
                          </>
                          :
                          <>
                            <Button
                              type="button"
                              color="primary"
                              outline
                              className="waves-effect waves-light"
                              onClick={()=>{
                                // let _values = cloneDeep(filterValidation.values);
                                // _values.submitType = "excel";
                                // filterValidation.setValues(_values);
                                filterValidation.setFieldValue("submitType", "excel");
                                filterValidation.handleSubmit(filterValidation.values);
                              }}
                              id="btn_down_excel_1"
                            >
                              Download Excel
                            </Button>
                            <UncontrolledTooltip placement="top" target="btn_down_excel_1">
                            Download details of all entities based on the filter as an Excel file
                            </UncontrolledTooltip>
                            <Button
                              type="button"
                              color="primary"
                              outline
                              className="waves-effect waves-light"
                              onClick={()=>{
                                // let _values = cloneDeep(filterValidation.values);
                                // _values.submitType = "pdf";
                                // filterValidation.setValues(_values);
                                filterValidation.setFieldValue("submitType", "pdf");
                                filterValidation.handleSubmit(filterValidation.values);
                              }}
                              id="btn_down_pdf_1"
                            >
                              Download PDF
                            </Button>
                            <UncontrolledTooltip placement="top" target="btn_down_pdf_1">
                            Download details of all entities based on the filter as a PDF file
                            </UncontrolledTooltip>
                            <Button
                              type="button"
                              color="primary"
                              outline
                              className="waves-effect waves-light"
                              onClick={()=>{
                                // let _values = cloneDeep(filterValidation.values);
                                // _values.submitType = "pdfDataOnly";
                                // filterValidation.setValues(_values);
                                filterValidation.setFieldValue("submitType", "pdfDataOnly");
                                filterValidation.handleSubmit(filterValidation.values);
                              }}
                              id="btn_down_pdfh_1"
                            >
                              Download PDF w/o email history
                            </Button>
                            <UncontrolledTooltip placement="top" target="btn_down_pdfh_1">
                            Download details of all entities without email history based on the filter as a PDF file
                            </UncontrolledTooltip>
                          </>
                          }
                        </span>
                        <Col md={3} style={{paddingLeft : '1.3em'}}>
                          <button
                            type="button"
                            className="waves-effect waves-light btn btn-primary"
                            style={{width : '148.58px'}}
                            onClick={()=>{
                              // let _values = cloneDeep(filterValidation.values);
                              // _values.submitType = "view";
                              // filterValidation.setValues(_values);
                              setCurPageInput(1);
                              filterValidation.setFieldValue("curPage", 1);
                              filterValidation.setFieldValue("submitType", "view");
                              filterValidation.handleSubmit(filterValidation.values);
                            }}
                          >
                            View ranking
                          </button>
                        </Col>
                      </Row>
                      
                      
                    </div>
                </div>
              </Collapse>
            </Card>
            <Modal
              size="xl"
              style={{maxWidth : "1700px"}}
              isOpen={modal_fullscreen}
              toggle={() => {
                togContactsScreen()
              }}
            >
              <div className="modal-body">
                <button
                  onClick={() => {
                    togContactsScreen()
                  }}
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>

                <ContactsDetails targetGroupNo={contactsTargetGroupNo}/>
              </div>
            </Modal>
            {
              loading ?
              <LoadingModal loading={loading} setLoading={setFilerLoading}></LoadingModal>
              : null
            }
            {success? (
              <SweetAlert
                title={success}
                timeout={2000}
                showCloseButton={false}
                showConfirm={false}
                success
                onConfirm={() => {
                  dispatch(onSetSuccess(""));
                }}
              ></SweetAlert>
            ) : null}
            {result !== -1 ? (
            <SweetAlert
              title={result !== 5 ? errMsg[result] : null}
              onConfirm={() => {
                dispatch(onSetResult(-1));
              }}
            >{result === 5 ? <>{errMsg[result]}<br/>{unsubsInfo}</> : null}
            </SweetAlert>
            ) : null}
            {alertMsg !== '' ? (
            <SweetAlert
              onConfirm={() => {
                setAlertMsg('');
              }}
              onCancel={() => {
                setAlertMsg('');
              }}
            >{alertMsg}
            </SweetAlert>
            ) : null}
            {confirmAlert ? (
              <SweetAlert
                title="Are you sure?"
                warning
                showCancel
                confirmBtnText={confirmBtnText}
                cancelBtnText={cancelBtnText}
                confirmBtnBsStyle="success"
                cancelBtnBsStyle="danger"
                onConfirm={() => {
                  // let _values = cloneDeep(filterValidation.values);
                  // _values.submitType = submitType;
                  // filterValidation.setValues(_values);
                  filterValidation.setFieldValue("submitType", submitType);
                  filterValidation.handleSubmit(filterValidation.values);
                  setConfirmAlert(false);
                }}
                onCancel={() => setConfirmAlert(false)}
              >
                {confirmMsg}
              </SweetAlert>
              ) : null}
            {previewEmail && Object.keys(previewEmail).length !== 0? (
              <Modal
                size="xl"
                isOpen={true}
                toggle={() => {
                  dispatch(onSetPreviewEmail({}));
                }}
              >
                <div className="modal-header">
                  <h5
                    className="modal-title mt-0"
                    id="myExtraLargeModalLabel"
                  >
                    To&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;: {previewEmail.toEmail? previewEmail.toEmail : null}<br/>
                    CC&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;: {previewEmail.emailCC? previewEmail.emailCC : null}<br/>
                    BCC&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;: {previewEmail.emailBCC? previewEmail.emailBCC : null}<br/>
                    Subject: {previewEmail.subject? previewEmail.subject : null}
                  </h5>
                  <button
                    onClick={() => {
                      dispatch(onSetPreviewEmail({}));
                    }}
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body">
                  <Card className="mb-2">
                    <CardBody dangerouslySetInnerHTML={{__html:previewEmail.emailForm? previewEmail.emailForm : null}}></CardBody>
                  </Card>
                    {previewEmail.attachFiles && previewEmail.attachFiles.length?
                    <Card className="mb-0">
                      <CardBody className="p-1">
                      <Table className="table mb-0">
                        <tbody>
                      {previewEmail.attachFiles.map((attach, index)=>{
                        return (
                          <tr><td>{attach.fileName}</td></tr>
                        )})}
                        </tbody>
                      </Table>
                      </CardBody>
                    </Card>
                    :null}
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    onClick={() => {
                      dispatch(onSetPreviewEmail({}));
                    }}
                    className="btn btn-secondary waves-effect"
                    data-dismiss="modal"
                  >
                    Close
                  </button>
                </div>
              </Modal>
            ) : null}
            <EditorModal isOpenEditor={isOpenEditor} isInitEditor={isInitEditor} closeEditor={closeEditor} loadedEditor={loadedEditor} setLoadedEditor={setLoadedEditor} editorModal={editorModal} ref={editorModaRef}
                        emailType='FR' targetGroupNo={targetGroupNo} editedTemplateYn={editedTemplateYn} inOutType={inOutType} />
            {noticeStr && noticeStr !== '' ?
            <Row>
              <Col lg={12}>
                <Card>
                  <CardBody>
                      <CardTitle className="text-muted mb-0">
                        <p style={{whiteSpace : "pre"}}>
                          {noticeStr}
                        </p>
                          <Row className="mb-3">
                          <span className="col-md-8 button-items">
                            {/*
                            <Button
                              type="button"
                              color="primary"
                              outline
                              className="waves-effect waves-light"
                              onClick={() => {
                                if(caseChk()){
                                  setSubmitType("multipleUnlock");
                                  setConfirmMsg("Unlock to view the details and send emails for this case.");
                                  setConfirmBtnText("Confirm");
                                  setCancelBtnText("Cancel");
                                  setConfirmAlert(true);
                                }
                              }}
                            >
                              Unlock
                            </Button>
                            */}
                            <Button
                              type="button"
                              color="primary"
                              outline
                              className="waves-effect waves-light"
                              onClick={() => {
                                if(caseChk()){
                                  setSubmitType("multipleSend");
                                  setConfirmMsg("Emails will only be sent for entities, for which an email address has been entered and the email subject and body are not empty.");
                                  setConfirmBtnText("Yes, send!");
                                  setCancelBtnText("Cancel");
                                  setConfirmAlert(true);
                                }
                              }}
                              id="btn_send_1"
                            >
                              Send
                            </Button>
                            <UncontrolledTooltip placement="top" target="btn_send_1" className="domain-correct-tooltip">
                            Send emails for selected entities
                            </UncontrolledTooltip>
                            <Button
                              type="button"
                              color="primary"
                              outline
                              className="waves-effect waves-light"
                              onClick={() => {
                                if(caseChk()){
                                  setSubmitType(starOrUnstar);
                                  setConfirmMsg("Are you sure you want to "+starOrUnstar.toLowerCase()+" all checked entities?");
                                  setConfirmBtnText("Yes, " + starOrUnstar.toLowerCase());
                                  setCancelBtnText("Cancel");
                                  setConfirmAlert(true);
                                }
                              }}
                              id="btn_star_unstar"
                            >
                              {starOrUnstar}
                            </Button>
                            <UncontrolledTooltip placement="top" target="btn_star_unstar">
                            {starOrUnstar} selected entities.
                            </UncontrolledTooltip>
                            <Button
                              type="button"
                              color="primary"
                              outline
                              className="waves-effect waves-light"
                              onClick={() => {
                                if(caseChk()){
                                  setSubmitType(hideOrUnhide);
                                  setConfirmMsg("Are you sure you want to "+hideOrUnhide.toLowerCase()+" all checked entities?");
                                  setConfirmBtnText("Yes, " + hideOrUnhide.toLowerCase());
                                  setCancelBtnText("Cancel");
                                  setConfirmAlert(true);
                                }
                              }}
                              id="btn_hide_unhide"
                            >
                              {hideOrUnhide}
                            </Button>
                            <UncontrolledTooltip placement="top" target="btn_hide_unhide">
                            {hideOrUnhide} selected entities.
                            </UncontrolledTooltip>
                            {userGrade === "00" ?
                            <>
                            <Button
                              type="button"
                              color="disable"
                              outline
                              className="waves-effect waves-light"
                              id="btn_down_excel_2"
                            >
                              Download Excel
                            </Button>
                            <UncontrolledTooltip placement="top" target="btn_down_excel_2">
                            Contact us at global@wecrest.com to access all features.
                            </UncontrolledTooltip>
                            <Button
                              type="button"
                              color="disable"
                              outline
                              className="waves-effect waves-light"
                              id="btn_down_pdf_2"
                            >
                              Download PDF
                            </Button>
                            <UncontrolledTooltip placement="top" target="btn_down_pdf_2">
                            Contact us at global@wecrest.com to access all features.
                            </UncontrolledTooltip>
                            </>
                            :
                            <>
                            <Button
                              type="button"
                              color="primary"
                              outline
                              className="waves-effect waves-light"
                              onClick={() => {
                                if(caseChk()){
                                  filterValidation.setFieldValue("submitType", "multipleExcel");
                                  filterValidation.handleSubmit(filterValidation.values);
                                }
                              }}
                              id="btn_down_excel_2"
                            >
                              Download Excel
                            </Button>
                            <UncontrolledTooltip placement="top" target="btn_down_excel_2">
                            Download details of selected entities as an Excel file
                            </UncontrolledTooltip>
                            <Button
                              type="button"
                              color="primary"
                              outline
                              className="waves-effect waves-light"
                              onClick={() => {
                                if(caseChk()){
                                  filterValidation.setFieldValue("submitType", "multiplePdf");
                                  filterValidation.handleSubmit(filterValidation.values);
                                }
                              }}
                              id="btn_down_pdf_2"
                            >
                              Download PDF
                            </Button>
                            <UncontrolledTooltip placement="top" target="btn_down_pdf_2">
                            Download details of selected entities as a PDF file
                            </UncontrolledTooltip>
                            </>
                            }
                            {/*
                            <button
                              type="button"
                              onClick={() => {
                                window.location.href = "/membershipPlan";
                              }}
                              className="btn btn-primary btn-wcdark waves-effect"
                              style={{width: "150px"}}
                            >
                              See full ranking
                            </button>
                            */}
                          </span>
                        </Row>
                      </CardTitle>
                      <div className="table-responsive">
                        <Table className="table mb-0">
                          <thead>
                            <tr>
                            <th><input type="checkbox" checked={chkAllFlag} onClick={(e)=>rowCheck("chkAll", e.target.checked)} /></th>
                            {
                              columns && columns.length ?
                              columns.map((col, index) => {
                                  return (
                                    <th style={{position: "relative", whiteSpace: "pre-line"}}>
                                      {col.label}
                                      {col.asc && col.desc? <>
                                      <Link to='#' onClick={()=> {viewFilerRankings(col.asc)}} className={col.ascActive}><i className="btn-asc uil uil-angle-up font-size-18"></i></Link>
                                      <Link to='#' onClick={()=> {viewFilerRankings(col.desc)}} className={col.descActive}><i className="btn-desc uil uil-angle-down font-size-18"></i></Link>
                                      </>
                                    :null}
                                    </th>
                                  )
                              })
                              :null
                            }
                            </tr>
                          </thead>
                          <tbody>
                          {filerRankings && filerRankings.length ?
                              filerRankings.map((ranking, index) => {
                                  return (
                                      <tr key={index} style={{backgroundColor: ranking.emailSentCount > 0? emailSentBgColor : null}}>
                                        <td style={{verticalAlign: "middle"}}>
                                          <input type="checkbox" 
                                            checked={ranking.checked}
                                            onClick={(e)=>rowCheck(index, e.target.checked)}
                                            />
                                        </td>
                                        <td style={{verticalAlign: "middle", textAlign: "center"}}>
                                          <Link to="#" id={"refusal_cnt_info_"+index} onClick={(e) => {
                                            filterValidation.setFieldValue("submitType", "goMadrid_"+ranking.targetGroupNo+"_"+ranking.name);
                                            filterValidation.handleSubmit(filterValidation.values);}}>{ranking.refusalCnt}</Link>
                                          <UncontrolledTooltip placement="top" target={"refusal_cnt_info_"+index}>
                                          {ranking.refusalCntInfo}
                                          </UncontrolledTooltip>
                                        </td>
                                        <td style={{verticalAlign: "middle"}}>
                                          <Row>
                                            <div className="d-flex vertical-align-center gap-2">
                                            {ranking.unlockYn === 'Y' ?
                                              <>
                                              <Link to="#" onClick={() => { togContactsScreen(ranking.targetGroupNo) }} data-toggle="modal" 
                                                className={ranking.dbStatus === "upToDate"? "text-success" 
                                                          : ranking.memberConfirmEmlYn === 'Y' ? "text-primary" 
                                                          : ranking.suggestEmlYn === 'Y' ? "text-warning" 
                                                          : "text-danger"}
                                                >
                                                <i className="uil uil-at font-size-22" id={"contacts_"+index}></i>
                                                <UncontrolledTooltip placement="top" target={"contacts_"+index}>
                                                {ranking.dbStatus === "notUpToDate" ? "Email address(es) haven't been updated in a while and may be out of date!" : ranking.suggestEmlYn === 'Y' ? "Contacts with WeCrest suggestions" : "Contacts"}
                                                </UncontrolledTooltip>
                                              </Link>
                                              <Link to="#" className={ranking.starYn === 'Y' ? "text-warning": null}
                                                onClick={(e)=> {
                                                  filterValidation.setFieldValue("submitType", (ranking.starYn === 'Y' ? "unStar_target_" : "star_target_") + ranking.targetGroupNo)
                                                  filterValidation.handleSubmit(filterValidation.values);
                                                }}>
                                                <i className="uil uil-star font-size-22" id={"Starred_"+index}></i>
                                                <UncontrolledTooltip placement="top" target={"Starred_"+index}>
                                                  {ranking.starYn === 'Y' ? "Remove from starred": "Star entity"}
                                                </UncontrolledTooltip>
                                              </Link>
                                              <Link to="#" className={ranking.hideYn === 'Y' ? "text-danger": null}
                                                onClick={(e)=> {
                                                  filterValidation.setFieldValue("submitType", (ranking.hideYn === 'Y' ? "unHide_target_" : "hide_target_") + ranking.targetGroupNo)
                                                  filterValidation.handleSubmit(filterValidation.values);
                                                }}>
                                                <i className="uil uil-eye-slash font-size-22" id={"Hidden_"+index}></i>
                                                <UncontrolledTooltip placement="top" target={"Hidden_"+index}>
                                                  {ranking.hideYn === 'Y' ? "Unhide entity": "Hide entity"}
                                                </UncontrolledTooltip>
                                              </Link>
                                              {
                                              ranking.dbStatus === "new" || ranking.unlockYn === 'N'? 
                                                <i className="uil uil-message font-size-22" style={{color: "transparent"}}></i>
                                              :
                                                <Link to="#" className={ranking.dbStatus === "upToDate"? "text-success": "text-danger"}
                                                      onClick={()=>{
                                                        // if (ranking.defaultSendEmailConfirmStatus === '' || ranking.defaultSendEmailConfirmStatus === 'pending') {
                                                        //   setAlertMsg(<span>
                                                        //     You are one small step away from being able to use the Filer Rankings. 
                                                        //     Please complete the setup process by entering your email settings <a href='/emailingSettings' className='a-href-underline'>here</a>. 
                                                        //     Once you have completed this step, you will be able to send emails using the Filer Rankings.
                                                        //     </span>);
                                                        // } else {
                                                          /*
                                                          Email template : (targetEmlTmpSetYn == 'Y' or defaultEmlTmpSetYn == 'Y') 
                                                          && Sender email : defaultSendEmailConfirmStatus == 'Confirmed'
                                                          */
                                                          if ((ranking.targetEmlTmpSetYn == 'Y' || ranking.defaultEmlTmpSetYn == 'Y') && ranking.defaultSendEmailConfirmStatus == 'Confirmed') {
                                                            setSubmitType("send_"+index);
                                                            setConfirmMsg("Are you sure you want to send this email?");
                                                            setConfirmBtnText("Confirm");
                                                            setCancelBtnText("Cancel");
                                                            setConfirmAlert(true);
                                                          } else {
                                                            setAlertMsg(<span>Send is not available the email setting is not complete.<br/> Please edit your "Jurisdiction defaults" in your <a href='/emailingSettings' className='a-href-underline'>settings</a>.</span>);
                                                          }
                                                        // }
                                                      }}>
                                                  <i className="uil uil-message font-size-22" id={"send_"+index}></i>
                                                  <UncontrolledTooltip placement="top" target={"send_"+index}>
                                                    Send 
                                                    {ranking.dbStatus === "notUpToDate"? 
                                                      <>
                                                        <br/>
                                                        [Email address(es) haven't been updated in a while and may be out of date!]
                                                      </>
                                                    : null
                                                    }
                                                  </UncontrolledTooltip>
                                                </Link>
                                              }
                                              </>
                                            :
                                            unlockCredit - unlockCnt > 0 ?
                                              <>
                                              <i className="uil uil-at font-size-22" style={{color: "transparent"}}></i>
                                              <Link to="#" className="text-success"
                                                onClick={()=>{
                                                  setSubmitType("unlock_"+index);
                                                  setConfirmMsg("Do you want to unlock this entity?");
                                                  setConfirmBtnText("Confirm");
                                                  setCancelBtnText("Cancel");
                                                  setConfirmAlert(true);
                                                }}>
                                                <i className="uil uil-unlock-alt font-size-22" id={"unlock_"+index}></i>
                                                <UncontrolledTooltip placement="top" target={"unlock_"+index}>
                                                Unlock to send emails for this entity.
                                                </UncontrolledTooltip>
                                              </Link>
                                              
                                              <i className="uil uil-eye-slash font-size-22" style={{color: "transparent"}}></i>
                                              <i className="uil uil-message font-size-22" style={{color: "transparent"}}></i>
                                              </>
                                            : 
                                              <>
                                              <i className="uil uil-at font-size-22" style={{color: "transparent"}}></i>
                                              <Link to="#" className="text-danger">
                                                <i className="uil uil-lock-alt font-size-22" id={"unlock_"+index}></i>
                                                <UncontrolledTooltip placement="top" target={"unlock_"+index}>
                                                {errMsg[3]}
                                                </UncontrolledTooltip>
                                              </Link>
                                              <i className="uil uil-eye-slash font-size-22" style={{color: "transparent"}}></i>
                                              <i className="uil uil-message font-size-22" style={{color: "transparent"}}></i>
                                              </>
                                            }
                                            
                                              <Link to="#" id={"target_info_"+index} onClick={(e) => {
                                                filterValidation.setFieldValue("submitType", "goMadrid_"+ranking.targetGroupNo+"_"+ranking.name);
                                                filterValidation.handleSubmit(filterValidation.values);}}>{ranking.name}
                                              </Link>
                                              <UncontrolledTooltip placement="top" target={"target_info_"+index}>
                                              {ranking.groupInfo}
                                              </UncontrolledTooltip>
                                              <Link to="#" id={"copy_entity_"+index}
                                                onClick={()=>{
                                                  if (window.isSecureContext && navigator.clipboard) {
                                                    navigator.clipboard.writeText(ranking.name);
                                                  } else {
                                                    // console.log('old copy clipboard!');
                                                    unsecuredCopyToClipboard(ranking.name, "copy_entity_"+index);
                                                  }
                                                  showSuccessCopyToast();
                                                }}>
                                                <i className="uil uil-copy-alt font-size-22 text-secondary"></i>
                                              </Link>
                                              <UncontrolledTooltip placement="top" target={"copy_entity_"+index}>
                                                Copy name to clipboard
                                              </UncontrolledTooltip>
                                            </div>
                                          </Row>
                                        </td>
                                        <td style={{verticalAlign: "middle"}} align="center">{ranking.country}</td>
                                        <td style={{verticalAlign: "middle"}}>{ranking.type}</td>
                                        <td style={{verticalAlign: "middle"}} align="center">{ranking.sendCount}</td>
                                        <td style={{verticalAlign: "middle"}} align="center">{ranking.lastContactDate? ranking.lastContactDate : "-" }</td>
                                        <td style={{verticalAlign: "middle"}} align="center">
                                        {ranking.unlockYn === 'Y' ?
                                          <>
                                          <Link to="#"
                                            onClick={()=>{openEditor(ranking.targetGroupNo, ranking.inOutType, ranking.targetEmlTmpSetYn)}}>
                                            <i className={ranking.targetEmlTmpSetYn == 'Y' ? "uil uil-envelope-edit font-size-22 text-success" : "uil uil-envelope-edit font-size-22 text-warning"} id={"editEmail_"+index}></i>
                                            <UncontrolledTooltip placement="top" target={"editEmail_"+index}>
                                            {ranking.targetEmlTmpSetYn == 'Y' ? "Edited template" : "Default template"}
                                            </UncontrolledTooltip>
                                          </Link>
                                          &nbsp;&nbsp;&nbsp;
                                          <Link to="#"
                                            onClick={()=>{
                                              if ((ranking.targetEmlTmpSetYn == 'Y' || ranking.defaultEmlTmpSetYn == 'Y') && ranking.defaultSendEmailConfirmStatus == 'Confirmed') {
                                                let _values = cloneDeep(filterValidation.values);
                                                _values.submitType = "preview_"+index;
                                                filterValidation.setValues(_values);
                                                filterValidation.handleSubmit(filterValidation.values);
                                              } else {
                                                setAlertMsg(<span>Preview is not available because the email setting is not complete.<br/> Please edit your "Jurisdiction defaults" in your <a href='/emailingSettings' className='a-href-underline'>settings</a>.</span>);
                                              }
                                            }}>
                                            <i className="uil uil-file-search-alt font-size-22" id={"preview_"+index}></i>
                                            <UncontrolledTooltip placement="top" target={"preview_"+index}>
                                              Preview
                                            </UncontrolledTooltip>
                                          </Link>
                                          
                                          </>
                                        :
                                          null
                                         
                                          }
                                        </td>
                                      </tr>
                                  )
                                })
                              : null
                            }
                          </tbody>
                        </Table>
                      </div>
                      <div className="d-flex justify-content-between">
                        <span className="d-flex align-items-center gap-2">
                          <label
                            htmlFor="perPage"
                            >
                            Entries per page
                          </label>
                          <div style={{width: "100px"}}>
                            <select 
                              className="form-select" 
                              name="perPage"
                              onChange={e => {viewFilerRankings(null, 1, e.target.value);}}
                              value={filterValidation.values.perPage}
                            >
                              <option value="50">50</option>
                              <option value="100">100</option>
                              <option value="250">250</option>
                              <option value="500">500</option>
                              <option value="1000">1000</option>
                            </select>
                          </div>
                          {/*
                          <button
                            type="button"
                            onClick={() => {
                              window.location.href = "/membershipPlan";
                            }}
                            className="btn btn-primary btn-wcdark waves-effect"
                            style={{width: "150px"}}
                          >
                            See full ranking
                          </button>
                          */}
                        </span>
                        <span className="d-flex align-items-center gap-2">
                          <div className="d-flex gap-1">
                            <Button
                              type="button"
                              color="primary"
                              onClick={()=>{viewFilerRankings(null, 1);}}
                              disabled={(filterValidation.values.curPage == 1)}
                            >
                              {"<<"}
                            </Button>
                            <Button
                              type="button"
                              color="primary"
                              onClick={()=>{viewFilerRankings(null, Number(filterValidation.values.curPage)-1);}}
                              disabled={(filterValidation.values.curPage == 1)}
                            >
                              {"<"}
                            </Button>
                          </div>
                          Page{" "}
                          <strong>
                            {filterValidation.values.curPage} of {totalPage}
                          </strong>
                          <Input
                            type="number"
                            min={1}
                            style={{ width: 70 }}
                            max={totalPage}
                            name="curPage"
                            defaultValue={filterValidation.values.curPage}
                            value={curPageInput}
                            onChange={(e) => { setCurPageInput(e.target.value)}}
                            onKeyPress={(e) => {
                              if(e.key === 'Enter'){
                                viewFilerRankings(null, e.target.value);
                              }
                            }}
                          />
                          <div className="d-flex gap-1">
                            <Button 
                              type="button"
                              color="primary"
                              onClick={()=>{viewFilerRankings(null, Number(filterValidation.values.curPage)+1);}}
                              disabled={(filterValidation.values.curPage == totalPage)}
                            >
                              {">"}
                            </Button>
                            <Button
                              type="button"
                              color="primary"
                              onClick={()=>{viewFilerRankings(null, totalPage);}}
                              disabled={(filterValidation.values.curPage == totalPage)}
                            >
                              {">>"}
                            </Button>
                          </div>
                        </span>
                      </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            :null}
          </div>
        </div>
      </Form>
    </React.Fragment>
  )
}

/*
UserList.propTypes = {
  users: PropTypes.array,
  loading: PropTypes.bool,
  onGetUserList: PropTypes.func,
};
*/

export default FilerRankings;