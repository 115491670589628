import {
  GET_SETTINGS_INFO,
  GET_SETTINGS_INFO_SUCCESS,
  UPDATE_ACCOUNT_INFO,
  UPDATE_ACCOUNT_INFO_SUCCESS,
  UPDATE_ACCOUNT_PASSWORD,
  UPDATE_ACCOUNT_INVOICE_INFO,
  UPDATE_ACCOUNT_UNSUBSCRIBE_INFO,
  UPDATE_ACCOUNT_UPTODATE_INFO,
  GET_SETTINGS_EMAIL_TEMPLATES,
  GET_SETTINGS_EMAIL_TEMPLATES_SUCCESS,
  DELETE_EMAIL_TEMPLATE,
  DELETE_EMAIL_TEMPLATE_SUCCESS,
  UPDATE_SENDER_EMAIL,
  UPDATE_SENDER_EMAIL_SUCCESS,
  UPDATE_SUBSCRIPTION_LIST,
  UPDATE_SUBSCRIPTION_LIST_SUCCESS,
  SET_REDUCER_VALUE,
  GET_USER_SIGNATURE_SAVE_POPUP_APPEAR_INFO,
  GET_USER_SIGNATURE_SAVE_POPUP_APPEAR_INFO_SUCCESS,
  ADD_USER_SIGNATURE_SAVE_POPUP_APPEAR_LOG,
  GET_USER_SIGNATURE_INFO,
  GET_USER_SIGNATURE_INFO_SUCCESS,
  
  GET_EMAILING_SETTINGS,
  GET_EMAILING_SETTINGS_SUCCESS,
  ADD_SENDER_EMAIL,
  ADD_SENDER_EMAIL_SUCCESS,
  DELETE_SENDER_EMAIL,
  DELETE_SENDER_EMAIL_SUCCESS,
  GET_USER_SIGNATURE,
  GET_USER_SIGNATURE_SUCCESS,
  ADD_USER_SIGNATURE,
  ADD_USER_SIGNATURE_SUCCESS,
  UPDATE_USER_SIGNATURE,
  UPDATE_USER_SIGNATURE_SUCCESS,
  DELETE_USER_SIGNATURE,
  DELETE_USER_SIGNATURE_SUCCESS,
  ADD_USER_JURISDICTION_EMAIL_SETTING,
  ADD_USER_JURISDICTION_EMAIL_SETTING_SUCCESS,
  UPDATE_USER_JURISDICTION_EMAIL_SETTING,
  UPDATE_USER_JURISDICTION_EMAIL_SETTING_SUCCESS,
  DELETE_USER_JURISDICTION_EMAIL_SETTING,
  DELETE_USER_JURISDICTION_EMAIL_SETTING_SUCCESS,
  UPDATE_DISPLAY_NAME,
  UPDATE_DISPLAY_NAME_SUCCESS,
  UPDATE_SENDER_EMAIL_ADDRESS,
  UPDATE_SENDER_EMAIL_ADDRESS_SUCCESS,

  GET_OCMN_SHOW_STARTUP,
  GET_OCMN_SHOW_STARTUP_SUCCESS,
  SAVE_OCMN_SETTINGS,
  SAVE_OCMN_SETTINGS_SUCCESS,

  ADD_USER_INVOICE_INFO,
  ADD_USER_INVOICE_INFO_SUCCESS,
  MODIFY_USER_INVOICE_INFO,
  MODIFY_USER_INVOICE_INFO_SUCCESS,
  REMOVE_USER_INVOICE_INFO,
  REMOVE_USER_INVOICE_INFO_SUCCESS,
  GET_USER_SUBSCRIPTION_LIST,
  GET_USER_SUBSCRIPTION_LIST_SUCCESS,
  MODIFY_USER_SUBSCRIPTION_INVOICE_INFO,
  MODIFY_USER_SUBSCRIPTION_INVOICE_INFO_SUCCESS,
} from "./actionTypes"

export const getSettingsInfo = data => {
  // console.log('getEmailTemplates action : ' + JSON.stringify(data))
  return {
    type: GET_SETTINGS_INFO,
    payload: data,
  }
}

export const getSettingsInfoSuccess = data => {
  // console.log('getEmailTemplatesSuccess action : ' + data)
  
  return {
    type: GET_SETTINGS_INFO_SUCCESS,
    payload: data,
  }
}

export const getSettingsEmailTemplates = data => {
  // console.log('getEmailTemplates action : ' + JSON.stringify(data))
  return {
    type: GET_SETTINGS_EMAIL_TEMPLATES,
    payload: data,
  }
}

export const getSettingsEmailTemplatesSuccess = data => {
  // console.log('getEmailTemplatesSuccess action : ' + data)
  
  return {
    type: GET_SETTINGS_EMAIL_TEMPLATES_SUCCESS,
    payload: data,
  }
}

export const updateAccountInfo = data => {
  // console.log('getEmailTemplates action : ' + JSON.stringify(data))
  return {
    type: UPDATE_ACCOUNT_INFO,
    payload: data,
  }
}

export const updateAccountInfoSuccess = data => {
  // console.log('updateAccountInfoSuccess action : ' + JSON.stringify(data))
  return {
    type: UPDATE_ACCOUNT_INFO_SUCCESS,
    payload: data,
  }
}

export const updateAccountPassword = data => {
  // console.log('getEmailTemplates action : ' + JSON.stringify(data))
  return {
    type: UPDATE_ACCOUNT_PASSWORD,
    payload: data,
  }
}

export const updateAccountInvoiceInfo = data => {
  // console.log('getEmailTemplates action : ' + JSON.stringify(data))
  return {
    type: UPDATE_ACCOUNT_INVOICE_INFO,
    payload: data,
  }
}

export const updateAccountUnsubscribeInfo = data => {
  // console.log('getEmailTemplates action : ' + JSON.stringify(data))
  return {
    type: UPDATE_ACCOUNT_UNSUBSCRIBE_INFO,
    payload: data,
  }
}

export const updateAccountUpToDateInfo = data => {
  // console.log('getEmailTemplates action : ' + JSON.stringify(data))
  return {
    type: UPDATE_ACCOUNT_UPTODATE_INFO,
    payload: data,
  }
}

export const deleteEmailTemplate = data => {
  // console.log('getEmailTemplates action : ' + JSON.stringify(data))
  return {
    type: DELETE_EMAIL_TEMPLATE,
    payload: data,
  }
}

export const deleteEmailTemplateSuccess = data => {
  // console.log('getEmailTemplatesSuccess action : ' + data)
  
  return {
    type: DELETE_EMAIL_TEMPLATE_SUCCESS,
    payload: data,
  }
}

export const updateSenderEmail = data => {
  // console.log('getEmailTemplatesSuccess action : ' + data)
  
  return {
    type: UPDATE_SENDER_EMAIL,
    payload: data,
  }
}

export const updateSenderEmailSuccess = data => {
  // console.log('getEmailTemplatesSuccess action : ' + data)
  
  return {
    type: UPDATE_SENDER_EMAIL_SUCCESS,
    payload: data,
  }
}

export const updateSubscriptionList = (subscriptionDetails, filerSettings) => {
  // console.log('updateSubscriptionList subscriptionDetails : ' + JSON.stringify(subscriptionDetails))
  // console.log('updateSubscriptionList filerSettings : ' + JSON.stringify(filerSettings))
  
  return {
    type: UPDATE_SUBSCRIPTION_LIST,
    payload: {subscriptionDetails, filerSettings},
  }
}

export const updateSubscriptionListSuccess = data => {
  // console.log('getEmailTemplatesSuccess action : ' + data)
  
  return {
    type: UPDATE_SUBSCRIPTION_LIST_SUCCESS,
    payload: data,
  }
}

export const setSettingsReducerValue = (type, data) => {
  return {
    type: SET_REDUCER_VALUE,
    payload: {type, data},
  }
}

export const getUserSignatureSavePopupAppearInfo = data => {
  return {
    type: GET_USER_SIGNATURE_SAVE_POPUP_APPEAR_INFO,
    payload: data,
  }
}

export const getUserSignatureSavePopupAppearInfoSuccess = data => {
  return {
    type: GET_USER_SIGNATURE_SAVE_POPUP_APPEAR_INFO_SUCCESS,
    payload: data,
  }
}

export const addUserSignatureSavePopupAppearLog = () => {
  return {
    type: ADD_USER_SIGNATURE_SAVE_POPUP_APPEAR_LOG,
  }
}

export const getUserSignatureInfo = data => {
  return {
    type: GET_USER_SIGNATURE_INFO,
    payload: data,
  }
}

export const getUserSignatureInfoSuccess = data => {
  return {
    type: GET_USER_SIGNATURE_INFO_SUCCESS,
    payload: data,
  }
}


export const getEmailingSettings = () => {
  return {
    type: GET_EMAILING_SETTINGS,
    // payload: data,
  }
}

export const getEmailingSettingsSuccess = data => {
  return {
    type: GET_EMAILING_SETTINGS_SUCCESS,
    payload: data,
  }
}

export const addSenderEmail = data => {
  return {
    type: ADD_SENDER_EMAIL,
    payload: data,
  }
}

export const addSenderEmailSuccess = data => {
  return {
    type: ADD_SENDER_EMAIL_SUCCESS,
    payload: data,
  }
}

export const updateDisplayName = data => {
  return {
    type: UPDATE_DISPLAY_NAME,
    payload: data,
  }
}

export const updateDisplayNameSuccess = data => {
  return {
    type: UPDATE_DISPLAY_NAME_SUCCESS,
    payload: data,
  }
}

export const updateSenderEmailAddress = data => {
  return {
    type: UPDATE_SENDER_EMAIL_ADDRESS,
    payload: data,
  }
}

export const updateSenderEmailAddressSuccess = data => {
  return {
    type: UPDATE_SENDER_EMAIL_ADDRESS_SUCCESS,
    payload: data,
  }
}

export const deleteSenderEmail = data => {
  return {
    type: DELETE_SENDER_EMAIL,
    payload: data,
  }
}

export const deleteSenderEmailSuccess = data => {
  return {
    type: DELETE_SENDER_EMAIL_SUCCESS,
    payload: data,
  }
}

export const getUserSignature = data => {
  return {
    type: GET_USER_SIGNATURE,
    payload: data,
  }
}

export const getUserSignatureSuccess = data => {
  return {
    type: GET_USER_SIGNATURE_SUCCESS,
    payload: data,
  }
}

export const addUserSignature = data => {
  return {
    type: ADD_USER_SIGNATURE,
    payload: data,
  }
}

export const addUserSignatureSuccess = data => {
  return {
    type: ADD_USER_SIGNATURE_SUCCESS,
    payload: data,
  }
}

export const updateUserSignature = data => {
  return {
    type: UPDATE_USER_SIGNATURE,
    payload: data,
  }
}

export const updateUserSignatureSuccess = data => {
  return {
    type: UPDATE_USER_SIGNATURE_SUCCESS,
    payload: data,
  }
}

export const deleteUserSignature = data => {
  return {
    type: DELETE_USER_SIGNATURE,
    payload: data,
  }
}

export const deleteUserSignatureSuccess = data => {
  return {
    type: DELETE_USER_SIGNATURE_SUCCESS,
    payload: data,
  }
}

export const addUserJurisdictionEmailSetting = data => {
  return {
    type: ADD_USER_JURISDICTION_EMAIL_SETTING,
    payload: data,
  }
}

export const addUserJurisdictionEmailSettingSuccess = data => {
  return {
    type: ADD_USER_JURISDICTION_EMAIL_SETTING_SUCCESS,
    payload: data,
  }
}

export const updateUserJurisdictionEmailSetting = data => {
  return {
    type: UPDATE_USER_JURISDICTION_EMAIL_SETTING,
    payload: data,
  }
}

export const updateUserJurisdictionEmailSettingSuccess = data => {
  return {
    type: UPDATE_USER_JURISDICTION_EMAIL_SETTING_SUCCESS,
    payload: data,
  }
}

export const deleteUserJurisdictionEmailSetting = data => {
  return {
    type: DELETE_USER_JURISDICTION_EMAIL_SETTING,
    payload: data,
  }
}

export const deleteUserJurisdictionEmailSettingSuccess = data => {
  return {
    type: DELETE_USER_JURISDICTION_EMAIL_SETTING_SUCCESS,
    payload: data,
  }
}

export const getOcmnShowStartup = () => {
  return {
    type: GET_OCMN_SHOW_STARTUP,
  }
}

export const getOcmnShowStartupSuccess = data => {
  return {
    type: GET_OCMN_SHOW_STARTUP_SUCCESS,
    payload: data,
  }
}

export const saveOcmnSettings = data => {
  return {
    type: SAVE_OCMN_SETTINGS,
    payload: data,
  }
}

export const saveOcmnSettingsSuccess = data => {
  return {
    type: SAVE_OCMN_SETTINGS_SUCCESS,
    payload: data,
  }
}

export const addUserInvoiceInfo = formData => {
  return {
    type: ADD_USER_INVOICE_INFO,
    payload: formData,
  }
}

export const addUserInvoiceInfoSuccess = data => {
  return {
    type: ADD_USER_INVOICE_INFO_SUCCESS,
    payload: data,
  }
}

export const modifyUserInvoiceInfo = formData => {
  return {
    type: MODIFY_USER_INVOICE_INFO,
    payload: formData,
  }
}

export const modifyUserInvoiceInfoSuccess = data => {
  return {
    type: MODIFY_USER_INVOICE_INFO_SUCCESS,
    payload: data,
  }
}

export const removeUserInvoiceInfo = formData => {
  return {
    type: REMOVE_USER_INVOICE_INFO,
    payload: formData,
  }
}

export const removeUserInvoiceInfoSuccess = data => {
  return {
    type: REMOVE_USER_INVOICE_INFO_SUCCESS,
    payload: data,
  }
}

export const getUserSubscriptionList = formData => {
  return {
    type: GET_USER_SUBSCRIPTION_LIST,
    payload: formData,
  }
}

export const getUserSubscriptionListSuccess = data => {
  return {
    type: GET_USER_SUBSCRIPTION_LIST_SUCCESS,
    payload: data,
  }
}

export const modifyUserSubscriptionInvoiceInfo = formData => {
  return {
    type: MODIFY_USER_SUBSCRIPTION_INVOICE_INFO,
    payload: formData,
  }
}

export const modifyUserSubscriptionInvoiceInfoSuccess = data => {
  return {
    type: MODIFY_USER_SUBSCRIPTION_INVOICE_INFO_SUCCESS,
    payload: data,
  }
}