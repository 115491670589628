import {
  SET_LOADING,
  SET_SUCCESS,
  SET_RESULT,
  SET_ERROR,
  CHANGE_ADM_ACCOUNT_MANAGEMENT_SEARCH_LIST,
  GET_ADM_ACCOUNT_MANAGEMENT_SEARCH_LIST,
  GET_ADM_ACCOUNT_MANAGEMENT_SEARCH_LIST_SUCCESS,
  DOWN_EXCEL_ADM_ACCOUNT_MANAGEMENT,
  DOWN_EXCEL_ADM_ACCOUNT_MANAGEMENT_SUCCESS,
  UPDATE_ACCOUNT_BY_ADMIN,
  UPDATE_ACCOUNT_BY_ADMIN_SUCCESS,
  UPDATE_PASSWORD_BY_ADMIN,
  UPDATE_PASSWORD_BY_ADMIN_SUCCESS,
  RESET_SMTP_BY_ADMIN,
  RESET_SMTP_BY_ADMIN_SUCCESS,
  VERIFY_ACCOUNT_BY_ADMIN,
  VERIFY_ACCOUNT_BY_ADMIN_SUCCESS,
  VIRTUAL_LOG_IN_BY_ADMIN,
  VIRTUAL_LOG_IN_BY_ADMIN_SUCCESS,
  CREATE_ACCOUNT_BY_ADMIN,
  CREATE_ACCOUNT_BY_ADMIN_SUCCESS,
  ADD_BILLING_PROFILE_BY_ADMIN,
  CHANGE_BILLING_PROFILE_BY_ADMIN,
  REMOVE_BILLING_PROFILE_BY_ADMIN,
} from "./actionTypes"

import { cloneDeep } from 'lodash';

const initialState = {
  error: "",
  loading: false,
  success: "",
  result: -1,
  totalPage: 1,
  noticeStr: "",
  admAccountManagementSearchList: [],
  searchUserList: [],
  searchUserMessage: "",
  admAccountManagementUserInfo: {},
  admAccountManagementItemList: [],
  admAccountManagementModifyInvoiceForm: {},
  subscriptionItemAllList: [],
  cresterList: [],
  admInfo: {},
  userInvoiceInfoList: [],
  previewInvoiceItem: {},
  createBillingProfileList: [],
}

const AdmAccountManagement = (state = initialState, action) => {
  switch (action.type) {
    case SET_LOADING:
      return {
        ...state,
        loading: action.payload,
      }
    case SET_SUCCESS:
      return {
        ...state,
        success: action.payload,
        loading: false,
      }
    case SET_RESULT:
      return {
        ...state,
        result: action.payload,
        loading: false,
      }
    case SET_ERROR:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }
    case CHANGE_ADM_ACCOUNT_MANAGEMENT_SEARCH_LIST:
      state =  {
        ...state,
        admAccountManagementSearchList: action.payload,
      }
      break
    case GET_ADM_ACCOUNT_MANAGEMENT_SEARCH_LIST:
      return state = {
        ...state,
        loading: true,
      }
    case GET_ADM_ACCOUNT_MANAGEMENT_SEARCH_LIST_SUCCESS:
      return state =  {
        ...state,
        admAccountManagementSearchList: action.payload.admAccountManagementSearchList,
        totalPage: action.payload.totalPage,
        loading: false,
      }
    case DOWN_EXCEL_ADM_ACCOUNT_MANAGEMENT:
      return {
        ...state,
        loading: true
      }
    case DOWN_EXCEL_ADM_ACCOUNT_MANAGEMENT_SUCCESS:
      return {
        ...state,
        loading: false
      }
    case UPDATE_ACCOUNT_BY_ADMIN:
      state = {
        ...state,
        loading: true,
      }
      break
    case UPDATE_ACCOUNT_BY_ADMIN_SUCCESS:
      state =  {
        ...state,
        loading: false,
        success: action.payload.result,
      }
      break
    
    case UPDATE_PASSWORD_BY_ADMIN:
      state = {
        ...state,
        loading: true,
      }
      break
    case UPDATE_PASSWORD_BY_ADMIN_SUCCESS:
      state =  {
        ...state,
        loading: false,
        success: action.payload,
      }
      break
    case RESET_SMTP_BY_ADMIN:
      state = {
        ...state,
        loading: true,
      }
      break
    case RESET_SMTP_BY_ADMIN_SUCCESS:
      state =  {
        ...state,
        loading: false,
      }
      break
    case VERIFY_ACCOUNT_BY_ADMIN:
      state = {
        ...state,
        loading: true,
      }
      break
    case VERIFY_ACCOUNT_BY_ADMIN_SUCCESS:
      state =  {
        ...state,
        loading: false,
        success: action.payload,
      }
      break
    case VIRTUAL_LOG_IN_BY_ADMIN:
      state = {
        ...state,
        loading: true,
      }
      break
    case VIRTUAL_LOG_IN_BY_ADMIN_SUCCESS:
      state =  {
        ...state,
        loading: false,
      }
      break
    case CREATE_ACCOUNT_BY_ADMIN:
      state = {
        ...state,
        loading: true,
      }
      break
    case CREATE_ACCOUNT_BY_ADMIN_SUCCESS:
      return {
        ...state,
        loading: false,
        success: action.payload,
      }
    case ADD_BILLING_PROFILE_BY_ADMIN:
      const _billingProfile = cloneDeep(action.payload);
      _billingProfile.seq = state.createBillingProfileList.length;

      const _createBillingProfileList = cloneDeep(state.createBillingProfileList);
      _createBillingProfileList.push(_billingProfile);
      return {
        ...state,
        createBillingProfileList: _createBillingProfileList,
      }
    case CHANGE_BILLING_PROFILE_BY_ADMIN:
      const _createBillingProfileList2 = cloneDeep(state.createBillingProfileList);
      _createBillingProfileList2[action.payload.seq] = action.payload;
      return {
        ...state,
        createBillingProfileList: _createBillingProfileList2,
      }
    case REMOVE_BILLING_PROFILE_BY_ADMIN:
      const _createBillingProfileList3 = cloneDeep(state.createBillingProfileList);
      _createBillingProfileList3.splice(action.payload, 1);
      return {
        ...state,
        createBillingProfileList: _createBillingProfileList3,
      }
    default:
      state = { ...state }
      break
  }
  return state
}

export default AdmAccountManagement
