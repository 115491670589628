import { PROFILE_ERROR, PROFILE_SUCCESS, EDIT_PROFILE, RESET_PROFILE_FLAG, CHANGE_USER_NAME, CHANGE_PHOTO_URL } from "./actionTypes"

export const editProfile = user => {
  return {
    type: EDIT_PROFILE,
    payload: { user },
  }
}

export const profileSuccess = msg => {
  return {
    type: PROFILE_SUCCESS,
    payload: msg,
  }
}

export const profileError = error => {
  return {
    type: PROFILE_ERROR,
    payload: error,
  }
}

export const resetProfileFlag = error => {
  return {
    type: RESET_PROFILE_FLAG,
  }
}

export const changeProfileUserName = data => {
  return {
    type: CHANGE_USER_NAME,
    payload: data,
  }
}

export const changeProfilePhotoUrl = data => {
  return {
    type: CHANGE_PHOTO_URL,
    payload: data,
  }
}
