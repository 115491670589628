import React from "react";

function EmailSvg() {
  return (
    <svg version="1.1" id="svg--email" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
        width="223.972px" height="145.982px" viewBox="0 0 223.972 145.982" style={{enableBackground:"new 0 0 223.972 145.982"}}
        xmlSpace="preserve">
        <path d="M164.979,1c2.996,12.231,5.914,24.481,9.005,36.688
            c4.313,17.029,8.756,34.026,13.213,51.308c7.832,0,15.456,0,23.452,0c0.117,1.891,0.215,3.462,0.349,5.627
            c-7.356,0-14.426,0-22.1,0c0.7,3.317,1.262,5.981,1.942,9.203c-9.039,2.369-18.104,4.776-27.187,7.119
            c-44.167,11.392-88.339,22.765-132.503,34.17c-0.431,0.111-0.771,0.57-1.154,0.867c-1,0-2,0-3,0
            c-0.186-1.301-0.25-2.633-0.575-3.898c-4.775-18.631-9.536-37.265-14.402-55.872C8.422,72.453,4.679,58.732,1,44.994
            c0-0.666,0-1.333,0-2C43.649,31.988,86.309,21.026,128.938,9.945C139.669,7.156,150.302,3.992,160.98,1
            C162.313,1,163.646,1,164.979,1z M99.609,88.765c18.289-26.99,36.259-53.511,54.728-80.768
            C107.072,20.235,60.634,32.259,13.003,44.591C42.42,59.596,70.833,74.088,99.609,88.765z M36.911,139.216
            c47.755-12.348,94.488-24.432,142.495-36.845c-21.583-10.892-42.071-21.232-62.684-31.634
            c-5.405,7.916-10.543,15.441-15.828,23.181c-8.326-4.167-16.393-8.206-24.684-12.356C63.115,100.773,50.313,119.554,36.911,139.216
            z M5.787,45.917c8.299,32.181,16.344,63.376,24.672,95.668c14.411-21.154,28.191-41.383,42.134-61.85
            C50.151,68.375,28.409,57.369,5.787,45.917z M182.981,88.615c-7.106-27.53-14.15-54.821-21.469-83.172
            c-14.44,21.212-28.225,41.461-42.264,62.084c22.357,11.274,44.245,22.311,66.98,33.776c-0.775-2.838-1.238-4.529-1.699-6.216
            c-2.099-0.185-3.879-0.341-5.967-0.524c0-1.786,0-3.358,0-5.184C179.997,89.132,181.401,88.889,182.981,88.615z"/>
        <path d="M223.972,123.984c-18.117,0-36.234,0-54.732,0
            c-0.09-1.902-0.165-3.477-0.284-5.999c18.551,0,36.783,0,55.016,0C223.972,119.985,223.972,121.985,223.972,123.984z"/>
        <path d="M187.714,73.181c3.667,0,6.605,0,9.942,0
            c0.096,1.672,0.185,3.233,0.298,5.206c-3.36,0-6.398,0-9.862,0C187.977,76.8,187.863,75.226,187.714,73.181z"/>
    </svg>
  );
}

export default EmailSvg;