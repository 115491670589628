import React, {useState, useEffect} from 'react';
import { useSelector, useDispatch } from "react-redux";
import {
    Row,
    Col,
    Card,
    Media,
    Collapse,
    Button,
    UncontrolledTooltip,
  } from "reactstrap"
import SweetAlert from "react-bootstrap-sweetalert";
import { Link } from "react-router-dom";

import {
  setEntityConnectManagementSuccess as onSetSuccess,
  setEntityConnectManagementResult as onSetResult,
  getUserDomainList as onGetUserDomainList,
  addUserDomain as onAddUserDomain,
  modifyUserDomain as onModifyUserDomain,
  changeUserDomainList as onChangeUserDomainList,
  addUserEntityConnectClaim as onAddUserEntityConnectClaim,
  removeUserDomain as onRemoveUserDomain,
} from "../../store/actions";

import '../../components/Common/Common.css';
import SearchEntity from './SearchEntity';
import "toastr/build/toastr.min.css"
import { cloneDeep } from 'lodash';

const EntityConnect = props => {
  const dispatch = useDispatch();

  const { success, result, userDomainList, myEntityList } = useSelector(state => ({
    success: state.EntityConnectManagement.success,
    result: state.EntityConnectManagement.result,
    userDomainList: state.EntityConnectManagement.userDomainList,
    myEntityList: state.EntityConnectManagement.myEntityList,
  }));

  const [isOpen, setIsOpen] = useState(true);
  const toggle = () => {
    setIsOpen(!isOpen);
  }

  const [domain, setDomain] = useState("");
  const [changeUserDomainIdx, setChangeUserDomainIdx] = useState(-1);
  const [deleteUserDomainIdx, setDeleteUserDomainIdx] = useState(-1);
  const [deleteMyEntityList, setDeleteMyEntityList] = useState([]);

  const changeUserDomain = (chkIndex, value) => {
    const _userDomainList = cloneDeep(userDomainList);
    _userDomainList[chkIndex].domain = value;

    dispatch(onChangeUserDomainList(_userDomainList))
  }

  const modifyUserDomain = (type, chkIndex) => {
    if (type === 'domain') {
      if (userDomainList[chkIndex].domain != userDomainList[chkIndex].orgDomain) {
        let _deleteMyEntityList = [];
        if (myEntityList && myEntityList.length !== 0) {
          myEntityList.map((entity, index)=>{
            if (entity.domain == userDomainList[chkIndex].orgDomain) {
              _deleteMyEntityList.push(entity);
            }
          })
        } 
        
        setDeleteMyEntityList(_deleteMyEntityList);
        setChangeUserDomainIdx(chkIndex);
      }
    } else {
      const _userDomainList = [];
      userDomainList.map((userDomain, index) => {
        const _userDomain = cloneDeep(userDomain);
        if (index === chkIndex) {
          _userDomain.mainYn = 'Y';
          _userDomainList.push(_userDomain);
        } else if (userDomain.mainYn === 'Y') {
          _userDomain.mainYn = 'N';
          _userDomainList.push(_userDomain);
        }
      })

      dispatch(onModifyUserDomain(_userDomainList));
    }
  }

  const deleteUserDomain = (chkIndex) => {
    let _deleteMyEntityList = [];
    if (myEntityList && myEntityList.length !== 0) {
      myEntityList.map((entity, index)=>{
        if (entity.domain == userDomainList[chkIndex].domain) {
          _deleteMyEntityList.push(entity);
        }
      })
    } 
    
    setDeleteMyEntityList(_deleteMyEntityList);
    setDeleteUserDomainIdx(chkIndex);
  }

  const addUserDomain = () => {
    if (domain == '' || domain.trim() == '') {
      dispatch(onSetResult("Please enter a domain."));
    } else {
      let isDuplicate = false;
      if (userDomainList && userDomainList.length > 0) {
        userDomainList.map((userDomain, index)=>{
          if (userDomain.domain == domain) {
            isDuplicate = true;
          }
        })
      }
            
      if (isDuplicate) {
        dispatch(onSetResult("[" + domain + "] is already saved domain."));
      } else {
        dispatch(onAddUserDomain({domain:domain.trim()}))
        setDomain('');
      }
    }
  }

  useEffect(() => { 
    dispatch(onGetUserDomainList());
  }, []);

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Row>
            <Col lg={12}>
              <Card>
                <Link onClick={toggle} className="text-dark" to="#">
                  <div className="p-4">
                    <Media className="d-flex align-items-center">
                      <div className="flex-shrink-0 me-3">
                        <i className="uil uil-object-group text-primary h2"></i>
                      </div>
                      <div className="flex-grow-1 overflow-hidden">
                        <h5 className="font-size-16 mb-1">Connectable entity</h5>
                      </div>
                      <i className="uil uil-filter font-size-24 text-muted"></i>
                    </Media>
                  </div>
                </Link>
                <Collapse isOpen={isOpen}>
                  <div className="p-4 border-top">
                    <Row className="mb-3">
                      <label
                        className="form-label"
                        htmlFor="Representative"
                      >
                        What can you do here?
                      </label>
                    </Row>
                    <Row className="mb-3">
                      <label
                        className="form-label"
                        htmlFor="Representative"
                      >
                        Connect your WeCrest account with entities that are owned by, controlled by, subsidiaries of, or the same as your firm.
                      </label>
                    </Row>
                    <Row className="mb-3">
                      <label
                        className="form-label"
                        htmlFor="Representative"
                      >
                        How do you do it?
                      </label>
                    </Row>
                    <Row className="mb-3">
                      <label
                        className="form-label"
                        htmlFor="Representative"
                      >
                        You can search by name or domain to identify your entity and request to connect with them.
                      </label>
                    </Row>
                    <Row className="mb-3">
                      <label
                        className="form-label"
                        htmlFor="Representative"
                      >
                        Please note that any given entity may use alternative spellings for their name or address as recorded by WIPO. We automatically group together entities that we believe are the same. You can review these groupings and make corrections, if necessary.
                      </label>
                    </Row>
                    <Row className="mb-3">
                      <label
                        className="form-label"
                        htmlFor="Representative"
                      >
                        You can add your domains here.
                      </label>
                    </Row>
                    
                    <Row className="mb-3 border-top" style={{paddingTop: "1.5rem"}}>
                      <label
                        className="form-label"
                        htmlFor="Representative"
                      >
                        My domains
                      </label>
                    </Row>
                    <Row className="mb-3">
                      <label
                          htmlFor="domain-text-input"
                          className="col-md-5 col-form-label"
                          style={{textAlign: "center"}}
                          >
                          Domain
                      </label>
                      <label
                          htmlFor="domain-text-input"
                          className="col-md-2 col-form-label"
                          style={{textAlign: "center"}}
                          >
                          &nbsp;
                      </label>
                    </Row>

                    {
                      userDomainList && userDomainList.length > 0 ?
                      userDomainList.map((userDomain, index)=>{
                        return (
                          <Row className="mb-3">
                            <div className="col-md-5">
                              {
                                userDomain.changeDomain && userDomain.changeDomain != '' ?
                                <>&nbsp;&nbsp;&nbsp;{userDomain.domain}&nbsp;&nbsp;<span className="text-warning">(You requested change this domain to "{userDomain.changeDomain}")</span></>
                                :
                                <input
                                className="form-control"
                                type="text"
                                name="domain"
                                onChange={(e) => {
                                  if (e.target.value.indexOf("/") > -1) {
                                    dispatch(onSetResult("Domain cannot include slash('/')."));
                                  } else if (e.target.value.indexOf(",") > -1) {
                                    dispatch(onSetResult("Domain cannot include comma(',')."));
                                  } else {
                                    changeUserDomain(index, e.target.value)
                                  }
                                }}
                                onBlur={(e) => { 
                                  if (e.target.value.indexOf("/") === -1 && e.target.value.indexOf(",") === -1 && result === "") {
                                    modifyUserDomain('domain', index) 
                                  }
                                }}
                                value={userDomain.domain}
                              />
                              }
                            </div>
                            <div className="col-md-2" style={{textAlign: "center"}}>
                              {
                                /*
                                <input
                                className="form-check-input"
                                type="radio"
                                name="status"
                                onChange={(e) => {
                                  if (userDomain.mainYn === 'N' && e.target.checked) {
                                    modifyUserDomain('mainYn', index)}
                                  }
                                }
                                value={index}
                                checked={userDomain.mainYn === 'Y'}
                              />
                              
                                userDomain.mainYn === 'N' ?
                                
                                : null
                                */
                              }
                              {
                              <Link to="#" 
                                  onClick={() => {
                                    deleteUserDomain(index);
                                  }}
                                >
                                  <i className="uil uil-trash-alt font-size-18 text-danger" id={"deleteBillingProfile_"+index}></i>
                                  <UncontrolledTooltip placement="top" target={"deleteBillingProfile_"+index}>
                                  Delete user domain
                                  </UncontrolledTooltip>
                                </Link>
                              }
                            </div>
                          </Row>
                        )
                      })
                      :
                      null
                    }

                    <Row className="">
                      <div className="col-md-5">
                        <input
                          className="form-control"
                          type="text"
                          name="domain"
                          onChange={(e) => {setDomain(e.target.value)}}
                          value={domain}
                        />
                      </div>
                      <div className="col-md-2" style={{textAlign: "center"}}>
                        <Button
                          type="button"
                          color="primary"
                          className="w-md"
                          onClick={()=>{addUserDomain()}}
                        >
                        Save
                        </Button>
                      </div>
                    </Row>
                  </div>
                </Collapse>
              </Card>
            </Col>
          </Row>
          
          <SearchEntity/>

          {success? (
          <SweetAlert
            title={success}
            timeout={2000}
            showCloseButton={false}
            showConfirm={false}
            success
            onConfirm={() => {
              dispatch(onSetSuccess(""));
            }}
          ></SweetAlert>
          ) : null}

          {result? (
          <SweetAlert
            warning
            onConfirm={() => {
              dispatch(onSetResult(""));
            }}
            onCancel={() => {
              dispatch(onSetResult(""));
            }}
          >
            {result}
          </SweetAlert>
          ) : null}

          {changeUserDomainIdx > -1 ? (
            <SweetAlert
              showCancel
              confirmBtnText="Confirm"
              cancelBtnText="Cancel"
              confirmBtnBsStyle="success"
              cancelBtnBsStyle="danger"
              title="Are you sure you want to change your domain?"
              onConfirm={() => {
                // dispatch(onAddUserEntityConnectClaim({userNo: userDomainList[changeUserDomainIdx].userNo, type: 'CUD', domain: userDomainList[changeUserDomainIdx].domain, userDomainSeq: userDomainList[changeUserDomainIdx].seq}, {}));
                dispatch(onModifyUserDomain([{userNo: userDomainList[changeUserDomainIdx].userNo, type: 'CUD', domain: userDomainList[changeUserDomainIdx].domain, orgDomain: userDomainList[changeUserDomainIdx].orgDomain, seq: userDomainList[changeUserDomainIdx].seq}]));
                setDeleteMyEntityList([]);
                setChangeUserDomainIdx(-1);
              }}
              onCancel={() => {
                changeUserDomain(changeUserDomainIdx, userDomainList[changeUserDomainIdx].orgDomain);
                setDeleteMyEntityList([]);
                setChangeUserDomainIdx(-1)
              }}
            >
              "{userDomainList[changeUserDomainIdx].orgDomain}" to "{userDomainList[changeUserDomainIdx].domain}"
              {
                deleteMyEntityList && deleteMyEntityList.length !== 0 ? 
                <>
                <br/><br/>
                Connected entity:<br/>
                {deleteMyEntityList.map((entity, index)=>{
                  return (<>{entity.targetGroupName}<br/></>)
                })}
                <br/>
                <div style={{color:'red'}}>
                * When you change your domain, all previously connected entities will be disconnected and all individual email addresses registered in My case management are removed.
                </div>
                </>
                : null
              }
            </SweetAlert>
          ) : null}

          {deleteUserDomainIdx > -1 ? (
            <SweetAlert
              showCancel
              confirmBtnText="Confirm"
              cancelBtnText="Cancel"
              confirmBtnBsStyle="success"
              cancelBtnBsStyle="danger"
              title="Are you sure you want to delete this domain?"
              onConfirm={() => {
                dispatch(onRemoveUserDomain({userNo: userDomainList[deleteUserDomainIdx].userNo, type: 'DELDomain', domain: userDomainList[deleteUserDomainIdx].domain, userDomainSeq: userDomainList[deleteUserDomainIdx].seq}));
                setDeleteMyEntityList([]);
                setDeleteUserDomainIdx(-1);
              }}
              onCancel={() => {
                // changeUserDomain(deleteUserDomainIdx, userDomainList[deleteUserDomainIdx].orgDomain);
                setDeleteMyEntityList([]);
                setDeleteUserDomainIdx(-1)
              }}
            >
              {
                deleteMyEntityList && deleteMyEntityList.length !== 0 ? 
                <>
                Connected entity:<br/>
                {deleteMyEntityList.map((entity, index)=>{
                  return (<>{entity.targetGroupName}<br/></>)
                })}
                <br/>
                <div style={{color:'red'}}>
                * When you delete your domain, all previously connected entities will be disconnected and all individual email addresses registered in My case management are removed.
                </div>
                </>
                : null
              }
            </SweetAlert>
          ) : null}
        </div>
      </div>
    </React.Fragment>
  )
}

export default EntityConnect;