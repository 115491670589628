import {
  GET_USER_UNLOCK_INFO,
  GET_USER_UNLOCK_INFO_SUCCESS,
} from "./actionTypes"

const initialState = {
  unlockCredit: 0,
  unlockCnt: 0,
  loading: false,
  errorMessage: "",
}

const Unlock = (state = initialState, action) => {
  switch (action.type) {
    case GET_USER_UNLOCK_INFO:
      return {
        ...state,
        // loading: true,
      }
    case GET_USER_UNLOCK_INFO_SUCCESS:
      // console.log("GET_USER_UNLOCK_INFO_SUCCESS : " + JSON.stringify(action.payload))
      return {
        ...state,
        // loading: false,
        unlockCredit: action.payload.unlockCredit,
        unlockCnt: action.payload.unlockCnt,
      }
    default:
      state = { ...state }
      break
  }
  return state
}

export default Unlock