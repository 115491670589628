import React, {useState} from 'react';
import { useSelector, useDispatch } from "react-redux";
import {
    Table,
    Card,
    CardBody,
    Media,
    Collapse,
    Modal,
  } from "reactstrap"
import { Link } from "react-router-dom";

import '../../components/Common/Common.css';

import {
  setViewDomainActionHistory,
} from "../../store/actions";

const AdmDomainActionHistory = props => {
  
  const dispatch = useDispatch();

  const { viewDomainActionHistory, domainActionHistory} = useSelector(state => ({
    viewDomainActionHistory: state.AdmDomainManagement.viewDomainActionHistory,
    domainActionHistory: state.AdmDomainManagement.domainActionHistory,
  }));

  const [isOpenHistory, setIsHistory] = useState(true);
  const toggleHistory = () => {
    setIsHistory(!isOpenHistory);
  }

  return (
    <React.Fragment>
      {
        viewDomainActionHistory ?
        <Modal
          size="xl"
          isOpen={true}
          toggle={() => {
            dispatch(setViewDomainActionHistory(false))
          }}
        >
          <div className="modal-header" style={{borderBottom: "0px", padding: "0.8rem"}}>
            
            <button
              onClick={() => {
                dispatch(setViewDomainActionHistory(false))
              }}
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            
            
            {domainActionHistory && domainActionHistory.length?
            <Card className="mb-0">
              <CardBody className="p-1">
                <Link onClick={toggleHistory} className="text-dark" to="#">
                  <div style={{padding: "0.75rem"}}>
                    <Media className="d-flex align-items-center">
                      <div className="flex-grow-1 overflow-hidden">
                        <p className="text-muted mb-0">History</p>
                      </div>
                      <i className={isOpenHistory ? "mdi mdi-chevron-up accor-down-icon font-size-24" : "mdi mdi-chevron-down accor-down-icon font-size-24"} style={{ cursor: "pointer"}}></i>
                    </Media>
                  </div>
                </Link>
                <Collapse isOpen={isOpenHistory}>
                  <Table className="table mb-0">
                    <tbody>
                      {domainActionHistory.map((domainAction, index)=>{
                        return (
                          <tr><td width="15%">{domainAction.actionDate}</td><td>{domainAction.userName}</td><td>{domainAction.action}</td></tr>
                        )
                      })}
                    </tbody>
                  </Table>
                </Collapse>
              </CardBody>
            </Card>
            :null}
          </div>
          <div className="modal-footer">
            <button
              type="button"
              onClick={() => {
                dispatch(setViewDomainActionHistory(false))
              }}
              className="btn btn-secondary waves-effect"
              data-dismiss="modal"
            >
              Close
            </button>
          </div>
        </Modal>
        : null
      }
    </React.Fragment>
  )
}

export default AdmDomainActionHistory;