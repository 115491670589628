import React, {useState, useEffect, useRef, useMemo} from 'react';
import { useSelector, useDispatch } from "react-redux";
import {
    Table,
    Row,
    Col,
    Card,
    CardBody,
    CardTitle,
    Media,
    Collapse,
    Form,
    Input,
    Label,
    Button,
    TabContent,
    TabPane,
    NavLink,
    NavItem,
    Nav,
    FormFeedback,
    UncontrolledTooltip,
    Modal,
  } from "reactstrap"
import SweetAlert from "react-bootstrap-sweetalert";
import LoadingModal from "../../components/Common/LoadingModal"
import { Link } from "react-router-dom";
import { useFormik } from "formik";
import { DragableTable } from "../../components/Common/DragableTable";

import {
  getServiceManualList,
  updateServiceManual,
  updateServiceManualPriority,
  changeServiceManualList,
  changeServiceManualInfo,
  changeServiceManualResult,
  changeServiceManualLoading,
  changeIsReload,
  getServiceManualPageNameList,
} from "../../store/actions";
import { cloneDeep } from 'lodash';

import '../../components/Common/Common.css';

const AdmServiceManualManagement = props => {
  const dispatch = useDispatch();
  
  const { loading, result, totalPage, serviceManualList, pageNames, isReload } = useSelector(state => ({
    loading: state.ServiceManual.loading,
    result : state.ServiceManual.result,
    totalPage: state.ServiceManual.totalPage,
    serviceManualList: state.ServiceManual.serviceManualList,
    pageNames: state.ServiceManual.pageNames,
    isReload: state.ServiceManual.isReload,
  }));

  const [inputServiceManual, setInputServiceManual] = useState({pageName:'Madrid Notifier', functionName:'', vodLink:''});

  const msg = [
      "Success"
    , "Please select a entity."
    , "This page & function name already exists!"
  ];

  const [isOpen, setIsOpen] = useState(true);
  const toggle = () => {
    setIsOpen(!isOpen);
  }

  const [curPageInput, setCurPageInput] = useState(1);
  const [chkAllFlag, setChkAllFlag] = useState(false);

  const viewServiceManual = (sortType, curPage, perPage) => {
    //curPage 유효성 체크
    if(curPage){
      if(curPage > totalPage) {
        curPage = totalPage;
      } else if (curPage <= 0) {
        curPage = 1;
      }
    }

    filterValidation.setFieldValue("submitType", "view");
   
    if(curPage){
      filterValidation.setFieldValue("curPage", curPage);
      setCurPageInput(curPage);
    }else{
      filterValidation.setFieldValue("curPage", 1);
      setCurPageInput(1);
    }
    if(perPage){
      filterValidation.setFieldValue("perPage", perPage);
    }
    
    filterValidation.handleSubmit(filterValidation.values);
  }

  const filterValidation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      submitType: "view",
      pageName: "",
      functionName: "",
      perPage: 20,
      curPage: 1,
    },
    
    onSubmit: (values) => {
      const filterParam = {
        pageName: values["pageName"],
        functionName: values["functionName"],
        perPage: values["perPage"],
        curPage: values["curPage"],
      };

      let submitType = values["submitType"];
      
      if(submitType === "view"){
        dispatch(getServiceManualList(filterParam));
      }
    }
  })

  const setServiceManualListLoading = () => {
    dispatch(changeServiceManualLoading(!loading));
  }

  const setServiceManualList = (_serviceManualList) => {
    if (_serviceManualList) dispatch(changeServiceManualList(_serviceManualList));
  }
  
  const columns = useMemo(
    () => [
      {
        Header: "Page",
        accessor: "pageName"
      },
      {
        Header: "Function",
        accessor: "functionName"
      },
      {
        Header: "Vod Link",
        accessor: "vodLink"
      },
      {
        Header: "PDF file",
        accessor: "pdfLink"
      },
      {
        Header: "VIEW",
        accessor: "viewCnt"
      },
      {
        Header: "PRIORITY",
        accessor: "priority"
      },
    ],
    []
  );

  useEffect(()=> {
    if (isReload) {
      setInputServiceManual({pageName:'Madrid Notifier', functionName:'', vodLink:''});
      dispatch(changeIsReload(false));
      filterValidation.setFieldValue("submitType", "view");
      filterValidation.handleSubmit(filterValidation.values);
    }
  },[isReload]);

  useEffect(()=> {
    dispatch(getServiceManualPageNameList());
    filterValidation.setFieldValue("submitType", "view");
    filterValidation.handleSubmit(filterValidation.values);
  },[]);

  return (
    <React.Fragment>
      <Form onSubmit={filterValidation.handleSubmit}>
        <div className="page-content">
          <div className="container-fluid">
            <Row>
              <Col lg={12}>
                <Card>
                  <Link onClick={toggle} className="text-dark" to="#">
                    <div className="p-4">
                      <Media className="d-flex align-items-center">
                        <div className="flex-shrink-0 me-3">
                          <i className="uil uil-book-open text-primary h2"></i>
                        </div>
                        <div className="flex-grow-1 overflow-hidden">
                          <h5 className="font-size-16 mb-1">WeCrest Manual management</h5>
                        </div>
                        <i className={isOpen ? "mdi mdi-chevron-up accor-down-icon font-size-24" : "mdi mdi-chevron-down accor-down-icon font-size-24"} style={{ cursor: "pointer"}}></i>
                      </Media>
                    </div>
                  </Link>
                  <Collapse isOpen={isOpen}>
                    <div className="p-4 border-top">
                      <div>
                        <Row className="mb-3">
                          <label
                          htmlFor="irNumber"
                          className="col-md-2 col-form-label"
                          >
                          Page
                          </label>
                          <div className="col-md-3">
                          <Input
                              className="form-control"
                              type="text"
                              placeholder="Enter page name"
                              id="pageName"
                              name="pageName"
                              onChange={filterValidation.handleChange}
                              value={filterValidation.values.pageName}
                              onKeyPress={(e) => {
                                if(e.key === 'Enter'){
                                  setCurPageInput(1);
                                  filterValidation.setFieldValue("curPage", 1);
                                  filterValidation.setFieldValue("submitType", "view");
                                  filterValidation.handleSubmit(filterValidation.values);
                                }                      
                              }}
                          />
                          </div>
                        </Row>
                        
                        <Row className="mb-3">
                          <label
                          htmlFor="toEmailMn"
                          className="col-md-2 col-form-label"
                          >
                          Function
                          </label>
                          <div className="col-md-3">
                          <Input
                              className="form-control"
                              type="text"
                              placeholder="Enter function name"
                              id="functionName"
                              name="functionName"
                              onChange={filterValidation.handleChange}
                              value={filterValidation.values.functionName}
                              onKeyPress={(e) => {
                                if(e.key === 'Enter'){
                                  setCurPageInput(1);
                                  filterValidation.setFieldValue("curPage", 1);
                                  filterValidation.setFieldValue("submitType", "view");
                                  filterValidation.handleSubmit(filterValidation.values);
                                }                      
                              }}
                          />
                          </div>
                          <button
                            type="button"
                            className="btn btn-primary waves-effect waves-light col-md-1"
                            onClick={()=>{
                              setCurPageInput(1);
                              filterValidation.setFieldValue("curPage", 1);
                              filterValidation.setFieldValue("submitType", "view");
                              filterValidation.handleSubmit(filterValidation.values);
                            }}
                          >
                            Search
                          </button>
                        </Row>
                      </div>
                    </div>
                  </Collapse>
                </Card>
              </Col>
            </Row>
            <Row>
              <Col lg={12}>
                <Card>
                  <CardBody>
                    <div className="table-responsive">
                      <Table className="table mb-0">
                        <thead>
                          <tr>
                          {
                          columns.filter((column) => (column.Header != 'VIEW' && column.Header != 'PRIORITY'))
                                 .map((column) =>{
                            return(<th>{column.Header}</th>)
                          })
                          }
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>
                              <select 
                                className="form-select" 
                                onChange={e => {
                                  let _inputServiceManual = cloneDeep(inputServiceManual);
                                  _inputServiceManual.pageName = e.target.value;
                                  setInputServiceManual(_inputServiceManual);
                                }}
                                value={inputServiceManual.pageName}
                              >
                                {pageNames.map((pageName,idx) => { 
                                  return (<option value={pageName}>{pageName}</option>);
                                })}
                              </select>
                            </td>
                            <td>
                              <Input
                                className="form-control"
                                type="text"
                                placeholder="Enter function"
                                value={inputServiceManual.functionName}
                                onChange={(e) => { 
                                  let _inputServiceManual = cloneDeep(inputServiceManual);
                                  _inputServiceManual.functionName = e.target.value;
                                  setInputServiceManual(_inputServiceManual);
                                }}
                              />
                            </td>
                            <td>
                              <Input
                                className="form-control"
                                type="text"
                                placeholder="Enter Vod Link"
                                value={inputServiceManual.vodLink}
                                onChange={(e) => { 
                                  let _inputServiceManual = cloneDeep(inputServiceManual);
                                  _inputServiceManual.vodLink = e.target.value;
                                  setInputServiceManual(_inputServiceManual);
                                }}
                              />
                            </td>
                            <td>
                              <div className="d-flex vertical-align-center gap-2">
                                <input type="file" 
                                  className="form-control"
                                  accept="application/pdf"
                                  onChange={(e) => {
                                    let _inputServiceManual = cloneDeep(inputServiceManual);
                                    _inputServiceManual.pdfFile = e.target.files[0];
                                    setInputServiceManual(_inputServiceManual);
                                  }}
                                  name="pdf"
                                  placeholder="Select pdf file"
                                />
                                <Button
                                  type="button"
                                  color="primary"
                                  outline
                                  className="waves-effect waves-light col-md-2"
                                  onClick={()=>{
                                    let isDupePageName = false;
                                    serviceManualList.map((serviceManual, idx) => {
                                      if (serviceManual.pageName == inputServiceManual.pageName && serviceManual.functionName == inputServiceManual.functionName) {
                                        isDupePageName = true;
                                      }
                                    })

                                    if (isDupePageName) {
                                      dispatch(changeServiceManualResult(2));
                                    } else {
                                      const formData = new FormData();
                                      formData.append('pageName', inputServiceManual.pageName);
                                      formData.append('functionName', inputServiceManual.functionName);
                                      formData.append('vodLink', inputServiceManual.vodLink);
                                      formData.append('pdfFile', inputServiceManual.pdfFile);
                                      formData.append('crudType', 'C');
  
                                      dispatch(updateServiceManual(formData));
                                    }
                                  }}
                                >
                                  Add
                                </Button>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </Table>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Row>
              <Col lg={12}>
                <Card>
                  <CardBody>
                    <div className="table-responsive">
                      {serviceManualList && serviceManualList.length ?
                      <DragableTable manuName='admServiceManual' columns={columns} data={serviceManualList} setData={setServiceManualList} />
                      : null}
                    </div>
                    <div className="d-flex justify-content-between">
                      <span className="d-flex align-items-center gap-2">
                        <label
                          htmlFor="perPage"
                          >
                          Entries per page
                        </label>
                        <div style={{width: "100px"}}>
                          <select 
                            className="form-select" 
                            name="perPage"
                            onChange={e => {viewServiceManual(null, 1, e.target.value);}}
                            value={filterValidation.values.perPage}
                          >
                            <option value="10">10</option>
                            <option value="20">20</option>
                            <option value="30">30</option>
                            <option value="40">40</option>
                            <option value="50">50</option>
                          </select>
                        </div>
                      </span>
                      <span className="d-flex align-items-center gap-2">
                        <div className="d-flex gap-1">
                          <Button
                            type="button"
                            color="primary"
                            onClick={()=>{viewServiceManual(null, 1);}}
                            disabled={(filterValidation.values.curPage === 1)}
                          >
                            {"<<"}
                          </Button>
                          <Button
                            type="button"
                            color="primary"
                            onClick={()=>{viewServiceManual(null, filterValidation.values.curPage-1);}}
                            disabled={(filterValidation.values.curPage === 1)}
                          >
                            {"<"}
                          </Button>
                        </div>
                        Page{" "}
                        <strong>
                          {filterValidation.values.curPage} of {totalPage}
                        </strong>
                        
                        <Input
                          type="number"
                          min={1}
                          style={{ width: 70 }}
                          max={totalPage}
                          name="curPage"
                          defaultValue={filterValidation.values.curPage}
                          value={curPageInput}
                          onChange={(e) => { setCurPageInput(e.target.value)}}
                          onKeyPress={(e) => {
                            if(e.key === 'Enter'){
                              viewServiceManual(null, e.target.value);
                            }                      
                          }}
                        />
                        <div className="d-flex gap-1">
                          <Button 
                            type="button"
                            color="primary"
                            onClick={()=>{viewServiceManual(null, filterValidation.values.curPage+1);}}
                            disabled={(filterValidation.values.curPage === totalPage)}
                          >
                            {">"}
                          </Button>
                          <Button
                            type="button"
                            color="primary"
                            onClick={()=>{viewServiceManual(null, totalPage);}}
                            disabled={(filterValidation.values.curPage === totalPage)}
                          >
                            {">>"}
                          </Button>
                        </div>
                      </span>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            {
              loading ?
              <LoadingModal loading={loading} setLoading={setServiceManualListLoading}></LoadingModal>
              : null
            }

            {result !== -1 ? (
            <SweetAlert
              title={msg[result]}
              onConfirm={() => {
                dispatch(changeServiceManualResult(-1));
              }}
            />
            ) : null}
          </div>
        </div>
      </Form>
    </React.Fragment>
  )
}

export default AdmServiceManualManagement;