import React, {useState, useEffect, useRef, useCallback} from 'react';
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";
import {
    Table,
    Row,
    Col,
    Card,
    CardBody,
    CardTitle,
    Media,
    Collapse,
    Form,
    Input,
    Label,
    Button,
    UncontrolledTooltip,
  } from "reactstrap"
import LoadingModal from "../../components/Common/LoadingModal"
import SweetAlert from "react-bootstrap-sweetalert";
import PeriodBtn from '../../components/Filter/PeriodBtn';
import { Link } from "react-router-dom";
import * as Yup from "yup";
import { useFormik } from "formik";
import PreviewInvoice from "./PreviewInvoice"
import EditInvoice from "./EditInvoice"

import {
  getAdmInvoiceManagementSearchList as onGetAdmInvoiceManagementSearchList,
  changeAdmInvoiceManagementSearchList as onChangeAdmInvoiceManagementSearchList,
  clearadmInvoiceManagementSearchList as onClearadmInvoiceManagementSearchList,
  downInvoicePdf as onDownInvoicePdf,
  updateInvoiceStatus as onUpdateInvoiceStatus,
  setAdmInvoiceManagementSearchSuccess as onSetSuccess,
  setAdmInvoiceManagementSearchResult as onSetResult,
  confirmPayment as onConfirmPayment,
  previewInvoiceItem as onPreviewInvoiceItem,
  changeAdmInvoiceManagementUserInfo as onChangeAdmInvoiceManagementUserInfo,
  changeAdmInvoiceManagementItemList as onChangeAdmInvoiceManagementItemList,
  changeUserInvoiceInfoList as onChangeUserInvoiceInfoList,
} from "../../store/actions";
import { cloneDeep } from 'lodash';
import dateFormat from 'dateformat';

const AdmInvoiceManagementSearch = props => {
  const dispatch = useDispatch();

  const { admInvoiceManagementSearchList, loading, noticeStr, totalPage, success, result, previewInvoiceItem } = useSelector(state => ({
    admInvoiceManagementSearchList: state.AdmInvoiceManagement.admInvoiceManagementSearchList,
    loading: state.AdmInvoiceManagement.loading,
    noticeStr: state.AdmInvoiceManagement.noticeStr,
    totalPage: state.AdmInvoiceManagement.totalPage,
    success: state.AdmInvoiceManagement.success,
    result: state.AdmInvoiceManagement.result,
    previewInvoiceItem: state.AdmInvoiceManagement.previewInvoiceItem,
  }));

  const [isOpenPreview, setIsOpenPreview] = useState(false);
  const [isOpenEdit, setIsOpenEdit] = useState(false);
  const [editInvoiceNo, setEditInvoiceNo] = useState(0);
  const [editUserNo, setEditUserNo] = useState(0);
  const [isOpen, setIsOpen] = useState(true);

  const toggle = () => {
    setIsOpen(!isOpen);
  }

  const [columns, setColumns] = useState([
    {label: "Username", asc:"UNA", desc:"UND", ascActive:"", descActive:""},
    {label: "Invoice number", asc:"INA", desc:"IND", ascActive:"", descActive:""},
    {label: "Currency", asc:"CRA", desc:"CRD", ascActive:"", descActive:""},
    {label: "Invoiced", asc:"TOA", desc:"TOD", ascActive:"", descActive:""},
    {label: "Amount paid", asc:"APA", desc:"APD", ascActive:"", descActive:""},
    {label: "Invoice", asc:"IDA", desc:"IDD", ascActive:"", descActive:"text-danger"},
    {label: "Due", asc:"DDA", desc:"DDD", ascActive:"", descActive:""},
    {label: "Payment", asc:"PDA", desc:"PDD", ascActive:"", descActive:""},
    {label: "Status", asc:"STA", desc:"STD", ascActive:"", descActive:""},
    {label: "", asc:null, desc:null, ascActive:null, descActive:null},
  ]);

  const [curPageInput, setCurPageInput] = useState(1);
  const [chkAllFlag, setChkAllFlag] = useState(false);
  const [chkFlag, setChkFlag] = useState(false);
  const [changeInvoiceStatus, setChangeInvoiceStatus] = useState("");
  const [confirmMsg, setConfirmMsg] = useState("");
  const [confirmAlert, setConfirmAlert] = useState(false);
  const [confirmBtnText, setConfirmBtnText] = useState("");
  const [cancelBtnText, setCancelBtnText] = useState("");
  const [changeInvoiceUserNo, setChangeInvoiceUserNo] = useState("");
  const [changeInvoiceNo, setChangeInvoiceNo] = useState("");
  const [paymentAlert, setPaymentAlert] = useState(false);

  const errMsg = [
    "Input Amount paid!"
    , "Input Payment date!"
    , "System Error. Contact your system administrator!"
    , "Please enter your Paypal Plan ID!"
    , "Please enter your Paypal Subscription ID!"
    , "Please enter your Paypal Onetime link!"
  ];

  // events validation
  const filterValidation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    //enableReinitialize: true,

    initialValues: {
      inOutType: "OUT",
      submitType: "view",
      memberKeyword: "",
      searchInvoice: "",
      statusList: ["Outstanding", "Pending"],
      dateType: "Invoice",
      datePeriod: "",
      sortType: "IDD",
      perPage: 50,
      curPage: 1,
      fromDate: undefined,
      toDate: undefined,

      amountPaid: "",
      paymentDate: "",
      invoiceStatus: "",
      paymentMethod: "",
      paymentType: "",
      paypalPlanID: "",
      paypalSubscriptionID: "",
      paypalOnetimeLink: "",
    },
    // validationSchema: Yup.object().shape({
    //   fromDate: Yup.date()
    //     .max(Yup.ref('toDate'), "From date must be earlier than to date")
    //     .required("Please Select From date"),
    //   toDate: Yup.date()
    //     .max(new Date(), "Date cannot be in the future")
    //     .required("Please Select to date"),
    // }),
    validateOnChange: false, // this one
    validateOnBlur: false, // and this one
    onSubmit: (values) => {
      //console.log(JSON.stringify(values, null, 2));
      
      // let _fromDateStr = values["fromDate"].replace(/-/gi,"/");
      // let _toDateStr = values["toDate"].replace(/-/gi,"/");
      // let newEndDate = new Date(values["toDate"]);
      // newEndDate.setDate(newEndDate.getDate() + 1);
      
      // let period = Math.abs(newEndDate.getTime() - new Date(values["fromDate"]).getTime());
      // period = Math.ceil(period / (1000 * 3600 * 24));
      
      // let _periodstr = period +" day(s)";
      // let _noticeStr = "General Statistics for period: "+_periodstr+" ["+_fromDateStr+" to "+_toDateStr+"]";
      // let _timePeriod = _fromDateStr+" to "+_toDateStr+" ["+period +" day(s)]";
      // let fileName = dateFormat(new Date(), "yymmdd") + " WeCrest General Statistics (" + values["fromDate"].replace(/-/gi,"") + "-" + values["toDate"].replace(/-/gi,"") + ")"; 

      const filterParam = {
        memberKeyword: values["memberKeyword"],
        searchInvoice: values["searchInvoice"],
        statusList: values["statusList"],
        dateType: values["dateType"],
        beginDate: values["fromDate"],
        endDate: values["toDate"],
        sortType: values["sortType"],
        perPage: values["perPage"],
        curPage: values["curPage"],
      };
      
      const confirmParam = {
        invoiceNo: changeInvoiceNo,
        amountPaid: values["amountPaid"],
        dateOfPayment: values["paymentDate"],
        invoiceStatus: values["invoiceStatus"],
        paymentMethod: values["paymentMethod"],
        paymentType: values["paymentType"],
        paypalPlanID: values["paypalPlanID"],
        paypalSubscriptionID: values["paypalSubscriptionID"],
        paypalOnetimeLink: values["paypalOnetimeLink"],
      };

      let submitType = values["submitType"];
      
      if(submitType === "view"){
        dispatch(onGetAdmInvoiceManagementSearchList(filterParam));
      } else if(submitType === "updateInvoiceStatus"){
        const userNoList = [];
        const invoiceNoList = [];

        if (changeInvoiceUserNo !== '' && changeInvoiceNo !== '') {
          userNoList.push(changeInvoiceUserNo);
          invoiceNoList.push(changeInvoiceNo);
        } else {
          admInvoiceManagementSearchList.map((row, index) =>{
            if(row.checked === true){
              userNoList.push(row.userNo);
              invoiceNoList.push(row.invoiceNo);
            }
          }); 
        }

        const updateParam = {userNoList:userNoList, invoiceNoList:invoiceNoList, invoiceStatus:changeInvoiceStatus};
        
        dispatch(onUpdateInvoiceStatus(filterParam, updateParam));
      } else if(submitType === "confirmPayment"){
        // console.log('updateInvoiceStatus.filterParam : ' + JSON.stringify(filterParam));
        // console.log('updateInvoiceStatus.confirmParam : ' + JSON.stringify(confirmParam));
        
        dispatch(onConfirmPayment(filterParam, confirmParam));
      }
    },
  });

  const viewAdmInvoiceManagementSearch = (sortType, curPage, perPage) => {
    //curPage 유효성 체크
    if(curPage){
      if(curPage > totalPage) {
        curPage = totalPage;
      } else if (curPage <= 0) {
        curPage = 1;
      }
    }
    //빨간색으로 변경
    if(sortType){
      const _columns = [];
      columns.map((col, index)=>{
        let _col = cloneDeep(col);
        if(col.asc && col.asc === sortType){
          _col.ascActive = "text-danger";
          _col.descActive = "";
        }else if(col.desc && col.desc === sortType ){
          _col.ascActive = "";
          _col.descActive = "text-danger";
        }else{
          _col.ascActive = "";
          _col.descActive = "";
        }
        _columns.push(_col);
      });

      setColumns(_columns);
    }

    filterValidation.setFieldValue("submitType", "view");
    if(sortType){
      filterValidation.setFieldValue("sortType", sortType);
    }
    if(curPage){
      filterValidation.setFieldValue("curPage", curPage);
      setCurPageInput(curPage);
    }else{
      filterValidation.setFieldValue("curPage", 1);
      setCurPageInput(1);
    }
    if(perPage){
      filterValidation.setFieldValue("perPage", perPage);
    }
    
    filterValidation.handleSubmit(filterValidation.values);
  }

  const rowCheck = (str, checked) => {

    console.log('rowCheck : ' + checked);
    let chkCnt = 0;

    const _admInvoiceManagementSearchList = [];
    admInvoiceManagementSearchList.map((row, index) =>{
        let _row = cloneDeep(row);

        if(str === "chkAll" || str === index){
            _row.checked = checked;
        }

        if(_row.checked === true){
          chkCnt++;
        }

        _admInvoiceManagementSearchList.push(_row);
    }); 

    if(admInvoiceManagementSearchList.length === chkCnt){
        setChkAllFlag(true);
    }else{
        setChkAllFlag(false);
    }

    if (chkCnt > 0) {
      setChkFlag(true);
    } else {
      setChkFlag(false);
    }

    dispatch(onChangeAdmInvoiceManagementSearchList(_admInvoiceManagementSearchList));
  }

  const editInvoice = (invoiceNo, userNo) => {
    // console.log("editInvoice : " + invoiceNo);
    setEditInvoiceNo(invoiceNo);
    setEditUserNo(userNo);
    setIsOpenEdit(true);
  }

  const previewInvoice = (paramInvoiceNo) => {
    // console.log("previewInvoice : " + paramInvoiceNo);
    dispatch(onPreviewInvoiceItem({invoiceNo: paramInvoiceNo}));
  }

  const savePdf = () => {
    const invoiceNoList = [];
    admInvoiceManagementSearchList.map((row, index) =>{
        if(row.checked === true){
          invoiceNoList.push(row.invoiceNo);
        }
    }); 
    
    dispatch(onDownInvoicePdf({invoiceNoList:invoiceNoList}, "Invoices.zip"));
  }

  useEffect(() => {
    if (JSON.stringify(previewInvoiceItem) !== '{}') {
      setIsOpenPreview(true);
    }
  },[previewInvoiceItem])

  useEffect(() => {
    if (!isOpenEdit) {
        dispatch(onChangeAdmInvoiceManagementUserInfo({}));
        dispatch(onChangeAdmInvoiceManagementItemList([]));
        dispatch(onChangeUserInvoiceInfoList([]));
        // console.log("Remove AdmCreateInvoiceInfo");
    }
  },[isOpenEdit])

  useEffect(() => {
    filterValidation.handleSubmit(filterValidation.values);
  },[])
  
  return (
    <React.Fragment>
      <Row>
        <Col lg={12}>
          <Card>
            <Link onClick={toggle} className="text-dark" to="#">
              <div className="p-4">
                <Media className="d-flex align-items-center">
                  <div className="flex-shrink-0 me-3">
                    <i className="uil uil-invoice text-primary h2"></i>
                  </div>
                  <div className="flex-grow-1 overflow-hidden">
                    <h5 className="font-size-16 mb-1">Invoice Management</h5>
                  </div>
                  <i className="uil uil-filter font-size-24 text-muted"></i>
                  {/* 
                  <i className={isOpen ? "mdi mdi-chevron-up accor-down-icon font-size-24" : "mdi mdi-chevron-down accor-down-icon font-size-24"}></i>
                  */}
                </Media>
              </div>
            </Link>
            <Collapse isOpen={isOpen}>
              <div className="p-4 border-top">
                <Form onSubmit={filterValidation.handleSubmit}>
                  <div>
                    
                    <Row className="mb-3">
                      <label
                      htmlFor="memberKeyword"
                      className="col-md-2 col-form-label"
                      >
                      Member
                      </label>
                      <div className="col-md-3">
                      <Input
                          className="form-control"
                          type="text"
                          placeholder="Member"
                          id="memberKeyword"
                          name="memberKeyword"
                          onChange={filterValidation.handleChange}
                          value={filterValidation.values.memberKeyword}
                          onKeyDown={(e)=>{
                            if (e.key === 'Enter') {
                              filterValidation.setFieldValue("submitType", "view");
                              filterValidation.handleSubmit(filterValidation.values);
                            }
                          }}
                      />
                      </div>
                    </Row>
                    <Row className="mb-3">
                      <label
                      htmlFor="searchInvoice"
                      className="col-md-2 col-form-label"
                      >
                      Invoice No
                      </label>
                      <div className="col-md-3">
                      <Input
                          className="form-control"
                          type="text"
                          placeholder="Invoice No"
                          id="searchInvoice"
                          name="searchInvoice"
                          onChange={filterValidation.handleChange}
                          value={filterValidation.values.searchInvoice}
                          onKeyDown={(e)=>{
                            if (e.key === 'Enter') {
                              filterValidation.setFieldValue("submitType", "view");
                              filterValidation.handleSubmit(filterValidation.values);
                            }
                          }}
                      />
                      </div>
                    </Row>
                    <Row className="mb-3 align-items-center">
                      <label htmlFor="statusList" className="col-md-2 col-form-label">
                        Status
                      </label>
                      <div className="col-md-2">
                        <div className="form-check">
                          <input type="checkbox" className="form-check-input"
                            id="entityStatusPaid"
                            name="statusList"
                            onChange={filterValidation.handleChange}
                            value="Paid"
                            checked={filterValidation.values.statusList.includes("Paid")}
                          />
                          <Label className="form-check-label" htmlFor="entityStatusPaid">
                          Paid
                          </Label>
                        </div>
                      </div>
                      <div className="col-md-2">
                        <div className="form-check">
                          <input type="checkbox" className="form-check-input"
                            id="entityStatusPartiallyPaid"
                            name="statusList"
                            onChange={filterValidation.handleChange}
                            value="PartiallyPaid"
                            checked={filterValidation.values.statusList.includes("PartiallyPaid")}
                          />
                          <Label className="form-check-label" htmlFor="entityStatusPartiallyPaid">
                          Partially paid
                          </Label>
                        </div>
                      </div>
                      <div className="col-md-2">
                        <div className="form-check">
                          <input type="checkbox" className="form-check-input"
                            id="entityStatusOutstanding"
                            name="statusList"
                            onChange={filterValidation.handleChange}
                            value="Outstanding"
                            checked={filterValidation.values.statusList.includes("Outstanding")}
                          />
                          <Label className="form-check-label" htmlFor="entityStatusOutstanding">
                          Outstanding
                          </Label>
                        </div>
                      </div>
                      <div className="col-md-2">
                        <div className="form-check">
                          <input type="checkbox" className="form-check-input"
                            id="entityStatusPending"
                            name="statusList"
                            onChange={filterValidation.handleChange}
                            value="Pending"
                            checked={filterValidation.values.statusList.includes("Pending")}
                          />
                          <Label className="form-check-label" htmlFor="entityStatusPending">
                          Pending
                          </Label>
                        </div>
                      </div>
                    </Row>
                    <Row className="mb-3 align-items-center">
                      <div class="col-md-2"><label class="col-form-label">&nbsp;</label></div>
                      
                      <div className="col-md-2">
                        <div className="form-check">
                          <input type="checkbox" className="form-check-input"
                            id="entityStatusOverdue"
                            name="statusList"
                            onChange={filterValidation.handleChange}
                            value="Overdue"
                            checked={filterValidation.values.statusList.includes("Overdue")}
                          />
                          <Label className="form-check-label" htmlFor="entityStatusOverdue">
                          Overdue
                          </Label>
                        </div>
                      </div>
                      <div className="col-md-2">
                        <div className="form-check">
                          <input type="checkbox" className="form-check-input"
                            id="entityStatusNotYetIssued"
                            name="statusList"
                            onChange={filterValidation.handleChange}
                            value="NotYetIssued"
                            checked={filterValidation.values.statusList.includes("NotYetIssued")}
                          />
                          <Label className="form-check-label" htmlFor="entityStatusNotYetIssued">
                          Not yet issued
                          </Label>
                        </div>
                      </div>
                      <div className="col-md-2">
                        <div className="form-check">
                          <input type="checkbox" className="form-check-input"
                            id="entityStatusTentative"
                            name="statusList"
                            onChange={filterValidation.handleChange}
                            value="Tentative"
                            checked={filterValidation.values.statusList.includes("Tentative")}
                          />
                          <Label className="form-check-label" htmlFor="entityStatusTentative">
                          Tentative
                          </Label>
                        </div>
                      </div>
                      <div className="col-md-2">
                        <div className="form-check">
                          <input type="checkbox" className="form-check-input"
                            id="entityStatusCancelled"
                            name="statusList"
                            onChange={filterValidation.handleChange}
                            value="Cancelled"
                            checked={filterValidation.values.statusList.includes("Cancelled")}
                          />
                          <Label className="form-check-label" htmlFor="entityStatusCancelled">
                          Cancelled
                          </Label>
                        </div>
                      </div>
                      <div className="col-md-2">
                        <div className="form-check">
                          <input type="checkbox" className="form-check-input"
                            id="entityStatusRefunded"
                            name="statusList"
                            onChange={filterValidation.handleChange}
                            value="Refunded"
                            checked={filterValidation.values.statusList.includes("Refunded")}
                          />
                          <Label className="form-check-label" htmlFor="entityStatusRefunded">
                          Refunded
                          </Label>
                        </div>
                      </div>
                    </Row>
                    <Row>
                      <label htmlFor="searchDate" className="col-md-2 col-form-label">
                      Date
                      </label>
                      <div className="col-md-10">
                        <Row className="mb-3">
                          <div className="col-md-3 form-check" style={{paddingLeft : '2em'}}>
                            <input
                              className="form-check-input"
                              type="radio"
                              name="dateType"
                              id="date_Invoice"
                              onChange={filterValidation.handleChange}
                              value="Invoice"
                              checked={filterValidation.values.dateType === 'Invoice'}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="date_Invoice"
                            >
                              Invoice
                            </label>
                          </div>
                          <div className="col-md-3 form-check" style={{paddingLeft : '2em'}}>
                            <input
                              className="form-check-input"
                              type="radio"
                              name="dateType"
                              id="date_Due"
                              onChange={filterValidation.handleChange}
                              value="Due"
                              checked={filterValidation.values.dateType === 'Due'}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="date_Due"
                            >
                              Due
                            </label>
                          </div>
                          <div className="col-md-3 form-check" style={{paddingLeft : '2em'}}>
                            <input
                              className="form-check-input"
                              type="radio"
                              name="dateType"
                              id="date_Payment"
                              onChange={filterValidation.handleChange}
                              value="Payment"
                              checked={filterValidation.values.dateType === 'Payment'}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="date_Payment"
                            >
                              Payment
                            </label>
                          </div>
                        </Row>
                        <PeriodBtn
                          formik={filterValidation}
                        />
                      </div>
                    </Row>
                    <Row className="mb-3">
                      <span className="col-md-3 button-items">
                        {/*
                        <Button
                          type="button"
                          color="primary"
                          outline
                          className="waves-effect waves-light"
                          onClick={()=>{
                            let _values = cloneDeep(filterValidation.values);
                            _values.submitType = "excel";
                            filterValidation.setValues(_values);
                            filterValidation.handleSubmit(filterValidation.values);
                          }}
                        >
                          Download Excel
                        </Button>
                        <Button
                          type="button"
                          color="primary"
                          outline
                          className="waves-effect waves-light"
                          onClick={()=>{
                            let _values = cloneDeep(filterValidation.values);
                            _values.submitType = "pdf";
                            filterValidation.setValues(_values);
                            filterValidation.handleSubmit(filterValidation.values);
                          }}
                        >
                          Download PDF
                        </Button>
                        */}
                      </span>
                      <Col md={3}>&nbsp;</Col>
                      <Col md={3}>
                        <button
                          type="button"
                          className="waves-effect waves-light btn btn-primary"
                          style={{width : '148.58px'}}
                          onClick={()=>{
                            filterValidation.setFieldValue("submitType", "view");
                            filterValidation.handleSubmit(filterValidation.values);
                          }}
                        >
                          Search
                        </button>
                      </Col>
                    </Row>
                  </div>
                </Form>
              </div>
            </Collapse>
          </Card>
        {
        loading ?
          <LoadingModal loading={loading}></LoadingModal>
        : null
        }

        {confirmAlert ? (
          <SweetAlert
            title={confirmMsg}
            showCancel
            confirmBtnText={confirmBtnText}
            cancelBtnText={cancelBtnText}
            confirmBtnBsStyle="success"
            cancelBtnBsStyle="danger"
            onConfirm={() => {
              filterValidation.setFieldValue("submitType", "updateInvoiceStatus");
              filterValidation.handleSubmit(filterValidation.values);
              setConfirmAlert(false);
            }}
            onCancel={() => setConfirmAlert(false)}
          >
          </SweetAlert>
        ) : null}

        {paymentAlert ? (
          <SweetAlert
            title="Enter Payment Details"
            showCancel
            confirmBtnText="Confirm Change"
            cancelBtnText="Cancel"
            confirmBtnBsStyle="success"
            cancelBtnBsStyle="danger"
            onConfirm={() => {
              if (filterValidation.values.amountPaid === '' || Number(filterValidation.values.amountPaid) === 0) {
                dispatch(onSetResult(0));
                return;
              }
      
              if (filterValidation.values.paymentDate === '') {
                dispatch(onSetResult(1));
                return;
              }

              if (filterValidation.values.paymentMethod === 'Paypal') {
                if (filterValidation.values.paymentType === 'Onetime') {
                  if (!filterValidation.values.paypalOnetimeLink) {
                    dispatch(onSetResult(5));
                    return;
                  }
                } else {
                  if (!filterValidation.values.paypalPlanID) {
                    dispatch(onSetResult(3));
                    return;
                  }
                  
                  if (!filterValidation.values.paypalSubscriptionID) {
                    dispatch(onSetResult(4));
                    return;
                  }
                }
              }

              filterValidation.setFieldValue("submitType", "confirmPayment");
              filterValidation.handleSubmit(filterValidation.values);
              setPaymentAlert(false);
            }}
            onCancel={() => setPaymentAlert(false)}
          >
            <div>
              <Row className="mb-3">
                <label
                htmlFor="amountPaid"
                className="col-md-6 col-form-label"
                >
                Amount paid
                </label>
                <div className="col-md-6">
                <Input
                    className="form-control"
                    type="number"
                    placeholder="Amount paid"
                    id="amountPaid"
                    name="amountPaid"
                    onChange={e => {filterValidation.setFieldValue("amountPaid", e.target.value)}}
                    value={filterValidation.values.amountPaid}
                />
                </div>
              </Row>
              <Row className="mb-3">
                <label
                htmlFor="paymentDate"
                className="col-md-6 col-form-label"
                >
                Payment Date
                </label>
                <div className="col-md-6">
                <Input
                    className="form-control"
                    type="date"
                    placeholder="0000.00.00"
                    id="paymentDate"
                    name="paymentDate"
                    onChange={e => {filterValidation.setFieldValue("paymentDate", e.target.value)}}
                    value={filterValidation.values.paymentDate}
                />
                </div>
              </Row>
              <Row className="mb-3">
                <label
                htmlFor="invoiceStatus"
                className="col-md-6 col-form-label"
                >
                Invoice Status
                </label>
                <div className="col-md-6">
                <select 
                  className="form-select" 
                  name="invoiceStatus"
                  onChange={e => {filterValidation.setFieldValue("invoiceStatus", e.target.value)}}
                  value={filterValidation.values.invoiceStatus}
                >
                  <option value="Paid">Paid</option>
                  <option value="PartiallyPaid">PartiallyPaid</option>
                  <option value="Outstanding">Outstanding</option>
                  <option value="Pending">Pending</option>
                  <option value="Overdue">Overdue</option>
                </select>
                </div>
              </Row>
              <Row className="mb-3">
                <label
                htmlFor="paymentMethod"
                className="col-md-6 col-form-label"
                >
                Payment method
                </label>
                <div className="col-md-6">
                <select 
                  className="form-select" 
                  id="paymentMethod"
                  name="paymentMethod"
                  onChange={e => {filterValidation.setFieldValue("paymentMethod", e.target.value)}}
                  value={filterValidation.values.paymentMethod}
                >
                  <option value="Paypal">Paypal</option>
                  <option value="BankTransfer">BankTransfer</option>
                </select>
                </div>
              </Row>
              <Row className="mb-3">
                <label 
                htmlFor="paymentType"
                className="col-md-6 col-form-label">
                Payment type
                </label>
                <div className="col-md-6">
                  <select 
                    className="form-select" 
                    name="paymentType"
                    onChange={filterValidation.handleChange}
                    value={filterValidation.values.paymentType}
                  >
                    <option value="Onetime">Onetime</option>
                    <option value="Subscription">Subscription</option>
                  </select>
                </div>
              </Row>
              {
              filterValidation.values.paymentMethod === 'Paypal'?
              filterValidation.values.paymentType === 'Onetime' ?
                <Row>
                  <label
                  htmlFor="paypalOnetimeLink"
                  className="col-md-6 col-form-label"
                  >
                  Paypal Onetime link *
                  </label>
                  <div className="col-md-6">
                  <Input
                      className="form-control"
                      type="text"
                      placeholder="Paypal Onetime link"
                      id="paypalOnetimeLink"
                      name="paypalOnetimeLink"
                      onChange={e => {filterValidation.setFieldValue("paypalOnetimeLink", e.target.value)}}
                      value={filterValidation.values.paypalOnetimeLink}
                  />
                  </div>
                </Row>
              :
              <>
                <Row className="mb-3">
                  <label
                  htmlFor="paypalPlanID"
                  className="col-md-6 col-form-label"
                  >
                  Paypal Plan ID *
                  </label>
                  <div className="col-md-6">
                  <Input
                      className="form-control"
                      type="text"
                      placeholder="Paypal Plan ID"
                      id="paypalPlanID"
                      name="paypalPlanID"
                      onChange={e => {filterValidation.setFieldValue("paypalPlanID", e.target.value)}}
                      value={filterValidation.values.paypalPlanID}
                  />
                  </div>
                </Row>
                <Row className="">
                  <label
                  htmlFor="paypalSubscriptionID"
                  className="col-md-6 col-form-label"
                  >
                  Paypal Subscription ID *
                  </label>
                  <div className="col-md-6">
                  <Input
                      className="form-control"
                      type="text"
                      placeholder="Paypal Subscription ID"
                      id="paypalSubscriptionID"
                      name="paypalSubscriptionID"
                      onChange={e => {filterValidation.setFieldValue("paypalSubscriptionID", e.target.value)}}
                      value={filterValidation.values.paypalSubscriptionID}
                  />
                  </div>
                </Row>
              </>
              : null
              }
            </div>
          </SweetAlert>
        ) : null}

        {success? (
          <SweetAlert
            title={success}
            timeout={2000}
            showCloseButton={false}
            showConfirm={false}
            success
            onConfirm={() => {
              dispatch(onSetSuccess(""));
            }}
          ></SweetAlert>
        ) : null}

        {result !== -1 ? (
        <SweetAlert
          warning
          title={errMsg[result]}
          onConfirm={() => {
            dispatch(onSetResult(-1));
          }}
        >
        </SweetAlert>
        ) : null}

        {admInvoiceManagementSearchList && admInvoiceManagementSearchList.length ?
          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  <CardTitle className="text-muted mb-0">
                    <Row className="mb-3">
                      <p className="col-md-2" style={{whiteSpace : "pre"}}>
                        {noticeStr}
                      </p>
                      <span className="col-md-8 button-items">
                        {
                          chkFlag ?
                          <>
                            <Button
                              type="button"
                              color="primary"
                              outline
                              className="waves-effect waves-light"
                              onClick={() => {
                                savePdf();
                              }}
                              id="btn_save_pdf"
                            >
                              Save PDF
                            </Button>
                            <UncontrolledTooltip placement="top" target="btn_save_pdf">
                            Save PDF selected cases
                            </UncontrolledTooltip>

                            <Button
                              type="button"
                              color="primary"
                              outline
                              className="waves-effect waves-light"
                              onClick={() => {
                                setChangeInvoiceStatus("Pending");
                                setConfirmMsg("Are you sure you want to pending these invoices?");
                                setConfirmBtnText("Yes, proceed!");
                                setCancelBtnText("Cancel");
                                setConfirmAlert(true);
                              }}
                              id="btn_pending"
                            >
                              Pending
                            </Button>
                            <UncontrolledTooltip placement="top" target="btn_pending">
                            Pending selected cases
                            </UncontrolledTooltip>

                            <Button
                              type="button"
                              color="primary"
                              outline
                              className="waves-effect waves-light"
                              onClick={() => {
                                setChangeInvoiceUserNo("");
                                setChangeInvoiceNo("");
                                setChangeInvoiceStatus("Cancelled");
                                setConfirmMsg("Are you sure you want to cancel these invoices?");
                                setConfirmBtnText("Yes, proceed!");
                                setCancelBtnText("Cancel");
                                setConfirmAlert(true);
                              }}
                              id="btn_cancel"
                            >
                              Cancel
                            </Button>
                            <UncontrolledTooltip placement="top" target="btn_cancel">
                            Cancel for selected cases
                            </UncontrolledTooltip>
                          </>
                          :
                          null
                        }
                      </span>
                    </Row>
                  </CardTitle>
                  <div className="table-responsive">
                    <Table className="table mb-0">
                      <thead>
                        <tr>
                        <th style={{verticalAlign: "middle"}}><input type="checkbox" checked={chkAllFlag} onClick={(e)=>rowCheck("chkAll", e.target.checked)} /></th>
                        {
                          columns && columns.length ?
                          columns.map((col, index) => {
                              return (
                                <th style={{position: "relative", whiteSpace: "pre-line", textAlign: "center", verticalAlign: "middle"}}>
                                  {col.label}
                                  {col.asc && col.desc? 
                                  <>
                                  <Link to='#' onClick={()=> {viewAdmInvoiceManagementSearch(col.asc)}} className={col.ascActive}><i className="btn-asc-40 uil uil-angle-up font-size-18"></i></Link>
                                  <Link to='#' onClick={()=> {viewAdmInvoiceManagementSearch(col.desc)}} className={col.descActive}><i className="btn-desc-40 uil uil-angle-down font-size-18"></i></Link>
                                  </>
                                  :null}
                                </th>
                              )
                          })
                          :null
                        }
                        </tr>
                      </thead>
                      <tbody>
                        {
                          admInvoiceManagementSearchList.map((invoice, index) => {
                            return (
                                <tr key={index}>
                                  <td style={{verticalAlign: "middle"}}>
                                    <input type="checkbox" 
                                      checked={invoice.checked}
                                      onClick={(e)=>rowCheck(index, e.target.checked)}
                                      />
                                  </td>
                                  <td style={{textAlign: "center", verticalAlign: "middle"}}>{invoice.userName}</td>
                                  <td style={{textAlign: "center", verticalAlign: "middle"}}>{invoice.invoiceNo}</td>
                                  <td style={{textAlign: "center", verticalAlign: "middle"}}>{invoice.currency}</td>
                                  <td style={{textAlign: "center", verticalAlign: "middle"}}>{invoice.total}</td>
                                  <td style={{textAlign: "center", verticalAlign: "middle"}}>{invoice.amountPaid}</td>
                                  <td style={{textAlign: "center", verticalAlign: "middle"}}>{invoice.invoiceDate}</td>
                                  <td style={{textAlign: "center", verticalAlign: "middle"}}>{invoice.deadline}</td>
                                  <td style={{textAlign: "center", verticalAlign: "middle"}}>{invoice.dateOfPayment}</td>
                                  <td style={{textAlign: "center", verticalAlign: "middle"}}>
                                    <select 
                                      className="form-select" 
                                      name="perPage"
                                      onChange={e => {
                                        if (e.target.value === 'Paid') {
                                          setChangeInvoiceNo(invoice.invoiceNo);
                                          filterValidation.setFieldValue("amountPaid", '');
                                          filterValidation.setFieldValue("paymentDate", '');                                          
                                          filterValidation.setFieldValue("invoiceStatus", 'Paid');
                                          filterValidation.setFieldValue("paymentMethod", invoice.paymentMethod);
                                          filterValidation.setFieldValue("paymentType", invoice.paymentType);
                                          filterValidation.setFieldValue("paypalPlanID", invoice.paypalPlanID);
                                          filterValidation.setFieldValue("paypalSubscriptionID", invoice.paypalSubscriptionID);
                                          filterValidation.setFieldValue("paypalOnetimeLink", invoice.paypalOnetimeLink);
                                          setPaymentAlert(true);
                                        } else {
                                          setChangeInvoiceUserNo(invoice.userNo);
                                          setChangeInvoiceNo(invoice.invoiceNo);
                                          setChangeInvoiceStatus(e.target.value);
                                          setConfirmMsg("Are you sure you want to " + e.target.value + " this invoice?");
                                          setConfirmBtnText("Yes, proceed!");
                                          setCancelBtnText("Cancel");
                                          setConfirmAlert(true);
                                        }
                                      }}
                                      value={invoice.invoiceStatus}
                                    >
                                      <option value="Paid">Paid</option>
                                      <option value="PartiallyPaid">PartiallyPaid</option>
                                      <option value="Outstanding">Outstanding</option>
                                      <option value="Pending">Pending</option>
                                      <option value="Overdue">Overdue</option>
                                      <option value="NotYetIssued">NotYetIssued</option>
                                      <option value="Tentative">Tentative</option>
                                      <option value="Cancelled">Cancelled</option>
                                      <option value="Refunded">Refunded</option>
                                    </select>
                                    </td>
                                  <td style={{textAlign: "center", verticalAlign: "middle"}}>
                                    <Row>
                                      <div className="d-flex vertical-align-center horizon-align-center gap-2">
                                        <Link to="#"
                                          onClick={()=>{
                                            editInvoice(invoice.invoiceNo, invoice.userNo)
                                          }}>
                                          <i className="uil uil-file-edit-alt font-size-22" id={"editInvoice_"+index}></i>
                                          <UncontrolledTooltip placement="top" target={"editInvoice_"+index}>
                                            Edit
                                          </UncontrolledTooltip>
                                        </Link>
                                        <Link to="#"
                                          onClick={()=>{
                                            previewInvoice(invoice.invoiceNo)
                                          }}>
                                          <i className="uil uil-file-search-alt font-size-22" id={"previewInvoice_"+index}></i>
                                          <UncontrolledTooltip placement="top" target={"previewInvoice_"+index}>
                                            Preview
                                          </UncontrolledTooltip>
                                        </Link>
                                        <Link to="#" onClick={() => {
                                          setChangeInvoiceUserNo(invoice.userNo);
                                          setChangeInvoiceNo(invoice.invoiceNo);
                                          setChangeInvoiceStatus("Cancelled");
                                          setConfirmMsg("Are you sure you want to cancel this invoice?");
                                          setConfirmBtnText("Yes, proceed!");
                                          setCancelBtnText("Cancel");
                                          setConfirmAlert(true);
                                          }}
                                        >
                                          <i className="mdi mdi-file-cancel-outline font-size-22 text-danger" id={"cancelInvoice_"+index}></i>
                                          <UncontrolledTooltip placement="top" target={"cancelInvoice_"+index}>
                                          Cancel
                                          </UncontrolledTooltip>
                                        </Link>
                                      </div>
                                    </Row>
                                  </td>
                                </tr>
                            )})
                        }
                      </tbody>
                    </Table>
                  </div>
                  <div className="d-flex justify-content-between">
                    <span className="d-flex align-items-center gap-2">
                      <label
                        htmlFor="perPage"
                        >
                        Entries per page
                      </label>
                      <div style={{width: "100px"}}>
                        <select 
                          className="form-select" 
                          name="perPage"
                          onChange={e => {viewAdmInvoiceManagementSearch(null, 1, e.target.value);}}
                          value={filterValidation.values.perPage}
                        >
                          <option value="50">50</option>
                          <option value="100">100</option>
                          <option value="250">250</option>
                          <option value="500">500</option>
                          <option value="1000">1000</option>
                        </select>
                      </div>
                    </span>
                    <span className="d-flex align-items-center gap-2">
                      <div className="d-flex gap-1">
                        <Button
                          type="button"
                          color="primary"
                          onClick={()=>{viewAdmInvoiceManagementSearch(null, 1);}}
                          disabled={(filterValidation.values.curPage === 1)}
                        >
                          {"<<"}
                        </Button>
                        <Button
                          type="button"
                          color="primary"
                          onClick={()=>{viewAdmInvoiceManagementSearch(null, Number(filterValidation.values.curPage)-1);}}
                          disabled={(filterValidation.values.curPage === 1)}
                        >
                          {"<"}
                        </Button>
                      </div>
                      Page{" "}
                      <strong>
                        {filterValidation.values.curPage} of {totalPage}
                      </strong>
                      
                      <Input
                        type="number"
                        min={1}
                        style={{ width: 70 }}
                        max={totalPage}
                        name="curPage"
                        defaultValue={filterValidation.values.curPage}
                        value={curPageInput}
                        onChange={(e) => { setCurPageInput(e.target.value)}}
                        onKeyPress={(e) => {
                          if(e.key === 'Enter'){
                            viewAdmInvoiceManagementSearch(null, e.target.value);
                          }                      
                        }}
                      />
                      <div className="d-flex gap-1">
                        <Button 
                          type="button"
                          color="primary"
                          onClick={()=>{viewAdmInvoiceManagementSearch(null, Number(filterValidation.values.curPage)+1);}}
                          disabled={(filterValidation.values.curPage === totalPage)}
                        >
                          {">"}
                        </Button>
                        <Button
                          type="button"
                          color="primary"
                          onClick={()=>{viewAdmInvoiceManagementSearch(null, totalPage);}}
                          disabled={(filterValidation.values.curPage === totalPage)}
                        >
                          {">>"}
                        </Button>
                      </div>
                    </span>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        : null }
        </Col>

        <PreviewInvoice isOpenPreview={isOpenPreview} setIsOpenPreview={setIsOpenPreview} invoiceInfo={previewInvoiceItem} itemList={previewInvoiceItem && previewInvoiceItem.itemList ? previewInvoiceItem.itemList : []}/>
        
        <EditInvoice isOpenEdit={isOpenEdit} setIsOpenEdit={setIsOpenEdit} invoiceNo={editInvoiceNo} userNo={editUserNo}/>
      </Row>
    </React.Fragment>
  )
}

export default AdmInvoiceManagementSearch;