import React, {useState, useEffect, useRef, forwardRef, useImperativeHandle} from 'react';
import Dropzone from "react-dropzone"
import { useSelector, useDispatch } from "react-redux";
import { Editor } from '@tinymce/tinymce-react';
import LoadingModal from "../../components/Common/LoadingModal"
import SweetAlert from "react-bootstrap-sweetalert";
import {
    Card,
    CardBody,
    Media,
    Collapse,
    Table,
    Row,
    Col,
    Progress,
    Input,
    Button,
    UncontrolledTooltip,
} from "reactstrap"
  
import { Link } from "react-router-dom"
import {
    setCommunityLoadingEditor,
    createCommunity,
    modifyCommunity,
    setCommunityErrorMsg,
    setCommunityUpFiles,
    setCommunityUpdateFlag,
    changeCommunityDetail,
    getCommunityDetail,
    changeCommunityAttachFileList,
    getCommunityAttachFileList,
    addCommunityAttachFile,
    removeCommunityAttachFile,
    changeCommunityReplyList,
    getCommunityReplyList,
    createCommunityReply,
    modifyCommunityReply,
    removeCommunityReply,
} from "../../store/actions";

import '../../components/Common/EditorModal.css';

import { cloneDeep } from 'lodash';

const CommunityEditorModal = forwardRef((props, ref) => {
    const dispatch = useDispatch();

    const { errorMsg, communityDetail, communityAttachList, upFiles, totalPage, communityReplyList, loadingEditor, updateFlag } = useSelector(state => ({
        errorMsg: state.Community.errorMsg,
        communityDetail: state.Community.communityDetail,
        communityAttachList: state.Community.communityAttachList,
        upFiles: state.Community.upFiles,
        totalPage: state.Community.replyTotalPage,
        communityReplyList: state.Community.communityReplyList,
        loadingEditor: state.Community.loadingEditor,
        updateFlag: state.Community.updateFlag,
    }));
    
    const [commentContent, setCommentContent] = useState("");
    const [curPage, setCurPage] = useState(1);
    const [pageSize, setPageSize] = useState(5);
    const [isOpenComment, setIsOpenComment] = useState(false);
    const [unsupportedFiles, setUnsupportedFiles] = useState([]);
    const [alertMessage, setAlertMessage] = useState('');
    const [confirmType, setConfirmType] = useState("");
    const [confirmMsg, setConfirmMsg] = useState("");
    const [confirmAlert, setConfirmAlert] = useState(false);
    const [confirmBtnText, setConfirmBtnText] = useState("");
    const [cancelBtnText, setCancelBtnText] = useState("");
    const [removeAttachFileIdx, setRemoveAttachFileIdx] = useState(-1);

    const toggleComment = () => {
        setIsOpenComment(!isOpenComment);
    }
    
    const changeCommunityReplyContent = (index, content) => {
        let _communityReplyList = cloneDeep(communityReplyList);
        _communityReplyList[index].content = content
        dispatch(changeCommunityReplyList(_communityReplyList));
    }

    // [S] attach File
    const [totalAttachFileSize, setTotalAttachFileSize] = useState("0");
    //등록가능한 총 파일 사이즈 MB    
    var maxUploadSize = 5;

    const getAttachFileSize = () => {
        let attachFileSize = 0;
        if (communityAttachList&&communityAttachList.length>0) {
            communityAttachList.map((f, i) => {
                attachFileSize += f.fileSize
            })
        }

        if (upFiles&&upFiles.length>0) {
            upFiles.map((f, i) => {
                attachFileSize += f.size
            })
        }

        return attachFileSize;
    }
	
    const handleAcceptedFiles = (files) => {
        let attachFileSize = getAttachFileSize();

        if (attachFileSize/1024/1024 > maxUploadSize) {
            setAlertMessage("The attachment exceeds the maximum attachment size of " + maxUploadSize + "MB.");
            return false;
        } 

        files.map(file => {
            attachFileSize += file.size;
        })

        // console.log('handleAcceptedFiles attachFileSize : '  + attachFileSize); 

        if (attachFileSize/1024/1024 > maxUploadSize) {
            setAlertMessage("Maximum total attachment size is " + maxUploadSize + "MB.");
            return false;
        } 

        setTotalAttachFileSize(formatBytes(attachFileSize));

        let _upFiles = cloneDeep(upFiles);

        files.map(file => {
            let _file = Object.assign(file, {
                preview: URL.createObjectURL(file),
                formattedSize: formatBytes(file.size),
                isUpload: false,
                uploadRate: 0,
                isImage: isImageType(file),
                isValid: validateFile(file),
                progressColor : "primary",
                errorMessage : "File type not permitted",
            })

            _upFiles.push(_file);
        })

        dispatch(setCommunityUpFiles(_upFiles))

        for(let i = 0; i < files.length; i++) {
            if (!validateFile(files[i])) {
                setUnsupportedFiles(prevArray => [...prevArray, files[i]]);
            }
        }
    }

    const validateFile = (file) => {
        const validTypes = ['text/html', 'application/zip', 'text/plain', 'application/rtf', 'application/pdf', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'application/vnd.ms-excel', 'text/csv'
                        , 'image/jpeg', 'image/jpg', 'image/png', 'image/gif', 'image/svg+xml', 'image/webp', 'application/vnd.ms-powerpoint', 'application/vnd.openxmlformats-officedocument.presentationml.presentation'];

        // console.log(file.name + ' : ' + file.type + ' >> ' + validTypes.indexOf(file.type));

        if (validTypes.indexOf(file.type) === -1) {
            return false;
        }

        return true;
    }

    const isImageType = (file) => {
        const imageTypes = ['image/jpeg', 'image/jpg', 'image/png', 'image/gif', 'image/svg+xml', 'image/webp'];
        if (imageTypes.indexOf(file.type) === -1) {
            return false;
        }

        return true;
    }

    const removeAttachFile = (idx) => {
        setConfirmType('removeAttachFile');
        setRemoveAttachFileIdx(idx);
        setConfirmMsg("Are you sure you want to delete this file?");
        setConfirmBtnText("Confirm");
        setCancelBtnText("Cancel");
        setConfirmAlert(true);
    }

    const removeCommunity = () => {
        setConfirmType('removeCommunity');
        setConfirmMsg("Are you sure you want to delete this content?");
        setConfirmBtnText("Confirm");
        setCancelBtnText("Cancel");
        setConfirmAlert(true);
    }

    const removeUpFile = (name) => {
        const index1 = upFiles.findIndex(e => e.name === name);
        const index2 = unsupportedFiles.findIndex(e => e.name === name);
        upFiles.splice(index1, 1);

        let attachFileSize = getAttachFileSize();
        
        // console.log('removeUpFile attachFileSize : '  + attachFileSize); 

        setTotalAttachFileSize(formatBytes(attachFileSize));

        dispatch(setCommunityUpFiles([...upFiles]));
        if (index2 !== -1) {
            unsupportedFiles.splice(index2, 1);
            setUnsupportedFiles([...unsupportedFiles]);
        }
    }

    /**
     * Formats the size
     */
    function formatBytes(bytes, decimals = 2) {
        if (bytes === 0) return "0 Bytes"
        const k = 1024
        const dm = decimals < 0 ? 0 : decimals
        const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]

        const i = Math.floor(Math.log(bytes) / Math.log(k))
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
    }
    // [E] attach File

    const editorRef = useRef(null);

    const saveCommunity = () => {
        if (communityDetail.title == null || communityDetail.title === '') {
            setAlertMessage('Please enter a title.');
            return;
        }
       
        if (editorRef.current.getContent() == null || editorRef.current.getContent() === '') {
            setAlertMessage('Please enter a content.');
            return;
        }


        const formData = new FormData();
        formData.append('idx', props.communityIdx);
        formData.append('communityCtg', communityDetail.communityCtg);
        formData.append('title', communityDetail.title);
        formData.append('content', editorRef.current.getContent());
        
        upFiles.forEach((element, index) => {
            formData.append('upFiles['+index+']', element);
        });
        
        if (props.communityIdx > 0) {
            dispatch(modifyCommunity(formData));
        } else {
            dispatch(createCommunity(formData));
        }
    }

    const clearEditorContent = () => {
        props.loadedEditor && editorRef.current.setContent('', {format: 'html'})
    } 

    useImperativeHandle(ref, () => ({
        clearEditorContent,
    }))

    useEffect(() => {
        // setTotalAttachFileSize(formatBytes(0));

        if (props.communityIdx > 0) {
            dispatch(getCommunityDetail({idx:props.communityIdx}));
            dispatch(getCommunityAttachFileList({idx:props.communityIdx}));
            dispatch(getCommunityReplyList({idx:props.communityIdx, curPage:1, pageSize:10}));
        } else if (props.communityIdx === -1) {
            clearEditorContent();
            let _communityDetail = {
                idx:-1,
                content:"",
            };
            dispatch(changeCommunityDetail(_communityDetail));
            dispatch(changeCommunityAttachFileList([]));
            dispatch(setCommunityUpFiles([]));
        }
    }, [props.communityIdx]);

    useEffect(() => {
        if (props.communityIdx === -1 || communityDetail.writerYn === 'Y') {
            props.loadedEditor && editorRef.current.setContent(communityDetail.content, {format: 'html'});
        }

        setCommunityLoadingEditor(false);
    }, [props.loadedEditor, communityDetail]);

    useEffect(() => {
        if (updateFlag) {
            // if (props.setIsInitEditor) {
            //     props.setIsInitEditor(false);
            // }
            
            dispatch(setCommunityUpdateFlag(false));
            props.closeEditor();
            setTotalAttachFileSize(formatBytes(0));
        }
    }, [updateFlag]);

    useEffect(() => {
        let attachFileSize = getAttachFileSize();
        
        // console.log('change communityAttachList attachFileSize : '  + attachFileSize); 

        setTotalAttachFileSize(formatBytes(attachFileSize));
    }, [communityAttachList]);

    return (
        <>
        <div tabindex="-1" style={props.isOpenEditor && props.isInitEditor && !loadingEditor ? {position: "relative", zIndex: "1050", display: "block"} : {position: "relative", zIndex: "1050", display: "none"}}>
            <div className="">
                <div className="modal fade show" role="dialog" tabindex="-1" style={props.isOpenEditor && props.isInitEditor && !loadingEditor ? {display: "block"} : {display: "none"}} id="CommunityEditorModal">
                    <div className="modal-dialog modal-xl" role="document" ref={props.CommunityEditorModal} openStatus={props.isOpenEditor ? "Y" : "N"}>
                        <div className="modal-content">
                            <div className="modal-header">
                                <Row style={{width: "100%", marginLeft: "0px"}}>
                                    {
                                    props.communityIdx === -1 || communityDetail.writerYn === 'Y' ? 
                                    <Row style={{marginLeft: "0px", paddingLeft: "0px", paddingRight: "0px"}}>
                                        <div className="col-md-2">
                                            <select 
                                            className="form-select" 
                                            name="communityCtg"
                                            onChange={e => {
                                                let _communityDetail = cloneDeep(communityDetail);
                                                _communityDetail.communityCtg = e.target.value;
                                                _communityDetail.content = editorRef.current.getContent();
                                                dispatch(changeCommunityDetail(_communityDetail))
                                            }}
                                            value={communityDetail.communityCtg}
                                            >
                                            <option value="Chitchat">Chitchat</option>
                                            <option value="SelfPromotion">Self promotion</option>
                                            <option value="CaseRequest">Case request</option>
                                            </select>
                                        </div>
                                        <div className="col-md-10" style={{paddingRight: "0px"}}>
                                        <input
                                            className="form-control"
                                            type="text"
                                            name="title"
                                            onChange={(e) => {
                                                let _communityDetail = cloneDeep(communityDetail);
                                                _communityDetail.title = e.target.value;
                                                _communityDetail.content = editorRef.current.getContent();
                                                dispatch(changeCommunityDetail(_communityDetail))
                                            }}
                                            value={communityDetail.title}
                                            maxLength={100}
                                        />
                                        </div>
                                    </Row>
                                    :
                                    <Row style={{marginLeft: "0px", paddingLeft: "0px", paddingRight: "0px"}}>
                                        <div className="col-md-2">
                                            <select 
                                            className="form-select" 
                                            name="communityCtg"
                                            value={communityDetail.communityCtg}
                                            disabled
                                            >
                                            <option value="Chitchat">Chitchat</option>
                                            <option value="SelfPromotion">Self promotion</option>
                                            <option value="CaseRequest">Case request</option>
                                            </select>
                                        </div>
                                        <div className="col-md-10 display-flex vertical-align-center" style={{paddingRight: "0px"}}>
                                        {communityDetail.title}
                                        </div>
                                    </Row>
                                    }
                                </Row>
                            </div>
                            <div className="modal-body">
                                {
                                (props.communityIdx === -1 || communityDetail.writerYn === 'Y') && props.isInitEditor ?
                                    <Editor
                                        apiKey='vp9wl3j35wpx6ktafg19n0tcbtatm5auwzy4s4r4m10t2c3w'
                                        onInit={(evt, editor) => {
                                            // console.log('Editor initialized')
                                            props.setLoadedEditor(true)
                                            editor.setContent(communityDetail.content, {format: 'html'});
                                            editorRef.current = editor
                                        }}
                                        initialValue={communityDetail.content}
                                        init={{
                                            branding: false,
                                            height: 500,
                                            plugins: 'a11ychecker advcode casechange formatpainter linkchecker autolink lists checklist image link mediaembed pageembed permanentpen powerpaste table advtable tinycomments tinymcespellchecker',
                                            toolbar: 'undo redo styleselect bold italic alignleft aligncenter alignright alignjustify | bullist numlist outdent indent table',
                                            toolbar_mode: 'floating',
                                            tinycomments_mode: 'embedded',
                                            tinycomments_author: 'WeCrest',
                                            content_css: [
                                                '//fonts.googleapis.com/css?family=Lato:300,300i,400,400i',
                                                '//www.tiny.cloud/css/codepen.min.css'
                                            ],
                                            images_upload_url: '/uploadCommunityImageFile.json',
                                            images_upload_credentials: true,
                                            relative_urls : false,
                                            remove_script_host : false,
                                            paste_auto_cleanup_on_paste : false,
                                            menubar: 'file edit view insert format tools table custom',
                                        }}
                                    />
                                :
                                <CardBody style={{overflow:"scroll", height:"500px"}} dangerouslySetInnerHTML={{__html:communityDetail.content? communityDetail.content : null}}></CardBody>
                                }
                              
                                {props.communityIdx === -1 || communityDetail.writerYn === 'Y' ?
                                <>
                                <div style={{marginTop: "10px"}}></div>

                                <Dropzone
                                    onDrop={acceptedFiles => {
                                    handleAcceptedFiles(acceptedFiles)
                                    }}
                                >
                                    {({ getRootProps, getInputProps }) => (
                                    <div className="dropzone" style={{minHeight: "130px"}}>
                                        <div
                                        className="dz-message needsclick" style={{fontSize: "0px"}}
                                        {...getRootProps()}
                                        >
                                        <input {...getInputProps()} />
                                        <div className="mb-1">
                                            <i className="display-4 text-muted uil uil-cloud-upload"/>
                                        </div>
                                        <h4>Drag & Drop Files Here or Browse Files ({totalAttachFileSize}/5MB)</h4>
                                        <h6 class="text-danger">Allowed file type: HTM, HTML, ZIP, TXT, RTF, PDF, DOC, DOCX, XLS, XLSX, CSV, PPT, PPTX, JPG, JPEG, PNG, GIF, SVG, WEBP</h6>
                                        </div>
                                    </div>
                                    )}
                                </Dropzone>
                                </>
                                : null}

                                <div className="dropzone-previews mt-3" id="file-previews" style={{marginBottom: "1rem"}}>
                                    {communityAttachList&&communityAttachList.length>0?
                                    communityAttachList.map((f, i) => {
                                        return (
                                            <Card
                                            className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                            key={i + "-file"}
                                            >
                                                <CardBody>
                                                <Row className="align-items-center">
                                                {
                                                    f.image ?
                                                    <Col className="col-auto">
                                                        <img
                                                        data-dz-thumbnail=""
                                                        height="80"
                                                        className="avatar-sm rounded bg-light"
                                                        alt={f.fileName}
                                                        src={f.imgStorageSrc}
                                                        referrerpolicy="no-referrer"
                                                        />
                                                    </Col>
                                                    : null
                                                }
                                                
                                                <Col>
                                                    <a href={f.attachStorageUrl} className="text-muted font-weight-bold" target="_blank" rel="noreferrer">
                                                    {f.fileName}
                                                    <span className="file-success-message"> (File Uploaded)</span>
                                                    </a>&nbsp;
                                                    <p className="mb-0">
                                                    <strong>{formatBytes(f.fileSize)}</strong>
                                                    </p>
                                                </Col>
                                                <Col md={1} className="file-remove display-flex horizon-align-center"  onClick={() => removeAttachFile(f.idx)}><Link to="#" style={{color: "black"}}>X</Link></Col>
                                                </Row>
                                                {
                                                f.isUpload ?
                                                    <div className="">
                                                    <div>
                                                        <Progress color={f.progressColor} value={f.uploadRate}>
                                                        {f.uploadRate}%
                                                        </Progress>
                                                    </div>
                                                    </div>
                                                : null
                                                }
                                                </CardBody>
                                            </Card>
                                        )
                                        })
                                    : null
                                    }
                                    
                                    {upFiles && upFiles.length > 0 ?
                                    upFiles.map((f, i) => {
                                        return (
                                            <Card
                                            className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                            key={i + "-upfile"}
                                            >
                                            <CardBody>
                                                <Row className="align-items-center">
                                                {
                                                    f.isImage ?
                                                    <Col className="col-auto">
                                                        <img
                                                        data-dz-thumbnail=""
                                                        height="80"
                                                        className="avatar-sm rounded bg-light"
                                                        alt={f.name}
                                                        src={f.preview}
                                                        />
                                                    </Col>
                                                    : null
                                                }
                                                
                                                <Col>
                                                    <Link
                                                    to="#"
                                                    className="text-muted font-weight-bold"
                                                    >
                                                    {f.name}
                                                    {!f.isValid && <span className="file-error-message">({f.errorMessage})</span>}
                                                    {f.isValid && f.uploadRate === 100 && <span className="file-success-message">(File Uploaded)</span>}
                                                    </Link>
                                                    <p className="mb-0">
                                                    <strong>{f.formattedSize}</strong>
                                                    </p>
                                                </Col>
                                                {!f.isValid || f.uploadRate !== 100 ?
                                                <Col md={1} className="file-remove display-flex horizon-align-center"  onClick={() => removeUpFile(f.name)}><Link to="#" style={{color: "black"}}>X</Link></Col>
                                                : null
                                                }
                                                </Row>
                                            </CardBody>
                                            </Card>
                                        )
                                        })
                                    : null
                                    }
                                </div>

                                <Card>
                                    <CardBody>
                                        <Row>
                                            <div className="col-md-6">
                                                {
                                                props.communityIdx === -1 || communityDetail.writerYn === 'Y' ?
                                                <button type="button" className="btn btn-primary" onClick={saveCommunity}>
                                                    Save changes
                                                </button>
                                                : null
                                                }
                                                {
                                                props.communityIdx > 0 && communityDetail.writerYn === 'Y' ? 
                                                <>
                                                &nbsp;&nbsp;&nbsp;&nbsp;
                                                <button type="button" className="btn btn-danger" onClick={removeCommunity}>
                                                    Delete
                                                </button>
                                                </>
                                                : null
                                                }
                                            </div>
                                            <div className="col-md-6 display-flex horizon-align-right">
                                                <button
                                                    type="button"
                                                    className="btn btn-light"
                                                    data-dismiss="modal"
                                                    onClick={props.closeEditor}
                                                >
                                                Close
                                                </button>
                                            </div>
                                        </Row>
                                    </CardBody>
                                </Card>

                                {props.communityIdx > 0 ?
                                <Card>
                                    <CardBody>
                                        <Row>   
                                            <div className="table-responsive">
                                                <Table className="table mb-0">
                                                   <tbody>
                                                    <tr style={{borderColor: "transparent"}}>
                                                        <td>
                                                            <Input
                                                                className="form-control"
                                                                type="text"
                                                                placeholder="Enter comment"
                                                                id="content"
                                                                name="content"
                                                                onChange={(e)=>{setCommentContent(e.target.value)}}
                                                                value={commentContent}
                                                            />
                                                        </td>
                                                        <td style={{textAlign: "right", verticalAlign: "middle", width: "20%"}}>
                                                            <Row>  
                                                                <div className="col-md-10 display-flex horizon-align-center">
                                                                    <Button
                                                                        type="button"
                                                                        color="primary"
                                                                        outline
                                                                        className="waves-effect waves-light col-md-10"
                                                                        onClick={()=>{
                                                                            if (!commentContent || commentContent === "") {
                                                                                setAlertMessage('Please enter a comment.');
                                                                            } else {
                                                                                // console.log('commentContent : ' + commentContent);
                                                                                dispatch(createCommunityReply({communityIdx:props.communityIdx, content:commentContent}, {idx:props.communityIdx, curPage:1, pageSize:pageSize}))
                                                                            }
                                                                        }}
                                                                        >
                                                                        Add
                                                                    </Button>
                                                                </div>
                                                                <div className="col-md-2 display-flex horizon-align-right">
                                                                <i onClick={toggleComment} className={isOpenComment ? "mdi mdi-chevron-up accor-down-icon font-size-24" : "mdi mdi-chevron-down accor-down-icon font-size-24"} style={{ cursor: "pointer"}}></i>
                                                                </div>
                                                            </Row>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                                </Table>
                                            </div>
                                        </Row>
                                        <Collapse isOpen={isOpenComment}>
                                            {communityReplyList && communityReplyList.length ?
                                            <Row>
                                               
                                                    <div className="table-responsive">
                                                        <Table className="table mb-3">
                                                        <tbody>
                                                            {
                                                            communityReplyList.map((communityReply, index) => {
                                                                if (communityReply.writerYn === 'Y') {
                                                                    return (
                                                                        <tr key={index}>
                                                                        <td style={{textAlign: "center", verticalAlign: "middle"}} colSpan={2}>
                                                                            <Input
                                                                                className="form-control"
                                                                                type="text"
                                                                                placeholder="Enter comment"
                                                                                id="content"
                                                                                name="content"
                                                                                onChange={(e)=>{changeCommunityReplyContent(index, e.target.value)}}
                                                                                value={communityReply.content}
                                                                            />
                                                                        </td>
                                                                        <td style={{textAlign: "center", verticalAlign: "middle", width: "20%"}}>
                                                                            <div className="col-form-label d-flex gap-3 horizon-align-center">
                                                                            <Link to="#" onClick={(e) => {dispatch(modifyCommunityReply({idx:communityReply.idx, content:communityReply.content}, {idx:props.communityIdx, curPage:curPage, pageSize:pageSize}))}}>
                                                                                <i className="bx bx-save font-size-22" style={{marginTop: "3px"}} id={"saveComment_"+index}></i>
                                                                                <UncontrolledTooltip placement="top" target={"saveComment_"+index}>
                                                                                Save comment
                                                                                </UncontrolledTooltip>
                                                                            </Link>
                                                                            <Link to="#" 
                                                                                onClick={() => {dispatch(removeCommunityReply({idx:communityReply.idx}, {idx:props.communityIdx, curPage:curPage, pageSize:pageSize}))}}>
                                                                                <i className="uil-trash-alt" id={"deleteComment_"+index} style={{color:"red", fontSize:"18px"}}></i>
                                                                                <UncontrolledTooltip placement="top" target={"deleteComment_"+index}>
                                                                                Delete comment
                                                                                </UncontrolledTooltip>
                                                                            </Link>
                                                                            </div>
                                                                        </td>
                                                                        </tr>
                                                                    )
                                                                } else {
                                                                    return (
                                                                        <tr key={index}>
                                                                        <td style={{verticalAlign: "middle", width: "20%"}}>{communityReply.userFullName}</td>
                                                                        <td style={{verticalAlign: "middle"}}>{communityReply.content}</td>
                                                                        <td style={{textAlign: "center", verticalAlign: "middle", width: "20%"}}>{communityReply.regDate}</td>
                                                                        </tr>
                                                                    )
                                                                }
                                                            })}
                                                        </tbody>
                                                        </Table>
                                                    </div>
                                                    <div className="d-flex justify-content-between">
                                                        <span className="d-flex align-items-center gap-2">
                                                        <label htmlFor="pageSize">
                                                            Entries per page
                                                        </label>
                                                        <div style={{width: "100px"}}>
                                                            <select 
                                                            className="form-select" 
                                                            name="pageSize"
                                                            onChange={e => {
                                                                setCurPage(1);
                                                                setPageSize(e.target.value);
                                                                dispatch(getCommunityReplyList({curPage:1, pageSize:e.target.value}))
                                                            }}
                                                            value={pageSize}
                                                            >
                                                            <option value="5">5</option>
                                                            <option value="10">10</option>
                                                            </select>
                                                        </div>
                                                        </span>
                                                        <span className="d-flex align-items-center gap-2">
                                                        <div className="d-flex gap-1">
                                                            <Button
                                                            type="button"
                                                            color="primary"
                                                            onClick={()=>{
                                                                setCurPage(1);
                                                                dispatch(getCommunityReplyList({curPage:1, pageSize:pageSize}))
                                                            }}
                                                            disabled={(curPage === 1)}
                                                            >
                                                            {"<<"}
                                                            </Button>
                                                            <Button
                                                            type="button"
                                                            color="primary"
                                                            onClick={()=>{
                                                                let _curPage = Number(curPage)-1;
                                                                setCurPage(_curPage);
                                                                dispatch(getCommunityReplyList({curPage:_curPage, pageSize:pageSize}))
                                                            }}
                                                            disabled={(curPage === 1)}
                                                            >
                                                            {"<"}
                                                            </Button>
                                                        </div>
                                                        Page{" "}
                                                        <strong>
                                                            {curPage} of {totalPage}
                                                        </strong>
                                                        
                                                        <Input
                                                            type="number"
                                                            min={1}
                                                            style={{ width: 70 }}
                                                            max={totalPage}
                                                            name="curPage"
                                                            defaultValue={curPage}
                                                            value={curPage}
                                                            onChange={(e) => { setCurPage(e.target.value)}}
                                                            onKeyPress={(e) => {
                                                            if(e.key === 'Enter'){
                                                                setCurPage(curPage);
                                                                dispatch(getCommunityReplyList({curPage:curPage, pageSize:pageSize}))
                                                            }                      
                                                            }}
                                                        />
                                                        <div className="d-flex gap-1">
                                                            <Button 
                                                            type="button"
                                                            color="primary"
                                                            onClick={()=>{
                                                                let _curPage = Number(curPage)+1;
                                                                setCurPage(_curPage);
                                                                dispatch(getCommunityReplyList({curPage:_curPage, pageSize:pageSize}))
                                                            }}
                                                            disabled={(curPage === totalPage)}
                                                            >
                                                            {">"}
                                                            </Button>
                                                            <Button
                                                            type="button"
                                                            color="primary"
                                                            onClick={()=>{
                                                                setCurPage(totalPage);
                                                                dispatch(getCommunityReplyList({curPage:totalPage, pageSize:pageSize}))
                                                            }}
                                                            disabled={(curPage === totalPage)}
                                                            >
                                                            {">>"}
                                                            </Button>
                                                        </div>
                                                        </span>
                                                    </div>
                                                    
                                            </Row>
                                            : null }
                                        </Collapse>
                                    </CardBody>
                                </Card>
                                : null }
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-backdrop fade show"></div>
            </div>
        </div>
        {
        props.isOpenEditor && loadingEditor?
            <LoadingModal loading={loadingEditor}></LoadingModal>
        :
            null
        }
        {alertMessage !== "" ? (
        <SweetAlert
            onConfirm={() => {
                setAlertMessage("");
            }}
            onCancel={() => setAlertMessage("")}
        >{alertMessage}
        </SweetAlert>
        ) : null}

        {errorMsg !== '' ? (
        <SweetAlert
            onConfirm={() => {
            dispatch(setCommunityErrorMsg(""))
            }}
            onCancel={() => {
            dispatch(setCommunityErrorMsg(""))
            }}
        >{errorMsg}
        </SweetAlert>
        ) 
        : null}

        {confirmAlert ? (
          <SweetAlert
            title={confirmMsg}
            showCancel
            confirmBtnText={confirmBtnText}
            cancelBtnText={cancelBtnText}
            confirmBtnBsStyle="success"
            cancelBtnBsStyle="danger"
            onConfirm={() => {
                if (confirmType === 'removeAttachFile') {
                    dispatch(removeCommunityAttachFile({idx:removeAttachFileIdx}, {idx:props.communityIdx}));
                    setRemoveAttachFileIdx(-1);
                } else {
                    props.removeCommunity(props.communityIdx);
                }
                
                setConfirmAlert(false);
                setConfirmType("")
            }}
            onCancel={() => {
                if (confirmType === 'removeAttachFile') {
                    setRemoveAttachFileIdx(-1);
                }
                setConfirmAlert(false)
            }}
          >
          </SweetAlert>
        ) : null}
        </>
    );
});

export default CommunityEditorModal;