import { call, put, takeEvery, takeLatest } from "redux-saga/effects"

// Login Redux States
import { 
  GET_CONTACTS_LIST,
  UPLOAD_CONTACTS,
  MULTIPLE_STAR_CONTACTS,
  MULTIPLE_UNSTAR_CONTACTS,
  MULTIPLE_HIDE_CONTACTS,
  MULTIPLE_UNHIDE_CONTACTS,
  MULTIPLE_UNSUBSCRIBE_CONTACTS,
  CHECK_EMAIL_UNSUBSCRIBE,
  SAVE_CONTACTS,
  SAVE_CONTACTS_SIMPLE,
  DELETE_CONTACTS,
  GET_IMPORTED_CONTACTS,
  GET_SUGGESTED_CONTACTS,
  GET_CONTACTS_DETAILS,
  GET_CONTACTS_DETAIL_LIST,
  GET_CONTACTS_MEMO,
  SAVE_MEMO,
  SAVE_CONTACTS_SETTINGS,
  GET_CONTACTS_SETTINGS,
  DOWN_CONTACTS_EXCEL,

  GET_UPLOADED_CONTACTS_LIST,
  SAVE_UPLOADED_CONTACTS,
  DELETE_UPLOADED_CONTACTS,
  DOWN_UPLOADED_CONTACTS_EXCEL,

  GET_DOMAIN_CORRECT_YN_COUNT,
  SAVE_DOMAIN_CORRECT_YN,
  GET_CONTACT_SEARCH_LIST,
  DOWN_SEARCH_ENTITY_EXCEL,
} from "./actionTypes"
import { 
  getContactsListSuccess,
  uploadContactsSuccess,
  getContactsList as getContactsListAction,
  getImportedContactsSuccess,
  getSuggestedContactsSuccess,
  getContactsDetailsSuccess,
  getContactsMemoSuccess,
  getContactsDetailList as getContactsDetailListAction,
  getContactsDetailListSuccess,
  getContactsSettingsSuccess,
  downContactsExcelSuccess,

  getUploadedContactsListSuccess,
  downUploadedContactsExcelSuccess,

  getDomainCorrectYnCountSuccess,

  getUploadedContactsList as getUploadedContactsListAction,

  saveContactsSimpleSuccess,
  checkEmailUnsubscribeSuccess,
  saveMemoSuccess,

  getContactSearchList as getContactSearchListAction,
  getContactSearchListSuccess,
  downSearchEntityExcelSuccess,
} from "./actions"

import { 
  setResult,
  setSuccess,
} from "../filerRankings/actions"

import {
  getContactsList,
  multipleStarContacts,
  multipleUnstarContacts,
  multipleHideContacts,
  multipleUnhideContacts,
  multipleUnsubscribeContacts,
  checkEmailUnsubscribe,
  saveContacts,
  saveContactsSimple,
  deleteContacts,
  getSuggestedContacts,
  getContactsDetails,
  getContactsDetailList,
  getContactsMemo,
  saveMemo,
  saveContactsSettings,
  getContactsSettings,
  downContactsExcel,

  getUploadedContactsList,
  uploadContacts,
  saveUploadedContacts,
  deleteUploadedContacts,
  downUploadedContactsExcel,

  getDomainCorrectYnCount,
  saveDomainCorrectYn,

  getContactSearchList,
  downSearchEntityExcel,
} from "../../helpers/backend_helper"

function* onGetContactsList({ payload: data }) {
  try {
    const response = yield call(getContactsList, data);
    yield put(getContactsListSuccess(response));
    
  } catch (error) {
    console.log('onGetContactsList error >> ' + error);
    //yield put(apiError(error))
  }
}

function* onUploadContacts({ payload: formData }) {
  try {
    const response = yield call(uploadContacts, formData);
    if(response.error != undefined && response.error >-1){
      yield put(setResult(response.error));
    }else{
      yield put(uploadContactsSuccess(response));
    }
    
  } catch (error) {
    console.log('onUploadContacts error >> ' + error);
    //yield put(apiError(error))
  }
}

function* onMultipleStarContacts({ payload: data }) {
  try {
    const response = yield call(multipleStarContacts, data.chkList);
    //새로고침
    if(data.filter != null) {
      if (data.type === 'Manage') {
        yield put(getContactsListAction(data.filter));
      } else {
        yield put(getContactSearchListAction(data.filter));
      }
    } else {
      yield put(getContactsDetailListAction({targetGroupNo : data.chkList[0].targetGroupNo}));
    }
    
  } catch (error) {
    console.log('onMultipleStarContacts error >> ' + error);
    //yield put(apiError(error))
  }
}

function* onMultipleUnstarContacts({ payload: data }) {
  try {
    const response = yield call(multipleUnstarContacts, data.chkList);
    //새로고침
    if(data.filter != null) {
      if (data.type === 'Manage') {
        yield put(getContactsListAction(data.filter));
      } else {
        yield put(getContactSearchListAction(data.filter));
      }
    } else {
      yield put(getContactsDetailListAction({targetGroupNo : data.chkList[0].targetGroupNo}));
    }
   
  } catch (error) {
    console.log('onMultipleUnstarContacts error >> ' + error);
    //yield put(apiError(error))
  }
}

function* onMultipleHideContacts({ payload: data }) {
  try {
    const response = yield call(multipleHideContacts, data.chkList);
    //새로고침
    if(data.filter != null) {
      if (data.type === 'Manage') {
        yield put(getContactsListAction(data.filter));
      } else {
        yield put(getContactSearchListAction(data.filter));
      }
    } else {
      yield put(getContactsDetailListAction({targetGroupNo : data.chkList[0].targetGroupNo}));
    }
    
  } catch (error) {
    console.log('onMultipleHideContacts error >> ' + error);
    //yield put(apiError(error))
  }
}

function* onMultipleUnhideContacts({ payload: data }) {
  try {
    const response = yield call(multipleUnhideContacts, data.chkList);
    //새로고침
    if(data.filter != null) {
      if (data.type === 'Manage') {
        yield put(getContactsListAction(data.filter));
      } else {
        yield put(getContactSearchListAction(data.filter));
      }
    } else {
      yield put(getContactsDetailListAction({targetGroupNo : data.chkList[0].targetGroupNo}));
    }
  } catch (error) {
    console.log('onMultipleUnhideContacts error >> ' + error);
    //yield put(apiError(error))
  }
}

function* onMultipleUnsubscribeContacts({ payload: data }) {
  try {
    const response = yield call(multipleUnsubscribeContacts, data.chkList);
    //새로고침
    if(data.filter != null) {
      yield put(getContactsListAction(data.filter));
    } else {
      yield put(getContactsDetailListAction({targetGroupNo : data.chkList[0].targetGroupNo}));
    }
  } catch (error) {
    console.log('onMultipleUnsubscribeContacts error >> ' + error);
    //yield put(apiError(error))
  }
}

function* onCheckEmailUnsubscribe({ payload: data }) {
  try {
    const response = yield call(checkEmailUnsubscribe, data.chkList);
    yield put(checkEmailUnsubscribeSuccess(response, data.screenName));
  } catch (error) {
    console.log('onCheckEmailUnsubscribe error >> ' + error);
    //yield put(apiError(error))
  }
}

function* onSaveContacts({ payload: data }) {
  try {
    const response = yield call(saveContacts, data.chkList);
    //새로고침
    if(data.filter != null) yield put(getContactsListAction(data.filter));
    if(data.filter == null) yield put(getContactsDetailListAction({targetGroupNo : data.chkList[0].targetGroupNo}));
    
  } catch (error) {
    console.log('onSaveContacts error >> ' + error);
    //yield put(apiError(error))
  }
}

function* onSaveContactsSimple({ payload: data }) {
  try {
    const response = yield call(saveContactsSimple, data);
    yield put(saveContactsSimpleSuccess(response));
    
  } catch (error) {
    console.log('onSaveContactsSimple error >> ' + error);
    //yield put(apiError(error))
  }
}

function* onDeleteContacts({ payload: data }) {
  try {
    const response = yield call(deleteContacts, data.chkList);
    //새로고침
    if(data.filter != null) yield put(getContactsListAction(data.filter));
    if(data.filter == null) yield put(getContactsDetailListAction({targetGroupNo : data.chkList[0].targetGroupNo}));
    
  } catch (error) {
    console.log('onDeleteContacts error >> ' + error);
    //yield put(apiError(error))
  }
}

function* onGetImportedContacts({payload: data}) {
  try {
    console.log('onGetImportedContacts data : ' + JSON.stringify(data));
    // const response = yield call(getImportedContacts);
    const response = yield call(getUploadedContactsList, data);
    yield put(getImportedContactsSuccess(response));
    
  } catch (error) {
    console.log('onGetImportedContacts error >> ' + error);
    //yield put(apiError(error))
  }
}

function* onGetSuggestedContacts({ payload: data }) {
  try {
    const response = yield call(getSuggestedContacts, data);
    yield put(getSuggestedContactsSuccess(response));
    
  } catch (error) {
    console.log('onGetSuggestedContacts error >> ' + error);
    //yield put(apiError(error))
  }
}

function* onGetContactsDetails({ payload: data }) {
  try {
    const response = yield call(getContactsDetails, data);
    yield put(getContactsDetailsSuccess(response));
    
  } catch (error) {
    console.log('onGetContactsDetails error >> ' + error);
    //yield put(apiError(error))
  }
}

function* onGetContactsDetailList({ payload: data }) {
  try {
    const response = yield call(getContactsDetailList, data);
    yield put(getContactsDetailListSuccess(response));
    
  } catch (error) {
    console.log('onGetContactsDetailList error >> ' + error);
    //yield put(apiError(error))
  }
}

function* onGetContactsMemo({ payload: data }) {
  try {
    const response = yield call(getContactsMemo, data);
    yield put(getContactsMemoSuccess(response));
    
  } catch (error) {
    console.log('onGetContactsMemo error >> ' + error);
    //yield put(apiError(error))
  }
}

function* onSaveMemo({ payload: data }) {
  try {
    const response = yield call(saveMemo, data);
    // yield put(setSuccess("Success"));
    yield put(saveMemoSuccess(response));
    
  } catch (error) {
    console.log('onSaveMemo error >> ' + error);
    //yield put(apiError(error))
  }
}

function* onSaveContactsSettings({ payload: data }) {
  try {
    const response = yield call(saveContactsSettings, data);
    yield put(setSuccess("Success"));
    
  } catch (error) {
    console.log('onSaveContactsSettings error >> ' + error);
    //yield put(apiError(error))
  }
}

function* onGetContactsSettings() {
  try {
    const response = yield call(getContactsSettings);
    yield put(getContactsSettingsSuccess(response));

  } catch (error) {
    console.log('onGetContactsSettings error >> ' + error);
    //yield put(apiError(error))
  }
}

function* onDownContactsExcel({payload : data}) {
  try {
    yield call(downContactsExcel, data);
    yield put(downContactsExcelSuccess());

  } catch (error) {
    console.log('onDownContactsExcel error >> ' + error);
    //yield put(apiError(error))
  }
}

function* onGetUploadedContactsList({ payload: data }) {
  try {
    const response = yield call(getUploadedContactsList, data);
    yield put(getUploadedContactsListSuccess(response));
    
  } catch (error) {
    console.log('onGetUploadedContactsList error >> ' + error);
    //yield put(apiError(error))
  }
}

function* onSaveUploadedContacts({ payload: data }) {
  try {
    const response = yield call(saveUploadedContacts, data.chkList);
    //새로고침
    if(data.filter != null) yield put(getUploadedContactsListAction(data.filter));
    
  } catch (error) {
    console.log('onSaveUploadedContacts error >> ' + error);
    //yield put(apiError(error))
  }
}

function* onDeleteUploadedContacts({ payload: data }) {
  try {
    const response = yield call(deleteUploadedContacts, data.chkList);
    //새로고침
    if(data.filter != null) yield put(getUploadedContactsListAction(data.filter));
    
  } catch (error) {
    console.log('onDeleteUploadedContacts error >> ' + error);
    //yield put(apiError(error))
  }
}

function* onDownUploadedContactsExcel({payload : data}) {
  try {
    yield call(downUploadedContactsExcel, data);
    yield put(downUploadedContactsExcelSuccess());

  } catch (error) {
    console.log('onDownUploadedContactsExcel error >> ' + error);
    //yield put(apiError(error))
  }
}

function* onGetDomainCorrectYnCount({ payload: data }) {
  try {
    const response = yield call(getDomainCorrectYnCount, data);
    yield put(getDomainCorrectYnCountSuccess(response));
    
  } catch (error) {
    console.log('onGetDomainCorrectYnCount error >> ' + error);
  }
}

function* onSaveDomainCorrectYn({ payload: data }) {
  try {
    const response = yield call(saveDomainCorrectYn, data);
    yield put(getDomainCorrectYnCountSuccess(response));
    yield put(setSuccess("Thank you!"));
  } catch (error) {
    console.log('onSaveDomainCorrectYn error >> ' + error);
  }
}

function* onGetContactSearchList({ payload: data }) {
  try {
    const response = yield call(getContactSearchList, data);
    yield put(getContactSearchListSuccess(response));
    
  } catch (error) {
    console.log('onGetContactSearchList error >> ' + error);
  }
}

function* onDownSearchEntityExcel({payload : data}) {
  try {
    yield call(downSearchEntityExcel, data);
    yield put(downSearchEntityExcelSuccess());

  } catch (error) {
    console.log('onDownSearchEntityExcel error >> ' + error);
  }
}

function* contactsSaga() {
  yield takeLatest(GET_CONTACTS_LIST, onGetContactsList);
  yield takeLatest(UPLOAD_CONTACTS, onUploadContacts);
  yield takeLatest(MULTIPLE_STAR_CONTACTS, onMultipleStarContacts);
  yield takeLatest(MULTIPLE_UNSTAR_CONTACTS, onMultipleUnstarContacts);
  yield takeLatest(MULTIPLE_HIDE_CONTACTS, onMultipleHideContacts);
  yield takeLatest(MULTIPLE_UNHIDE_CONTACTS, onMultipleUnhideContacts);
  yield takeLatest(MULTIPLE_UNSUBSCRIBE_CONTACTS, onMultipleUnsubscribeContacts);
  yield takeLatest(CHECK_EMAIL_UNSUBSCRIBE, onCheckEmailUnsubscribe);
  yield takeLatest(SAVE_CONTACTS, onSaveContacts);
  yield takeLatest(SAVE_CONTACTS_SIMPLE, onSaveContactsSimple);
  yield takeLatest(DELETE_CONTACTS, onDeleteContacts);
  yield takeLatest(GET_IMPORTED_CONTACTS, onGetImportedContacts);
  yield takeLatest(GET_SUGGESTED_CONTACTS, onGetSuggestedContacts);
  yield takeLatest(GET_CONTACTS_DETAILS, onGetContactsDetails);
  yield takeLatest(GET_CONTACTS_DETAIL_LIST, onGetContactsDetailList);
  yield takeLatest(GET_CONTACTS_MEMO, onGetContactsMemo);
  yield takeLatest(SAVE_MEMO, onSaveMemo);
  yield takeLatest(SAVE_CONTACTS_SETTINGS, onSaveContactsSettings);
  yield takeLatest(GET_CONTACTS_SETTINGS, onGetContactsSettings);
  yield takeLatest(DOWN_CONTACTS_EXCEL, onDownContactsExcel);
  
  yield takeLatest(GET_UPLOADED_CONTACTS_LIST, onGetUploadedContactsList);
  yield takeLatest(SAVE_UPLOADED_CONTACTS, onSaveUploadedContacts);
  yield takeLatest(DELETE_UPLOADED_CONTACTS, onDeleteUploadedContacts);
  yield takeLatest(DOWN_UPLOADED_CONTACTS_EXCEL, onDownUploadedContactsExcel);

  yield takeLatest(GET_DOMAIN_CORRECT_YN_COUNT, onGetDomainCorrectYnCount);
  yield takeLatest(SAVE_DOMAIN_CORRECT_YN, onSaveDomainCorrectYn);
  yield takeLatest(GET_CONTACT_SEARCH_LIST, onGetContactSearchList);
  yield takeLatest(DOWN_SEARCH_ENTITY_EXCEL, onDownSearchEntityExcel);
}

export default contactsSaga

