import {
  GET_SERVICE_MANUAL_LIST,
  GET_SERVICE_MANUAL_LIST_SUCCESS,
  GET_SERVICE_MANUAL_INFO,
  GET_SERVICE_MANUAL_INFO_SUCCESS,
  UPDATE_SERVICE_MANUAL,
  UPDATE_SERVICE_MANUAL_SUCCESS,
  UPDATE_SERVICE_MANUAL_PRIORITY,
  UPDATE_SERVICE_MANUAL_PRIORITY_SUCCESS,
  UPDATE_SERVICE_MANUAL_PDF,
  UPDATE_SERVICE_MANUAL_PDF_SUCCESS,
  CHANGE_SERVICE_MANUAL_LIST,
  CHANGE_SERVICE_MANUAL_INFO,
  CHANGE_SERVICE_MANUAL_RESULT,
  CHANGE_SERVICE_MANUAL_LOADING,
  CHANGE_IS_RELOAD,
  GET_PREVIEW_SERVICE_MANUAL,
  GET_PREVIEW_SERVICE_MANUAL_SUCCESS,
  SET_PREVIEW_SERVICE_MANUAL,
  UPDATE_SERVICE_MANUAL_VIEW_CNT_SUCCESS,
  GET_SERVICE_MANUAL_PAGE_NAMELIST,
  GET_SERVICE_MANUAL_PAGE_NAMELIST_SUCCESS,
} from "./actionTypes"

const initialState = {
  loading: false,
  error: "",
  result: -1,
  totalPage: 1,
  serviceManualList: [],
  serviceManualInfo: {},
  pageNames: ['Madrid Notifier', 'Filer Rankings', 'General Statistics', 'Country Rankings', 'Specific Search', 'Contacts', 'Sending Analytics', 'Settings - Membership plan', 'Settings - Invoicing', 'Settings - Account', 'Settings - Emailing'],
  isReload: false,
  previewServiceManual: {},
  previewPdf: "",
}

const ServiceManual = (state = initialState, action) => {
  switch (action.type) {
    case GET_SERVICE_MANUAL_LIST:
      return {
        ...state,
        loading: true,
        domainList: [],
      }
    case GET_SERVICE_MANUAL_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        totalPage: action.payload.totalPage,
        serviceManualList: action.payload.serviceManualList,
      }
    case GET_SERVICE_MANUAL_INFO:
      return {
        ...state,
        loading: true,
      }
    case GET_SERVICE_MANUAL_INFO_SUCCESS:
      if (action.payload.serviceManualInfo) {
        let _serviceManualList = [];
        state.serviceManualList.map((serviceManual, index) => {
          if (action.payload.serviceManualInfo.no == serviceManual.no) {
            _serviceManualList.push(action.payload.serviceManualInfo);
          } else {
            _serviceManualList.push(serviceManual);
          }
        });

        return {
          ...state,
          serviceManualList: _serviceManualList,
          loading: false,
        }
      } else {
        return {
          ...state,
          loading: false,
        }
      }
    case UPDATE_SERVICE_MANUAL:
      return {
        ...state,
        loading: true,
        
      }
    case UPDATE_SERVICE_MANUAL_SUCCESS:
      if (action.payload.crudType == 'C' || action.payload.crudType == 'D') {
        return {
          ...state,
          isReload: true,
        }
      } else {
        let _serviceManualList = [];
        state.serviceManualList.map((serviceManual, index) => {
          if (action.payload.serviceManualInfo.no == serviceManual.no) {
            _serviceManualList.push(action.payload.serviceManualInfo);
          } else {
            _serviceManualList.push(serviceManual);
          }
        });

        return {
          ...state,
          loading: false,
          serviceManualList: _serviceManualList,
        }
      }
    case UPDATE_SERVICE_MANUAL_PRIORITY:
      return {
        ...state,
        loading: true,
      }
    case UPDATE_SERVICE_MANUAL_PRIORITY_SUCCESS:
      if (!action.payload.isReload && action.payload.data.serviceManualInfo) {
        let _serviceManualList = [];
        state.serviceManualList.map((serviceManual, index) => {
          if (action.payload.data.serviceManualInfo.no == serviceManual.no) {
            _serviceManualList.push(action.payload.data.serviceManualInfo);
          } else {
            _serviceManualList.push(serviceManual);
          }
        });

        return {
          ...state,
          serviceManualList: _serviceManualList,
          loading: false,
        }
      } else {
        return {
          ...state,
          isReload: action.payload.isReload,
        }
      }
    case UPDATE_SERVICE_MANUAL_PDF:
      return {
        ...state,
        loading: true,
      }
    case UPDATE_SERVICE_MANUAL_PDF_SUCCESS:
      if (action.payload.serviceManualInfo) {
        let _serviceManualList = [];
        state.serviceManualList.map((serviceManual, index) => {
          if (action.payload.serviceManualInfo.no == serviceManual.no) {
            _serviceManualList.push(action.payload.serviceManualInfo);
          } else {
            _serviceManualList.push(serviceManual);
          }
        });

        return {
          ...state,
          serviceManualList: _serviceManualList,
          loading: false,
        }
      } else {
        return {
          ...state,
          loading: false,
        }
      }
    case CHANGE_SERVICE_MANUAL_LIST:
      return {
        ...state,
        serviceManualList: action.payload,
      }
    case CHANGE_SERVICE_MANUAL_INFO:
      return {
        ...state,
        serviceManualInfo: action.payload,
      }
    case CHANGE_SERVICE_MANUAL_RESULT:
      return {
        ...state,
        loading: false,
        result: action.payload,
      }
    case CHANGE_SERVICE_MANUAL_LOADING:
      return {
        ...state,
        loading: action.payload,
      }
    case CHANGE_IS_RELOAD:
      return {
        ...state,
        isReload: action.payload,
      }
    case GET_PREVIEW_SERVICE_MANUAL:
      return {
        ...state,
        loading: action.payload,
      }
    case GET_PREVIEW_SERVICE_MANUAL_SUCCESS:
      let _serviceManualList = [];
      state.serviceManualList.map((serviceManual, index) => {
        if (action.payload.previewServiceManual.no == serviceManual.no) {
          _serviceManualList.push(action.payload.previewServiceManual);
        } else {
          _serviceManualList.push(serviceManual);
        }
      });

      // console.log('action.payload.previewPdf : ' + action.payload.previewPdf);

      return {
        ...state,
        serviceManualList: _serviceManualList,
        previewServiceManual: action.payload.previewServiceManual,
        previewPdf: action.payload.previewPdf,
      }
    case SET_PREVIEW_SERVICE_MANUAL:
      return {
        ...state,
        previewServiceManual: action.payload.data,
        previewPdf: "",
      }
    case UPDATE_SERVICE_MANUAL_VIEW_CNT_SUCCESS:
      let _serviceManualList2 = [];
      state.serviceManualList.map((serviceManual, index) => {
        if (action.payload.no == serviceManual.no) {
          serviceManual.viewCnt = serviceManual.viewCnt+1;
        }
        _serviceManualList2.push(serviceManual);
      });

      return {
        ...state,
        serviceManualList: _serviceManualList2,
      }
    case GET_SERVICE_MANUAL_PAGE_NAMELIST:
      return {
        ...state,
        pageNames: [],
      }
    case GET_SERVICE_MANUAL_PAGE_NAMELIST_SUCCESS:
      return {
        ...state,
        pageNames: action.payload.serviceManualPageNameList,
      }
    default:
      state = { ...state }
      break
  }
  return state
}

export default ServiceManual
