//REGISTER
export const POST_FAKE_REGISTER = "/post-fake-register"
export const POST_WECREST_REGISTER = "/register.json"
export const GET_HOME_COUNTRY_LIST = "/getHomeCountryList.json"

//LOGIN
export const POST_FAKE_LOGIN = "/post-fake-login"
export const POST_FAKE_JWT_LOGIN = "/post-jwt-login"
export const POST_FAKE_PASSWORD_FORGET = "/fake-forget-pwd"
export const POST_FAKE_JWT_PASSWORD_FORGET = "/jwt-forget-pwd"
export const SOCIAL_LOGIN = "/social-login"

export const WECREST_LOGIN = "/login.json"
export const WECREST_LOGOUT = "/logout.json"
export const FORGET_PASSWORD = "/reset-password/send-mail.json"
export const RESET_PASSWORD = "/reset-password.json"

export const CHECK_COOKIE_AGREE = "/checkCookieAgree.json"
export const UPDATE_COOKIE_AGREE = "/updateCookieAgree.json"

//PROFILE
export const POST_EDIT_JWT_PROFILE = "/post-jwt-profile"
export const POST_EDIT_PROFILE = "/post-fake-profile"

//PRODUCTS
export const GET_PRODUCTS = "/products"
export const GET_PRODUCTS_DETAIL = "/product"

//CALENDER
export const GET_EVENTS = "/events"
export const ADD_NEW_EVENT = "/add/event"
export const UPDATE_EVENT = "/update/event"
export const DELETE_EVENT = "/delete/event"
export const GET_CATEGORIES = "/categories"

//CHATS
export const GET_CHATS = "/chats"
export const GET_GROUPS = "/groups"
export const GET_CONTACTS = "/contacts"
export const GET_MESSAGES = "/messages"
export const ADD_MESSAGE = "/add/messages"

//ORDERS
export const GET_ORDERS = "/orders"

//CART DATA
export const GET_CART_DATA = "/cart"

//CUSTOMERS
export const GET_CUSTOMERS = "/customers"

//SHOPS
export const GET_SHOPS = "/shops"

//CRYPTO
export const GET_WALLET = "/wallet"
export const GET_CRYPTO_ORDERS = "/crypto/orders"

//INVOICES
export const GET_INVOICES = "/invoices"
export const GET_INVOICE_DETAIL = "/invoice"

//PROJECTS
export const GET_PROJECTS = "/projects"
export const GET_PROJECT_DETAIL = "/project"

//TASKS
export const GET_TASKS = "/tasks"

//CONTACTS
export const GET_USERS = "/users"
export const GET_USER_PROFILE = "/user"

export const GET_USER_LIST = "/user/list.json"

//DASHBOARD
export const GET_DASHBOARD_CARD_INFO = "/getDashboardCardInfo.json"
export const SEND_FEEDBACK = "/sendFeedback.json"
export const GET_WECREST_INFO = "/wecrestInfo.json"

//GENERAL STATISTICS
export const GET_ALL_JURISDICTIONS_LIST = "/getAllJurisdictionsList.json"
export const GET_JURISDICTIONS_LIST = "/getJurisdictionsList.json"
export const GET_MULTIPLE_JURISDICTIONS_LIST = "/getMultipleJurisdictionsList.json"
export const GET_COUNTRY_LIST = "/getCountryList.json"
export const GET_ALL_COUNTRY_LIST = "/getAllCountryList.json"
export const GET_MULTIPLE_COUNTRY_LIST = "/getMultipleCountryList.json"
export const GET_SEARCH_NAME_LIST = "/getSearchNameList.json"
export const GET_SEARCH_TARGET_GROUP_LIST = "/getSearchTargetGroup.json"
export const GET_GENERALSTATISTICS_LIST = "/getGeneralStatisticsList.json"
export const DOWN_STATISTICS_EXCEL = "/downStatisticsExcel"
export const DOWN_STATISTICS_PDF = "/downStatisticsPdf"

//COUNTRY RANKINGS
export const GET_COUNTRY_RANKINGS_LIST = "/viewCountryRankings.json"
export const DOWN_COUNTRY_RANKINGS_EXCEL = "/downCountryRankingExcel"
export const DOWN_COUNTRY_RANKINGS_PDF = "/downCountryRankingPdf"

//SPECIFIC SEARCH
export const GET_SPECIFIC_SEARCH_LIST = "/viewSpecificSearch.json"
export const DOWN_SPECIFIC_SEARCH_EXCEL = "/downSpecificSearchExcel"
export const DOWN_SPECIFIC_SEARCH_PDF = "/downSpecificSearchPdf"

//EMAIL FORM
export const GET_EMAIL_TEMPLATES = "/getEmailTemplates.json"
export const DELETE_EMAIL_TEMPLATE = "/deleteEmailTemplate.json"
export const GET_EMAIL_FORM = "/getEmailForm.json"
export const UPDATE_EMAIL_FORM = "/updateEmailForm.json"
export const DELETE_EMAIL_FORM = "/deleteEmailForm.json"

//FILER RANKINGS
export const GET_FILER_SETTINGS = "/getFilerSettings.json"
export const SAVE_FILER_SETTINGS = "/saveFilerSetting.json"
export const GET_FILER_RANKINGS = "/getFilerRankings.json"
export const GET_FILER_RANKING_INFO = "/getFilerRankingInfo.json"
export const MULTIPLE_HIDE_FILER = "/multipleHideFiler.json"
export const MULTIPLE_UNHIDE_FILER = "/multipleUnhideFiler.json"
export const DOWN_FILER_EXCEL = "/downFilerExcel"
export const DOWN_MULTIPLE_FILER_EXCEL = "/downMultipleFilerExcel"
export const DOWN_FILER_PDF = "/downFilerPdf"
export const DOWN_FILER_PDF_DATA_ONLY = "/downFilerPdfDataOnly"
export const DOWN_MULTIPLE_FILER_PDF = "/downMultipleFilerPdf"
export const FILER_SEND_EMAIL = "/filerSendEmail.json"
export const FILER_PREVIEW_EMAIL = "/filerPreviewEmail.json"

//SETTINGS
export const GET_ACCOUNT_INFO = "/getAccountInfo.json"
export const UPDATE_ACCOUNT_INFO = "/updateAccountInfo.json"
export const UPDATE_NEW_DASHBOARD = "/updateNewDashboard.json"
export const UPDATE_ACCOUNT_PASSWORD = "/updateAccountPassword.json"
export const UPDATE_ACCOUNT_INVOICE_INFO = "/updateAccountInvoiceInfo.json"
export const UPDATE_ACCOUNT_UNSUBSCRIBE_INFO = "/updateAccountUnsubscribeInfo.json"
export const UPDATE_ACCOUNT_UPTODATE_INFO = "/updateAccountUpToDateInfo.json"
export const UPDATE_SENDER_EMAIL = "/updateSenderEmail.json"
export const UPDATE_SUBSCRIPTION_EMAIL_TEMPLATE = "/updateSubscriptionEmailTemplate.json"
export const UPDATE_FILER_EMAIL_TEMPLATE = "/updateFilerEmailTemplate.json"
export const GET_USER_SIGNATURE_SAVE_POPUP_APPEAR_INFO = "/getUserSignatureSavePopupAppearInfo.json"
export const ADD_USER_SIGNATURE_SAVE_POPUP_APPEAR_LOG = "/addUserSignatureSavePopupAppearLog.json"
export const GET_USER_SIGNATURE_INFO = "/getUserSignatureInfo.json"
export const UPDATE_USER_SIGNATURE_INFO = "/updateUserSignature.json"

export const GET_EMAILING_SETTINGS = "/getEmailingSettings.json"
export const ADD_SENDER_EMAIL = "/addSenderEmail.json"
export const DELETE_SENDER_EMAIL = "/deleteSenderEmail.json"
export const UPDATE_DISPLAY_NAME = "/updateDisplayName.json"
export const UPDATE_SENDER_EMAIL_ADDRESS = "/updateSenderEmailAddress.json"
export const GET_USER_SIGNATURE = "/getUserSignature.json"
export const ADD_USER_SIGNATURE = "/addUserSignature.json"
export const UPDATE_USER_SIGNATURE = "/updateUserSignature.json"
export const DELETE_USER_SIGNATURE = "/deleteUserSignature.json"
export const ADD_USER_JURISDICTION_EMAIL_SETTING = "/addUserJurisdictionEmailSetting.json"
export const UPDATE_USER_JURISDICTION_EMAIL_SETTING = "/updateUserJurisdictionEmailSetting.json"
export const DELETE_USER_JURISDICTION_EMAIL_SETTING = "/deleteUserJurisdictionEmailSetting.json"

export const GET_OCMN_SHOW_STARTUP = "/getOcmnShowStartup.json"
export const ADD_USER_INVOICE_INFO = "/addUserInvoiceInfo.json"
export const MODIFY_USER_INVOICE_INFO = "/modifyUserInvoiceInfo.json"
export const REMOVE_USER_INVOICE_INFO = "/removeUserInvoiceInfo.json"
export const GET_USER_SUBSCRIPTION_LIST = "/getUserSubscriptionList.json"
export const MODIFY_USER_SUBSCRIPTION_INVOICE_INFO = "/modifyUserSubscriptionInvoiceInfo.json"

//MADRID NOTIFIER
export const GET_MADRID_SETTINGS = "/getMadridSettings.json"
export const SAVE_MADRID_SETTINGS = "/saveMadridSetting.json"
export const GET_MADRID_NOTIFIER = "/getMadridNotifier.json"
export const GET_MADRID_NOTIFIER_INFO = "/getMadridNotifierInfo.json"
export const MULTIPLE_HIDE_MADRID = "/multipleHideMadrid.json"
export const MULTIPLE_UNHIDE_MADRID = "/multipleUnhideMadrid.json"
export const MULTIPLE_STAR_MADRID = "/multipleStarMadrid.json"
export const MULTIPLE_UNSTAR_MADRID = "/multipleUnstarMadrid.json"
export const DOWN_MADRID_EXCEL = "/downMadridExcel"
export const DOWN_MULTIPLE_MADRID_EXCEL = "/downMultipleMadridExcel"
export const DOWN_MADRID_PDF = "/downMadridPdf"
export const DOWN_MULTIPLE_MADRID_PDF = "/downMultipleMadridPdf"
export const SEND_MADRID_EMAIL = "/sendMadridEmail.json"
export const PREVIEW_MADRID_EMAIL = "/previewMadridEmail.json"
export const GET_OCMN_LIST = "/getOCMNList.json"
export const SAVE_OCMN_SHOW_STARTUP = "/saveOCMNShowStartup.json"
export const SAVE_OCMN_AUTO_SEND_YN = "/saveOCMNAutoSendYn.json"
export const SAVE_OCMN_SETTING = "/saveOCMNSetting.json"
export const GET_OCMN_SUGGESTED_CONTACTS = "/getOCMNSuggestedContacts.json"
export const SEND_OCMN_EMAIL = "/sendOCMNEmail.json"
export const ADD_OCMN_LOG = "/addOcmnLog.json"

// UNLOCK
export const GET_USER_UNLOCK_INFO = "/getUserUnlockInfo.json"
export const UNLOCK_MADRID = "/unlockMadrid.json"
export const UNLOCK_FILER = "/unlockFiler.json"

// CONTACTS
export const GET_CONTACTS_LIST = "/getContactsList.json"
export const MULTIPLE_STAR_CONTACTS = "/multipleStarContacts.json"
export const MULTIPLE_UNSTAR_CONTACTS = "/multipleUnstarContacts.json"
export const MULTIPLE_HIDE_CONTACTS = "/multipleHideContacts.json"
export const MULTIPLE_UNHIDE_CONTACTS = "/multipleUnhideContacts.json"
export const MULTIPLE_UNSUBSCRIBE_CONTACTS = "/multipleUnsubscribeContacts.json"
export const CHECK_EMAIL_UNSUBSCRIBE = "/checkEmailUnsubscribe.json"
export const SAVE_CONTACTS = "/saveContacts.json"
export const SAVE_CONTACTS_SIMPLE = "/saveContactsSimple.json"
export const DELETE_CONTACTS = "/deleteContacts.json"
export const GET_IMPORTED_CONTACTS = "/getImportedContacts.json"
export const GET_SUGGESTED_CONTACTS = "/getSuggestedContacts.json"
export const GET_CONTACTS_DETAILS = "/getContactsDetails.json"
export const GET_CONTACTS_DETAIL_LIST = "/getContactsDetailList.json"
export const GET_CONTACTS_MEMO = "/getContactsMemo.json"
export const SAVE_MEMO = "/saveMemo.json"
export const SAVE_CONTACTS_SETTINGS = "/saveContactsSettings.json"
export const GET_CONTACTS_SETTINGS = "/getContactsSettings.json"
export const DOWN_CONTACTS_EXCEL = "/downContactsExcel.json"

export const GET_UPLOADED_CONTACTS_LIST = "/getUploadedContactsList.json"
export const UPLOAD_CONTACTS = "/uploadContacts.json"
export const SAVE_UPLOADED_CONTACTS = "/saveUploadedContacts.json"
export const DELETE_UPLOADED_CONTACTS = "/deleteUploadedContacts.json"
export const DOWN_UPLOADED_CONTACTS_EXCEL = "/downUploadedContactsExcel.json"

export const GET_DOMAIN_CORRECT_YN_COUNT = "/getDomainCorrectYnCount.json"
export const SAVE_DOMAIN_CORRECT_YN = "/saveDomainCorrectYn.json"

export const GET_CONTACT_SEARCH_LIST = "/getContactSearchList.json"
export const DOWN_SEARCH_ENTITY_EXCEL = "/downSearchEntityExcel.json"

// SENDING ANALYTICS
export const GET_SENDING_ANALYTICS_MN_SETTINGS = "/getSendingAnalyticsSettingsMN.json"
export const SAVE_SENDING_ANALYTICS_MN_SETTINGS = "/saveSendingAnalyticsSettingsMN.json"
export const GET_SENDING_ANALYTICS_MN_LIST = "/getSendingAnalyticsNMList.json"
export const DOWN_SENDING_ANALYTICS_MN_EXCEL = "/downSendingAnalyticsNMExcel"
export const GET_SENDING_ANALYTICS_MN = "/getSendingAnalyticsMN.json"
export const GET_SENDING_ANALYTICS_FR_SETTINGS = "/getSendingAnalyticsSettingsFR.json"
export const SAVE_SENDING_ANALYTICS_FR_SETTINGS = "/saveSendingAnalyticsSettingsFR.json"
export const GET_SENDING_ANALYTICS_FR_LIST = "/getSendingAnalyticsFRList.json"
export const DOWN_SENDING_ANALYTICS_FR_EXCEL = "/downSendingAnalyticsFRExcel"
export const GET_MADRID_SENDING_ANALYTICS_STATISTICS = "gelectMadridSendingAnalyticsStatistics.json"
export const GET_FILER_SENDING_ANALYTICS_STATISTICS = "gelectFilerSendingAnalyticsStatistics.json"

// ADMIN DOMAIN MANAGE
export const GET_DOMAIN_LIST = "/getDomainList.json"
export const GET_DOMAIN_ACTION_HISTORY = "/getDomainActionHistory.json"
export const SAVE_DOMAIN_INFO = "/saveDomainInfo.json"
export const GET_BLACK_LIST = "/getBlackList.json"
export const ADD_BLACK_DOMAIN = "/addBlackList.json"
export const DOWN_EXCEL_BLACK_LIST = "/downExcelBlackList"
export const GET_ENTITY_DETAIL = "/getEntityDetail.json"
export const SAVE_ENTITY_EMAILS = "/saveEntityEmails.json"
export const DELETE_ENTITY_EMAILS = "/deleteEntityEmails.json"

// MEMBERSHIP PLAN
export const GET_JURISDICTIONS_MONTHLY_PRICING = "/jurisdictionMonthlyPricing.json"
export const GET_USER_MEMBERSHIP_INFO = "/getUserMembershipInfo.json"
export const UPDATE_SUBSCRIPTION_TO_FREE = "/updateSubscriptionToFree.json"
export const UPDATE_GOLD_MEMBERSHIP_PLAN = "/updateGoldMembershipPlan.json"
export const GET_PAYPAL_PLAN_ID = "/getPaypalPlanId.json"

// SERVICE MANUAL
export const GET_SERVICE_MANUAL_LIST = "/getServiceManualList.json"
export const GET_SERVICE_MANUAL_INFO = "/getServiceManualInfo.json"
export const UPDATE_SERVICE_MANUAL_LIST = "/updateServiceManual.json"
export const UPDATE_SERVICE_MANUAL_VIEW_CNT = "/updateServiceManualViewCnt.json"
export const UPDATE_SERVICE_MANUAL_PRIORITY = "/updateServiceManualPriority.json"
export const UPDATE_SERVICE_MANUAL_PDF = "/updateServiceManualPDF.json"
export const PREVIEW_SERVICE_MANUAL = "/previewServiceManual.json"
export const GET_SERVICE_MANUAL_PAGE_NAMELIST = "/getServiceManualPageNameList.json"

// ADMIN PRICING MANAGEMENT
export const GET_SUBSCRIPTION_ITEM_LIST = "/getSubscriptionItemList.json"
export const SAVE_SUBSCRIPTION_ITEM = "/saveSubscriptionItem.json"
export const DELETE_SUBSCRIPTION_ITEM = "/deleteSubscriptionItem.json"

// ADMIN INVOICE MANAGEMENT
export const GET_ADM_INVOICE_MANAGEMENT_SEARCH_LIST = "/getAdmInvoiceManagementSearchList.json"
export const DOWN_INVOICE_PDF = "/downInvoicePdf"
export const UPDATE_INVOICE_STATUS = "/updateInvoiceStatus.json"
export const CONFIRM_PAYMENT = "/confirmPayment.json"
export const GET_SEARCH_USER_LIST = "/admSearchUser.json"
export const GET_SUBSCRIPTION_ITEM_ALL_LIST = "/getSubscriptionItemAllList.json"
export const GET_CRESTER_LIST = "/getCresterList.json"
export const CREATE_ADMIN_INVOICE = "/createAdminInvoice.json"
export const GET_USER_INVOICE_INFO_LIST = "/getUserInvoiceInfoList.json"
export const GET_INVOICE_ITEM_INFO = "/getInvoiceItemInfo.json"

// COMMUNITY
export const GET_COMMUNITY_LIST = "/getCommunityList.json"
export const GET_COMMUNITY_DETAIL = "/getCommunityDetail.json"
export const CREATE_COMMUNITY = "/createCommunity.json"
export const MODIFY_COMMUNITY = "/modifyCommunity.json"
export const REMOVE_COMMUNITY = "/removeCommunity.json"
export const GET_COMMUNITY_ATTACH_FILE_LIST = "/getCommunityAttachFileList.json"
export const ADD_COMMUNITY_ATTACH_FILE = "/addCommunityAttachFile.json"
export const REMOVE_COMMUNITY_ATTACH_FILE = "/removeCommunityAttachFile.json"
export const GET_COMMUNITY_REPLY_LIST = "/getCommunityReplyList.json"
export const CREATE_COMMUNITY_REPLY = "/createCommunityReply.json"
export const MODIFY_COMMUNITY_REPLY = "/modifyCommunityReply.json"
export const REMOVE_COMMUNITY_REPLY = "/removeCommunityReply.json"

// ENTITY CONNECT MANAGEMENT
export const GET_CONNECTABLE_ENTITY_LIST = "/getConnectableEntityList.json"
export const GET_MY_ENTITY_LIST = "/getMyEntityList.json"
export const GET_MAIN_DOMAIN = "/getMainDomain.json"
export const GET_SEARCH_ENTITY_LIST = "/getSearchEntityList.json"
export const GET_USER_ENTITY_CONNECT_LIST = "/getUserEntityConnectList.json"
export const DOWN_EXCEL_USER_ENTITY_CONNECT_LIST = "/downExcelUserEntityConnectList"
export const CONFIRM_USER_ENTITY_CONNECT = "/confirmUserEntityConnect.json"
export const DISCONNECT_USER_ENTITY = "/disConnectUserEntity.json"
export const GET_USER_ENTITY_CONNECT_CLAIM_LIST = "/getUserEntityConnectClaimList.json"
export const DOWN_EXCEL_USER_ENTITY_CONNECT_CLAIM_LIST = "/downExcelUserEntityConnectClaimList"
export const ADD_USER_ENTITY_CONNECT_CLAIM = "/addUserEntityConnectClaim.json"
export const MODIFY_USER_ENTITY_CONNECT_CLAIM_STATUS = "/modifyUserEntityConnectClaimStatus.json"
export const CONFIRM_USER_ENTITY_CONNECT_CLAIM = "/confirmUserEntityConnectClaim.json"
export const GET_REQUESTER_LIST = "/getRequester.json"
export const GET_USER_DOMAIN_LIST = "/getUserDomainList.json"
export const ADD_USER_DOMAIN = "/addUserDomain.json"
export const MODIFY_USER_DOMAIN = "/modifyUserDomain.json"
export const REMOVE_USER_DOMAIN = "/removeUserDomain.json"

// ADMIN ACCOUNT MANAGEMENT
export const GET_ADM_ACCOUNT_MANAGEMENT_SEARCH_LIST = "/getAdmAccountManagementSearchList.json"
export const DOWN_EXCEL_ADM_ACCOUNT_MANAGEMENT = "/downExcelAdmAccountManagement"
export const UPDATE_ACCOUNT_BY_ADMIN = "/updateAccountByAdmin.json"
export const UPDATE_PASSWORD_BY_ADMIN = "/updatePasswordByAdmin.json"
export const RESET_SMTP_BY_ADMIN = "/resetSmtpByAdmin.json"
export const VERIFY_ACCOUNT_BY_ADMIN = "/verifyAccountByAdmin.json"
export const VIRTUAL_LOG_IN_BY_ADMIN = "/virtualLogInByAdmin.json"
export const CREATE_ACCOUNT_BY_ADMIN = "/createAccountByAdmin.json"


export const GET_MY_CONNECTED_CASE_SETTINGS = "/getMyConnectedCaseSettings.json"
export const SAVE_MY_CONNECTED_CASE_SETTINGS = "/saveMyConnectedCaseSetting.json"
export const GET_MY_CASE_MANAGEMENT_SETTINGS = "/getMyCaseManagementSettings.json"
export const SAVE_MY_CASE_MANAGEMENT_SETTINGS = "/saveMyCaseManagementSetting.json"
export const GET_MY_CASE_MANAGEMENT_LIST = "/getMyCaseManagementList.json"
export const DOWN_MY_CASE_MANAGEMENT_EXCEL = "/downMyCaseManagementExcel"
export const DOWN_MULTIPLE_MY_CASE_MANAGEMENT_EXCEL = "/downMultipleMyCaseManagementExcel"
export const DOWN_MY_CASE_MANAGEMENT_PDF = "/downMyCaseManagementPdf"
export const DOWN_MULTIPLE_MY_CASE_MANAGEMENT_PDF = "/downMultipleMyCaseManagementPdf"
export const GET_USER_IR_INFO_CLAIM_LIST = "/getUserIrInfoClaimList.json"
export const ADD_USER_IR_INFO_CLAIM = "/addUserIrInfoClaim.json"
export const CONFIRM_USER_IR_INFO_CLAIM = "/confirmUserIrInfoClaim.json"
export const GET_TARGET_GROUP_DETAIL_LIST = "/getTargetGroupDetailList.json"