import { call, put, take, takeEvery, takeLatest, fork} from "redux-saga/effects"
// import { eventChannel, END } from 'redux-saga'

import { 
  GET_EMAIL_TEMPLATES,
  GET_EMAILFORM,
  UPDATE_EMAILFORM,
  DELETE_EMAILFORM,
} from "./actionTypes"

import { 
  getEmailTemplatesSuccess,
  getEmailFormSuccess, 
  updateEmailFormSuccess,
  updateEmailFormError,
  deleteEmailFormSuccess,
  setReducerValue,
} from "./actions"

import {
  setSettingsReducerValue
} from "../settings/actions"

import {
  getEmailTemplates,
  getEmailForm,
  updateEmailForm,
  deleteEmailForm,
} from "../../helpers/backend_helper"

function* onGetEmailTemplates({ payload: data }) {
  try {
    // console.log('onGetEmailTemplates saga >> ' + JSON.stringify(data));
    const response = yield call(getEmailTemplates, data);
    yield put(getEmailTemplatesSuccess(response));
  } catch (error) {
    console.log('onGetEmailTemplates error >> ' + error);
    
    //yield put(apiError(error))
  }
}

function* onGetEmailform({ payload: data }) {
  try {
    // console.log('onGetEmailform saga data >> ' + JSON.stringify(data));
    const response = yield call(getEmailForm, data);
    yield put(getEmailFormSuccess(response));
  } catch (error) {
    console.log('onGetEmailform error >> ' + error);
    
    //yield put(apiError(error))
  }
}

// SAGA에서 upload progress bar 적용 
// function* watchOnProgress(chan) {
//   while (true) {
//       const data = yield take(chan);
//       console.log('watchOnProgress : ' + data);
//       yield put(setReducerValue('UPLOAD_RATE', data));
//   }
// }

function* onUpdateEmailform({ payload: formData }) {
  try {
    // [S] SAGA에서 upload progress bar 적용 
    // let emit;
    // const chan = eventChannel(emitter => {
    //   emit = emitter;
    //   return () => {};
    // });

    // const config = {
    //   onUploadProgress: (progressEvent) => {
    //     let percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
    //     emit(percentCompleted);
    //     if(percentCompleted===100)
    //         emit(END);
    //     else
    //         emit(percentCompleted);
    //   }
    // };

    // yield fork(watchOnProgress, chan);
    // [E] SAGA에서 upload progress bar 적용 

    // console.log('onUpdateEmailform saga data >> ');
    const response = yield call(updateEmailForm, formData);
    if (response.errorMsg) {
      yield put(updateEmailFormError(response));
    } else {
      yield put(updateEmailFormSuccess(response));

      // console.log('formData.emailType : ' + formData.get("emailType"));
      if (formData.get("emailType") === 'ST') {
        yield put(setSettingsReducerValue('SUCCESS', 'Success'));
      }
    }
  } catch (error) {
    console.log('onUpdateEmailform error >> ' + error);

    //yield put(apiError(error))
  }
}

function* onDeleteEmailform({ payload: data }) {
  try {
    console.log('onDeleteEmailform saga data >> ' + JSON.stringify(data));
    const response = yield call(deleteEmailForm);
    yield put(deleteEmailFormSuccess(response));
    
  } catch (error) {
    console.log('onDeleteEmailform error >> ' + error);
    //yield put(apiError(error))
  }
}

function* editorSaga() {
  yield takeLatest(GET_EMAIL_TEMPLATES, onGetEmailTemplates);
  yield takeLatest(GET_EMAILFORM, onGetEmailform);
  yield takeLatest(UPDATE_EMAILFORM, onUpdateEmailform);
  yield takeLatest(DELETE_EMAILFORM, onDeleteEmailform);
}

export default editorSaga

