export const GET_SUBSCRIPTION_ITEM_LIST = "GET_SUBSCRIPTION_ITEM_LIST"
export const GET_SUBSCRIPTION_ITEM_LIST_SUCCESS = "GET_SUBSCRIPTION_ITEM_LIST_SUCCESS"
export const CHANGE_SUBSCRIPTION_ITEM_LIST = "CHANGE_SUBSCRIPTION_ITEM_LIST"
export const SAVE_SUBSCRIPTION_ITEM = "SAVE_SUBSCRIPTION_ITEM"
export const SAVE_SUBSCRIPTION_ITEM_SUCCESS = "SAVE_SUBSCRIPTION_ITEM_SUCCESS"
export const DELETE_SUBSCRIPTION_ITEM = "DELETE_SUBSCRIPTION_ITEM"
export const DELETE_SUBSCRIPTION_ITEM_SUCCESS = "DELETE_SUBSCRIPTION_ITEM_SUCCESS"
export const SET_ADM_PRICING_MANAGEMENT_LOADING = "SET_ADM_PRICING_MANAGEMENT_LOADING"
export const SET_ADM_PRICING_MANAGEMENT_SUCCESS = "SET_ADM_PRICING_MANAGEMENT_SUCCESS"
export const SET_ADM_PRICING_MANAGEMENT_ERROR = "SET_ADM_PRICING_MANAGEMENT_ERROR"
export const SET_IS_RELOAD = "SET_IS_RELOAD"
