import {
  GET_COUNTRY_RANKINGS_LIST,
  GET_COUNTRY_RANKINGS_LIST_SUCCESS,
  DOWN_COUNTRY_RANKINGS_EXCEL,
  DOWN_COUNTRY_RANKINGS_EXCEL_SUCCESS,
  DOWN_COUNTRY_RANKINGS_PDF,
  DOWN_COUNTRY_RANKINGS_PDF_SUCCESS,
  CLEAR_COUNTRY_RANKINGS_LIST,
} from "./actionTypes"

export const getCountryRankingsList = data => {
  return {
    type: GET_COUNTRY_RANKINGS_LIST,
    payload: data,
  }
}

export const getCountryRankingsListSuccess = data => {
  return {
    type: GET_COUNTRY_RANKINGS_LIST_SUCCESS,
    payload: data,
  }
}

export const downCountryRankingsExcel = (data, fileName) => {
  return {
    type: DOWN_COUNTRY_RANKINGS_EXCEL,
    payload: {data, fileName},
  }
}

export const downCountryRankingsExcelSuccess = () => {
  return {
    type: DOWN_COUNTRY_RANKINGS_EXCEL_SUCCESS,
  }
}

export const downCountryRankingsPdf = (data, fileName) => {
  return {
    type: DOWN_COUNTRY_RANKINGS_PDF,
    payload: {data, fileName},
  }
}

export const downCountryRankingsPdfSuccess = () => {
  return {
    type: DOWN_COUNTRY_RANKINGS_PDF_SUCCESS,
  }
}

export const clearCountryRankingsList = () => {
  return {
    type: CLEAR_COUNTRY_RANKINGS_LIST,
  }
}