import {
  GET_USER_LIST,
  GET_USER_LIST_SUCCESS,
  USER_API_ERROR,
} from "./actionTypes"

const initialState = {
  error: "",
  loading: false,
  userList: [],
}

const user = (state = initialState, action) => {
  switch (action.type) {
    case GET_USER_LIST:
      return {
        ...state,
        loading: true
      }
    case GET_USER_LIST_SUCCESS:
      return {
        ...state,
        userList: action.payload,
        loading: false
      }  
    case USER_API_ERROR:
      state = { ...state, error: action.payload, loading: false }
      break
    default:
      state = { ...state }
      break
  }
  return state
}

export default user
