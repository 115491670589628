import React, { useMemo, useState } from "react";
import {
  closestCenter,
  DndContext,
  DragOverlay,
  KeyboardSensor,
  MouseSensor,
  TouchSensor,
  useSensor,
  useSensors
} from "@dnd-kit/core";
import { restrictToVerticalAxis } from "@dnd-kit/modifiers";
import {
  arrayMove,
  SortableContext,
  verticalListSortingStrategy
} from "@dnd-kit/sortable";
import { useTable } from "react-table";
import { DraggableTableRow } from "./DraggableTableRow";
import { StaticTableRow } from "./StaticTableRow";
import { cloneDeep } from 'lodash';
import { useDispatch } from "react-redux";
import { changeServiceManualList, updateServiceManualPriority } from "../../store/actions";
import {
  Table,
} from "reactstrap"

export function DragableTable({ manuName, columns, data, setData }) {
  const dispatch = useDispatch();
  const [activeId, setActiveId] = useState();
  const items = useMemo(() => data?.map(({ id }) => id), [data]);
  // Use the state and functions returned from useTable to build your UI
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow
  } = useTable({
    columns,
    data
  });
  const sensors = useSensors(
    useSensor(MouseSensor, {}),
    useSensor(TouchSensor, {}),
    useSensor(KeyboardSensor, {})
  );

  function handleDragStart(event) {
    setActiveId(event.active.id);
  }

  function handleDragEnd(event) {
    const { active, over } = event;
    if (active.id !== over.id) {
      const oldIndex = items.indexOf(active.id);
      const newIndex = items.indexOf(over.id);

      const oldPriority = data[oldIndex].priority;
      const newPriority = data[newIndex].priority;

      // console.log('oldPriority : ' + oldPriority + ', newPriority : ' + newPriority);
      
      const _data = [];
      data.map((d, idx) =>{
        let _d = cloneDeep(d);
        if (idx == oldIndex) {
          _d.priority = newPriority;
        } else {
          // up
          if (oldIndex < newIndex) {
            if (oldIndex < idx && idx <= newIndex) {
              _d.priority = Number(_d.priority) - 1
            }
          // down
          } else {
            if (oldIndex > idx && idx >= newIndex) {
              _d.priority = Number(_d.priority) + 1
            }
          }
        }
        _data.push(_d);
      }); 

      let newArray = arrayMove(_data, oldIndex, newIndex);
      setData(newArray);
      if (manuName == 'admServiceManual') {
        dispatch(changeServiceManualList(newArray))
        dispatch(updateServiceManualPriority(_data[oldIndex], false))
      }
    }

    setActiveId(null);
  }

  function handleDragCancel() {
    setActiveId(null);
  }

  const selectedRow = useMemo(() => {
    if (!activeId) {
      return null;
    }
    const row = rows.find(({ original }) => original.id === activeId);
    prepareRow(row);
    return row;
  }, [activeId, rows, prepareRow]);

  // Render the UI for your table
  return (
    <DndContext
      sensors={sensors}
      onDragEnd={handleDragEnd}
      onDragStart={handleDragStart}
      onDragCancel={handleDragCancel}
      collisionDetection={closestCenter}
      modifiers={[restrictToVerticalAxis]}
    >
      <Table className="table mb-0" {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps()}>{column.render("Header")}</th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          <SortableContext items={items} strategy={verticalListSortingStrategy}>
            {rows.map((row, i) => {
              prepareRow(row);
              return <DraggableTableRow manuName={manuName} key={row.original.id} row={row} rowIdx={i}/>;
            })}
          </SortableContext>
        </tbody>
      </Table>
      <DragOverlay>
        {activeId && (
          <Table className="table mb-0">
            <tbody>
              <StaticTableRow row={selectedRow} />
            </tbody>
          </Table>
        )}
      </DragOverlay>
    </DndContext>
  );
}
