import React, {useState, useEffect, useRef} from 'react';
import { useSelector, useDispatch } from "react-redux";
import {
    Col,
    Row,
    Card,
    CardBody,
    Form,
    Label,
    Input,
    Button,
    FormFeedback,
    UncontrolledTooltip,
  } from "reactstrap"
import EditorModal from "../../components/Common/EditorModal"
import { useFormik } from "formik";
import SearchSubscriptionItem from "./SearchSubscriptionItem"
import { Link } from "react-router-dom";
import SweetAlert from "react-bootstrap-sweetalert";
import CreateBillingProfile from "../../components/Common/CreateBillingProfile"
import SelectHomeCountry from '../../components/Filter/SelectHomeCountry';
import PreviewInvoice from "./PreviewInvoice"

import {
  getCresterList as onGetCresterList,
  createAccountByAdmin as onCreateAdminAccount,
  removeAdmAccountManagementBillingProfile as onRemoveAdmAccountManagementBillingProfile,
} from "../../store/actions";
import { cloneDeep } from 'lodash';

const AdmCreateInvoice = props => {
  const dispatch = useDispatch();

  const { accountInfo, admAccountManagementItemList, cresterList, createBillingProfileList, emailSubject, emailCcItems, emailBccItems, attachFiles, copyFiles, upFiles, userSubscriptionList } = useSelector(state => ({
    accountInfo: state.AdmAccountManagement.accountInfo,
    admAccountManagementItemList: state.AdmAccountManagement.admAccountManagementItemList,
    cresterList: state.AdmInvoiceManagement.cresterList,
    createBillingProfileList: state.AdmAccountManagement.createBillingProfileList,

    emailSubject: state.Editor.emailSubject,
    emailCcItems: state.Editor.emailCcItems,
    emailBccItems: state.Editor.emailBccItems,
    attachFiles: state.Editor.attachFiles,
    copyFiles: state.Editor.copyFiles,
    upFiles: state.Editor.upFiles,

    userSubscriptionList: state.Settings.userSubscriptionList,
  }));

  const [isOpenBillingProfile, setIsOpenBillingProfile] = useState(false);
  const billingProfileModalRef = useRef(null);

  const [errorMsg, setErrorMsg] = useState('');
  const [isOpenPreview, setIsOpenPreview] = useState(false);
  const emailType = 'ADM_ACCOUNT';
  const [emailCode, setEmailCode] = useState('01R');
  // const [isUpdateAccountInfo, setIsUpdateAccountInfo] = useState(false);
  // const [alertUpdateAccountInfo, setAlertUpdateAccountInfo] = useState(false);

  // [S] Email Editor
  const editorModalRef = useRef(null);
  const editorModal = useRef(null);

  const [isOpenEditor, setIsOpenEditor] = useState(false);
  const [isInitEditor, setIsInitEditor] = useState(false);
  const [loadedEditor, setLoadedEditor] = useState(false);
  const [createFlag, setCreateFlag] = useState(false);
  
  function openEditor() {
    if (!isInitEditor) {
      // console.log('editor init start');
      setIsInitEditor(true);
    } else {
      // console.log('editor inited');
    }
    
    setIsOpenEditor(true);
    removeBodyCss();
  }

  function closeEditor() {
    // console.log('closeEditor')
    setIsOpenEditor(false);
    // editorModalRef.current.clearEditorContent();
    document.body.classList.remove("modal-open");
    document.body.classList.remove("no_padding");
    document.body.style.overflow = 'visible';
    document.body.style.paddingRight = '';
  }

  function removeBodyCss() {
    document.body.classList.add("no_padding");
    document.body.classList.add("modal-open");
    document.body.style.overflow = 'hidden';
  }
  
  const handleCloseEmailSettingModal = (e) => {
    if (e.target.id === 'EditorModal' && (!editorModal.current || !editorModal.current.contains(e.target))) {
      closeEditor()
    }
  }
  // [E] Editor

  const createBillingProfile = () => {
    billingProfileModalRef.current.createBillingProfile();
  }

  const editBillingProfile = (userAccountInfo) => {
    billingProfileModalRef.current.editBillingProfile(userAccountInfo);
  }

  const removeBillingProfile = (index) => {
    dispatch(onRemoveAdmAccountManagementBillingProfile(index));
  }

  const previewInvoice = () => {
    setIsOpenPreview(true);
  }

  const createAdminAccount = (emailForm) => {
    // console.log("createAdminAccount emailForm:" + emailForm);

    closeEditor();

    filterValidation.setFieldValue("emailForm", emailForm);
    filterValidation.setFieldValue("submitType", "createAdminAccount");

    filterValidation.handleSubmit(filterValidation.values);
  }

  const changeItemList = (index, item) => {
    const _itemList = cloneDeep(filterValidation.values.itemList);
    _itemList[index] = item;

    changeFinalCost('finalDcAmt', filterValidation.values.accountInfo.finalDcAmt, _itemList);
  }

  const changeTypeOrTerm = (_itemList, index) => {
    if(_itemList[index].term === 'annually'){
      _itemList[index].cost = _itemList[index].m1*12;
      _itemList[index].dcDesc = '12 months discount'
      _itemList[index].dcAmt = _itemList[index].cost - _itemList[index].m12;
      _itemList[index].dcRate = 30;
      _itemList[index].standardFee = _itemList[index].m12;
    } else if(_itemList[index].term === '6months'){
      _itemList[index].cost = _itemList[index].m1*6;
      _itemList[index].dcDesc = '6 months discount'
      _itemList[index].dcAmt = _itemList[index].cost - _itemList[index].m6;
      _itemList[index].dcRate = 15;
      _itemList[index].standardFee = _itemList[index].m6;
    } else if(_itemList[index].term === '3months'){
      _itemList[index].cost = _itemList[index].m1*3;
      _itemList[index].dcDesc = '3 months discount'
      _itemList[index].dcAmt = _itemList[index].cost - _itemList[index].m3;
      _itemList[index].dcRate = 7.5;
      _itemList[index].standardFee = _itemList[index].m3;    
    } else if(_itemList[index].term === 'monthly'){
      _itemList[index].cost = _itemList[index].m1;
      _itemList[index].dcDesc = ''
      _itemList[index].dcAmt = 0;
      _itemList[index].dcRate = ''
      _itemList[index].standardFee = _itemList[index].m1;
    }

    //Trial은 100% dc로 처리
    if(_itemList[index].type === 'Trial' || _itemList[index].type === 'WeCrest'){
      _itemList[index].addDcAmt = _itemList[index].standardFee;
      _itemList[index].addDcRate = 100;
    }else {
      _itemList[index].addDcAmt = 0
      _itemList[index].addDcRate = 0;
    }

    _itemList[index].endDate = calcEndDate(_itemList[index].startDate, _itemList[index].type, _itemList[index].term);
    _itemList[index].subtotal = Number(_itemList[index].standardFee) - Number(_itemList[index].addDcAmt);
  }

  const changeType = (index, fieldValue) => {
    const _itemList = cloneDeep(filterValidation.values.itemList);
    _itemList[index].type = fieldValue;

    // EmailCode 셋팅, Member : 01R, Trial : 01T
    let isMember = false;
    _itemList.map((_item) => {
      if (_item.type === 'Member') {
        isMember = true;

        return false;
      }
    })

    if (isMember) {
      setEmailCode('01R');
    } else {
      setEmailCode('01T');
    }

    changeTypeOrTerm(_itemList, index);

    changeFinalCost('finalDcAmt', filterValidation.values.accountInfo.finalDcAmt, _itemList);
  }

  const changeTerm = (index, fieldValue) => {
    const _itemList = cloneDeep(filterValidation.values.itemList);
    _itemList[index].term = fieldValue;
        
    changeTypeOrTerm(_itemList, index);

    changeFinalCost('finalDcAmt', filterValidation.values.accountInfo.finalDcAmt, _itemList);
  }

  const changeCost = (index, fieldName, fieldValue) => {
    const _itemList = cloneDeep(filterValidation.values.itemList);

    if (fieldName === 'cost') {
      _itemList[index].cost = fieldValue ? fieldValue : 0;
      _itemList[index].dcAmt = _itemList[index].dcAmt ? _itemList[index].dcAmt : 0;
      _itemList[index].dcRate = Math.round((Number(_itemList[index].dcAmt) / Number(_itemList[index].cost)) * 100);
      _itemList[index].standardFee = Number(_itemList[index].cost) - Number(_itemList[index].dcAmt);

      _itemList[index].addDcAmt = _itemList[index].addDcAmt ? _itemList[index].addDcAmt : 0;
      _itemList[index].addDcRate = Math.round((Number(_itemList[index].addDcAmt) / Number(_itemList[index].standardFee)) * 100);
      _itemList[index].subtotal = Number(_itemList[index].standardFee) - Number(_itemList[index].addDcAmt);
    } else if (fieldName === 'dcAmt') {
      _itemList[index].dcAmt = fieldValue ? fieldValue : 0;
      _itemList[index].dcRate = Math.round((Number(_itemList[index].dcAmt) / Number(_itemList[index].cost)) * 100);
      _itemList[index].standardFee = Number(_itemList[index].cost) - Number(_itemList[index].dcAmt);

      _itemList[index].addDcAmt = _itemList[index].addDcAmt ? _itemList[index].addDcAmt : 0;
      _itemList[index].addDcRate = Math.round((Number(_itemList[index].addDcAmt) / Number(_itemList[index].standardFee)) * 100);
      _itemList[index].subtotal = Number(_itemList[index].standardFee) - Number(_itemList[index].addDcAmt);
    } else if (fieldName === 'dcRate') {
      _itemList[index].dcRate = fieldValue ? fieldValue : 0;
      _itemList[index].cost = _itemList[index].cost ? _itemList[index].cost : 0;
      _itemList[index].dcAmt = Math.round(Number(_itemList[index].cost) * Number(_itemList[index].dcRate) / 100);
      _itemList[index].standardFee = Number(_itemList[index].cost) - Number(_itemList[index].dcAmt);

      _itemList[index].addDcAmt = _itemList[index].addDcAmt ? _itemList[index].addDcAmt : 0;
      _itemList[index].addDcRate = Math.round((Number(_itemList[index].addDcAmt) / Number(_itemList[index].standardFee)) * 100);
      _itemList[index].subtotal = Number(_itemList[index].standardFee) - Number(_itemList[index].addDcAmt);
    } else if (fieldName === 'standardFee') {
      _itemList[index].standardFee = fieldValue ? fieldValue : 0;
      _itemList[index].cost = _itemList[index].cost ? _itemList[index].cost : 0;
      _itemList[index].dcAmt = Number(_itemList[index].cost) - Number(_itemList[index].standardFee);
      _itemList[index].dcRate = Math.round((Number(_itemList[index].dcAmt) / Number(_itemList[index].cost)) * 100);

      _itemList[index].addDcAmt = _itemList[index].addDcAmt ? _itemList[index].addDcAmt : 0;
      _itemList[index].addDcRate = Math.round((Number(_itemList[index].addDcAmt) / Number(_itemList[index].standardFee)) * 100);
      _itemList[index].subtotal = Number(_itemList[index].standardFee) - Number(_itemList[index].addDcAmt);
    } else if (fieldName === 'addDcAmt') {
      _itemList[index].addDcAmt = fieldValue ? fieldValue : 0;
      _itemList[index].addDcRate = Math.round((Number(_itemList[index].addDcAmt) / Number(_itemList[index].standardFee)) * 100);
      _itemList[index].subtotal = Number(_itemList[index].standardFee) - Number(_itemList[index].addDcAmt);
    } else if (fieldName === 'addDcRate') {
      _itemList[index].addDcRate = fieldValue ? fieldValue : 0;
      _itemList[index].addDcAmt = Math.round(Number(_itemList[index].standardFee) * Number(_itemList[index].addDcRate) / 100);
      _itemList[index].subtotal = Number(_itemList[index].standardFee) - Number(_itemList[index].addDcAmt);
    } else if (fieldName === 'subtotal') {
      _itemList[index].subtotal = fieldValue ? fieldValue : 0;

      _itemList[index].addDcAmt = Number(_itemList[index].standardFee) - Number(_itemList[index].subtotal);
      _itemList[index].addDcRate = Math.round((Number(_itemList[index].addDcAmt) / Number(_itemList[index].standardFee)) * 100);
    }

    // console.log("changeCost itemList[" + index + "] : " + JSON.stringify(_itemList[index]));

    changeFinalCost('finalDcAmt', filterValidation.values.accountInfo.finalDcAmt, _itemList);
  }

  const changeStartDate = (index, fieldValue) => {
    const _itemList = cloneDeep(filterValidation.values.itemList);
    _itemList[index].startDate = fieldValue;

    if (fieldValue !== '') {
      _itemList[index].endDate = calcEndDate(fieldValue, _itemList[index].type, _itemList[index].term);
      filterValidation.setFieldValue("itemList", _itemList);
    } 
  }

  const calcEndDate = (_startDate, _type, _term) => {
    let yyyy = _startDate.substr(0,4);
    let mm = _startDate.substr(5,2);
    let dd = _startDate.substr(8,2);                        
    
    let _endDate = new Date(yyyy, mm-1, dd);

    // Trial은 무조건 2주
    if(_type === 'Trial'){
      _endDate.setDate(_endDate.getDate() + 13);
      
      return _endDate.getFullYear() 
              + '-' + (_endDate.getMonth() + 1 < 10 ? '0' + (_endDate.getMonth() + 1) : _endDate.getMonth() + 1) 
              + '-' + (_endDate.getDate() < 10 ? '0' + _endDate.getDate() : _endDate.getDate());
    } else {
      if (_term === 'annually') {
        _endDate.setFullYear(_endDate.getFullYear() + 1);
      } else if (_term === '6months') {
        _endDate.setMonth(_endDate.getMonth() + 6);
      } else if (_term === '3months') {
        _endDate.setMonth(_endDate.getMonth() + 3);
      } else {
        _endDate.setMonth(_endDate.getMonth() + 1);
      }

      _endDate.setDate(_endDate.getDate() - 1);

      return _endDate.getFullYear() 
              + '-' + (_endDate.getMonth() + 1 < 10 ? '0' + (_endDate.getMonth() + 1) : _endDate.getMonth() + 1) 
              + '-' + (_endDate.getDate() < 10 ? '0' + _endDate.getDate() : _endDate.getDate());
    }
  } 

  const changeFinalCost = (fieldName, fieldValue, _itemList) => {
    // console.log('changeFinalCost[' + fieldName + '] : ' + fieldValue);

    let sumOfSubtotal = 0;
    _itemList.map((item, index) => {
      sumOfSubtotal += Number(item.subtotal);
    })

    const _accountInfo = cloneDeep(filterValidation.values.accountInfo);

    // console.log('sumOfSubtotal : ' + sumOfSubtotal);
  
    if (fieldName === 'finalDcAmt') {
      if(sumOfSubtotal === 0) {
        _accountInfo.finalDcDesc = '';
        _accountInfo.finalDcAmt = 0;
        _accountInfo.finalDcRate = 0;
      } else {
        _accountInfo.finalDcAmt = fieldValue ? fieldValue : 0;
        _accountInfo.finalDcRate = Math.round(Number(_accountInfo.finalDcAmt) / sumOfSubtotal * 100);
      }

      _accountInfo.addFee = _accountInfo.addFee ? _accountInfo.addFee : 0;
      _accountInfo.total = (sumOfSubtotal - Number(_accountInfo.finalDcAmt) + Number(_accountInfo.addFee)).toFixed(2);
      _accountInfo.balance = (_accountInfo.total - Number(_accountInfo.amountPaid)).toFixed(2);
    } else if (fieldName === 'finalDcRate') {
      if(sumOfSubtotal === 0) {
        _accountInfo.finalDcDesc = '';
        _accountInfo.finalDcAmt = 0;
        _accountInfo.finalDcRate = 0;
      } else {
        _accountInfo.finalDcRate = fieldValue ? fieldValue : 0;
        _accountInfo.finalDcAmt = Math.round(sumOfSubtotal * Number(_accountInfo.finalDcRate) / 100);
      }

      _accountInfo.addFee = _accountInfo.addFee ? _accountInfo.addFee : 0;
      _accountInfo.total = (sumOfSubtotal - Number(_accountInfo.finalDcAmt) + Number(_accountInfo.addFee)).toFixed(2);
      _accountInfo.balance = (_accountInfo.total - Number(_accountInfo.amountPaid)).toFixed(2);
    } else if (fieldName === 'addFee') {
      _accountInfo.addFee = fieldValue ? fieldValue : 0;
      _accountInfo.total = (sumOfSubtotal - Number(_accountInfo.finalDcAmt) + Number(_accountInfo.addFee)).toFixed(2);
    } else if (fieldName === 'total') {
      _accountInfo.total = fieldValue ? fieldValue : 0;

      _accountInfo.addFee = (_accountInfo.total - sumOfSubtotal + Number(_accountInfo.finalDcAmt)).toFixed(2);
    } else if (fieldName === 'amountPaid') {
      _accountInfo.amountPaid = fieldValue ? fieldValue : 0;

      _accountInfo.balance = (_accountInfo.total - Number(_accountInfo.amountPaid)).toFixed(2);
    } else if (fieldName === 'balance') {
      _accountInfo.balance = fieldValue ? fieldValue : 0;

      _accountInfo.amountPaid = (_accountInfo.total - Number(_accountInfo.balance)).toFixed(2);
    }

    // console.log('total : ' + _accountInfo.total);

    filterValidation.setFieldValue("itemList", _itemList);
    filterValidation.setFieldValue("accountInfo", _accountInfo);
  }

  const changeInvoiceDate = (invoiceDate) => {
    filterValidation.setFieldValue("accountInfo.invoiceDate", invoiceDate);

    let yyyy = invoiceDate.substring(0,4);
    let mm = invoiceDate.substring(5,7);
    let dd = invoiceDate.substring(8,10);         
    
    // console.log(yyyy + ' ' + mm + ' ' + dd);
    
    let invoiceDeadline = new Date(yyyy, mm-1, dd);
    invoiceDeadline.setDate(invoiceDeadline.getDate() + 7);

    filterValidation.setFieldValue("accountInfo.deadline", 
                                  invoiceDeadline.getFullYear() 
                                  + '-' + (invoiceDeadline.getMonth() + 1 < 10 ? '0' + (invoiceDeadline.getMonth() + 1) : invoiceDeadline.getMonth() + 1) 
                                  + '-' + (invoiceDeadline.getDate() < 10 ? '0' + invoiceDeadline.getDate() : invoiceDeadline.getDate())
                                                        );
  }

  const addInvoiceItem = () => {
    let _itemList = cloneDeep(filterValidation.values.itemList);
    _itemList.push({
      gradeStr:'Gold'
      , type:'Member'
      , cresterInChargeNo : cresterList && cresterList.length > 0 ? cresterList[0].userNo : ''
      , inOutType:'IN'
      , crCode:''
      , term:'annually'
      , startDate:''
      , endDate:''
      , cost:''
      , dcDesc:''
      , dcAmt:''
      , standardFee:''
      , addDcDesc:''
      , addDcAmt:''
      , subtotal:''
    })

    filterValidation.setFieldValue("itemList", _itemList);
  } 

  const removeInvoiceItem = (index) => {
    let _itemList = cloneDeep(filterValidation.values.itemList);
    _itemList.splice(index, 1); 
    filterValidation.setFieldValue("itemList", _itemList);
    changeFinalCost('finalDcAmt', filterValidation.values.accountInfo.finalDcAmt, _itemList);
  }

  const validateForm= (values, props /* only available when using withFormik */) => {
    const errors = {};
    let regex = /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*\.[a-zA-Z]{2,15}$/i;

    /*
    if (!values.accountInfo.invoiceName) {
      errors.invoiceName = 'Please enter your invoicing name';
    }
    */

    if (Number(values.accountInfo.userAccountInfoSeq) === -1 && !values.accountInfo.invoiceTitle) {
      errors.invoiceTitle = 'Please enter your invoicing info title';
    }

    /*
    if (Number(values.accountInfo.userAccountInfoSeq) === -1 && !values.accountInfo.invoiceFirstName) {
      errors.invoiceFirstName = 'Please enter your invoicing first name';
    }

    if (Number(values.accountInfo.userAccountInfoSeq) === -1 && !values.accountInfo.invoiceLastName) {
      errors.invoiceLastName = 'Please enter your invoicing last name';
    }
    */

    if (!values.accountInfo.invoiceCompany) {
      errors.invoiceCompany = 'Please enter your invoicing company name';
    } 
    
    if (!values.accountInfo.invoiceAddress) {
      errors.invoiceAddress = 'Please enter your invoicing address';
    }

    if (!values.accountInfo.invoiceEmail) {
      errors.invoiceEmail = 'Please enter your accounting email';
    } else {
      let emailAddressList = values.accountInfo.invoiceEmail.split(",");
      for (var i=0; i<emailAddressList.length; i++) { 
        // console.log(emailAddressList[i] + " : " + regex.test(emailAddressList[i].trim()));

        if (!regex.test(emailAddressList[i].trim())) {
          errors.invoiceEmail = 'Please enter a valid accounting email';
        }
      }
    }

    if (values.accountInfo.invoiceStatus === 'Paid' && (values.accountInfo.dateOfPayment == '' || !values.accountInfo.dateOfPayment)) {
      errors.dateOfPayment = 'Please enter a date of payment';
    }

    if (values.accountInfo.paymentMethod === 'Paypal' && values.accountInfo.paymentType === 'Subscription' && !values.accountInfo.paypalPlanID) {
      errors.paypalPlanID = 'Please enter your Paypal Plan ID';
    }

    if (values.accountInfo.paymentMethod === 'Paypal' && values.accountInfo.paymentType === 'Onetime' && !values.accountInfo.paypalOnetimeLink) {
      errors.paypalOnetimeLink = 'Please enter your Paypal Onetime link';
    }

    return errors;
  };

  const filterValidation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      submitType: "",
      accountInfo: accountInfo,
      crCdList: [],
      itemList: admAccountManagementItemList,
      emailForm: "",
    },
    
    onSubmit: (values, {setErrors}) => {
      let submitType = values["submitType"];

      if(submitType === "createAdminAccount"){
        let sigError = validateForm(values);
        if (JSON.stringify(sigError) !== '{}') {
          //console.log("sigError", sigError);
          setErrors(sigError);

          if (JSON.stringify(sigError).indexOf('invoiceName') > -1) {
            document.getElementById("invoiceName").focus();
          } else if (JSON.stringify(sigError).indexOf('invoiceTitle') > -1) {
            document.getElementById("invoiceTitle").focus();
          } else if (JSON.stringify(sigError).indexOf('invoiceFirstName') > -1) {
            document.getElementById("invoiceFirstName").focus();
          } else if (JSON.stringify(sigError).indexOf('invoiceLastName') > -1) {
            document.getElementById("invoiceLastName").focus();
          } else if (JSON.stringify(sigError).indexOf('invoiceCompany') > -1) {
            document.getElementById("invoiceCompany").focus();
          } else if (JSON.stringify(sigError).indexOf('invoiceAddress') > -1) {
            document.getElementById("invoiceAddress").focus();
          } else if (JSON.stringify(sigError).indexOf('invoiceEmail') > -1) {
            document.getElementById("invoiceEmail").focus();
          } else if (JSON.stringify(sigError).indexOf('paypalPlanID') > -1) {
            document.getElementById("paypalPlanID").focus();
          } else if (JSON.stringify(sigError).indexOf('paypalOnetimeLink') > -1) {
            document.getElementById("paypalOnetimeLink").focus();
          }
        } else {
          if (values.accountInfo.userAccountInfoSeq === -1) {
            let isDuplicateTitle = false;
            createBillingProfileList.map((userAccountInfo, index) => {
              if (userAccountInfo.invoiceTitle === values.accountInfo.invoiceTitle) {
                isDuplicateTitle = true;
                return false;
              }
            })

            if (isDuplicateTitle) {
              setErrorMsg(<>The following invoice titles are duplicates<br/>[{values.accountInfo.invoiceTitle}]</>);
              document.getElementById("invoiceTitle").focus();
              return false;
            }  
          }
         
          const formData = new FormData();
          formData.append('pricingType', 'NEW');
          formData.append('emailCode', emailCode);

          Object.keys(values["accountInfo"]).forEach(fieldName => {
            if (fieldName === 'userName'
              || fieldName === 'password'
              || fieldName === 'mainEmail'
              || fieldName === 'invoiceFirstName'
              || fieldName === 'invoiceLastName'
              || fieldName === 'invoiceName' 
              || fieldName === 'invoiceCompany' 
              || fieldName === 'invoiceAddress' 
              || fieldName === 'invoiceEmail' 
              || fieldName === 'invoiceTaxId' 
              || fieldName === 'accountInfo' 
              || fieldName === 'invoiceDate' 
              || fieldName === 'deadline' 
              || fieldName === 'invoiceMemo' 
              || fieldName === 'currency' 
              || fieldName === 'finalDcDesc' 
              || fieldName === 'finalDcAmt' 
              || fieldName === 'finalDcRate' 
              || fieldName === 'addFeeDesc' 
              || fieldName === 'addFee' 
              || fieldName === 'total' 
              || fieldName === 'amountPaid' 
              || fieldName === 'dateOfPayment' 
              || fieldName === 'balance' 
              || fieldName === 'invoiceStatus' 
              || fieldName === 'paymentMethod' 
              || fieldName === 'paymentType' 
              || fieldName === 'paypalPlanID' 
              || fieldName === 'paypalSubscriptionID' 
              || fieldName === 'paypalOnetimeLink'
              || fieldName === 'sendEmailFlag' 
              || fieldName === 'downloadInvoiceFlag' 
              || fieldName === 'sendInvoiceFlag' 
              || fieldName === 'overwriteBillingProfile'
              ) {
              // console.log(fieldName, values["accountInfo"][fieldName]);
              formData.append(fieldName, values["accountInfo"][fieldName]);
            }
          })

          let dontHaveItemArray = [];
          // let itemId = "";
          Object.keys(values["itemList"]).forEach(itemIndex => {
            let haveItem = false
            Object.keys(values["itemList"][itemIndex]).forEach(fieldName => {
              if (fieldName === 'crCode' && values["itemList"][itemIndex][fieldName] !== '') haveItem = true;
              // console.log("itemList[" + itemIndex + "]." + fieldName, values["itemList"][itemIndex][fieldName]);
              formData.append("itemList[" + itemIndex + "]." + fieldName, values["itemList"][itemIndex][fieldName]);
            })

            if (!haveItem) {
              dontHaveItemArray.push("Item " + (Number(itemIndex)+1));
              // if (itemId === "") {
              //   itemId = "itemName_" + (Number(itemIndex)+1)
              // }
            }
          })

          if (dontHaveItemArray.length > 0) {
            // console.log("itemId : " + itemId);
            // document.getElementById(itemId).focus();
            setErrorMsg(<>Please select following item:<br/>{JSON.stringify(dontHaveItemArray)}.</>);
            return;
          }

          formData.append('subject', emailSubject);
          if (emailCcItems) {
              formData.append('emailCC', emailCcItems.join(", "));
          }
          if (emailBccItems) {
              formData.append('emailBCC', emailBccItems.join(", "));
          }

          // console.log("emailForm : " + values["emailForm"]);
          formData.append('emailForm', values["emailForm"]);

          attachFiles.forEach((element, index) => {
              for (const [key, value] of Object.entries(element)) {
                  if (value && value !== 'null') {
                      formData.append('attachFiles['+index+'].'+key, value);
                  }
              }              
          });

          copyFiles.forEach((element, index) => {
              for (const [key, value] of Object.entries(element)) {
                  if (value && value !== 'null') {
                      formData.append('copyFiles['+index+'].'+key, value);
                  }
              }    
          });
          
          upFiles.forEach((element, index) => {
              formData.append('upFiles['+index+']', element);
          });

          dispatch(onCreateAdminAccount(formData));
        }
      }
    },
  });

  useEffect(() => {
    dispatch(onGetCresterList());
    addInvoiceItem();

    window.addEventListener('click', handleCloseEmailSettingModal);
    return () => {
      window.removeEventListener('click', handleCloseEmailSettingModal);
    }
  },[])

  return (
    <React.Fragment>
      <Row>
        <Col lg={12}>
          <Form 
            onSubmit={(e) => {
              e.preventDefault();
              filterValidation.handleSubmit();
              return false;
            }}
          >
            <Row>
              <Card>
                <CardBody>
                  <Row className="mb-3">
                    <label className="col-md-3 col-form-label">
                    Username*
                    </label>
                    <div className="col-md-4">
                    <input
                        className="form-control"
                        type="text"
                        placeholder="Username"
                        name="username"
                        value={filterValidation.values.accountInfo ? filterValidation.values.accountInfo.userName : ''}
                    />
                    </div>
                  </Row>

                  <Row className="mb-3">
                    <label className="col-md-3 col-form-label">
                    Password*
                    </label>
                    <div className="col-md-4">
                    <input
                        className="form-control"
                        type="password"
                        placeholder="Password"
                        name="password"
                        value={filterValidation.values.accountInfo ? filterValidation.values.accountInfo.password : ''}
                    />
                    </div>
                  </Row>

                  <Row className="mb-3">
                    <label className="col-md-3 col-form-label">
                    Main email*
                    </label>
                    <div className="col-md-4">
                    <input
                        className="form-control"
                        type="text"
                        placeholder="Main email"
                        name="mainEmail"
                        value={filterValidation.values.accountInfo ? filterValidation.values.accountInfo.mainEmail : ''}
                    />
                    </div>
                  </Row>

                  <Row className="mb-3">
                    <label className="col-md-3 col-form-label">
                    First name*
                    </label>
                    <div className="col-md-4">
                    <input
                        className="form-control"
                        type="text"
                        placeholder="First name"
                        name="firstName"
                        value={filterValidation.values.accountInfo ? filterValidation.values.accountInfo.firstName : ''}
                    />
                    </div>
                  </Row>

                  <Row className="mb-3">
                    <label className="col-md-3 col-form-label">
                    Last name*
                    </label>
                    <div className="col-md-4">
                    <input
                        className="form-control"
                        type="text"
                        placeholder="Last name"
                        name="lastName"
                        value={filterValidation.values.accountInfo ? filterValidation.values.accountInfo.lastName : ''}
                    />
                    </div>
                  </Row>

                  <Row className="mb-3">
                    <label className="col-md-3 col-form-label">
                    Organization*
                    </label>
                    <div className="col-md-4">
                    <input
                        className="form-control"
                        type="text"
                        placeholder="Organization"
                        name="companyName"
                        value={filterValidation.values.accountInfo ? filterValidation.values.accountInfo.companyName : ''}
                    />
                    </div>
                  </Row>

                  <Row className="mb-3">
                    <label className="col-md-3 col-form-label">
                    Position
                    </label>
                    <div className="col-md-4">
                    <input
                        className="form-control"
                        type="text"
                        placeholder="Position"
                        name="position"
                        value={filterValidation.values.accountInfo ? filterValidation.values.accountInfo.position : ''}
                    />
                    </div>
                  </Row>

                  <Row className="mb-3">
                    <label className="col-md-3 col-form-label">
                    Main address
                    </label>
                    <div className="col-md-4">
                    <input
                        className="form-control"
                        type="text"
                        placeholder="Main address"
                        name="addressLine1"
                        value={filterValidation.values.accountInfo ? filterValidation.values.accountInfo.addressLine1 : ''}
                    />
                    </div>
                  </Row>

                  <SelectHomeCountry
                    formik={filterValidation}
                    componentName='RadioCountry'
                    firstColMd={3}
                  />

                  <Row className="mb-3">
                    <label className="col-md-3 col-form-label">
                    Website
                    </label>
                    <div className="col-md-4">
                    <input
                        className="form-control"
                        type="text"
                        placeholder="Website"
                        name="website"
                        value={filterValidation.values.accountInfo ? filterValidation.values.accountInfo.website : ''}
                    />
                    </div>
                  </Row>

                  <Row className="mb-3 align-items-center">
                    <label htmlFor="status" className="col-md-3 col-form-label">
                    Status
                    </label>
                    <div className="col-md-4 d-flex" style={{justifyContent: "space-between"}}>
                        <div className="col-md-6 form-check" style={{paddingLeft : '2em'}}>
                          <input
                            className="form-check-input"
                            type="radio"
                            name="statusStr"
                            id="status_Confirmed"
                            onChange={filterValidation.handleChange}
                            value="Confirmed"
                            checked={filterValidation.values.statusStr === 'Confirmed'}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="status_Confirmed"
                          >
                            Confirmed
                          </label>
                        </div>
                        <div className="col-md-6 form-check" style={{paddingLeft : '2em'}}>
                          <input
                            className="form-check-input"
                            type="radio"
                            name="statusStr"
                            id="status_Waiting"
                            onChange={filterValidation.handleChange}
                            value="Waiting"
                            checked={filterValidation.values.statusStr === 'Waiting'}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="status_Waiting"
                          >
                            Waiting
                          </label>
                        </div>
                    </div>
                  </Row>

                  <Row className="mb-3">
                    <label className="col-md-3 col-form-label">
                    Billing profile title *
                    </label>
                    <div className="col-md-4">
                      <Input
                        className="form-control"
                        type="text"
                        placeholder="Billing info title"
                        name="accountInfo.invoiceTitle"
                        id="invoiceTitle"
                        onChange={filterValidation.handleChange}
                        value={filterValidation.values.accountInfo ? filterValidation.values.accountInfo.invoiceTitle : ''}
                        invalid={
                          filterValidation.errors.invoiceTitle ? true : false
                        }
                      />
                      {filterValidation.errors.invoiceTitle ? (
                        <FormFeedback type="invalid">{filterValidation.errors.invoiceTitle}</FormFeedback>
                      ) : null}
                    </div>
                  </Row>
                  
                  <Row className="mb-3">
                    <label className="col-md-3 col-form-label">
                    Invoicing first name
                    </label>
                    <div className="col-md-4">
                      <Input
                        className="form-control"
                        type="text"
                        placeholder="Invoicing first name"
                        name="accountInfo.invoiceFirstName"
                        id="invoiceFirstName"
                        onChange={filterValidation.handleChange}
                        value={filterValidation.values.accountInfo ? filterValidation.values.accountInfo.invoiceFirstName : ''}
                        invalid={
                          filterValidation.errors.invoiceFirstName ? true : false
                        }
                      />
                      {filterValidation.errors.invoiceFirstName ? (
                        <FormFeedback type="invalid">{filterValidation.errors.invoiceFirstName}</FormFeedback>
                      ) : null}
                    </div>
                  </Row>
                  <Row className="mb-3">
                    <label className="col-md-3 col-form-label">
                    Invoicing last name
                    </label>
                    <div className="col-md-4">
                      <Input
                        className="form-control"
                        type="text"
                        placeholder="Invoicing last name"
                        name="accountInfo.invoiceLastName"
                        id="invoiceLastName"
                        onChange={filterValidation.handleChange}
                        value={filterValidation.values.accountInfo ? filterValidation.values.accountInfo.invoiceLastName : ''}
                        invalid={
                          filterValidation.errors.invoiceLastName ? true : false
                        }
                      />
                      {filterValidation.errors.invoiceLastName ? (
                        <FormFeedback type="invalid">{filterValidation.errors.invoiceLastName}</FormFeedback>
                      ) : null}
                    </div>
                  </Row>
                    
                  <Row className="mb-3">
                    <label className="col-md-3 col-form-label">
                      Invoicing company name *
                    </label>
                    <div className="col-md-4">
                      <Input
                        className="form-control"
                        type="text"
                        placeholder="Invoicing company name"
                        name="accountInfo.invoiceCompany"
                        id="invoiceCompany"
                        onChange={filterValidation.handleChange}
                        value={filterValidation.values.accountInfo ? filterValidation.values.accountInfo.invoiceCompany : ''}
                        invalid={
                          filterValidation.errors.invoiceCompany ? true : false
                        }
                      />
                      {filterValidation.errors.invoiceCompany ? (
                        <FormFeedback type="invalid">{filterValidation.errors.invoiceCompany}</FormFeedback>
                      ) : null}
                    </div>
                  </Row>
                  <Row className="mb-3">
                    <label className="col-md-3 col-form-label">
                      Invoicing address *
                    </label>
                    <div className="col-md-4">
                      <textarea
                        className={filterValidation.errors.invoiceAddress ? "form-control is-invalid" : "form-control"}
                        rows="3"
                        placeholder="Invoicing address..."
                        name="accountInfo.invoiceAddress"
                        id="invoiceAddress"
                        onChange={filterValidation.handleChange}
                        value={filterValidation.values.accountInfo ? filterValidation.values.accountInfo.invoiceAddress : ''}
                      />
                      {filterValidation.errors.invoiceAddress ? (
                        <FormFeedback type="invalid">{filterValidation.errors.invoiceAddress}</FormFeedback>
                      ) : null}
                    </div>
                  </Row>
                  <Row className="mb-3">
                    <label className="col-md-3 col-form-label">
                      Accounting email *
                    </label>
                    <div className="col-md-4">
                      <Input
                        type="email"
                        placeholder="Accounting email"
                        className="form-control"
                        name="accountInfo.invoiceEmail"
                        id="invoiceEmail"
                        onChange={filterValidation.handleChange}
                        value={filterValidation.values.accountInfo ? filterValidation.values.accountInfo.invoiceEmail : ''}
                        invalid={filterValidation.errors.invoiceEmail ? true : false}
                      />
                      {filterValidation.errors.invoiceEmail ? (
                        <FormFeedback type="invalid">{filterValidation.errors.invoiceEmail}</FormFeedback>
                      ) : null}
                    </div>
                  </Row>
                  <Row className="mb-3">
                    <label className="col-md-3 col-form-label">
                      Invoicing name
                    </label>
                    <div className="col-md-4">
                      <Input
                        className="form-control"
                        type="text"
                        placeholder="Invoicing name"
                        name="accountInfo.invoiceName"
                        id="invoiceName"
                        onChange={filterValidation.handleChange}
                        value={filterValidation.values.accountInfo ? filterValidation.values.accountInfo.invoiceName : ''}
                        invalid={
                          filterValidation.errors.invoiceName ? true : false
                        }
                      />
                      {filterValidation.errors.invoiceName ? (
                          <FormFeedback type="invalid">{filterValidation.errors.invoiceName}</FormFeedback>
                        ) : null}
                    </div>
                  </Row>
                  <Row className="mb-3">
                    <label className="col-md-3 col-form-label">
                      Tax ID
                    </label>
                    <div className="col-md-4">
                      <input
                        className="form-control"
                        type="text"
                        placeholder="Tax ID"
                        name="accountInfo.invoiceTaxId"
                        onChange={filterValidation.handleChange}
                        value={filterValidation.values.accountInfo ? filterValidation.values.accountInfo.invoiceTaxId : ''}
                      />
                    </div>
                  </Row>
                  <Row className="mb-3">
                    <label className="col-md-3 col-form-label">
                      Important info
                    </label>
                    <div className="col-md-4">
                      <textarea
                        className="form-control"
                        rows="3"
                        placeholder="Important info..."
                        name="accountInfo.accountInfo"
                        onChange={filterValidation.handleChange}
                        value={filterValidation.values.accountInfo ? filterValidation.values.accountInfo.invoiceInfo : ''}
                      />
                    </div>
                  </Row>

                  <Row className="mb-3">
                    <label
                    htmlFor="invoiceDate"
                    className="col-md-3 col-form-label"
                    >
                    Invoice date
                    </label>
                    <div className="col-md-4">
                    <Input
                        className="form-control"
                        type="date"
                        id="invoiceDate"
                        name="accountInfo.invoiceDate"
                        onChange={(e) => {
                          changeInvoiceDate(e.target.value)
                        }}
                        value={filterValidation.values.accountInfo ? filterValidation.values.accountInfo.invoiceDate : ''}
                        onPaste={(e) => {
                            try {
                                let checkDate = String(e.clipboardData.getData('Text'));
                                if (checkDate.indexOf('-') > -1 && checkDate.length === 10) {
                                    filterValidation.setFieldValue("accountInfo.invoiceDate", checkDate);
                                } else if (checkDate.length === 8) {
                                    filterValidation.setFieldValue("accountInfo.invoiceDate", checkDate.substring(0,4) + '-' + checkDate.substring(4,6) + '-' + checkDate.substring(6,8));
                                }
                            } catch (error) {
                                console.log("error :: " + error);
                            }
                        }}
                        />
                    </div>
                  </Row>
                  <Row className="mb-3">
                    <label
                    htmlFor="deadline"
                    className="col-md-3 col-form-label"
                    >
                    Invoice deadline
                    </label>
                    <div className="col-md-4">
                    <Input
                        className="form-control"
                        type="date"
                        id="deadline"
                        name="accountInfo.deadline"
                        onChange={(e) => {
                            filterValidation.setFieldValue("accountInfo.deadline", e.target.value);
                        }}
                        value={filterValidation.values.accountInfo ? filterValidation.values.accountInfo.deadline : ''}
                        onPaste={(e) => {
                            try {
                                let checkDate = String(e.clipboardData.getData('Text'));
                                if (checkDate.indexOf('-') > -1 && checkDate.length === 10) {
                                    filterValidation.setFieldValue("accountInfo.deadline", checkDate);
                                } else if (checkDate.length === 8) {
                                    filterValidation.setFieldValue("accountInfo.deadline", checkDate.substring(0,4) + '-' + checkDate.substring(4,6) + '-' + checkDate.substring(6,8));
                                }
                            } catch (error) {
                                console.log("error :: " + error);
                            }
                        }}
                        />
                    </div>
                  </Row>
                  <Row className="mb-3">
                    <label className="col-md-3 col-form-label">
                    Invoice memo
                    </label>
                    <div className="col-md-4">
                      <textarea
                        className="form-control"
                        rows="3"
                        placeholder=""
                        name="accountInfo.invoiceMemo"
                        onChange={filterValidation.handleChange}
                        value={filterValidation.values.accountInfo ? filterValidation.values.accountInfo.invoiceMemo : ''}
                      />
                    </div>
                  </Row>
                  <Row>
                    <label className="col-md-3 col-form-label">
                    Currency
                    </label>
                    <div className="col-md-4">
                      <select 
                        className="form-select" 
                        name="accountInfo.currency"
                        onChange={filterValidation.handleChange}
                        value={filterValidation.values.accountInfo ? filterValidation.values.accountInfo.currency : 'USD'}
                      >
                        <option value="USD">USD</option>
                        <option value="KRW">KRW</option>
                      </select>
                    </div>
                  </Row>
                </CardBody>
              </Card>
              {
                filterValidation.values.itemList ?
                filterValidation.values.itemList.map((item, index) => {
                  return (
                    <Card>
                      <CardBody>
                        <Row className="mb-3">
                          <label className="col-md-3 col-form-label">
                          Item {index+1}
                          </label>
                          {
                          !props.invoiceNo && index > 0 ?
                          <Link className="col-md-4" to="#" onClick={() => {
                            removeInvoiceItem(index)
                            }}
                          >
                            <i className="uil uil-times font-size-22 text-danger"></i>
                          </Link>
                          : null
                          }
                        </Row>
                        <Row className="mb-3">
                          <label className="col-md-3 col-form-label">
                          Crester in charge
                          </label>
                          <div className="col-md-4">
                            <select 
                              className="form-select" 
                              name={"itemList[" + index + "].cresterInChargeNo"}
                              onChange={filterValidation.handleChange}
                              value={item.cresterInChargeNo && item.cresterInChargeNo !== '' ? item.cresterInChargeNo : cresterList && cresterList.length > 0 ? cresterList[0].userNo : 1}
                            >
                              {
                                cresterList && cresterList.length > 0 ?
                                cresterList.map((crester, index) => {
                                  return (
                                    <option value={crester.userNo}>{crester.userName}</option>
                                  )
                                })
                                : null
                              }
                            </select>
                          </div>
                        </Row>
                        <Row className="mb-3">
                          <label className="col-md-3 col-form-label">
                          Type
                          </label>
                          <Row className="col-md-9">
                            {props.invoiceNo ?
                            item.type
                            :
                            <Row className="vertical-align-center" style={{padding: "0.47rem 1.25rem"}}>
                              <div className="col-md-3 form-check">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name={"itemList[" + index + "].type"}
                                  id={"item" + index + "_Member"}
                                  onChange={(e) => {changeType(index, e.target.value)}}
                                  value="Member"
                                  checked={item.type === 'Member'}
                                />
                                <label
                                  className="form-check-label"
                                  onClick={() => {changeType(index, 'Member')}}
                                >
                                  Member
                                </label>
                              </div>
                              <div className="col-md-3 form-check">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name={"itemList[" + index + "].type"}
                                  id={"item" + index + "_Trial"}
                                  onChange={(e) => {changeType(index, e.target.value)}}
                                  value="Trial"
                                  checked={item.type === 'Trial'}
                                />
                                <label
                                  className="form-check-label"
                                  onClick={() => {changeType(index, 'Trial')}}
                                >
                                  Trial
                                </label>
                              </div>
                              <div className="col-md-3 form-check">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name={"itemList[" + index + "].type"}
                                  id={"item" + index + "_WeCrest"}
                                  onChange={(e) => {changeType(index, e.target.value)}}
                                  value="WeCrest"
                                  checked={item.type === 'WeCrest'}
                                />
                                <label
                                  className="form-check-label"
                                  onClick={() => {changeType(index, 'WeCrest')}}
                                >
                                  WeCrest
                                </label>
                              </div>
                            </Row>
                            }
                          </Row>
                        </Row>
                        {props.invoiceNo ?
                        <Row className="mb-3">
                          <label className="col-md-3 col-form-label">
                          Item Name
                          </label>
                          <div className="col-md-4">
                          {item.crCode}
                          </div>
                        </Row>
                        :
                        <SearchSubscriptionItem index={index} item={item} itemList={filterValidation.values.itemList} changeItemList={changeItemList}/>
                        }
                        <Row className="mb-3">
                          <label className="col-md-3 col-form-label">
                          Term
                          </label>
                          <Row className="col-md-9">
                            {props.invoiceNo ?
                            item.term
                            :
                            <Row className="vertical-align-center" style={{padding: "0.47rem 1.25rem"}}>
                              <div className="col-md-2 form-check">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name={"itemList[" + index + "].term"}
                                  id={"item" + index + "_annually"}
                                  onChange={(e) => {changeTerm(index, e.target.value)}}
                                  value="annually"
                                  checked={item.term === 'annually'}
                                />
                                <label
                                  className="form-check-label"
                                  onClick={() => {changeTerm(index, 'annually')}}
                                >
                                  annually
                                </label>
                              </div>
                              <div className="col-md-2 form-check">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name={"itemList[" + index + "].term"}
                                  id={"item" + index + "_6months"}
                                  onChange={(e) => {changeTerm(index, e.target.value)}}
                                  value="6months"
                                  checked={item.term === '6months'}
                                />
                                <label
                                  className="form-check-label"
                                  onClick={() => {changeTerm(index, '6months')}}
                                >
                                  6 months
                                </label>
                              </div>
                              <div className="col-md-2 form-check" style={{paddingLeft : '2em'}}>
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name={"itemList[" + index + "].term"}
                                  id={"item" + index + "_3months"}
                                  onChange={(e) => {changeTerm(index, e.target.value)}}
                                  value="3months"
                                  checked={item.term === '3months'}
                                />
                                <label
                                  className="form-check-label"
                                  onClick={() => {changeTerm(index, '3months')}}
                                >
                                  3 months
                                </label>
                              </div>
                              <div className="col-md-2 form-check" style={{paddingLeft : '2em'}}>
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name={"itemList[" + index + "].term"}
                                  id={"item" + index + "_monthly"}
                                  onChange={(e) => {changeTerm(index, e.target.value)}}
                                  value="monthly"
                                  checked={item.term === 'monthly'}
                                />
                                <label
                                  className="form-check-label"
                                  onClick={() => {changeTerm(index, 'monthly')}}
                                >
                                  monthly
                                </label>
                              </div>
                            </Row>
                            }
                          </Row>
                        </Row>
                        <Row className="mb-3">
                          <label
                          className="col-md-3 col-form-label"
                          >
                          Start date
                          </label>
                          <div className="col-md-3">
                            {props.invoiceNo ?
                            item.startDate
                            :
                            <Input
                              className="form-control"
                              type="date"
                              name={"itemList[" + index + "].startDate"}
                              onChange={(e) => {
                                  changeStartDate(index, e.target.value)
                              }}
                              value={item.startDate}
                              onPaste={(e) => {
                                  try {
                                      let checkDate = String(e.clipboardData.getData('Text'));
                                      if (checkDate.indexOf('-') > -1 && checkDate.length === 10) {
                                        changeStartDate(index, checkDate)
                                      } else if (checkDate.length === 8) {
                                        changeStartDate(index, checkDate.substring(0,4) + '-' + checkDate.substring(4,6) + '-' + checkDate.substring(6,8))
                                      }
                                  } catch (error) {
                                      console.log("error :: " + error);
                                  }
                              }}
                              />
                            }
                          </div>
                        </Row>
                        <Row className="mb-3">
                          <label className="col-md-3 col-form-label">
                          End date
                          </label>
                          <div className="col-md-3">
                          {props.invoiceNo ?
                            item.endDate
                            :
                            <Input
                              className="form-control"
                              type="date"
                              name={"itemList[" + index + "].endDate"}
                              onChange={(e) => {
                                filterValidation.setFieldValue("itemList[" + index + "].endDate", e.target.value);
                              }}
                              value={item.endDate}
                              onPaste={(e) => {
                                  try {
                                      let checkDate = String(e.clipboardData.getData('Text'));
                                      if (checkDate.indexOf('-') > -1 && checkDate.length === 10) {
                                        filterValidation.setFieldValue("itemList[" + index + "].endDate", checkDate);
                                      } else if (checkDate.length === 8) {
                                        filterValidation.setFieldValue("itemList[" + index + "].endDate", checkDate.substring(0,4) + '-' + checkDate.substring(4,6) + '-' + checkDate.substring(6,8));
                                      }
                                  } catch (error) {
                                      console.log("error :: " + error);
                                  }
                              }}
                              />
                            }
                          </div>
                        </Row>
                        <Row className="mb-3">
                          <label className="col-md-3 col-form-label">
                          Cost
                          </label>
                          <div className="col-md-4">
                            <input
                              className="form-control"
                              type="number"
                              placeholder="Cost"
                              name={"itemList[" + index + "].cost"}
                              onChange={(e) => {changeCost(index, "cost", e.target.value)}}
                              value={item.cost}
                            />
                          </div>
                        </Row>
                        <Row className="mb-3">
                          <label className="col-md-3 col-form-label">
                          Discount description
                          </label>
                          <div className="col-md-4">
                            <input
                              className="form-control"
                              type="text"
                              placeholder="Tax ID"
                              name={"itemList[" + index + "].dcDesc"}
                              onChange={filterValidation.handleChange}
                              value={item.dcDesc}
                            />
                          </div>
                        </Row>
                        <Row className="mb-3">
                          <label className="col-md-3 col-form-label">
                          Discount
                          </label>
                          <div className="col-md-2">
                            <input
                              className="form-control"
                              type="number"
                              placeholder="Discount"
                              name={"itemList[" + index + "].dcAmt"}
                              onChange={(e) => {changeCost(index, "dcAmt", e.target.value)}}
                              value={item.dcAmt}
                            />
                          </div>
                          <div className="col-md-2" style={{display: "flex"}}>
                            <input
                              className="form-control"
                              type="number"
                              placeholder="Discount Rate"
                              name={"itemList[" + index + "].dcRate"}
                              onChange={(e) => {changeCost(index, "dcRate", e.target.value)}}
                              value={item.dcRate}
                            />
                            <label className="col-form-label">
                            &nbsp;%
                            </label>
                          </div>
                        </Row>
                        <Row className="mb-3">
                          <label className="col-md-3 col-form-label">
                          Standard fee
                          </label>
                          <div className="col-md-4">
                            <input
                              className="form-control"
                              type="number"
                              placeholder="Standard fee"
                              name={"itemList[" + index + "].standardFee"}
                              onChange={(e) => {changeCost(index, "standardFee", e.target.value)}}
                              value={item.standardFee}
                            />
                          </div>
                        </Row>
                        <Row className="mb-3">
                          <label className="col-md-3 col-form-label">
                          Additional discount description
                          </label>
                          <div className="col-md-4">
                            <input
                              className="form-control"
                              type="text"
                              placeholder="Additional discount description"
                              name={"itemList[" + index + "].addDcDesc"}
                              onChange={filterValidation.handleChange}
                              value={item.addDcDesc}
                            />
                          </div>
                        </Row>
                        <Row className="mb-3">
                          <label className="col-md-3 col-form-label">
                          Additional discount
                          </label>
                          <div className="col-md-2">
                            <input
                              className="form-control"
                              type="number"
                              placeholder="Additional discount"
                              name={"itemList[" + index + "].addDcAmt"}
                              onChange={(e) => {changeCost(index, "addDcAmt", e.target.value)}}
                              value={item.addDcAmt}
                            />
                          </div>
                          <div className="col-md-2" style={{display: "flex"}}>
                            <input
                              className="form-control"
                              type="number"
                              placeholder="Discount Rate"
                              name={"itemList[" + index + "].addDcRate"}
                              onChange={(e) => {changeCost(index, "addDcRate", e.target.value)}}
                              value={item.addDcRate}
                            />
                            <label className="col-form-label">
                            &nbsp;%
                            </label>
                          </div>
                        </Row>
                        <Row className={props.invoiceNo && (index === filterValidation.values.itemList.length-1) ? "mb-3" : ""}>
                          <label className="col-md-3 col-form-label">
                          Subtotal
                          </label>
                          <div className="col-md-4">
                            <input
                              className="form-control"
                              type="number"
                              placeholder="Subtotal"
                              name={"itemList[" + index + "].subtotal"}
                              onChange={(e) => {changeCost(index, "subtotal", e.target.value)}}
                              value={item.subtotal}
                            />
                          </div>
                        </Row>
                        {
                        !props.invoiceNo && (index === filterValidation.values.itemList.length-1) ?
                        <div className="d-flex flex-wrap gap-3 mt-3">
                          <Button
                            type="button"
                            color="primary"
                            className="w-md"
                            onClick={()=>{
                              addInvoiceItem()
                            }}
                          >
                            Add item
                          </Button>
                        </div>
                        : null
                        }
                      </CardBody>
                    </Card>
                  )
                })
                : null
              }
              {
                filterValidation.values.itemList && filterValidation.values.itemList.length > 0 ?
                <>
                <Card>
                  <CardBody>
                    <Row className="mb-3">
                      <label className="col-md-3 col-form-label">
                      Final discount description
                      </label>
                      <div className="col-md-4">
                        <input
                          className="form-control"
                          type="text"
                          placeholder="Final discount description"
                          name="accountInfo.finalDcDesc"
                          onChange={filterValidation.handleChange}
                          value={filterValidation.values.accountInfo ? filterValidation.values.accountInfo.finalDcDesc : ''}
                        />
                      </div>
                    </Row>
                    <Row className="mb-3">
                      <label className="col-md-3 col-form-label">
                      Final discount
                      </label>
                      <div className="col-md-2">
                        <input
                          className="form-control"
                          type="number"
                          placeholder="Final discount"
                          name="accountInfo.finalDcAmt"
                          onChange={(e) => {changeFinalCost("finalDcAmt", e.target.value, cloneDeep(filterValidation.values.itemList))}}
                          value={filterValidation.values.accountInfo ? filterValidation.values.accountInfo.finalDcAmt : '0'}
                        />
                      </div>
                      <div className="col-md-2" style={{display: "flex"}}>
                        <input
                          className="form-control"
                          type="number"
                          placeholder="Discount Rate"
                          name="accountInfo.finalDcRate"
                          onChange={(e) => {changeFinalCost("finalDcRate", e.target.value, cloneDeep(filterValidation.values.itemList))}}
                          value={filterValidation.values.accountInfo ? filterValidation.values.accountInfo.finalDcRate : ''}
                        />
                        <label className="col-form-label">
                        &nbsp;%
                        </label>
                      </div>
                    </Row>
                    <Row className="mb-3">
                      <label className="col-md-3 col-form-label">
                      Additional fee description
                      </label>
                      <div className="col-md-4">
                        <input
                          className="form-control"
                          type="text"
                          placeholder="Additional fee description"
                          name="accountInfo.addFeeDesc"
                          onChange={filterValidation.handleChange}
                          value={filterValidation.values.accountInfo ? filterValidation.values.accountInfo.addFeeDesc : ''}
                        />
                      </div>
                    </Row>
                    <Row className="mb-3">
                      <label className="col-md-3 col-form-label">
                      Additional fee
                      </label>
                      <div className="col-md-4">
                        
                        <input
                          className="form-control"
                          type="number"
                          placeholder="Additional fee"
                          name="accountInfo.addFee"
                          onChange={(e) => {changeFinalCost("addFee", e.target.value, cloneDeep(filterValidation.values.itemList))}}
                          value={filterValidation.values.accountInfo ? filterValidation.values.accountInfo.addFee : '0'}
                        />
                      </div>
                    </Row>
                    <Row>
                      <label className="col-md-3 col-form-label">
                      Total
                      </label>
                      <div className="col-md-4">
                        <input
                          className="form-control"
                          type="number"
                          placeholder="Total"
                          name="accountInfo.total"
                          onChange={(e) => {changeFinalCost("total", e.target.value, cloneDeep(filterValidation.values.itemList))}}
                          value={filterValidation.values.accountInfo ? filterValidation.values.accountInfo.total : '0'}
                        />
                      </div>
                    </Row>
                    <Row className="mb-3">
                      <label className="col-md-3 col-form-label">
                      Payment method
                      </label>
                      <div className="col-md-4">
                        <select 
                          className="form-select" 
                          name="accountInfo.paymentMethod"
                          onChange={filterValidation.handleChange}
                          value={filterValidation.values.accountInfo ? filterValidation.values.accountInfo.paymentMethod : ''}
                        >
                          <option value="Paypal">Paypal</option>
                          <option value="BankTransfer">BankTransfer</option>
                        </select>
                      </div>
                    </Row>
                    <Row className="mb-3">
                      <label className="col-md-3 col-form-label">
                      Payment type
                      </label>
                      <div className="col-md-4">
                        <select 
                          className="form-select" 
                          name="accountInfo.paymentType"
                          onChange={filterValidation.handleChange}
                          value={filterValidation.values.accountInfo ? filterValidation.values.accountInfo.paymentType : ''}
                        >
                          <option value="Onetime">Onetime</option>
                          <option value="Subscription">Subscription</option>
                        </select>
                      </div>
                    </Row>
                    {
                    filterValidation.values.accountInfo && filterValidation.values.accountInfo.paymentMethod === 'Paypal' && filterValidation.values.accountInfo.paymentType === 'Subscription' ?
                    <>
                    <Row className="mb-3">
                      <label className="col-md-3 col-form-label">
                      Paypal Plan ID *
                      </label>
                      <div className="col-md-4">
                        <Input
                          className="form-control"
                          type="text"
                          placeholder="Paypal Plan ID"
                          name="accountInfo.paypalPlanID"
                          id="paypalPlanID"
                          onChange={filterValidation.handleChange}
                          value={filterValidation.values.accountInfo ? filterValidation.values.accountInfo.paypalPlanID : ''}
                          invalid={filterValidation.errors.paypalPlanID ? true : false}
                        />
                        {filterValidation.errors.paypalPlanID ? (
                        <FormFeedback type="invalid">{filterValidation.errors.paypalPlanID}</FormFeedback>
                      ) : null}
                      </div>
                    </Row>
                    <Row>
                      <label className="col-md-3 col-form-label">
                      Paypal Subscription ID
                      </label>
                      <div className="col-md-4">
                        <input
                          className="form-control"
                          type="text"
                          placeholder="Paypal Subscription ID"
                          name="accountInfo.paypalSubscriptionID"
                          onChange={filterValidation.handleChange}
                          value={filterValidation.values.accountInfo ? filterValidation.values.accountInfo.paypalSubscriptionID : ''}
                        />
                      </div>
                    </Row> 
                    </>
                    : null
                    }

                    {
                    filterValidation.values.accountInfo && filterValidation.values.accountInfo.paymentMethod === 'Paypal' && filterValidation.values.accountInfo.paymentType === 'Onetime' ?
                    <Row className="mb-3">
                      <label className="col-md-3 col-form-label">
                      Paypal Onetime link *
                      </label>
                      <div className="col-md-4">
                        <Input
                          className="form-control"
                          type="text"
                          placeholder="Paypal Onetime link"
                          name="accountInfo.paypalOnetimeLink"
                          id="paypalOnetimeLink"
                          onChange={filterValidation.handleChange}
                          value={filterValidation.values.accountInfo ? filterValidation.values.accountInfo.paypalOnetimeLink : ''}
                          invalid={filterValidation.errors.paypalOnetimeLink ? true : false}
                        />
                        {filterValidation.errors.paypalOnetimeLink ? (
                        <FormFeedback type="invalid">{filterValidation.errors.paypalOnetimeLink}</FormFeedback>
                      ) : null}
                      </div>
                    </Row>
                    : null
                    }
                    
                  </CardBody>
                </Card>
                <Card>
                  <CardBody>
                    <Row>
                      <div className="col-md-2">
                        <Button
                          type="button"
                          color="primary"
                          className="w-md"
                          onClick={() => {
                            setCreateFlag(false);
                            openEditor()
                          }}
                        >
                          Edit email
                        </Button>
                      </div>
                      <div className="col-md-2 display-flex vertical-align-center">
                        <div className="form-check">
                          <input type="checkbox" className="form-check-input"
                            id="sendEmailFlag"
                            name="accountInfo.sendEmailFlag"
                            onChange={(e) => {
                              filterValidation.setFieldValue("accountInfo.sendEmailFlag", e.target.checked ? "Y" : "N");
                              if (!e.target.checked) {
                                filterValidation.setFieldValue("accountInfo.sendInvoiceFlag", "N");
                              }
                            }}
                            checked={filterValidation.values.accountInfo.sendEmailFlag === 'Y'}
                          />
                          <Label className="form-check-label">
                          Send email
                          </Label>
                        </div>
                      </div>
                      <div className="col-md-2 display-flex vertical-align-center">
                        <div className="form-check">
                          <input type="checkbox" className="form-check-input"
                            id="downloadInvoiceFlag"
                            name="accountInfo.downloadInvoiceFlag"
                            onChange={(e) => {filterValidation.setFieldValue("accountInfo.downloadInvoiceFlag", e.target.checked ? "Y" : "N");}}
                            checked={filterValidation.values.accountInfo.downloadInvoiceFlag === 'Y'}
                          />
                          <Label className="form-check-label">
                          Download invoice
                          </Label>
                        </div>
                      </div>
                      <div className="col-md-2 display-flex vertical-align-center">
                        <div className="form-check">
                          <input type="checkbox" className="form-check-input"
                            id="sendInvoiceFlag"
                            name="accountInfo.sendInvoiceFlag"
                            onChange={(e) => {filterValidation.setFieldValue("accountInfo.sendInvoiceFlag", e.target.checked ? "Y" : "N");}}
                            checked={filterValidation.values.accountInfo.sendEmailFlag === 'Y' && filterValidation.values.accountInfo.sendInvoiceFlag === 'Y'}
                            disabled={filterValidation.values.accountInfo.sendEmailFlag === 'N' && filterValidation.values.accountInfo.sendInvoiceFlag === 'N'}
                            readOnly={filterValidation.values.accountInfo.sendEmailFlag === 'N' && filterValidation.values.accountInfo.sendInvoiceFlag === 'N'}
                          />
                          <Label className="form-check-label">
                          Send invoice
                          </Label>
                        </div>
                      </div>
                    </Row>
                  </CardBody>
                </Card>
                <Card>
                  <CardBody>
                    <div className="d-flex flex-wrap gap-3">
                      <Button
                        type="button"
                        color="primary"
                        className="w-md"
                        onClick={()=>{
                          previewInvoice();
                        }}
                      >
                        Preview invoice
                      </Button>
                      <Button
                        type="button"
                        color="primary"
                        className="w-md"
                        onClick={()=>{
                          if (filterValidation.values.accountInfo.sendEmailFlag === 'Y') {
                            setCreateFlag(true);
                            openEditor();
                          } else {
                            filterValidation.setFieldValue("emailSubject", "");
                            filterValidation.setFieldValue("emailCcItems", []);
                            filterValidation.setFieldValue("emailBccItems", []);
                            filterValidation.setFieldValue("emailForm", "");
                            filterValidation.setFieldValue("attachFiles", []);
                            filterValidation.setFieldValue("copyFiles", []);
                            filterValidation.setFieldValue("upFiles", []);
                            filterValidation.setFieldValue("submitType", "createAdminAccount");
                            filterValidation.handleSubmit(filterValidation.values);
                          }
                        }}
                      >
                        Create account
                      </Button>
                      <Button
                        type="button"
                        color="danger"
                        outline
                        className="w-md"
                        onClick={()=>{
                          props.toggleActiveTab("Search")
                        }}
                      >
                        Cancel
                      </Button>
                    </div>
                  </CardBody>
                </Card>
              </>
              :null
              }
            </Row>
          </Form>
        </Col>
      </Row>

      <CreateBillingProfile ref={billingProfileModalRef} isOpenBillingProfile={isOpenBillingProfile} setIsOpenBillingProfile={setIsOpenBillingProfile} pageName='admCreateAccount'/>

      <EditorModal isOpenEditor={isOpenEditor} isInitEditor={isInitEditor} setIsInitEditor={setIsInitEditor} closeEditor={closeEditor} loadedEditor={loadedEditor} setLoadedEditor={setLoadedEditor} 
        editorModal={editorModal} ref={editorModalRef} emailType={emailType} emailCode={emailCode} createAdminAccount={createAdminAccount} createFlag={createFlag}/>

      <PreviewInvoice isOpenPreview={isOpenPreview} setIsOpenPreview={setIsOpenPreview} invoiceInfo={filterValidation.values.invoiceInfo} itemList={filterValidation.values.itemList}/>

      {
        errorMsg !== '' ?
        <SweetAlert
          warning
          title="Warning!"
          confirmBtnBsStyle="success"
          onConfirm={() => {
            setErrorMsg('');
          }}
        >
          {errorMsg}
        </SweetAlert>
        : null
      }
    </React.Fragment>
  )
}

export default AdmCreateInvoice;