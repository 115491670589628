import { call, put, takeLatest} from "redux-saga/effects"

import { 
  GET_ADM_ACCOUNT_MANAGEMENT_SEARCH_LIST,
  DOWN_EXCEL_ADM_ACCOUNT_MANAGEMENT,
  UPDATE_ACCOUNT_BY_ADMIN,
  UPDATE_PASSWORD_BY_ADMIN,
  RESET_SMTP_BY_ADMIN,
  VERIFY_ACCOUNT_BY_ADMIN,
  VIRTUAL_LOG_IN_BY_ADMIN,
  CREATE_ACCOUNT_BY_ADMIN,
} from "./actionTypes"

import { 
  setAdmAccountManagementSearchError,
  getAdmAccountManagementSearchList as getAdmAccountManagementSearchListAction,
  getAdmAccountManagementSearchListSuccess,
  downExcelAdmAccountManagement as downInvoicePdfAction,
  downExcelAdmAccountManagementSuccess,
  updateAccountByAdminSuccess,
  updatePasswordByAdminSuccess,
  resetSmtpByAdminSuccess,
  verifyAccountByAdminSuccess,
  virtualLogInByAdminSuccess,
  createAccountByAdminSuccess,
} from "./actions"

import {
  getAdmAccountManagementSearchList,
  downExcelAdmAccountManagement,
  updateAccountByAdmin,
  updatePasswordByAdmin,
  resetSmtpByAdmin,
  verifyAccountByAdmin,
  virtualLogInByAdmin,
  createAccountByAdmin,
} from "../../helpers/backend_helper"

function* onGetAdmAccountManagementSearchList({ payload: data }) {
  try {
    const response = yield call(getAdmAccountManagementSearchList, data);
    yield put(getAdmAccountManagementSearchListSuccess(response));
  } catch (error) {
    console.log('onGetAdmAccountManagementSearchList error >> ' + error);
  }
}

function* onDownExcelAdmAccountManagement({ payload: data }) {
  try {
    yield call(downExcelAdmAccountManagement, data);
    yield put(downExcelAdmAccountManagementSuccess());

  } catch (error) {
    console.log('onDownExcelAdmAccountManagement error >> ' + error);
  }
}

function* onUpdateAccountByAdmin({ payload: data }) {
  try {
    const response = yield call(updateAccountByAdmin, data.updateParam);
    if (response.result === 'Success') {
      yield put(updateAccountByAdminSuccess(response.result));
      yield put(getAdmAccountManagementSearchListAction(data.filterParam));
    } else {
      yield put(setAdmAccountManagementSearchError(response.result));
    }
  } catch (error) {
    console.log('onUpdateAccountByAdmin error >> ' + error);
  }
}

function* onUpdatePasswordByAdmin({ payload: data }) {
  try {
    const response = yield call(updatePasswordByAdmin, data.updateParam);
    
    if (response.result === 'Success') {
      yield put(updatePasswordByAdminSuccess(response.result));
    } else {
      yield put(setAdmAccountManagementSearchError(response.result));
    }
    
    yield put(getAdmAccountManagementSearchListAction(data.filterParam));
  } catch (error) {
    console.log('onUpdatePasswordByAdmin error >> ' + error);
  }
}

function* onResetSmtpByAdmin({ payload: data }) {
  try {
    const response = yield call(resetSmtpByAdmin, data);
    yield put(resetSmtpByAdminSuccess(response));
  } catch (error) {
    console.log('onResetSmtpByAdmin error >> ' + error);
  }
}

function* onVerifyAccountByAdmin({ payload: data }) {
  try {
    const response = yield call(verifyAccountByAdmin, data.updateParam);
    yield put(verifyAccountByAdminSuccess(response.result));
    yield put(getAdmAccountManagementSearchListAction(data.filterParam));
  } catch (error) {
    console.log('onVerifyAccountByAdmin error >> ' + error);
  }
}

function* onVirtualLogInByAdmin({ payload: data }) {
  try {
    const response = yield call(virtualLogInByAdmin, data);

    if (response.result === 'Success') {
      localStorage.removeItem("loginKey");
      localStorage.removeItem("authUser")
      
      localStorage.setItem("loginKey", response.virtualLogInKey);
      const authUser = {
          userName: response.userName,
          adminYn: 'N',
          photoUrl: response.photoUrl,
          virtualLogIn: 'Y',
          adminNo: response.adminNo
      }
      localStorage.setItem("authUser", JSON.stringify(authUser));
      window.location.href="/dashboard";
    }
  } catch (error) {
    console.log('onVirtualLogInByAdmin error >> ' + error);
  }
}

function* onCreateAccountByAdmin({ payload: formData }) {
  try {
    const response = yield call(createAccountByAdmin, formData);
    if (response.errorMsg) {
      yield put(setAdmAccountManagementSearchError(response.errorMsg));
    } else {
      // console.log("downloadInvoiceFlag : " + formData.get("downloadInvoiceFlag"));
      if (formData.get("downloadInvoiceFlag") === "Y") {
        console.log("downloadInvoiceNo : " + response.invoiceNo);
        yield put(downInvoicePdfAction({invoiceNoList:[response.invoiceNo]}, "Invoices.zip"));
      }

      yield put(createAccountByAdminSuccess(response.ret));
    }
  } catch (error) {
    console.log('onCreateAccountByAdmin error >> ' + error);
  }
}

function* admAccountManagementSaga() {
  yield takeLatest(GET_ADM_ACCOUNT_MANAGEMENT_SEARCH_LIST, onGetAdmAccountManagementSearchList);
  yield takeLatest(DOWN_EXCEL_ADM_ACCOUNT_MANAGEMENT, onDownExcelAdmAccountManagement);
  yield takeLatest(UPDATE_ACCOUNT_BY_ADMIN, onUpdateAccountByAdmin);
  yield takeLatest(UPDATE_PASSWORD_BY_ADMIN, onUpdatePasswordByAdmin);
  yield takeLatest(RESET_SMTP_BY_ADMIN, onResetSmtpByAdmin);
  yield takeLatest(VERIFY_ACCOUNT_BY_ADMIN, onVerifyAccountByAdmin);
  yield takeLatest(VIRTUAL_LOG_IN_BY_ADMIN, onVirtualLogInByAdmin);
  yield takeLatest(CREATE_ACCOUNT_BY_ADMIN, onCreateAccountByAdmin);
}

export default admAccountManagementSaga

