import { call, put, takeLatest} from "redux-saga/effects"

import { 
  GET_ADM_INVOICE_MANAGEMENT_SEARCH_LIST,
  DOWN_INVOICE_PDF,
  UPDATE_INVOICE_STATUS,
  CONFIRM_PAYMENT,
  GET_SEARCH_USER_LIST,
  GET_SUBSCRIPTION_ITEM_ALL_LIST,
  GET_CRESTER_LIST,
  CREATE_ADMIN_INVOICE,
  GET_USER_INVOICE_INFO_LIST,
  PREVIEW_INVOICE_ITEM,
  GET_INVOICE_ITEM,
} from "./actionTypes"

import { 
  getAdmInvoiceManagementSearchList as getAdmInvoiceManagementSearchListAction,
  getAdmInvoiceManagementSearchListSuccess,
  downInvoicePdf as downInvoicePdfAction,
  downInvoicePdfSuccess,
  updateInvoiceStatusSuccess,
  confirmPaymentSuccess,
  getSearchUserListSuccess,
  getSubscriptionItemAllListSuccess,
  getCresterListSuccess,
  createAdminInvoiceSuccess,
  setAdmInvoiceManagementError,
  getUserInvoiceInfoListSuccess,
  previewInvoiceItemSuccess,
  getInvoiceItemSuccess,
  setAdmInvoiceManagementSearchResult
} from "./actions"

import {
  getAdmInvoiceManagementSearchList,
  downInvoicePdf,
  updateInvoiceStatus,
  confirmPayment,
  getSearchUserList,
  getSubscriptionItemAllList,
  getCresterList,
  createAdminInvoice,
  getUserInvoiceInfoList,
  getInvoiceItemInfo,
} from "../../helpers/backend_helper"

function* onGetAdmInvoiceManagementSearchList({ payload: data }) {
  try {
    const response = yield call(getAdmInvoiceManagementSearchList, data);
    yield put(getAdmInvoiceManagementSearchListSuccess(response));
  } catch (error) {
    console.log('onGetAdmInvoiceManagementSearchList error >> ' + error);
  }
}

function* onDownInvoicePdf({ payload: data }) {
  try {
    yield call(downInvoicePdf, data);
    yield put(downInvoicePdfSuccess());

  } catch (error) {
    console.log('onDownInvoicePdf error >> ' + error);
  }
}

function* onUpdateInvoiceStatus({ payload: data }) {
  try {
    const response = yield call(updateInvoiceStatus, data.updateParam);
    yield put(updateInvoiceStatusSuccess(response.ret));
    yield put(getAdmInvoiceManagementSearchListAction(data.filterParam));
  } catch (error) {
    console.log('onUpdateInvoiceStatus error >> ' + error);
  }
}

function* onConfirmPayment({ payload: data }) {
  try {
    const response = yield call(confirmPayment, data.confirmParam);

    // console.log('response >> ' + JSON.stringify(response));
    // console.log('response.error >> ' + response.error);
    // console.log('response.success >> ' + response.success);
    if(response.error){
      yield put(setAdmInvoiceManagementSearchResult(response.error));
    } else {
      yield put(confirmPaymentSuccess(response.success));
    }
    
    yield put(getAdmInvoiceManagementSearchListAction(data.filterParam));
  } catch (error) {
    console.log('onConfirmPayment error >> ' + error);
  }
}

function* onGetSearchUserList({ payload: data }) {
  try {
    const response = yield call(getSearchUserList, data);
    yield put(getSearchUserListSuccess(response));
  } catch (error) {
    console.log('onGetSearchUserList error >> ' + error);
  }
}

function* onGetSubscriptionItemAllList({ payload: data }) {
  try {
    const response = yield call(getSubscriptionItemAllList, data);
    yield put(getSubscriptionItemAllListSuccess(response));
  } catch (error) {
    console.log('onGetSubscriptionItemAllList error >> ' + error);
  }
}

function* onGetCresterList({ payload: data }) {
  try {
    const response = yield call(getCresterList, data);
    yield put(getCresterListSuccess(response));
  } catch (error) {
    console.log('onGetCresterList error >> ' + error);
  }
}

function* onCreateAdminInvoice({ payload: formData }) {
  try {
    const response = yield call(createAdminInvoice, formData);
    if (response.errorMsg) {
      yield put(setAdmInvoiceManagementError(response.errorMsg));
    } else {
      // console.log("downloadInvoiceFlag : " + formData.get("downloadInvoiceFlag"));
      if (formData.get("downloadInvoiceFlag") === "Y") {
        console.log("downloadInvoiceNo : " + response.invoiceNo);
        yield put(downInvoicePdfAction({invoiceNoList:[response.invoiceNo]}, "Invoices.zip"));
      }

      yield put(createAdminInvoiceSuccess(response.ret));
    }
  } catch (error) {
    console.log('onCreateAdminInvoice error >> ' + error);
  }
}

function* onGetUserInvoiceInfoList({ payload: data }) {
  try {
    const response = yield call(getUserInvoiceInfoList, data);
    yield put(getUserInvoiceInfoListSuccess(response));
  } catch (error) {
    console.log('onGetUserInvoiceInfoList error >> ' + error);
  }
}

function* onPreviewInvoiceItem({ payload: data }) {
  try {
    const response = yield call(getInvoiceItemInfo, data);
    yield put(previewInvoiceItemSuccess(response));
  } catch (error) {
    console.log('onPreviewInvoiceItem error >> ' + error);
  }
}

function* onGetInvoiceItem({ payload: data }) {
  try {
    const response = yield call(getInvoiceItemInfo, data);
    yield put(getInvoiceItemSuccess(response));
  } catch (error) {
    console.log('onGetInvoiceItem error >> ' + error);
  }
}


function* admInvoiceManagementSaga() {
  yield takeLatest(GET_ADM_INVOICE_MANAGEMENT_SEARCH_LIST, onGetAdmInvoiceManagementSearchList);
  yield takeLatest(DOWN_INVOICE_PDF, onDownInvoicePdf);
  yield takeLatest(UPDATE_INVOICE_STATUS, onUpdateInvoiceStatus);
  yield takeLatest(CONFIRM_PAYMENT, onConfirmPayment);
  yield takeLatest(GET_SEARCH_USER_LIST, onGetSearchUserList);
  yield takeLatest(GET_SUBSCRIPTION_ITEM_ALL_LIST, onGetSubscriptionItemAllList);
  yield takeLatest(GET_CRESTER_LIST, onGetCresterList);
  yield takeLatest(CREATE_ADMIN_INVOICE, onCreateAdminInvoice);
  yield takeLatest(GET_USER_INVOICE_INFO_LIST, onGetUserInvoiceInfoList);
  yield takeLatest(PREVIEW_INVOICE_ITEM, onPreviewInvoiceItem);
  yield takeLatest(GET_INVOICE_ITEM, onGetInvoiceItem);
}

export default admInvoiceManagementSaga

