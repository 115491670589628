import {
  GET_CONNECTABLE_ENTITY_LIST,
  GET_CONNECTABLE_ENTITY_LIST_SUCCESS,
  GET_MY_ENTITY_LIST,
  GET_MY_ENTITY_LIST_SUCCESS,
  GET_MAIN_DOMAIN,
  GET_MAIN_DOMAIN_SUCCESS,
  GET_SEARCH_ENTITY_LIST,
  GET_SEARCH_ENTITY_LIST_SUCCESS,
  GET_USER_ENTITY_CONNECT_LIST,
  GET_USER_ENTITY_CONNECT_LIST_SUCCESS,
  DOWN_EXCEL_USER_ENTITY_CONNECT_LIST,
  DOWN_EXCEL_USER_ENTITY_CONNECT_LIST_SUCCESS,
  CHANGE_USER_ENTITY_CONNECT_LIST,
  CONFIRM_USER_ENTITY_CONNECT,
  CONFIRM_USER_ENTITY_CONNECT_SUCCESS,
  DISCONNECT_USER_ENTITY,
  DISCONNECT_USER_ENTITY_SUCCESS,
  GET_USER_ENTITY_CONNECT_CLAIM_LIST,
  GET_USER_ENTITY_CONNECT_CLAIM_LIST_SUCCESS,
  DOWN_EXCEL_USER_ENTITY_CONNECT_CLAIM_LIST,
  DOWN_EXCEL_USER_ENTITY_CONNECT_CLAIM_LIST_SUCCESS,
  ADD_USER_ENTITY_CONNECT_CLAIM,
  ADD_USER_ENTITY_CONNECT_CLAIM_SUCCESS,
  MODIFY_USER_ENTITY_CONNECT_CLAIM_STATUS,
  MODIFY_USER_ENTITY_CONNECT_CLAIM_STATUS_SUCCESS,
  CONFIRM_USER_ENTITY_CONNECT_CLAIM,
  CONFIRM_USER_ENTITY_CONNECT_CLAIM_SUCCESS,
  CHANGE_USER_ENTITY_CONNECT_CLAIM_LIST,
  SET_SUCCESS,
  SET_RESULT,
  SET_LOADING,
  GET_REQUESTER_LIST,
  GET_REQUESTER_LIST_SUCCESS,
  CHANGE_REQUESTER_LIST,
  GET_USER_DOMAIN_LIST,
  GET_USER_DOMAIN_LIST_SUCCESS,
  ADD_USER_DOMAIN,
  ADD_USER_DOMAIN_SUCCESS,
  MODIFY_USER_DOMAIN,
  MODIFY_USER_DOMAIN_SUCCESS,
  REMOVE_USER_DOMAIN,
  REMOVE_USER_DOMAIN_SUCCESS,
  CHANGE_USER_DOMAIN_LIST,
} from "./actionTypes"

export const getConnectableEntityList = data => {
  return {
    type: GET_CONNECTABLE_ENTITY_LIST,
    payload: data,
  }
}

export const getConnectableEntityListSuccess = data => {
  return {
    type: GET_CONNECTABLE_ENTITY_LIST_SUCCESS,
    payload: data,
  }
}

export const getMyEntityList = data => {
  return {
    type: GET_MY_ENTITY_LIST,
    payload: data,
  }
}

export const getMyEntityListSuccess = data => {
  return {
    type: GET_MY_ENTITY_LIST_SUCCESS,
    payload: data,
  }
}

export const getMainDomain = data => {
  return {
    type: GET_MAIN_DOMAIN,
    payload: data,
  }
}

export const getMainDomainSuccess = data => {
  return {
    type: GET_MAIN_DOMAIN_SUCCESS,
    payload: data,
  }
}

export const getSearchEntityList = data => {
  return {
    type: GET_SEARCH_ENTITY_LIST,
    payload: data,
  }
}

export const getSearchEntityListSuccess = data => {
  return {
    type: GET_SEARCH_ENTITY_LIST_SUCCESS,
    payload: data,
  }
}

export const getUserEntityConnectList = data => {
  return {
    type: GET_USER_ENTITY_CONNECT_LIST,
    payload: data,
  }
}

export const getUserEntityConnectListSuccess = data => {
  return {
    type: GET_USER_ENTITY_CONNECT_LIST_SUCCESS,
    payload: data,
  }
}

export const downExcelUserEntityConnectList = data => {
  return {
    type: DOWN_EXCEL_USER_ENTITY_CONNECT_LIST,
    payload: data,
  }
}

export const downExcelUserEntityConnectListSuccess = data => {
  return {
    type: DOWN_EXCEL_USER_ENTITY_CONNECT_LIST_SUCCESS,
    payload: data,
  }
}

export const changeUserEntityConnectList = data => {
  return {
    type: CHANGE_USER_ENTITY_CONNECT_LIST,
    payload: data,
  }
}

export const confirmUserEntityConnect = data => {
  return {
    type: CONFIRM_USER_ENTITY_CONNECT,
    payload: data,
  }
}

export const confirmUserEntityConnectSuccess = data => {
  return {
    type: CONFIRM_USER_ENTITY_CONNECT_SUCCESS,
    payload: data,
  }
}

export const disConnectUserEntity = data => {
  return {
    type: DISCONNECT_USER_ENTITY,
    payload: data,
  }
}

export const disConnectUserEntitySuccess = data => {
  return {
    type: DISCONNECT_USER_ENTITY_SUCCESS,
    payload: data,
  }
}

export const getUserEntityConnectClaimList = data => {
  return {
    type: GET_USER_ENTITY_CONNECT_CLAIM_LIST,
    payload: data,
  }
}

export const getUserEntityConnectClaimListSuccess = data => {
  return {
    type: GET_USER_ENTITY_CONNECT_CLAIM_LIST_SUCCESS,
    payload: data,
  }
}

export const downExcelUserEntityConnectClaimList = data => {
  return {
    type: DOWN_EXCEL_USER_ENTITY_CONNECT_CLAIM_LIST,
    payload: data,
  }
}

export const downExcelUserEntityConnectClaimListSuccess = data => {
  return {
    type: DOWN_EXCEL_USER_ENTITY_CONNECT_CLAIM_LIST_SUCCESS,
    payload: data,
  }
}

export const addUserEntityConnectClaim = (data, filter, myEntityFilterParam) => {
  return {
    type: ADD_USER_ENTITY_CONNECT_CLAIM,
    payload: {data, filter, myEntityFilterParam},
  }
}

export const addUserEntityConnectClaimSuccess = data => {
  return {
    type: ADD_USER_ENTITY_CONNECT_CLAIM_SUCCESS,
    payload: data,
  }
}

export const confirmUserEntityConnectClaim = (data, filter) => {
  return {
    type: CONFIRM_USER_ENTITY_CONNECT_CLAIM,
    payload: {data, filter},
  }
}

export const confirmUserEntityConnectClaimSuccess = data => {
  return {
    type: CONFIRM_USER_ENTITY_CONNECT_CLAIM_SUCCESS,
    payload: data,
  }
}

export const modifyUserEntityConnectClaimStatus = (data, filter) => {
  return {
    type: MODIFY_USER_ENTITY_CONNECT_CLAIM_STATUS,
    payload: {data, filter},
  }
}

export const modifyUserEntityConnectClaimStatusSuccess = data => {
  return {
    type: MODIFY_USER_ENTITY_CONNECT_CLAIM_STATUS_SUCCESS,
    payload: data,
  }
}

export const changeUserEntityConnectClaimList = data => {
  return {
    type: CHANGE_USER_ENTITY_CONNECT_CLAIM_LIST,
    payload: data,
  }
}

export const setEntityConnectManagementSuccess = data => {
  return {
    type: SET_SUCCESS,
    payload: data,
  }
}

export const setEntityConnectManagementResult = data => {
  return {
    type: SET_RESULT,
    payload: data,
  }
}

export const setEntityConnectManagementLoading = data => {
  return {
    type: SET_LOADING,
    payload: data,
  }
}

export const getRequesterList = data => {
  return {
    type: GET_REQUESTER_LIST,
    payload: data,
  }
}

export const getRequesterListSuccess = data => {
  return {
    type: GET_REQUESTER_LIST_SUCCESS,
    payload: data,
  }
}

export const changeRequesterList = data => {
  return {
    type: CHANGE_REQUESTER_LIST,
    payload: data,
  }
}

export const getUserDomainList = data => {
  return {
    type: GET_USER_DOMAIN_LIST,
    payload: data,
  }
}

export const getUserDomainListSuccess = data => {
  return {
    type: GET_USER_DOMAIN_LIST_SUCCESS,
    payload: data,
  }
}

export const addUserDomain = data => {
  return {
    type: ADD_USER_DOMAIN,
    payload: data,
  }
}

export const addUserDomainSuccess = data => {
  return {
    type: ADD_USER_DOMAIN_SUCCESS,
    payload: data,
  }
}

export const modifyUserDomain = data => {
  return {
    type: MODIFY_USER_DOMAIN,
    payload: data,
  }
}

export const modifyUserDomainSuccess = data => {
  return {
    type: MODIFY_USER_DOMAIN_SUCCESS,
    payload: data,
  }
}

export const removeUserDomain = data => {
  return {
    type: REMOVE_USER_DOMAIN,
    payload: data,
  }
}

export const removeUserDomainSuccess = data => {
  return {
    type: REMOVE_USER_DOMAIN_SUCCESS,
    payload: data,
  }
}

export const changeUserDomainList = data => {
  return {
    type: CHANGE_USER_DOMAIN_LIST,
    payload: data,
  }
}