import React, { useState } from "react";
import { Card, CardBody, Table, CardTitle, Row, Col } from "reactstrap";

const SubscriptionDetails = props => {
    return (
        <Row>
            <Col lg={12}>
                <Card>
                    <CardBody>
                        <CardTitle className="h4 mb-4">Subscription details</CardTitle>
                        <div className="table-responsive">
                            <Table className="table-centered table-nowrap mb-0">
                                <thead className="table-light">
                                    <tr>
                                        <th>Subscription</th>
                                        <th>Tier</th>
                                        <th>Start</th>
                                        <th>End</th>
                                    </tr>
                                </thead>
                                <tbody>
                                {props.data && props.data.length?
                                    props.data.map((subscription, index)=>{
                                        return(
                                            subscription.crCode != '00' ?
                                            <tr>
                                                <td>({subscription.inOutType === 'IN' ? 'Inbound' : 'Outbound'}) {subscription.crCode} {subscription.crName}</td>
                                                <td>{subscription.gradeStr}</td>
                                                <td>{subscription.startDate}</td>
                                                <td>{subscription.endDate}</td>
                                            </tr>
                                            : null
                                        )
                                    })
                                :null}
                                </tbody>
                            </Table>
                        </div>
                    </CardBody>
                </Card>
            </Col>
        </Row>
    );
};

export default SubscriptionDetails;