import React, {useEffect, useState} from 'react';
import { cloneDeep, join } from 'lodash';

import {
    Row,
    Button,
    FormFeedback,
    Input,
} from "reactstrap"
  
const Period4MBtn = props => {
    const [dateBtnList, setDateBtnList] = useState([
        {label: '1W', value: '1 weeks'},
        {label: '1M', value: '1 months'},
        {label: '3M', value: '3 months'},
        {label: '4M', value: '4 months'},
        ]);

    useEffect(() => {
        if (props.formik.values.datePeriod) {
            dateSetting(props.formik.values.datePeriod);
        }
        
    }, [props.formik.values.datePeriod]);

    const dateSetting = (period) => {
        let today = new Date();
            
        let endDate = today.getFullYear() + "-" + ("0" + (today.getMonth() + 1)).slice(-2) + "-" + ("0" +today.getDate()).slice(-2);
        
        let startDate;
        
        if (period.slice(2,3) == 'd') {
            startDate  = today.getFullYear() + "-" + ("0" + (today.getMonth() + 1)).slice(-2) + "-" + ("0" + (today.getDate())).slice(-2);
        } else if (period.slice(2,3) == 'w') {
            today.setDate(today.getDate() - (parseInt(period.slice(0,1)*7)) + 1);
            startDate  = today.getFullYear() + "-" + ("0" + (today.getMonth() + 1)).slice(-2) + "-" + ("0" + (today.getDate())).slice(-2);
        } else if (period.slice(2,3) == 'm') {
            today.setMonth(today.getMonth() - parseInt(period.slice(0,1)));
            today.setDate(today.getDate() + 1);
            startDate  = today.getFullYear() + "-" + ("0" + (today.getMonth() + 1)).slice(-2) + "-" + ("0" + (today.getDate())).slice(-2);
        }    
        
        // console.log("dateSetting : " + period + ", startDate : " + startDate + ", endDate : " + endDate);

        props.formik.setFieldValue("startDate", startDate);
        props.formik.setFieldValue("endDate", endDate);
    }

    return (
        <>
            <Row className="mb-3">
                <div className="button-items" style={{paddingRight : '0'}}>
                    {
                        dateBtnList && dateBtnList.length ?
                        dateBtnList.map((btn, index) => {
                                return (
                                    <Button 
                                    type="button"
                                    color="secondary" outline 
                                    className="waves-effect"
                                    style={{width: "135px"}}
                                    active={props.formik.values.datePeriod === btn.value}
                                    onClick={() => {
                                        props.formik.setFieldValue("datePeriod", btn.value);
                                    }}>
                                        {btn.label}
                                    </Button>
                                )
                            })
                            :
                            null
                    }
                </div>
            </Row>
            <Row className="mb-3">
                <label
                htmlFor="startDate"
                className="col-md-1 col-form-label"
                >
                From
                </label>
                <div className="col-md-3">
                <Input
                    className="form-control"
                    type="date"
                    id="startDate"
                    name="startDate"
                    onChange={(e) => {
                        // props.formik.handleChange;
                        props.formik.setFieldValue("startDate", e.target.value);
                        props.formik.setFieldValue("datePeriod", "");
                    }}
                    value={props.formik.values.startDate}
                    invalid={
                        props.formik.touched.startDate &&
                        props.formik.errors.startDate
                          ? true
                          : false
                      }
                    onPaste={(e) => {
                        // console.log("onpaste :: " + e.clipboardData.getData('Text'));
                        try {
                            let checkDate = String(e.clipboardData.getData('Text'));
                            if (checkDate.indexOf('-') > -1 && checkDate.length == 10) {
                                props.formik.setFieldValue("startDate", checkDate);
                            } else if (checkDate.length == 8) {
                                props.formik.setFieldValue("startDate", checkDate.substring(0,4) + '-' + checkDate.substring(4,6) + '-' + checkDate.substring(6,8));
                            }
                        } catch (error) {
                            console.log("error :: " + error);
                        }
                    }}
                    />
                    {props.formik.touched.startDate &&
                    props.formik.errors.startDate ? (
                      <FormFeedback type="invalid">
                        {props.formik.errors.startDate}
                      </FormFeedback>
                    ) : null}
                </div>
                <label
                htmlFor="endDate"
                className="col-md-1 col-form-label"
                >
                To
                </label>
                <div className="col-md-3">
                <Input
                    className="form-control"
                    type="date"
                    id="endDate"
                    name="endDate"
                    onChange={(e) => {
                        // props.formik.handleChange;
                        props.formik.setFieldValue("endDate", e.target.value);
                        props.formik.setFieldValue("datePeriod", "");
                    }}
                    value={props.formik.values.endDate}
                    invalid={
                        props.formik.touched.endDate &&
                        props.formik.errors.endDate
                          ? true
                          : false
                      }
                    onPaste={(e) => {
                    // console.log("onpaste :: " + e.clipboardData.getData('Text'));
                    try {
                        let checkDate = String(e.clipboardData.getData('Text'));
                        if (checkDate.indexOf('-') > -1 && checkDate.length == 10) {
                            props.formik.setFieldValue("endDate", checkDate);
                        } else if (checkDate.length == 8) {
                            props.formik.setFieldValue("endDate", checkDate.substring(0,4) + '-' + checkDate.substring(4,6) + '-' + checkDate.substring(6,8));
                        }
                    } catch (error) {
                        console.log("error :: " + error);
                    }
                }}
                />
                    {props.formik.touched.endDate &&
                    props.formik.errors.endDate ? (
                      <FormFeedback type="invalid">
                        {props.formik.errors.endDate}
                      </FormFeedback>
                    ) : null}
                </div>
            </Row>
        </>
    )
};

export default Period4MBtn;