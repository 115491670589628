import PropTypes from 'prop-types'
import React, { useEffect } from "react"
import { Row, Col, Alert, Card, CardBody, Container, FormFeedback, Input, Label, Form } from "reactstrap";

// Redux
import { useSelector, useDispatch } from "react-redux";
import { withRouter, Link } from "react-router-dom"

// Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";

// action
import { userResetPassword } from "../../store/actions"

// import images
import logo from "../../assets/images/logo-dark.png"
import logolight from "../../assets/images/logo-light.png"

const ResetPasswordPage = ({ match }) => {
  const dispatch = useDispatch();

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    //enableReinitialize: true,
    initialValues: {
      password: '',
      passwordType: "password",
      passwordIcon: "col-md-1 uil-eye",
      chkConfirmNewPassword: '',
      chkConfirmNewPasswordType: "password",
      chkConfirmNewPasswordIcon: "col-md-1 uil-eye",
      encConfirmKey: match.params.encConfirmKey,
    },
    validationSchema: Yup.object({
      password: Yup.string().required("Please Enter New password"),
      chkConfirmNewPassword: Yup.string().required("Please Enter Confirm new password"),
    }),
    onSubmit: (values) => {
      dispatch(userResetPassword(values));
    }
  });


  useEffect(() => {
    document.body.className = "authentication-bg";
    // remove classname when component will unmount
    return function cleanup() {
      document.body.className = "";
    };
  });
  const { forgetError, forgetSuccessMsg } = useSelector(state => ({
    forgetError: state.ForgetPassword.forgetError,
    forgetSuccessMsg: state.ForgetPassword.forgetSuccessMsg,
  }));


  return (
    <React.Fragment>
      {/*
      <div className="home-btn d-none d-sm-block">
        <Link to="/" className="text-dark">
          <i className="mdi mdi-home-variant h2"></i>
        </Link>
      </div>
      */}

      <div className="account-pages my-5  pt-sm-5">
        <Container>
          <Row className="justify-content-center">

            <Col md={8} lg={6} xl={5}>
              <div>
                <a href="https://www.wecrest.com" target="_blank"  className="mb-5 d-block auth-logo">
                  <img src={logo} alt="" height="50" className="logo logo-dark" />
                  <img src={logolight} alt="" height="50" className="logo logo-light" />
                </a>
                
                <Card>
                  <CardBody className="p-4">
                    <div className="p-2 mt-4">
                      {forgetError && forgetError ? (
                        <Alert color="danger" className="text-center mb-4" style={{ marginTop: "13px" }}>
                          {forgetError}
                        </Alert>
                      ) : null}
                      {forgetSuccessMsg && forgetSuccessMsg === "Success" ? (
                        <Alert color="success" className="text-center mb-4" style={{ marginTop: "13px" }}>
                          Your password has been reset, and you can now<br/>
                          use it to <Link to="/login" className="fw-medium text-primary">log in</Link> to your WeCrest account.
                        </Alert>
                      ) : null}

                    <Form
                      className="form-horizontal"
                      onSubmit={(e) => {
                        e.preventDefault();
                        validation.handleSubmit();
                        return false;
                      }}
                    >
                        <div className="mb-3">
                          <Label className="form-label">New password</Label>
                          <div className="d-flex vertical-align-center gap-2">
                            <div className="col-md-11">
                              <Input
                                name="password"
                                className="form-control"
                                placeholder="New password"
                                type={validation.values.passwordType}
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.password || ""}
                                invalid={
                                  validation.touched.password && validation.errors.password ? true : false
                                }
                              />
                              {validation.touched.password && validation.errors.password ? (
                                <FormFeedback type="invalid">{validation.errors.password}</FormFeedback>
                              ) : null}
                            </div>
                            <i className={validation.values.passwordIcon} style={{color:"#5c8dc6", fontSize:"18px", cursor:"pointer"}} 
                              onClick={()=>{
                                if (validation.values.passwordType === "password") {
                                  validation.setFieldValue("passwordType", "text");
                                  validation.setFieldValue("passwordIcon", "col-md-1 uil-eye-slash");
                                } else {
                                  validation.setFieldValue("passwordType", "password");
                                  validation.setFieldValue("passwordIcon", "col-md-1 uil-eye");
                                }
                              }}></i>
                          </div>
                          
                        </div>
                        <div className="mb-3">
                          <Label className="form-label">Confirm new password</Label>
                          <div className="d-flex vertical-align-center gap-2">
                            <div className="col-md-11">
                              <Input
                                name="chkConfirmNewPassword"
                                className="form-control"
                                placeholder="Confirm new password"
                                type={validation.values.chkConfirmNewPasswordType}
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.chkConfirmNewPassword || ""}
                                invalid={
                                  validation.touched.chkConfirmNewPassword && validation.errors.chkConfirmNewPassword ? true : false
                                }
                              />
                              {validation.touched.chkConfirmNewPassword && validation.errors.chkConfirmNewPassword ? (
                                <FormFeedback type="invalid">{validation.errors.chkConfirmNewPassword}</FormFeedback>
                              ) : null}
                            </div>
                            <i className={validation.values.chkConfirmNewPasswordIcon} style={{color:"#5c8dc6", fontSize:"18px", cursor:"pointer"}} 
                              onClick={()=>{
                                if (validation.values.chkConfirmNewPasswordType === "password") {
                                  validation.setFieldValue("chkConfirmNewPasswordType", "text");
                                  validation.setFieldValue("chkConfirmNewPasswordIcon", "col-md-1 uil-eye-slash");
                                } else {
                                  validation.setFieldValue("chkConfirmNewPasswordType", "password");
                                  validation.setFieldValue("chkConfirmNewPasswordIcon", "col-md-1 uil-eye");
                                }
                              }}></i>
                          </div>
                        </div>
                        <Row className="mb-0">
                          <Col xs={12} className="text-end">
                            <button
                              className="btn btn-primary w-md waves-effect waves-light"
                              type="submit"
                            >
                              Reset
                          </button>
                          </Col>
                        </Row>
                        <div className="mt-4 text-center">
                          <p className="mb-0"><Link to="/login" className="fw-medium text-primary"> Return to log in page </Link></p>
                        </div>
                      </Form>
                    </div>

                  </CardBody>
                </Card>
                <div className="mt-5 text-center">
                  <p>© WeCrest Ltd 2017-{new Date().getFullYear()} all rights reserved</p>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withRouter(ResetPasswordPage);