import { call, put, takeEvery, takeLatest } from "redux-saga/effects"

// Login Redux States
import { 
  GET_DASHBOARD_CARD_INFO,
  SEND_FEEDBACK,
  GET_WECREST_INFO,
} from "./actionTypes"
import { 
  getDashboardCardInfoSuccess,
  getWeCrestSuccess,
} from "./actions"
import { 
  setResult,
} from "../filerRankings/actions"

//Include Both Helper File with needed methods
import {
  getDashboardCardInfo,
  sendFeedback,
  getWeCrestInfo,
} from "../../helpers/backend_helper"

function* onGetDashboardCardInfo() {
  try {
    const response = yield call(getDashboardCardInfo);
    yield put(getDashboardCardInfoSuccess(response));
    
  } catch (error) {
    console.log('onGetDashboardCardInfo error >> ' + error);
    //yield put(apiError(error))
  }
}

function* onSendFeedback({ payload : data}) {
  try {
    const response = yield call(sendFeedback, data);
    yield put(setResult(1));
  } catch (error) {
    console.log('onSendFeedback error >> ' + error);
    //yield put(apiError(error))
  }
}

function* onGetWeCrestInfo() {
  try {
    const response = yield call(getWeCrestInfo);
    yield put(getWeCrestSuccess(response));
    
  } catch (error) {
    console.log('onGetWeCrestInfo error >> ' + error);
    //yield put(apiError(error))
  }
}

function* DashboardSaga() {
  yield takeLatest(GET_DASHBOARD_CARD_INFO, onGetDashboardCardInfo);
  yield takeLatest(SEND_FEEDBACK, onSendFeedback);
  yield takeLatest(GET_WECREST_INFO, onGetWeCrestInfo);
}

export default DashboardSaga

