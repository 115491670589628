import {
  GET_SPECIFIC_SEARCH_LIST,
  GET_SPECIFIC_SEARCH_LIST_SUCCESS,
  DOWN_SPECIFIC_SEARCH_EXCEL,
  DOWN_SPECIFIC_SEARCH_EXCEL_SUCCESS,
  DOWN_SPECIFIC_SEARCH_PDF,
  DOWN_SPECIFIC_SEARCH_PDF_SUCCESS,
  CLEAR_SPECIFIC_SEARCH,
} from "./actionTypes"

export const getSpecificSearchList = data => {
  return {
    type: GET_SPECIFIC_SEARCH_LIST,
    payload: data,
  }
}

export const getSpecificSearchListSuccess = data => {
  return {
    type: GET_SPECIFIC_SEARCH_LIST_SUCCESS,
    payload: data,
  }
}

export const downSpecificSearchExcel = (data, fileName) => {
  return {
    type: DOWN_SPECIFIC_SEARCH_EXCEL,
    payload: {data, fileName},
  }
}

export const downSpecificSearchExcelSuccess = () => {
  return {
    type: DOWN_SPECIFIC_SEARCH_EXCEL_SUCCESS,
  }
}

export const downSpecificSearchPdf = (data, fileName) => {
  return {
    type: DOWN_SPECIFIC_SEARCH_PDF,
    payload: {data, fileName},
  }
}

export const downSpecificSearchPdfSuccess = () => {
  return {
    type: DOWN_SPECIFIC_SEARCH_PDF_SUCCESS,
  }
}

export const clearSpecificSearch = () => {
  return {
    type: CLEAR_SPECIFIC_SEARCH,
  }
}