import { call, put, takeEvery, takeLatest } from "redux-saga/effects"

// Login Redux States
import { 
  GET_SPECIFIC_SEARCH_LIST,
  DOWN_SPECIFIC_SEARCH_EXCEL,
  DOWN_SPECIFIC_SEARCH_PDF,
} from "./actionTypes"
import { 
  getSpecificSearchListSuccess,
  downSpecificSearchExcelSuccess,
  downSpecificSearchPdfSuccess,
} from "./actions"

//Include Both Helper File with needed methods
import {
  getSpecificSearchList,
  downSpecificSearchExcel,
  downSpecificSearchPdf,
} from "../../helpers/backend_helper"

function* onGetSpecificSearchList({ payload: data }) {
  try {
    const response = yield call(getSpecificSearchList, data);
    yield put(getSpecificSearchListSuccess(response));
    
  } catch (error) {
    console.log('onGetSpecificSearchList error >> ' + error);
    //yield put(apiError(error))
  }
}

function* onDownSpecificSearchExcel({ payload: data }) {
  try {
    yield call(downSpecificSearchExcel, data);
    yield put(downSpecificSearchExcelSuccess());

  } catch (error) {
    console.log('onDownSpecificSearchExcel error >> ' + error);
    //yield put(apiError(error))
  }
}

function* onDownSpecificSearchPdf({ payload: data }) {
  try {
    yield call(downSpecificSearchPdf, data);
    yield put(downSpecificSearchPdfSuccess());

  } catch (error) {
    console.log('onDownSpecificSearchPdf error >> ' + error);
    //yield put(apiError(error))
  }
}

function* SpecificSearchSaga() {
  yield takeLatest(GET_SPECIFIC_SEARCH_LIST, onGetSpecificSearchList);
  yield takeLatest(DOWN_SPECIFIC_SEARCH_EXCEL, onDownSpecificSearchExcel);
  yield takeLatest(DOWN_SPECIFIC_SEARCH_PDF, onDownSpecificSearchPdf);
  
}

export default SpecificSearchSaga

