import React, {useState, useEffect, useRef} from 'react';
import { useSelector, useDispatch } from "react-redux";
import { Editor } from '@tinymce/tinymce-react';
import LoadingModal from "./LoadingModal"
import {
    Row,
} from "reactstrap"
import {
    addUserSignature,
    updateUserSignature,
    setSettingsReducerValue,
} from "../../store/actions";
import SweetAlert from "react-bootstrap-sweetalert";
import './EditorModal.css';
import { cloneDeep } from 'lodash';

const SignatureEditorModal = props => {
    const dispatch = useDispatch();

    const { userSignature, userSignatureList, userSignatureMenuItems, closeEditorFlag, modifiedSignatureFlag, errorMsg } = useSelector(state => ({
        userSignature: state.Settings.userSignature,
        userSignatureList: state.Settings.userSignatureList,
        userSignatureMenuItems: state.Settings.userSignatureMenuItems,
        closeEditorFlag: state.Settings.closeEditorFlag,
        modifiedSignatureFlag: state.Settings.modifiedSignatureFlag,
        errorMsg: state.Settings.errorMsg,
    }));

    const sigEditorRef = useRef(null);

    useEffect(()=> {
        if (modifiedSignatureFlag) {
            dispatch(setSettingsReducerValue('MODIFIED_SIGNATURE_FLAG', false));
        }  
    },[modifiedSignatureFlag]);

    useEffect(()=> {
        if (closeEditorFlag) {
            props.closeSigEditor();
        }
    },[closeEditorFlag]);

    useEffect(() => {
        props.loadedSigEditor && userSignature.sigForm && sigEditorRef.current.setContent(userSignature.sigForm, {format: 'html'});
    }, [userSignature]);

    return (
        <>
        <div tabindex="-1" style={props.isOpenSigEditor && props.isInitSigEditor && props.loadedSigEditor ? {position: "relative", zIndex: "1050", display: "block"} : {position: "relative", zIndex: "1050", display: "none"}}>
            <div className="">
                <div className="modal fade show" role="dialog" tabindex="-1" style={props.isOpenSigEditor && props.isInitSigEditor && props.loadedSigEditor ? {display: "block"} : {display: "none"}} id="SigEditorModal">
                    <div className="modal-dialog modal-xl" role="document" ref={props.sigEditorModal} openStatus={props.isOpenSigEditor ? "Y" : "N"}>
                        <div className="modal-content">
                            <div className="modal-header">
                                <Row style={{width: "100%", marginLeft: "0px"}}>
                                    <Row className="mb-3" style={{marginLeft: "0px", paddingLeft: "0px", paddingRight: "0px"}}>
                                        <label
                                            htmlFor="example-text-input"
                                            className="col-md-1 col-form-label"
                                            >
                                            Sig name
                                        </label>
                                        <div className="col-md-11" style={{paddingRight: "0px"}}>
                                        <input
                                            className="form-control"
                                            type="text"
                                            name="sigName"
                                            id="sigName"
                                            onChange={(e) => {
                                                let _userSignature = cloneDeep(userSignature);
                                                _userSignature.sigName = e.target.value;
                                                dispatch(setSettingsReducerValue('USER_SIGNATURE', _userSignature));
                                            }}
                                            value={userSignature.sigName}
                                        />
                                        </div>
                                    </Row>
                                </Row>
                            </div>
                            <div className="modal-body">
                                {
                                props.isInitSigEditor && !modifiedSignatureFlag?
                                    <Editor
                                        apiKey='vp9wl3j35wpx6ktafg19n0tcbtatm5auwzy4s4r4m10t2c3w'
                                        onInit={(evt, editor) => {
                                            console.log('Sig editor initialized')
                                            props.setLoadedSigEditor(true);
                                            // console.log(userSignature.sigForm);
                                            editor.setContent(userSignature.sigForm, {format: 'html'});
                                            sigEditorRef.current = editor
                                        }}
                                        initialValue={userSignature.sigForm}
                                        init={{
                                            branding: false,
                                            height: 500,
                                            plugins: 'a11ychecker advcode casechange formatpainter linkchecker autolink lists checklist image link mediaembed pageembed permanentpen powerpaste table advtable tinycomments tinymcespellchecker',
                                            toolbar: 'undo redo styleselect bold italic alignleft aligncenter alignright alignjustify | bullist numlist outdent indent table',
                                            toolbar_mode: 'floating',
                                            tinycomments_mode: 'embedded',
                                            tinycomments_author: 'WeCrest',
                                            content_css: [
                                                '//fonts.googleapis.com/css?family=Lato:300,300i,400,400i',
                                                '//www.tiny.cloud/css/codepen.min.css'
                                            ],
                                            images_upload_url: '/uploadEmailFormImageFile.json',
                                            images_upload_credentials: true,
                                            relative_urls : false,
                                            remove_script_host : false,
                                            paste_auto_cleanup_on_paste : false,
                                            menu: {
                                                custom: { 
                                                    title: 'Email signature library', 
                                                    items: userSignatureMenuItems
                                                }
                                            },
                                            menubar: 'file edit view insert format tools table custom',
                                            setup: function(editor) {
                                                userSignatureList.map((userSignatureI, i) => {
                                                    // console.log('1. userSignature' + (i+1) + ' add Menu');
                                                    // console.log('userSignature' + (i+1) + ' sigName : ' + userSignatureI.sigName);
                                                    // console.log('userSignature' + (i+1) + ' sigForm : ' + userSignature.sigForm);
                                                    editor.ui.registry.addMenuItem('userSignature' + (i+1), {
                                                        text: userSignatureI.sigName,
                                                        onAction: function() {
                                                            // console.log('userSignature' + (i+1) + ' custom menu click');
                                                            let _userSignature = cloneDeep(userSignatureI);
                                                            _userSignature.seq = userSignature.seq;
                                                            dispatch(setSettingsReducerValue('USER_SIGNATURE', _userSignature));
                                                            editor.setContent(userSignatureI.sigForm, {format: 'html'});
                                                        }
                                                    });
                                                });
                                            },
                                        }}
                                    />
                                :
                                null
                                }
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-primary" 
                                    onClick={()=>{
                                        // console.log('userSignature.seq : ' + userSignature.seq);

                                        if (userSignature.orgSigName !== userSignature.sigName) {
                                            // console.log('modify sigName');
                                            dispatch(setSettingsReducerValue('MODIFIED_SIGNATURE_NAME_FLAG', true));
                                        }

                                        if (userSignature.seq === '-1') {
                                            dispatch(addUserSignature({'seq': userSignature.seq, 'sigName': userSignature.sigName, 'sigForm': sigEditorRef.current.getContent()}));
                                        } else {
                                            dispatch(updateUserSignature({'seq': userSignature.seq, 'sigName': userSignature.sigName, 'sigForm': sigEditorRef.current.getContent()}));
                                        }
                                      }}
                                >
                                    Save changes
                                </button>
                                <button
                                    type="button"
                                    className="btn btn-light"
                                    data-dismiss="modal"
                                    onClick={props.closeSigEditor}
                                >
                                Close
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-backdrop fade show"></div>
            </div>
        </div>
        {
        props.isOpenSigEditor && !props.loadedSigEditor?
            <LoadingModal loading={!props.loadedSigEditor}></LoadingModal>
        :
        null
        }
        {errorMsg !== '' ? (
        <SweetAlert
            onConfirm={() => {
            dispatch(setSettingsReducerValue('ERROR_MSG', ""));
            }}
            onCancel={() => {
            dispatch(setSettingsReducerValue('ERROR_MSG', ""));
            }}
        >{errorMsg}
        </SweetAlert>
        ) 
        : null}
        </>
    );
};

export default SignatureEditorModal;