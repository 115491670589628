import React, {useState, useEffect, useRef, useCallback} from 'react';
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";
import {
    Table,
    Row,
    Col,
    Card,
    CardBody,
    CardTitle,
    Media,
    Collapse,
    Form,
    Input,
    FormGroup,
    Button,
    FormFeedback,
  } from "reactstrap"
import LoadingModal from "../../components/Common/LoadingModal"
import SelectMultipleJurisdictions from '../../components/Filter/SelectMultipleJurisdictions';
import SearchName from '../../components/Filter/SearchName';
import PeriodBtn from '../../components/Filter/PeriodBtn';
import { Link } from "react-router-dom";
import * as Yup from "yup";
import { useFormik } from "formik";

import {
  getSpecificSearchList as onGetSpecificSearchList,
  downSpecificSearchExcel as onDownSpecificSearchExcel,
  downSpecificSearchPdf as onDownSpecificSearchPdf,
  changeSearchNameList as onChangeSearchNameList,
  changeSearchNameSelectedList as onChangeSearchNameSelectedList,
} from "../../store/actions";
import { cloneDeep } from 'lodash';
import dateFormat from 'dateformat';

const SpecificSearch = props => {
  const dispatch = useDispatch();

  const { yearIntervalList, monthIntervalList, crList, intervalCrMap, loading, noticeStr } = useSelector(state => ({
    yearIntervalList: state.SpecificSearch.yearIntervalList,
    monthIntervalList: state.SpecificSearch.monthIntervalList,
    crList: state.SpecificSearch.crList,
    intervalCrMap: state.SpecificSearch.intervalCrMap,
    loading: state.SpecificSearch.loading,
    noticeStr: state.SpecificSearch.noticeStr,
  }));

  const [isOpen, setIsOpen] = useState(true);

  //하늘색
  const color1 = "#dce9f8"; 
  //연두색
  const color2 = "#dcf8e2";

  /*
  useEffect(() => { 
    return () => {
      dispatch(onClearSpecificSearch());
    };
  }, []);
  */
 
  const toggle = () => {
    setIsOpen(!isOpen);
  }

  const filterValidation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    //enableReinitialize: true,

    initialValues: {
      inOutType: "IN",
      submitType: "view",
      searchNoList: [],
      searchNameList: [],
      searchKeyword: "",
      jurType: "",
      jrCdList: [],
      datePeriod: "",
      fromDate: undefined,
      toDate: undefined,
      screenGrade: "Gold",
      screenName: "SpecificSearch",
    },
    validationSchema: Yup.object().shape({
      fromDate: Yup.date()
        .max(Yup.ref('toDate'), "From date must be earlier than to date")
        .required("Please Select From date"),
      toDate: Yup.date()
        .max(new Date(), "Date cannot be in the future")
        .required("Please Select to date"),
    }),
    validateOnChange: false, // this one
    validateOnBlur: false, // and this one
    onSubmit: (values) => {
      //console.log(JSON.stringify(values, null, 2));
      
      let _fromDateStr = values["fromDate"].replace(/-/gi,"/");
      let _toDateStr = values["toDate"].replace(/-/gi,"/");
      let newEndDate = new Date(values["toDate"]);
      newEndDate.setDate(newEndDate.getDate() + 1);
      
      let period = Math.abs(newEndDate.getTime() - new Date(values["fromDate"]).getTime());
      period = Math.ceil(period / (1000 * 3600 * 24));
      
      let _periodstr = period +" day(s)";
      let _noticeStr = "Specific Search for period: "+_periodstr+" ["+_fromDateStr+" to "+_toDateStr+"]";
      let _timePeriod = _fromDateStr+" to "+_toDateStr+" ["+period +" day(s)]";
      const filterParam = {
        searchNoList: values["searchNoList"],
        searchNameList: values["searchNameList"],
        jurType: values["jurType"],
        jrCdList: values["jurType"] === "All"? [] : values["jrCdList"],
        startDate: values["fromDate"],
        endDate: values["toDate"],
        noticeStr: _noticeStr,
        timePeriod: _timePeriod,
      };
      
      let fileName = dateFormat(new Date(), "yymmdd") + " WeCrest Specific Search (" + values["fromDate"].replace(/-/gi,"") + "-" + values["toDate"].replace(/-/gi,"") + ")"; 

      let submitType = values["submitType"];
      
      if(submitType == "view"){
        dispatch(onGetSpecificSearchList(filterParam));
        
      }else if(submitType == "excel"){
        dispatch(onDownSpecificSearchExcel(filterParam, fileName));

      }else if(submitType == "pdf"){
        dispatch(onDownSpecificSearchPdf(filterParam, fileName));
      }
    },
  });

  useEffect(() => {
    return () => {
      dispatch(onChangeSearchNameList([], filterValidation.values.screenName));
      dispatch(onChangeSearchNameSelectedList([], filterValidation.values.screenName));
    };
  }, []);

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Card>
            <Link onClick={toggle} className="text-dark" to="#">
              <div className="p-4">
                <Media className="d-flex align-items-center">
                  <div className="flex-shrink-0 me-3">
                    <i className="uil uil-crosshair text-primary h2"></i>
                  </div>
                  <div className="flex-grow-1 overflow-hidden">
                    <h5 className="font-size-16 mb-1">Specific Search</h5>
                    <p className="text-muted mb-0">Use this tool to generate filing stats of a specific holder or representative of international<br/>
                      (Madrid) trademark applications.</p>
                  </div>
                  <i className="uil uil-filter font-size-24 text-muted"></i>
                  {/* 
                  <i className={isOpen ? "mdi mdi-chevron-up accor-down-icon font-size-24" : "mdi mdi-chevron-down accor-down-icon font-size-24"}></i>
                  */}
                </Media>
              </div>
            </Link>
            <Collapse isOpen={isOpen}>
              <div className="p-4 border-top">
                <Form onSubmit={filterValidation.handleSubmit}>
                  <div>

                    <SearchName
                      formik={filterValidation}
                    />

                    <SelectMultipleJurisdictions
                        formik={filterValidation}
                      />

                    <Row>
                      <label htmlFor="inscribedDate" className="col-md-3 col-form-label">
                      Inscription date
                      </label>
                      <div className="col-md-9">
                        <PeriodBtn
                          formik={filterValidation}
                        />
                      </div>
                    </Row>
                    
                    <Row className="mb-3">
                      <span className="col-md-6 button-items">
                        <Button
                          type="button"
                          color="primary"
                          outline
                          className="waves-effect waves-light"
                          onClick={()=>{
                            let _values = cloneDeep(filterValidation.values);
                            _values.submitType = "excel";
                            filterValidation.setValues(_values);
                            filterValidation.handleSubmit(filterValidation.values);
                          }}
                        >
                          Download Excel
                        </Button>
                        <Button
                          type="button"
                          color="primary"
                          outline
                          className="waves-effect waves-light"
                          onClick={()=>{
                            let _values = cloneDeep(filterValidation.values);
                            _values.submitType = "pdf";
                            filterValidation.setValues(_values);
                            filterValidation.handleSubmit(filterValidation.values);
                          }}
                        >
                          Download PDF
                        </Button>
                      </span>
                      <Col md={3} style={{paddingLeft : '1.3em'}}>
                        <button
                          type="button"
                          className="waves-effect waves-light btn btn-primary"
                          style={{width : '148.58px'}}
                          onClick={()=>{
                            let _values = cloneDeep(filterValidation.values);
                            _values.submitType = "view";
                            filterValidation.setValues(_values);
                            filterValidation.handleSubmit(filterValidation.values);
                          }}
                        >
                          View statistics
                        </button>
                      </Col>
                    </Row>
                  </div>
                </Form>
              </div>
            </Collapse>
          </Card>
        {
          loading ?
          <LoadingModal loading={loading}></LoadingModal>
          : null
        }
        
          {noticeStr && noticeStr !== '' ?
          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                    <CardTitle className="text-muted mb-0">
                      {yearIntervalList && monthIntervalList && yearIntervalList.length && monthIntervalList.length?
                      <p style={{whiteSpace : "pre"}}>
                        {noticeStr}
                      </p>
                      :
                      <span>
                        {noticeStr}
                      </span>
                      }
                    </CardTitle>
                    {yearIntervalList && monthIntervalList && yearIntervalList.length && monthIntervalList.length?
                    <div className="table-responsive">
                      <Table className="table mb-0">
                      {
                          (()=> {
                              if (yearIntervalList && monthIntervalList && yearIntervalList.length && monthIntervalList.length) {
                                let lastBackgroundColor = color1;
                                let _yearIntervalList = yearIntervalList.map((yearInterval, status1) => {
                                  let colspanVal = 0;
                                  monthIntervalList.map((monthInterval, status2) => {
                                    if(monthInterval.indexOf(yearInterval) > -1){
                                      colspanVal++;
                                    }
                                  });
                                  
                                  if((status1+1)%2 == 0){
                                    lastBackgroundColor = color2;
                                  }else{
                                    lastBackgroundColor = color1;
                                  }

                                  return (
                                    <th colspan={colspanVal} 
                                        ref={element => { 
                                          if (element) element.style.setProperty('background-color', lastBackgroundColor, 'important'); 
                                        }}> {yearInterval}</th>
                                  )
                                });

                                let _monthIntervalList = monthIntervalList.map((monthInterval, status1) => {
                                  let backgroundColor = color1;
                                  yearIntervalList.map((yearInterval, status2) => {
                                    if(monthInterval.indexOf(yearInterval) > -1 && (status2+1)%2 == 0){
                                      backgroundColor = color2;
                                    }
                                  });

                                  return(
                                    <th ref={element => { 
                                          if (element) {
                                            element.style.setProperty('background-color', backgroundColor, 'important'); 
                                          }
                                        }}>
                                      {monthInterval.indexOf("-01") > -1? "Jan" : null}
                                      {monthInterval.indexOf("-02") > -1? "Feb" : null}
                                      {monthInterval.indexOf("-03") > -1? "Mar" : null}
                                      {monthInterval.indexOf("-04") > -1? "Apr" : null}
                                      {monthInterval.indexOf("-05") > -1? "May" : null}
                                      {monthInterval.indexOf("-06") > -1? "Jun" : null}
                                      {monthInterval.indexOf("-07") > -1? "Jul" : null}
                                      {monthInterval.indexOf("-08") > -1? "Aug" : null}
                                      {monthInterval.indexOf("-09") > -1? "Sep" : null}
                                      {monthInterval.indexOf("-10") > -1? "Oct" : null}
                                      {monthInterval.indexOf("-11") > -1? "Nov" : null}
                                      {monthInterval.indexOf("-12") > -1? "Dec" : null}
                                    </th>
                                  )

                                });

                                return (
                                    <thead>
                                      <tr>
                                        <th scope="col" rowspan="2" ref={element => { 
                                          if (element) {
                                            element.style.setProperty('background-color', color2, 'important'); 
                                            element.style.setProperty('width', "10%", 'important'); 
                                          }
                                        }}></th>
                                        {_yearIntervalList}
                                        <th scope="col" rowspan="2" 
                                        ref={element => { 
                                          if (element) element.style.setProperty('background-color', lastBackgroundColor == color1? color2 : color1, 'important'); 
                                        }}
                                        >Total</th>
                                      </tr>
                                      <tr>{_monthIntervalList}</tr>
                                    </thead>
                                )
                              }
                          })()
                      }
                      {
                          (()=> {
                              if (crList && crList.length) {
                                let _crList = crList.map((crCdName, status) => {
                                  let _monthIntervalList = [];
                                  if(monthIntervalList && monthIntervalList.length){
                                    _monthIntervalList = monthIntervalList.map((interval, status2, monthIntervalList) => {
                                      let mapKey = crCdName +" "+interval;
                                      //last one일 때
                                      if(status2 + 1 === monthIntervalList.length){
                                        return (
                                          <>
                                          <td>{!intervalCrMap[mapKey] || intervalCrMap[mapKey] == "" ? "0" 
                                                  :  intervalCrMap[mapKey] == -999 ? "•" : intervalCrMap[mapKey]} </td>
                                          <td>{!intervalCrMap[crCdName+" Total"] || intervalCrMap[crCdName+" Total"] == "" ? "0" 
                                                  :  intervalCrMap[crCdName+" Total"] == -999 ? "•" : intervalCrMap[crCdName+" Total"]} </td>
                                          </>
                                          )
                                      }else{
                                        return (
                                          <td>{!intervalCrMap[mapKey] || intervalCrMap[mapKey] == "" ? "0" 
                                                  :  intervalCrMap[mapKey] == -999 ? "•" : intervalCrMap[mapKey]} </td>
                                          )
                                      }
                                    });
                                  }

                                  return(
                                    <tr>
                                      <td>{crCdName}</td>
                                      {_monthIntervalList}
                                    </tr>
                                  )
                                });

                                return (
                                  <tbody>
                                    {_crList}
                                  </tbody>
                                )
                              }
                          })()
                      }
                      </Table>
                    </div>
                    :null }
                </CardBody>
              </Card>
            </Col>
          </Row>
          :null }
        </div>
      </div>
    </React.Fragment>
  )
}

/*
UserList.propTypes = {
  users: PropTypes.array,
  loading: PropTypes.bool,
  onGetUserList: PropTypes.func,
};
*/

export default SpecificSearch;