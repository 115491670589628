import React, {useState} from 'react';
import { useSelector, useDispatch } from "react-redux";
import {
    TabContent,
    TabPane,
    NavLink,
    NavItem,
    Nav,
  } from "reactstrap"

import classnames from "classnames"
import SweetAlert from "react-bootstrap-sweetalert";
import LoadingModal from "../../components/Common/LoadingModal"
import FilerSendingAnalyticsLog from './FilerSendingAnalyticsLog';
import FilerSendingAnalyticsStatistics from './FilerSendingAnalyticsStatistics';
import '../../components/Common/Common.css';

import {
  setSendingAnalyticsSuccess,
  setSendingAnalyticsError,
  setSendingAnalyticsLoading as onSetSendingAnalyticsLoading,
} from "../../store/actions";

const FilerSendingAnalytics = props => {
  const dispatch = useDispatch();
  const { loading, error, success,} = useSelector(state => ({
    loading: state.SendingAnalytics.loading,
    error: state.SendingAnalytics.error,
    success: state.SendingAnalytics.success,
  }));

  const [activeTab, setactiveTab] = useState("Statistics")
  function toggleActiveTab(tab) {
    if (activeTab !== tab) {
      setactiveTab(tab)
    }
  }

  const setSendingAnalyticsLoading = () => {
    dispatch(onSetSendingAnalyticsLoading(!loading))
  }


  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Nav tabs className="col-md-6 nav-tabs-custom nav-justified">
            <NavItem>
              <NavLink
                style={{ cursor: "pointer" }}
                className={classnames({
                  active: activeTab === "Statistics",
                })}
                onClick={() => {
                  toggleActiveTab("Statistics")
                }}
              >
                <span>Statistics</span>
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                style={{ cursor: "pointer" }}
                className={classnames({
                  active: activeTab === "Log",
                })}
                onClick={() => {
                  toggleActiveTab("Log")
                }}
              >
                <span>Log</span>
              </NavLink>
            </NavItem>
          </Nav>
          <TabContent
            activeTab={activeTab}
            className="text-muted"
          >
            <TabPane tabId="Statistics">
              <FilerSendingAnalyticsStatistics/>
            </TabPane>
            <TabPane tabId="Log">
              <FilerSendingAnalyticsLog />
            </TabPane>
          </TabContent>

          {
            loading ?
            <LoadingModal loading={loading} setLoading={setSendingAnalyticsLoading}></LoadingModal>
            : null
          }

          {
            success ? 
            <SweetAlert
              title={success}
              timeout={2000}
              showCloseButton={false}
              showConfirm={false}
              success
              onConfirm={() => {
                dispatch(setSendingAnalyticsSuccess(""));
              }}
            ></SweetAlert>
            : null
          }

          {
            error ?
            <SweetAlert
              warning
              title={error}
              onConfirm={() => {
                dispatch(setSendingAnalyticsError(""));
              }}
            >
            </SweetAlert>
            : null
          }
        </div>
      </div>
    </React.Fragment>
  )
}

export default FilerSendingAnalytics;