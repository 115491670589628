import {
  GET_MULTIPLE_COUNTRY_LIST,
  GET_MULTIPLE_COUNTRY_LIST_SUCCESS,
  CHANGE_MULTIPLE_COUNTRY_LIST,
} from "./actionTypes"

export const getMultipleCountryList = (inOutType, screenGrade, filterName) => ({
    type: GET_MULTIPLE_COUNTRY_LIST,
    payload: {inOutType, screenGrade, filterName},
})

export const getMultipleCountryListSuccess = (data, filterName) => {
  return {
    type: GET_MULTIPLE_COUNTRY_LIST_SUCCESS,
    payload: {data, filterName},
  }
}

export const changeMultipleCountryList = (multipleCountryList, filterName) => {
  return {
    type: CHANGE_MULTIPLE_COUNTRY_LIST,
    payload: {multipleCountryList, filterName},
  }
}