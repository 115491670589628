import {
  REGISTER_USER,
  REGISTER_USER_SUCCESSFUL,
  REGISTER_USER_FAILED,
  CLOSE_REGISTER_USER_SUCCESSFUL_MODAL,
  GET_HOME_COUNTRY_LIST,
  GET_HOME_COUNTRY_LIST_SUCCESS,
  CHANGE_HOME_COUNTRY_LIST,
  SET_REGISTER_LOADING,
} from "./actionTypes"

const initialState = {
  registrationError: null,
  message: null,
  loading: false,
  registrationFlag: false,
  registerUserSuccessfulModalOpen: false,
  homeCountryList: [],
}

const account = (state = initialState, action) => {
  switch (action.type) {
    case REGISTER_USER:
      state = {
        ...state,
        loading: true,
        registrationError: null,
      }
      break
    case REGISTER_USER_SUCCESSFUL:
      state = {
        ...state,
        loading: false,
        registrationFlag: true,
        registrationError: null,
        registerUserSuccessfulModalOpen: true,
      }
      break
    case REGISTER_USER_FAILED:
      // console.log("REGISTER_USER_FAILED fail : ", action.payload)
      state = {
        ...state,
        loading: false,
        registrationError: action.payload,
      }
      break
    case CLOSE_REGISTER_USER_SUCCESSFUL_MODAL:
      state = {
        ...state,
        registerUserSuccessfulModalOpen: false,
      }
      break
    case GET_HOME_COUNTRY_LIST:
      return {
        ...state,
      }
      break
    case GET_HOME_COUNTRY_LIST_SUCCESS:
      return {
        ...state,
        homeCountryList: action.payload
      }
      break
    case CHANGE_HOME_COUNTRY_LIST:
      return {
        ...state,
        homeCountryList: action.payload
      }
      break
    case SET_REGISTER_LOADING:
      return {
        ...state,
        loading: action.payload,
      }
      break
    default:
      state = { ...state }
      break
  }
  return state
}

export default account
