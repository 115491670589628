import axios from "axios"
import { post, postRawData, del, get, put, downExcel, downPdf, downZip } from "./api_helper"
import * as url from "./url_helper"

// WeCrest Login
const postWeCrestLogin = data => {
  return axios
    .post(url.WECREST_LOGIN, data)
    .then(response => {
      if (response.status >= 200 || response.status <= 299) {
        if (response.data.result === 'Fail') {
          //console.log('1 > ' + response.data.result)
          //console.log('1 > ' + response.data.message)
          throw response.data;
        } else {
          return response.data;
        }
      }
      throw response.data
    })
    .catch(err => {
      let message
      if (err.response && err.response.status) {
        switch (err.response.status) {
          case 404:
            message = "Sorry! the page you are looking for could not be found"
            break
          case 500:
            message =
              "Sorry! something went wrong, please contact our support team"
            break
          case 401:
            message = "Invalid credentials"
            break
          default:
            message = err[1]
            break
        }
      } else {
        //console.log('2 > ' + err.result)
        //console.log('2 > ' + err.message)
        message = err.message
      }
      throw message
    })
}
  
// WeCrest Logout
const postWeCrestLogout = data => post(url.WECREST_LOGOUT, data)

// WeCrest register
const postWeCrestRegist = data => {
  return axios
    .post(url.POST_WECREST_REGISTER, data)
    .then(response => {
      if (response.status >= 200 || response.status <= 299) {
        if (response.data.result === 'Fail') {
          throw response.data;
        } else {
          return response.data.result;
        }
      }
      throw response.data
    })
    .catch(err => {
      let message
      if (err.response && err.response.status) {
        switch (err.response.status) {
          case 404:
            message = "Sorry! the page you are looking for could not be found"
            break
          case 500:
            message = "Sorry! something went wrong, please contact our support team"
            break
          case 401:
            message = "Invalid credentials"
            break
          default:
            message = err[1]
            break
        }
      } else {
        // console.log('2 > ' + err.result)
        // console.log('2 > ' + err.message)
        message =err.message
      } 

      // message = 'error message 123'
      // console.log('2 > ' + message)
      throw message
    })
}

const getHomeCountryList = () => get(url.GET_HOME_COUNTRY_LIST)

const checkCookieAgree = () => get(url.CHECK_COOKIE_AGREE)
const updateCookieAgree = () => get(url.UPDATE_COOKIE_AGREE)

// WeCrest Forget password
const forgetPassword = data => post(url.FORGET_PASSWORD, data)
const resetPassword = data => post(url.RESET_PASSWORD, data)

// WeCrest user list
const getWeCrestUsers = () => get(url.GET_USER_LIST)

// Dashboard
const getDashboardCardInfo = () => get(url.GET_DASHBOARD_CARD_INFO)
const sendFeedback = data => post(url.SEND_FEEDBACK, data)
const getWeCrestInfo = () => get(url.GET_WECREST_INFO)

// get Jurisdictions List
const getAllJurisdictionsList = data => post(url.GET_ALL_JURISDICTIONS_LIST, data)
const getJurisdictionsList = data => post(url.GET_JURISDICTIONS_LIST, data)
const getMultipleJurisdictionsList = data => post(url.GET_MULTIPLE_JURISDICTIONS_LIST, data)
const getCountryList = data => post(url.GET_COUNTRY_LIST, data)
const getAllCountryList = data => post(url.GET_ALL_COUNTRY_LIST, data)
const getMultipleCountryList = data => post(url.GET_MULTIPLE_COUNTRY_LIST, data)
const getSearchNameList = data => post(url.GET_SEARCH_NAME_LIST, data)
const getSearchTargetGroupList = data => post(url.GET_SEARCH_TARGET_GROUP_LIST, data)

// get General Statistics List
const getGeneralStatisticsList = data => post(url.GET_GENERALSTATISTICS_LIST, data)
const downStatisticsExcel = data => downExcel(url.DOWN_STATISTICS_EXCEL, data.data, data.fileName)
const downStatisticsPdf = data => downPdf(url.DOWN_STATISTICS_PDF, data.data, data.fileName)

// get Country Rankings List
const getCountryRankingsList = data => post(url.GET_COUNTRY_RANKINGS_LIST, data)
const downCountryRankingsExcel = data => downExcel(url.DOWN_COUNTRY_RANKINGS_EXCEL, data.data, data.fileName)
const downCountryRankingsPdf = data => downPdf(url.DOWN_COUNTRY_RANKINGS_PDF, data.data, data.fileName)

// get Specific Search List
const getSpecificSearchList = data => post(url.GET_SPECIFIC_SEARCH_LIST, data)
const downSpecificSearchExcel = data => downExcel(url.DOWN_SPECIFIC_SEARCH_EXCEL, data.data, data.fileName)
const downSpecificSearchPdf = data => downPdf(url.DOWN_SPECIFIC_SEARCH_PDF, data.data, data.fileName)

// get Email Templates
const getEmailTemplates = data => post(url.GET_EMAIL_TEMPLATES, data)
const deleteEmailTemplate = data => post(url.DELETE_EMAIL_TEMPLATE, data)

// get Email Form
const getEmailForm = data => post(url.GET_EMAIL_FORM, data)

// update Email Form
const updateEmailForm = formData => postRawData(url.UPDATE_EMAIL_FORM, formData)

// delete Email Form
const deleteEmailForm = data => post(url.DELETE_EMAIL_FORM, data)

// filer rankings
const getFilerSettings = () => get(url.GET_FILER_SETTINGS)
const saveFilerSettings = data => post(url.SAVE_FILER_SETTINGS, data)
const getFilerRankings = data => post(url.GET_FILER_RANKINGS, data)
const getFilerRankingInfo = data => post(url.GET_FILER_RANKING_INFO, data)
const multipleHideFiler = data => postRawData(url.MULTIPLE_HIDE_FILER, data)
const multipleUnhideFiler = data => postRawData(url.MULTIPLE_UNHIDE_FILER, data)
const downFilerExcel = data => downExcel(url.DOWN_FILER_EXCEL, data.data, data.fileName)
const downMultipleFilerExcel = data => downExcel(url.DOWN_MULTIPLE_FILER_EXCEL, data, data.fileName)
const downFilerPdf = data => downPdf(url.DOWN_FILER_PDF, data.data, data.fileName)
const downFilerPdfDataOnly = data => downPdf(url.DOWN_FILER_PDF_DATA_ONLY, data.data, data.fileName)
const downMultipleFilerPdf = data => downPdf(url.DOWN_MULTIPLE_FILER_PDF, data.rankingList, data.fileName)
const filerSendEmail = data => postRawData(url.FILER_SEND_EMAIL, data)
const filerPreviewEmail = data => postRawData(url.FILER_PREVIEW_EMAIL, data)

// settings
const getAccountInfo = () => get(url.GET_ACCOUNT_INFO)
const updateAccountInfo = data => postRawData(url.UPDATE_ACCOUNT_INFO, data)
const updateNewDashboard = data => post(url.UPDATE_NEW_DASHBOARD, data)
const updateAccountPassword = data => postRawData(url.UPDATE_ACCOUNT_PASSWORD, data)
const updateAccountInvoiceInfo = data => postRawData(url.UPDATE_ACCOUNT_INVOICE_INFO, data)
const updateAccountUnsubscribeInfo = data => postRawData(url.UPDATE_ACCOUNT_UNSUBSCRIBE_INFO, data)
const updateAccountUpToDateInfo = data => postRawData(url.UPDATE_ACCOUNT_UPTODATE_INFO, data)
const updateSenderEmail = data => post(url.UPDATE_SENDER_EMAIL, data)
const updateSubscriptionEmailTemplate = data => postRawData(url.UPDATE_SUBSCRIPTION_EMAIL_TEMPLATE, data)
const updateFilerEmailTemplate = data => post(url.UPDATE_FILER_EMAIL_TEMPLATE, data)
const getUserSignatureSavePopupAppearInfo = () => get(url.GET_USER_SIGNATURE_SAVE_POPUP_APPEAR_INFO)
const addUserSignatureSavePopupAppearLog = data => post(url.ADD_USER_SIGNATURE_SAVE_POPUP_APPEAR_LOG, data)
const getUserSignatureInfo = () => get(url.GET_USER_SIGNATURE_INFO)
const updateUserSignatureInfo = data => postRawData(url.UPDATE_USER_SIGNATURE_INFO, data)

const getEmailingSettings = () => get(url.GET_EMAILING_SETTINGS)
const addSenderEmail = data => post(url.ADD_SENDER_EMAIL, data)
const deleteSenderEmail = data => post(url.DELETE_SENDER_EMAIL, data)
const updateDisplayName = data => post(url.UPDATE_DISPLAY_NAME, data)
const updateSenderEmailAddress = data => post(url.UPDATE_SENDER_EMAIL_ADDRESS, data)
const getUserSignature = data => post(url.GET_USER_SIGNATURE, data)
const addUserSignature = data => post(url.ADD_USER_SIGNATURE, data)
const updateUserSignature = data => post(url.UPDATE_USER_SIGNATURE, data)
const deleteUserSignature = data => post(url.DELETE_USER_SIGNATURE, data)
const addUserJurisdictionEmailSetting = data => postRawData(url.ADD_USER_JURISDICTION_EMAIL_SETTING, data)
const updateUserJurisdictionEmailSetting = data => post(url.UPDATE_USER_JURISDICTION_EMAIL_SETTING, data)
const deleteUserJurisdictionEmailSetting = data => post(url.DELETE_USER_JURISDICTION_EMAIL_SETTING, data)

const getOcmnShowStartup = () => get(url.GET_OCMN_SHOW_STARTUP)

const addUserInvoiceInfo = data => post(url.ADD_USER_INVOICE_INFO, data)
const modifyUserInvoiceInfo = data => post(url.MODIFY_USER_INVOICE_INFO, data)
const removeUserInvoiceInfo = data => post(url.REMOVE_USER_INVOICE_INFO, data)
const getUserSubscriptionList = data => post(url.GET_USER_SUBSCRIPTION_LIST, data)
const modifyUserSubscriptionInvoiceInfo = data => post(url.MODIFY_USER_SUBSCRIPTION_INVOICE_INFO, data)

// madrid notifier
const getMadridSettings = () => get(url.GET_MADRID_SETTINGS)
const saveMadridSettings = data => post(url.SAVE_MADRID_SETTINGS, data)
const getMadridNotifier = data => post(url.GET_MADRID_NOTIFIER, data)
const getMadridNotifierInfo = data => post(url.GET_MADRID_NOTIFIER_INFO, data)
const multipleHideMadrid = data => postRawData(url.MULTIPLE_HIDE_MADRID, data)
const multipleUnhideMadrid = data => postRawData(url.MULTIPLE_UNHIDE_MADRID, data)
const multipleStarMadrid = data => postRawData(url.MULTIPLE_STAR_MADRID, data)
const multipleUnstarMadrid = data => postRawData(url.MULTIPLE_UNSTAR_MADRID, data)
const downMadridExcel = data => downExcel(url.DOWN_MADRID_EXCEL, data.data, data.fileName)
const downMultipleMadridExcel = data => downExcel(url.DOWN_MULTIPLE_MADRID_EXCEL, data, data.fileName)
const downMadridPdf = data => downPdf(url.DOWN_MADRID_PDF, data.data, data.fileName)
const downMultipleMadridPdf = data => downPdf(url.DOWN_MULTIPLE_MADRID_PDF, data.data, data.fileName)
const sendMadridEmail = data => postRawData(url.SEND_MADRID_EMAIL, data)
const previewMadridEmail = data => postRawData(url.PREVIEW_MADRID_EMAIL, data)
const getOCMNList = data => post(url.GET_OCMN_LIST, data)
const saveOCMNShowStartup = data => post(url.SAVE_OCMN_SHOW_STARTUP, data)
const saveOCMNAutoSendYn = data => post(url.SAVE_OCMN_AUTO_SEND_YN, data)
const saveOCMNSetting = data => post(url.SAVE_OCMN_SETTING, data)
const getOCMNSuggestedContacts = data => post(url.GET_OCMN_SUGGESTED_CONTACTS, data)
const sendOCMNEmail = data => postRawData(url.SEND_OCMN_EMAIL, data)
const addOcmnLog = data => post(url.ADD_OCMN_LOG, data)

// Unlock
const getUserUnlockInfo = () => get(url.GET_USER_UNLOCK_INFO)
const unlockMadrid = data => postRawData(url.UNLOCK_MADRID, data)
const unlockFiler = data => postRawData(url.UNLOCK_FILER, data)

//Contacts
const getContactsList = data => post(url.GET_CONTACTS_LIST, data)

const multipleStarContacts = data => postRawData(url.MULTIPLE_STAR_CONTACTS, data)
const multipleUnstarContacts = data => postRawData(url.MULTIPLE_UNSTAR_CONTACTS, data)
const multipleHideContacts = data => postRawData(url.MULTIPLE_HIDE_CONTACTS, data)
const multipleUnhideContacts = data => postRawData(url.MULTIPLE_UNHIDE_CONTACTS, data)
const multipleUnsubscribeContacts = data => postRawData(url.MULTIPLE_UNSUBSCRIBE_CONTACTS, data)
const checkEmailUnsubscribe = data => postRawData(url.CHECK_EMAIL_UNSUBSCRIBE, data)
const saveContacts = data => postRawData(url.SAVE_CONTACTS, data)
const saveContactsSimple = data => postRawData(url.SAVE_CONTACTS_SIMPLE, data)
const deleteContacts = data => postRawData(url.DELETE_CONTACTS, data)
const getImportedContacts = () => get(url.GET_IMPORTED_CONTACTS)
const getSuggestedContacts = data => post(url.GET_SUGGESTED_CONTACTS, data)
const getContactsDetails = data => post(url.GET_CONTACTS_DETAILS, data)
const getContactsDetailList = data => post(url.GET_CONTACTS_DETAIL_LIST, data)
const getContactsMemo = data => post(url.GET_CONTACTS_MEMO, data)
const saveMemo = data => post(url.SAVE_MEMO, data)
const saveContactsSettings = data => post(url.SAVE_CONTACTS_SETTINGS, data)
const getContactsSettings = () => get(url.GET_CONTACTS_SETTINGS)
const downContactsExcel = data => downExcel(url.DOWN_CONTACTS_EXCEL, data.data, data.fileName)

const getUploadedContactsList = data => post(url.GET_UPLOADED_CONTACTS_LIST, data)
const uploadContacts = formData => postRawData(url.UPLOAD_CONTACTS, formData)
const saveUploadedContacts = data => postRawData(url.SAVE_UPLOADED_CONTACTS, data)
const deleteUploadedContacts = data => postRawData(url.DELETE_UPLOADED_CONTACTS, data)
const downUploadedContactsExcel = data => downExcel(url.DOWN_UPLOADED_CONTACTS_EXCEL, data, "uploaded contacts")

const getDomainCorrectYnCount = data => post(url.GET_DOMAIN_CORRECT_YN_COUNT, data)
const saveDomainCorrectYn = data => post(url.SAVE_DOMAIN_CORRECT_YN, data)

const getContactSearchList = data => post(url.GET_CONTACT_SEARCH_LIST, data)
const downSearchEntityExcel = data => downExcel(url.DOWN_SEARCH_ENTITY_EXCEL, data.data, data.fileName)

// SENDING ANALYTICS
const getSendingAnalyticsMnSettings = data => post(url.GET_SENDING_ANALYTICS_MN_SETTINGS, data)
const saveSendingAnalyticsMnSettings = data => post(url.SAVE_SENDING_ANALYTICS_MN_SETTINGS, data)
const getSendingAnalyticsMnList = data => post(url.GET_SENDING_ANALYTICS_MN_LIST, data)
const downSendingAnalyticsMnExcel = data => downExcel(url.DOWN_SENDING_ANALYTICS_MN_EXCEL, data.data, data.fileName)
const getSendingAnalyticsMn = data => post(url.GET_SENDING_ANALYTICS_MN, data)
const getSendingAnalyticsFrSettings = data => post(url.GET_SENDING_ANALYTICS_FR_SETTINGS, data)
const saveSendingAnalyticsFrSettings = data => post(url.SAVE_SENDING_ANALYTICS_FR_SETTINGS, data)
const getSendingAnalyticsFrList = data => post(url.GET_SENDING_ANALYTICS_FR_LIST, data)
const downSendingAnalyticsFrExcel = data => downExcel(url.DOWN_SENDING_ANALYTICS_FR_EXCEL, data.data, data.fileName)
const getMadridSendingAnalyticsStatistics = data => post(url.GET_MADRID_SENDING_ANALYTICS_STATISTICS, data)
const getFilerSendingAnalyticsStatistics = data => post(url.GET_FILER_SENDING_ANALYTICS_STATISTICS, data)

// ADMIN DOMAIN MANAGE
const getDomainList = data => post(url.GET_DOMAIN_LIST, data)
const getDomainActionHistory = data => post(url.GET_DOMAIN_ACTION_HISTORY, data)
const saveDomainInfo = data => postRawData(url.SAVE_DOMAIN_INFO, data)
const getBlackList = data => post(url.GET_BLACK_LIST, data)
const addBlackList = data => post(url.ADD_BLACK_DOMAIN, data)
const downExcelBlackList = data => downExcel(url.DOWN_EXCEL_BLACK_LIST, data.data, data.fileName)
const getEntityDetail = data => post(url.GET_ENTITY_DETAIL, data)
const saveEntityEmails = data => postRawData(url.SAVE_ENTITY_EMAILS, data)
const deleteEntityEmails = data => postRawData(url.DELETE_ENTITY_EMAILS, data)

// MEMBERSHIP PLAN
const getJurisdictionsMonthlyPricing = data => post(url.GET_JURISDICTIONS_MONTHLY_PRICING, data)
const getUserMembershipInfo = () => get(url.GET_USER_MEMBERSHIP_INFO)
const updateSubscriptionToFree = () => post(url.UPDATE_SUBSCRIPTION_TO_FREE)
const updateGoldMembershipPlan = data => postRawData(url.UPDATE_GOLD_MEMBERSHIP_PLAN, data)
const getPaypalPlanId = data => postRawData(url.GET_PAYPAL_PLAN_ID, data)

// SERVICE MANUAL
const getServiceManualList = data => post(url.GET_SERVICE_MANUAL_LIST, data)
const getServiceManualInfo = data => post(url.GET_SERVICE_MANUAL_INFO, data)
const updateServiceManual = data => postRawData(url.UPDATE_SERVICE_MANUAL_LIST, data)
const updateServiceManualViewCnt = data => post(url.UPDATE_SERVICE_MANUAL_VIEW_CNT, data)
const updateServiceManualPriority = data => postRawData(url.UPDATE_SERVICE_MANUAL_PRIORITY, data)
const updateServiceManualPDF = data => postRawData(url.UPDATE_SERVICE_MANUAL_PDF, data)
const previewServiceManual = data => postRawData(url.PREVIEW_SERVICE_MANUAL, data)
const getServiceManualPageNameList = () => get(url.GET_SERVICE_MANUAL_PAGE_NAMELIST)

// ADMIN PRICING MANAGEMENT
const getSubscriptionItemList = data => post(url.GET_SUBSCRIPTION_ITEM_LIST, data)
const saveSubscriptionItem = data => postRawData(url.SAVE_SUBSCRIPTION_ITEM, data)
const deleteSubscriptionItem = data => postRawData(url.DELETE_SUBSCRIPTION_ITEM, data)

// ADMIN INVOICE MANAGEMENT
const getAdmInvoiceManagementSearchList = data => post(url.GET_ADM_INVOICE_MANAGEMENT_SEARCH_LIST, data)
const downInvoicePdf = data => downZip(url.DOWN_INVOICE_PDF, data.data, data.fileName)
const updateInvoiceStatus = data => post(url.UPDATE_INVOICE_STATUS, data)
const confirmPayment = data => post(url.CONFIRM_PAYMENT, data)
const getSearchUserList = data => post(url.GET_SEARCH_USER_LIST, data)
const getSubscriptionItemAllList = data => post(url.GET_SUBSCRIPTION_ITEM_ALL_LIST, data)
const getCresterList = data => post(url.GET_CRESTER_LIST, data)
const createAdminInvoice = formData => postRawData(url.CREATE_ADMIN_INVOICE, formData)
const getUserInvoiceInfoList = data => post(url.GET_USER_INVOICE_INFO_LIST, data)
const getInvoiceItemInfo = data => post(url.GET_INVOICE_ITEM_INFO, data)

// COMMUNITY
const getCommunityList = data => post(url.GET_COMMUNITY_LIST, data)
const getCommunityDetail = data => post(url.GET_COMMUNITY_DETAIL, data)
const createCommunity = formData => postRawData(url.CREATE_COMMUNITY, formData)
const modifyCommunity = data => postRawData(url.MODIFY_COMMUNITY, data)
const removeCommunity = data => post(url.REMOVE_COMMUNITY, data)
const getCommunityAttachFileList = data => post(url.GET_COMMUNITY_ATTACH_FILE_LIST, data)
const addCommunityAttachFile = formData => postRawData(url.ADD_COMMUNITY_ATTACH_FILE, formData)
const removeCommunityAttachFile = data => post(url.REMOVE_COMMUNITY_ATTACH_FILE, data)
const getCommunityReplyList = data => post(url.GET_COMMUNITY_REPLY_LIST, data)
const createCommunityReply = data => post(url.CREATE_COMMUNITY_REPLY, data)
const modifyCommunityReply = data => post(url.MODIFY_COMMUNITY_REPLY, data)
const removeCommunityReply = data => post(url.REMOVE_COMMUNITY_REPLY, data)

// ENTITY CONNECT MANAGEMENT
const getConnectableEntityList = data => post(url.GET_CONNECTABLE_ENTITY_LIST, data)
const getMyEntityList = data => post(url.GET_MY_ENTITY_LIST, data)
const getMainDomain = () => get(url.GET_MAIN_DOMAIN)
const getSearchEntityList =  data => post(url.GET_SEARCH_ENTITY_LIST, data)
const getUserEntityConnectList = data => post(url.GET_USER_ENTITY_CONNECT_LIST, data)
const downExcelUserEntityConnectList = data => downExcel(url.DOWN_EXCEL_USER_ENTITY_CONNECT_LIST, data.data, data.fileName)
const confirmUserEntityConnect = data => post(url.CONFIRM_USER_ENTITY_CONNECT, data)
const disConnectUserEntity = data => post(url.DISCONNECT_USER_ENTITY, data)
const getUserEntityConnectClaimList = data => post(url.GET_USER_ENTITY_CONNECT_CLAIM_LIST, data)
const downExcelUserEntityConnectClaimList = data => downExcel(url.DOWN_EXCEL_USER_ENTITY_CONNECT_CLAIM_LIST, data.data, data.fileName)
const addUserEntityConnectClaim = data => postRawData(url.ADD_USER_ENTITY_CONNECT_CLAIM, data)
const modifyUserEntityConnectClaimStatus = data => postRawData(url.MODIFY_USER_ENTITY_CONNECT_CLAIM_STATUS, data)
const confirmUserEntityConnectClaim = data => postRawData(url.CONFIRM_USER_ENTITY_CONNECT_CLAIM, data)
const getRequester = data => post(url.GET_REQUESTER_LIST, data)
const getUserDomainList = () => get(url.GET_USER_DOMAIN_LIST)
const addUserDomain = data => postRawData(url.ADD_USER_DOMAIN, data)
const modifyUserDomain = data => postRawData(url.MODIFY_USER_DOMAIN, data)
const removeUserDomain = data => postRawData(url.REMOVE_USER_DOMAIN, data)

// ADMIN ACCOUNT MANAGEMENT
const getAdmAccountManagementSearchList = data => post(url.GET_ADM_ACCOUNT_MANAGEMENT_SEARCH_LIST, data)
const downExcelAdmAccountManagement = data => downExcel(url.DOWN_EXCEL_ADM_ACCOUNT_MANAGEMENT, data.data, data.fileName)
const updateAccountByAdmin = data => post(url.UPDATE_ACCOUNT_BY_ADMIN, data)
const updatePasswordByAdmin = data => post(url.UPDATE_PASSWORD_BY_ADMIN, data)
const resetSmtpByAdmin = data => post(url.RESET_SMTP_BY_ADMIN, data)
const verifyAccountByAdmin = data => post(url.VERIFY_ACCOUNT_BY_ADMIN, data)
const virtualLogInByAdmin = data => post(url.VIRTUAL_LOG_IN_BY_ADMIN, data)
const createAccountByAdmin = formData => postRawData(url.CREATE_ACCOUNT_BY_ADMIN, formData)

// MY CASE MANAGEMENT
const getMyConnectedCaseSettings = () => get(url.GET_MY_CONNECTED_CASE_SETTINGS)
const saveMyConnectedCaseSetting = data => post(url.SAVE_MY_CONNECTED_CASE_SETTINGS, data)
const getMyCaseManagementSettings = () => get(url.GET_MY_CASE_MANAGEMENT_SETTINGS)
const saveMyCaseManagementSetting = data => post(url.SAVE_MY_CASE_MANAGEMENT_SETTINGS, data)
const getMyCaseManagementList = data => post(url.GET_MY_CASE_MANAGEMENT_LIST, data)
const downMyCaseManagementExcel = data => downExcel(url.DOWN_MY_CASE_MANAGEMENT_EXCEL, data.data, data.fileName)
const downMultipleMyCaseManagementExcel = data => downExcel(url.DOWN_MULTIPLE_MY_CASE_MANAGEMENT_EXCEL, data, data.fileName)
const downMyCaseManagementPdf = data => downPdf(url.DOWN_MY_CASE_MANAGEMENT_PDF, data.data, data.fileName)
const downMultipleMyCaseManagementPdf = data => downPdf(url.DOWN_MULTIPLE_MY_CASE_MANAGEMENT_PDF, data.data, data.fileName)
const getUserIrInfoClaimList = data => post(url.GET_USER_IR_INFO_CLAIM_LIST, data)
const addUserIrInfoClaim = data => postRawData(url.ADD_USER_IR_INFO_CLAIM, data)
const confirmUserIrInfoClaim = data => postRawData(url.CONFIRM_USER_IR_INFO_CLAIM, data)
const getTargetGroupDetailList = data => post(url.GET_TARGET_GROUP_DETAIL_LIST, data)

export {
  postWeCrestLogin,
  postWeCrestLogout,
  postWeCrestRegist,
  forgetPassword,
  resetPassword,
  getHomeCountryList,

  checkCookieAgree,
  updateCookieAgree,

  getWeCrestUsers,
  getDashboardCardInfo,
  sendFeedback,
  getWeCrestInfo,
  
  getAllJurisdictionsList,
  getJurisdictionsList,
  getMultipleJurisdictionsList,
  getCountryList,
  getAllCountryList,
  getMultipleCountryList,
  getSearchNameList,
  getSearchTargetGroupList,
  getGeneralStatisticsList,
  downStatisticsExcel,
  downStatisticsPdf,

  getCountryRankingsList,
  downCountryRankingsExcel,
  downCountryRankingsPdf,

  getSpecificSearchList,
  downSpecificSearchExcel,
  downSpecificSearchPdf,
  
  getEmailTemplates,
  deleteEmailTemplate,
  getEmailForm,
  updateEmailForm,
  deleteEmailForm,

  getFilerSettings,
  saveFilerSettings,
  getFilerRankings,
  getFilerRankingInfo,
  multipleHideFiler,
  multipleUnhideFiler,
  downFilerExcel,
  downMultipleFilerExcel,
  downFilerPdf,
  downFilerPdfDataOnly,
  downMultipleFilerPdf,
  filerSendEmail,
  filerPreviewEmail,

  getAccountInfo,
  updateAccountInfo,
  updateNewDashboard,
  updateAccountPassword,
  updateAccountInvoiceInfo,
  updateAccountUnsubscribeInfo,
  updateAccountUpToDateInfo,
  updateSenderEmail,
  updateSubscriptionEmailTemplate,
  updateFilerEmailTemplate,
  getUserSignatureSavePopupAppearInfo,
  addUserSignatureSavePopupAppearLog,
  getUserSignatureInfo,
  updateUserSignatureInfo,

  getEmailingSettings,
  addSenderEmail,
  deleteSenderEmail,
  updateDisplayName,
  updateSenderEmailAddress,
  getUserSignature,
  addUserSignature,
  updateUserSignature,
  deleteUserSignature,
  addUserJurisdictionEmailSetting,
  updateUserJurisdictionEmailSetting,
  deleteUserJurisdictionEmailSetting,
  getOcmnShowStartup,
  addUserInvoiceInfo,
  modifyUserInvoiceInfo,
  removeUserInvoiceInfo,
  getUserSubscriptionList,
  modifyUserSubscriptionInvoiceInfo,

  getMadridSettings,
  saveMadridSettings,
  getMadridNotifier,
  getMadridNotifierInfo,
  multipleHideMadrid,
  multipleUnhideMadrid,
  multipleStarMadrid,
  multipleUnstarMadrid,
  downMadridExcel,
  downMultipleMadridExcel,
  downMadridPdf,
  downMultipleMadridPdf,
  sendMadridEmail,
  previewMadridEmail,
  getOCMNList,
  saveOCMNShowStartup,
  saveOCMNAutoSendYn,
  saveOCMNSetting,
  getOCMNSuggestedContacts,
  sendOCMNEmail,
  addOcmnLog,

  getUserUnlockInfo,
  unlockMadrid,
  unlockFiler,

  getContactsList,
  multipleStarContacts,
  multipleUnstarContacts,
  multipleHideContacts,
  multipleUnhideContacts,
  multipleUnsubscribeContacts,
  checkEmailUnsubscribe,
  saveContacts,
  saveContactsSimple,
  deleteContacts,
  getImportedContacts,
  getSuggestedContacts,
  getContactsDetails,
  getContactsDetailList,
  getContactsMemo,
  saveMemo,
  saveContactsSettings,
  getContactsSettings,
  downContactsExcel,
  getUploadedContactsList,
  uploadContacts,
  saveUploadedContacts,
  deleteUploadedContacts,
  downUploadedContactsExcel,
  getDomainCorrectYnCount,
  saveDomainCorrectYn,
  getContactSearchList,
  downSearchEntityExcel,

  getSendingAnalyticsMnSettings,
  saveSendingAnalyticsMnSettings,
  getSendingAnalyticsMnList,
  downSendingAnalyticsMnExcel,
  getSendingAnalyticsMn,
  getSendingAnalyticsFrSettings,
  saveSendingAnalyticsFrSettings,
  getSendingAnalyticsFrList,
  downSendingAnalyticsFrExcel,
  getMadridSendingAnalyticsStatistics,
  getFilerSendingAnalyticsStatistics,

  getDomainList,
  getDomainActionHistory,
  saveDomainInfo,
  getBlackList,
  addBlackList,
  downExcelBlackList,
  getEntityDetail,
  saveEntityEmails,
  deleteEntityEmails,

  getJurisdictionsMonthlyPricing,
  getUserMembershipInfo,
  updateSubscriptionToFree,
  updateGoldMembershipPlan,
  getPaypalPlanId,

  getServiceManualList,
  getServiceManualInfo,
  updateServiceManual,
  updateServiceManualViewCnt,
  updateServiceManualPriority,
  updateServiceManualPDF,
  previewServiceManual,
  getServiceManualPageNameList,

  getSubscriptionItemList,
  saveSubscriptionItem,
  deleteSubscriptionItem,

  getAdmInvoiceManagementSearchList,
  downInvoicePdf,
  updateInvoiceStatus,
  confirmPayment,
  getSearchUserList,
  getSubscriptionItemAllList,
  getCresterList,
  createAdminInvoice,
  getUserInvoiceInfoList,
  getInvoiceItemInfo,

  getCommunityList,
  getCommunityDetail,
  createCommunity,
  modifyCommunity,
  removeCommunity,
  getCommunityAttachFileList,
  addCommunityAttachFile,
  removeCommunityAttachFile,
  getCommunityReplyList,
  createCommunityReply,
  modifyCommunityReply,
  removeCommunityReply,

  getConnectableEntityList,
  getMyEntityList,
  getMainDomain,
  getSearchEntityList,
  getUserEntityConnectList,
  downExcelUserEntityConnectList,
  confirmUserEntityConnect,
  disConnectUserEntity,
  getUserEntityConnectClaimList,
  downExcelUserEntityConnectClaimList,
  addUserEntityConnectClaim,
  modifyUserEntityConnectClaimStatus,
  confirmUserEntityConnectClaim,
  getRequester,
  getUserDomainList,
  addUserDomain,
  modifyUserDomain,
  removeUserDomain,

  getAdmAccountManagementSearchList,
  downExcelAdmAccountManagement,
  updateAccountByAdmin,
  updatePasswordByAdmin,
  resetSmtpByAdmin,
  verifyAccountByAdmin,
  virtualLogInByAdmin,
  createAccountByAdmin,

  getMyConnectedCaseSettings,
  saveMyConnectedCaseSetting,
  getMyCaseManagementSettings,
  saveMyCaseManagementSetting,
  getMyCaseManagementList,
  downMyCaseManagementExcel,
  downMultipleMyCaseManagementExcel,
  downMyCaseManagementPdf,
  downMultipleMyCaseManagementPdf,
  getUserIrInfoClaimList,
  addUserIrInfoClaim,
  confirmUserIrInfoClaim,
  getTargetGroupDetailList,
}