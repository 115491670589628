import {
  REGISTER_USER,
  REGISTER_USER_SUCCESSFUL,
  REGISTER_USER_FAILED,
  CLOSE_REGISTER_USER_SUCCESSFUL_MODAL,
  GET_HOME_COUNTRY_LIST,
  GET_HOME_COUNTRY_LIST_SUCCESS,
  CHANGE_HOME_COUNTRY_LIST,
  SET_REGISTER_LOADING,
} from "./actionTypes"

export const registerUser = (user, history) => {
  return {
    type: REGISTER_USER,
    payload: { user, history },
  }
}

export const registerUserSuccessful = () => {
  // console.log('registerUserSuccessful => ' + user);
  return {
    type: REGISTER_USER_SUCCESSFUL,
    // payload: user,
  }
}

export const registerUserFailed = error => {
  // console.log("registerUserFailed : ", error)

  return {
    type: REGISTER_USER_FAILED,
    payload: error,
  }
}

export const closeRegisterUserSuccessfulModal = () => {
  // console.log("registerUserFailed : ", error)

  return {
    type: CLOSE_REGISTER_USER_SUCCESSFUL_MODAL,
  }
}

export const getHomeCountryList = () => ({
  type: GET_HOME_COUNTRY_LIST,
})

export const getHomeCountryListSuccess = data => {
  return {
    type: GET_HOME_COUNTRY_LIST_SUCCESS,
    payload: data.homeCountryList,
  }
}

export const changeHomeCountryList = homeCountryList => {
  return {
    type: CHANGE_HOME_COUNTRY_LIST,
    payload: homeCountryList,
  }
}

export const setRegisterLoading = data => {
  return {
    type: SET_REGISTER_LOADING,
    payload: data,
  }
}