import React from 'react';
import { useSelector } from "react-redux";
import {
    Modal,
  } from "reactstrap"
import AdmCreateInvoice from './AdmCreateInvoice';

const EditInvoice = props => {
  return (
    <React.Fragment>
      {props.isOpenEdit ? (
        <Modal
          size="xl"
          isOpen={true}
          toggle={() => {
            props.setIsOpenEdit(false);
          }}
        >
          <div className="modal-header">
            <h5
              className="modal-title mt-0"
              id="myExtraLargeModalLabel"
            >
            </h5>
            <button
              onClick={() => {
                props.setIsOpenEdit(false);
              }}
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <AdmCreateInvoice invoiceNo={props.invoiceNo} userNo={props.userNo}/>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              onClick={() => {
                props.setIsOpenEdit(false);
              }}
              className="btn btn-secondary waves-effect"
              data-dismiss="modal"
            >
              Close
            </button>
          </div>
        </Modal>
      ) : null}
    </React.Fragment>
  )
}

export default EditInvoice;