import {
  GET_MULTIPLE_JURISDICTIONS_LIST,
  GET_MULTIPLE_JURISDICTIONS_LIST_SUCCESS,
  CHANGE_MULTIPLE_JURISDICTIONS_LIST,
} from "./actionTypes"

const initialState = {
  multipleJurisdictionList: [],
  subsJurisdictionCount: 0,
  multipleJurisdictionList_MCC: [],
  error: "",
  loading: false,
}

const multipleJurisdictions = (state = initialState, action) => {
  switch (action.type) {
    case GET_MULTIPLE_JURISDICTIONS_LIST:
      return {
        ...state,
        multipleJurisdictionList: [],
      }
    case GET_MULTIPLE_JURISDICTIONS_LIST_SUCCESS:
      // console.log('action.payload.filterName : ' + action.payload.filterName);
      if (action.payload.filterName && action.payload.filterName === 'myConnectedCase') {
        return {
          ...state,
          multipleJurisdictionList_MCC: action.payload.data.multipleJurisdictionList,
        }
      } else {
        return {
          ...state,
          multipleJurisdictionList: action.payload.data.multipleJurisdictionList,
          subsJurisdictionCount: action.payload.data.subsJurisdictionCount,
        }
      }
    case CHANGE_MULTIPLE_JURISDICTIONS_LIST:
      if (action.payload.filterName && action.payload.filterName === 'myConnectedCase') {
        return {
          ...state,
          multipleJurisdictionList_MCC: action.payload.multipleJurisdictionList
        }
      } else {
        return {
          ...state,
          multipleJurisdictionList: action.payload.multipleJurisdictionList
        }
      }
    default:
      state = { ...state }
      break
  }
  return state
}

export default multipleJurisdictions
