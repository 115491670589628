import {
  GET_SUBSCRIPTION_ITEM_LIST,
  GET_SUBSCRIPTION_ITEM_LIST_SUCCESS,
  CHANGE_SUBSCRIPTION_ITEM_LIST,
  SAVE_SUBSCRIPTION_ITEM,
  SAVE_SUBSCRIPTION_ITEM_SUCCESS,
  DELETE_SUBSCRIPTION_ITEM,
  DELETE_SUBSCRIPTION_ITEM_SUCCESS,
  SET_ADM_PRICING_MANAGEMENT_LOADING,
  SET_ADM_PRICING_MANAGEMENT_SUCCESS,
  SET_ADM_PRICING_MANAGEMENT_ERROR,
  SET_IS_RELOAD,
} from "./actionTypes"

const initialState = {
  loading: false,
  error: "",
  success: "",
  subscriptionItemList: [],
  totalPage: 1,
  isReload: true,
}

const AdmPricingManagement = (state = initialState, action) => {
  switch (action.type) {
    case GET_SUBSCRIPTION_ITEM_LIST:
      return {
        ...state,
        loading: true,
        subscriptionItemList: [],
      }
    case GET_SUBSCRIPTION_ITEM_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        subscriptionItemList: action.payload.subscriptionItemList,
        totalPage: action.payload.totalPage,
      }
    case CHANGE_SUBSCRIPTION_ITEM_LIST:
      return {
        ...state,
        subscriptionItemList: action.payload,
      }
    case SAVE_SUBSCRIPTION_ITEM:
      return {
        ...state,
        loading: true,
      }
    case SAVE_SUBSCRIPTION_ITEM_SUCCESS:
      if (action.payload.data.result === 'Success') {
        return {
          ...state,
          loading: false,
          success: action.payload.data.result,
          isReload: action.payload.isReload,
        }
      } else {
        return {
          ...state,
          loading: false,
          error: action.payload.data.result,
        }
      }
    case DELETE_SUBSCRIPTION_ITEM:
      return {
        ...state,
        loading: true,
      }
    case DELETE_SUBSCRIPTION_ITEM_SUCCESS:
      return {
        ...state,
        loading: false,
        success: action.payload.result,
        isReload: true,
      }
    case SET_ADM_PRICING_MANAGEMENT_LOADING:
      return {
        ...state,
        loading: action.payload
      }
    case SET_ADM_PRICING_MANAGEMENT_SUCCESS:
      return {
        ...state,
        success: action.payload
      }
    case SET_ADM_PRICING_MANAGEMENT_ERROR:
      return {
        ...state,
        error: action.payload
      }
    case SET_IS_RELOAD:
      return {
        ...state,
        isReload: action.payload
      }
    default:
      state = { ...state }
      break
  }
  return state
}

export default AdmPricingManagement
