import {
  GET_SUBSCRIPTION_ITEM_LIST,
  GET_SUBSCRIPTION_ITEM_LIST_SUCCESS,
  CHANGE_SUBSCRIPTION_ITEM_LIST,
  SAVE_SUBSCRIPTION_ITEM,
  SAVE_SUBSCRIPTION_ITEM_SUCCESS,
  DELETE_SUBSCRIPTION_ITEM,
  DELETE_SUBSCRIPTION_ITEM_SUCCESS,
  SET_ADM_PRICING_MANAGEMENT_LOADING,
  SET_ADM_PRICING_MANAGEMENT_SUCCESS,
  SET_ADM_PRICING_MANAGEMENT_ERROR,
  SET_IS_RELOAD,
} from "./actionTypes"

export const getSubscriptionItemList = data => {
  return {
    type: GET_SUBSCRIPTION_ITEM_LIST,
    payload: data,
  }
}

export const getSubscriptionItemListSuccess = data => {
  return {
    type: GET_SUBSCRIPTION_ITEM_LIST_SUCCESS,
    payload: data,
  }
}

export const changeSubscriptionItemList = data => {
  return {
    type: CHANGE_SUBSCRIPTION_ITEM_LIST,
    payload: data,
  }
}

export const saveSubscriptionItem = (data, isReload) => {
  return {
    type: SAVE_SUBSCRIPTION_ITEM,
    payload: {data, isReload},
  }
}

export const saveSubscriptionItemSuccess = (data, isReload) => {
  return {
    type: SAVE_SUBSCRIPTION_ITEM_SUCCESS,
    payload: {data, isReload},
  }
}

export const deleteSubscriptionItem = data => {
  return {
    type: DELETE_SUBSCRIPTION_ITEM,
    payload: data,
  }
}

export const deleteSubscriptionItemSuccess = data => {
  return {
    type: DELETE_SUBSCRIPTION_ITEM_SUCCESS,
    payload: data,
  }
}

export const setAdmPricingManagementLoading = data => {
  return {
    type: SET_ADM_PRICING_MANAGEMENT_LOADING,
    payload: data,
  }
}

export const setAdmPricingManagementSuccess = data => {
  return {
    type: SET_ADM_PRICING_MANAGEMENT_SUCCESS,
    payload: data,
  }
}

export const setAdmPricingManagementError = data => {
  return {
    type: SET_ADM_PRICING_MANAGEMENT_ERROR,
    payload: data,
  }
}

export const setIsReload = data => {
  return {
    type: SET_IS_RELOAD,
    payload: data,
  }
}