import React, {useState, useEffect} from 'react';
import { useSelector, useDispatch } from "react-redux";
import {
    Table,
    Row,
    Col,
    Card,
    CardBody,
    CardTitle,
    Media,
    Collapse,
    Form,
    Input,
    Label,
    Button,
    UncontrolledTooltip,
    Modal,
    TabContent,
    TabPane,
    NavLink,
    NavItem,
    Nav,
  } from "reactstrap"
import SweetAlert from "react-bootstrap-sweetalert";
import LoadingModal from "../../components/Common/LoadingModal"
import PeriodBtn from '../../components/Filter/PeriodBtn';
import ContactsDetails from '../Contacts/ViewDetail';
import { Link } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import classnames from "classnames"

import {
  setContacsResult as onSetContacsResult,
  setUploadContactsOpen as onSetUploadContactsOpen,
  getContactsList as onGetContactsList,
  changeContactsList as onChangeContactsList,
  multipleStarContacts as onMultipleStarContacts,
  multipleUnstarContacts as onMultipleUnstarContacts,
  multipleHideContacts as onMultipleHideContacts,
  multipleUnhideContacts as onMultipleUnhideContacts,
  multipleUnsubscribeContacts as onMultipleUnsubscribeContacts,
  saveContacts as onSaveContacts,
  deleteContacts as onDeleteContacts,
  saveContactsSettings as onSaveContactsSettings,
  getContactsSettings as onGetContactsSettings,
  downContactsExcel as onDownContactsExcel,
  setSearchSettings as onSetSearchSettings,
  setContactsLoading as onSetContactsLoading,

  getUploadedContactsList as onGetUploadedContactsList,
  saveUploadedContacts as onSaveUploadedContacts,
  deleteUploadedContacts as onDeleteUploadedContacts,
  downUploadedContactsExcel as onDownUploadedContactsExcel,
  changeUploadedContactsList as onChangeUploadedContactsList,

  checkEmailUnsubscribe as onCheckEmailUnsubscribe,
  changeCheckUnsubsEmailAddressFlag as onChangeCheckUnsubsEmailAddressFlag,
  setSuccess as onSetSuccess,

  getContactSearchList as onGetContactSearchList,
  changeContactSearchList as onChangeContactSearchList,
  downSearchEntityExcel,
} from "../../store/actions";
import { cloneDeep } from 'lodash';
import UploadContactsModal from './UploadContactsModal';
import dateFormat from 'dateformat';
import toastr from "toastr"
import "toastr/build/toastr.min.css"
import SelectCountry from '../../components/Filter/SelectCountry';

const Contacts = props => {
  const dispatch = useDispatch();

  const { noticeStr, mainList, searchSettings, loading, result, totalPage, isSearched, uploadedContactsList, totalPageUp, userGrade, uploadContactsOpen, unsubsEmailAddress, checkUnsubsEmailAddressFlag, screenName, success, searchList, totalPageSearch } = useSelector(state => ({
    noticeStr: state.Contacts.noticeStr,
    mainList: state.Contacts.contactsList,
    searchSettings: state.Contacts.searchSettings,
    loading: state.Contacts.loading,
    result: state.Contacts.result,
    totalPage: state.Contacts.totalPage,
    isSearched: state.Contacts.isSearched,

    // searchSettingsUp: state.Contacts.searchSettingsUp,
    uploadedContactsList: state.Contacts.uploadedContactsList,
    totalPageUp: state.Contacts.totalPageUp,
    userGrade: state.Contacts.userGrade,

    uploadContactsOpen: state.Contacts.uploadContactsOpen,

    unsubsEmailAddress: state.Contacts.unsubsEmailAddress,
    checkUnsubsEmailAddressFlag: state.Contacts.checkUnsubsEmailAddressFlag,
    screenName: state.Contacts.screenName,

    success: state.FilerRankings.success,

    searchList: state.Contacts.searchList,
    totalPageSearch: state.Contacts.totalPageSearch,
  }));

  const [isOpen, setIsOpen] = useState(true);
  const [chkAllFlag, setChkAllFlag] = useState(false);
  const [hideOrUnhide, setHideOrUnhide] = useState("Hide");
  const [starOrUnstar, setStarOrUnstar] = useState("Star");
  const [confirmMsg, setConfirmMsg] = useState("");
  const [confirmAlert, setConfirmAlert] = useState(false);
  const [curPageInput, setCurPageInput] = useState(1);
  const [curPageInputUp, setCurPageInputUp] = useState(1);
  const [activeTab, setActiveTab] = useState("Manage");
  const [curPageSearch, setCurPageSearch] = useState(1);
  const [chkAllFlag_Search, setChkAllFlag_Search] = useState(false);
  const [hideOrUnhide_Search, setHideOrUnhide_Search] = useState("Hide");
  const [starOrUnstar_Search, setStarOrUnstar_Search] = useState("Star");
  function toggleActiveTab(tab) {
    if (activeTab !== tab) {
      setActiveTab(tab)
    }
  }

  /**
   * errMsg[0] : .csv 파일이 아님
   * errMsg[1] : 선택된 entity 없음.
   * errMsg[2] : 선택된 contact 없음.
   * errMsg[3] : save되지 않은 contact 선택함.
   * errMsg[4] : 필수항목 미입력
   * errMsg[5] : email 형식 잘못됨
   * errMsg[6] : 필수항목 미입력
   */
  const errMsg = [
      "not csv file"
    , "Please select a entity."
    , "Please select a contact."
    , "There are unsaved rows."
    , "Email address is required."
    , <>Invalid email format: Please check if you accidentally included any special characters or blank spaces.
      <br/><br/>Please refer to the following website to check for any hidden special characters or blank spaces: <a href='https://www.regextester.com/99810' target='_blank'>https://www.regextester.com/99810</a>
      <br/>*Please note that we do not have any affiliation with this website, but we found it helpful.</>
    , "First name is required."
    , "Please Select From date."
    , "Please Select to date."
    , "This email address already exists for this entity!"
    , "The following email addresses have already been unsubscribed:"
    , <>If you selected All Entity status,<br/>Please enter a Name of holder or representative or Domain</>
  ];

  const [dupeEmailAddress, setDupeEmailAddress] = useState([]);
  const [chkAllFlagUp, setChkAllFlagUp] = useState(false);

  useEffect(() => {
    if(isSearched){
      // 새로고침
      filterValidation.setFieldValue("submitType", "view");
      filterValidation.handleSubmit(filterValidation.values);
    }else{
      //첫 액세스
      dispatch(onGetContactsSettings());
    }
  },[]);

  useEffect(() => { 
    if (checkUnsubsEmailAddressFlag && screenName == 'List') {
      dispatch(onChangeCheckUnsubsEmailAddressFlag(false));
      filterValidation.setFieldValue("submitType", "Save");
      setConfirmMsg("Are you sure you want to save the selected email(s)?");
      setConfirmAlert(true);
    }
  }, [checkUnsubsEmailAddressFlag]);

  useEffect(() => {
    if(!uploadContactsOpen){
      // console.log('imported email address 조회')
      // imported email address 조회 
      filterValidation.setFieldValue("submitType", "viewUp");
      filterValidation.handleSubmit(filterValidation.values);
    }
  },[uploadContactsOpen]);

  const toggle = () => {
    setIsOpen(!isOpen);
  }

  const filterValidation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      submitType: "view",
      entityName: searchSettings && searchSettings.targetGroupName? searchSettings.targetGroupName : "",
      entityWebDomain: searchSettings && searchSettings.domain? searchSettings.domain : "",
      entityStatus: searchSettings && searchSettings.entityStatus? searchSettings.entityStatus : "",
      contactEmail: searchSettings && searchSettings.emailAddress? searchSettings.emailAddress : "",
      contactName: searchSettings && searchSettings.name? searchSettings.name : "",
      contactTitle: searchSettings && searchSettings.title? searchSettings.title : "",
      contactPosition: searchSettings && searchSettings.position? searchSettings.position : "",
      contactStatus: searchSettings && searchSettings.unsubscribe? searchSettings.unsubscribe : "",
      datePeriod: searchSettings && searchSettings.datePeriod? searchSettings.datePeriod : "",
      fromDate: undefined,
      toDate: undefined,
      sortType: "NRD",
      perPage: 20,
      curPage: 1,

      emailAddress: "",
      firstName: "",
      lastName: "",
      title: "",
      position: "",
      // sortTypeUp: "NRD",
      perPageUp: 20,
      curPageUp: 1,

      entityStatusSearch: "",
      searchName: "",
      searchDomain: "",
      crCdList: [],
      searchAddress: "",
      perPageSearch: 20,
      curPageSearch: 1,
    },
    validationSchema: Yup.object().shape({
      fromDate: Yup.date()
        .max(Yup.ref('toDate'), "From date must be earlier than to date"),
        // .required("Please Select From date"),
      toDate: Yup.date()
        .max(new Date(), "Date cannot be in the future"),
        // .required("Please Select to date"),
    }),
    validateOnChange: false, // this one
    validateOnBlur: false, // and this one
    onSubmit: (values) => {
      // console.log(JSON.stringify(values, null, 2));

      if((!values["fromDate"] || values["fromDate"] == '') && values["toDate"] && values["toDate"] != ''){
        dispatch(onSetContacsResult(7));
        return false;
      }

      if(values["fromDate"] && values["fromDate"] != '' && (!values["toDate"] || values["toDate"] == '')){
        dispatch(onSetContacsResult(8));
        return false;
      }
      
      const filterParam = {
        targetGroupName: values["entityName"],
        domain: values["entityWebDomain"],
        entityStatus: values["entityStatus"],
        emailAddress: values["contactEmail"],
        name: values["contactName"],
        title: values["contactTitle"],
        position: values["contactPosition"],
        unsubscribe: values["contactStatus"],
        startDate: values["fromDate"],
        endDate: values["toDate"],
        sortType: values["sortType"],
        perPage: values["perPage"],
        curPage: values["curPage"],
      };
      
      const filterParamSearch = {
        entityStatus: values["entityStatusSearch"],
        targetGroupName: values["searchName"],
        domain: values["searchDomain"],
        crCdList: values["crCdList"],
        address: values["searchAddress"],
        perPage: values["perPageSearch"],
        curPage: values["curPageSearch"],
      };

      let fileName = dateFormat(new Date(), "yymmdd") + " WeCrest Contacts";
      // if (values["fromDate"] && values["fromDate"] != '') {
      //   fileName += " ("+ values["fromDate"].replace(/-/gi,"") + "-" + values["toDate"].replace(/-/gi,"") + ")"; 
      // }
      
      let submitType = values["submitType"];

      if(submitType == "view"){
        dispatch(onGetContactsList(filterParam));
        const settingsParam = {
          targetGroupName: values["entityName"],
          domain: values["entityWebDomain"],
          entityStatus: values["entityStatus"],
          emailAddress: values["contactEmail"],
          name: values["contactName"],
          title: values["contactTitle"],
          position: values["contactPosition"],
          unsubscribe: values["contactStatus"],
          datePeriod: values["datePeriod"],
        };
        // dispatch(onSetSearchSettings(settingsParam));
      }else if(submitType == "Star"){
        const _mainList = [];
        mainList.map((entity, entityIdx) =>{
          if(entity.checked && !entity.starred){
            _mainList.push(entity);
          }
        }); 

        dispatch(onMultipleStarContacts(_mainList, filterParam, "Manage"));
      }else if(submitType == "Unstar"){
        const _mainList = [];
        mainList.map((entity, entityIdx) =>{
          if(entity.checked && entity.starred){
            _mainList.push(entity);
          }
        }); 

        dispatch(onMultipleUnstarContacts(_mainList, filterParam, "Manage"));
      }else if(submitType == "Hide"){
        const _mainList = [];
        mainList.map((entity, entityIdx) =>{
          if(entity.checked && !entity.hidden){
            _mainList.push(entity);
          }
        }); 

        dispatch(onMultipleHideContacts(_mainList, filterParam, "Manage"));
      }else if(submitType == "Unhide"){
        const _mainList = [];
        mainList.map((entity, entityIdx) =>{
          if(entity.checked && entity.hidden){
            _mainList.push(entity);
          }
        }); 

        dispatch(onMultipleUnhideContacts(_mainList, filterParam, "Manage"));
      }else if(submitType == "Save" || submitType == "Delete"){
        const _contactsList = [];
        mainList.map((entity, entityIdx) =>{
          entity.contactsList.map((contact, contactIdx) => {
            if(contact.checked) _contactsList.push(contact);
          });
        }); 

        if(submitType == "Save") dispatch(onSaveContacts(_contactsList, filterParam));
        else if(submitType == "Delete") dispatch(onDeleteContacts(_contactsList, filterParam));

      }else if(submitType == "Unsubscribed" || submitType == "Confirmed"){
        let value = submitType == "Unsubscribed"? "Y" : "N"
        let _mainList = cloneDeep(mainList);
        const _contactsList = [];
        _mainList.map((entity, entityIdx) =>{
          entity.contactsList.map((contact, contactIdx) => {
            if(contact.checked){
              contact.unsubscribe = value;
              _contactsList.push(contact);
            }
          });
        }); 

        dispatch(onMultipleUnsubscribeContacts(_contactsList, filterParam));
      }else if(submitType.indexOf("Star_") === 0
                || submitType.indexOf("Unstar_") === 0
                  || submitType.indexOf("Hide_") === 0
                    || submitType.indexOf("Unhide_") === 0){
        let targetGroupNo = submitType.substring(submitType.lastIndexOf("_")+1, submitType.length);
        const _mainList = [];
        mainList.map((entity, entityIdx) =>{
          if(targetGroupNo == entity.targetGroupNo){
            _mainList.push(entity);
          }
        });

        if(submitType.indexOf("Star_") === 0) dispatch(onMultipleStarContacts(_mainList, filterParam, "Manage"));
        else if(submitType.indexOf("Unstar_") === 0) dispatch(onMultipleUnstarContacts(_mainList, filterParam, "Manage"));
        else if(submitType.indexOf("Hide_") === 0) dispatch(onMultipleHideContacts(_mainList, filterParam, "Manage"));
        else if(submitType.indexOf("Unhide_") === 0) dispatch(onMultipleUnhideContacts(_mainList, filterParam, "Manage"));

      }else if(submitType.indexOf("Unsubscribed_") === 0
                || submitType.indexOf("Confirmed_") === 0){
        let targetGroupNo = submitType.substring(submitType.indexOf("_")+1, submitType.lastIndexOf("_"));
        let emailSeq = submitType.substring(submitType.lastIndexOf("_")+1, submitType.length);
        let value = submitType.indexOf("Unsubscribed_") === 0? "Y" : "N"
        let _contactsList = [];
        mainList.map((entity, entityIdx) =>{
          if(targetGroupNo == entity.targetGroupNo){
            entity.contactsList.map((contact, contactIdx) => {
              if(emailSeq == contact.emailSeq){
                _contactsList.push(contact);
              }
            });
          }
        }); 
        _contactsList[0].unsubscribe = value;
        dispatch(onMultipleUnsubscribeContacts(_contactsList, filterParam));
      }else if(submitType == "saveSettings"){
        const settingsParam = {
          targetGroupName: values["entityName"],
          domain: values["entityWebDomain"],
          entityStatus: values["entityStatus"],
          emailAddress: values["contactEmail"],
          name: values["contactName"],
          title: values["contactTitle"],
          position: values["contactPosition"],
          unsubscribe: values["contactStatus"],
          datePeriod: values["datePeriod"],
        };

        // console.log(settingsParam);

        dispatch(onSaveContactsSettings(settingsParam));

      }else if(submitType == "excel"){
        dispatch(onDownContactsExcel(filterParam, fileName));
      }else if(submitType == "viewUp"){
        const filterParamUp = {
          emailAddress: values["emailAddress"],
          firstName: values["firstName"],
          lastName: values["lastName"],
          title: values["title"],
          position: values["position"],
          perPage: values["perPageUp"],
          curPage: values["curPageUp"],
        };

        dispatch(onGetUploadedContactsList(filterParamUp));
      }else if(submitType == "saveUp" || submitType == "deleteUp"){
        const filterParamUp = {
          emailAddress: values["emailAddress"],
          firstName: values["firstName"],
          lastName: values["lastName"],
          title: values["title"],
          position: values["position"],
          perPage: values["perPageUp"],
          curPage: values["curPageUp"],
        };

        const _uploadedContactsList = [];
        uploadedContactsList.map((entity, entityIdx) =>{
          if(entity.checked) _uploadedContactsList.push(entity);
        }); 

        if(submitType == "saveUp") dispatch(onSaveUploadedContacts(_uploadedContactsList, filterParamUp));
        else if(submitType == "deleteUp") dispatch(onDeleteUploadedContacts(_uploadedContactsList, filterParamUp));
      }else if (submitType == "excelUp") {
        const filterParamUp = {
          emailAddress: values["emailAddress"],
          firstName: values["firstName"],
          lastName: values["lastName"],
          title: values["title"],
          position: values["position"],
        };

        dispatch(onDownUploadedContactsExcel(filterParamUp));
      } else if (submitType == "excelSearch"){
        dispatch(downSearchEntityExcel(filterParamSearch, "Search entity " + dateFormat(new Date(), "yymmdd")));
      }else if(submitType == "viewSearch"){

        if (values["entityStatusSearch"] === '' && values["searchName"] === '' && values["searchDomain"] === '') {
          dispatch(onSetContacsResult(11));
        } else {
          dispatch(onGetContactSearchList(filterParamSearch));
        }
      }else if(submitType == "StarSearch"){
        const _searchList = [];
        searchList.map((entity, entityIdx) =>{
          if(entity.checked && !entity.starred){
            _searchList.push(entity);
          }
        }); 

        dispatch(onMultipleStarContacts(_searchList, filterParamSearch, "Search"));
        setChkAllFlag_Search(false);
        setStarOrUnstar_Search("Star");

      }else if(submitType == "UnstarSearch"){
        const _searchList = [];
        searchList.map((entity, entityIdx) =>{
          if(entity.checked && entity.starred){
            _searchList.push(entity);
          }
        }); 

        dispatch(onMultipleUnstarContacts(_searchList, filterParamSearch, "Search"));
        setChkAllFlag_Search(false);
        setStarOrUnstar_Search("Star");
      }else if(submitType == "HideSearch"){
        const _searchList = [];
        searchList.map((entity, entityIdx) =>{
          if(entity.checked && !entity.hidden){
            _searchList.push(entity);
          }
        }); 

        dispatch(onMultipleHideContacts(_searchList, filterParamSearch, "Search"));
        setChkAllFlag_Search(false);
      }else if(submitType == "UnhideSearch"){
        const _searchList = [];
        searchList.map((entity, entityIdx) =>{
          if(entity.checked && entity.hidden){
            _searchList.push(entity);
          }
        }); 

        dispatch(onMultipleUnhideContacts(_searchList, filterParamSearch, "Search"));
        setChkAllFlag_Search(false);
        setHideOrUnhide_Search("Hide");
      }else if(submitType.indexOf("StarSearch_") === 0
              || submitType.indexOf("UnstarSearch_") === 0
                || submitType.indexOf("HideSearch_") === 0
                  || submitType.indexOf("UnhideSearch_") === 0){
        let targetGroupNo = submitType.substring(submitType.lastIndexOf("_")+1, submitType.length);
        const _searchList = [];
        searchList.map((entity, entityIdx) =>{
        if(targetGroupNo == entity.targetGroupNo){
          _searchList.push(entity);
        }
        });

        if(submitType.indexOf("StarSearch_") === 0) dispatch(onMultipleStarContacts(_searchList, filterParamSearch, "Search"));
        else if(submitType.indexOf("UnstarSearch_") === 0) dispatch(onMultipleUnstarContacts(_searchList, filterParamSearch, "Search"));
        else if(submitType.indexOf("HideSearch_") === 0) dispatch(onMultipleHideContacts(_searchList, filterParamSearch, "Search"));
        else if(submitType.indexOf("UnhideSearch_") === 0) dispatch(onMultipleUnhideContacts(_searchList, filterParamSearch, "Search"));
      }
    },
  });

  const viewContactsList = (sortType, curPage, perPage) => {
    //curPage 유효성 체크
    if(curPage){
      if(curPage > totalPage) {
        curPage = totalPage;
      } else if (curPage <= 0) {
        curPage = 1;
      }
    }

    // console.log("viewContactsList [sortType: "+sortType+", curPage: "+curPage+", perPage: "+perPage);
    let _values = cloneDeep(filterValidation.values);
    _values.submitType = "view";
    if(sortType){
      _values.sortType = sortType;
    }
    if(curPage){
      _values.curPage = curPage;
      setCurPageInput(curPage);
    }else{
      _values.curPage = 1;
      setCurPageInput(1);
    }
    if(perPage){
      _values.perPage = perPage;
    }
    filterValidation.setValues(_values);
    filterValidation.handleSubmit(filterValidation.values);
  }

  const entityCheck = (entityChkIdx, value) => {
    let _mainList = cloneDeep(mainList);
    let starredCnt = 0;
    let hiddenCnt = 0;

    if(entityChkIdx == "chkAll"){
      setChkAllFlag(value);
      _mainList = _mainList.map((entity, entityIdx) => {
        entity.checked = value;
        entity.contactsList.map((contact, contactIdx) =>{
          contact.checked = value;
          return contact;
        })

        if(entity.starred) starredCnt++;
        if(entity.hidden) hiddenCnt++;
        
        return entity;
      })

      if(starredCnt === mainList.length){
        setStarOrUnstar("Unstar");
      }else{
        setStarOrUnstar("Star");
      }

      if(hiddenCnt === mainList.length){
        setHideOrUnhide("Unhide");
      }else{
        setHideOrUnhide("Hide");
      }

    }else{
      let entityChkCnt = 0;

      _mainList = _mainList.map((entity, entityIdx) => {
        if(entityChkIdx == entityIdx){
          entity.checked = value;
          entity.contactsList.map((contact, contactIdx) =>{
            contact.checked = value;
            return contact;
          })
        }
        if(entity.checked){
          entityChkCnt++;
          if(entity.starred) starredCnt++;
          if(entity.hidden) hiddenCnt++;
        } 

        return entity;
      })

      if(entityChkCnt === mainList.length){
        setChkAllFlag(true);
      }else{
        setChkAllFlag(false);
      }

      if(starredCnt === entityChkCnt && entityChkCnt !== 0){
        setStarOrUnstar("Unstar");
      }else{
        setStarOrUnstar("Star");
      }

      if(hiddenCnt === entityChkCnt && entityChkCnt !== 0){
        setHideOrUnhide("Unhide");
      }else{
        setHideOrUnhide("Hide");
      }
    }

    dispatch(onChangeContactsList(_mainList));
  }

  const contactCheck = (entityChkIdx, contactChkIdx, value) => {
    let _mainList = cloneDeep(mainList);
    let entityChkCnt = 0;
    let contactChkCnt = 0;
    let starredCnt = 0;
    let hiddenCnt = 0;

    _mainList = _mainList.map((entity, entityIdx) => {
      if(entityChkIdx == entityIdx){
        entity.contactsList.map((contact, contactIdx) =>{
          if(contactChkIdx == contactIdx){
            contact.checked = value;
          }
          if(contact.checked == true){
            contactChkCnt++;
          }
          return contact;
        })

        if(contactChkCnt == entity.contactsList.length){
          entity.checked = true;
        }else{
          entity.checked = false;
        }
      }
      if(entity.checked == true){
        entityChkCnt++;
        if(entity.starred) starredCnt++;
        if(entity.hidden) hiddenCnt++;
      }
      return entity;
    })

    if(entityChkCnt === mainList.length){
      setChkAllFlag(true);
    }else{
      setChkAllFlag(false);
    }
    
    if(starredCnt === entityChkCnt && entityChkCnt !== 0){
      setStarOrUnstar("Unstar");
    }else{
      setStarOrUnstar("Star");
    }

    if(hiddenCnt === entityChkCnt && entityChkCnt !== 0){
      setHideOrUnhide("Unhide");
    }else{
      setHideOrUnhide("Hide");
    }

    dispatch(onChangeContactsList(_mainList));
  }
  
  const handleTypeSelect = (entityIdx, contactIdx, value) => {
    let _mainList = cloneDeep(mainList);
    _mainList[entityIdx].contactsList[contactIdx].type = value;
    
    dispatch(onChangeContactsList(_mainList));
  }

  const handleInput = (attr, entityIdx, contactIdx, value) => {
    let _mainList = cloneDeep(mainList);
    _mainList[entityIdx].contactsList[contactIdx][attr] = value;
    
    dispatch(onChangeContactsList(_mainList));
  }
  
  const addContacts = (targetGroupNo, entityIdx) => {
    let _mainList = cloneDeep(mainList);
    let newRow = {"targetGroupNo":targetGroupNo,"emailSeq":null,"emailAddress":null,"modFlag":"I","type":"To","firstName":null,"lastName":null,"title":null,"position":null,"unsubscribe":"N","checked":true,"sendYn":"Y"};
    _mainList[entityIdx].contactsList.push(newRow);

    dispatch(onChangeContactsList(_mainList));
  }

  const entityChk = () => {
    let chkCnt = 0;
    mainList.map((entity, entityIdx) =>{
      if(entity.checked === true) chkCnt++;
    }); 

    if(chkCnt === 0){
      dispatch(onSetContacsResult(1));
      return false;
    }

    return true;
  }

  const contactChk = () => {
    let chkCnt = 0;
    let notSaveCnt = 0;
    mainList.map((entity, entityIdx) =>{
      entity.contactsList.map((contact, contactIdx) => {
        if(contact.checked === true) {
          chkCnt++;
          if(contact.modFlag == "I") notSaveCnt++;
        }
      })
    }); 
    
    if(chkCnt === 0){
      dispatch(onSetContacsResult(2));
      return false;
    }
    if(notSaveCnt > 0){
      dispatch(onSetContacsResult(3));
      return false;
    }

    return true;
  }

  const saveValidation = () => {
    let chkCnt = 0;
    let noEmail = false;
    let noFirstName = false;
    let invalidEmail = false;
    let regex = /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*\.[a-zA-Z]{2,15}$/i;
    let checkUnsubsEmail = [];
    let checkDupeEmailAddress = [];

    mainList.map((entity, entityIdx) =>{
      entity.contactsList.map((contact, contactIdx) => {
        if(contact.checked === true) {
          chkCnt++;
          if(!contact.emailAddress){
            noEmail = true;
          }else {
            if(contact.emailAddress.match(regex) == null) invalidEmail = true;
          }

          // if (contact.modFlag === "I") {
          checkUnsubsEmail.push(contact);
          // }

          // if(!contact.firstName){
          //   noFirstName = true;
          // }

          mainList.map((entity2, entityIdx2) =>{
            entity2.contactsList.map((contact2, contactIdx2) => {
              if(entityIdx == entityIdx2 && contactIdx != contactIdx2 && contact.emailAddress && contact.emailAddress != '' && contact.emailAddress.toLowerCase() == contact2.emailAddress.toLowerCase() && !checkDupeEmailAddress.includes(contact.emailAddress)) {
                checkDupeEmailAddress.push(contact.emailAddress)
              }
            })
          })
        }
      })
    }); 

    if (checkDupeEmailAddress.length > 0) {
      setDupeEmailAddress(checkDupeEmailAddress);
      dispatch(onSetContacsResult(9));
      return false;
    }

    if(chkCnt === 0){
      dispatch(onSetContacsResult(2));
      return false;
    }else if(noEmail){
      dispatch(onSetContacsResult(4));
      return false;
    }else if(invalidEmail){
      dispatch(onSetContacsResult(5));
      return false;
    }else if(noFirstName){
      dispatch(onSetContacsResult(6));
      return false;
    }else{
      if (checkUnsubsEmail.length > 0) {
        // console.log('checkUnsubsEmail : ' + JSON.stringify(checkUnsubsEmail))
        dispatch(onCheckEmailUnsubscribe(checkUnsubsEmail, '', 'List'));
        return false;
      } else {
        return true;
      }
    }
  }

  const setContactsLoading = () => {
    dispatch(onSetContactsLoading(!loading));
  }

  const [modalContactsScreen, setModalContactsScreen] = useState(false);
  const [contactsTargetGroupNo, setContactsTargetGroupNo] = useState(-1);
  function togContactsScreen(targetGroupNo) {
    if (targetGroupNo) {
      setContactsTargetGroupNo(targetGroupNo);
    } else {
      if (activeTab === 'Manage') {
        filterValidation.setFieldValue("submitType", "view");
      } else if (activeTab === 'Search') {
        filterValidation.setFieldValue("submitType", "viewSearch");
      }
      
      filterValidation.handleSubmit(filterValidation.values);
    }
    setModalContactsScreen(!modalContactsScreen);
    removeBodyCss();
  }

  function removeBodyCss() {
    document.body.classList.add("no_padding")
    document.body.classList.add("modal-open")
  }


  const entityCheckUp = (entityChkIdx, value) => {
    let _uploadedContactsList = cloneDeep(uploadedContactsList);

    if(entityChkIdx == "chkAll"){
      setChkAllFlagUp(value);
      _uploadedContactsList = _uploadedContactsList.map((entity, entityIdx) => {
        entity.checked = value;

        return entity;
      })

    }else{
      let entityChkCnt = 0;

      _uploadedContactsList = _uploadedContactsList.map((entity, entityIdx) => {
        if(entityChkIdx == entityIdx){
          entity.checked = value;
        }
        if(entity.checked){
          entityChkCnt++;
        } 

        return entity;
      })

      if(entityChkCnt === uploadedContactsList.length){
        setChkAllFlagUp(true);
      }else{
        setChkAllFlagUp(false);
      }
    }

    dispatch(onChangeUploadedContactsList(_uploadedContactsList));
  }

  const handleInputUp = (attr, entityIdx, value) => {
    let _uploadedContactsList = cloneDeep(uploadedContactsList);
    _uploadedContactsList[entityIdx][attr] = value;
    
    dispatch(onChangeUploadedContactsList(_uploadedContactsList));
  }

  const viewContactsListUp = (curPageUp, perPageUp) => {
    // console.log("viewContactsListUp 1 [curPageUp: "+curPageUp+", perPageUp: "+perPageUp);

    //curPage 유효성 체크
    if(curPageUp){
      if(curPageUp > totalPageUp) {
        curPageUp = totalPageUp;
      } else if (curPageUp <= 0) {
        curPageUp = 1;
      }
    }

    // console.log("viewContactsListUp 2 [curPageUp: "+curPageUp+", perPageUp: "+perPageUp);
    let _values = cloneDeep(filterValidation.values);
    _values.submitType = "viewUp";
    
    if(curPageUp){
      _values.curPageUp = curPageUp;
      setCurPageInputUp(curPageUp);
    }else{
      _values.curPageUp = 1;
      setCurPageInputUp(1);
    }
    if(perPageUp){
      _values.perPageUp = perPageUp;
    }
    filterValidation.setValues(_values);
    filterValidation.handleSubmit(filterValidation.values);
  }

  const contactChkUp = () => {
    let chkCnt = 0;
    uploadedContactsList.map((contact, entityIdx) =>{
      if(contact.checked === true) {
        chkCnt++;
      }
    }); 
    
    if(chkCnt === 0){
      dispatch(onSetContacsResult(2));
      return false;
    }
    
    return true;
  }

  const saveValidationUp = () => {
    let chkCnt = 0;
    let noEmail = false;
    let noFirstName = false;
    let invalidEmail = false;
    let regex = /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*\.[a-zA-Z]{2,15}$/i;

    uploadedContactsList.map((contact, entityIdx) =>{
      if(contact.checked === true) {
        chkCnt++;
        if(!contact.emailAddress){
          noEmail = true;
        }else {
          if(contact.emailAddress.match(regex) == null) invalidEmail = true;
        }

        // if(!contact.firstName){
        //   noFirstName = true;
        // }
      }
    }); 

    if(chkCnt === 0){
      dispatch(onSetContacsResult(2));
      return false;
    }else if(noEmail){
      dispatch(onSetContacsResult(4));
      return false;
    }else if(invalidEmail){
      dispatch(onSetContacsResult(5));
      return false;
    }else if(noFirstName){
      dispatch(onSetContacsResult(6));
      return false;
    }else{
      return true;
    }
  }

  function showSuccessCopyToast() {
    const ele = "success";
    const position = "toast-bottom-left"
    const title = ""
    let message = "Copy clipboard!"

    //Close Button
    const closeButton = false

    //Debug
    const debug = false

    //Progressbar
    const progressBar = false

    //Duplicates
    const preventDuplicates = false

    //Newest on Top
    const newestOnTop = false

    //position class
    let positionClass = "toast-bottom-left"

    //Show Easing
    const showEasing = "swing";

    //Hide Easing
    const hideEasing = "linear";

    //show method
    const showMethod = "fadeIn";

    //Hide method
    const hideMethod = "fadeOut";

    //show duration
    const showDuration = 300;

    //Hide duration
    const hideDuration = 1000;

    //timeout
    const timeOut = 1000;

    //extended timeout
    const extendedTimeOut = 1000;

    toastr.options = {
      positionClass: positionClass,
      timeOut: timeOut,
      extendedTimeOut: extendedTimeOut,
      closeButton: closeButton,
      debug: debug,
      progressBar: progressBar,
      preventDuplicates: preventDuplicates,
      newestOnTop: newestOnTop,
      showEasing: showEasing,
      hideEasing: hideEasing,
      showMethod: showMethod,
      hideMethod: hideMethod,
      showDuration: showDuration,
      hideDuration: hideDuration
    }

    // setTimeout(() => toastr.success(`Settings updated `), 300)
    //Toaster Types
    toastr.success(message, title)
  }

  const unsecuredCopyToClipboard = (text, elmentId) => {
    const textArea = document.createElement("textarea");
    textArea.setAttribute("type", "hidden");
    textArea.value = text;

    // const node = document.getElementById(elmentId).lastElementChild;
    document.getElementById(elmentId).appendChild(textArea);
    // document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();
    try {
      document.execCommand('copy');
    } catch (err) {
      console.error('Unable to copy to clipboard', err);
    }
    document.getElementById(elmentId).removeChild(textArea);
  }

  const viewContactsSearchList = (sortType, curPageSearch, perPageSearch) => {
    //curPage 유효성 체크
    if(curPageSearch){
      if(curPageSearch > totalPageSearch) {
        curPageSearch = totalPageSearch;
      } else if (curPageSearch <= 0) {
        curPageSearch = 1;
      }
    }

    // console.log("viewContactsList [sortType: "+sortType+", curPage: "+curPage+", perPage: "+perPage);
    let _values = cloneDeep(filterValidation.values);
    _values.submitType = "viewSearch";
    if(sortType){
      _values.sortType = sortType;
    }
    if(curPageSearch){
      _values.curPageSearch = curPageSearch;
      setCurPageSearch(curPageSearch);
    }else{
      _values.curPageSearch = 1;
      setCurPageSearch(1);
    }
    if(perPageSearch){
      _values.perPageSearch = perPageSearch;
    }
    filterValidation.setValues(_values);
    filterValidation.handleSubmit(filterValidation.values);
  }

  const entityCheckSearch = (entityChkIdx, value) => {
    let _searchList = cloneDeep(searchList);
    let starredCnt = 0;
    let hiddenCnt = 0;

    if(entityChkIdx == "chkAll"){
      setChkAllFlag_Search(value);
      _searchList = _searchList.map((entity, entityIdx) => {
        entity.checked = value;

        if(entity.starred) starredCnt++;
        if(entity.hidden) hiddenCnt++;
        
        return entity;
      })

      if(starredCnt === searchList.length){
        setStarOrUnstar_Search("Unstar");
      }else{
        setStarOrUnstar_Search("Star");
      }

      if(hiddenCnt === searchList.length){
        setHideOrUnhide_Search("Unhide");
      }else{
        setHideOrUnhide_Search("Hide");
      }

    }else{
      let entityChkCnt = 0;

      _searchList = _searchList.map((entity, entityIdx) => {
        if(entityChkIdx == entityIdx){
          entity.checked = value;
        }
        if(entity.checked){
          entityChkCnt++;
          if(entity.starred) starredCnt++;
          if(entity.hidden) hiddenCnt++;
        } 

        return entity;
      })

      if(entityChkCnt === searchList.length){
        setChkAllFlag_Search(true);
      }else{
        setChkAllFlag_Search(false);
      }

      if(starredCnt === entityChkCnt && entityChkCnt !== 0){
        setStarOrUnstar_Search("Unstar");
      }else{
        setStarOrUnstar_Search("Star");
      }

      if(hiddenCnt === entityChkCnt && entityChkCnt !== 0){
        setHideOrUnhide_Search("Unhide");
      }else{
        setHideOrUnhide_Search("Hide");
      }
    }

    dispatch(onChangeContactSearchList(_searchList));
  }

  const entityChkSearch = () => {
    let chkCnt = 0;
    searchList.map((entity, entityIdx) =>{
      if(entity.checked === true) chkCnt++;
    }); 

    if(chkCnt === 0){
      dispatch(onSetContacsResult(1));
      return false;
    }

    return true;
  }

  return (
    <React.Fragment>
      <Form onSubmit={filterValidation.handleSubmit}>
        <div className="page-content">
          <div className="container-fluid">
            <Nav tabs className="col-md-6 nav-tabs-custom nav-justified">
              <NavItem>
                <NavLink
                  style={{ cursor: "pointer" }}
                  className={classnames({
                    active: activeTab === "Manage",
                  })}
                  onClick={() => {
                    toggleActiveTab("Manage")
                  }}
                >
                  <span>Manage Contacts</span>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  style={{ cursor: "pointer" }}
                  className={classnames({
                    active: activeTab === "Upload",
                  })}
                  onClick={() => {
                    toggleActiveTab("Upload");
                  }}
                >
                  <span>Imported Email Addresses</span>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  style={{ cursor: "pointer" }}
                  className={classnames({
                    active: activeTab === "Search",
                  })}
                  onClick={() => {
                    toggleActiveTab("Search")
                  }}
                >
                  <span>Search entity</span>
                </NavLink>
              </NavItem>
            </Nav>
            <TabContent
              activeTab={activeTab}
              className="text-muted"
            >
              <TabPane tabId="Manage">
                <Row>
                  <Col lg={12}>

                    <Card>
                      <Link onClick={toggle} className="text-dark" to="#">
                        <div className="p-4">
                          <Media className="d-flex align-items-center">
                            <div className="flex-shrink-0 me-3">
                              <i className="uil uil-at text-primary h2"></i>
                            </div>
                            <div className="flex-grow-1 overflow-hidden">
                              <h5 className="font-size-16 mb-1">Contacts</h5>
                              <p className="text-muted mb-0">Manage the contact information of your clients</p>
                            </div>
                            <i className="uil uil-filter font-size-24 text-muted"></i>
                          </Media>
                        </div>
                      </Link>
                      <Collapse isOpen={isOpen}>
                        <div className="p-4 border-top">
                            <div>
                              <Row className="mb-3 align-items-center">
                                <label htmlFor="entityStatus" className="col-md-3 col-form-label">
                                  Entity status
                                </label>
                                <div className="col-md-4 d-flex" style={{justifyContent: "space-between"}}>
                                  <div className="form-check">
                                      <input type="radio" className="form-check-input"
                                        id="onlyNonHidden"
                                        name="entityStatus"
                                        onChange={filterValidation.handleChange}
                                        value="onlyNonHidden"
                                        checked={filterValidation.values.entityStatus === "onlyNonHidden"}
                                      />
                                      <Label className="form-check-label" htmlFor="onlyNonHidden">
                                      Only non-hidden
                                      </Label>
                                  </div>
                                  <div className="form-check">
                                      <input type="radio" className="form-check-input"
                                        id="onlyStarred"
                                        name="entityStatus"
                                        onChange={filterValidation.handleChange}
                                        value="onlyStarred"
                                        checked={filterValidation.values.entityStatus === "onlyStarred"}
                                      />
                                      <Label className="form-check-label" htmlFor="onlyStarred">
                                        Only Starred
                                      </Label>
                                  </div>
                                  <div className="form-check">
                                      <input type="radio" className="form-check-input"
                                        id="onlyHidden"
                                        name="entityStatus"
                                        onChange={filterValidation.handleChange}
                                        value="onlyHidden"
                                        checked={filterValidation.values.entityStatus === "onlyHidden"}
                                      />
                                      <Label className="form-check-label" htmlFor="onlyHidden">
                                        Only Hidden
                                      </Label>
                                  </div>
                                  <div className="form-check">
                                      <input type="radio" className="form-check-input"
                                        id="entityStatusAll"
                                        name="entityStatus"
                                        onChange={filterValidation.handleChange}
                                        value=""
                                        checked={filterValidation.values.entityStatus === ""}
                                      />
                                      <Label className="form-check-label" htmlFor="entityStatusAll">
                                      All
                                      </Label>
                                  </div>
                                </div>
                              </Row>
                              <Row className="mb-3">
                                <label htmlFor="entityName" className="col-md-3 col-form-label">
                                Entity name
                                </label>
                                <div className="col-md-3">
                                <Input className="form-control"
                                    type="text"
                                    placeholder="Name of holder or representative"
                                    id="entityName"
                                    name="entityName"
                                    onChange={filterValidation.handleChange}
                                    value={filterValidation.values.entityName}
                                    onKeyPress={(e)=>{
                                      if (e.key === 'Enter') {
                                        filterValidation.setFieldValue("submitType", "view");
                                        filterValidation.handleSubmit(filterValidation.values);
                                      }
                                    }}
                                /></div>
                              </Row>
                              <Row>
                                <label htmlFor="lastUpdated" className="col-md-3 col-form-label">
                                  Last updated
                                </label>
                                <div className="col-md-9">
                                  <PeriodBtn formik={filterValidation}/>
                                </div>
                              </Row>
                              {/*
                              <Row className="mb-3">
                                <label htmlFor="entityWebDomain" className="col-md-3 col-form-label">
                                Entity web domain
                                </label>
                                <div className="col-md-3">
                                <Input className="form-control"
                                    type="text"
                                    placeholder="Web domain of holder or representative"
                                    id="entityWebDomain"
                                    name="entityWebDomain"
                                    onChange={filterValidation.handleChange}
                                    value={filterValidation.values.entityWebDomain}
                                /></div>
                              </Row>
                              */}
                              
                              <Row className="mb-3">
                                <label htmlFor="contactEmail" className="col-md-3 col-form-label">
                                Contact email address
                                </label>
                                <div className="col-md-3">
                                <Input className="form-control"
                                    type="text"
                                    placeholder="Email address of contact"
                                    id="contactEmail"
                                    name="contactEmail"
                                    onChange={filterValidation.handleChange}
                                    value={filterValidation.values.contactEmail}
                                    onKeyPress={(e)=>{
                                      if (e.key === 'Enter') {
                                        filterValidation.setFieldValue("submitType", "view");
                                        filterValidation.handleSubmit(filterValidation.values);
                                      }
                                    }}
                                /></div>
                              </Row>
                              <Row className="mb-3">
                                <label htmlFor="contactName" className="col-md-3 col-form-label">
                                Contact name
                                </label>
                                <div className="col-md-3">
                                <Input className="form-control"
                                    type="text"
                                    placeholder="First or last name of contact"
                                    id="contactName"
                                    name="contactName"
                                    onChange={filterValidation.handleChange}
                                    value={filterValidation.values.contactName}
                                    onKeyPress={(e)=>{
                                      if (e.key === 'Enter') {
                                        filterValidation.setFieldValue("submitType", "view");
                                        filterValidation.handleSubmit(filterValidation.values);
                                      }
                                    }}
                                /></div>
                              </Row>
                              <Row className="mb-3">
                                <label htmlFor="contactTitle" className="col-md-3 col-form-label">
                                Contact title
                                </label>
                                <div className="col-md-3">
                                <Input className="form-control"
                                    type="text"
                                    placeholder="Title of contact"
                                    id="contactTitle"
                                    name="contactTitle"
                                    onChange={filterValidation.handleChange}
                                    value={filterValidation.values.contactTitle}
                                    onKeyPress={(e)=>{
                                      if (e.key === 'Enter') {
                                        filterValidation.setFieldValue("submitType", "view");
                                        filterValidation.handleSubmit(filterValidation.values);
                                      }
                                    }}
                                /></div>
                              </Row>
                              <Row className="mb-3">
                                <label htmlFor="contactPosition" className="col-md-3 col-form-label">
                                Contact position
                                </label>
                                <div className="col-md-3">
                                <Input className="form-control"
                                    type="text"
                                    placeholder="Position of contact"
                                    id="contactPosition"
                                    name="contactPosition"
                                    onChange={filterValidation.handleChange}
                                    value={filterValidation.values.contactPosition}
                                    onKeyPress={(e)=>{
                                      if (e.key === 'Enter') {
                                        filterValidation.setFieldValue("submitType", "view");
                                        filterValidation.handleSubmit(filterValidation.values);
                                      }
                                    }}
                                /></div>
                              </Row>
                              <Row className="mb-3 align-items-center">
                                <label htmlFor="contactStatus" className="col-md-3 col-form-label">
                                  Contact status
                                </label>
                                <div className="col-md-4 d-flex" style={{justifyContent: "space-between"}}>
                                  <div className="col-md-1 form-check">
                                      <input type="radio" className="form-check-input"
                                        id="contactStatusAll"
                                        name="contactStatus"
                                        onChange={filterValidation.handleChange}
                                        value=""
                                        checked={filterValidation.values.contactStatus === ""}
                                      />
                                      <Label className="form-check-label" htmlFor="contactStatusAll">
                                      Both
                                      </Label>
                                  </div>
                                  <div className="col-md-1 form-check">
                                      <input type="radio" className="form-check-input"
                                        id="contactStatusConfirmed"
                                        name="contactStatus"
                                        onChange={filterValidation.handleChange}
                                        value="N"
                                        checked={filterValidation.values.contactStatus === "N"}
                                      />
                                      <Label className="form-check-label" htmlFor="contactStatusConfirmed">
                                      Confirmed
                                      </Label>
                                  </div>
                                  <div className="col-md-1 form-check">
                                      <input type="radio" className="form-check-input"
                                        id="contactStatusUnsub"
                                        name="contactStatus"
                                        onChange={filterValidation.handleChange}
                                        value="Y"
                                        checked={filterValidation.values.contactStatus === "Y"}
                                      />
                                      <Label className="form-check-label" htmlFor="contactStatusUnsub">
                                      Unsubscribed
                                      </Label>
                                  </div>
                                </div>
                                
                              </Row>
                              <Row className="mb-3">
                                <span className="col-md-6 button-items">
                                  <Button type="button" color="primary" outline className="waves-effect waves-light"
                                    onClick={()=>{
                                      filterValidation.setFieldValue("submitType", "saveSettings");
                                      filterValidation.handleSubmit(filterValidation.values);
                                    }}
                                  >Save settings
                                  </Button>
                                  {userGrade === "00" ?
                                  <>
                                  <Button type="button" color="disable" outline className="waves-effect waves-light" id="btn_down_contacts"
                                  >Download contacts
                                  </Button>
                                  <UncontrolledTooltip placement="top" target="btn_down_contacts">
                                  Contact us at global@wecrest.com to access all features.
                                  </UncontrolledTooltip>
                                  </>
                                  :
                                  <Button type="button" color="primary" outline className="waves-effect waves-light"
                                    onClick={()=>{
                                      filterValidation.setFieldValue("submitType", "excel");
                                      filterValidation.handleSubmit(filterValidation.values);
                                    }}
                                  >Download contacts
                                  </Button>
                                  }
                                  {/*
                                  <Button type="button" color="primary" outline className="waves-effect waves-light"
                                    onClick={()=>{ dispatch(onSetUploadContactsOpen(true));}}
                                  >Upload contacts
                                  </Button>
                                  */}
                                </span>
                                <Col md={3}>
                                  <button type="button" className="waves-effect waves-light btn btn-primary" style={{width : '148.58px'}}
                                    onClick={()=>{
                                      setCurPageInput(1);
                                      filterValidation.setFieldValue("curPage", 1);
                                      filterValidation.setFieldValue("submitType", "view");
                                      filterValidation.handleSubmit(filterValidation.values);
                                    }}
                                  >Search
                                  </button>
                                </Col>
                              </Row>
                              
                            </div>
                        </div>
                      </Collapse>
                    </Card>
                  </Col>
                </Row>
                
                {noticeStr && noticeStr !== '' ?
                <Row>
                <Col lg={12}>
                  <Card>
                    <CardBody>
                        <CardTitle className="text-muted mb-0">
                        <span>{noticeStr}</span>
                        </CardTitle>
                    </CardBody>
                  </Card>
                </Col>
                </Row>
                : null}
                
                {mainList && mainList.length ?
                <Row>
                  <Col lg={12}>
                    <Card>
                      <CardBody>
                          <CardTitle className="text-muted mb-0">
                            <Row className="mb-3">
                              <span className="col-md-6 button-items">
                                <Button type="button" color="primary" outline className="waves-effect waves-light"
                                  onClick={() => {
                                    if(entityChk()){
                                      filterValidation.setFieldValue("submitType", starOrUnstar);
                                      setConfirmMsg("Are you sure you want to "+starOrUnstar.toLowerCase()+" the selected entity?");
                                      setConfirmAlert(true);
                                    }
                                  }}
                                >{starOrUnstar} entity
                                </Button>
                                <Button type="button" color="primary" outline className="waves-effect waves-light"
                                  onClick={() => {
                                    if(entityChk()){
                                      filterValidation.setFieldValue("submitType", hideOrUnhide);
                                      setConfirmMsg("Are you sure you want to "+hideOrUnhide.toLowerCase()+" the selected entity?");
                                      setConfirmAlert(true);
                                    }
                                  }}
                                >{hideOrUnhide} entity
                                </Button>
                              </span>
                            </Row>
                            <Row className="mb-3">
                              <span className="col-md-6 button-items">
                                <Button type="button" color="primary" outline className="waves-effect waves-light"
                                  onClick={() => {
                                    if(saveValidation()){
                                      filterValidation.setFieldValue("submitType", "Save");
                                      setConfirmMsg("Are you sure you want to save the selected email(s)?");
                                      setConfirmAlert(true);
                                    }
                                  }}
                                >Save contact
                                </Button>
                                {/*
                                <Button type="button" color="primary" outline className="waves-effect waves-light"
                                  onClick={() => {
                                    if(contactChk()){
                                      filterValidation.setFieldValue("submitType", "Confirmed");
                                      setConfirmMsg("Are you sure you want to confirm the selected email(s)?");
                                      setConfirmAlert(true);
                                    }
                                  }}
                                >Confirmed contact
                                </Button>
                                */}
                                <Button type="button" color="primary" outline className="waves-effect waves-light"
                                  onClick={() => {
                                    if(contactChk()){
                                      filterValidation.setFieldValue("submitType", "Unsubscribed");
                                      setConfirmMsg("Are you sure you want to unsubscribe the selected email(s)?");
                                      setConfirmAlert(true);
                                    }
                                  }}
                                >Unsubscribe contact
                                </Button>
                                <Button type="button" color="primary" outline className="waves-effect waves-light"
                                  onClick={() => {
                                    if(contactChk()){
                                      filterValidation.setFieldValue("submitType", "Delete");
                                      setConfirmMsg("Are you sure you want to delete the selected email(s)?");
                                      setConfirmAlert(true);
                                    }
                                  }}
                                >Delete contact
                                </Button>
                              </span>
                            </Row>
                          </CardTitle>
                          <div className="table-responsive">
                            <Table className="table mb-0">
                              <thead>
                                <tr>
                                <th style={{verticalAlign: "middle", textAlign: "center"}}><input type="checkbox" checked={chkAllFlag} onClick={(e)=>entityCheck("chkAll", e.target.checked)} /></th>
                                <th>Entity</th>
                                <th style={{width: "115px", textAlign: "center"}}>Last updated</th>
                                <th></th>
                                {/*
                                <th width="10%">Type</th>
                                */}
                                <th>Email address*</th>
                                <th>First name</th>
                                <th>Last name</th>
                                <th>Title</th>
                                <th>Position</th>
                                <th style={{textAlign: "center"}}>Send?</th>
                                <th></th>
                                </tr>
                              </thead>
                              <tbody>
                              {
                                  mainList.map((entity, entityIdx) => {
                                      return entity.contactsList.map((contact, contactIdx) => {
                                          return contactIdx === 0 ? 
                                              (
                                                  <tr key={entityIdx}>
                                                    <td rowSpan={entity.contactsList.length} style={entity.contactsList.length > 1 ? {paddingTop: "23px", textAlign: "center"}:{verticalAlign: "middle", textAlign: "center"}}>
                                                      <input type="checkbox" 
                                                        checked={entity.checked}
                                                        onClick={(e)=>entityCheck(entityIdx, e.target.checked)}
                                                        />
                                                    </td>
                                                    <td rowSpan={entity.contactsList.length} style={entity.contactsList.length > 1 ? {paddingTop: "23px"}:{verticalAlign: "middle"}}>
                                                      <div className="d-flex gap-2">
                                                        <Link to="#" className={entity.starred? "text-warning": "text-muted"}
                                                          onClick={(e)=> {
                                                            filterValidation.setFieldValue("submitType", entity.starred? "Unstar_"+ entity.targetGroupNo: "Star_"+ entity.targetGroupNo)
                                                            filterValidation.handleSubmit(filterValidation.values);
                                                          }}>
                                                          <i className="uil uil-star font-size-16" id={"Starred_"+entityIdx+"_"+contactIdx}></i>
                                                          <UncontrolledTooltip placement="top" target={"Starred_"+entityIdx+"_"+contactIdx}>
                                                            {entity.starred? "Remove from starred": "Star"}
                                                          </UncontrolledTooltip>
                                                        </Link>
                                                        <Link to="#" className={entity.hidden? "text-danger": "text-muted"}
                                                              onClick={(e)=> {
                                                                filterValidation.setFieldValue("submitType", entity.hidden? "Unhide_"+ entity.targetGroupNo: "Hide_"+ entity.targetGroupNo)
                                                                filterValidation.handleSubmit(filterValidation.values);
                                                              }}>
                                                          <i className="uil uil-eye-slash font-size-16" id={"Hidden_"+entityIdx+"_"+contactIdx}></i>
                                                          <UncontrolledTooltip placement="top" target={"Hidden_"+entityIdx+"_"+contactIdx}>
                                                            {entity.hidden? "Unhide": "Hide"}
                                                          </UncontrolledTooltip>
                                                        </Link>
                                                        {/*to={"/contacts/details/" + entity.targetGroupNo}*/}
                                                        <Link to="#" onClick={() => { togContactsScreen(entity.targetGroupNo) }} >{entity.targetGroupName}</Link>
                                                        <Link to="#" id={"copy_entity_"+entityIdx}
                                                          onClick={()=>{
                                                            if (window.isSecureContext && navigator.clipboard) {
                                                              navigator.clipboard.writeText(entity.targetGroupName);
                                                            } else {
                                                              // console.log('old copy clipboard!');
                                                              unsecuredCopyToClipboard(entity.targetGroupName, "copy_entity_"+entityIdx);
                                                            }
                                                            showSuccessCopyToast();
                                                          }}>
                                                          <i className="uil uil-copy-alt font-size-22 text-secondary"></i>
                                                        </Link>
                                                        <UncontrolledTooltip placement="top" target={"copy_entity_"+entityIdx}>
                                                          Copy entity to clipboard
                                                        </UncontrolledTooltip>
                                                      </div>
                                                    </td>
                                                    <td rowSpan={entity.contactsList.length} style={entity.contactsList.length > 1 ? {paddingTop: "23px", textAlign: "center"}:{verticalAlign: "middle", textAlign: "center"}}>
                                                      {entity.lastTargetEmailModDate}
                                                    </td>
                                                    <td style={{verticalAlign: "middle", textAlign: "center"}}>
                                                      <input type="checkbox" 
                                                        checked={contact.checked}
                                                        onClick={(e)=>contactCheck(entityIdx, contactIdx, e.target.checked)}
                                                        />
                                                    </td>
                                                    {/*
                                                    <td>
                                                    <select className="form-select"
                                                        value={contact.type}
                                                        onChange={(e) => handleTypeSelect(entityIdx, contactIdx, e.target.value)}
                                                      >
                                                        <option value="To">To</option>
                                                        <option value="cc">cc</option>
                                                      </select>
                                                    </td>
                                                    */}
                                                    <td><input type="text" className="form-control" onChange={(e) => { contact.unsubscribe === "N" && handleInput("emailAddress", entityIdx, contactIdx, e.target.value)}} value={contact.emailAddress ? contact.emailAddress : ""}  disabled={contact.unsubscribe === "Y" ? true : false}/></td>
                                                    <td><input type="text" className="form-control" onChange={(e) => { contact.unsubscribe === "N" && handleInput("firstName", entityIdx, contactIdx, e.target.value)}} value={contact.firstName ? contact.firstName : ""} disabled={contact.unsubscribe === "Y" ? true : false}/></td>
                                                    <td><input type="text" className="form-control" onChange={(e) => { contact.unsubscribe === "N" && handleInput("lastName", entityIdx, contactIdx, e.target.value)}} value={contact.lastName ? contact.lastName : ""} disabled={contact.unsubscribe === "Y" ? true : false}/></td>
                                                    <td><input type="text" className="form-control" onChange={(e) => { contact.unsubscribe === "N" && handleInput("title", entityIdx, contactIdx, e.target.value)}} value={contact.title ? contact.title : "" } disabled={contact.unsubscribe === "Y" ? true : false}/></td>
                                                    <td><input type="text" className="form-control" onChange={(e) => { contact.unsubscribe === "N" && handleInput("position", entityIdx, contactIdx, e.target.value)}} value={contact.position ? contact.position : ""} disabled={contact.unsubscribe === "Y" ? true : false}/></td>
                                                    <td style={{verticalAlign: "middle"}}>
                                                      <div className="d-flex horizon-align-center">
                                                      {contact.unsubscribe === "Y" ? 
                                                      <>
                                                      <i className="uil uil-envelope-block font-size-22 text-danger" id={"Unsubscribed_"+contactIdx}></i>
                                                      <UncontrolledTooltip placement="top" target={"Unsubscribed_"+contactIdx}>
                                                        Unsubscribed
                                                      </UncontrolledTooltip>
                                                      </>
                                                      :
                                                      <div className="form-check form-switch" style={{marginLeft: "0.5rem"}}>
                                                        <input
                                                          type="checkbox"
                                                          className="form-check-input"
                                                          checked={contact.sendYn === "Y" ? true : false}
                                                          onClick={e => {
                                                            handleInput("sendYn", entityIdx, contactIdx, (e.target.checked ? "Y" : "N"));
                                                          }}
                                                        />
                                                      </div>
                                                      }
                                                      {/* style={{paddingLeft: "1em"}} style={{marginLeft: "0px"}}*/}
                                                      {/*contact.unsubscribe != null ? <>
                                                      <div className={contact.unsubscribe === "N"? "text-success": "text-primary"} style={contact.unsubscribe === "N"? null : {cursor : "not-allowed"}}>
                                                        <i className="uil uil-envelope-check font-size-22" id={"Confirmed_"+entityIdx+"_"+contactIdx}></i>
                                                        <UncontrolledTooltip placement="top" target={"Confirmed_"+entityIdx+"_"+contactIdx}>
                                                          Confirmed
                                                        </UncontrolledTooltip>
                                                      </div> 
                                                      <Link to="#" className={contact.unsubscribe === "Y"? "text-danger": null}
                                                            onClick={(e)=> {
                                                              filterValidation.setFieldValue("submitType", "Unsubscribed_"+contact.targetGroupNo+"_"+contact.emailSeq);
                                                              filterValidation.handleSubmit(filterValidation.values);
                                                            }}>
                                                        <i className="uil uil-envelope-block font-size-22" id={"Unsubscribed_"+entityIdx+"_"+contactIdx}></i>
                                                        <UncontrolledTooltip placement="top" target={"Unsubscribed_"+entityIdx+"_"+contactIdx}>
                                                          Unsubscribed
                                                        </UncontrolledTooltip>
                                                      </Link> 
                                                          </>:null */}
                                                      </div>
                                                    </td>
                                                    <td style={{verticalAlign: "middle", textAlign: "center"}}>
                                                      {contactIdx+1 === entity.contactsList.length ?
                                                        <Link to="#" onClick={(e) => addContacts(entity.targetGroupNo, entityIdx)}>
                                                          <i className="uil uil-plus-square font-size-22" id={"add_"+entityIdx}></i>
                                                          <UncontrolledTooltip placement="top" target={"add_"+entityIdx}>
                                                            Add more contacts
                                                          </UncontrolledTooltip>
                                                        </Link>
                                                      :null}
                                                    </td>
                                                  </tr>
                                                ) : (
                                                  <tr className={contact.modFlag === "I" ? "bg-soft-danger" : null}>
                                                    <td style={{verticalAlign: "middle", textAlign: "center"}}>
                                                      <input type="checkbox" 
                                                        checked={contact.checked}
                                                        onClick={(e)=>contactCheck(entityIdx, contactIdx, e.target.checked)}
                                                        />
                                                    </td>
                                                    {/*
                                                    <td>
                                                    <select className="form-select"
                                                        value={contact.type}
                                                        onChange={(e) => handleTypeSelect(entityIdx, contactIdx, e.target.value)}
                                                      >
                                                        <option value="To">To</option>
                                                        <option value="cc">cc</option>
                                                      </select>
                                                    </td>
                                                    */}
                                                    <td><input type="text" className="form-control" onChange={(e) => { contact.unsubscribe === "N" && handleInput("emailAddress", entityIdx, contactIdx, e.target.value)}} value={contact.emailAddress ? contact.emailAddress : ""} disabled={contact.unsubscribe === "Y" ? true : false}/></td>
                                                    <td><input type="text" className="form-control" onChange={(e) => { contact.unsubscribe === "N" && handleInput("firstName", entityIdx, contactIdx, e.target.value)}} value={contact.firstName ? contact.firstName : ""} disabled={contact.unsubscribe === "Y" ? true : false}/></td>
                                                    <td><input type="text" className="form-control" onChange={(e) => { contact.unsubscribe === "N" && handleInput("lastName", entityIdx, contactIdx, e.target.value)}} value={contact.lastName ? contact.lastName : ""} disabled={contact.unsubscribe === "Y" ? true : false}/></td>
                                                    <td><input type="text" className="form-control" onChange={(e) => { contact.unsubscribe === "N" && handleInput("title", entityIdx, contactIdx, e.target.value)}} value={contact.title ? contact.title : ""} disabled={contact.unsubscribe === "Y" ? true : false}/></td>
                                                    <td><input type="text" className="form-control" onChange={(e) => { contact.unsubscribe === "N" && handleInput("position", entityIdx, contactIdx, e.target.value)}} value={contact.position ? contact.position : ""} disabled={contact.unsubscribe === "Y" ? true : false}/></td>
                                                    <td style={{verticalAlign: "middle"}}>
                                                      <div className="d-flex horizon-align-center">
                                                      {contact.unsubscribe === "Y" ? 
                                                      <>
                                                      <i className="uil uil-envelope-block font-size-22 text-danger" id={"Unsubscribed_"+contactIdx}></i>
                                                      <UncontrolledTooltip placement="top" target={"Unsubscribed_"+contactIdx}>
                                                        Unsubscribed
                                                      </UncontrolledTooltip>
                                                      </>
                                                      :
                                                      <div className="form-check form-switch" style={{marginLeft: "0.5rem"}}>
                                                        <input
                                                          type="checkbox"
                                                          className="form-check-input"
                                                          checked={contact.sendYn === "Y" ? true : false}
                                                          onClick={e => {
                                                            handleInput("sendYn", entityIdx, contactIdx, (e.target.checked ? "Y" : "N"));
                                                          }}
                                                        />
                                                      </div>
                                                      }
                                                      {/*contact.unsubscribe != null ? <>
                                                      <div className={contact.unsubscribe === "N"? "text-success": "text-primary"} style={contact.unsubscribe === "N"? null : {cursor : "not-allowed"}}>
                                                        <i className="uil uil-envelope-check font-size-22" id={"Confirmed_"+entityIdx+"_"+contactIdx}></i>
                                                        <UncontrolledTooltip placement="top" target={"Confirmed_"+entityIdx+"_"+contactIdx}>
                                                          Confirmed
                                                        </UncontrolledTooltip>
                                                      </div>
                                                      <Link to="#" className={contact.unsubscribe === "Y"? "text-danger": null}
                                                            onClick={(e)=> {
                                                              filterValidation.setFieldValue("submitType", "Unsubscribed_"+contact.targetGroupNo+"_"+contact.emailSeq);
                                                              filterValidation.handleSubmit(filterValidation.values);
                                                            }}>
                                                        <i className="uil uil-envelope-block font-size-22" id={"Unsubscribed_"+entityIdx+"_"+contactIdx}></i>
                                                        <UncontrolledTooltip placement="top" target={"Unsubscribed_"+entityIdx+"_"+contactIdx}>
                                                          Unsubscribed
                                                        </UncontrolledTooltip>
                                                      </Link> 
                                                          </>:null */}
                                                      </div>
                                                    </td>
                                                    <td style={{verticalAlign: "middle", textAlign: "center"}}>
                                                      {contactIdx+1 === entity.contactsList.length ?
                                                        <Link to="#" onClick={(e) => addContacts(entity.targetGroupNo, entityIdx)}>
                                                          <i className="uil uil-plus-square font-size-22" id={"add_"+entityIdx}></i>
                                                          <UncontrolledTooltip placement="top" target={"add_"+entityIdx}>
                                                            Add more contacts
                                                          </UncontrolledTooltip>
                                                        </Link>
                                                      :null}
                                                    </td>
                                                  </tr>
                                                )
                                          })
                                    })
                                  }
                              </tbody>
                            </Table>
                          </div>
                          <div className="d-flex page-nation-align">
                            <span className="d-flex align-items-center gap-2">
                              <label
                                htmlFor="perPage"
                                className="d-none d-sm-block"
                                >
                                Entries per page
                              </label>
                              <div style={{width: "100px"}} className="d-none d-sm-block">
                                <select 
                                  className="form-select" 
                                  name="perPage"
                                  onChange={e => {viewContactsList(null, 1, e.target.value);}}
                                  value={filterValidation.values.perPage}
                                >
                                  <option value="10">10</option>
                                  <option value="20">20</option>
                                  <option value="30">30</option>
                                  <option value="40">40</option>
                                  <option value="50">50</option>
                                </select>
                              </div>
                            </span>
                            <span className="d-flex align-items-center gap-2">
                              <div className="d-flex gap-1">
                                <Button
                                  type="button"
                                  color="primary"
                                  onClick={()=>{viewContactsList(null, 1);}}
                                  disabled={(filterValidation.values.curPage == 1)}
                                >
                                  {"<<"}
                                </Button>
                                <Button
                                  type="button"
                                  color="primary"
                                  onClick={()=>{viewContactsList(null, filterValidation.values.curPage-1);}}
                                  disabled={(filterValidation.values.curPage == 1)}
                                >
                                  {"<"}
                                </Button>
                              </div>
                              Page{" "}
                              <strong>
                                {filterValidation.values.curPage} of {totalPage}
                              </strong>
                              <Input
                                type="number"
                                min={1}
                                style={{ width: 70 }}
                                max={totalPage}
                                name="curPage"
                                defaultValue={filterValidation.values.curPage}
                                value={curPageInput}
                                onChange={(e) => { setCurPageInput(e.target.value)}}
                                onKeyPress={(e) => {
                                  if(e.key === 'Enter'){
                                    viewContactsList(null, e.target.value);
                                  }
                                }}
                                className="d-none d-sm-block"
                              />
                              <div className="d-flex gap-1">
                                <Button 
                                  type="button"
                                  color="primary"
                                  onClick={()=>{viewContactsList(null, filterValidation.values.curPage+1);}}
                                  disabled={(filterValidation.values.curPage == totalPage)}
                                >
                                  {">"}
                                </Button>
                                <Button
                                  type="button"
                                  color="primary"
                                  onClick={()=>{viewContactsList(null, totalPage);}}
                                  disabled={(filterValidation.values.curPage == totalPage)}
                                >
                                  {">>"}
                                </Button>
                              </div>
                            </span>
                          </div>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
                :null}
              </TabPane>
              <TabPane tabId="Upload">
                <Row>
                  <Col lg={12}>
                  <Card>
                      <Link onClick={toggle} className="text-dark" to="#">
                        <div className="p-4">
                          <Media className="d-flex align-items-center">
                            <div className="flex-shrink-0 me-3">
                              <i className="uil uil-at text-primary h2"></i>
                            </div>
                            <div className="flex-grow-1 overflow-hidden">
                              <h5 className="font-size-16 mb-1">Imported Email Addresses</h5>
                              <p className="text-muted mb-0">Bulk import email addresses via CSV file and manage imported email addresses</p>
                            </div>
                            <i className="uil uil-filter font-size-24"></i>
                            {/* 
                            <i className={isOpen ? "mdi mdi-chevron-up accor-down-icon font-size-24" : "mdi mdi-chevron-down accor-down-icon font-size-24"}></i>
                            */}
                          </Media>
                        </div>
                      </Link>
                      <Collapse isOpen={isOpen}>
                        <div className="p-4 border-top">
                            <div>
                              <Row className="mb-3 col-md-6">
                                <Button type="button" color="danger" className="waves-effect waves-light"
                                    onClick={()=>{ dispatch(onSetUploadContactsOpen(true));}}
                                    id={"uploadCsv"}
                                  > <i className="uil uil-upload-alt"></i> Import email addresses
                                  </Button>
                                  <UncontrolledTooltip placement="top" target={"uploadCsv"}>
                                  Import email addresses (csv file)
                                  </UncontrolledTooltip>
                              </Row>
                              <Row className="mb-3">
                                <h5 className="font-size-16 mb-1"><i className="uil uil-search"></i> Search existing imports</h5>
                              </Row>
                              <Row className="mb-3">
                                <label htmlFor="emailAddress" className="col-md-3 col-form-label">
                                Email address
                                </label>
                                <div className="col-md-3">
                                <Input className="form-control"
                                    type="text"
                                    placeholder="Email address"
                                    id="emailAddress"
                                    name="emailAddress"
                                    onChange={filterValidation.handleChange}
                                    value={filterValidation.values.emailAddress}
                                    onKeyPress={(e)=>{
                                      if (e.key === 'Enter') {
                                        filterValidation.setFieldValue("submitType", "viewUp");
                                        filterValidation.handleSubmit(filterValidation.values);
                                      }
                                    }}
                                /></div>
                              </Row>
                              <Row className="mb-3">
                                <label htmlFor="firstName" className="col-md-3 col-form-label">
                                First name
                                </label>
                                <div className="col-md-3">
                                <Input className="form-control"
                                    type="text"
                                    placeholder="First name"
                                    id="firstName"
                                    name="firstName"
                                    onChange={filterValidation.handleChange}
                                    value={filterValidation.values.firstName}
                                    onKeyPress={(e)=>{
                                      if (e.key === 'Enter') {
                                        filterValidation.setFieldValue("submitType", "viewUp");
                                      filterValidation.handleSubmit(filterValidation.values);
                                      }
                                    }}
                                /></div>
                              </Row>
                              <Row className="mb-3">
                                <label htmlFor="lastName" className="col-md-3 col-form-label">
                                Last name
                                </label>
                                <div className="col-md-3">
                                <Input className="form-control"
                                    type="text"
                                    placeholder="Last name"
                                    id="lastName"
                                    name="lastName"
                                    onChange={filterValidation.handleChange}
                                    value={filterValidation.values.lastName}
                                    onKeyPress={(e)=>{
                                      if (e.key === 'Enter') {
                                        filterValidation.setFieldValue("submitType", "viewUp");
                                      filterValidation.handleSubmit(filterValidation.values);
                                      }
                                    }}
                                /></div>
                              </Row>
                              <Row className="mb-3">
                                <label htmlFor="title" className="col-md-3 col-form-label">
                                Title
                                </label>
                                <div className="col-md-3">
                                <Input className="form-control"
                                    type="text"
                                    placeholder="Title of contact"
                                    id="title"
                                    name="title"
                                    onChange={filterValidation.handleChange}
                                    value={filterValidation.values.title}
                                    onKeyPress={(e)=>{
                                      if (e.key === 'Enter') {
                                        filterValidation.setFieldValue("submitType", "viewUp");
                                      filterValidation.handleSubmit(filterValidation.values);
                                      }
                                    }}
                                /></div>
                              </Row>
                              <Row className="mb-3">
                                <label htmlFor="position" className="col-md-3 col-form-label">
                                Position
                                </label>
                                <div className="col-md-3">
                                <Input className="form-control"
                                    type="text"
                                    placeholder="Position of contact"
                                    id="position"
                                    name="position"
                                    onChange={filterValidation.handleChange}
                                    value={filterValidation.values.position}
                                    onKeyPress={(e)=>{
                                      if (e.key === 'Enter') {
                                        filterValidation.setFieldValue("submitType", "viewUp");
                                      filterValidation.handleSubmit(filterValidation.values);
                                      }
                                    }}
                                /></div>
                                <button type="button" className="waves-effect waves-light btn btn-primary col-md-3" style={{width : '148.58px'}}
                                    onClick={()=>{
                                      setCurPageInputUp(1);
                                      filterValidation.setFieldValue("curPageUp", 1);
                                      filterValidation.setFieldValue("submitType", "viewUp");
                                      filterValidation.handleSubmit(filterValidation.values);
                                    }}
                                  > Search
                                  </button>
                              </Row>
                              {/*
                              <Row className="mb-3 ">
                                <Col className="col-md-6 button-items">
                                  
                                  pc-tablet-menu-block
                                  <Button type="button" color="primary" outline className="waves-effect waves-light"
                                    onClick={()=>{
                                      filterValidation.setFieldValue("submitType", "excelUp");
                                      filterValidation.handleSubmit(filterValidation.values);
                                    }}
                                  ><i className="uil uil-download-alt"></i> Download uploaded contacts
                                  </Button>
                                  <Button type="button" color="primary" outline className="waves-effect waves-light"
                                    onClick={()=>{ dispatch(onSetUploadContactsOpen(true));}}
                                  > <i className="uil uil-upload-alt"></i> Upload contacts (csv file)
                                  </Button>
                                  
                                  <Button type="button" color="primary" outline className="waves-effect waves-light"
                                    onClick={()=>{
                                      filterValidation.setFieldValue("submitType", "excelUp");
                                      filterValidation.handleSubmit(filterValidation.values);
                                    }}
                                    id={"excelUp"} style={{width : '50px'}}
                                  ><i className="uil uil-download-alt"></i>
                                  </Button>
                                  <UncontrolledTooltip placement="top" target={"excelUp"}>
                                  Download
                                  </UncontrolledTooltip>
                                  
                                  <Button type="button" color="danger" outline className="waves-effect waves-light col-md-6"
                                    onClick={()=>{ dispatch(onSetUploadContactsOpen(true));}}
                                    id={"uploadCsv"}
                                  > <i className="uil uil-upload-alt"></i> Import email address
                                  </Button>
                                  <UncontrolledTooltip placement="top" target={"uploadCsv"}>
                                  Import email address (csv file)
                                  </UncontrolledTooltip>
                                  
                                </Col>
                                <Col md={3}>
                                  
                                  <button type="button" className="waves-effect waves-light btn btn-primary" style={{width : '148.58px'}}
                                    onClick={()=>{
                                      filterValidation.setFieldValue("submitType", "viewUp");
                                      filterValidation.handleSubmit(filterValidation.values);
                                    }}
                                  > Search
                                  </button>
                                  //
                                  <button type="button" className="waves-effect waves-light btn btn-primary" style={{width : '50px'}}
                                    onClick={()=>{
                                      filterValidation.setFieldValue("submitType", "viewUp");
                                      filterValidation.handleSubmit(filterValidation.values);
                                    }}
                                    id={"search-upload-email"}
                                  > <i className="uil uil-search"></i>
                                  </button>
                                  <UncontrolledTooltip placement="top" target={"search-upload-email"}>
                                    Search uploaded email address
                                  </UncontrolledTooltip>
                                  
                                </Col>
                              </Row>
                              */}
                              
                              {/*
                              <Row className="mb-3 mobile-menu-block ">
                                <Col className="col-md-6 d-flex justify-content-between">
                                  <span className="d-flex align-items-center gap-2">
                                    
                                    <Button type="button" color="primary" outline className="waves-effect waves-light"
                                      onClick={()=>{
                                        filterValidation.setFieldValue("submitType", "excelUp");
                                        filterValidation.handleSubmit(filterValidation.values);
                                      }}
                                      id={"excelUp"} style={{width : '50px'}}
                                    ><i className="uil uil-download-alt"></i>
                                    </Button>
                                    <UncontrolledTooltip placement="top" target={"excelUp"}>
                                    Download
                                    </UncontrolledTooltip>
                                    
                                    <Button type="button" color="danger" outline className="waves-effect waves-light col-md-6"
                                      onClick={()=>{ dispatch(onSetUploadContactsOpen(true));}}
                                      id={"mb-uploadCsv"}
                                    > <i className="uil uil-upload-alt"></i> Import email address
                                    </Button>
                                    <UncontrolledTooltip placement="top" target={"mb-uploadCsv"}>
                                    Import email address (csv file)
                                    </UncontrolledTooltip>
                                    
                                  </span>
                                      
                                  <button type="button" className="waves-effect waves-light btn btn-primary" style={{width : '50px'}}
                                    onClick={()=>{
                                      filterValidation.setFieldValue("submitType", "viewUp");
                                      filterValidation.handleSubmit(filterValidation.values);
                                    }}
                                    id={"search-upload-email"}
                                  > <i className="uil uil-search"></i>
                                  </button>
                                  <UncontrolledTooltip placement="top" target={"search-upload-email"}>
                                    Search uploaded email address
                                  </UncontrolledTooltip>
                                </Col>
                              </Row>
                              */}
                              
                            </div>
                        </div>
                      </Collapse>
                    </Card>
                  </Col>
                </Row>
                {uploadedContactsList && uploadedContactsList.length ?
                <Row>
                  <Col lg={12}>
                    <Card>
                      <CardBody>
                          <CardTitle className="text-muted mb-0">
                            <Row className="mb-3">
                              <span className="col-md-6 button-items">
                                <Button type="button" color="primary" outline className="waves-effect waves-light"
                                  onClick={() => {
                                    if(saveValidationUp()){
                                      filterValidation.setFieldValue("submitType", "saveUp");
                                      setConfirmMsg("Are you sure you want to save the selected email(s)?");
                                      setConfirmAlert(true);
                                    }
                                  }}
                                  id={"save-contact"}
                                >{/*<i className="bx bx-save"></i>*/}Save contact
                                </Button>
                                {/*
                                <UncontrolledTooltip placement="top" target={"save-contact"}>
                                Save contact
                                </UncontrolledTooltip>
                                */}

                                <Button type="button" color="primary" outline className="waves-effect waves-light"
                                  onClick={() => {
                                    if(contactChkUp()){
                                      filterValidation.setFieldValue("submitType", "deleteUp");
                                      setConfirmMsg("Are you sure you want to delete the selected email(s)?");
                                      setConfirmAlert(true);
                                    }
                                  }}
                                  id={"delete-contact"}
                                >{/*<i className="bx bx-trash"></i>*/}Delete contact
                                </Button>
                                {/*
                                <UncontrolledTooltip placement="top" target={"delete-contact"}>
                                Delete contact
                                </UncontrolledTooltip>

                                <i className="bx bx-save"
                                  style={{fontSize: "30px", color: "#5c8dc6", marginLeft: "8px"}}
                                  onClick={() => {
                                    if(saveValidation()){
                                      filterValidation.setFieldValue("submitType", "saveUp");
                                      setConfirmMsg("Are you sure you want to save the selected email(s)?");
                                      setConfirmAlert(true);
                                    }
                                  }}
                                ></i>

                                <i className="bx bx-trash"
                                  style={{fontSize: "30px", color: "#5c8dc6", marginLeft: "8px"}}
                                  onClick={() => {
                                    if(contactChk()){
                                      filterValidation.setFieldValue("submitType", "deleteUp");
                                      setConfirmMsg("Are you sure you want to delete the selected email(s)?");
                                      setConfirmAlert(true);
                                    }
                                  }}
                                  ></i>
                                */}
                              </span>
                            </Row>
                          </CardTitle>
                          <div className="table-responsive">
                            <Table className="table mb-0">
                              <thead>
                                <tr>
                                <th style={{verticalAlign: "middle", textAlign: "center"}}><input type="checkbox" checked={chkAllFlagUp} onClick={(e)=>entityCheckUp("chkAll", e.target.checked)} /></th>
                                <th>Email address*</th>
                                <th>First name</th>
                                <th>Last name</th>
                                <th>Title</th>
                                <th>Position</th>
                                </tr>
                              </thead>
                              <tbody>
                              {
                                uploadedContactsList.map((entity, entityIdx) => {
                                  return (
                                          <tr key={entityIdx}>
                                            <td style={{verticalAlign: "middle", textAlign: "center"}}>
                                              <input type="checkbox" 
                                                checked={entity.checked}
                                                onClick={(e)=>entityCheckUp(entityIdx, e.target.checked)}
                                                />
                                            </td>
                                            <td>{entity.emailAddress}</td>
                                            <td><input type="text" className="form-control" onChange={(e) => { handleInputUp("firstName", entityIdx, e.target.value)}} value={entity.firstName ? entity.firstName : ""}/></td>
                                            <td><input type="text" className="form-control" onChange={(e) => { handleInputUp("lastName", entityIdx, e.target.value)}} value={entity.lastName ? entity.lastName : ""}/></td>
                                            <td><input type="text" className="form-control" onChange={(e) => { handleInputUp("title", entityIdx, e.target.value)}} value={entity.title ? entity.title : "" }/></td>
                                            <td><input type="text" className="form-control" onChange={(e) => { handleInputUp("position", entityIdx, e.target.value)}} value={entity.position ? entity.position : ""}/></td>
                                          </tr>
                                    )
                                  })
                              }
                              </tbody>
                            </Table>
                          </div>
                          <div className="d-flex page-nation-align">
                            <span className="d-flex align-items-center gap-2">
                              <label
                                htmlFor="perPageUp"
                                className="d-none d-sm-block"
                                >
                                Entries per page
                              </label>
                              <div style={{width: "100px"}} className="d-none d-sm-block">
                                <select 
                                  className="form-select" 
                                  name="perPageUp"
                                  onChange={e => {viewContactsListUp(1, e.target.value);}}
                                  value={filterValidation.values.perPageUp}
                                >
                                  <option value="10">10</option>
                                  <option value="20">20</option>
                                  <option value="30">30</option>
                                  <option value="40">40</option>
                                  <option value="50">50</option>
                                </select>
                              </div>
                            </span>
                            <span className="d-flex align-items-center gap-2">
                              <div className="d-flex gap-1">
                                <Button
                                  type="button"
                                  color="primary"
                                  onClick={()=>{viewContactsListUp(1);}}
                                  disabled={(filterValidation.values.curPageUp == 1)}
                                >
                                  {"<<"}
                                </Button>
                                <Button
                                  type="button"
                                  color="primary"
                                  onClick={()=>{viewContactsListUp(filterValidation.values.curPageUp-1);}}
                                  disabled={(filterValidation.values.curPageUp == 1)}
                                >
                                  {"<"}
                                </Button>
                              </div>
                              Page{" "}
                              <strong>
                                {filterValidation.values.curPageUp} of {totalPageUp}
                              </strong>
                              <Input
                                type="number"
                                min={1}
                                style={{ width: 70 }}
                                max={totalPageUp}
                                name="curPageUp"
                                defaultValue={filterValidation.values.curPageUp}
                                value={curPageInputUp}
                                onChange={(e) => { setCurPageInputUp(e.target.value)}}
                                onKeyPress={(e) => {
                                  if(e.key === 'Enter'){
                                    viewContactsListUp(e.target.value);
                                  }
                                }}
                                className="d-none d-sm-block"
                              />
                              <div className="d-flex gap-1">
                                <Button 
                                  type="button"
                                  color="primary"
                                  onClick={()=>{viewContactsListUp(filterValidation.values.curPageUp+1);}}
                                  disabled={(filterValidation.values.curPageUp == totalPageUp)}
                                >
                                  {">"}
                                </Button>
                                <Button
                                  type="button"
                                  color="primary"
                                  onClick={()=>{viewContactsListUp(totalPageUp);}}
                                  disabled={(filterValidation.values.curPageUp == totalPageUp)}
                                >
                                  {">>"}
                                </Button>
                              </div>
                            </span>
                          </div>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
                :null}
              </TabPane>
              <TabPane tabId="Search">
                <Row>
                  <Col lg={12}>

                    <Card>
                      <Link onClick={toggle} className="text-dark" to="#">
                        <div className="p-4">
                          <Media className="d-flex align-items-center">
                            <div className="flex-shrink-0 me-3">
                              <i className="uil uil-search text-primary h2"></i>
                            </div>
                            <div className="flex-grow-1 overflow-hidden">
                              <h5 className="font-size-16 mb-1">Search</h5>
                              <p className="text-muted mb-0">Search for entities</p>
                            </div>
                            <i className="uil uil-filter font-size-24 text-muted"></i>
                          </Media>
                        </div>
                      </Link>
                      <Collapse isOpen={isOpen}>
                        <div className="p-4 border-top">
                            <div>
                              <Row className="mb-3 align-items-center">
                                <label htmlFor="entityStatusSearch" className="col-md-3 col-form-label">
                                  Entity status
                                </label>
                                <div className="col-md-4 d-flex" style={{justifyContent: "space-between"}}>
                                  <div className="form-check">
                                      <input type="radio" className="form-check-input"
                                        id="onlyStarredSearch"
                                        name="entityStatusSearch"
                                        onChange={filterValidation.handleChange}
                                        value="onlyStarred"
                                        checked={filterValidation.values.entityStatusSearch === "onlyStarred"}
                                      />
                                      <Label className="form-check-label" htmlFor="onlyStarredSearch">
                                        Only Starred
                                      </Label>
                                  </div>
                                  <div className="form-check">
                                      <input type="radio" className="form-check-input"
                                        id="onlyHiddenSearch"
                                        name="entityStatusSearch"
                                        onChange={filterValidation.handleChange}
                                        value="onlyHidden"
                                        checked={filterValidation.values.entityStatusSearch === "onlyHidden"}
                                      />
                                      <Label className="form-check-label" htmlFor="onlyHiddenSearch">
                                        Only Hidden
                                      </Label>
                                  </div>
                                  <div className="form-check">
                                      <input type="radio" className="form-check-input"
                                        id="entityStatusAllSearch"
                                        name="entityStatusSearch"
                                        onChange={filterValidation.handleChange}
                                        value=""
                                        checked={filterValidation.values.entityStatusSearch === ""}
                                      />
                                      <Label className="form-check-label" htmlFor="entityStatusAllSearch">
                                      All
                                      </Label>
                                  </div>
                                </div>
                              </Row>
                              <Row className="mb-3">
                                <label htmlFor="searchName" className="col-md-3 col-form-label">
                                Name of holder or representative 
                                </label>
                                <div className="col-md-3">
                                <Input className="form-control"
                                    type="text"
                                    placeholder="Name of holder or representative"
                                    id="searchName"
                                    name="searchName"
                                    onChange={filterValidation.handleChange}
                                    value={filterValidation.values.searchName}
                                    onKeyPress={(e)=>{
                                      if (e.key === 'Enter') {
                                        filterValidation.setFieldValue("submitType", "viewSearch");
                                        filterValidation.handleSubmit(filterValidation.values);
                                      }
                                    }}
                                /></div>
                              </Row>

                              <Row className="mb-3">
                                <label htmlFor="searchDomain" className="col-md-3 col-form-label">
                                Domain
                                </label>
                                <div className="col-md-3">
                                <Input className="form-control"
                                    type="text"
                                    placeholder="Domain"
                                    id="searchDomain"
                                    name="searchDomain"
                                    onChange={filterValidation.handleChange}
                                    value={filterValidation.values.searchDomain}
                                    onKeyPress={(e)=>{
                                      if (e.key === 'Enter') {
                                        filterValidation.setFieldValue("submitType", "viewSearch");
                                        filterValidation.handleSubmit(filterValidation.values);
                                      }
                                    }}
                                /></div>
                              </Row>

                              <SelectCountry
                                formik={filterValidation}
                                componentName='SearchEntity'
                              />

                              <Row className="mb-3">
                                <label htmlFor="searchAddress" className="col-md-3 col-form-label">
                                Address
                                </label>
                                <div className="col-md-3">
                                <Input className="form-control"
                                    type="text"
                                    placeholder="Address"
                                    id="searchAddress"
                                    name="searchAddress"
                                    onChange={filterValidation.handleChange}
                                    value={filterValidation.values.searchAddress}
                                    onKeyPress={(e)=>{
                                      if (e.key === 'Enter') {
                                        filterValidation.setFieldValue("submitType", "viewSearch");
                                        filterValidation.handleSubmit(filterValidation.values);
                                      }
                                    }}
                                /></div>
                              </Row>

                              <Row className="mb-3">
                                <span className="col-md-3">
                                  <Button type="button" color="primary" outline className="waves-effect waves-light"
                                    onClick={()=>{
                                      filterValidation.setFieldValue("submitType", "excelSearch");
                                      filterValidation.handleSubmit(filterValidation.values);
                                    }}
                                  >Download excel
                                  </Button>
                                </span>
                                <div className="col-md-3"></div>
                                <Col md={3}>
                                  <button type="button" className="waves-effect waves-light btn btn-primary" style={{width : '148.58px'}}
                                    onClick={()=>{
                                      setCurPageSearch(1);
                                      filterValidation.setFieldValue("curPageSearch", 1);
                                      filterValidation.setFieldValue("submitType", "viewSearch");
                                      filterValidation.handleSubmit(filterValidation.values);
                                    }}
                                  >Search
                                  </button>
                                </Col>
                              </Row>
                              
                            </div>
                        </div>
                      </Collapse>
                    </Card>
                  </Col>
                </Row>
                
                
                {searchList && searchList.length ?
                <Row>
                  <Col lg={12}>
                    <Card>
                      <CardBody>
                          <CardTitle className="text-muted mb-0">
                            <Row className="mb-3">
                              <span className="col-md-6 button-items">
                                <Button type="button" color="primary" outline className="waves-effect waves-light"
                                  onClick={() => {
                                    if(entityChkSearch()){
                                      filterValidation.setFieldValue("submitType", starOrUnstar_Search+"Search");
                                      setConfirmMsg("Are you sure you want to "+starOrUnstar_Search.toLowerCase()+" the selected entity?");
                                      setConfirmAlert(true);
                                    }
                                  }}
                                >{starOrUnstar_Search} entity
                                </Button>
                                <Button type="button" color="primary" outline className="waves-effect waves-light"
                                  onClick={() => {
                                    if(entityChkSearch()){
                                      filterValidation.setFieldValue("submitType", hideOrUnhide_Search+"Search");
                                      setConfirmMsg("Are you sure you want to "+hideOrUnhide_Search.toLowerCase()+" the selected entity?");
                                      setConfirmAlert(true);
                                    }
                                  }}
                                >{hideOrUnhide_Search} entity
                                </Button>
                              </span>
                            </Row>
                          </CardTitle>
                          <div className="table-responsive">
                            <Table className="table mb-0">
                              <thead>
                                <tr>
                                <th style={{verticalAlign: "middle", textAlign: "center"}}><input type="checkbox" checked={chkAllFlag_Search} onClick={(e)=>entityCheckSearch("chkAll", e.target.checked)} /></th>
                                <th>Entity</th>
                                <th>Address</th>
                                <th>Domain</th>
                                </tr>
                              </thead>
                              <tbody>
                              {
                                  searchList.map((entity, entityIdx) => {
                                      return (
                                              <tr key={entityIdx}>
                                                <td style={{verticalAlign: "middle", textAlign: "center"}}>
                                                  <input type="checkbox" 
                                                    checked={entity.checked}
                                                    onClick={(e)=>entityCheckSearch(entityIdx, e.target.checked)}
                                                    />
                                                </td>
                                                <td style={{verticalAlign: "middle"}}>
                                                  <div className="d-flex gap-2">
                                                    <Link to="#" className={entity.starred? "text-warning": "text-muted"}
                                                      onClick={(e)=> {
                                                        filterValidation.setFieldValue("submitType", entity.starred? "UnstarSearch_"+ entity.targetGroupNo: "StarSearch_"+ entity.targetGroupNo)
                                                        filterValidation.handleSubmit(filterValidation.values);
                                                      }}>
                                                      <i className="uil uil-star font-size-16" id={"StarredSearch_"+entityIdx}></i>
                                                      <UncontrolledTooltip placement="top" target={"StarredSearch_"+entityIdx}>
                                                        {entity.starred? "Remove from starred": "Star"}
                                                      </UncontrolledTooltip>
                                                    </Link>
                                                    <Link to="#" className={entity.hidden? "text-danger": "text-muted"}
                                                          onClick={(e)=> {
                                                            filterValidation.setFieldValue("submitType", entity.hidden? "UnhideSearch_"+ entity.targetGroupNo: "HideSearch_"+ entity.targetGroupNo)
                                                            filterValidation.handleSubmit(filterValidation.values);
                                                          }}>
                                                      <i className="uil uil-eye-slash font-size-16" id={"searchHidden_"+entityIdx}></i>
                                                      <UncontrolledTooltip placement="top" target={"searchHidden_"+entityIdx}>
                                                        {entity.hidden? "Unhide": "Hide"}
                                                      </UncontrolledTooltip>
                                                    </Link>
                                                    <Link to="#" 
                                                      className={ entity.memberConfirmEmlYn === "upToDate"? "text-success" 
                                                              : entity.memberConfirmEmlYn === 'Y' ? "text-primary" 
                                                              : entity.suggestEmlYn === 'Y' ? "text-warning" 
                                                              : "text-danger"}
                                                      onClick={() => { togContactsScreen(entity.targetGroupNo) }} >{entity.targetGroupName}</Link>
                                                    <Link to="#" id={"copy_entity_search_"+entityIdx}
                                                      onClick={()=>{
                                                        if (window.isSecureContext && navigator.clipboard) {
                                                          navigator.clipboard.writeText(entity.targetGroupName);
                                                        } else {
                                                          // console.log('old copy clipboard!');
                                                          unsecuredCopyToClipboard(entity.targetGroupName, "copy_entity_search_"+entityIdx);
                                                        }
                                                        showSuccessCopyToast();
                                                      }}>
                                                      <i className="uil uil-copy-alt font-size-22 text-secondary"></i>
                                                    </Link>
                                                    <UncontrolledTooltip placement="top" target={"copy_entity_search_"+entityIdx}>
                                                      Copy entity to clipboard
                                                    </UncontrolledTooltip>
                                                  </div>
                                                </td>
                                                <td style={{verticalAlign: "middle"}}>
                                                  <div className="d-flex gap-2 vertical-align-center">
                                                  {
                                                  entity.contactsDetailList.length > 0 && entity.contactsDetailList.map((contactsDetail, contactsDetailIdx) => {
                                                    return (
                                                      <>{contactsDetail.country + " " + contactsDetail.address}<br/></>
                                                    )
                                                  })
                                                  }
                                                  <Link to="#" id={"copy_address_search_"+entityIdx}
                                                      onClick={()=>{
                                                        let copy_address = "";
                                                        entity.contactsDetailList.length > 0 && entity.contactsDetailList.map((contactsDetail, contactsDetailIdx) => {
                                                          copy_address += contactsDetail.country + " " + contactsDetail.address + "\n";
                                                        })

                                                        if (window.isSecureContext && navigator.clipboard) {
                                                          navigator.clipboard.writeText(copy_address);
                                                        } else {
                                                          unsecuredCopyToClipboard(copy_address, "copy_address_search_"+entityIdx);
                                                        }
                                                        showSuccessCopyToast();
                                                      }}>
                                                      <i className="uil uil-copy-alt font-size-22 text-secondary"></i>
                                                  </Link>
                                                  <UncontrolledTooltip placement="top" target={"copy_address_search_"+entityIdx}>
                                                    Copy address to clipboard
                                                  </UncontrolledTooltip>
                                                  </div>
                                                </td>
                                                <td style={{verticalAlign: "middle"}}>
                                                  <div className="d-flex gap-2">
                                                  {entity.domain}
                                                  <Link to="#" id={"copy_domain_search_"+entityIdx}
                                                      onClick={()=>{
                                                        if (window.isSecureContext && navigator.clipboard) {
                                                          navigator.clipboard.writeText(entity.domain);
                                                        } else {
                                                          unsecuredCopyToClipboard(entity.domain, "copy_domain_search_"+entityIdx);
                                                        }
                                                        showSuccessCopyToast();
                                                      }}>
                                                      <i className="uil uil-copy-alt font-size-22 text-secondary"></i>
                                                  </Link>
                                                  <UncontrolledTooltip placement="top" target={"copy_domain_search_"+entityIdx}>
                                                    Copy domain to clipboard
                                                  </UncontrolledTooltip>
                                                  </div>
                                                </td>
                                              </tr>
                                      )
                                    })
                                  }
                              </tbody>
                            </Table>
                          </div>
                          <div className="d-flex page-nation-align">
                            <span className="d-flex align-items-center gap-2">
                              <label
                                htmlFor="perPageSearch"
                                className="d-none d-sm-block"
                                >
                                Entries per page
                              </label>
                              <div style={{width: "100px"}} className="d-none d-sm-block">
                                <select 
                                  className="form-select" 
                                  name="perPageSearch"
                                  onChange={e => {viewContactsSearchList(null, 1, e.target.value);}}
                                  value={filterValidation.values.perPageSearch}
                                >
                                  <option value="10">10</option>
                                  <option value="20">20</option>
                                  <option value="30">30</option>
                                  <option value="40">40</option>
                                  <option value="50">50</option>
                                </select>
                              </div>
                            </span>
                            <span className="d-flex align-items-center gap-2">
                              <div className="d-flex gap-1">
                                <Button
                                  type="button"
                                  color="primary"
                                  onClick={()=>{viewContactsSearchList(null, 1);}}
                                  disabled={(filterValidation.values.curPageSearch == 1)}
                                >
                                  {"<<"}
                                </Button>
                                <Button
                                  type="button"
                                  color="primary"
                                  onClick={()=>{viewContactsSearchList(null, filterValidation.values.curPageSearch-1);}}
                                  disabled={(filterValidation.values.curPageSearch == 1)}
                                >
                                  {"<"}
                                </Button>
                              </div>
                              Page{" "}
                              <strong>
                                {filterValidation.values.curPageSearch} of {totalPageSearch}
                              </strong>
                              <Input
                                type="number"
                                min={1}
                                style={{ width: 70 }}
                                max={totalPageSearch}
                                name="curPageSearch"
                                defaultValue={filterValidation.values.curPageSearch}
                                value={curPageSearch}
                                onChange={(e) => { setCurPageSearch(e.target.value)}}
                                onKeyPress={(e) => {
                                  if(e.key === 'Enter'){
                                    viewContactsSearchList(null, e.target.value);
                                  }
                                }}
                                className="d-none d-sm-block"
                              />
                              <div className="d-flex gap-1">
                                <Button 
                                  type="button"
                                  color="primary"
                                  onClick={()=>{viewContactsSearchList(null, filterValidation.values.curPageSearch+1);}}
                                  disabled={(filterValidation.values.curPageSearch == totalPageSearch)}
                                >
                                  {">"}
                                </Button>
                                <Button
                                  type="button"
                                  color="primary"
                                  onClick={()=>{viewContactsSearchList(null, totalPageSearch);}}
                                  disabled={(filterValidation.values.curPageSearch == totalPageSearch)}
                                >
                                  {">>"}
                                </Button>
                              </div>
                            </span>
                          </div>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
                :null}
              </TabPane>
            </TabContent>
            <UploadContactsModal />
            {
              loading ?
              <LoadingModal loading={loading} setLoading={setContactsLoading}></LoadingModal>
              : null
            }
            <Modal
              size="xl"
              style={{maxWidth : "1700px"}}
              isOpen={modalContactsScreen}
              toggle={() => {
                togContactsScreen()
              }}
            >
              <div className="modal-body">
                <button
                  onClick={() => {
                    togContactsScreen()
                  }}
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>

                <ContactsDetails targetGroupNo={contactsTargetGroupNo}/>
              </div>
            </Modal>
            {success? (
              <SweetAlert
                title={success}
                timeout={2000}
                showCloseButton={false}
                showConfirm={false}
                success
                onConfirm={() => {
                  dispatch(onSetSuccess(""));
                }}
              ></SweetAlert>
            ) : null}
            {result !== -1 ? (
            <SweetAlert
              onConfirm={() => {
                dispatch(onSetContacsResult(-1));
                setDupeEmailAddress([]);
              }}
            >{result < 9 ? errMsg[result] : <>{errMsg[result]}<br/>{result == 9 ? "["+ dupeEmailAddress + "]" : unsubsEmailAddress}</>}
            </SweetAlert>
            ) : null}
            {confirmAlert ? (
              <SweetAlert
                title="Are you sure?"
                warning
                showCancel
                confirmBtnText="Confirm"
                cancelBtnText="No, go back"
                confirmBtnBsStyle="success"
                cancelBtnBsStyle="danger"
                onConfirm={() => {
                  filterValidation.handleSubmit(filterValidation.values);
                  setConfirmAlert(false);
                }}
                onCancel={() => setConfirmAlert(false)}
              >
                {confirmMsg}
              </SweetAlert>
            ) : null}
          </div>
        </div>
      </Form>
    </React.Fragment>
  )
}

export default Contacts;