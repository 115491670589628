import React, {useState, useEffect, useRef, useCallback} from 'react';
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";
import {
    Table,
    Row,
    Col,
    Card,
    CardBody,
    CardTitle,
    Media,
    Collapse,
    Form,
    Input,
    Label,
    Button,
    UncontrolledTooltip,
  } from "reactstrap"
import SweetAlert from "react-bootstrap-sweetalert";
import PeriodBtn from '../../components/Filter/PeriodBtn';
import { Link } from "react-router-dom";
import * as Yup from "yup";
import { useFormik } from "formik";
import SelectAllJurisdictions from '../../components/Filter/SelectAllJurisdictions';

import {
  setAdmAccountManagementSearchResult as onSetResult,
  getAdmAccountManagementSearchList as onGetAdmAccountManagementSearchList,
  changeAdmAccountManagementSearchList as onChangeAdmAccountManagementSearchList,
  downExcelAdmAccountManagement as onDownExcelAdmAccountManagement,
  updateAccountByAdmin as onUpdateAccountByAdmin,
  updatePasswordByAdmin as onUpdatePasswordByAdmin,
  verifyAccountByAdmin as onVerifyAccountByAdmin,
  virtualLogInByAdmin as onVirtualLogInByAdmin,
  createAccountByAdmin as onCreateAccountByAdmin,
} from "../../store/actions";
import { cloneDeep } from 'lodash';
import dateFormat from 'dateformat';

const AdmAccountManagementSearch = props => {
  const dispatch = useDispatch();

  const { admAccountManagementSearchList, totalPage } = useSelector(state => ({
    admAccountManagementSearchList: state.AdmAccountManagement.admAccountManagementSearchList,
    totalPage: state.AdmAccountManagement.totalPage,
  }));

  const [isOpen, setIsOpen] = useState(true);
  const toggle = () => {
    setIsOpen(!isOpen);
  }

  const [columns, setColumns] = useState([
    {label: "Organization", asc:"CNA", desc:"CND", ascActive:"", descActive:""},
    {label: "Username", asc:"UNA", desc:"UND", ascActive:"", descActive:""},
    {label: "Jur", asc:"JDA", desc:"JDD", ascActive:"", descActive:""},
    {label: "Subs Category", asc:"SCA", desc:"SCD", ascActive:"", descActive:""},
    {label: "Tier", asc:"GRA", desc:"GRD", ascActive:"", descActive:""},
    {label: "Type", asc:"TYA", desc:"TYD", ascActive:"", descActive:""},
    {label: "Start", asc:"STA", desc:"STD", ascActive:"", descActive:"text-danger"},
    {label: "End", asc:"ENA", desc:"END", ascActive:"", descActive:""},
    {label: "Account Status", asc:"ASTAA", desc:"ASTAD", ascActive:"", descActive:""},
    {label: "Subs Type", asc:"STYA", desc:"STYD", ascActive:"", descActive:""},
    {label: "Subs Status", asc:"SSA", desc:"SSD", ascActive:"", descActive:""},
    {label: "WAR Sub", asc:"WARA", desc:"WARD", ascActive:"", descActive:""},
  ]);

  const [curPageInput, setCurPageInput] = useState(1);
  const [chkAllFlag, setChkAllFlag] = useState(false);
  const [chkCnt, setChkCnt] = useState(0);
  const [editAccountInfo, setEditAccountInfo] = useState({});
  const [editSubscriptionInfo, setEditSubscriptionInfo] = useState({});
  const [changePasswordInfo, setChangePasswordInfo] = useState({});
  const [verifyAccountInfo, setVerifyAccountInfo] = useState({});

  // events validation
  const filterValidation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    //enableReinitialize: true,

    initialValues: {
      submitType: "view",
      memberKeyword: "",
      gradeList: ["02", "01", "00"],
      accountTypeList: ["Member", "Trial", "WeCrest"],
      accountStatusList: ["01", "00", "02"],
      inOutTypeList: ["IN", "OUT"],
      subsStatusList: ["Live", "Closed", "Deleted"],
      startDatePeriod: "",
      startBeginDate: undefined,
      startEndDate: undefined,
      endDatePeriod: "",
      changeDatePeriodIsFirst: false,
      endBeginDate: undefined,
      endEndDate: undefined,
      viewMode: "Accounts",
      sortType: "IDD",
      perPage: 50,
      curPage: 1,
      chkUserNoList: [],
    },
    validateOnChange: false, // this one
    validateOnBlur: false, // and this one
    onSubmit: (values) => {
      const filterParam = {
        memberKeyword: values["memberKeyword"],
        gradeList: values["gradeList"],
        accountTypeList: values["accountTypeList"],
        accountStatusList: values["accountStatusList"],
        inOutTypeList: values["inOutTypeList"],
        subsStatusList: values["subsStatusList"],
        startBeginDate: values["startBeginDate"],
        startEndDate: values["startEndDate"],
        endBeginDate: values["endBeginDate"],
        endEndDate: values["endEndDate"],
        viewMode: values["viewMode"],
        sortType: values["sortType"],
        perPage: values["perPage"],
        curPage: values["curPage"],
        chkUserNoList: values["chkUserNoList"],
      };

      let submitType = values["submitType"];
      
      if(submitType === "view"){
        dispatch(onGetAdmAccountManagementSearchList(filterParam));
      } else if(submitType === "excel"){
        const _chkUserNoList = [];

        admAccountManagementSearchList.map((row, index) =>{
          if(row.checked === true){
            _chkUserNoList.push(row.userNo);
          }
        }); 

        filterParam.chkUserNoList = _chkUserNoList;

        let fileName = dateFormat(new Date(), "yymmdd") + " WeCrest Admin Account Management"; 
        
        dispatch(onDownExcelAdmAccountManagement(filterParam, fileName));
      } else if(submitType === "updateAccountByAdmin"){
        if (filterValidation.values.viewMode === 'Accounts') {
          editAccountInfo.viewMode = filterValidation.values.viewMode;
          // console.log('editAccountInfo : ' + JSON.stringify(editAccountInfo));
          dispatch(onUpdateAccountByAdmin(filterParam, editAccountInfo));
          setEditAccountInfo({});
        } else {
          editSubscriptionInfo.viewMode = filterValidation.values.viewMode;
          // console.log('editSubscriptionInfo : ' + JSON.stringify(editSubscriptionInfo));
          dispatch(onUpdateAccountByAdmin(filterParam, editSubscriptionInfo));
          setEditSubscriptionInfo({});
        }
      } else if(submitType === "updatePasswordByAdmin"){
        dispatch(onUpdatePasswordByAdmin(filterParam, changePasswordInfo));
        setChangePasswordInfo({});
      } else if(submitType === "verifyAccountByAdmin"){
        dispatch(onVerifyAccountByAdmin(filterParam, verifyAccountInfo));
        setVerifyAccountInfo({});

      }
    },
  });

  const viewAdmAccountManagementSearch = (sortType, curPage, perPage) => {
    //curPage 유효성 체크
    if(curPage){
      if(curPage > totalPage) {
        curPage = totalPage;
      } else if (curPage <= 0) {
        curPage = 1;
      }
    }
    //빨간색으로 변경
    if(sortType){
      const _columns = [];
      columns.map((col, index)=>{
        let _col = cloneDeep(col);
        if(col.asc && col.asc === sortType){
          _col.ascActive = "text-danger";
          _col.descActive = "";
        }else if(col.desc && col.desc === sortType ){
          _col.ascActive = "";
          _col.descActive = "text-danger";
        }else{
          _col.ascActive = "";
          _col.descActive = "";
        }
        _columns.push(_col);
      });

      setColumns(_columns);
    }

    filterValidation.setFieldValue("submitType", "view");
    if(sortType){
      filterValidation.setFieldValue("sortType", sortType);
    }
    if(curPage){
      filterValidation.setFieldValue("curPage", curPage);
      setCurPageInput(curPage);
    }else{
      filterValidation.setFieldValue("curPage", 1);
      setCurPageInput(1);
    }
    if(perPage){
      filterValidation.setFieldValue("perPage", perPage);
    }
    
    filterValidation.handleSubmit(filterValidation.values);
  }

  const rowCheck = (str, checked) => {

    // console.log('rowCheck : ' + checked);
    let _chkCnt = 0;

    const _admAccountManagementSearchList = [];
    admAccountManagementSearchList.map((row, index) =>{
        let _row = cloneDeep(row);

        if(str === "chkAll" || str === index){
            _row.checked = checked;
        }

        if(_row.checked === true){
          _chkCnt++;
        }

        _admAccountManagementSearchList.push(_row);
    }); 

    if(admAccountManagementSearchList.length === _chkCnt){
        setChkAllFlag(true);
    }else{
        setChkAllFlag(false);
    }

    setChkCnt(_chkCnt);

    dispatch(onChangeAdmAccountManagementSearchList(_admAccountManagementSearchList));
  }
  
  useEffect(() => {
    filterValidation.handleSubmit(filterValidation.values);
  },[])
  
  return (
    <React.Fragment>
      <Row>
        <Col lg={12}>
          <Card>
            <Link onClick={toggle} className="text-dark" to="#">
              <div className="p-4">
                <Media className="d-flex align-items-center">
                  <div className="flex-shrink-0 me-3">
                    <i className="uil uil-user text-primary h2"></i>
                  </div>
                  <div className="flex-grow-1 overflow-hidden">
                    <h5 className="font-size-16 mb-1">Account Management</h5>
                  </div>
                  <i className="uil uil-filter font-size-24 text-muted"></i>
                  {/* 
                  <i className={isOpen ? "mdi mdi-chevron-up accor-down-icon font-size-24" : "mdi mdi-chevron-down accor-down-icon font-size-24"}></i>
                  */}
                </Media>
              </div>
            </Link>
            <Collapse isOpen={isOpen}>
              <div className="p-4 border-top">
                <Form onSubmit={filterValidation.handleSubmit}>
                  <div>
                    
                    <Row className="mb-3">
                      <label
                      htmlFor="memberKeyword"
                      className="col-md-2 col-form-label"
                      >
                      Member
                      </label>
                      <div className="col-md-3">
                      <Input
                          className="form-control"
                          type="text"
                          placeholder="Member"
                          id="memberKeyword"
                          name="memberKeyword"
                          onChange={filterValidation.handleChange}
                          value={filterValidation.values.memberKeyword}
                          onKeyDown={(e)=>{
                            if (e.key === 'Enter') {
                              filterValidation.setFieldValue("submitType", "view");
                              filterValidation.handleSubmit(filterValidation.values);
                            }
                          }}
                      />
                      </div>
                    </Row>
                    <Row className="mb-3 align-items-center">
                      <label htmlFor="accountTierList" className="col-md-2 col-form-label">
                      Account tier
                      </label>
                      <div className="col-md-2">
                        <div className="form-check">
                          <input type="checkbox" className="form-check-input"
                            id="accountTierGold"
                            name="gradeList"
                            onChange={filterValidation.handleChange}
                            value="02"
                            checked={filterValidation.values.gradeList.includes("02")}
                          />
                          <Label className="form-check-label" htmlFor="accountTierGold">
                          Gold
                          </Label>
                        </div>
                      </div>
                      <div className="col-md-2">
                        <div className="form-check">
                          <input type="checkbox" className="form-check-input"
                            id="accountTierSilver"
                            name="gradeList"
                            onChange={filterValidation.handleChange}
                            value="01"
                            checked={filterValidation.values.gradeList.includes("01")}
                          />
                          <Label className="form-check-label" htmlFor="accountTierSilver">
                          Silver
                          </Label>
                        </div>
                      </div>
                      <div className="col-md-2">
                        <div className="form-check">
                          <input type="checkbox" className="form-check-input"
                            id="accountTierFree"
                            name="gradeList"
                            onChange={filterValidation.handleChange}
                            value="00"
                            checked={filterValidation.values.gradeList.includes("00")}
                          />
                          <Label className="form-check-label" htmlFor="accountTierFree">
                          Free
                          </Label>
                        </div>
                      </div>
                    </Row>
                    <Row className="mb-3 align-items-center">
                      <label htmlFor="accountTypeList" className="col-md-2 col-form-label">
                      Account type
                      </label>
                      <div className="col-md-2">
                        <div className="form-check">
                          <input type="checkbox" className="form-check-input"
                            id="accountTypeMember"
                            name="accountTypeList"
                            onChange={filterValidation.handleChange}
                            value="Member"
                            checked={filterValidation.values.accountTypeList.includes("Member")}
                          />
                          <Label className="form-check-label" htmlFor="accountTypeMember">
                          Paid
                          </Label>
                        </div>
                      </div>
                      <div className="col-md-2">
                        <div className="form-check">
                          <input type="checkbox" className="form-check-input"
                            id="accountTypeTrial"
                            name="accountTypeList"
                            onChange={filterValidation.handleChange}
                            value="PartiallyPaid"
                            checked={filterValidation.values.accountTypeList.includes("Trial")}
                          />
                          <Label className="form-check-label" htmlFor="accountTypeTrial">
                          Trial
                          </Label>
                        </div>
                      </div>
                      <div className="col-md-2">
                        <div className="form-check">
                          <input type="checkbox" className="form-check-input"
                            id="accountTypeWeCrest"
                            name="accountTypeList"
                            onChange={filterValidation.handleChange}
                            value="WeCrest"
                            checked={filterValidation.values.accountTypeList.includes("WeCrest")}
                          />
                          <Label className="form-check-label" htmlFor="accountTypeWeCrest">
                          WeCrest
                          </Label>
                        </div>
                      </div>
                    </Row>
                    <Row className="mb-3 align-items-center">
                      <label htmlFor="accountStatusList" className="col-md-2 col-form-label">
                      Account status
                      </label>
                      <div className="col-md-2">
                        <div className="form-check">
                          <input type="checkbox" className="form-check-input"
                            id="accountStatusConfirmed"
                            name="accountStatusList"
                            onChange={filterValidation.handleChange}
                            value="01"
                            checked={filterValidation.values.accountStatusList.includes("01")}
                          />
                          <Label className="form-check-label" htmlFor="accountStatusConfirmed">
                          Confirmed
                          </Label>
                        </div>
                      </div>
                      <div className="col-md-2">
                        <div className="form-check">
                          <input type="checkbox" className="form-check-input"
                            id="accountStatusWaiting"
                            name="accountStatusList"
                            onChange={filterValidation.handleChange}
                            value="00"
                            checked={filterValidation.values.accountStatusList.includes("00")}
                          />
                          <Label className="form-check-label" htmlFor="accountStatusWaiting">
                          Waiting
                          </Label>
                        </div>
                      </div>
                      <div className="col-md-2">
                        <div className="form-check">
                          <input type="checkbox" className="form-check-input"
                            id="accountStatusClosed"
                            name="accountStatusList"
                            onChange={filterValidation.handleChange}
                            value="02"
                            checked={filterValidation.values.accountStatusList.includes("02")}
                          />
                          <Label className="form-check-label" htmlFor="accountStatusClosed">
                          Closed
                          </Label>
                        </div>
                      </div>
                    </Row>
                    <Row className="mb-3 align-items-center">
                      <label htmlFor="inOutTypeList" className="col-md-2 col-form-label">
                      Subscriptions type
                      </label>
                      <div className="col-md-2">
                        <div className="form-check">
                          <input type="checkbox" className="form-check-input"
                            id="inOutTypeIN"
                            name="inOutTypeList"
                            onChange={filterValidation.handleChange}
                            value="IN"
                            checked={filterValidation.values.inOutTypeList.includes("IN")}
                          />
                          <Label className="form-check-label" htmlFor="inOutTypeIN">
                          Inbound
                          </Label>
                        </div>
                      </div>
                      <div className="col-md-2">
                        <div className="form-check">
                          <input type="checkbox" className="form-check-input"
                            id="inOutTypeOUT"
                            name="inOutTypeList"
                            onChange={filterValidation.handleChange}
                            value="OUT"
                            checked={filterValidation.values.inOutTypeList.includes("OUT")}
                          />
                          <Label className="form-check-label" htmlFor="inOutTypeOUT">
                          Outbound
                          </Label>
                        </div>
                      </div>
                    </Row>
                    <Row className="mb-3 align-items-center">
                      <label htmlFor="subsStatusList" className="col-md-2 col-form-label">
                      Subscriptions status
                      </label>
                      <div className="col-md-2">
                        <div className="form-check">
                          <input type="checkbox" className="form-check-input"
                            id="subsStatusLive"
                            name="subsStatusList"
                            onChange={filterValidation.handleChange}
                            value="Live"
                            checked={filterValidation.values.subsStatusList.includes("Live")}
                          />
                          <Label className="form-check-label" htmlFor="subsStatusLive">
                          Live
                          </Label>
                        </div>
                      </div>
                      <div className="col-md-2">
                        <div className="form-check">
                          <input type="checkbox" className="form-check-input"
                            id="subsStatusClosed"
                            name="subsStatusList"
                            onChange={filterValidation.handleChange}
                            value="Closed"
                            checked={filterValidation.values.subsStatusList.includes("Closed")}
                          />
                          <Label className="form-check-label" htmlFor="subsStatusClosed">
                          Closed
                          </Label>
                        </div>
                      </div>
                      <div className="col-md-2">
                        <div className="form-check">
                          <input type="checkbox" className="form-check-input"
                            id="subsStatusDeleted"
                            name="subsStatusList"
                            onChange={filterValidation.handleChange}
                            value="Deleted"
                            checked={filterValidation.values.subsStatusList.includes("Deleted")}
                          />
                          <Label className="form-check-label" htmlFor="subsStatusDeleted">
                          Deleted
                          </Label>
                        </div>
                      </div>
                    </Row>
                    <SelectAllJurisdictions
                      formik={filterValidation}
                      firstColMd={2}
                    />
                    <Row>
                      <label htmlFor="searchDate" className="col-md-2 col-form-label">
                      Subscription start date (past)
                      </label>
                      <div className="col-md-10">
                        <PeriodBtn
                          formik={filterValidation}
                          periodType="SubscriptionStartDate"
                        />
                      </div>
                    </Row>
                    <Row>
                      <label htmlFor="searchDate" className="col-md-2 col-form-label">
                      Subscription end date (future)
                      </label>
                      <div className="col-md-10">
                        <PeriodBtn
                          formik={filterValidation}
                          periodType="SubscriptionEndDate"
                        />
                      </div>
                    </Row>
                    <Row>
                      <label htmlFor="searchDate" className="col-md-2 col-form-label">
                      View
                      </label>
                      <div className="col-md-2 form-check" style={{paddingLeft : '2em'}}>
                        <input
                          className="form-check-input"
                          type="radio"
                          name="viewMode"
                          id="viewModeAccounts"
                          onChange={filterValidation.handleChange}
                          value="Accounts"
                          checked={filterValidation.values.viewMode === 'Accounts'}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="viewModeAccounts"
                        >
                          Accounts
                        </label>
                      </div>
                      <div className="col-md-2 form-check" style={{paddingLeft : '2em'}}>
                        <input
                          className="form-check-input"
                          type="radio"
                          name="viewMode"
                          id="viewModeSubscriptions"
                          onChange={filterValidation.handleChange}
                          value="Subscriptions"
                          checked={filterValidation.values.viewMode === 'Subscriptions'}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="viewModeSubscriptions"
                        >
                          Subscriptions
                        </label>
                      </div>
                    </Row>
                    <Row className="mb-3">
                      <span className="col-md-3 button-items">
                        <Button
                          type="button"
                          color="primary"
                          outline
                          className="waves-effect waves-light"
                          onClick={()=>{
                            let _values = cloneDeep(filterValidation.values);
                            _values.submitType = "excel";
                            filterValidation.setValues(_values);
                            filterValidation.handleSubmit(filterValidation.values);
                          }}
                        >
                          Download Excel
                        </Button>
                      </span>
                      <Col md={3}>&nbsp;</Col>
                      <Col md={3}>
                        <button
                          type="button"
                          className="waves-effect waves-light btn btn-primary"
                          style={{width : '148.58px'}}
                          onClick={()=>{
                            filterValidation.setFieldValue("submitType", "view");
                            filterValidation.handleSubmit(filterValidation.values);
                          }}
                        >
                          Search
                        </button>
                      </Col>
                    </Row>
                  </div>
                </Form>
              </div>
            </Collapse>
          </Card>

        {editAccountInfo && Object.keys(editAccountInfo).length !== 0 ? (
          <SweetAlert
            title="Edit account"
            showCancel
            confirmBtnText="Confirm Change"
            cancelBtnText="Cancel"
            confirmBtnBsStyle="success"
            cancelBtnBsStyle="danger"
            onConfirm={() => {
              if (editAccountInfo.userName.trim() === '') {
                dispatch(onSetResult(0));
                return;
              }

              filterValidation.setFieldValue("submitType", "updateAccountByAdmin");
              filterValidation.handleSubmit(filterValidation.values);
            }}
            onCancel={() => setEditAccountInfo({})}
          >
            <div>
              <Row className="mb-3">
                <label
                htmlFor="userName"
                className="col-md-6 col-form-label"
                >
                Username
                </label>
                <div className="col-md-6">
                <Input
                    className="form-control"
                    type="text"
                    placeholder="Enter username"
                    id="userName"
                    name="userName"
                    onChange={e => {
                      let _editAccountInfo = cloneDeep(editAccountInfo);
                      _editAccountInfo.userName = e.target.value;
                      setEditAccountInfo(_editAccountInfo);
                    }}
                    value={editAccountInfo.userName}
                />
                </div>
              </Row>
              <Row className="mb-3">
                <label
                htmlFor="tier"
                className="col-md-6 col-form-label"
                >
                Tier
                </label>
                <div className="col-md-6">
                <select 
                  className="form-select" 
                  name="tier"
                  onChange={e => {
                    let _editAccountInfo = cloneDeep(editAccountInfo);
                    _editAccountInfo.grade = e.target.value;
                    _editAccountInfo.gradeStr = e.target.value === '00' ? 'Free' : 'Gold';
                    setEditAccountInfo(_editAccountInfo);
                  }}
                  value={editAccountInfo.grade}
                >
                  <option value="02">Gold</option>
                  <option value="00">Free</option>
                </select>
                </div>
              </Row>
              <Row className="mb-3">
                <label
                htmlFor="accountStatus"
                className="col-md-6 col-form-label"
                >
                Account Status
                </label>
                <div className="col-md-6">
                <select 
                  className="form-select" 
                  name="accountStatus"
                  onChange={e => {
                    let _editAccountInfo = cloneDeep(editAccountInfo);
                    _editAccountInfo.status = e.target.value;
                    _editAccountInfo.statusStr = e.target.value === '00' ? 'Waiting' : e.target.value === '01' ? 'Confirmed' : e.target.value === '02' ? 'Closed' : 'Deleted';
                    setEditAccountInfo(_editAccountInfo);
                  }}
                  value={editAccountInfo.status}
                >
                  <option value="00">Waiting</option>
                  <option value="01">Confirmed</option>
                  <option value="02">Closed</option>
                  <option value="03">Deleted</option>
                </select>
                </div>
              </Row>
              <Row className="mb-3">
                <label
                htmlFor="warSub"
                className="col-md-6 col-form-label"
                >
                WAR Sub
                </label>
                <div className="col-md-6">
                <select 
                  className="form-select" 
                  name="warSub"
                  onChange={e => {
                    let _editAccountInfo = cloneDeep(editAccountInfo);
                    _editAccountInfo.warSubYn = e.target.value;
                    setEditAccountInfo(_editAccountInfo);
                  }}
                  value={editAccountInfo.warSubYn}
                >
                  <option value="Y">Y</option>
                  <option value="N">N</option>
                </select>
                </div>
              </Row>
            </div>
          </SweetAlert>
        ) : null}

        {editSubscriptionInfo && Object.keys(editSubscriptionInfo).length !== 0 ? (
          <SweetAlert
            title="Edit subscription"
            showCancel
            confirmBtnText="Confirm Change"
            cancelBtnText="Cancel"
            confirmBtnBsStyle="success"
            cancelBtnBsStyle="danger"
            onConfirm={() => {
              if (editSubscriptionInfo.startDate.trim() === '') {
                dispatch(onSetResult(1));
                return;
              }

              if (editSubscriptionInfo.endDate.trim() === '') {
                dispatch(onSetResult(2));
                return;
              }

              filterValidation.setFieldValue("submitType", "updateAccountByAdmin");
              filterValidation.handleSubmit(filterValidation.values);
            }}
            onCancel={() => setEditSubscriptionInfo({})}
          >
            <div>
              <Row className="mb-3">
                <label
                htmlFor="startDate"
                className="col-md-6 col-form-label"
                >
                Start Date
                </label>
                <div className="col-md-6">
                <Input
                    className="form-control"
                    type="date"
                    placeholder="0000.00.00"
                    id="startDate"
                    name="startDate"
                    onChange={e => {
                      let _editSubscriptionInfo = cloneDeep(editSubscriptionInfo);
                      _editSubscriptionInfo.startDate = e.target.value;
                      setEditSubscriptionInfo(_editSubscriptionInfo);
                    }}
                    value={editSubscriptionInfo.startDate}
                />
                </div>
              </Row>
              <Row className="mb-3">
                <label
                htmlFor="endDate"
                className="col-md-6 col-form-label"
                >
                End Date
                </label>
                <div className="col-md-6">
                <Input
                    className="form-control"
                    type="date"
                    placeholder="0000.00.00"
                    id="endDate"
                    name="endDate"
                    onChange={e => {
                      let _editSubscriptionInfo = cloneDeep(editSubscriptionInfo);
                      _editSubscriptionInfo.endDate = e.target.value;
                      setEditSubscriptionInfo(_editSubscriptionInfo);
                    }}
                    value={editSubscriptionInfo.endDate}
                />
                </div>
              </Row>
              <Row className="mb-3">
                <label
                htmlFor="subsStatus"
                className="col-md-6 col-form-label"
                >
                Subs Status
                </label>
                <div className="col-md-6">
                <select 
                  className="form-select" 
                  name="subsStatus"
                  onChange={e => {
                    let _editSubscriptionInfo = cloneDeep(editSubscriptionInfo);
                    _editSubscriptionInfo.subsStatus = e.target.value;
                    setEditSubscriptionInfo(_editSubscriptionInfo);
                  }}
                  value={editAccountInfo.subsStatus}
                >
                  <option value="Live">Live</option>
                  <option value="Closed">Closed</option>
                  <option value="Deleted">Deleted</option>
                </select>
                </div>
              </Row>
            </div>
          </SweetAlert>
        ) : null}
        
        {changePasswordInfo && Object.keys(changePasswordInfo).length !== 0 ? (
          <SweetAlert
            title="Change password"
            showCancel
            confirmBtnText="Confirm Change"
            cancelBtnText="Cancel"
            confirmBtnBsStyle="success"
            cancelBtnBsStyle="danger"
            onConfirm={() => {
              if (changePasswordInfo.chkNewPassword.trim() === '') {
                dispatch(onSetResult(3));
                return;
              }

              if (!/^(?=.*[A-Za-z])(?=.*\d)(?=.*[$@$!%*#?&])[A-Za-z\d$@$!%*#?&]{3,}$/.test(changePasswordInfo.chkNewPassword.trim())) {
                dispatch(onSetResult(4));
                return;
              }
            
              if (changePasswordInfo.chkConfirmNewPassword.trim() === '') {
                dispatch(onSetResult(5));
                return;
              }

              if (changePasswordInfo.chkNewPassword.trim() != changePasswordInfo.chkConfirmNewPassword.trim()) {
                dispatch(onSetResult(6));
                return;
              }

              filterValidation.setFieldValue("submitType", "updatePasswordByAdmin");
              filterValidation.handleSubmit(filterValidation.values);
            }}
            onCancel={() => setChangePasswordInfo({})}
          >
            <div>
              <Row className="mb-3">
                <label
                htmlFor="chkNewPassword"
                className="col-md-6 col-form-label"
                >
                New password
                </label>
                <div className="col-md-6">
                <Input
                    className="form-control"
                    type="password"
                    placeholder="New password"
                    id="chkNewPassword"
                    name="chkNewPassword"
                    onChange={e => {
                      let _changePasswordInfo = cloneDeep(changePasswordInfo);
                      _changePasswordInfo.chkNewPassword = e.target.value;
                      setChangePasswordInfo(_changePasswordInfo);
                    }}
                    value={changePasswordInfo.chkNewPassword}
                />
                </div>
              </Row>
              <Row className="mb-3">
                <label
                htmlFor="chkConfirmNewPassword"
                className="col-md-6 col-form-label"
                >
                Confirm new password
                </label>
                <div className="col-md-6">
                <Input
                    className="form-control"
                    type="password"
                    placeholder="Confirm new password"
                    id="chkConfirmNewPassword"
                    name="chkConfirmNewPassword"
                    onChange={e => {
                      let _changePasswordInfo = cloneDeep(changePasswordInfo);
                      _changePasswordInfo.chkConfirmNewPassword = e.target.value;
                      setChangePasswordInfo(_changePasswordInfo);
                    }}
                    value={changePasswordInfo.chkConfirmNewPassword}
                />
                </div>
              </Row>
            </div>
          </SweetAlert>
        ) : null}
        
        {verifyAccountInfo && Object.keys(verifyAccountInfo).length !== 0 ? (
          <SweetAlert
            title={"Are you sure you want to verify this account?"}
            showCancel
            confirmBtnText={"Yes, verify!"}
            cancelBtnText={"No, go back to previous screen"}
            confirmBtnBsStyle="success"
            cancelBtnBsStyle="danger"
            onConfirm={() => {
              filterValidation.setFieldValue("submitType", "verifyAccountByAdmin");
              filterValidation.handleSubmit(filterValidation.values);
            }}
            onCancel={() => setVerifyAccountInfo({})}
          >
          </SweetAlert>
        ) : null}

        {admAccountManagementSearchList && admAccountManagementSearchList.length ?
          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  <CardTitle className="text-muted mb-0">
                    <Row className="mb-3">
                      <p style={{whiteSpace : "pre"}}>
                      Displaying {admAccountManagementSearchList.length} records!
                      </p>
                    </Row>
                  </CardTitle>
                  <div className="table-responsive">
                    <Table className="table mb-0">
                      <thead>
                        <tr>
                        <th style={{verticalAlign: "middle"}}><input type="checkbox" checked={chkAllFlag} onClick={(e)=>rowCheck("chkAll", e.target.checked)} /></th>
                        {
                          columns && columns.length ?
                          columns.map((col, index) => {
                            if (filterValidation.values.viewMode === 'Accounts' && col.label !== 'Subs Category') {
                              return (
                                <th style={{position: "relative", whiteSpace: "pre-line", textAlign: "center", verticalAlign: "middle"}}>
                                  {col.label}
                                  {col.asc && col.desc? 
                                  <>
                                  <Link to='#' onClick={()=> {viewAdmAccountManagementSearch(col.asc)}} className={col.ascActive}><i className="btn-asc-40 uil uil-angle-up font-size-18"></i></Link>
                                  <Link to='#' onClick={()=> {viewAdmAccountManagementSearch(col.desc)}} className={col.descActive}><i className="btn-desc-40 uil uil-angle-down font-size-18"></i></Link>
                                  </>
                                  :null}
                                </th>
                              )
                            } else if (filterValidation.values.viewMode === 'Subscriptions' && col.label !== 'Jur') {
                              return (
                                <th style={{position: "relative", whiteSpace: "pre-line", textAlign: "center", verticalAlign: "middle"}}>
                                  {col.label}
                                  {col.asc && col.desc? 
                                  <>
                                  <Link to='#' onClick={()=> {viewAdmAccountManagementSearch(col.asc)}} className={col.ascActive}><i className="btn-asc-40 uil uil-angle-up font-size-18"></i></Link>
                                  <Link to='#' onClick={()=> {viewAdmAccountManagementSearch(col.desc)}} className={col.descActive}><i className="btn-desc-40 uil uil-angle-down font-size-18"></i></Link>
                                  </>
                                  :null}
                                </th>
                              )
                            }
                          })
                          :null
                        }
                        </tr>
                      </thead>
                      <tbody>
                        {
                          admAccountManagementSearchList.map((account, index) => {
                            return (
                                <tr key={index}>
                                  <td style={{verticalAlign: "middle"}}>
                                    <input type="checkbox" 
                                      checked={account.checked}
                                      onClick={(e)=>rowCheck(index, e.target.checked)}
                                      />
                                  </td>
                                  <td style={{textAlign: "center", verticalAlign: "middle"}}>{account.companyName}</td>
                                  <td style={{textAlign: "center", verticalAlign: "middle"}}>{account.userName}</td>
                                  <td style={{textAlign: "center", verticalAlign: "middle"}}>
                                    <p className='multiline' id={"crCodeList_"+index}>{account.crCodeList}</p>
                                    <UncontrolledTooltip placement="top" target={"crCodeList_"+index}>
                                      {account.crCodeList}
                                    </UncontrolledTooltip>
                                  </td>
                                  <td style={{textAlign: "center", verticalAlign: "middle"}}>{account.gradeStr}</td>
                                  <td style={{textAlign: "center", verticalAlign: "middle"}}>{account.accountType}</td>
                                  <td style={{textAlign: "center", verticalAlign: "middle"}}>{account.startDate}</td>
                                  <td style={{textAlign: "center", verticalAlign: "middle"}}>{account.endDate}</td>
                                  <td style={{textAlign: "center", verticalAlign: "middle"}}>{account.statusStr}</td>
                                  <td style={{textAlign: "center", verticalAlign: "middle"}}>{account.inOutType}</td>
                                  <td style={{textAlign: "center", verticalAlign: "middle"}}>{account.subsStatus}</td>
                                  <td style={{textAlign: "center", verticalAlign: "middle"}}>{account.warSubYn}</td>
                                  <td style={{textAlign: "center", verticalAlign: "middle"}}>
                                    <Row>
                                      <div className="d-flex vertical-align-center horizon-align-center gap-2">
                                        {
                                          filterValidation.values.viewMode === 'Accounts' ?
                                          <Link to="#"
                                            onClick={()=>{
                                              setEditAccountInfo(account);
                                            }}>
                                            <i className="uil uil-file-edit-alt font-size-22" id={"editInvoice_"+index}></i>
                                            <UncontrolledTooltip placement="top" target={"editInvoice_"+index}>
                                              Edit account
                                            </UncontrolledTooltip>
                                          </Link>
                                          :
                                          <Link to="#"
                                            onClick={()=>{
                                              setEditSubscriptionInfo(account);
                                            }}>
                                            <i className="uil uil-file-edit-alt font-size-22" id={"editInvoice_"+index}></i>
                                            <UncontrolledTooltip placement="top" target={"editInvoice_"+index}>
                                              Edit subscription
                                            </UncontrolledTooltip>
                                          </Link>
                                        }
                                        <Link to="#"
                                          onClick={()=>{
                                            dispatch(onVirtualLogInByAdmin({userNo:account.userNo}));
                                          }}>
                                          <i className="bx bx-log-in-circle font-size-22" id={"virtualLogIn_"+index} style={{marginTop: "5px"}}></i>
                                          <UncontrolledTooltip placement="top" target={"virtualLogIn_"+index}>
                                          Virtual Log In
                                          </UncontrolledTooltip>
                                        </Link>
                                        <Link to="#"
                                          onClick={()=>{
                                            setChangePasswordInfo(account);
                                          }}>
                                          <i className="uil uil-lock-access font-size-22" id={"changePassword_"+index}></i>
                                          <UncontrolledTooltip placement="top" target={"changePassword_"+index}>
                                          Change Password
                                          </UncontrolledTooltip>
                                        </Link>
                                        <Link to="#"
                                          onClick={()=>{
                                            setVerifyAccountInfo(account);
                                          }}>
                                          <i className="bx bx-badge-check font-size-22" id={"verifyAccount_"+index} style={{marginTop: "5px"}}></i>
                                          <UncontrolledTooltip placement="top" target={"verifyAccount_"+index}>
                                          Verify Account
                                          </UncontrolledTooltip>
                                        </Link>

                                      </div>
                                    </Row>
                                  </td>
                                </tr>
                            )})
                        }
                      </tbody>
                    </Table>
                  </div>
                  <div className="d-flex justify-content-between">
                    <span className="d-flex align-items-center gap-2">
                      <label
                        htmlFor="perPage"
                        >
                        Entries per page
                      </label>
                      <div style={{width: "100px"}}>
                        <select 
                          className="form-select" 
                          name="perPage"
                          onChange={e => {viewAdmAccountManagementSearch(null, 1, e.target.value);}}
                          value={filterValidation.values.perPage}
                        >
                          <option value="20">20</option>
                          <option value="50">50</option>
                          <option value="100">100</option>
                        </select>
                      </div>
                    </span>
                    <span className="d-flex align-items-center gap-2">
                      <div className="d-flex gap-1">
                        <Button
                          type="button"
                          color="primary"
                          onClick={()=>{viewAdmAccountManagementSearch(null, 1);}}
                          disabled={(filterValidation.values.curPage === 1)}
                        >
                          {"<<"}
                        </Button>
                        <Button
                          type="button"
                          color="primary"
                          onClick={()=>{viewAdmAccountManagementSearch(null, Number(filterValidation.values.curPage)-1);}}
                          disabled={(filterValidation.values.curPage === 1)}
                        >
                          {"<"}
                        </Button>
                      </div>
                      Page{" "}
                      <strong>
                        {filterValidation.values.curPage} of {totalPage}
                      </strong>
                      
                      <Input
                        type="number"
                        min={1}
                        style={{ width: 70 }}
                        max={totalPage}
                        name="curPage"
                        defaultValue={filterValidation.values.curPage}
                        value={curPageInput}
                        onChange={(e) => { setCurPageInput(e.target.value)}}
                        onKeyPress={(e) => {
                          if(e.key === 'Enter'){
                            viewAdmAccountManagementSearch(null, e.target.value);
                          }                      
                        }}
                      />
                      <div className="d-flex gap-1">
                        <Button 
                          type="button"
                          color="primary"
                          onClick={()=>{viewAdmAccountManagementSearch(null, Number(filterValidation.values.curPage)+1);}}
                          disabled={(filterValidation.values.curPage === totalPage)}
                        >
                          {">"}
                        </Button>
                        <Button
                          type="button"
                          color="primary"
                          onClick={()=>{viewAdmAccountManagementSearch(null, totalPage);}}
                          disabled={(filterValidation.values.curPage === totalPage)}
                        >
                          {">>"}
                        </Button>
                      </div>
                    </span>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        : <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  <CardTitle className="text-muted mb-0">
                    <Row className="mb-3">
                      <p style={{whiteSpace : "pre"}}>
                      Displaying 0 records!
                      </p>
                    </Row>
                  </CardTitle> 
                </CardBody>
              </Card>
            </Col>
          </Row>
        }
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default AdmAccountManagementSearch;