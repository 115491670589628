import React, {useState, useEffect} from 'react';
import { useSelector, useDispatch } from "react-redux";

import {
    Row,
    Tooltip,
    Col,
    Label,
    Modal,
} from "reactstrap"

import {
    getSearchNameList as onGetSearchNameList,
    getSearchNameList2 as onGetSearchNameList2,
    changeSearchNameList as onChangeSearchNameList,
    changeSearchNameSelectedList as onChangeSearchNameSelectedList,
    setIsDruSearch as onSetIsDruSearch,
} from "../../store/actions";
import { cloneDeep } from 'lodash';
  
const SearchName = props => {
    const dispatch = useDispatch();

    const { searchNameList, madridSearchNameList, filerSALSearchNameList, filerSASSearchNameList, isDruSearch, searchNameSelectedList, madridSearchNameSelectedList, filerSALSearchNameSelectedList, filerSASSearchNameSelectedList
        , searchRepNameList, searchRepNameSelectedList, searchHolderNameList, searchHolderNameSelectedList, searchHolderNameList_MCC, searchHolderNameSelectedList_MCC  } = useSelector(state => ({
        searchNameList: state.GeneralStatistics.searchNameList,
        madridSearchNameList: state.GeneralStatistics.madridSearchNameList,
        filerSALSearchNameList: state.GeneralStatistics.filerSALSearchNameList,
        filerSASSearchNameList: state.GeneralStatistics.filerSASSearchNameList,
        isDruSearch: state.GeneralStatistics.isDruSearch,
        searchNameSelectedList: state.GeneralStatistics.searchNameSelectedList,
        madridSearchNameSelectedList: state.GeneralStatistics.madridSearchNameSelectedList,
        filerSALSearchNameSelectedList: state.GeneralStatistics.filerSALSearchNameSelectedList,
        filerSASSearchNameSelectedList: state.GeneralStatistics.filerSASSearchNameSelectedList,

        searchRepNameList: state.GeneralStatistics.searchRepNameList,
        searchRepNameSelectedList: state.GeneralStatistics.searchRepNameSelectedList,
        searchHolderNameList: state.GeneralStatistics.searchHolderNameList,
        searchHolderNameSelectedList: state.GeneralStatistics.searchHolderNameSelectedList,

        searchHolderNameList_MCC: state.GeneralStatistics.searchHolderNameList_MCC,
        searchHolderNameSelectedList_MCC: state.GeneralStatistics.searchHolderNameSelectedList_MCC,
    }));

    const [tright, settright] = useState(false);
  
    const [modal_standard, setmodal_standard] = useState(false);
    const [chkAllFlag, setChkAllFlag] = useState(false);
    const [keyword, setKeyword] = useState("");

    let chkAll = "chkAll";

    function removeBodyCss() {
        document.body.classList.add("no_padding")
    }
    
    const openModal = () => {
        setChkAllFlag(false);
        // dispatch(onClearSearchNameList(props.formik.values.screenName));

        setmodal_standard(!modal_standard);
        removeBodyCss();
    }

    const search = (keyword) => {
        if (props.formik.values.screenName === 'MyCaseManagement') {
            if (props.filterName == 'myConnectedCase') {
                props.formik.setFieldValue("searchHolderKeywordMyConnectedCase", keyword);
            } else {
                if (props.labelName === 'Name of the representative') {
                    props.formik.setFieldValue("searchRepKeyword", keyword);
                } else {
                    props.formik.setFieldValue("searchHolderKeyword", keyword);
                }
            }
        } else {
            props.formik.setFieldValue("searchKeyword", keyword);
        }

        // console.log('search :: ' + keyword);
        
        dispatch(onGetSearchNameList({searchName: keyword, screenName: props.formik.values.screenName, labelName: props.labelName, filterName: props.filterName}));
    }

    const getSearchNameList = () => {
        let _searchNameList = [];

        if (props.formik.values.screenName === 'SpecificSearch') {
            _searchNameList = cloneDeep(searchNameList);
        } else if (props.formik.values.screenName === 'MadridNotifier') {
            _searchNameList = cloneDeep(madridSearchNameList);
        } else if (props.formik.values.screenName === 'FilerSendingAnalyticsLog') {
            _searchNameList = cloneDeep(filerSALSearchNameList);
        } else if (props.formik.values.screenName === 'FilerSendingAnalyticsStatistics') {
            _searchNameList = cloneDeep(filerSASSearchNameList);
        } else if (props.formik.values.screenName === 'MyCaseManagement') {
            if (props.filterName === 'myConnectedCase') {
                _searchNameList = cloneDeep(searchHolderNameList_MCC);
            } else {
                if (props.labelName === 'Name of the representative') {
                    _searchNameList = cloneDeep(searchRepNameList);
                } else {
                    _searchNameList = cloneDeep(searchHolderNameList);
                }
            }
        }

        // console.log('getSearchNameList :: ' + props.formik.values.screenName + " > " + JSON.stringify(_searchNameList));
        return _searchNameList;
    }

    const getSelectedList = () => {
        let _selectedList = [];

        if (props.formik.values.screenName === 'SpecificSearch') {
            _selectedList = cloneDeep(searchNameSelectedList);
        } else if (props.formik.values.screenName === 'MadridNotifier') {
            _selectedList = cloneDeep(madridSearchNameSelectedList);
        } else if (props.formik.values.screenName === 'FilerSendingAnalyticsLog') {
            _selectedList = cloneDeep(filerSALSearchNameSelectedList);
        } else if (props.formik.values.screenName === 'FilerSendingAnalyticsStatistics') {
            _selectedList = cloneDeep(filerSASSearchNameSelectedList);
        } else if (props.formik.values.screenName === 'MyCaseManagement') {
            if (props.filterName == 'myConnectedCase') {
                _selectedList = cloneDeep(searchHolderNameSelectedList_MCC);
            } else {
                if (props.labelName === 'Name of the representative') {
                    _selectedList = cloneDeep(searchRepNameSelectedList);
                } else {
                    _selectedList = cloneDeep(searchHolderNameSelectedList);
                }
            }
        }

        return _selectedList;
    }

    const targetCheck = (str, checked) => {
        let chkCnt = 0;
        let _searchNameList = [];
        
        let _chkSearchNameList = getSearchNameList();

        _chkSearchNameList.map((target, index) => {
            let _target = cloneDeep(target);

            if(str == chkAll){
                _target.checked = checked;
            }else{
                if(target.no == str){
                    _target.checked = checked;
                }
            }

            if(_target.checked == true){
                chkCnt++;
            }

            _searchNameList.push(_target);
        }); 

        if(_chkSearchNameList.length == chkCnt){
            setChkAllFlag(true);
        }else{
            setChkAllFlag(false);
        }

        dispatch(onChangeSearchNameList(_searchNameList, props.formik.values.screenName, props.labelName, props.filterName));
    }

    const targetConfirm = () => {
        let _selectedList = [];
        let _searchNoList = [];
        let _searchNameList = [];

        let _chkSearchNameList = getSearchNameList();

        _chkSearchNameList.map((target, index) => {
            if(target.checked == true){
                _selectedList.push(target);
                _searchNoList.push(target.no);
                _searchNameList.push(target.name);
            }
        });

        dispatch(onChangeSearchNameSelectedList(_selectedList, props.formik.values.screenName, props.labelName, props.filterName));

        if (props.formik.values.screenName === 'MyCaseManagement') {
            if (props.filterName == 'myConnectedCase') {
                props.formik.setFieldValue("searchHolderNoListMyConnectedCase", _searchNoList);
                props.formik.setFieldValue("searchHolderNameListMyConnectedCase", _searchNameList);
            } else {
                if (props.labelName === 'Name of the representative') {
                    props.formik.setFieldValue("searchRepNoList", _searchNoList);
                    props.formik.setFieldValue("searchRepNameList", _searchNameList);
                } else {
                    props.formik.setFieldValue("searchHolderNoList", _searchNoList);
                    props.formik.setFieldValue("searchHolderNameList", _searchNameList);
                }
            }
           
        } else {
            props.formik.setFieldValue("searchNoList", _searchNoList);
            props.formik.setFieldValue("searchNameList", _searchNameList);
        }

        openModal();
    }

    const [firstSearchKeywordFlag, setFirstSearchKeywordFlag] = useState(false);

    useEffect(()=> {
        if (!firstSearchKeywordFlag && props.formik.values.searchKeyword !== "") {
            // console.log("onGetSearchNameList By First Search Keyword : " + props.formik.values.searchKeyword);
            if (props.formik.values.screenName === 'FilerSendingAnalyticsLog') {
                dispatch(onGetSearchNameList2({searchName: props.formik.values.searchKeyword, screenName: props.formik.values.screenName, labelName: props.labelName, filterName: props.filterName}));
            } else {
                dispatch(onGetSearchNameList({searchName: props.formik.values.searchKeyword, screenName: props.formik.values.screenName, labelName: props.labelName, filterName: props.filterName}));
            }
        }
    },[props.formik.values.searchKeyword]);

    useEffect(()=> {
        if (!firstSearchKeywordFlag && props.formik.values.searchRepKeyword !== "" && props.labelName === 'Name of the representative') {
            // console.log("props.formik.values.searchRepKeyword : " + props.formik.values.searchRepKeyword);
            dispatch(onGetSearchNameList({searchName: props.formik.values.searchRepKeyword, screenName: props.formik.values.screenName, labelName: props.labelName, filterName: props.filterName}));
        }
    },[props.formik.values.searchRepKeyword]);

    useEffect(()=> {
        if (!firstSearchKeywordFlag && props.formik.values.searchHolderKeyword !== "" && props.labelName === 'Name of the holder' && props.filterName == 'myCaseManagement') {
            // console.log("props.formik.values.searchHolderKeyword : " + props.formik.values.searchHolderKeyword);
            dispatch(onGetSearchNameList({searchName: props.formik.values.searchHolderKeyword, screenName: props.formik.values.screenName, labelName: props.labelName, filterName: props.filterName}));
        }
    },[props.formik.values.searchHolderKeyword]);

    useEffect(()=> {
        if (!firstSearchKeywordFlag && props.formik.values.searchHolderKeywordMyConnectedCase !== "" && props.labelName === 'Name of the holder' && props.filterName == 'myConnectedCase') {
            // console.log("props.formik.values.searchHolderKeywordMyConnectedCase : " + props.formik.values.searchHolderKeywordMyConnectedCase);
            dispatch(onGetSearchNameList({searchName: props.formik.values.searchHolderKeywordMyConnectedCase, screenName: props.formik.values.screenName, labelName: props.labelName, filterName: props.filterName}));
        }
    },[props.formik.values.searchHolderKeywordMyConnectedCase]);

    useEffect(()=> {
        if (props.formik.values.screenName === 'SpecificSearch' && !firstSearchKeywordFlag && props.formik.values.searchKeyword !== "" && props.formik.values.searchNoList.length > 0 && searchNameList.length > 0) {
            mappingSearchNameList(searchNameList);
        }
    },[searchNameList]);

    useEffect(()=> {
        if (props.formik.values.screenName === 'MadridNotifier' && !firstSearchKeywordFlag && props.formik.values.searchKeyword !== "" && props.formik.values.searchNoList.length > 0 && madridSearchNameList.length > 0) {
            mappingSearchNameList(madridSearchNameList);
        }
    },[madridSearchNameList]);

    useEffect(()=> {
        if (props.formik.values.screenName === 'FilerSendingAnalyticsLog' && !firstSearchKeywordFlag && props.formik.values.searchKeyword !== "" && props.formik.values.searchNoList.length > 0 && filerSALSearchNameList.length > 0) {
            mappingSearchNameList(filerSALSearchNameList);
        }
    },[filerSALSearchNameList]);

    useEffect(()=> {
        if (props.formik.values.screenName === 'FilerSendingAnalyticsStatistics' && !firstSearchKeywordFlag && props.formik.values.searchKeyword !== "" && props.formik.values.searchNoList.length > 0 && filerSASSearchNameList.length > 0) {
            mappingSearchNameList(filerSASSearchNameList);
        }
    },[filerSASSearchNameList]);

    useEffect(()=> {
        if (props.formik.values.screenName === 'MyCaseManagement' && props.filterName == 'myCaseManagement' && !firstSearchKeywordFlag
            && props.labelName === 'Name of the representative' && props.formik.values.searchRepKeyword !== "" && props.formik.values.searchRepNoList.length > 0 && searchRepNameList.length > 0) {
            mappingSearchNameList(searchRepNameList);
        }
    },[searchRepNameList]);

    useEffect(()=> {
        if (props.formik.values.screenName === 'MyCaseManagement' && props.filterName == 'myCaseManagement' && !firstSearchKeywordFlag
            && props.labelName === 'Name of the holder' && props.formik.values.searchHolderKeyword !== "" && props.formik.values.searchHolderNoList.length > 0 && searchHolderNameList.length > 0) {
            mappingSearchNameList(searchHolderNameList);
        }
    },[searchHolderNameList]);

    useEffect(()=> {
        if (props.formik.values.screenName === 'MyCaseManagement' && props.filterName == 'myConnectedCase' && !firstSearchKeywordFlag
            && props.labelName === 'Name of the holder' && props.formik.values.searchHolderKeywordMyConnectedCase !== "" && props.formik.values.searchHolderNoListMyConnectedCase.length > 0 && searchHolderNameList_MCC.length > 0) {
            mappingSearchNameList(searchHolderNameList_MCC);
        }
    },[searchHolderNameList_MCC]);

    const mappingSearchNameList = (_searchNameList) => {
        // console.log(props.formik.values.screenName + " searchNameList mapping By First searchNoList : " + props.formik.values.searchNoList);
        let _selectedList = [];
        _searchNameList.map((target, index) =>{
            if (props.formik.values.screenName === 'MyCaseManagement') {
                if (props.filterName && props.filterName == 'myConnectedCase') {
                    props.formik.values.searchHolderNoListMyConnectedCase.map((no, idx) => {
                        if (target.no === no) {
                            target.checked = true;
                            _selectedList.push(target);
                        }
                    }) 
                } else {
                    if (props.labelName === 'Name of the representative') {
                        props.formik.values.searchRepNoList.map((no, idx) => {
                            if (target.no === no) {
                                target.checked = true;
                                _selectedList.push(target);
                            }
                        }) 
                    } else {
                        props.formik.values.searchHolderNoList.map((no, idx) => {
                            if (target.no === no) {
                                target.checked = true;
                                _selectedList.push(target);
                            }
                        }) 
                    }
                }
            } else {
                props.formik.values.searchNoList.map((no, idx) => {
                    if (target.no === no) {
                        target.checked = true;
                        _selectedList.push(target);
                    }
                }) 
            }
            
        });

        dispatch(onChangeSearchNameSelectedList(_selectedList, props.formik.values.screenName, props.labelName, props.filterName));

        setFirstSearchKeywordFlag(true);
    }

    useEffect(()=> {
        // console.log("useEffect searchNameList : " + JSON.stringify(searchNameList));
        if(props.formik.values.screenName === 'MadridNotifier' && isDruSearch && madridSearchNameList.length > 0){
            let _selectedList = [];
            madridSearchNameList.map((target, index) =>{
                props.formik.values.searchNoList.map((no, idx) => {
                    if (target.no === no) {
                        target.checked = true;
                        _selectedList.push(target);
                    }
                }) 
            });

            dispatch(onChangeSearchNameSelectedList(_selectedList, props.formik.values.screenName, props.labelName, props.filterName));

            dispatch(onSetIsDruSearch(false));
        }
    },[isDruSearch]);

    return (
        <>
        <Row className="mb-3">
            <Col md={props.firstColMd ? props.firstColMd : 3}>
                <label
                    className="col-form-label"
                >
                   {props.labelName ? props.labelName : 'Name of holder or representative'} 
                </label>
            </Col>
            <Col md={3}>
                <label
                    className="col-form-label"
                >
                {
                    getSelectedList().length > 0 ?
                    getSelectedList().map((target, index) => {
                        return (<>{target.name} <br/></>)
                    })
                    :
                    'All (default)'
                }
                </label>
            </Col>
            <Col md={3}>
                <Tooltip
                    placement="right"
                    isOpen={tright}
                    target="searchName"
                    toggle={() => {
                        settright(!tright)
                    }}
                >
                    Name of your potential<br/> client
                </Tooltip>
                <button
                    type="button"
                    className="btn btn-primary waves-effect waves-light"
                    style={{width : '148.58px'}}
                    id="searchName"
                    onClick={() => {
                        openModal();
                    }}
                    data-toggle="modal"
                    data-target="#myModal"
                >
                    Search name
                </button>
            </Col>
        </Row>

        <Modal
            style={{maxWidth: "550px"}}
            isOpen={modal_standard}
            toggle={() => {
            openModal()
            }}
            scrollable={true}
        >
            <div className="modal-header" style={{marginRight: "20px"}}>
                <h6 className="modal-title mt-0 col-md-6" id="myModalLabel">
                    Name of holder or representative
                </h6>
                <input
                    type="text"
                    className="form-control"
                    style={{border: "solid 1px"}}
                    placeholder="Search..."
                    value={keyword}
                    onChange={(e) => {setKeyword(e.target.value)}}
                    onKeyDown={(e) => {
                        if(e.code === 'Enter'){
                            search(e.target.value);
                        }                      
                    }}
                />
                <button
                    type="button"
                    onClick={() => {
                        search(keyword);
                    }}
                    className="btn btn-primary waves-effect waves-light"
                    style={{marginLeft:"5px", 
                        width: "100px",
                        padding: "0.3rem 0.3rem"}}
                >
                    <i className="uil-search" style={{fontSize:"18px"}}></i>
                </button>

                <button
                    type="button"
                    onClick={() => {
                        openModal(false)
                    }}
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                >
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div className="modal-body">
                {
                    getSearchNameList().length ?
                    getSearchNameList().map((target, index) => {
                        if(index == 0){
                            return (
                                <>
                                <Row style={{margin : 'auto'}}>
                                    <div className="col-md-3 form-check mb-2">
                                        <input
                                        type="checkbox"
                                        className="form-check-input"
                                        id={chkAll}
                                        checked={chkAllFlag}
                                        onClick={(e)=>targetCheck(chkAll, e.target.checked)}
                                        />
                                        <Label
                                        className="form-check-label"
                                        htmlFor={chkAll}
                                        >
                                        Select all
                                        </Label>
                                    </div>
                                </Row>
                                <div className="form-check mb-2">
                                <input
                                type="checkbox"
                                className="form-check-input"
                                id={target.no}
                                checked={target.checked}
                                onClick={(e)=>targetCheck(target.no, e.target.checked)}
                                />
                                <Label
                                className="form-check-label"
                                htmlFor={target.no}
                                >
                                {target.name}
                                </Label>
                            </div>
                            </>
                            )
                        }else{
                            return (
                                <div className="form-check mb-2">
                                    <input
                                    type="checkbox"
                                    className="form-check-input"
                                    id={target.no}
                                    checked={target.checked}
                                    onClick={(e)=>targetCheck(target.no, e.target.checked)}
                                    />
                                    <Label
                                    className="form-check-label"
                                    htmlFor={target.no}
                                    >
                                    {target.name}
                                    </Label>
                                </div>
                            )
                        }
                    })
                    : null
                }
            </div>
            <div className="modal-footer">
                <button
                    type="button"
                    onClick={() => {
                    openModal()
                    }}
                    className="btn btn-secondary waves-effect"
                    data-dismiss="modal"
                >
                    Cancel
                </button>
                <button
                    type="button"
                    className="btn btn-primary waves-effect waves-light"
                    onClick={()=> targetConfirm()}
                >
                    Confirm
                </button>
            </div>
        </Modal>
        </>
    )
};

export default SearchName;