export const SET_ADM_INVOICE_MANAGEMENT_LOADING = "SET_ADM_INVOICE_MANAGEMENT_LOADING"
export const CHANGE_ADM_INVOICE_MANAGEMENT_SEARCH_LIST = "CHANGE_ADM_INVOICE_MANAGEMENT_SEARCH_LIST"
export const GET_ADM_INVOICE_MANAGEMENT_SEARCH_LIST = "GET_ADM_INVOICE_MANAGEMENT_SEARCH_LIST"
export const GET_ADM_INVOICE_MANAGEMENT_SEARCH_LIST_SUCCESS = "GET_ADM_INVOICE_MANAGEMENT_SEARCH_LIST_SUCCESS"
export const DOWN_INVOICE_PDF = "DOWN_INVOICE_PDF"
export const DOWN_INVOICE_PDF_SUCCESS = "DOWN_INVOICE_PDF_SUCCESS"
export const UPDATE_INVOICE_STATUS = "UPDATE_INVOICE_STATUS"
export const UPDATE_INVOICE_STATUS_SUCCESS = "UPDATE_INVOICE_STATUS_SUCCESS"
export const SET_SUCCESS = "SET_SUCCESS"
export const SET_RESULT = "SET_RESULT"
export const CONFIRM_PAYMENT = "CONFIRM_PAYMENT"
export const CONFIRM_PAYMENT_SUCCESS = "CONFIRM_PAYMENT_SUCCESS"
export const GET_SEARCH_USER_LIST = "GET_SEARCH_USER_LIST"
export const GET_SEARCH_USER_LIST_SUCCESS = "GET_SEARCH_USER_LIST_SUCCESS"
export const CHANGE_SEARCH_USER_LIST = "CHANGE_SEARCH_USER_LIST"
export const CHANGE_ADM_INVOICE_MANAGEMENT_USER_INFO = "CHANGE_ADM_INVOICE_MANAGEMENT_USER_INFO"
export const CHANGE_ADM_INVOICE_MANAGEMENT_ITEM_LIST = "CHANGE_ADM_INVOICE_MANAGEMENT_ITEM_LIST"
export const CHANGE_USER_INVOICE_INFO_LIST = "CHANGE_USER_INVOICE_INFO_LIST"
export const GET_SUBSCRIPTION_ITEM_ALL_LIST = "GET_SUBSCRIPTION_ITEM_ALL_LIST"
export const GET_SUBSCRIPTION_ITEM_ALL_LIST_SUCCESS = "GET_SUBSCRIPTION_ITEM_ALL_LIST_SUCCESS"
export const CHANGE_SUBSCRIPTION_ITEM_ALL_LIST = "CHANGE_SUBSCRIPTION_ITEM_ALL_LIST"
export const GET_CRESTER_LIST = "GET_CRESTER_LIST"
export const GET_CRESTER_LIST_SUCCESS = "GET_CRESTER_LIST_SUCCESS"
export const SET_ADM_INVOICE_MANAGEMENT_SUCCESS = "SET_ADM_INVOICE_MANAGEMENT_SUCCESS"
export const SET_ADM_INVOICE_MANAGEMENT_ERROR = "SET_ADM_INVOICE_MANAGEMENT_ERROR"
export const CREATE_ADMIN_INVOICE = "CREATE_ADMIN_INVOICE"
export const CREATE_ADMIN_INVOICE_SUCCESS = "CREATE_ADMIN_INVOICE_SUCCESS"
export const GET_USER_INVOICE_INFO_LIST = "GET_USER_INVOICE_INFO_LIST"
export const GET_USER_INVOICE_INFO_LIST_SUCCESS = "GET_USER_INVOICE_INFO_LIST_SUCCESS"
export const PREVIEW_INVOICE_ITEM = "PREVIEW_INVOICE_ITEM"
export const PREVIEW_INVOICE_ITEM_SUCCESS = "PREVIEW_INVOICE_ITEM_SUCCESS"
export const GET_INVOICE_ITEM = "GET_INVOICE_ITEM"
export const GET_INVOICE_ITEM_SUCCESS = "GET_INVOICE_ITEM_SUCCESS"
