import React, {useState, useImperativeHandle, forwardRef} from 'react';
import { useSelector, useDispatch } from "react-redux";
import { Link } from 'react-router-dom';
import {
    Modal,
    Row,
    Col,
    Card,
    Progress,
    Alert,
  } from "reactstrap"
import Dropzone from "react-dropzone"
import {
    setUploadContactsOpen as onSetUploadContactsOpen,
    uploadContacts as onUploadContacts,
    setIsUploadFalse as onSetIsUploadFalse,
  } from "../../store/actions";

const UploadContactsModal = forwardRef((props, ref) => {
    const dispatch = useDispatch();

    const { uploadContactsOpen, successCnt, failCnt, isUpload } = useSelector(state => ({
        uploadContactsOpen: state.Contacts.uploadContactsOpen,
        successCnt: state.Contacts.successCnt,
        failCnt: state.Contacts.failCnt,
        isUpload: state.Contacts.isUpload,
    }));

    const [selectedFiles, setSelectedFiles] = useState([]);
    const [error, setError] = useState('');
    const validTypes = ['text/csv', 'application/vnd.ms-excel'];
  
    function handleAcceptedFiles(files, fileRejections) {
      if(isUpload){
        dispatch(onSetIsUploadFalse());
        setError('');
      }
      
      let tooManyFiles = false;
      let fileInvalidType = false;

      if(fileRejections.length > 0){
        //Rejection된 파일 수만큼
        for(let i =0; i < fileRejections.length; i++ ){
          //해당 파일의 에러코드
          for(let j=0; j<fileRejections[i].errors.length; j++){
            if(fileRejections[i].errors[j].code == "too-many-files") tooManyFiles = true;
            else if(fileRejections[i].errors[j].code == "file-invalid-type") fileInvalidType = true;
          }
        }
      }

      let result = files.filter(file => file.name.substring(file.name.lastIndexOf('.'), file.name.length).toLowerCase() === ".csv");

      if(result.length !== files.length){
        fileInvalidType = true;
      }

      let errMsg = "";
      if(tooManyFiles){
        errMsg += "Please upload only one CSV file at a time.\n";
      }
      if(fileInvalidType){
        errMsg += "Please upload your contacts in CSV format.\n";
      }
      setError(errMsg);

      result.map(file => 
        Object.assign(file, {
          preview: URL.createObjectURL(file),
          formattedSize: formatBytes(file.size),
          isUpload: false,
          uploadRate: 0,
          progressColor : "primary",
        })
      )
      setSelectedFiles(result);
  
    }
  
    const removeFile = (name) => {
      const index = selectedFiles.findIndex(e => e.name === name);
      selectedFiles.splice(index, 1);
      setSelectedFiles([...selectedFiles]);
    }
  
    const uploadContacts = () => {
      const formData = new FormData();
      formData.append('selectedFiles', selectedFiles[0]);

      //console.info(selectedFiles[0]);

      dispatch(onUploadContacts(formData));
    }

    /**
     * Formats the size
     */
    function formatBytes(bytes, decimals = 2) {
      if (bytes === 0) return "0 Bytes"
      const k = 1024
      const dm = decimals < 0 ? 0 : decimals
      const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]
  
      const i = Math.floor(Math.log(bytes) / Math.log(k))
      return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
    }

    return (
        <Modal size="lg" isOpen={uploadContactsOpen} 
                toggle={() => {
                  dispatch(onSetUploadContactsOpen(false))
                  setSelectedFiles([])
                }}>
            <div className="modal-header">
                <h5 className="modal-title mt-0" id="myModalLabel">
                Import email addresses
                </h5>
                <button type="button" className="close" data-dismiss="modal" aria-label="Close"
                        onClick={() => {dispatch(onSetUploadContactsOpen(false))}}><span aria-hidden="true">&times;</span></button>
            </div>
            <div className="modal-body">
                <p>Please select a CSV file containing your contacts to import. Please also note that if the email address already exists, the contact information will be overwritten.</p>
                <a href="/downContactsExample">Download an example.csv file</a>&nbsp;<span className="text-danger">(There are five columns in the example CSV file, and Column A "Email address" is requied.)</span>
                <br/><br/>
                {error && typeof error === "string" ? (
                  <Alert color="danger" style={{ whiteSpace : 'pre'}}>{error}</Alert>
                  ) : null}
                {isUpload? (
                  <Alert color="success"><span style={{color : 'blue'}}>Success: {successCnt} contacts</span> | <span style={{color : 'red'}}>Error: {failCnt} contacts</span></Alert>
                  ) : null}
                <Dropzone
                    accept={validTypes}
                    maxFiles={1}
                    onDrop={(acceptedFiles, fileRejections) => {
                    handleAcceptedFiles(acceptedFiles, fileRejections);
                    }}
                >
                    {({ getRootProps, getInputProps }) => (
                    <div className="dropzone" style={{minHeight: "130px"}}>
                        <div
                        className="dz-message needsclick" style={{fontSize: "0px"}}
                        {...getRootProps()}
                        >
                        <input {...getInputProps()} />
                        <div className="mb-3">
                            <i className="display-4 text-muted uil uil-cloud-upload"/>
                        </div>
                        <h4>Drop a CSV file here or click to upload</h4>
                        </div>
                    </div>
                    )}
                </Dropzone>
                <div className="dropzone-previews mt-3" id="file-previews" style={{marginBottom: "1rem"}}>
                    {selectedFiles.map((f, i) => {
                    return (
                        <Card
                        className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                        key={i + "-file"}
                        >
                        <div className="p-2">
                            <Row className="align-items-center">
                            {
                                f.isImage ?
                                <Col className="col-auto">
                                    <img
                                    data-dz-thumbnail=""
                                    height="80"
                                    className="avatar-sm rounded bg-light"
                                    alt={f.name}
                                    src={f.preview}
                                    />
                                </Col>
                                : null
                            }
                            
                            <Col>
                                <Link
                                to="#"
                                className="text-muted font-weight-bold"
                                >
                                {f.name}
                                {isUpload && <span className="file-success-message">(File Uploaded)</span>}
                                </Link>
                                <p className="mb-0">
                                <strong>{f.formattedSize}</strong>
                                </p>
                            </Col>
                            {!isUpload?
                            <Col md={1} className="file-remove"  onClick={() => removeFile(f.name)}><Link to="#" style={{color: "black"}}>X</Link></Col>
                            : null
                            }
                            </Row>
                        </div>
                        </Card>
                    )
                    })}
                </div>

                <i className="uil-lock-alt"></i>
                <span> We don't sell, rent or use your database for any commercial purposes. <a href="https://www.wecrest.com/privacy-policy" target="_blank" rel="noreferrer">More infos</a></span>
            </div>
            <div className="modal-footer">
                <button type="button" className="btn btn-secondary waves-effect" data-dismiss="modal"
                        onClick={() => {
                          dispatch(onSetUploadContactsOpen(false))
                          setSelectedFiles([])
                        }}>Close</button>
                <button type="button" className="btn btn-primary waves-effect waves-light"
                        onClick={() => {uploadContacts()}}>Import email address</button>
            </div>
        </Modal>
    )
})

export default UploadContactsModal;