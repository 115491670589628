import { call, put, takeEvery, takeLatest } from "redux-saga/effects"

// Login Redux States
import { 
  GET_USER_UNLOCK_INFO,
} from "./actionTypes"
import { 
  getUserUnlockInfoSuccess,
} from "./actions"

//Include Both Helper File with needed methods
import {
  getUserUnlockInfo,
} from "../../helpers/backend_helper"

function* onGetUserUnlockInfo() {
  try {
    // console.log('onGetUserUnlockInfo start >> ');
    const response = yield call(getUserUnlockInfo);
    yield put(getUserUnlockInfoSuccess(response));
    
  } catch (error) {
    console.log('onGetUserUnlockInfo error >> ' + error);
    //yield put(apiError(error))
  }
}

function* unlockSaga() {
  yield takeLatest(GET_USER_UNLOCK_INFO, onGetUserUnlockInfo);
}

export default unlockSaga