import { call, put, takeEvery, takeLatest } from "redux-saga/effects"

// Login Redux States
import { 
  GET_MULTIPLE_JURISDICTIONS_LIST,
} from "./actionTypes"
import { 
  getMultipleJurisdictionsListSuccess,
} from "./actions"

//Include Both Helper File with needed methods
import {
  getMultipleJurisdictionsList,
} from "../../helpers/backend_helper"

function* onGetMultipleJurisdictionsList({ payload: data }) {
  try {
    // console.log('onGetMultipleJurisdictionsList data : ' + JSON.stringify(data));
    const response = yield call(getMultipleJurisdictionsList, data);
    // console.log('onGetMultipleJurisdictionsList response : ' + JSON.stringify(response));
    yield put(getMultipleJurisdictionsListSuccess(response, data.filterName));
    
  } catch (error) {
    console.log('onGetMultipleJurisdictionsList error >> ' + error);
    //yield put(apiError(error))
  }
}

function* multipleJurisdictionsSaga() {
  yield takeEvery(GET_MULTIPLE_JURISDICTIONS_LIST, onGetMultipleJurisdictionsList);
}

export default multipleJurisdictionsSaga

