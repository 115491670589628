import {
  GET_MADRID_SETTINGS,
  GET_MADRID_SETTINGS_SUCCESS,
  SAVE_MADRID_SETTINGS,
  SAVE_MADRID_SETTINGS_SUCCESS,
  SET_SUCCESS,
  SET_RESULT,
  GET_MADRID_NOTIFIER,
  GET_MADRID_NOTIFIER_SUCCESS,
  GET_MADRID_NOTIFIER_INFO,
  GET_MADRID_NOTIFIER_INFO_SUCCESS,
  GET_MADRID_NOTIFIER_INFO_SUCCESS_UNLOCK,
  CHANGE_MADRID_NOTIFIER,
  MULTIPLE_HIDE_MADRID,
  MULTIPLE_UNHIDE_MADRID,
  MULTIPLE_STAR_MADRID,
  MULTIPLE_UNSTAR_MADRID,
  DOWN_MADRID_EXCEL,
  DOWN_MADRID_EXCEL_SUCCESS,
  DOWN_MULTIPLE_MADRID_EXCEL,
  DOWN_MULTIPLE_MADRID_EXCEL_SUCCESS,
  DOWN_MADRID_PDF,
  DOWN_MADRID_PDF_SUCCESS,
  DOWN_MULTIPLE_MADRID_PDF,
  DOWN_MULTIPLE_MADRID_PDF_SUCCESS,
  SEND_MADRID_EMAIL,
  PREVIEW_MADRID_EMAIL,
  PREVIEW_MADRID_EMAIL_SUCCESS,
  SET_PREVIEW_EMAIL,
  CLEAR_MADRID_NOTIFIER,
  UNLOCK_MADRID,
  SET_MADRID_SETTINGS,
  SET_MADRID_LOADING,
  STAR_TARGET,
  UN_STAR_TARGET,
  HIDE_TARGET,
  UN_HIDE_TARGET,
  SET_UPGRADE_ALERT_MESSAGE,
  SET_MADRID_SETTINGS_DRU,
  SET_IS_DRU_SEARCH,
  CHANGE_SENT_RESULT,
  SET_SENT_SUCCESS,
  GET_OCMN_LIST,
  GET_OCMN_LIST_SUCCESS,
  SAVE_OCMN_SHOW_STARTUP,
  SAVE_OCMN_SHOW_STARTUP_SUCCESS,
  SAVE_OCMN_AUTO_SEND_YN,
  SAVE_OCMN_AUTO_SEND_YN_SUCCESS,
  GET_OCMN_SUGGESTED_CONTACTS,
  GET_OCMN_SUGGESTED_CONTACTS_SUCCESS,
  SEND_OCMN_EMAIL,
  SEND_OCMN_EMAIL_SUCCESS,
  SET_OCMN_OPEN,
  SET_OCMN_LOADING,
  SET_OCMN_INDEX,
  OCMN_PREVIEW_MADRID_EMAIL,
  OCMN_PREVIEW_MADRID_EMAIL_SUCCESS,
  SET_OCMN_PREVIEW_EMAIL,
  UNLOCK_OCMN,
  CHANGE_OCMN_LIST_UNLOCK,
  ADD_OCMN_LOG,
  CHANGE_OCMN_SUGGESTED_CONTACTS_LIST,
  SET_OCMN_SHOW_STARTUP,
} from "./actionTypes"

export const getMadridSettings = () => ({
  type: GET_MADRID_SETTINGS,
})

export const getMadridSettingsSuccess = data => {
  return {
    type: GET_MADRID_SETTINGS_SUCCESS,
    payload: data,
  }
}

export const saveMadridSettings = data => {
  return {
    type: SAVE_MADRID_SETTINGS,
    payload: data,
  }
}

export const saveMadridSettingsSuccess = data => {
  return {
    type: SAVE_MADRID_SETTINGS_SUCCESS,
    payload: data,
    
  }
}

export const setMadridSuccess = data => {
  return {
    type: SET_SUCCESS,
    payload: data,
  }
}

export const setMadridResult = data => {
  return {
    type: SET_RESULT,
    payload: data,
  }
}

export const getMadridNotifier = data => {
  return {
    type: GET_MADRID_NOTIFIER,
    payload: data,
  }
}

export const getMadridNotifierSuccess = data => {
  return {
    type: GET_MADRID_NOTIFIER_SUCCESS,
    payload: data,
  }
}

export const getMadridNotifierInfo = (madridNotifierList, inOutType) => {
  return {
    type: GET_MADRID_NOTIFIER_INFO,
    payload: {madridNotifierList, inOutType},
  }
}

export const getMadridNotifierInfoSuccess = data => {
  return {
    type: GET_MADRID_NOTIFIER_INFO_SUCCESS,
    payload: data,
  }
}

export const getMadridNotifierInfoSuccessUnlock = data => {
  return {
    type: GET_MADRID_NOTIFIER_INFO_SUCCESS_UNLOCK,
    payload: data,
  }
}

export const changeMadridNotifier = data => {
  return {
    type: CHANGE_MADRID_NOTIFIER,
    payload: data,
  }
}

export const multipleHideMadrid = (caseList, filter) => {
  return {
    type: MULTIPLE_HIDE_MADRID,
    payload: {caseList, filter},
  }
}

export const multipleUnhideMadrid = (caseList, filter) => {
  return {
    type: MULTIPLE_UNHIDE_MADRID,
    payload: {caseList, filter},
  }
}

export const multipleStarMadrid = (caseList, filter) => {
  return {
    type: MULTIPLE_STAR_MADRID,
    payload: {caseList, filter},
  }
}

export const multipleUnstarMadrid = (caseList, filter) => {
  return {
    type: MULTIPLE_UNSTAR_MADRID,
    payload: {caseList, filter},
  }
}

export const downMadridExcel = (data, fileName) => {
  return {
    type: DOWN_MADRID_EXCEL,
    payload: {data, fileName},
  }
}

export const downMadridExcelSuccess = () => {
  return {
    type: DOWN_MADRID_EXCEL_SUCCESS,
  }
}

export const downMultipleMadridExcel = (madridNotifierList, noticeStr, fileName) => {
  return {
    type: DOWN_MULTIPLE_MADRID_EXCEL,
    payload: {madridNotifierList, noticeStr, fileName},
  }
}

export const downMultipleMadridExcelSuccess = () => {
  return {
    type: DOWN_MULTIPLE_MADRID_EXCEL_SUCCESS,
  }
}

export const downMadridPdf = (data, fileName) => {
  return {
    type: DOWN_MADRID_PDF,
    payload: {data, fileName},
  }
}

export const downMadridPdfSuccess = () => {
  return {
    type: DOWN_MADRID_PDF_SUCCESS,
  }
}

export const downMultipleMadridPdf = (data, fileName) => {
  return {
    type: DOWN_MULTIPLE_MADRID_PDF,
    payload: {data, fileName},
  }
}

export const downMultipleMadridPdfSuccess = () => {
  return {
    type: DOWN_MULTIPLE_MADRID_PDF_SUCCESS,
  }
}

export const sendMadridEmail = (madridNotifierList, period, inOutType, sendTarget, filter) => {
  return {
    type: SEND_MADRID_EMAIL,
    payload: {caseList : {madridNotifierList, period, inOutType, sendTarget}, filter},
  }
}

export const previewMadridEmail = (madridNotifierList, period, inOutType) => {
  return {
    type: PREVIEW_MADRID_EMAIL,
    payload: {madridNotifierList, period, inOutType},
  }
}

export const previewMadridEmailSuccess = data => {
  return {
    type: PREVIEW_MADRID_EMAIL_SUCCESS,
    payload: data,
  }
}

export const setPreviewEmail = data => {
  return {
    type: SET_PREVIEW_EMAIL,
    payload: data,
  }
}

export const clearMadridNotifier = () => {
  return {
    type: CLEAR_MADRID_NOTIFIER,
  }
}

export const unlockMadrid = (madridNotifierList, period, inOutType, filter) => {
  return {
    type: UNLOCK_MADRID,
    payload: {caseList : {madridNotifierList, period, inOutType}, filter},
  }
}

export const setMadridSettings = data => {
  return {
    type: SET_MADRID_SETTINGS,
    payload: data,
  }
}

export const setMadridLoading = data => {
  return {
    type: SET_MADRID_LOADING,
    payload: data,
  }
}

export const starTarget = (targetGroupNo, filter, madridNotifierList) => {
  return {
    type: STAR_TARGET,
    payload: {targetGroupNo, filter, madridNotifierList},
  }
}

export const unStarTarget = (targetGroupNo, filter, madridNotifierList) => {
  return {
    type: UN_STAR_TARGET,
    payload: {targetGroupNo, filter, madridNotifierList},
  }
}

export const hideTarget = (targetGroupNo, filter, madridNotifierList) => {
  return {
    type: HIDE_TARGET,
    payload: {targetGroupNo, filter, madridNotifierList},
  }
}

export const unHideTarget = (targetGroupNo, filter, madridNotifierList) => {
  return {
    type: UN_HIDE_TARGET,
    payload: {targetGroupNo, filter, madridNotifierList},
  }
}

export const setUpgradeAlertMessage = data => {
  return {
    type: SET_UPGRADE_ALERT_MESSAGE,
    payload: data,
  }
}

export const setMadridSettingsDru = data => {
  return {
    type: SET_MADRID_SETTINGS_DRU,
    payload: data,
  }
}

export const setIsDruSearch = data => {
  return {
    type: SET_IS_DRU_SEARCH,
    payload: data,
  }
}

export const changeSentResult = data => {
  return {
    type: CHANGE_SENT_RESULT,
    payload: data,
  }
}

export const setMadridSentSuccess = data => {
  return {
    type: SET_SENT_SUCCESS,
    payload: data,
  }
}

export const getOCMNList = data => {
  return {
    type: GET_OCMN_LIST,
    payload: data,
  }
}

export const getOCMNListSuccess = data => {
  return {
    type: GET_OCMN_LIST_SUCCESS,
    payload: data,
  }
}

export const saveOCMNShowStartup = data => {
  return {
    type: SAVE_OCMN_SHOW_STARTUP,
    payload: data,
  }
}

export const saveOCMNShowStartupSuccess = data => {
  return {
    type: SAVE_OCMN_SHOW_STARTUP_SUCCESS,
    payload: data,
  }
}

export const saveOCMNAutoSendYn = data => {
  return {
    type: SAVE_OCMN_AUTO_SEND_YN,
    payload: data,
  }
}

export const saveOCMNAutoSendYnSuccess = data => {
  return {
    type: SAVE_OCMN_AUTO_SEND_YN_SUCCESS,
    payload: data,
  }
}

export const getOCMNSuggestedContacts = data => {
  return {
    type: GET_OCMN_SUGGESTED_CONTACTS,
    payload: data,
  }
}

export const getOCMNSuggestedContactsSuccess = data => {
  return {
    type: GET_OCMN_SUGGESTED_CONTACTS_SUCCESS,
    payload: data,
  }
}

export const sendOCMNEmail = (madridNotifierList, inOutType, oneClickMNEmailList) => {
  return {
    type: SEND_OCMN_EMAIL,
    payload: {caseList : {madridNotifierList, inOutType, oneClickMNEmailList}},
  }
}

export const sendOCMNEmailSuccess = data => {
  return {
    type: SEND_OCMN_EMAIL_SUCCESS,
    payload: data,
  }
}

export const setOcmnOpen = data => {
  return {
    type: SET_OCMN_OPEN,
    payload: data,
  }
}

export const setOcmnLoading = data => {
  return {
    type: SET_OCMN_LOADING,
    payload: data,
  }
}

export const setOcmnIndex = data => {
  return {
    type: SET_OCMN_INDEX,
    payload: data,
  }
}

export const ocmnPreviewMadridEmail = (madridNotifierList, inOutType) => {
  return {
    type: OCMN_PREVIEW_MADRID_EMAIL,
    payload: {madridNotifierList, inOutType},
  }
}

export const ocmnPreviewMadridEmailSuccess = data => {
  return {
    type: OCMN_PREVIEW_MADRID_EMAIL_SUCCESS,
    payload: data,
  }
}

export const setOcmnPreviewEmail = data => {
  return {
    type: SET_OCMN_PREVIEW_EMAIL,
    payload: data,
  }
}

export const unlockOcmn = (madridNotifierList, period, inOutType) => {
  return {
    type: UNLOCK_OCMN,
    payload: {caseList : {madridNotifierList, period, inOutType}},
  }
}

export const changeOcmnListUnlock = data => {
  return {
    type: CHANGE_OCMN_LIST_UNLOCK,
    payload: data,
  }
}

export const addOcmnLog = data => {
  return {
    type: ADD_OCMN_LOG,
    payload: data,
  }
}

export const changeOcmnSuggestedContactsList = data => {
  return {
    type: CHANGE_OCMN_SUGGESTED_CONTACTS_LIST,
    payload: data,
  }
}

export const setOcmnShowStartup = data => {
  return {
    type: SET_OCMN_SHOW_STARTUP,
    payload: data,
  }
}