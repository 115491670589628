import {
  GET_USER_LIST,
  GET_USER_LIST_SUCCESS,
  USER_API_ERROR,
} from "./actionTypes"

export const getUserList = () => ({
    type: GET_USER_LIST,
})


export const getUserListSuccess = data => {
  // console.log('action getUserListSuccess');
  // console.log(data.userVOList);

  return {
    type: GET_USER_LIST_SUCCESS,
    payload: data.userVOList,
  }
}

export const apiError = error => {
  return {
    type: USER_API_ERROR,
    payload: error,
  }
}