import React, {useState, useEffect, useRef} from 'react';
import { useSelector, useDispatch } from "react-redux";
import {
    Table,
    Col,
    Row,
    Card,
    CardBody,
    CardTitle,
    Collapse,
    Media,
    Form,
    Label,
    Input,
    FormGroup,
    Tooltip,
    Button,
    Modal,
    UncontrolledTooltip,
    FormFeedback,
  } from "reactstrap"
import LoadingModal from "../../components/Common/LoadingModal"
import EditorModal from "../../components/Common/EditorModal"
import JurisdictionsMonthlyPricing from '../../components/Filter/JurisdictionsMonthlyPricing';
import * as Yup from "yup";
import { useFormik } from "formik";
import SweetAlert from "react-bootstrap-sweetalert";
import { Link } from "react-router-dom";

import {
  setMembershipPlanValue,
  getUserMembershipInfo,
  updateSubscriptionToFree,
  updateGoldMembershipPlan,
  getPaypalPlanId,
} from "../../store/actions";
import { cloneDeep } from 'lodash';

import { 
  PayPalScriptProvider,
  PayPalButtons,
  usePayPalScriptReducer 
} from "@paypal/react-paypal-js";

import '../../components/Common/Common.css';

const MembershipPlan = props => {
  const dispatch = useDispatch();
  const jurisdictionsMonthlyPricingRef = useRef({});

  const { loading, success, billedMonthFlag, orgUserSubscriptionList, userSubscriptionList, changeSubscriptionFlag, serverDate, userGrade, membershipPlanType, paypalClientID, wecrestProductID, paypalProductID, paypalPlanID, errorMsg } = useSelector(state => ({
    loading: state.MembershipPlan.loading,
    success: state.MembershipPlan.success,
    billedMonthFlag: state.MembershipPlan.billedMonthFlag,
    orgUserSubscriptionList: state.MembershipPlan.orgUserSubscriptionList,
    userSubscriptionList: state.MembershipPlan.userSubscriptionList,
    changeSubscriptionFlag: state.MembershipPlan.changeSubscriptionFlag,
    serverDate: state.MembershipPlan.serverDate,
    userGrade: state.MembershipPlan.userGrade,
    membershipPlanType: state.MembershipPlan.membershipPlanType,
    paypalClientID: state.MembershipPlan.paypalClientID,
    wecrestProductID: state.MembershipPlan.wecrestProductID,
    paypalProductID: state.MembershipPlan.paypalProductID,
    paypalPlanID: state.MembershipPlan.paypalPlanID,
    errorMsg: state.MembershipPlan.errorMsg,
  }));

  const [isOpen, setIsOpen] = useState(true);
  const toggle = () => {
    setIsOpen(!isOpen);
  }

  const dollarUSLocale = Intl.NumberFormat('en-US');
  const [monthPrice, setMonthPrice] = useState(0);
  const [nextPrice, setNextPrice] = useState('0');
  const [nextPaymentDate, setNextPaymentDate] = useState('');
  const [dueTodayPrice, setDueTodayPrice] = useState('0');
  const [currentRenewalPeriod, setCurrentRenewalPeriod] = useState('');
  const [orgCurrentRenewalPeriod, setOrgCurrentRenewalPeriod] = useState('');
  const [manageGoldPlan, setManageGoldPlan] = useState(false);
  const [upgradeMessage, setUpgradeMessage] = useState({title:"", confirmBtnText:"", method:"", text:""});
  
  const closeLoding = () => {
    dispatch(setMembershipPlanValue('LOADING_FLAG', false));
  }

  useEffect(() => {
    dispatch(getUserMembershipInfo());
  }, []);

  useEffect(() => {
    makePricing('');
  }, [userSubscriptionList, billedMonthFlag]);

  useEffect(() => {
    // RenewalPeriod 확인 
    if (orgUserSubscriptionList && orgUserSubscriptionList.length) {
      let _currentRenewalPeriod = '00000000';
      if (userGrade != '00') {
        orgUserSubscriptionList.map((userSubscription, index) => {
          if (userSubscription.renewalPeriod && userSubscription.renewalPeriod != null && userSubscription.renewalPeriod != 'null') {
            _currentRenewalPeriod = userSubscription.renewalPeriod;
          }
        })
      
        // _currentRenewalPeriod = orgUserSubscriptionList[0].renewalPeriod;
      } else {
        if (billedMonthFlag) {
          _currentRenewalPeriod = 'monthly';
        } else {
          _currentRenewalPeriod = 'annually';
        }
      }

      // console.log('change orgUserSubscriptionList >> _currentRenewalPeriod : ' + _currentRenewalPeriod)

      setCurrentRenewalPeriod(_currentRenewalPeriod);
      setOrgCurrentRenewalPeriod(_currentRenewalPeriod);
      makePricing(_currentRenewalPeriod);
      dispatch(setMembershipPlanValue('BILLED_MONTH_FLAG', _currentRenewalPeriod == 'monthly' ? true : false));
    }
  }, [orgUserSubscriptionList]);

  useEffect(() => {
    // RenewalPeriod 확인 
    if (userGrade == '00') {
      setManageGoldPlan(true);
    } else {
      setManageGoldPlan(false);
    }
  }, [userGrade]);

  useEffect(() => {
    makePricing(currentRenewalPeriod);
  }, [currentRenewalPeriod]);

  const makePricing = (_currentRenewalPeriod) => {
    if (userSubscriptionList && userSubscriptionList.length && serverDate != '') {
      let _monthPrice = 0;
      let _nextPrice = 0;
      let _newDate = new Date();
      
      let _nextPaymentDate = '';

      // console.log('manageGoldPlan : ' + manageGoldPlan);
      // console.log('serverDate : ' + serverDate);
      // console.log('_newDate : ' + _newDate);

      let _goldEndDate = '00000000';

      userSubscriptionList.map((userSubscription, index) => {
        if (!manageGoldPlan) {
          if (userSubscription.invoiceTerm == '3months') {
            _monthPrice += Number(userSubscription.invoiceAmount) / 3;
          } else if (userSubscription.invoiceTerm == '6months') {
            _monthPrice += Number(userSubscription.invoiceAmount) / 6;
          } else if (userSubscription.invoiceTerm == 'annually') {
            _monthPrice += Number(userSubscription.invoiceAmount) / 12;
          } else {
            _monthPrice += Number(userSubscription.invoiceAmount);
          }

          _nextPrice += Number(userSubscription.renewalPrice) === 0 ? Number(userSubscription.invoiceAmount) : Number(userSubscription.renewalPrice);
          _goldEndDate = userSubscription.endDate;
        } else {
          if (billedMonthFlag) {
            _monthPrice += userSubscription.monthly_1m;
          } else {
            _monthPrice += userSubscription.annually_1m;
          }
        }
      })

      if (!manageGoldPlan) {
        _newDate.setFullYear(_goldEndDate.substring(0,4));
        _newDate.setMonth(Number(_goldEndDate.substring(4,6)) - 1);
        _newDate.setDate(_goldEndDate.substring(6,8));

        // if (_currentRenewalPeriod == '1month') {
        //   _newDate.setMonth(_newDate.getMonth() + 1);
        // } else if (_currentRenewalPeriod == '3months') {
        //   _newDate.setMonth(_newDate.getMonth() + 3);
        // } else if (_currentRenewalPeriod == '6months') {
        //   _newDate.setMonth(_newDate.getMonth() + 6);
        // } else {
        //   _newDate.setMonth(_newDate.getMonth() + 12);
        // }
      } else {
        _newDate.setFullYear(serverDate.split('-')[0]);
        _newDate.setMonth(Number(serverDate.split('-')[1]) - 1);
        _newDate.setDate(Number(serverDate.split('-')[2]) - 1);

        if (billedMonthFlag) {
          _nextPrice = _monthPrice;
          _newDate.setMonth(_newDate.getMonth() + 1);
        } else {
          _nextPrice = _monthPrice * 12;
          _newDate.setFullYear(_newDate.getFullYear() + 1);
        }
      }

      _nextPaymentDate = _newDate.getFullYear() 
                      + '-' + (_newDate.getMonth() + 1 < 10 ? '0' + (_newDate.getMonth() + 1) : _newDate.getMonth() + 1) 
                      + '-' + (_newDate.getDate() < 10 ? '0' + _newDate.getDate() : _newDate.getDate());
    
      setMonthPrice(dollarUSLocale.format(_monthPrice));
      setNextPrice(dollarUSLocale.format(_nextPrice));
      setNextPaymentDate(_nextPaymentDate);

      // console.log('_currentRenewalPeriod : ' + _currentRenewalPeriod)

      // console.log('serverDate : ' + serverDate)

      // console.log('_newDate : ' + _newDate)



      // let _toDay = new Date();

      if (_currentRenewalPeriod != '' && Number(serverDate.split('-')[0]) == _newDate.getFullYear() && Number(serverDate.split('-')[1]) == _newDate.getMonth() && Number(serverDate.split('-')[2]) == _newDate.getDate()) {
        setDueTodayPrice(dollarUSLocale.format(_nextPrice));
      // } else if (_currentRenewalPeriod == '') {
      //   let totalRemainingBalance = getTotalRemainingBalance();
      //   setDueTodayPrice(dollarUSLocale.format(_nextPrice - Math.round(totalRemainingBalance)));
      } else {
        setDueTodayPrice(0);
      }
    }
  }

  const getRemainingBalance = (type) => {
    let totalRemainingBalance = getTotalRemainingBalance();

    /*
    if (userGrade != '00') {
      orgUserSubscriptionList.map((userSubscription, index) => {
        let _today = new Date();
        let _startDate = new Date();
        let _endDate = new Date();
        _endDate.setFullYear(userSubscription.endDate.substring(0,4));
        _endDate.setMonth(Number(userSubscription.endDate.substring(4,6)) - 1);
        _endDate.setDate(userSubscription.endDate.substring(6,8));
  
        if (userSubscription.invoiceTerm == 'monthly') {
          _startDate.setMonth(_endDate.getMonth() - 1);
        } else if (userSubscription.invoiceTerm == '3months') {
          _startDate.setMonth(_endDate.getMonth() - 3);
        } else if (userSubscription.invoiceTerm == '6months') {
          _startDate.setMonth(_endDate.getMonth() - 6);
        } else {
          _startDate.setMonth(_endDate.getMonth() - 12);
        }
  
        let subsDayDiff = (_endDate.getTime() - _startDate.getTime()) / (1000 * 60 * 60 * 24);   
  
        let dayAmount = Number(userSubscription.invoiceAmount) / subsDayDiff;
  
        let remainDay = (_endDate.getTime() - _today.getTime()) / (1000 * 60 * 60 * 24);   
  
        let remainingBalance = dayAmount * remainDay;
  
        totalRemainingBalance += remainingBalance;
  
        console.log('getRemainingBalance >> crCode : ' + userSubscription.crCode + ', subsDayDiff : ' + subsDayDiff + ', dayAmount : ' + dayAmount + ', remainDay : ' + remainDay + ', remainingBalance : ' + remainingBalance);
      })
    }

    console.log('totalRemainingBalance : ' + totalRemainingBalance);
    */

    if (type == 'Free') {
      // console.log('Free totalRemainingBalance : ' + Math.round(totalRemainingBalance));
      return Math.round(totalRemainingBalance);
    } else {
      // console.log('Gold nextPrice : ' + nextPrice + ', totalRemainingBalance : ' + Math.round(totalRemainingBalance));
      return Number(nextPrice.replaceAll(',','')) - Math.round(totalRemainingBalance);
    }
  }

  const getTotalRemainingBalance = () => {
    let totalRemainingBalance = 0;

    if (userGrade != '00') {
      orgUserSubscriptionList.map((userSubscription, index) => {
        let _today = new Date();
        let _startDate = new Date();
        _startDate.setFullYear(userSubscription.endDate.substring(0,4));
        _startDate.setMonth((Number(userSubscription.endDate.substring(4,6)) - 1));
        _startDate.setDate(userSubscription.endDate.substring(6,8));

        let _endDate = new Date();
        _endDate.setFullYear(userSubscription.endDate.substring(0,4));
        _endDate.setMonth((Number(userSubscription.endDate.substring(4,6)) - 1));
        _endDate.setDate(userSubscription.endDate.substring(6,8));
  
        // console.log('1. getTotalRemainingBalance >> _startDate : ' + _startDate);
        // console.log('1. getTotalRemainingBalance >> _endDate : ' + _endDate);

        if (userSubscription.invoiceTerm == 'monthly') {
          _startDate.setMonth(_startDate.getMonth() - 1);
        } else if (userSubscription.invoiceTerm == '3months') {
          _startDate.setMonth(_startDate.getMonth() - 3);
        } else if (userSubscription.invoiceTerm == '6months') {
          _startDate.setMonth(_startDate.getMonth() - 6);
        } else {
          _startDate.setMonth(_startDate.getMonth() - 12);
        }

        // console.log('2. getTotalRemainingBalance >> _startDate : ' + _startDate);
        // console.log('2. getTotalRemainingBalance >> _endDate : ' + _endDate);
  
        let subsDayDiff = (_endDate.getTime() - _startDate.getTime()) / (1000 * 60 * 60 * 24);   
        
        let dayAmount = Number(userSubscription.invoiceAmount) / subsDayDiff;

        let remainDay = ((_endDate.getTime() - _today.getTime()) / (1000 * 60 * 60 * 24)) + 1;   

        if (remainDay > 0) {
          let remainingBalance = dayAmount * remainDay;
    
          totalRemainingBalance += remainingBalance;
    
          // console.log('getRemainingBalance >> crCode : ' + userSubscription.crCode + ', subsDayDiff : ' + subsDayDiff + ', dayAmount : ' + dayAmount + ', remainDay : ' + remainDay + ', remainingBalance : ' + remainingBalance);
        } else {
          // console.log('getRemainingBalance >> crCode : ' + userSubscription.crCode + ', subsDayDiff : ' + subsDayDiff + ', dayAmount : ' + 0 + ', remainDay : ' + 0 + ', remainingBalance : ' + 0);
        }
        
      })
    }

    // console.log('totalRemainingBalance : ' + totalRemainingBalance);

    return totalRemainingBalance;
  }

  const filterValidation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      submitType: "",
      jurType: "",
      jrCdList: [],

      paypalSubscriptionID: "",
    },
    // validationSchema: Yup.object().shape({
    //   fromDate: Yup.date()
    //     .max(Yup.ref('toDate'), "From date must be earlier than to date")
    //     .required("Please Select From date"),
    //   toDate: Yup.date()
    //     .max(new Date(), "Date cannot be in the future")
    //     .required("Please Select to date"),
    // }),
    /*
    validationSchema: Yup.object({
      sigFirstName: Yup.string().when(['submitType', 'sigIncludeEmailYn'], {
        is: (submitType, sigIncludeEmailYn) => {
          console.log('1. submitType : ' + submitType + ', sigIncludeEmailYn : ' + sigIncludeEmailYn);
          if (submitType == 'updateSignature' && sigIncludeEmailYn == 'Y') {
            return true;
          } else {
            return false;
          }
        },
        then: Yup.string().required("Please enter your first name")
      }),
      sigLastName: Yup.string().when(['submitType', 'sigIncludeEmailYn'], {
        is: (submitType, sigIncludeEmailYn) => {
          if (submitType == 'updateSignature' && sigIncludeEmailYn == 'Y') {
            return true;
          } else {
            return false;
          }
        },
        then: Yup.string().required("Please enter your last name")
      }),
      sigOrganization: Yup.string().when("sigIncludeEmailYn", {
        is: 'Y',
        then: Yup.string().required("Please enter your firm's name")
      }),
      sigHomeCountry: Yup.string().when("sigIncludeEmailYn", {
        is: 'Y',
        then: Yup.string().required("Please enter your home country")
      }),
      sigEmailAddress: Yup.string().when("sigIncludeEmailYn", {
        is: 'Y',
        then: Yup.string().email("Please enter a valid email address").required("Please enter your work email address")
      }),
    }),
    */
    onSubmit: (values, {setErrors}) => {
      
      let submitType = values["submitType"];

      if (submitType == "updateSubscriptionToFree") {
        // console.log('updateSubscriptionToFree >>> ');
        dispatch(updateSubscriptionToFree());
      } else if(submitType === "updateGoldMembershipPlan"){
        const _newUserSubscriptionList = [];
        userSubscriptionList.map((subs, index) =>{
          let _subs = cloneDeep(subs);
          _subs.startDate = serverDate.replaceAll('-', '');
          _subs.endDate = nextPaymentDate.replaceAll('-', '');
          _subs.renewalPrice = billedMonthFlag ? _subs.monthly_1m : _subs.annually_1m * 12;
          _subs.renewalPeriod = billedMonthFlag ? 'monthly' : 'annually';
          _subs.renewalCost = billedMonthFlag ? _subs.monthly_1m : _subs.monthly_1m * 12;
          _subs.renewalYn = 'Y';
          _subs.wecrestProductID = wecrestProductID;
          _subs.paypalProductID = paypalProductID;
          _subs.paypalPlanID = paypalPlanID;
          _subs.paypalSubscriptionID = values["paypalSubscriptionID"];

          _newUserSubscriptionList.push(_subs);
        });

        if (_newUserSubscriptionList.length > 0) {
          // console.log('updateGoldMembershipPlan >>> ');
          // console.log(JSON.stringify(_newUserSubscriptionList));

          dispatch(updateGoldMembershipPlan(_newUserSubscriptionList));
        } else {
          // console.log('updateGoldMembershipPlan >>> ');
          dispatch(setMembershipPlanValue('ERROR_MSG', "Please select jurisdictions"));
        }
      } else if(submitType === "getPaypalPlanId"){
        const _newUserSubscriptionList = [];
        userSubscriptionList.map((subs, index) =>{
          let _subs = cloneDeep(subs);
          _subs.startDate = serverDate.replaceAll('-', '');
          _subs.endDate = nextPaymentDate.replaceAll('-', '');
          _subs.renewalPrice = billedMonthFlag ? _subs.monthly_1m : _subs.annually_1m * 12;
          _subs.renewalPeriod = billedMonthFlag ? 'monthly' : 'annually';
          _subs.renewalCost = billedMonthFlag ? _subs.monthly_1m : _subs.monthly_1m * 12;
          _subs.renewalYn = 'Y';

          _newUserSubscriptionList.push(_subs);
        });

        // console.log('getPaypalPlanId >>> ');
        // console.log(JSON.stringify(_newUserSubscriptionList));

        dispatch(getPaypalPlanId(_newUserSubscriptionList));
      }
    },
  });

  // [S] Paypal Button
  // Custom component to wrap the PayPalButtons and handle currency changes
  const ButtonWrapper = ({ showSpinner }) => {
    // usePayPalScriptReducer can be use only inside children of PayPalScriptProviders
    // This is the main reason to wrap the PayPalButtons in a new component
    const [{ options, isPending }, dispatch] = usePayPalScriptReducer();

    // console.log('isPending ' + isPending);

    useEffect(() => {
        dispatch({
            type: "resetOptions",
            value: {
                ...options,
                currency: "USD",
                intent: "subscription",
            },
        });
    }, [showSpinner]);


    return (<>
            { (showSpinner && isPending) && <div className="spinner" /> }
            { paypalPlanID != null ?
            <PayPalButtons
                style={{"layout":"vertical"}}
                disabled={false}
                forceReRender={[paypalPlanID]}
                fundingSource={undefined}
                
                createSubscription={(data, actions) => {
                  return actions.subscription
                    .create({
                      'plan_id': paypalPlanID
                    })
                    .then((orderId) => {
                      // Your code here after create the order
                      return orderId;
                    });             
                }}

                // onInit={(data, actions) => {
                //   console.log('successfully init ' + JSON.stringify(data));
                //   console.log('isPending ' + isPending);          
                // }}

                onApprove = {function(data, actions) {
                  // console.log('successfully created subscription : ' + JSON.stringify(data));
                  // console.log('subscription id : ' + data.subscriptionID);
                  filterValidation.setFieldValue("paypalSubscriptionID", data.subscriptionID);
                  filterValidation.setFieldValue("submitType", "updateGoldMembershipPlan");
                  filterValidation.handleSubmit(filterValidation.values);
                }}

                onError={ function (err) {
                  // For example, redirect to a specific error page
                  // window.location.href = "/your-error-page-here";
                  dispatch(setMembershipPlanValue('ERROR_MSG', 'Paypal transaction error : ' + err));
                }}
            />
            : null}
        </>
    );
  }
  // [E] Paypal Button
  
  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          
          <Row>
            <Card>
              <CardBody>
                <CardTitle className="h4">Manage my WeCrest membership plan</CardTitle>
                <p className="text-muted">
                With WeCrest, you have the tools and tactics to grow your inbound and outbound trademark practice. Whether you’re a specialist IP boutique or a full-service multinational law firm, we’re here to help you.
                </p>
                {
                  manageGoldPlan ?
                  <Row className="mt-5">
                    <Col xl={12} className="d-flex vertical-align-center gap-3">
                      <label
                        className={billedMonthFlag ? "form-check-label" : "form-check-label text-muted"}
                        style={{marginRight: "0.5rem"}}
                      >
                        Billed monthly
                      </label>
                      <div className="form-check form-switch form-switch-md">
                        <input
                          type="checkbox"
                          className="form-check-input billing-switch"
                          id="billedFlag"
                          style={{backgroundColor: "#5b73e8", borderColor: "#5b73e8"}}
                          onClick={e => {
                            dispatch(setMembershipPlanValue('BILLED_MONTH_FLAG', !billedMonthFlag));

                            setCurrentRenewalPeriod(!billedMonthFlag ? 'monthly' : 'annually');
                            if (currentRenewalPeriod != orgCurrentRenewalPeriod) {
                              dispatch(setMembershipPlanValue('CHANGE_SUBSCRIPTION_FLAG', true));
                            }
                          }}
                          checked={!billedMonthFlag}
                        />
                      </div>
                      <label
                          className={!billedMonthFlag ? "form-check-label" : "form-check-label text-muted"}
                        >
                          Billed annually (save 30%)
                        </label>
                    </Col>
                  </Row>
                  :
                  null
                }
                
                <Row className="mt-4">
                  {
                    manageGoldPlan ?
                    <Col xl={4} className="mx-auto">
                      <Card>
                        <CardBody style={userGrade == '00' ? {backgroundColor: "#e5eceaff"} : {}}>
                          <CardTitle className="h4">Free{userGrade == '00' ? " (current plan)" : ""}</CardTitle>
                          <p className="text-muted">
                          Explore data-driven trademark business development.
                          </p>
                          <Row className="mb-3">
                            <p className="text-muted" style={{marginBottom:"0px"}}>
                              <i class="uil uil-check font-size-16 text-success me-2"></i> WeCrest branding in email footer
                            </p>
                            <p className="text-muted" style={{marginBottom:"0px"}}>
                              <i class="uil uil-check font-size-16 text-success me-2"></i> 5 case unlocks and email sends / week
                            </p>
                            <p className="text-muted" style={{marginBottom:"0px"}}>
                              <i class="uil uil-check font-size-16 text-success me-2"></i> Email customer support
                            </p>
                          </Row>
                          <Row className="mb-3" style={{borderTop: "1px solid #f5f6f8"}}>
                            <label
                              htmlFor="irNumber"
                              className="col-form-label"
                              >
                              $0
                            </label>
                            <p className="text-muted">
                            Free forever
                            </p>
                            <p className="text-muted">
                              &nbsp;
                            </p>
                            <p className="text-muted">
                            &nbsp;
                            </p>
                          </Row>
                          <Row className="mb-3">&nbsp;</Row>
                          <Row className="justify-content-center">
                          {
                            userGrade == '00' ?
                            <button
                              type="button"
                              className="btn btn-secondary col-md-4"
                            >
                              Current plan
                            </button>
                            :
                            <button
                              type="button"
                              onClick={() => {
                                //dollarUSLocale.format();
                                setUpgradeMessage({title:"Gold to Free!", confirmBtnText:"Downgrade", method:"updateSubscriptionToFree", text:"If you change your plan now, you will lose the remainder of your existing plan with no refund. We recommend you wait till closer to your next billing date to change your plan, to make the most of your existing plan. Are you sure you want to change your plan now?"})
                              }}
                              className="btn btn-primary btn-wcdark waves-effect col-md-4"
                            >
                              Downgrade
                            </button>
                          }
                          </Row>
                        </CardBody>
                      </Card>
                      {/*
                      <Card>
                        <CardBody>
                          <CardTitle className="h4">Free plan features</CardTitle>
                          
                          <Row className="mb-3">
                            <p className="text-muted" style={{marginBottom:"0px"}}>
                              5 case unlocks per week
                            </p>
                            <p className="text-muted" style={{marginBottom:"0px"}}>
                              2 day update time for new cases
                            </p>
                          </Row>
                          <Row className="mb-3" style={{borderTop: "1px solid #f5f6f8"}}>
                            <p className="text-muted" style={{marginBottom:"0px"}}>
                              <i class="uil uil-check font-size-16 text-success me-2"></i> No branding in email footer
                            </p>
                            <p className="text-muted" style={{marginBottom:"0px"}}>
                              <i class="uil uil-check font-size-16 text-success me-2"></i> 6 month case search history
                            </p>
                            <p className="text-muted" style={{marginBottom:"0px"}}>
                              <i class="uil uil-check font-size-16 text-success me-2"></i> 50 search results in Filer Rankings
                            </p>
                          </Row>
                          
                        </CardBody>
                      </Card>
                        */}
                    </Col>
                    :
                    null
                  }
                  
                  {/*
                  <Col xl={4} className="mx-auto">
                    <Card>
                      <CardBody>
                        <CardTitle className="h4">Silver</CardTitle>
                        <p className="text-muted">
                        Access more cases and send more emails for any jurisdiction.
                        </p>
                        <Row className="mb-3">
                          <label
                          htmlFor="irNumber"
                          className="col-form-label"
                          >
                          50 case unlocks per month
                          </label>
                        </Row>
                        <Row className="mb-3" style={{borderTop: "1px solid #f5f6f8"}}>
                          <label
                            htmlFor="irNumber"
                            className="col-form-label"
                            >
                            $33 / month
                          </label>
                          <p className="text-muted">
                          Billed annually
                          </p>
                          <p className="text-muted">
                          Next payment: $393 (2023-08-29)
                          </p>
                          <p className="text-muted">
                          Due today: $0 USD
                          </p>
                        </Row>
                        <Row className="justify-content-center">
                          <button
                            type="button"
                            onClick={() => {
                              //window.location.href = "/membershipPlan";
                            }}
                            className="btn btn-primary btn-wcdark waves-effect col-md-4"
                          >
                            Downgrade
                          </button>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>
                  */}

                  <Col xl={4} className="mx-auto">
                    <Card>
                      <CardBody style={userGrade == '02' ? {backgroundColor: "#e5eceaff"} : {}}>
                        <CardTitle className="h4">Gold{userGrade == '02' ? " (current plan)" : ""}</CardTitle>
                        <p className="text-muted">
                        Access unlimited cases in your Gold jurisdictions.
                        </p>
                        <Row className="mb-3">
                          <p className="text-muted" style={{marginBottom:"0px"}}>
                            <i class="uil uil-check font-size-16 text-success me-2"></i> No WeCrest branding in email footer
                          </p>
                          <p className="text-muted" style={{marginBottom:"0px"}}>
                            <i class="uil uil-check font-size-16 text-success me-2"></i> Unlimited access to all cases
                          </p>
                          <p className="text-muted" style={{marginBottom:"0px"}}>
                            <i class="uil uil-check font-size-16 text-success me-2"></i> Email and video call customer support
                          </p>
                        </Row>
                        {/*
                        <Row className="mb-3">
                          <label
                          htmlFor="irNumber"
                          className="col-form-label"
                          >
                          Unlimited case unlocks
                          </label>
                        </Row>
                        */}
                        <Row className="mb-3" style={{borderTop: "1px solid #f5f6f8"}}>
                          <label
                            htmlFor="irNumber"
                            className="col-form-label"
                            >
                            ${monthPrice} / month
                          </label>
                          <p className="text-muted" style={{marginBottom:"0px"}}>
                            {currentRenewalPeriod !== '' ? "Billed " + currentRenewalPeriod : billedMonthFlag ? "Billed monthly" : "Billed annually"}
                          </p>
                          <p className="text-muted" style={{marginBottom:"0px"}}>
                          Next payment: ${nextPrice} ({nextPaymentDate} KST)
                          </p>
                          <p className="text-muted" style={{marginBottom:"0px"}}>
                          Due today: ${dueTodayPrice} USD
                          </p>
                        </Row>
                        <Row className={userSubscriptionList && userSubscriptionList.length ? "mb-3" : ""}>
                        {
                          userSubscriptionList && userSubscriptionList.length ?
                          userSubscriptionList.map((userSubscription, index) => {
                            let _monthPrice = 0;
                            if (userSubscription.invoiceTerm == '3months') {
                              _monthPrice += Number(userSubscription.invoiceAmount) / 3;
                            } else if (userSubscription.invoiceTerm == '6months') {
                              _monthPrice += Number(userSubscription.invoiceAmount) / 6;
                            } else if (userSubscription.invoiceTerm == 'annually') {
                              _monthPrice += Number(userSubscription.invoiceAmount) / 12;
                            } else {
                              _monthPrice += userSubscription.invoiceAmount;
                            }

                            return (
                              !manageGoldPlan ?
                              <p className="text-muted" style={{marginBottom:"0px"}}>
                              {userSubscription.crCode} {userSubscription.crName} - ${dollarUSLocale.format(_monthPrice)} / month
                              </p>
                              :
                              <div class="alert alert-wcgreen alert-dismissible" style={userGrade == '00' ? {backgroundColor: "#e5eceaff"} : {}}>
                                <button type="button" class="btn-close" aria-label="Close" 
                                  onClick={() => {

                                    // console.log('del index : ' + index)

                                    jurisdictionsMonthlyPricingRef.current.jurCheck(userSubscription.crCode, false);

                                    let sameCrCnt = 0;
                                    let _newUserSubscriptionList = cloneDeep(userSubscriptionList);
                                    _newUserSubscriptionList = _newUserSubscriptionList.filter((obj, chkIdx) => {
                                      return chkIdx !== index;
                                    });
                            
                                    _newUserSubscriptionList.map((jur, index1) =>{
                                      orgUserSubscriptionList.map((subs, index2) =>{
                                        // console.log('index1 : ' + index1 + ', index2 : ' + index2);
                                        // console.log('jur.crCode : ' + jur.crCode);
                                        // console.log('subs.crCode : ' + subs.crCode);

                                          if (jur.crCode == subs.crCode) {
                                              sameCrCnt++;
                                          }
                                      });
                                    });

                                    // console.log('sameCrCnt : ' + sameCrCnt + ', orgUserSubscriptionList.length : ' + orgUserSubscriptionList.length);
                            
                                    dispatch(setMembershipPlanValue('USER_SUBSCRIPTION_LIST', _newUserSubscriptionList));
                                    dispatch(setMembershipPlanValue('CHANGE_SUBSCRIPTION_FLAG', sameCrCnt != orgUserSubscriptionList.length ? true : false));
                                  }}>
                                </button>
                              {userSubscription.crCode} {userSubscription.crName}<br/>
                              ${billedMonthFlag ? dollarUSLocale.format(userSubscription.monthly_1m) : dollarUSLocale.format(userSubscription.annually_1m)} / month
                              </div>
                            )
                          })
                          : null
                        }
                        </Row>

                        <JurisdictionsMonthlyPricing formik={filterValidation} manageGoldPlan={manageGoldPlan} paypalPlanId={paypalPlanID} ref={jurisdictionsMonthlyPricingRef}/>

                        {
                        userGrade == '02' ?
                        paypalPlanID == '' ?
                            <Row className="justify-content-center gap-3">
                              {
                                membershipPlanType == 'User' ?
                                  !manageGoldPlan ?
                                  <button
                                    type="button"
                                    className="btn btn-outline-secondary col-md-4"
                                    onClick={() => {
                                      setCurrentRenewalPeriod('');
                                      setManageGoldPlan(true);
                                    }}
                                  >
                                    Manage plan
                                  </button>
                                  :
                                  <button
                                    type="button"
                                    onClick={() => {
                                      dispatch(setMembershipPlanValue('USER_SUBSCRIPTION_LIST', orgUserSubscriptionList));
                                      dispatch(setMembershipPlanValue('CHANGE_SUBSCRIPTION_FLAG', false));
                                      setCurrentRenewalPeriod(orgCurrentRenewalPeriod);
                                      setManageGoldPlan(false);
                                    }}
                                    className="btn btn-primary btn-wcdark waves-effect col-md-4"
                                  >
                                    Stay with current plan
                                  </button>
                                : 
                                  <button
                                    type="button"
                                    className="btn btn-outline-secondary col-md-4"
                                    onClick={() => {
                                      dispatch(setMembershipPlanValue('ERROR_MSG', <>To change your membership plan, please contact us at <a href='mailto:global@wecrest.com'>global@wecrest.com</a>. We look forward to hearing from you!</>));
                                    }}
                                  >
                                    Contact us
                                  </button>
                              }

                              {
                                manageGoldPlan && changeSubscriptionFlag ?
                                <button
                                  type="button"
                                  onClick={() => {
                                    setUpgradeMessage({title:"Free to Gold!", confirmBtnText:"Upgrade", method:"getPaypalPlanId", text:getRemainingBalance('Gold') > 0 ? "Please click the PayPal button to proceed with the payment (amount due : " +dollarUSLocale.format(getRemainingBalance('Gold')) + ")" : "If you change your plan now, you will lose the remainder of your existing plan with no refund. We recommend you wait till closer to your next billing date to change your plan, to make the most of your existing plan. Are you sure you want to change your plan now?"})
                                  }}
                                  className="btn btn-primary btn-wcdark waves-effect col-md-4"
                                >
                                  Switch to this plan
                                </button>
                                :
                                null
                              }
                              
                            </Row>
                          : null
                        :
                        paypalPlanID == '' ?
                            <Row className="justify-content-center gap-3">
                              <button
                                type="button"
                                onClick={() => {
                                  setUpgradeMessage({title:"Free to Gold!", confirmBtnText:"Upgrade", method:"getPaypalPlanId", text:getRemainingBalance('Gold') > 0 ? "Please click the PayPal button to proceed with the payment (amount due : " +dollarUSLocale.format(getRemainingBalance('Gold')) + ")" : "If you change your plan now, you will lose the remainder of your existing plan with no refund. We recommend you wait till closer to your next billing date to change your plan, to make the most of your existing plan. Are you sure you want to change your plan now?"})
                                }}
                                className="btn btn-primary btn-wcdark waves-effect col-md-4"
                              >
                                Switch to this plan
                              </button>
                            </Row>
                          : null
                        }

                        {paypalClientID != '' && paypalPlanID != '' ?
                        <Row className="justify-content-center gap-3">
                          <PayPalScriptProvider
                            options={{
                                "client-id": paypalClientID,
                                components: "buttons",
                                currency: "USD",
                                vault: true,
                            }}
                          >
                            {
                              /*
                              <ButtonWrapper
                              showSpinner={true}
                            />
                            */
                            }
                            
                            <PayPalButtons
                              style={{"layout":"vertical"}}
                              disabled={false}
                              // forceReRender={[paypalPlanId]}
                              fundingSource={undefined}
                              
                              createSubscription={(data, actions) => {
                                // console.log('createSubscription >> paypalClientID : ' + paypalClientID + ', paypalPlanID : ' + paypalPlanID )

                                return actions.subscription
                                  .create({
                                    'plan_id': paypalPlanID
                                  })
                                  .then((orderId) => {
                                    // Your code here after create the order
                                    return orderId;
                                  });             
                              }}

                              // onInit={(data, actions) => {
                              //   console.log('successfully init ' + JSON.stringify(data));
                              //   console.log('isPending ' + isPending);          
                              // }}

                              onApprove = {function(data, actions) {
                                // console.log('successfully created subscription : ' + JSON.stringify(data));
                                // console.log('subscription id : ' + data.subscriptionID);
                                filterValidation.setFieldValue("paypalSubscriptionID", data.subscriptionID);
                                filterValidation.setFieldValue("submitType", "updateGoldMembershipPlan");
                                filterValidation.handleSubmit(filterValidation.values);
                              }}

                              onError={ function (err) {
                                // For example, redirect to a specific error page
                                // window.location.href = "/your-error-page-here";
                                dispatch(setMembershipPlanValue('ERROR_MSG', 'Paypal transaction error : ' + err));
                              }}
                          />
                          </PayPalScriptProvider>
                        </Row>
                        :null}
                        
                      </CardBody>
                    </Card>
                    {/*
                    <Card>
                      <CardBody>
                        <CardTitle className="h4">Gold plan features</CardTitle>
                        
                        <Row className="mb-3">
                          <p className="text-muted" style={{marginBottom:"0px"}}>
                            5 case unlocks per week
                          </p>
                          <p className="text-muted" style={{marginBottom:"0px"}}>
                            2 day update time for new cases
                          </p>
                        </Row>
                        <Row className="mb-3" style={{borderTop: "1px solid #f5f6f8"}}>
                          <p className="text-muted" style={{marginBottom:"0px"}}>
                            <i class="uil uil-check font-size-16 text-success me-2"></i> No branding in email footer
                          </p>
                          <p className="text-muted" style={{marginBottom:"0px"}}>
                            <i class="uil uil-check font-size-16 text-success me-2"></i> 6 month case search history
                          </p>
                          <p className="text-muted" style={{marginBottom:"0px"}}>
                            <i class="uil uil-check font-size-16 text-success me-2"></i> 50 search results in Filer Rankings
                          </p>
                        </Row>
                        
                      </CardBody>
                    </Card>
                    */}
                  </Col>
                  {
                    !manageGoldPlan ?
                    <Col xl={4} className="mx-auto">
                    </Col>
                    : null
                  }
                  <Col xl={4} className="mx-auto">
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Row>
          <Row>
            <Card>
              <CardBody>
                <Link onClick={toggle} className="text-dark" to="#">
                  <div>
                    <Media className="d-flex align-items-center">
                      <div className="me-3">
                        <div className="avatar-xs">
                          <div className="avatar-title rounded-circle font-size-22">
                            <i className="uil uil-question-circle"></i>
                          </div>
                        </div>
                      </div>
                      <div className="flex-grow-1 overflow-hidden">
                        <CardTitle>Frequently asked questions</CardTitle>
                      </div>
                      <i className={isOpen ? "mdi mdi-chevron-up accor-down-icon font-size-16" : "mdi mdi-chevron-down accor-down-icon font-size-16"}></i>
                    </Media>
                  </div>
                </Link>
                <Collapse isOpen={isOpen}>
                  <Row className="mt-4">
                    <Col md="6">
                      <div>
                        <div className="d-flex align-items-start mt-4">
                          <div className="flex-shrink-0 me-3">
                            <div className="avatar-xs">
                              <div className="avatar-title rounded-circle bg-soft-primary text-primary font-size-22">
                                <i className="uil uil-question-circle"></i>
                              </div>
                            </div>
                          </div>

                          <div className="flex-grow-1">
                            <h5 className="font-size-16 mt-1">
                            Can I pay by credit card or PayPal ?
                            </h5>
                            <p className="text-muted">
                            Yes, you can specify your preferred payment method on the checkout page.
                            </p>
                          </div>
                        </div>

                        <div className="d-flex align-items-start mt-4">
                          <div className="flex-shrink-0 me-3">
                            <div className="avatar-xs">
                              <div className="avatar-title rounded-circle bg-soft-primary text-primary font-size-22">
                                <i className="uil uil-question-circle"></i>
                              </div>
                            </div>
                          </div>

                          <div className="flex-grow-1">
                            <h5 className="font-size-16 mt-1">
                            Can I cancel my membership ?
                            </h5>
                            <p className="text-muted">
                            Yes, you can cancel your membership at any time.
                            </p>
                          </div>
                        </div>

                        <div className="d-flex align-items-start mt-4">
                          <div className="flex-shrink-0 me-3">
                            <div className="avatar-xs">
                              <div className="avatar-title rounded-circle bg-soft-primary text-primary font-size-22">
                                <i className="uil uil-question-circle"></i>
                              </div>
                            </div>
                          </div>

                          <div className="flex-grow-1">
                            <h5 className="font-size-16 mt-1">
                            Can I make a profit from using WeCrest ?
                            </h5>
                            <p className="text-muted">
                            Most WeCrest members make a profit and renew their subscriptions. From 2019 to today (2022/12/10), we had 238 paid subscription renewals in 55 Madrid jurisdictions at a renewal rate of approximately 60%. Our data shows that the most active members renew more often.
                            </p>
                          </div>
                        </div>

                        <div className="d-flex align-items-start mt-4">
                          <div className="flex-shrink-0 me-3">
                            <div className="avatar-xs">
                              <div className="avatar-title rounded-circle bg-soft-primary text-primary font-size-22">
                                <i className="uil uil-question-circle"></i>
                              </div>
                            </div>
                          </div>

                          <div className="flex-grow-1">
                            <h5 className="font-size-16 mt-1">
                            How many Madrid cases are available in my jurisdiction(s) per year ?
                            </h5>
                            <p className="text-muted">
                            Find out by selecting your jurisdiction(s) at <a href="https://app.wecrest.com/generalStatistics" target="_blank">app.wecrest.com/generalStatistics</a>.
                            </p>
                          </div>
                        </div>

                        <div className="d-flex align-items-start mt-4">
                          <div className="flex-shrink-0 me-3">
                            <div className="avatar-xs">
                              <div className="avatar-title rounded-circle bg-soft-primary text-primary font-size-22">
                                <i className="uil uil-question-circle"></i>
                              </div>
                            </div>
                          </div>

                          <div className="flex-grow-1">
                            <h5 className="font-size-16 mt-1">
                            Are my contacts and my data stored in my WeCrest account safe and private ?
                            </h5>
                            <p className="text-muted">
                            Your data is SSL encrypted and safe. We do not share, rent, or sell your data with IP firms or 3rd parties other than contractors who we rely on to deliver our services. Read more about our privacy policy at <a href="https://www.wecrest.com/privacy-policy" target="_blank">wecrest.com/privacy-policy</a>.
                            </p>
                          </div>
                        </div>
                      </div>
                    </Col>

                    <Col md="6">
                      <div>
                        <div className="d-flex align-items-start mt-4">
                          <div className="flex-shrink-0 me-3">
                            <div className="avatar-xs">
                              <div className="avatar-title rounded-circle bg-soft-primary text-primary font-size-22">
                                <i className="uil uil-question-circle"></i>
                              </div>
                            </div>
                          </div>

                          <div className="flex-grow-1">
                            <h5 className="font-size-16 mt-1">
                            Can I pay by bank transfer ?
                            </h5>
                            <p className="text-muted">
                            Yes, please contact us at <a href="mailto:members@wecrest.com">members@wecrest.com</a>.
                            </p>
                          </div>
                        </div>
                      
                        <div className="d-flex align-items-start mt-4">
                          <div className="flex-shrink-0 me-3">
                            <div className="avatar-xs">
                              <div className="avatar-title rounded-circle bg-soft-primary text-primary font-size-22">
                                <i className="uil uil-question-circle"></i>
                              </div>
                            </div>
                          </div>

                          <div className="flex-grow-1">
                            <h5 className="font-size-16 mt-1">
                            How many other IP firms use WeCrest ?
                            </h5>
                            <p className="text-muted">
                            As of today (2022/12/10), 137 IP firms have a WeCrest Gold account. Read more about their WeCrest experience at <a href="https://www.wecrest.com/blog-categories/insights-member-stories" target="_blank">wecrest.com/members</a>.
                            </p>
                          </div>
                        </div>

                        <div className="d-flex align-items-start mt-4">
                          <div className="flex-shrink-0 me-3">
                            <div className="avatar-xs">
                              <div className="avatar-title rounded-circle bg-soft-primary text-primary font-size-22">
                                <i className="uil uil-question-circle"></i>
                              </div>
                            </div>
                          </div>

                          <div className="flex-grow-1">
                            <h5 className="font-size-16 mt-1">
                            How many users from our firm would be able to use WeCrest at the same time ?
                            </h5>
                            <p className="text-muted">
                            You will receive one account (i.e., one login ID), but there is no limit on the number of people or devices that can access WeCrest at the same time. We recommend, however, that only one person logs in at a time, because if multiple people log in to the same account at the same time, the actions of one might interfere with those of another.
                            </p>
                          </div>
                        </div>

                        <div className="d-flex align-items-start mt-4">
                          <div className="flex-shrink-0 me-3">
                            <div className="avatar-xs">
                              <div className="avatar-title rounded-circle bg-soft-primary text-primary font-size-22">
                                <i className="uil uil-question-circle"></i>
                              </div>
                            </div>
                          </div>

                          <div className="flex-grow-1">
                            <h5 className="font-size-16 mt-1">
                            How many representative email addresses does WeCrest provide ?
                            </h5>
                            <p className="text-muted">
                            As of today (2022/12/10), we provide email addresses for about 42% of all representatives who filed under the Madrid Protocol recently. Our data team is adding new email addresses to WeCrest every day.
                            </p>
                          </div>
                        </div>

                        <div className="d-flex align-items-start mt-4">
                          <div className="flex-shrink-0 me-3">
                            <div className="avatar-xs">
                              <div className="avatar-title rounded-circle bg-soft-primary text-primary font-size-22">
                                <i className="uil uil-question-circle"></i>
                              </div>
                            </div>
                          </div>

                          <div className="flex-grow-1">
                            <h5 className="font-size-16 mt-1">
                            Is using WeCrest compliant with common personal data protection laws ?
                            </h5>
                            <p className="text-muted">
                            According to the Recital 47 of the GDPR of the European Union, the processing of personal data for direct marketing purposes may be regarded as carried out for a legitimate interest. For more information on GDPR-compliance, send us an email at <a href="mailto:members@wecrest.com">members@wecrest.com</a>.
                            </p>
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Collapse>
              </CardBody>
            </Card>
          </Row>
        </div>
      </div>

      {
        success ? 
        <SweetAlert
          title={success}
          timeout={2000}
          showCloseButton={false}
          showConfirm={false}
          success
          onConfirm={() => {
            dispatch(setMembershipPlanValue('SUCCESS', ""));
          }}
        ></SweetAlert>
        : null
      }

      {errorMsg !== '' ? (
        <SweetAlert
          onConfirm={() => {
            dispatch(setMembershipPlanValue('ERROR_MSG', ""));
          }}
          onCancel={() => {
            dispatch(setMembershipPlanValue('ERROR_MSG', ""));
          }}
        >{errorMsg}
        </SweetAlert>
        ) 
      : null}

      {upgradeMessage.title != "" ? (
        <SweetAlert
          warning
          showCancel
          confirmBtnText={upgradeMessage.confirmBtnText}
          cancelBtnText="Cancel"
          confirmBtnBsStyle="wcdark"
          cancelBtnBsStyle="secondary"
          onConfirm={() => {
            setUpgradeMessage({title:"", confirmBtnText:"", method:"", text:""});
            filterValidation.setFieldValue("submitType", upgradeMessage.method);
            filterValidation.handleSubmit(filterValidation.values);
            
          }}
          onCancel={() => setUpgradeMessage({title:"", confirmBtnText:"", method:"", text:""})}
        >
          {upgradeMessage.text}
        </SweetAlert>
      ) : null}

      <LoadingModal loading={loading} setLoading={closeLoding}></LoadingModal>
    </React.Fragment>
  )
}

export default MembershipPlan;