import React, {useState} from 'react';
import {
    Table,
    Card,
    CardBody,
    Media,
    Collapse,
    Modal,
  } from "reactstrap"
import { Link } from "react-router-dom";

import '../../components/Common/Common.css';

const SendEmailHistory = props => {
  const [isOpenAttach, setIsOpenAttach] = useState(true);
  const toggleAttach = () => {
    setIsOpenAttach(!isOpenAttach);
  }

  const [isOpenHistory, setIsHistory] = useState(true);
  const toggleHistory = () => {
    setIsHistory(!isOpenHistory);
  }

  return (
    <React.Fragment>
      {
        props.preview && Object.keys(props.preview).length !== 0 ?
        <Modal
          size="xl"
          isOpen={true}
          toggle={() => {
            props.setPreview({})
          }}
        >
          <div className="modal-header" style={{borderBottom: "0px", padding: "0.8rem"}}>
            
            <button
              onClick={() => {
                props.setPreview({})
              }}
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            
            <Card className="mb-2">
              <CardBody className="p-1">
                <Table className="table mb-0">
                  <tbody>
                    <tr style={{borderTop: "transparent"}}>
                      <td width="15%">Sent</td><td>{props.preview.sentDate}</td>
                    </tr>
                    <tr>
                      <td width="15%">From</td><td>{props.preview.fromEmail}</td>
                    </tr>
                    <tr>
                      <td width="15%">To</td><td>{props.preview.toEmail}</td>
                    </tr>
                    <tr>
                      <td width="15%">CC</td><td>{props.preview.ccEmail}</td>
                    </tr>
                    <tr>
                      <td width="15%">BCC</td><td>{props.preview.bccEmail}</td>
                    </tr>
                    <tr>
                      <td width="15%">Subject</td><td>{props.preview.subject}</td>
                    </tr>
                  </tbody>
                </Table>
              </CardBody>
            </Card>

            <Card className="mb-2">
              <CardBody dangerouslySetInnerHTML={{__html:props.preview.content ? props.preview.content : null}}></CardBody>
            </Card>
            <Card className={props.preview.sendinblueStatusList && props.preview.sendinblueStatusList.length ? "mb-2" : "mb-0"}>
              <CardBody className="p-1">
                <Link onClick={toggleAttach} className="text-dark" to="#">
                  <div style={{padding: "0.75rem"}}>
                    <Media className="d-flex align-items-center">
                      <div className="flex-grow-1 overflow-hidden">
                        <p className="text-muted mb-0">Attachment</p>
                      </div>
                      <i className={isOpenAttach ? "mdi mdi-chevron-up accor-down-icon font-size-24" : "mdi mdi-chevron-down accor-down-icon font-size-24"} style={{ cursor: "pointer"}}></i>
                    </Media>
                  </div>
                </Link>
                <Collapse isOpen={isOpenAttach}>
                  <Table className="table mb-0">
                    <tbody>
                      {props.preview.irNumber && props.preview.pdfStorageUrl ?
                      <tr><td><a href={props.preview.pdfStorageUrl} target="_blank" rel="noreferrer"><i className="mdi mdi-file-pdf text-danger font-size-22"></i> {props.preview.irNumber}</a></td></tr>
                      : null}
                      {props.preview.attachList && props.preview.attachList.length?
                        props.preview.attachList.map((attach, index)=>{
                          return (
                            <tr><td>{attach.fileName}</td></tr>
                          )
                        })
                        :null
                      }
                    </tbody>
                  </Table>
                </Collapse>
              </CardBody>
            </Card>
            {props.preview.sendinblueStatusList && props.preview.sendinblueStatusList.length?
            <Card className="mb-0">
              <CardBody className="p-1">
                <Link onClick={toggleHistory} className="text-dark" to="#">
                  <div style={{padding: "0.75rem"}}>
                    <Media className="d-flex align-items-center">
                      <div className="flex-grow-1 overflow-hidden">
                        <p className="text-muted mb-0">History</p>
                      </div>
                      <i className={isOpenHistory ? "mdi mdi-chevron-up accor-down-icon font-size-24" : "mdi mdi-chevron-down accor-down-icon font-size-24"} style={{ cursor: "pointer"}}></i>
                    </Media>
                  </div>
                </Link>
                <Collapse isOpen={isOpenHistory}>
                  <Table className="table mb-0">
                    <tbody>
                      {props.preview.sendinblueStatusList.map((sendinblueStatus, index)=>{
                        return (
                          <tr><td width="15%">{sendinblueStatus.sendinblueStatusDesc}</td><td>{sendinblueStatus.eventDate}</td></tr>
                        )
                      })}
                    </tbody>
                  </Table>
                </Collapse>
              </CardBody>
            </Card>
            :null}
          </div>
          <div className="modal-footer">
            <button
              type="button"
              onClick={() => {
                props.setPreview({})
              }}
              className="btn btn-secondary waves-effect"
              data-dismiss="modal"
            >
              Close
            </button>
          </div>
        </Modal>
        : null
      }
    </React.Fragment>
  )
}

export default SendEmailHistory;