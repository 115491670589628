import React, {useState, forwardRef, useImperativeHandle} from 'react';
import { useSelector, useDispatch } from "react-redux";
import {
    Row,
    Card,
    CardBody,
    Form,
    Input,
    Button,
    Modal,
    FormFeedback,
  } from "reactstrap"
import { useFormik } from "formik";
import SweetAlert from "react-bootstrap-sweetalert";

import {
  addUserInvoiceInfo as onAddUserInvoiceInfo,
  modifyUserInvoiceInfo as onModifyUserInvoiceInfo,
  addAdmAccountManagementBillingProfile as onAddAdmAccountManagementBillingProfile,
  changeAdmAccountManagementBillingProfile as onChangeAdmAccountManagementBillingProfile,
} from "../../store/actions";

const CreateBillingProfile = forwardRef((props, ref) => {
  const dispatch = useDispatch();

  const { userInvoiceInfoList} = useSelector(state => ({
    userInvoiceInfoList: props.pageName === 'invoiceSetting' ? state.AdmInvoiceManagement.userInvoiceInfoList : state.AdmAccountManagement.createBillingProfileList,
  }));

  const [errorMsg, setErrorMsg] = useState('');

  const createBillingProfile = () => {
    filterValidation.setFieldValue("seq", 0);
    filterValidation.setFieldValue("invoiceTitle", "");
    filterValidation.setFieldValue("invoiceFirstName", "");
    filterValidation.setFieldValue("invoiceLastName", "");
    filterValidation.setFieldValue("invoiceCompanyName", "");
    filterValidation.setFieldValue("invoiceAddress", "");
    filterValidation.setFieldValue("invoiceEmail", "");
    filterValidation.setFieldValue("taxId", "");
    filterValidation.setFieldValue("invoiceInfo", "");
    if (props.pageName === 'invoiceSetting') {
      filterValidation.setFieldValue("submitType", "addUserInvoiceInfo");
    } else if (props.pageName === 'admCreateAccount') {
      filterValidation.setFieldValue("submitType", "addAdmAccountManagementBillingProfile");
    }
    props.setIsOpenBillingProfile(true);
  }

  const editBillingProfile = (userInvoiceInfo) => {
    filterValidation.setFieldValue("seq", userInvoiceInfo.seq);
    filterValidation.setFieldValue("invoiceTitle", userInvoiceInfo.invoiceTitle);
    filterValidation.setFieldValue("invoiceFirstName", userInvoiceInfo.invoiceFirstName);
    filterValidation.setFieldValue("invoiceLastName", userInvoiceInfo.invoiceLastName);
    filterValidation.setFieldValue("invoiceCompanyName", userInvoiceInfo.invoiceCompanyName);
    filterValidation.setFieldValue("invoiceAddress", userInvoiceInfo.invoiceAddress);
    filterValidation.setFieldValue("invoiceEmail", userInvoiceInfo.invoiceEmail);
    filterValidation.setFieldValue("taxId", userInvoiceInfo.taxId);
    filterValidation.setFieldValue("invoiceInfo", userInvoiceInfo.invoiceInfo);
    if (props.pageName === 'invoiceSetting') {
      filterValidation.setFieldValue("submitType", "modifyUserInvoiceInfo");
    } else if (props.pageName === 'admCreateAccount') {
      filterValidation.setFieldValue("submitType", "changeAdmAccountManagementBillingProfile");
    }
    props.setIsOpenBillingProfile(true);
  }

  useImperativeHandle(ref, () => ({
    createBillingProfile,
    editBillingProfile,
  }))

  const validateForm= (values, props /* only available when using withFormik */) => {
    const errors = {};
    let regex = /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*\.[a-zA-Z]{2,15}$/i;

    if (!values.invoiceTitle) {
      errors.invoiceTitle = 'Please enter your billing profile';
    } 

    if (!values.invoiceCompanyName) {
      errors.invoiceCompanyName = 'Please enter your invoicing company name';
    } 
    
    if (!values.invoiceAddress) {
      errors.invoiceAddress = 'Please enter your invoicing address';
    }

    if (!values.invoiceEmail) {
      errors.invoiceEmail = 'Please enter your accounting email';
    } else {
      let emailAddressList = values.invoiceEmail.split(",");
      for (var i=0; i<emailAddressList.length; i++) { 
        console.log(emailAddressList[i] + " : " + regex.test(emailAddressList[i].trim()));

        if (!regex.test(emailAddressList[i].trim())) {
          errors.invoiceEmail = 'Please enter a valid accounting email';
        }
      }
    }
  
    return errors;
  };

  const filterValidation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      submitType: "",
      
      seq: 0,
      invoiceTitle: "",
      invoiceFirstName: "",
      invoiceLastName: "",
      invoiceCompanyName: "",
      invoiceAddress: "",
      invoiceEmail: "",
      taxId: "",
      invoiceInfo: "",
    },
    
    onSubmit: (values, {setErrors}) => {
      let submitType = values["submitType"];

      let sigError = validateForm(values);
      if (JSON.stringify(sigError) !== '{}') {
        setErrors(sigError);
      } else {
        
        let isDuplicateTitle = false;
        userInvoiceInfoList.map((userInvoiceInfo, index) => {
          if (userInvoiceInfo.seq !== values["seq"] && userInvoiceInfo.invoiceTitle === values["invoiceTitle"]) {
            isDuplicateTitle = true;
            return false;
          }
        })

        if (isDuplicateTitle) {
          setErrorMsg(<>The following invoice titles are duplicates<br/>[{values["invoiceTitle"]}]</>);
          return;
        }

        const param = {
          seq: values["seq"]
          , invoiceTitle: values["invoiceTitle"]
          , invoiceFirstName: values["invoiceFirstName"]
          , invoiceLastName: values["invoiceLastName"]
          , invoiceCompanyName: values["invoiceCompanyName"]
          , invoiceAddress: values["invoiceAddress"]
          , invoiceEmail: values["invoiceEmail"]
          , taxId: values["taxId"]
          , invoiceInfo: values["invoiceInfo"]
        };

        if (submitType === "addUserInvoiceInfo") {
          dispatch(onAddUserInvoiceInfo(param));
        } else if (submitType === "modifyUserInvoiceInfo") {
          dispatch(onModifyUserInvoiceInfo(param));
        } else if (submitType === "addAdmAccountManagementBillingProfile") {
          dispatch(onAddAdmAccountManagementBillingProfile(param));
          props.setIsOpenBillingProfile(false);
        } else if (submitType === "changeAdmAccountManagementBillingProfile") {
          dispatch(onChangeAdmAccountManagementBillingProfile(param));
          props.setIsOpenBillingProfile(false);
        }
      }
    },
  });

  return (
    <>
    <Modal
      size="lg"
      isOpen={props.isOpenBillingProfile}
      toggle={() => {
        props.setIsOpenBillingProfile(false);
      }}
    >
      <div className="modal-header">
        <h5
          className="modal-title mt-0"
          id="myExtraLargeModalLabel"
        >
        </h5>
        <button
          onClick={() => {
            props.setIsOpenBillingProfile(false);
          }}
          type="button"
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body">
        <Form 
          onSubmit={(e) => {
            e.preventDefault();
            filterValidation.handleSubmit();
            return false;
          }}
        >
          <Row>
            <Card style={{ marginBottom: "0px" }}>
              <CardBody>
                <Row className="mb-3">
                  <label className="col-md-4 col-form-label">
                  Billing profile *
                  </label>
                  <div className="col-md-8">
                    <Input
                      className="form-control"
                      type="text"
                      placeholder="Billing profile"
                      name="invoiceTitle"
                      onChange={filterValidation.handleChange}
                      value={filterValidation.values.invoiceTitle}
                      invalid={
                        filterValidation.touched.invoiceTitle && filterValidation.errors.invoiceTitle ? true : false
                      }
                    />
                    {filterValidation.touched.invoiceTitle && filterValidation.errors.invoiceTitle ? (
                      <FormFeedback type="invalid">{filterValidation.errors.invoiceTitle}</FormFeedback>
                    ) : null}
                  </div>
                </Row>
                <Row className="mb-3">
                  <label className="col-md-4 col-form-label">
                    Invoicing first name
                  </label>
                  <div className="col-md-8">
                    <Input
                      className="form-control"
                      type="text"
                      placeholder="Invoicing first name"
                      name="invoiceFirstName"
                      onChange={filterValidation.handleChange}
                      value={filterValidation.values.invoiceFirstName}
                      invalid={
                        filterValidation.touched.invoiceFirstName && filterValidation.errors.invoiceFirstName ? true : false
                      }
                    />
                    {filterValidation.touched.invoiceFirstName && filterValidation.errors.invoiceFirstName ? (
                      <FormFeedback type="invalid">{filterValidation.errors.invoiceFirstName}</FormFeedback>
                    ) : null}
                  </div>
                </Row>
                <Row className="mb-3">
                  <label className="col-md-4 col-form-label">
                    Invoicing last name
                  </label>
                  <div className="col-md-8">
                    <Input
                      className="form-control"
                      type="text"
                      placeholder="Invoicing last name"
                      name="invoiceLastName"
                      onChange={filterValidation.handleChange}
                      value={filterValidation.values.invoiceLastName}
                      invalid={
                        filterValidation.touched.invoiceLastName && filterValidation.errors.invoiceLastName ? true : false
                      }
                    />
                    {filterValidation.touched.invoiceLastName && filterValidation.errors.invoiceLastName ? (
                      <FormFeedback type="invalid">{filterValidation.errors.invoiceLastName}</FormFeedback>
                    ) : null}
                  </div>
                </Row>
                <Row className="mb-3">
                  <label className="col-md-4 col-form-label">
                    Invoicing company name *
                  </label>
                  <div className="col-md-8">
                    <Input
                      className="form-control"
                      type="text"
                      placeholder="Invoicing company name"
                      name="invoiceCompanyName"
                      onChange={filterValidation.handleChange}
                      value={filterValidation.values.invoiceCompanyName}
                      invalid={
                        filterValidation.touched.invoiceCompanyName && filterValidation.errors.invoiceCompanyName ? true : false
                      }
                    />
                    {filterValidation.touched.invoiceCompanyName && filterValidation.errors.invoiceCompanyName ? (
                      <FormFeedback type="invalid">{filterValidation.errors.invoiceCompanyName}</FormFeedback>
                    ) : null}
                  </div>
                </Row>
                <Row className="mb-3">
                  <label className="col-md-4 col-form-label">
                    Invoicing address *
                  </label>
                  <div className="col-md-8">
                    <textarea
                      className={filterValidation.touched.invoiceAddress && filterValidation.errors.invoiceAddress ? "form-control is-invalid" : "form-control"}
                      rows="3"
                      placeholder="Invoicing address..."
                      name="invoiceAddress"
                      onChange={filterValidation.handleChange}
                      value={filterValidation.values.invoiceAddress}
                    />
                    {filterValidation.touched.invoiceAddress && filterValidation.errors.invoiceAddress ? (
                      <FormFeedback type="invalid">{filterValidation.errors.invoiceAddress}</FormFeedback>
                    ) : null}
                  </div>
                </Row>
                <Row className="mb-3">
                  <label className="col-md-4 col-form-label">
                    Accounting email *
                  </label>
                  <div className="col-md-8">
                    <Input
                      type="email"
                      placeholder="Accounting email"
                      className="form-control"
                      name="invoiceEmail"
                      onChange={filterValidation.handleChange}
                      value={filterValidation.values.invoiceEmail}
                      invalid={
                        filterValidation.touched.invoiceEmail && filterValidation.errors.invoiceEmail ? true : false
                      }
                    />
                    {filterValidation.touched.invoiceEmail && filterValidation.errors.invoiceEmail ? (
                      <FormFeedback type="invalid">{filterValidation.errors.invoiceEmail}</FormFeedback>
                    ) : null}
                  </div>
                </Row>
                <Row className="mb-3">
                  <label className="col-md-4 col-form-label">
                    Tax ID
                  </label>
                  <div className="col-md-8">
                    <input
                      className="form-control"
                      type="text"
                      placeholder="Tax ID"
                      name="taxId"
                      onChange={filterValidation.handleChange}
                      value={filterValidation.values.taxId}
                    />
                  </div>
                </Row>
                <Row>
                  <label className="col-md-4 col-form-label">
                    Important info
                  </label>
                  <div className="col-md-8">
                    <input
                      className="form-control"
                      type="textarea"
                      rows="3"
                      placeholder="Important info..."
                      name="invoiceInfo"
                      onChange={filterValidation.handleChange}
                      value={filterValidation.values.invoiceInfo}
                    />
                  </div>
                </Row>
              </CardBody>
            </Card>
          </Row>
        </Form>
      </div>
      <div className="modal-footer">
        <Button
          type="button"
          color="primary"
          className="w-md"
          onClick={()=>{
            filterValidation.handleSubmit(filterValidation.values);
          }}
        >
          Save
        </Button>
        <Button
          type="button"
          color="danger"
          outline
          className="w-md"
          onClick={()=>{
            props.setIsOpenBillingProfile(false);
          }}
        >
          Cancel
        </Button>
      </div>
    </Modal>

    {errorMsg !== '' ? (
      <SweetAlert
        onConfirm={() => {
          setErrorMsg('');
        }}
        onCancel={() => {
          setErrorMsg('');
        }}
      >{errorMsg}
      </SweetAlert>
      ) 
    : null}
    </>
  )
})

export default CreateBillingProfile;