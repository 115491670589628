import React, {useState, useEffect, useRef, useCallback} from 'react';
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";
import {
    Table,
    Row,
    Col,
    Card,
    CardBody,
    CardTitle,
    Media,
    Collapse,
    Form,
    Input,
    FormGroup,
    Button,
    FormFeedback,
  } from "reactstrap"
import LoadingModal from "../../components/Common/LoadingModal"
import SelectAllJurisdictions from '../../components/Filter/SelectAllJurisdictions';
import PeriodBtn from '../../components/Filter/PeriodBtn';
import { Link } from "react-router-dom";
import * as Yup from "yup";
import { useFormik } from "formik";

import {
  getCountryRankingsList as onGetCountryRankingsList,
  downCountryRankingsExcel as onDownCountryRankingsExcel,
  downCountryRankingsPdf as onDownCountryRankingsPdf,
  clearCountryRankingsList as onClearCountryRankingsList
} from "../../store/actions";
import { cloneDeep } from 'lodash';
import dateFormat from 'dateformat';

const CountryRankings = props => {
  const dispatch = useDispatch();

  const { countryRankingsList, loading, noticeStr } = useSelector(state => ({
    countryRankingsList: state.CountryRankings.countryRankingsList,
    loading: state.CountryRankings.loading,
    noticeStr: state.CountryRankings.noticeStr,
  }));

  const [isOpen, setIsOpen] = useState(true);

  /*
  useEffect(()=>{
    return dispatch(onClearCountryRankingsList());
  },[]);
  */
 
  const toggle = () => {
    setIsOpen(!isOpen);
  }

  // events validation
  const filterValidation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    //enableReinitialize: true,

    initialValues: {
      // inOutType: "IN",
      submitType: "view",
      jurType: "",
      jrCdList: [],
      datePeriod: "",
      fromDate: undefined,
      toDate: undefined,
      rdoRiHi: "RO",
      entryCnt: "10",
    },
    validationSchema: Yup.object().shape({
      fromDate: Yup.date()
        .max(Yup.ref('toDate'), "From date must be earlier than to date")
        .required("Please Select From date"),
      toDate: Yup.date()
        .max(new Date(), "Date cannot be in the future")
        .required("Please Select to date"),
    }),
    validateOnChange: false, // this one
    validateOnBlur: false, // and this one
    onSubmit: (values) => {
      //console.log(JSON.stringify(values, null, 2));
      
      let _fromDateStr = values["fromDate"].replace(/-/gi,"/");
      let _toDateStr = values["toDate"].replace(/-/gi,"/");
      let newEndDate = new Date(values["toDate"]);
      newEndDate.setDate(newEndDate.getDate() + 1);
      
      let period = Math.abs(newEndDate.getTime() - new Date(values["fromDate"]).getTime());
      period = Math.ceil(period / (1000 * 3600 * 24));
      
      let _periodstr = period +" day(s)";
      let _noticeStr = "Country Rankings for period: "+_periodstr+" ["+_fromDateStr+" to "+_toDateStr+"]";
      let _timePeriod = _fromDateStr+" to "+_toDateStr+" ["+period +" day(s)]";
      const filterParam = {
        jurType: values["jurType"],
        jrCdList: values["jurType"] === "All"? [] : values["jrCdList"],
        startDate: values["fromDate"],
        endDate: values["toDate"],
        rdoRiHi: values["rdoRiHi"],
        entryCnt: values["entryCnt"],
        noticeStr: _noticeStr,
        timePeriod: _timePeriod,
      };

      let fileName = dateFormat(new Date(), "yymmdd") + " WeCrest Country Rankings (" + values["fromDate"].replace(/-/gi,"") + "-" + values["toDate"].replace(/-/gi,"") + ")"; 

      let submitType = values["submitType"];
      
      if(submitType == "view"){
        dispatch(onGetCountryRankingsList(filterParam));
        
      }else if(submitType == "excel"){
        dispatch(onDownCountryRankingsExcel(filterParam, fileName));

      }else if(submitType == "pdf"){
        dispatch(onDownCountryRankingsPdf(filterParam, fileName));
      }
      
    },
  });

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Card>
            <Link onClick={toggle} className="text-dark" to="#">
              <div className="p-4">
                <Media className="d-flex align-items-center">
                  <div className="flex-shrink-0 me-3">
                    <i className="uil uil-globe text-primary h2"></i>
                  </div>
                  <div className="flex-grow-1 overflow-hidden">
                    <h5 className="font-size-16 mb-1">Country Rankings</h5>
                    <p className="text-muted mb-0">Use this tool to generate rankings of countries of origin of international<br/>
                      (Madrid) trademark applications that have been provisionally refused in your jurisdictions.</p>
                  </div>
                  <i className="uil uil-filter font-size-24 text-muted"></i>
                  {/* 
                  <i className={isOpen ? "mdi mdi-chevron-up accor-down-icon font-size-24" : "mdi mdi-chevron-down accor-down-icon font-size-24"}></i>
                  */}
                </Media>
              </div>
            </Link>
            <Collapse isOpen={isOpen}>
              <div className="p-4 border-top">
                <Form onSubmit={filterValidation.handleSubmit}>
                  <div>
                    <SelectAllJurisdictions
                      formik={filterValidation}
                    />

                    <Row className="mb-3">
                      <div className="col-md-3 form-check" style={{paddingLeft : '2em'}}>
                        <input
                          className="form-check-input"
                          type="radio"
                          name="rdoRiHi"
                          id="repOnly"
                          onChange={filterValidation.handleChange}
                          value="RO"
                          defaultChecked
                        />
                        <label
                          className="form-check-label"
                          htmlFor="repOnly"
                        >
                          Representatives only
                        </label>
                      </div>
                      <div className="col-md-3 form-check" style={{paddingLeft : '2em'}}>
                        <input
                          className="form-check-input"
                          type="radio"
                          name="rdoRiHi"
                          id="holderOnly"
                          onChange={filterValidation.handleChange}
                          value="HO"
                        />
                        <label
                          className="form-check-label"
                          htmlFor="holderOnly"
                        >
                          Holders only
                        </label>
                      </div>
                    </Row>
                    <Row>
                      <label htmlFor="inscriptionDate" className="col-md-3 col-form-label">
                      Inscription date
                      </label>
                      <div className="col-md-9">
                        <PeriodBtn
                          formik={filterValidation}
                        />
                      </div>
                    </Row>
                    <Row className="mb-3">
                      <label
                        htmlFor="entryCnt"
                        className="col-md-3 col-form-label"
                        >
                        Number of entries to include
                      </label>
                      <div className="col-md-2">
                        <select 
                          className="form-select" 
                          name="entryCnt"
                          onChange={filterValidation.handleChange}
                        >
                          <option value="10">10</option>
                          <option value="20">20</option>
                          <option value="30">30</option>
                          <option value="40">40</option>
                          <option value="50">50</option>
                          <option value="60">60</option>
                          <option value="70">70</option>
                          <option value="80">80</option>
                          <option value="90">90</option>
                          <option value="100">100</option>
                          <option value="150">150</option>
                          <option value="200">200</option>
                          <option value="250">250</option>
                        </select>
                      </div>
                    </Row>
                    <Row className="mb-3">
                      <span className="col-md-6 button-items">
                        <Button
                          type="button"
                          color="primary"
                          outline
                          className="waves-effect waves-light"
                          onClick={()=>{
                            let _values = cloneDeep(filterValidation.values);
                            _values.submitType = "excel";
                            filterValidation.setValues(_values);
                            filterValidation.handleSubmit(filterValidation.values);
                          }}
                        >
                          Download Excel
                        </Button>
                        <Button
                          type="button"
                          color="primary"
                          outline
                          className="waves-effect waves-light"
                          onClick={()=>{
                            let _values = cloneDeep(filterValidation.values);
                            _values.submitType = "pdf";
                            filterValidation.setValues(_values);
                            filterValidation.handleSubmit(filterValidation.values);
                          }}
                        >
                          Download PDF
                        </Button>
                      </span>
                      <Col md={3} style={{paddingLeft : '1.3em'}}>
                        <button
                          type="button"
                          className="waves-effect waves-light btn btn-primary"
                          style={{width : '148.58px'}}
                          onClick={()=>{
                            let _values = cloneDeep(filterValidation.values);
                            _values.submitType = "view";
                            filterValidation.setValues(_values);
                            filterValidation.handleSubmit(filterValidation.values);
                          }}
                        >
                          View ranking
                        </button>
                      </Col>
                    </Row>
                  </div>
                </Form>
              </div>
            </Collapse>
          </Card>
        {
          loading ?
          <LoadingModal loading={loading}></LoadingModal>
          : null
        }
        {countryRankingsList && countryRankingsList.length ?
          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                    <CardTitle className="text-muted mb-0">
                      <p style={{whiteSpace : "pre"}}>
                        {noticeStr}
                      </p>
                    </CardTitle>
                    <div className="table-responsive">
                      <Table className="table mb-0">
                        <thead>
                          <tr>
                          <th>Rank</th>
                          <th>Number of provisional refusals</th>
                          <th>Country</th>
                          <th>Type</th>
                          </tr>
                        </thead>
                        <tbody>
                          {
                              countryRankingsList.map((ranking, index) => {
                                return (
                                    <tr key={index}>
                                      <td>{ranking.rnk}</td>
                                      <td>{ranking.refusalCnt}</td>
                                      <td>{ranking.country}</td>
                                      <td>{ranking.type}</td>
                                    </tr>
                                )})
                          }
                        </tbody>
                      </Table>
                    </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        :null}
        </div>
      </div>
    </React.Fragment>
  )
}

/*
UserList.propTypes = {
  users: PropTypes.array,
  loading: PropTypes.bool,
  onGetUserList: PropTypes.func,
};
*/

export default CountryRankings;