import React, {useState, useEffect, useRef, useCallback} from 'react';
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";
import {
    Table,
    Row,
    Col,
    Card,
    CardBody,
    CardTitle,
    Media,
    Collapse,
    Form,
    Input,
    FormGroup,
    Button,
    FormFeedback,
  } from "reactstrap"
import LoadingModal from "../../components/Common/LoadingModal"
import SelectAllJurisdictions from '../../components/Filter/SelectAllJurisdictions';
import PeriodBtn from '../../components/Filter/PeriodBtn';
import { Link } from "react-router-dom";
import * as Yup from "yup";
import { useFormik } from "formik";

import {
  getGeneralStatisticsList as onGetGeneralStatisticsList,
  downStatisticsExcel as onDownStatisticsExcel,
  clearGeneralStatisticsList as onClearGeneralStatisticsList,
  downStatisticsPdf as onDownStatisticsPdf,
  setIsWarSearch as onSetIsWarSearch,
} from "../../store/actions";
import { cloneDeep } from 'lodash';
import dateFormat from 'dateformat';

const GeneralStatistics = props => {
  const dispatch = useDispatch();
  const params = new URLSearchParams(window.location.search);

  const { generalStatisticsList, loading, noticeStr, isWarGsSearch } = useSelector(state => ({
    generalStatisticsList: state.GeneralStatistics.generalStatisticsList,
    loading: state.GeneralStatistics.loading,
    noticeStr: state.GeneralStatistics.noticeStr,
    isWarGsSearch: state.GeneralStatistics.isWarGsSearch,
  }));

  const [isOpen, setIsOpen] = useState(true);

  const toggle = () => {
    setIsOpen(!isOpen);
  }

  // events validation
  const filterValidation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    //enableReinitialize: true,

    initialValues: {
      inOutType: "OUT",
      submitType: "view",
      jrCdList: [],
      datePeriod: "",
      fromDate: undefined,
      toDate: undefined,
    },
    validationSchema: Yup.object().shape({
      fromDate: Yup.date()
        .max(Yup.ref('toDate'), "From date must be earlier than to date")
        .required("Please Select From date"),
      toDate: Yup.date()
        .max(new Date(), "Date cannot be in the future")
        .required("Please Select to date"),
    }),
    validateOnChange: false, // this one
    validateOnBlur: false, // and this one
    onSubmit: (values) => {
      //console.log(JSON.stringify(values, null, 2));
      
      let _fromDateStr = values["fromDate"].replace(/-/gi,"/");
      let _toDateStr = values["toDate"].replace(/-/gi,"/");
      let newEndDate = new Date(values["toDate"]);
      newEndDate.setDate(newEndDate.getDate() + 1);
      
      let period = Math.abs(newEndDate.getTime() - new Date(values["fromDate"]).getTime());
      period = Math.ceil(period / (1000 * 3600 * 24));
      
      let _periodstr = period +" day(s)";
      let _noticeStr = "General Statistics for period: "+_periodstr+" ["+_fromDateStr+" to "+_toDateStr+"]";
      let _timePeriod = _fromDateStr+" to "+_toDateStr+" ["+period +" day(s)]";

      const filterParam = {
        jrCdList: values["jrCdList"],
        fromDate: values["fromDate"],
        toDate: values["toDate"],
        noticeStr: _noticeStr,
        timePeriod: _timePeriod,
      };
      
      let fileName = dateFormat(new Date(), "yymmdd") + " WeCrest General Statistics (" + values["fromDate"].replace(/-/gi,"") + "-" + values["toDate"].replace(/-/gi,"") + ")"; 

      let submitType = values["submitType"];
      
      if(submitType == "view"){
        dispatch(onGetGeneralStatisticsList(filterParam));
        
      }else if(submitType == "excel"){
        dispatch(onDownStatisticsExcel(filterParam, fileName));

      }else if(submitType == "pdf"){
        dispatch(onDownStatisticsPdf(filterParam, fileName));
      }
      
    },
  });

  useEffect(() => {
    if (isWarGsSearch && filterValidation.values.fromDate != '') {
      // console.log('WAR Search >> ');
      dispatch(onSetIsWarSearch(false));

      filterValidation.setFieldValue("submitType", "view");
      filterValidation.handleSubmit(filterValidation.values); 
    }
  },[filterValidation.values.fromDate])

  useEffect(() => { 
    if (params.get('warFromDate') && params.get('warToDate')) {
      // console.log('warFromDate : ' + params.get('warFromDate') + ', warToDate : ' + params.get('warToDate'));

      dispatch(onSetIsWarSearch(true));
      filterValidation.setFieldValue("fromDate", params.get('warFromDate').substring(0,4) + '-' + params.get('warFromDate').substring(4,6) + '-' + params.get('warFromDate').substring(6,8));
      filterValidation.setFieldValue("toDate", params.get('warToDate').substring(0,4) + '-' + params.get('warToDate').substring(4,6) + '-' + params.get('warToDate').substring(6,8));
    }
  }, []);

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Card>
            <Link onClick={toggle} className="text-dark" to="#">
              <div className="p-4">
                <Media className="d-flex align-items-center">
                  <div className="flex-shrink-0 me-3">
                    <i className="uil uil-comparison text-primary h2"></i>
                  </div>
                  <div className="flex-grow-1 overflow-hidden">
                    <h5 className="font-size-16 mb-1">General Statistics</h5>
                    <p className="text-muted mb-0">Use this tool to generate statistics of the number of provisionally refused international<br/>
                      (Madrid) trademark applications per jurisdiction in a given period of time.</p>
                  </div>
                  <i className="uil uil-filter font-size-24 text-muted"></i>
                  {/* 
                  <i className={isOpen ? "mdi mdi-chevron-up accor-down-icon font-size-24" : "mdi mdi-chevron-down accor-down-icon font-size-24"}></i>
                  */}
                </Media>
              </div>
            </Link>
            <Collapse isOpen={isOpen}>
              <div className="p-4 border-top">
                <Form onSubmit={filterValidation.handleSubmit}>
                  <div>
                    <SelectAllJurisdictions
                      formik={filterValidation}
                    />
                    <Row>
                      <label htmlFor="inscriptionDate" className="col-md-3 col-form-label">
                      Inscription date
                      </label>
                      <div className="col-md-9">
                        <PeriodBtn
                          formik={filterValidation}
                        />
                      </div>
                    </Row>
                    <Row className="mb-3">
                      <span className="col-md-3 button-items">
                        <Button
                          type="button"
                          color="primary"
                          outline
                          className="waves-effect waves-light"
                          onClick={()=>{
                            let _values = cloneDeep(filterValidation.values);
                            _values.submitType = "excel";
                            filterValidation.setValues(_values);
                            filterValidation.handleSubmit(filterValidation.values);
                          }}
                        >
                          Download Excel
                        </Button>
                        <Button
                          type="button"
                          color="primary"
                          outline
                          className="waves-effect waves-light"
                          onClick={()=>{
                            let _values = cloneDeep(filterValidation.values);
                            _values.submitType = "pdf";
                            filterValidation.setValues(_values);
                            filterValidation.handleSubmit(filterValidation.values);
                          }}
                        >
                          Download PDF
                        </Button>
                      </span>
                      <Col md={3}>&nbsp;</Col>
                      <Col md={3}>
                        <button
                          type="button"
                          className="waves-effect waves-light btn btn-primary"
                          style={{width : '148.58px'}}
                          onClick={()=>{
                            let _values = cloneDeep(filterValidation.values);
                            _values.submitType = "view";
                            filterValidation.setValues(_values);
                            filterValidation.handleSubmit(filterValidation.values);
                          }}
                        >
                          View statistics
                        </button>
                      </Col>
                    </Row>
                  </div>
                </Form>
              </div>
            </Collapse>
          </Card>
        {
          loading ?
          <LoadingModal loading={loading}></LoadingModal>
        : null
        }
        {generalStatisticsList && generalStatisticsList.length ?
          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  <CardTitle className="text-muted mb-0">
                    <p style={{whiteSpace : "pre"}}>
                      {noticeStr}
                    </p>
                  </CardTitle>
                  <div className="table-responsive">
                    <Table className="table mb-0">
                      <thead>
                        <tr>
                        <th>Jurisdiction</th>
                        <th>Total for period</th>
                        <th>Daily average</th>
                        <th>Weekly average</th>
                        <th>Monthly average</th>
                        <th>3-month average</th>
                        <th>6-month average</th>
                        <th>1-year average</th>
                        </tr>
                      </thead>
                      <tbody>
                        {
                          generalStatisticsList.map((statistics, index) => {
                            return (
                                <tr key={index}>
                                  <td>{statistics.crCd} {statistics.crNm}</td>
                                  <td>{statistics.total}</td>
                                  <td>{statistics.per1D}</td>
                                  <td>{statistics.per1W}</td>
                                  <td>{statistics.per1M}</td>
                                  <td>{statistics.per3M}</td>
                                  <td>{statistics.per6M}</td>
                                  <td>{statistics.per1Y}</td>
                                </tr>
                            )})
                        }
                      </tbody>
                    </Table>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        : null }
        </div>
      </div>
    </React.Fragment>
  )
}

/*
UserList.propTypes = {
  users: PropTypes.array,
  loading: PropTypes.bool,
  onGetUserList: PropTypes.func,
};
*/

export default GeneralStatistics;