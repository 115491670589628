import React, {useState, useEffect, useRef, forwardRef, useImperativeHandle} from 'react';
import Dropzone from "react-dropzone"
import { useSelector, useDispatch } from "react-redux";
import { Editor } from '@tinymce/tinymce-react';
import LoadingModal from "./LoadingModal"
import SweetAlert from "react-bootstrap-sweetalert";
import {
    Card,
    Media,
    Collapse,
    Table,
    Row,
    Col,
    Progress,
} from "reactstrap"
  
import { Link } from "react-router-dom"
import {
    getEmailTemplates,
    getEmailForm,
    updateEmailForm,
    setReducerValue,
} from "../../store/actions";

import './EditorModal.css';

import { cloneDeep } from 'lodash';

const EditorModal = forwardRef((props, ref) => {
    const dispatch = useDispatch();

    const { emailTemplates, menuItems, tmpTitle, emailSubject, emailCcItems, emailCcError, emailBccItems, emailBccError, emailForm, attachFiles, copyFiles, upFiles, isUpload, progressColor, uploadRate, loadingEditor, updateFlag, errorMsg } = useSelector(state => ({
        emailTemplates: state.Editor.emailTemplates,
        menuItems: state.Editor.menuItems,
        tmpTitle: state.Editor.tmpTitle,
        emailSubject: state.Editor.emailSubject,
        emailCcItems: state.Editor.emailCcItems,
        emailCcError: state.Editor.emailCcError,
        emailBccItems: state.Editor.emailBccItems,
        emailBccError: state.Editor.emailBccError,
        emailForm: state.Editor.emailForm,
        attachFiles: state.Editor.attachFiles,
        copyFiles: state.Editor.copyFiles,
        upFiles: state.Editor.upFiles,
        isUpload: state.Editor.isUpload,
        progressColor: state.Editor.progressColor,
        uploadRate: state.Editor.uploadRate,
        loadingEditor: state.Editor.loadingEditor,
        updateFlag: state.Editor.updateFlag,
        errorMsg: state.Editor.errorMsg,
    }));

    const [emailCc, setEmailCc] = useState('');
    const [emailBcc, setEmailBcc] = useState('');
    const [isOpenPlaceHolder, setIsOpenPlaceHolder] = useState(false);
    const [unsupportedFiles, setUnsupportedFiles] = useState([]);
    const [alertMessage, setAlertMessage] = useState('');

    const togglePlaceHolder = () => {
        setIsOpenPlaceHolder(!isOpenPlaceHolder);
    }

    // [S] cc email
    const emailCcKeyDown = evt => {
        // console.log('emailCcKeyDown : ' + evt.key);
        if (["Enter", "Tab", ",", ";", " "].includes(evt.key)) {
            evt.preventDefault();

            var value = emailCc.trim();

            if (value && isValidEmail(value, 'CC')) {
                if (emailCcItems.length === 10) {
                    dispatch(setReducerValue('EMAIL_CC_ERROR', 'You can only add at most 10 email addresses in the cc field'));
                } else {
                    dispatch(setReducerValue('EMAIL_CC_ITEMS', [...emailCcItems, value]));
                    setEmailCc('');
                    dispatch(setReducerValue('EMAIL_CC_ERROR', ''));
                }
            }
        }
    };

    const emailFocusOut = () => {
        // console.log('emailFocusOut : ');

        var value = emailCc.trim();

        if (value && isValidEmail(value, 'CC')) {
            if (emailCcItems.length === 10) {
                dispatch(setReducerValue('EMAIL_CC_ERROR', 'You can only add at most 10 email addresses in the cc field'));
            } else {
                dispatch(setReducerValue('EMAIL_CC_ITEMS', [...emailCcItems, value]));
                setEmailCc('');
                dispatch(setReducerValue('EMAIL_CC_ERROR', ''));
            }
        }
    };
    
    const emailCcDelete = item => {
        const index = emailCcItems.findIndex(e => e === item);
        emailCcItems.splice(index, 1);
        dispatch(setReducerValue('EMAIL_CC_ITEMS', [...emailCcItems]));
    };
    
    const emailCcPaste = evt => {
        evt.preventDefault();

        let paste = evt.clipboardData.getData("text");
        // let emails = paste.match(/[\w\d\.-]+@[\w\d\.-]+\.[\w\d\.-]+/g);
        let emails = paste.match(/^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*\.[a-zA-Z]{2,15}$/i);

        if (emails) {
            // console.log('emailCcPaste : ' + emails);
            let toBeAdded = emails.filter(email => !isInList(email, 'CC') && isEmail(email));
            let isInListEmails = emails.filter(email => isInList(email, 'CC'));

            if (toBeAdded.length > 0) {
                if (toBeAdded.length + emailCcItems.length > 10) {
                    if (emailCcItems.length < 10) {
                        for (let index=toBeAdded.length-1; index > 10-emailCcItems.length-1; index--) {
                            // console.log('remove add array index : ' + index + ', toBeAdded : ' + toBeAdded[index]);
                            toBeAdded.splice(index,1);
                        }
                        dispatch(setReducerValue('EMAIL_CC_ITEMS', [...emailCcItems.concat(toBeAdded)]));
                    }
                    dispatch(setReducerValue('EMAIL_CC_ERROR', 'You can only add at most 10 email addresses in the cc field'));
                } else {
                    dispatch(setReducerValue('EMAIL_CC_ITEMS', [...emailCcItems.concat(toBeAdded)]));
                    dispatch(setReducerValue('EMAIL_CC_ERROR', ''));
                }
            }

            if (isInListEmails.length > 0) {
                dispatch(setReducerValue('EMAIL_CC_ERROR', `${isInListEmails} has already been adde.`));
            }
            
            setEmailCc('');
        }
    };
    // [E] cc email

    // [S] bcc email
    const emailBccKeyDown = evt => {
        // console.log('emailBccKeyDown : ' + evt.key);
        if (["Enter", "Tab", ",", ";", " "].includes(evt.key)) {
            evt.preventDefault();

            var value = emailBcc.trim();

            if (value && isValidEmail(value, 'BCC')) {
                if (emailBccItems.length === 10) {
                    dispatch(setReducerValue('EMAIL_BCC_ERROR', 'You can only add at most 10 email addresses in the bcc field'));
                } else {
                    dispatch(setReducerValue('EMAIL_BCC_ITEMS', [...emailBccItems, value]));
                    setEmailBcc('');
                    dispatch(setReducerValue('EMAIL_BCC_ERROR', ''));
                }
            }
        }
    };

    const emailBccFocusOut = () => {
        // console.log('emailBccFocusOut : ');

        var value = emailBcc.trim();

        if (value && isValidEmail(value, 'BCC')) {
            if (emailBccItems.length === 10) {
                dispatch(setReducerValue('EMAIL_BCC_ERROR', 'You can only add at most 10 email addresses in the bcc field'));
            } else {
                dispatch(setReducerValue('EMAIL_BCC_ITEMS', [...emailBccItems, value]));
                setEmailBcc('');
                dispatch(setReducerValue('EMAIL_BCC_ERROR', ''));
            }
        }
    };
    
    const emailBccDelete = item => {
        const index = emailBccItems.findIndex(e => e === item);
        emailBccItems.splice(index, 1);
        dispatch(setReducerValue('EMAIL_BCC_ITEMS', [...emailBccItems]));
    };
    
    const emailBccPaste = evt => {
        evt.preventDefault();

        let paste = evt.clipboardData.getData("text");
        // let emails = paste.match(/[\w\d\.-]+@[\w\d\.-]+\.[\w\d\.-]+/g);
        let emails = paste.match(/^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*\.[a-zA-Z]{2,15}$/i);

        if (emails) {
            // console.log('emailCcPaste : ' + emails);
            let toBeAdded = emails.filter(email => !isInList(email, 'BCC') && isEmail(email));
            let isInListEmails = emails.filter(email => isInList(email, 'BCC'));

            if (toBeAdded.length > 0) {
                if (toBeAdded.length + emailBccItems.length > 10) {
                    if (emailBccItems.length < 10) {
                        for (let index=toBeAdded.length-1; index > 10-emailBccItems.length-1; index--) {
                            // console.log('remove add array index : ' + index + ', toBeAdded : ' + toBeAdded[index]);
                            toBeAdded.splice(index,1);
                        }
                        dispatch(setReducerValue('EMAIL_BCC_ITEMS', [...emailBccItems.concat(toBeAdded)]));
                    }
                    dispatch(setReducerValue('EMAIL_BCC_ERROR', 'You can only add at most 10 email addresses in the bcc field'));
                } else {
                    dispatch(setReducerValue('EMAIL_BCC_ITEMS', [...emailBccItems.concat(toBeAdded)]));
                    dispatch(setReducerValue('EMAIL_BCC_ERROR', ''));
                }
            }

            if (isInListEmails.length > 0) {
                dispatch(setReducerValue('EMAIL_BCC_ERROR', `${isInListEmails} has already been adde.`));
            }
            
            setEmailBcc('');
        }
    };
    // [E] cc email
    
    const isValidEmail = (email, field) => {
        let error = null;

        if (isInList(email, field)) {
            error = `${email} has already been added.`;
        }

        if (!isEmail(email)) {
            error = `${email} is not a valid email address.`;
        }

        if (error) {
            if (field === 'CC') {
                dispatch(setReducerValue('EMAIL_CC_ERROR', error));
            } else {
                dispatch(setReducerValue('EMAIL_BCC_ERROR', error));
            }
            
            return false;
        }

        return true;
    }
    
    const isInList = (email, field) => {
        // console.log('emailCcItems : ' + emailCcItems + ', email : ' + email + ", isInList : " + emailCcItems.includes(email));

        if (field === 'CC') {
            return emailCcItems.includes(email);
        } else {
            return emailBccItems.includes(email);
        }
    }
    
    const isEmail = (email) => {
        // return /[\w\d\.-]+@[\w\d\.-]+\.[\w\d\.-]+/.test(email);

        return /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*\.[a-zA-Z]{2,15}$/i.test(email);
    }
    
    // [S] attach File
    const [totalAttachFileSize, setTotalAttachFileSize] = useState("0");
    //등록가능한 총 파일 사이즈 MB    
    var maxUploadSize = 5;

    const getAttachFileSize = () => {
        let attachFileSize = 0;
        if (attachFiles&&attachFiles.length>0) {
            attachFiles.map((f, i) => {
                attachFileSize += f.fileSize
            })
        }

        if (copyFiles&&copyFiles.length>0) {
            copyFiles.map((f, i) => {
                attachFileSize += f.fileSize
            })
        }

        if (upFiles&&upFiles.length>0) {
            upFiles.map((f, i) => {
                attachFileSize += f.size
            })
        }

        return attachFileSize;
    }
	
    const handleAcceptedFiles = (files) => {
        let attachFileSize = getAttachFileSize();

        if (attachFileSize/1024/1024 > maxUploadSize) {
            setAlertMessage("The attachment exceeds the maximum attachment size of " + maxUploadSize + "MB.");
            return false;
        } 

        files.map(file => {
            attachFileSize += file.size;
        })

        console.log('handleAcceptedFiles attachFileSize : '  + attachFileSize); 

        if (attachFileSize/1024/1024 > maxUploadSize) {
            setAlertMessage("Maximum total attachment size is " + maxUploadSize + "MB.");
            return false;
        } 

        setTotalAttachFileSize(formatBytes(attachFileSize));

        let _upFiles = cloneDeep(upFiles);

        files.map(file => {
            let _file = Object.assign(file, {
                preview: URL.createObjectURL(file),
                formattedSize: formatBytes(file.size),
                isUpload: false,
                uploadRate: 0,
                isImage: isImageType(file),
                isValid: validateFile(file),
                progressColor : "primary",
                errorMessage : "File type not permitted",
            })

            _upFiles.push(_file);
        })

        dispatch(setReducerValue('UP_FILES', _upFiles))

        for(let i = 0; i < files.length; i++) {
            if (!validateFile(files[i])) {
                setUnsupportedFiles(prevArray => [...prevArray, files[i]]);
            }
        }
    }

    const validateFile = (file) => {
        const validTypes = ['text/html', 'application/zip', 'text/plain', 'application/rtf', 'application/pdf', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'application/vnd.ms-excel', 'text/csv'
                        , 'image/jpeg', 'image/jpg', 'image/png', 'image/gif', 'image/svg+xml', 'image/webp', 'application/vnd.ms-powerpoint', 'application/vnd.openxmlformats-officedocument.presentationml.presentation'];

        // console.log(file.name + ' : ' + file.type + ' >> ' + validTypes.indexOf(file.type));

        if (validTypes.indexOf(file.type) === -1) {
            return false;
        }

        return true;
    }

    const isImageType = (file) => {
        const imageTypes = ['image/jpeg', 'image/jpg', 'image/png', 'image/gif', 'image/svg+xml', 'image/webp'];
        if (imageTypes.indexOf(file.type) === -1) {
            return false;
        }

        return true;
    }

    const removeAttachFile = (fileName) => {
        const index = attachFiles.findIndex(e => e.fileName === fileName);

        // console.log('name > ' + fileName)
        // console.log('index > ' + index)
        // console.log('checkName > ' + attachFiles[index]);

        // console.log('attachFiles 1 > ' + attachFiles)

        attachFiles.splice(index, 1);

        let attachFileSize = getAttachFileSize();
        
        console.log('removeAttachFile attachFileSize : '  + attachFileSize); 

        setTotalAttachFileSize(formatBytes(attachFileSize));

        // console.log('attachFiles 2 > ' + attachFiles)
        dispatch(setReducerValue('ATTACH_FILES', [...attachFiles]));
    }

    const removeCopyFile = (fileName) => {
        const index = copyFiles.findIndex(e => e.fileName === fileName);
        copyFiles.splice(index, 1);

        let attachFileSize = getAttachFileSize();
        
        console.log('removeCopyFile attachFileSize : '  + attachFileSize); 

        setTotalAttachFileSize(formatBytes(attachFileSize));


        dispatch(setReducerValue('COPY_FILES', [...copyFiles]));
    }

    const removeUpFile = (name) => {
        const index1 = upFiles.findIndex(e => e.name === name);
        const index2 = unsupportedFiles.findIndex(e => e.name === name);
        upFiles.splice(index1, 1);

        let attachFileSize = getAttachFileSize();
        
        console.log('removeUpFile attachFileSize : '  + attachFileSize); 

        setTotalAttachFileSize(formatBytes(attachFileSize));

        dispatch(setReducerValue('UP_FILES', [...upFiles]));
        if (index2 !== -1) {
            unsupportedFiles.splice(index2, 1);
            setUnsupportedFiles([...unsupportedFiles]);
        }
    }

    /**
     * Formats the size
     */
    function formatBytes(bytes, decimals = 2) {
        if (bytes === 0) return "0 Bytes"
        const k = 1024
        const dm = decimals < 0 ? 0 : decimals
        const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]

        const i = Math.floor(Math.log(bytes) / Math.log(k))
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
    }
    // [E] attach File

    const editorRef = useRef(null);

    const saveEmail = () => {
        if (props.emailType === 'ST') {
            if (tmpTitle == null || tmpTitle == '' || emailSubject == null || emailSubject == '') {
                setAlertMessage('Please designate a title and subject for this template.');
                return;
            }
        } else {
            if (emailSubject == null || emailSubject == '') {
                setAlertMessage('Please designate a subject for this template.');
                return;
            }
        }

        if (editorRef.current.getContent() == null || editorRef.current.getContent() == '') {
            setAlertMessage('Please designate a content for this template.');
            return;
        }

        if (props.emailType === 'ADM_INVOICE') {
            props.createAdminInvoice(editorRef.current.getContent());
        } else if (props.emailType === 'ADM_ACCOUNT') {
            props.createAdminAccount(editorRef.current.getContent());
        } else {
            const formData = new FormData();
            formData.append('emailType', props.emailType);
            if (props.idx) {
                formData.append('idx', props.idx);
            }
            if (props.irNumber) {
                formData.append('irNumber', props.irNumber);
            }
            if (props.ftpDate) {
                formData.append('ftpDate', props.ftpDate);
            }
            if (props.crCode) {
                formData.append('crCode', props.crCode);
            }
            if (props.targetGroupNo) {
                formData.append('targetGroupNo', props.targetGroupNo);
            }
            if (props.inOutType) {
                formData.append('inOutType', props.inOutType);
            }
        
            formData.append('tmpTitle', tmpTitle);
            formData.append('subject', emailSubject);
            if (emailCcItems) {
                formData.append('emailCC', emailCcItems.join(", "));
            }
            if (emailBccItems) {
                formData.append('emailBCC', emailBccItems.join(", "));
            }
            formData.append('emailForm', editorRef.current.getContent());

            attachFiles.forEach((element, index) => {
                for (const [key, value] of Object.entries(element)) {
                    if (value && value !== 'null') {
                        formData.append('attachFiles['+index+'].'+key, value);
                    }
                }              
            });

            copyFiles.forEach((element, index) => {
                for (const [key, value] of Object.entries(element)) {
                    if (value && value !== 'null') {
                        formData.append('copyFiles['+index+'].'+key, value);
                    }
                }    
            });
            
            upFiles.forEach((element, index) => {
                formData.append('upFiles['+index+']', element);
            });
            
            dispatch(updateEmailForm(formData));
        }
    }

    const clearEditorContent = () => {
        // console.log('clearEditorContent!!')
        dispatch(setReducerValue('EMAIL_FORM', ''))
        editorRef.current.setContent('', {format: 'html'})
    } 

    useImperativeHandle(ref, () => ({
        clearEditorContent,
    }))
    
    useEffect(() => {
        if (props.emailType !== 'ST') {
            dispatch(getEmailTemplates(({
                emailType: props.emailType,
                crCode: props.crCode,
                inOutType: props.inOutType,
            })));
        }
    }, []);

    useEffect(() => {
        dispatch(getEmailForm({
            emailType: props.emailType,
            idx: props.idx,
            irNumber: props.irNumber,
            ftpDate: props.ftpDate,
            crCode: props.crCode,
            targetGroupNo: props.targetGroupNo,
            inOutType: props.inOutType,
            emailCode: props.emailCode,
        }));
        setEmailCc('');
        setEmailBcc('');
    }, [props.emailType, props.idx, props.irNumber, props.ftpDate, props.crCode, props.targetGroupNo, props.inOutType, props.emailCode]);

    useEffect(() => {
        props.loadedEditor && editorRef.current.setContent(emailForm, {format: 'html'});
    }, [props.loadedEditor, emailForm]);

    useEffect(() => {
        if (updateFlag) {
            if (props.setIsInitEditor) {
                props.setIsInitEditor(false);
            }
            
            dispatch(setReducerValue('UPDATE_FLAG'));
            props.closeEditor();
            setTotalAttachFileSize(formatBytes(0));
        }
    }, [updateFlag]);

    useEffect(() => {
        let attachFileSize = 0;
        if (attachFiles&&attachFiles.length>0) {
            attachFiles.map((f, i) => {
                attachFileSize += f.fileSize
            })
        }

        if (copyFiles&&copyFiles.length>0) {
            copyFiles.map((f, i) => {
                attachFileSize += f.fileSize
            })
        }

        setTotalAttachFileSize(formatBytes(attachFileSize));
    }, [attachFiles, copyFiles]);

    return (
        <>
        <div tabindex="-1" style={props.isOpenEditor && props.isInitEditor && props.loadedEditor ? {position: "relative", zIndex: "1050", display: "block"} : {position: "relative", zIndex: "1050", display: "none"}}>
            <div className="">
                <div className="modal fade show" role="dialog" tabindex="-1" style={props.isOpenEditor && props.isInitEditor && props.loadedEditor ? {display: "block"} : {display: "none"}} id="EditorModal">
                    <div className="modal-dialog modal-xl" role="document" ref={props.editorModal} openStatus={props.isOpenEditor ? "Y" : "N"}>
                        <div className="modal-content">
                            <div className="modal-header">
                                <Row style={{width: "100%", marginLeft: "0px"}}>
                                    {props.emailType === 'ST' ?
                                    <Row className="mb-3" style={{marginLeft: "0px", paddingLeft: "0px", paddingRight: "0px"}}>
                                        <label
                                            htmlFor="example-text-input"
                                            className="col-md-1 col-form-label"
                                            >
                                            Temp title
                                        </label>
                                        <div className="col-md-11" style={{paddingRight: "0px"}}>
                                        <input
                                            className="form-control"
                                            type="text"
                                            name="tmpTitle"
                                            onChange={(e) => {dispatch(setReducerValue('TMP_TITLE', e.target.value))}}
                                            value={tmpTitle}
                                        />
                                        </div>
                                    </Row>
                                    : props.emailType === 'MN' || props.emailType === 'FR' ?
                                    <Row className="mb-3" style={{marginLeft: "0px", paddingLeft: "0px", paddingRight: "0px"}}>
                                        <label
                                            htmlFor="example-text-input"
                                            className={props.editedTemplateYn === 'Y' ? "col-md-2 col-form-label text-success" : "col-md-2 col-form-label text-warning"}
                                            >
                                            {props.editedTemplateYn === 'Y' ? "Edited template" : "Default template"}
                                        </label>
                                    </Row>
                                    : null}

                                    <Row className="mb-3" style={{marginLeft: "0px", paddingLeft: "0px", paddingRight: "0px"}}>
                                        <label
                                            htmlFor="example-text-input"
                                            className="col-md-1 col-form-label"
                                            >
                                            Subject
                                        </label>
                                        <div className="col-md-11" style={{paddingRight: "0px"}}>
                                        <input
                                            className="form-control"
                                            type="text"
                                            name="emailSubject"
                                            onChange={(e) => {dispatch(setReducerValue('SUBJECT', e.target.value))}}
                                            value={emailSubject}
                                        />
                                        </div>
                                    </Row>

                                    
                                    <Row className={emailCcError === '' ? "mb-3" : ""} style={{marginLeft: "0px", paddingLeft: "0px", paddingRight: "0px"}}>
                                        <label
                                            htmlFor="example-text-input"
                                            className="col-md-1 col-form-label"
                                            >
                                            Cc
                                        </label>
                                        <div className="col-md-11" style={{paddingRight: "0px"}}>
                                            {
                                                emailCcItems && emailCcItems.length > 0 ?
                                                emailCcItems.map(item => (
                                                    <div className="tag-item" key={item}>
                                                        {item}
                                                        <button
                                                        type="button"
                                                        className="button"
                                                        onClick={() => emailCcDelete(item)}
                                                        >
                                                        &times;
                                                        </button>
                                                    </div>
                                                ))
                                                : null
                                            }
                                            <input
                                                type="hidden"
                                                name="emailCcItems"
                                                value={emailCcItems}
                                            />
                                            <input
                                                className="form-control"
                                                type="text"
                                                value={emailCc}
                                                onKeyDown={emailCcKeyDown}
                                                onChange={(e) => {setEmailCc(e.target.value)}}
                                                onPaste={emailCcPaste}
                                                onBlur={emailFocusOut}
                                            />
                                            
                                        </div>
                                    </Row>
                                    {emailCcError !== '' && 
                                    <Row className="mb-3" style={{marginLeft: "0px", paddingLeft: "0px", paddingRight: "0px", height: "10px"}}>
                                        <label
                                            htmlFor="example-text-input"
                                            className="col-md-1 col-form-label"
                                            >
                                        </label>
                                        <p className="col-md-11 error">{emailCcError}</p>
                                    </Row>
                                    }

                                    <Row style={{marginLeft: "0px", paddingLeft: "0px", paddingRight: "0px"}}>
                                        <label
                                            htmlFor="example-text-input"
                                            className="col-md-1 col-form-label"
                                            >
                                            Bcc
                                        </label>
                                        <div className="col-md-11" style={{paddingRight: "0px"}}>
                                            {
                                                emailBccItems && emailBccItems.length > 0 ?
                                                emailBccItems.map(item => (
                                                    <div className="tag-item" key={item}>
                                                        {item}
                                                        <button
                                                        type="button"
                                                        className="button"
                                                        onClick={() => emailBccDelete(item)}
                                                        >
                                                        &times;
                                                        </button>
                                                    </div>
                                                ))
                                                : null
                                            }
                                            <input
                                                type="hidden"
                                                name="emailCcItems"
                                                value={emailBccItems}
                                            />
                                            <input
                                                className="form-control"
                                                type="text"
                                                value={emailBcc}
                                                onKeyDown={emailBccKeyDown}
                                                onChange={(e) => {setEmailBcc(e.target.value)}}
                                                onPaste={emailBccPaste}
                                                onBlur={emailBccFocusOut}
                                            />
                                            
                                        </div>
                                    </Row>
                                    {emailBccError !== '' && 
                                    <Row style={{marginLeft: "0px", paddingLeft: "0px", paddingRight: "0px", height: "10px"}}>
                                        <label
                                            htmlFor="example-text-input"
                                            className="col-md-1 col-form-label"
                                            >
                                        </label>
                                        <p className="col-md-11 error">{emailBccError}</p>
                                    </Row>
                                    }
                                    
                                </Row>
                            </div>
                            <div className="modal-body">
                                {
                                props.isInitEditor ?
                                    <Editor
                                        apiKey='vp9wl3j35wpx6ktafg19n0tcbtatm5auwzy4s4r4m10t2c3w'
                                        onInit={(evt, editor) => {
                                            console.log('Editor initialized')
                                            props.setLoadedEditor(true)
                                            editor.setContent(emailForm, {format: 'html'});
                                            editorRef.current = editor
                                            // console.log('getContent', editorRef.current.getContent());
                                        }}
                                        initialValue={emailForm}
                                        init={{
                                            branding: false,
                                            height: 500,
                                            plugins: 'a11ychecker advcode casechange formatpainter linkchecker autolink lists checklist image link mediaembed pageembed permanentpen powerpaste table advtable tinycomments tinymcespellchecker',
                                            toolbar: 'undo redo styleselect bold italic alignleft aligncenter alignright alignjustify | bullist numlist outdent indent table',
                                            toolbar_mode: 'floating',
                                            tinycomments_mode: 'embedded',
                                            tinycomments_author: 'WeCrest',
                                            content_css: [
                                                '//fonts.googleapis.com/css?family=Lato:300,300i,400,400i',
                                                '//www.tiny.cloud/css/codepen.min.css'
                                            ],
                                            images_upload_url: '/uploadEmailFormImageFile.json',
                                            images_upload_credentials: true,
                                            relative_urls : false,
                                            remove_script_host : false,
                                            paste_auto_cleanup_on_paste : false,
                                            menu: {
                                                custom: { 
                                                    title: 'Email Template', 
                                                    items: menuItems
                                                }
                                            },
                                            menubar: 'file edit view insert format tools table custom',
                                            setup: function(editor) {
                                                emailTemplates.map((emailTemplate, i) => {
                                                    // console.log('emailTemplate' + (i+1) + ' add Menu');
                                                    // console.log('emailTemplate' + (i+1) + ' tmpTitle : ' + emailTemplate.tmpTitle);
                                                    // console.log('emailTemplate' + (i+1) + ' emailForm : ' + emailForm);
                                                    editor.ui.registry.addMenuItem('emailTemplate' + (i+1), {
                                                        text: emailTemplate.tmpTitle,
                                                        onAction: function() {
                                                            // console.log('emailTemplate' + (i+1) + ' custom menu click');
                                                            editor.setContent(emailTemplate.emailForm, {format: 'html'});
                                                            dispatch(setReducerValue('CHANGE_TEMPLATE', emailTemplate));
                                                        }
                                                    });
                                                });
                                            },
                                        }}
                                    />
                                :
                                    null
                                }
                              
                                <div style={{marginTop: "10px"}}></div>

                                <Dropzone
                                    onDrop={acceptedFiles => {
                                    handleAcceptedFiles(acceptedFiles)
                                    }}
                                >
                                    {({ getRootProps, getInputProps }) => (
                                    <div className="dropzone" style={{minHeight: "130px"}}>
                                        <div
                                        className="dz-message needsclick" style={{fontSize: "0px"}}
                                        {...getRootProps()}
                                        >
                                        <input {...getInputProps()} />
                                        <div className="mb-1">
                                            <i className="display-4 text-muted uil uil-cloud-upload"/>
                                        </div>
                                        <h4>Drag & Drop Files Here or Browse Files ({totalAttachFileSize}/5MB)</h4>
                                        <h6 class="text-danger">Allowed file type: HTM, HTML, ZIP, TXT, RTF, PDF, DOC, DOCX, XLS, XLSX, CSV, PPT, PPTX, JPG, JPEG, PNG, GIF, SVG, WEBP</h6>
                                        </div>
                                    </div>
                                    )}
                                </Dropzone>
                                <div className="dropzone-previews mt-3" id="file-previews" style={{marginBottom: "1rem"}}>
                                    {attachFiles&&attachFiles.length>0?
                                    attachFiles.map((f, i) => {
                                        return (
                                            <Card
                                            className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                            key={i + "-file"}
                                            >
                                            <div className="p-2">
                                                <Row className="align-items-center">
                                                {
                                                    f.isImage ?
                                                    <Col className="col-auto">
                                                        <img
                                                        data-dz-thumbnail=""
                                                        height="80"
                                                        className="avatar-sm rounded bg-light"
                                                        alt={f.fileName}
                                                        src={f.attachStorageUrl}
                                                        />
                                                    </Col>
                                                    : null
                                                }
                                                
                                                <Col>
                                                    <Link
                                                    to="#"
                                                    className="text-muted font-weight-bold"
                                                    >
                                                    (ATTACH) {f.fileName}
                                                    <span className="file-success-message"> (File Uploaded)</span>
                                                    </Link>
                                                    <p className="mb-0">
                                                    <strong>{formatBytes(f.fileSize)}</strong>
                                                    </p>
                                                </Col>
                                                <Col md={1} className="file-remove"  onClick={() => removeAttachFile(f.fileName)}><Link to="#" style={{color: "black"}}>X</Link></Col>
                                                </Row>
                                                {
                                                f.isUpload ?
                                                    <div className="">
                                                    <div>
                                                        <Progress color={f.progressColor} value={f.uploadRate}>
                                                        {f.uploadRate}%
                                                        </Progress>
                                                    </div>
                                                    </div>
                                                : null
                                                }
                                            </div>
                                            </Card>
                                        )
                                        })
                                    : null
                                    }
                                    {copyFiles&&copyFiles.length>0?
                                    copyFiles.map((f, i) => {
                                        return (
                                            <Card
                                            className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                            key={i + "-file"}
                                            >
                                            <div className="p-2">
                                                <Row className="align-items-center">
                                                {
                                                    f.isImage ?
                                                    <Col className="col-auto">
                                                        <img
                                                        data-dz-thumbnail=""
                                                        height="80"
                                                        className="avatar-sm rounded bg-light"
                                                        alt={f.fileName}
                                                        src={f.attachStorageUrl}
                                                        />
                                                    </Col>
                                                    : null
                                                }
                                                
                                                <Col>
                                                    <Link
                                                    to="#"
                                                    className="text-muted font-weight-bold"
                                                    >
                                                    (COPY) {f.fileName}
                                                    <span className="file-success-message">(File Uploaded)</span>
                                                    </Link>
                                                    <p className="mb-0">
                                                    <strong>{formatBytes(f.fileSize)}</strong>
                                                    </p>
                                                </Col>
                                                <Col md={1} className="file-remove"  onClick={() => removeCopyFile(f.fileName)}><Link to="#" style={{color: "black"}}>X</Link></Col>
                                                </Row>
                                                {
                                                f.isUpload ?
                                                    <div className="">
                                                    <div>
                                                        <Progress color={f.progressColor} value={f.uploadRate}>
                                                        {f.uploadRate}%
                                                        </Progress>
                                                    </div>
                                                    </div>
                                                : null
                                                }
                                            </div>
                                            </Card>
                                        )
                                        })
                                    : null
                                    }
                                    {upFiles && upFiles.length > 0 ?
                                    upFiles.map((f, i) => {
                                        return (
                                            <Card
                                            className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                            key={i + "-file"}
                                            >
                                            <div className="p-2">
                                                <Row className="align-items-center">
                                                {
                                                    f.isImage ?
                                                    <Col className="col-auto">
                                                        <img
                                                        data-dz-thumbnail=""
                                                        height="80"
                                                        className="avatar-sm rounded bg-light"
                                                        alt={f.name}
                                                        src={f.preview}
                                                        />
                                                    </Col>
                                                    : null
                                                }
                                                
                                                <Col>
                                                    <Link
                                                    to="#"
                                                    className="text-muted font-weight-bold"
                                                    >
                                                    {f.name}
                                                    {!f.isValid && <span className="file-error-message">({f.errorMessage})</span>}
                                                    {f.isValid && f.uploadRate === 100 && <span className="file-success-message">(File Uploaded)</span>}
                                                    </Link>
                                                    <p className="mb-0">
                                                    <strong>{f.formattedSize}</strong>
                                                    </p>
                                                </Col>
                                                {!f.isValid || f.uploadRate !== 100 ?
                                                <Col md={1} className="file-remove"  onClick={() => removeUpFile(f.name)}><Link to="#" style={{color: "black"}}>X</Link></Col>
                                                : null
                                                }
                                                </Row>
                                            </div>
                                            </Card>
                                        )
                                        })
                                    : null
                                    }
                                    {
                                    /*
                                    isUpload ?
                                        <div className="">
                                        <div>
                                            <Progress color={progressColor} value={uploadRate}>
                                            {uploadRate}%
                                            </Progress>
                                        </div>
                                        </div>
                                    : null
                                    */
                                    }
                                </div>
                                
                                <Card>
                                    <Link onClick={togglePlaceHolder} className="text-dark" to="#">
                                        <div className="p-4">
                                            <Media className="d-flex align-items-center">
                                            <div className="flex-shrink-0 me-3">
                                                <i className="uil uil-left-to-right-text-direction text-primary h2"></i>
                                            </div>
                                            <div className="flex-grow-1 overflow-hidden">
                                                <h5 className="font-size-16 mb-1">Placeholders</h5>
                                                <p className="text-muted text-truncate mb-0">For optimal results, copy and paste from MS Word. Use the following placeholders, which will be replaced with the corresponding values in the actual email:</p>
                                            </div>
                                            <i className={isOpenPlaceHolder ? "mdi mdi-chevron-up accor-down-icon font-size-24" : "mdi mdi-chevron-down accor-down-icon font-size-24"}></i>
                                            </Media>
                                        </div>
                                    </Link>
                                    <Collapse isOpen={isOpenPlaceHolder}>
                                        <div className="table-responsive">
                                            <Table className="table mb-0">
                                            <tbody>
                                                {
                                                props.emailType !== 'ADM_INVOICE' ?
                                                <>
                                                <tr>
                                                <th colSpan={4}>General</th>
                                                </tr>
                                                <tr>
                                                <th scope="row">&#123;&#123;JC&#125;&#125;</th>
                                                <td>Code of refusal jurisdiction (format "JP")</td>
                                                <th>&#123;&#123;JN&#125;&#125;</th>
                                                <td>Name of refusal jurisdiction (format "Japan")</td>
                                                </tr>
                                                <tr>
                                                <th scope="row">&#123;&#123;HCC&#125;&#125;</th>
                                                <td>Holder's country code (format "FR")</td>
                                                <th>&#123;&#123;HCN&#125;&#125;</th>
                                                <td>Holder's country name (format "France")</td>
                                                </tr>
                                                <tr>
                                                <th scope="row">&#123;&#123;RCC&#125;&#125;</th>
                                                <td>Representative's country code (format "CN")</td>
                                                <th>&#123;&#123;RCN&#125;&#125;</th>
                                                <td>Representative's country name (format "China")</td>
                                                </tr>
                                                <tr>
                                                <th scope="row">&#123;&#123;HA&#125;&#125;</th>
                                                <td>Holder's address</td>
                                                <th>&#123;&#123;HN&#125;&#125;</th>
                                                <td>Holder's name</td>
                                                </tr>
                                                <tr>
                                                <th scope="row">&#123;&#123;RA&#125;&#125;</th>
                                                <td>Representative's address</td>
                                                <th>&#123;&#123;RN&#125;&#125;</th>
                                                <td>Representative's name</td>
                                                </tr>
                                                <tr>
                                                <th scope="row">&#123;&#123;Org&#125;&#125;</th>
                                                <td>Your organization's name</td>
                                                <th>&#123;&#123;OrgHyperlink&#125;&#125;</th>
                                                <td>Your organization's name, hyperlinked to your website</td>
                                                </tr>
                                                <tr>
                                                <th scope="row">&#123;&#123;UserFirst&#125;&#125;</th>
                                                <td>User's first name</td>
                                                <th>&#123;&#123;UserLast&#125;&#125;</th>
                                                <td>User's last name</td>
                                                </tr>
                                                <tr>
                                                <th>&#123;&#123;HomeCountry&#125;&#125;</th>
                                                <td>User's home country</td>
                                                <th>&#123;&#123;DD&#125;&#125;</th>
                                                <td>Current day (format "14")</td>
                                                </tr>
                                                <tr>
                                                <th>&#123;&#123;Month&#125;&#125;</th>
                                                <td>Current month (format "March")</td>
                                                <th scope="row">&#123;&#123;MM&#125;&#125;</th>
                                                <td>Current month (format "03")</td>
                                                </tr>
                                                <tr>
                                                <th scope="row">&#123;&#123;YYYY&#125;&#125;</th>
                                                <td>Current year (format "2019")</td>
                                                <th>&#123;&#123;YY&#125;&#125;</th>
                                                <td>Current year (format "19")</td>
                                                </tr>
                                                <tr>
                                                <th colSpan={4} className="text-warning">Contact (the first contact of the entity will be shown in the Preview as an example)</th>
                                                </tr>
                                                <tr>
                                                <th scope="row">&#123;&#123;contactFirstname&#125;&#125;</th>
                                                <td>Contact first name</td>
                                                <th>&#123;&#123;contactLastname&#125;&#125;</th>
                                                <td>Contact last name</td>
                                                </tr>
                                                <tr>
                                                <th scope="row">&#123;&#123;contactTitle&#125;&#125;</th>
                                                <td>Contact title</td>
                                                <th>&#123;&#123;contactPosition&#125;&#125;</th>
                                                <td>Contact position</td>
                                                </tr>
                                                </>
                                                : null
                                                }
                                                {
                                                props.emailType === 'ST' || props.emailType === 'MN' ?
                                                <>
                                                <tr>
                                                <th colSpan={4}>Madrid Notifier</th>
                                                </tr>
                                                <tr>
                                                <th scope="row">&#123;&#123;IRN&#125;&#125;</th>
                                                <td>International registration number</td>
                                                <th style={{color: "red"}}>&#123;&#123;TM&#125;&#125;</th>
                                                <td style={{color: "red"}}>Trademark (cannot be used in email subject)</td>
                                                </tr>
                                                <tr>
                                                <th scope="row" style={{color: "red"}}>&#123;&#123;RefusalPDFLink&#125;&#125;</th>
                                                <td style={{color: "red"}}>Refusal PDF Link (cannot be used in email subject)</td>
                                                <th></th>
                                                <td></td>
                                                </tr>
                                                </>
                                                : null
                                                }
                                                {
                                                props.emailType === 'ST' || props.emailType === 'FR' ?
                                                <>
                                                <tr>
                                                <th colSpan={4}>Filer Rankings</th>
                                                </tr>
                                                <tr>
                                                <th scope="row">&#123;&#123;NoRF&#125;&#125;</th>
                                                <td>Number of refusals (format "225")</td>
                                                <th>&#123;&#123;Period&#125;&#125;</th>
                                                <td>Period (format "118 day(s) [2021/02/25 to 2021/06/22]")</td>
                                                </tr>
                                                </>
                                                : null
                                                }
                                                {
                                                props.emailType === 'ADM_INVOICE' ?
                                                <>
                                                <tr>
                                                <th colSpan={4}>Admin</th>
                                                </tr>
                                                <tr>
                                                <th scope="row">&#123;&#123;User&#125;&#125;</th>
                                                <td>User name</td>
                                                <th>&#123;&#123;UserFirst&#125;&#125;</th>
                                                <td>First name</td>
                                                </tr>
                                                <tr>
                                                <th scope="row">&#123;&#123;UserOrg&#125;&#125;</th>
                                                <td>Organization</td>
                                                <th>&#123;&#123;UserMainE&#125;&#125;</th>
                                                <td>Main email</td>
                                                </tr>
                                                <tr>
                                                <th scope="row">&#123;&#123;UserAccE&#125;&#125;</th>
                                                <td>Accounting email</td>
                                                <th>&#123;&#123;UserPW&#125;&#125;</th>
                                                <td>Account password (welcome mail only)</td>
                                                </tr>
                                                <tr>
                                                <th scope="row">&#123;&#123;UserTier&#125;&#125;</th>
                                                <td>Gold, Silver, Free</td>
                                                <th>&#123;&#123;admin.userFirst&#125;&#125;</th>
                                                <td>Account manager First name</td>
                                                </tr>
                                                <tr>
                                                <th scope="row">&#123;&#123;InvNo&#125;&#125;</th>
                                                <td>Invoice number</td>
                                                <th>&#123;&#123;InvDate&#125;&#125;</th>
                                                <td>Invoice date</td>
                                                </tr>
                                                <tr>
                                                <th scope="row">&#123;&#123;InvTotal&#125;&#125;</th>
                                                <td>Invoice Total</td>
                                                <th>&#123;&#123;InvDL&#125;&#125;</th>
                                                <td>Invoice deadline</td>
                                                </tr>
                                                <tr>
                                                <th scope="row">&#123;&#123;AllSubDetails&#125;&#125;</th>
                                                <td>All subs</td>
                                                <th>&#123;&#123;AllTrialSubDetails&#125;&#125;</th>
                                                <td>All trial subs</td>
                                                </tr>
                                                <tr>
                                                <th scope="row">&#123;&#123;AllMemberSubDetails&#125;&#125;</th>
                                                <td>All member subs</td>
                                                <th>&#123;&#123;InvoiceSubDetails&#125;&#125;</th>
                                                <td>Only invoice subs</td>
                                                </tr>
                                                </>
                                                : null
                                                }
                                            </tbody>
                                            </Table>
                                        </div>
                                    </Collapse>
                                </Card>
                            </div>
                            <div className="modal-footer">
                                {
                                props.emailType === 'ADM_INVOICE' || props.emailType === 'ADM_ACCOUNT' ?
                                    <>
                                    {
                                    props.createFlag?
                                    <button type="button" className="btn btn-primary" onClick={saveEmail}>
                                        {props.emailType === 'ADM_INVOICE' ? "Send email" : "Create Account"}
                                    </button>
                                    :
                                    null
                                    }
                                    <button
                                        type="button"
                                        className="btn btn-light"
                                        data-dismiss="modal"
                                        onClick={props.closeEditor}
                                    >
                                        Minimize
                                    </button>
                                    </>
                                :
                                    <>
                                    <button type="button" className="btn btn-primary" onClick={saveEmail}>
                                        Save changes
                                    </button>
                                    <button
                                        type="button"
                                        className="btn btn-light"
                                        data-dismiss="modal"
                                        onClick={props.closeEditor}
                                    >
                                    Close
                                    </button>
                                    </>
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-backdrop fade show"></div>
            </div>
        </div>
        {
        props.isOpenEditor && (!props.loadedEditor || loadingEditor)?
            <LoadingModal loading={!props.loadedEditor || loadingEditor}></LoadingModal>
        :
            null
        }
        {alertMessage !== "" ? (
        <SweetAlert
            onConfirm={() => {
                setAlertMessage("");
            }}
            onCancel={() => setAlertMessage("")}
        >{alertMessage}
        </SweetAlert>
        ) : null}

        {errorMsg !== '' ? (
        <SweetAlert
            warning
            onConfirm={() => {
            dispatch(setReducerValue('ERROR_MSG', ""))
            }}
            onCancel={() => {
            dispatch(setReducerValue('ERROR_MSG', ""))
            }}
        >{errorMsg}
        </SweetAlert>
        ) 
        : null}
        </>
    );
});

export default EditorModal;