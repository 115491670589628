import React from "react";
import ReactApexChart from "react-apexcharts";
import { Card, CardBody, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, CardTitle } from "reactstrap";
import CountUp from 'react-countup';

const MadridChart = props => {

    const series = [{
        name: 'Email sent',
        type: 'line',
        data: props.data && props.data.length? props.data.map((month, index) => {
            return month.sentFrEmailCnt
        }) : []
    }, {
        name: 'WeCrest max',
        type: 'area',
        data: props.data && props.data.length? props.data.map((month, index) => {
            return month.wecrestMaxSentFrEmailCnt
        }) : []
    }];

    const options = {
        chart: {
            stacked: !1,
            toolbar: {
                show: !1
            }
        },
        stroke: {
            width: [4, 2],
            curve: 'smooth'
        },
        plotOptions: {
            bar: {
                columnWidth: '30%'
            }
        },
        colors: ['#f46a6a', '#dfe2e6'],

        fill: {
            opacity: [0.9, 0.25],
            gradient: {
                inverseColors: !1,
                shade: 'light',
                type: "vertical",
                opacityFrom: 0.85,
                opacityTo: 0.55,
                stops: [0, 100, 100, 100]
            }
        },
        labels: props.data && props.data.length? props.data.map((month, index) => {
            return month.regDate
        }) : [],
        markers: {
            size: 0
        },

        xaxis: {
            type: 'datetime'
        },
        /*
        yaxis: {
            title: {
                text: 'Points',
            },
        },
        */
        tooltip: {
            shared: !0,
            intersect: !1,
            x: {
                formatter: function (x) {
                    if (typeof x !== "undefined") {
                        const xdate = new Date(x);
                        return xdate.toLocaleDateString('en-US', {year: '2-digit', month: 'short'});
                        // console.log('tooltip x : ' + x);
                        // return x;
                    }
                    return x;
                }
            },
            y: {
                formatter: function (y) {
                    if (typeof y !== "undefined") {
                        //return y.toFixed(0) + " points";
                        return y.toFixed(0);
                    }
                    return y;

                }
            }
        },
        grid: {
            borderColor: '#f1f1f1'
        }
    };

    return (
        <React.Fragment>
            <Card>
                <CardBody>
                    <CardTitle className="mb-4 h4">Filer Rankings statistics</CardTitle>
                    <div className="mt-3">
                        <ReactApexChart
                            options={options}
                            series={series}
                            height="339"
                            type="line"
                            className="apex-charts"
                        />
                    </div>
                </CardBody>
            </Card>

        </React.Fragment>
    );
};

export default MadridChart;