import {
  GET_MULTIPLE_COUNTRY_LIST,
  GET_MULTIPLE_COUNTRY_LIST_SUCCESS,
  CHANGE_MULTIPLE_COUNTRY_LIST,
} from "./actionTypes"

const initialState = {
  multipleCountryList: [],
  subsCountryCount:0,
  multipleCountryList_MCC: [],
  error: "",
  loading: false,
}

const multipleCountry = (state = initialState, action) => {
  switch (action.type) {
    case GET_MULTIPLE_COUNTRY_LIST:
      return {
        ...state,
        multipleCountryList: [],
      }
    case GET_MULTIPLE_COUNTRY_LIST_SUCCESS:
      // console.log('action.payload.filterName : ' + action.payload.filterName);
      // console.log(JSON.stringify(action.payload.data.multipleCountryList));

      if (action.payload.filterName && action.payload.filterName === 'myConnectedCase') {
        return {
          ...state,
          multipleCountryList_MCC: action.payload.data.multipleCountryList,
        }
      } else {
        return {
          ...state,
          multipleCountryList: action.payload.data.multipleCountryList,
          subsCountryCount: action.payload.data.subsCountryCount,
        }
      }
    case CHANGE_MULTIPLE_COUNTRY_LIST:
      if (action.payload.filterName && action.payload.filterName === 'myConnectedCase') {
        return {
          ...state,
          multipleCountryList_MCC: action.payload.multipleCountryList
        }
      } else {
        return {
          ...state,
          multipleCountryList: action.payload.multipleCountryList
        }
      }
    default:
      state = { ...state }
      break
  }
  return state
}

export default multipleCountry
