import { call, put, takeEvery, takeLatest } from "redux-saga/effects"

// MadridNotifier Redux States
import { 
  GET_SUBSCRIPTION_ITEM_LIST,
  SAVE_SUBSCRIPTION_ITEM,
  DELETE_SUBSCRIPTION_ITEM,
} from "./actionTypes"
import { 
  getSubscriptionItemListSuccess,
  saveSubscriptionItemSuccess,
  deleteSubscriptionItemSuccess,
} from "./actions"

//Include Both Helper File with needed methods
import {
  getSubscriptionItemList,
  saveSubscriptionItem,
  deleteSubscriptionItem,
} from "../../helpers/backend_helper"

function* onGetSubscriptionItemList({ payload : data }) {
  try {
    const response = yield call(getSubscriptionItemList, data);
    yield put(getSubscriptionItemListSuccess(response));
    
  } catch (error) {
    console.log('onGetSubscriptionItemList error >> ' + error);
  }
}

function* onSaveSubscriptionItem({ payload : data }) {
  try {
    const response = yield call(saveSubscriptionItem, data.data);
    yield put(saveSubscriptionItemSuccess(response, data.isReload));
    
  } catch (error) {
    console.log('onSaveSubscriptionItemList error >> ' + error);
  }
}

function* onDeleteSubscriptionItem({ payload : data }) {
  try {
    const response = yield call(deleteSubscriptionItem, data);
    yield put(deleteSubscriptionItemSuccess(response));
    
  } catch (error) {
    console.log('onDeleteSubscriptionItem error >> ' + error);
  }
}

function* AdmPricingManagementSaga() {
  yield takeLatest(GET_SUBSCRIPTION_ITEM_LIST, onGetSubscriptionItemList);
  yield takeLatest(SAVE_SUBSCRIPTION_ITEM, onSaveSubscriptionItem);
  yield takeLatest(DELETE_SUBSCRIPTION_ITEM, onDeleteSubscriptionItem);
}

export default AdmPricingManagementSaga

