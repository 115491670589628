import React, { useState } from "react"
import { Card, CardBody, Table } from "reactstrap"

//Simple bar
import SimpleBar from "simplebar-react"


const TopFiler = props => {
    return (
        <React.Fragment>
            <Card style={{height : '95%'}}>
                <CardBody>
                    <h4 className="card-title mb-4">Top {props.userGrade === '00' ? "10" : "20"} filers (last month)</h4>
                    <SimpleBar style={{ maxHeight: "321px" }}>
                        <div className="table-responsive">
                            <Table className="table-borderless table-centered table-nowrap">
                                <tbody>
                                    <tr>
                                        <td><h6 className="font-size-15 mb-1 fw-normal">Ranking</h6></td>
                                        <td><h6 className="font-size-15 mb-1 fw-normal">Representative </h6></td>
                                    </tr>
                                {props.data && props.data.length?
                                    props.data.map((filer, index)=>{
                                        return (
                                            <tr>
                                                <td><h6 className="font-size-15 mb-1 fw-normal">{filer.rnk}</h6></td>
                                                <td><h6 className="font-size-15 mb-1 fw-normal">{filer.targetGroupName}</h6></td>
                                            </tr>
                                        )
                                    })
                                :null }
                                </tbody>
                            </Table>
                        </div>
                    </SimpleBar>
                </CardBody>
            </Card>
        </React.Fragment>
    )
}

export default TopFiler