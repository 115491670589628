export const GET_FILER_SETTINGS = "GET_FILER_SETTINGS"
export const GET_FILER_SETTINGS_SUCCESS = "GET_FILER_SETTINGS_SUCCESS"
export const SAVE_FILER_SETTINGS = "SAVE_FILER_SETTINGS"
export const SAVE_FILER_SETTINGS_SUCCESS = "SAVE_FILER_SETTINGS_SUCCESS"
export const SET_SUCCESS = "SET_SUCCESS"
export const SET_RESULT = "SET_RESULT"
export const GET_FILER_RANKINGS = "GET_FILER_RANKINGS"
export const GET_FILER_RANKINGS_SUCCESS = "GET_FILER_RANKINGS_SUCCESS"
export const GET_FILER_RANKING_INFO = "GET_FILER_RANKING_INFO"
export const GET_FILER_RANKING_INFO_SUCCESS = "GET_FILER_RANKING_INFO_SUCCESS"
export const CHANGE_FILER_RANKINGS = "CHANGE_FILER_RANKINGS"
export const MULTIPLE_HIDE_FILER = "MULTIPLE_HIDE_FILER"
export const MULTIPLE_UNHIDE_FILER = "MULTIPLE_UNHIDE_FILER"
export const MULTIPLE_STAR_FILER = "MULTIPLE_STAR_FILER"
export const MULTIPLE_UNSTAR_FILER = "MULTIPLE_UNSTAR_FILER"
export const DOWN_FILER_EXCEL = "DOWN_FILER_EXCEL"
export const DOWN_FILER_EXCEL_SUCCESS = "DOWN_FILER_EXCEL_SUCCESS"
export const DOWN_MULTIPLE_FILER_EXCEL = "DOWN_MULTIPLE_FILER_EXCEL"
export const DOWN_MULTIPLE_FILER_EXCEL_SUCCESS = "DOWN_MULTIPLE_FILER_EXCEL_SUCCESS"
export const DOWN_FILER_PDF = "DOWN_FILER_PDF"
export const DOWN_FILER_PDF_SUCCESS = "DOWN_FILER_PDF_SUCCESS"
export const DOWN_FILER_PDF_DATA_ONLY = "DOWN_FILER_PDF_DATA_ONLY"
export const DOWN_FILER_PDF_DATA_ONLY_SUCCESS = "DOWN_FILER_PDF_DATA_ONLY_SUCCESS"
export const DOWN_MULTIPLE_FILER_PDF = "DOWN_MULTIPLE_FILER_PDF"
export const DOWN_MULTIPLE_FILER_PDF_SUCCESS = "DOWN_MULTIPLE_FILER_PDF_SUCCESS"
export const FILER_SEND_EMAIL = "FILER_SEND_EMAIL"
export const FILER_PREVIEW_EMAIL = "FILER_PREVIEW_EMAIL"
export const FILER_PREVIEW_EMAIL_SUCCESS = "FILER_PREVIEW_EMAIL_SUCCESS"
export const SET_PREVIEW_EMAIL = "SET_PREVIEW_EMAIL"
export const CLEAR_FILER_RANKINGS = "CLEAR_FILER_RANKINGS"
export const UNLOCK_FILER = "UNLOCK_FILER"
export const SET_FILER_LOADING = "SET_FILER_LOADING"
export const STAR_FILER_TARGET = "STAR_FILER_TARGET"
export const UN_STAR_FILER_TARGET = "UN_STAR_FILER_TARGET"
export const HIDE_FILER_TARGET = "HIDE_FILER_TARGET"
export const UN_HIDE_FILER_TARGET = "UN_HIDE_FILER_TARGET"
export const CHANGE_UNSUBS_INFO = "CHANGE_UNSUBS_INFO"
export const CHANGE_FILER_SEARCH_SETTINGS_COUNTRY = "CHANGE_FILER_SEARCH_SETTINGS_COUNTRY"