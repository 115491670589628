import { call, put, takeLatest } from "redux-saga/effects"

// Login Redux States
import { 
  GET_JURISDICTIONS_MONTHLY_PRICING,
  GET_USER_MEMBERSHIP_INFO,
  UPDATE_SUBSCRIPTION_TO_FREE,
  UPDATE_GOLD_MEMBERSHIP_PLAN,
  GET_PAYPAL_PLAN_ID,
} from "./actionTypes"
import { 
  getJurisdictionsMonthlyPricingSuccess,
  getUserMembershipInfoSuccess,
  updateSubscriptionToFreeSuccess,
  updateGoldMembershipPlanSuccess,
  getPaypalPlanIdSuccess,
  setMembershipPlanValue,
} from "./actions"

//Include Both Helper File with needed methods
import {
  getJurisdictionsMonthlyPricing,
  getUserMembershipInfo,
  updateSubscriptionToFree,
  updateGoldMembershipPlan,
  getPaypalPlanId,
  updateNewDashboard,
} from "../../helpers/backend_helper"

function* onGetJurisdictionsMonthlyPricing({ payload: inOutType }) {
  try {
    const response = yield call(getJurisdictionsMonthlyPricing, inOutType);
    yield put(getJurisdictionsMonthlyPricingSuccess(response));
    
  } catch (error) {
    console.log('onGetJurisdictionsMonthlyPricing error >> ' + error);
  }
}


function* onGetUserMembershipInfo() {
  try {
    const response = yield call(getUserMembershipInfo);
    yield put(getUserMembershipInfoSuccess(response));
    
  } catch (error) {
    console.log('onGetUserMembershipInfo error >> ' + error);
  }
}

function* onUpdateSubscriptionToFree() {
  try {
    const response = yield call(updateSubscriptionToFree);
    if(response.error != undefined && response.error >-1){
      yield put(setMembershipPlanValue('ERROR_MSG', response.error));
    }else{
      yield put(updateSubscriptionToFreeSuccess(response));
    }

  } catch (error) {
    console.log('onUpdateSubscriptionToFree error >> ' + error);
  }
}

function* onUpdateGoldMembershipPlan({ payload : data }) {
  try {
    const response = yield call(updateGoldMembershipPlan, data);
    if(response.error != undefined && response.error >-1){
      yield put(setMembershipPlanValue('ERROR_MSG', response.error));
    }else{
      yield put(updateGoldMembershipPlanSuccess(response));

      // dashboard update 비동기 처리 
      yield call(updateNewDashboard, data);
    }
  } catch (error) {
    console.log('onUpdateGoldMembershipPlan error >> ' + error);
  }
}

function* onGetPaypalPlanId({ payload : data }) {
  try {
    const response = yield call(getPaypalPlanId, data);
    if(response.error != undefined && response.error >-1){
      yield put(setMembershipPlanValue('ERROR_MSG', response.error));
    }else{
      yield put(getPaypalPlanIdSuccess(response));
    }
  } catch (error) {
    console.log('onGetPaypalPlanId error >> ' + error);
  }
}

function* membershipPlanSaga() {
  yield takeLatest(GET_JURISDICTIONS_MONTHLY_PRICING, onGetJurisdictionsMonthlyPricing);
  yield takeLatest(GET_USER_MEMBERSHIP_INFO, onGetUserMembershipInfo);
  yield takeLatest(UPDATE_SUBSCRIPTION_TO_FREE, onUpdateSubscriptionToFree);
  yield takeLatest(UPDATE_GOLD_MEMBERSHIP_PLAN, onUpdateGoldMembershipPlan);
  yield takeLatest(GET_PAYPAL_PLAN_ID, onGetPaypalPlanId);
}

export default membershipPlanSaga

