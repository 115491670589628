export const GET_MY_CONNECTED_CASE_SETTINGS = "GET_MY_CONNECTED_CASE_SETTINGS"
export const GET_MY_CONNECTED_CASE_SETTINGS_SUCCESS = "GET_MY_CONNECTED_CASE_SETTINGS_SUCCESS"
export const SAVE_MY_CONNECTED_CASE_SETTINGS = "SAVE_MY_CONNECTED_CASE_SETTINGS"
export const SAVE_MY_CONNECTED_CASE_SETTINGS_SUCCESS = "SAVE_MY_CONNECTED_CASE_SETTINGS_SUCCESS"
export const GET_MY_CONNECTED_CASE_LIST = "GET_MY_CONNECTED_CASE_LIST"
export const GET_MY_CONNECTED_CASE_LIST_SUCCESS = "GET_MY_CONNECTED_CASE_LIST_SUCCESS"
export const CHANGE_MY_CONNECTED_CASE_LIST = "CHANGE_MY_CONNECTED_CASE_LIST"
export const GET_MY_CASE_MANAGEMENT_SETTINGS = "GET_MY_CASE_MANAGEMENT_SETTINGS"
export const GET_MY_CASE_MANAGEMENT_SETTINGS_SUCCESS = "GET_MY_CASE_MANAGEMENT_SETTINGS_SUCCESS"
export const SAVE_MY_CASE_MANAGEMENT_SETTINGS = "SAVE_MY_CASE_MANAGEMENT_SETTINGS"
export const SAVE_MY_CASE_MANAGEMENT_SETTINGS_SUCCESS = "SAVE_MY_CASE_MANAGEMENT_SETTINGS_SUCCESS"
export const GET_MY_CASE_MANAGEMENT_LIST = "GET_MY_CASE_MANAGEMENT_LIST"
export const GET_MY_CASE_MANAGEMENT_LIST_SUCCESS = "GET_MY_CASE_MANAGEMENT_LIST_SUCCESS"
export const CHANGE_MY_CASE_MANAGEMENT_LIST = "CHANGE_USER_ENTITY_CONNECT_LIST"
export const DOWN_MY_CASE_MANAGEMENT_EXCEL = "DOWN_MY_CASE_MANAGEMENT_EXCEL"
export const DOWN_MY_CASE_MANAGEMENT_EXCEL_SUCCESS = "DOWN_MY_CASE_MANAGEMENT_EXCEL_SUCCESS"
export const DOWN_MULTIPLE_MY_CASE_MANAGEMENT_EXCEL = "DOWN_MULTIPLE_MY_CASE_MANAGEMENT_EXCEL"
export const DOWN_MULTIPLE_MY_CASE_MANAGEMENT_EXCEL_SUCCESS = "DOWN_MULTIPLE_MY_CASE_MANAGEMENT_EXCEL_SUCCESS"
export const DOWN_MY_CASE_MANAGEMENT_PDF = "DOWN_MY_CASE_MANAGEMENT_PDF"
export const DOWN_MY_CASE_MANAGEMENT_PDF_SUCCESS = "DOWN_MY_CASE_MANAGEMENT_PDF_SUCCESS"
export const DOWN_MULTIPLE_MY_CASE_MANAGEMENT_PDF = "DOWN_MULTIPLE_MY_CASE_MANAGEMENT_PDF"
export const DOWN_MULTIPLE_MY_CASE_MANAGEMENT_PDF_SUCCESS = "DOWN_MULTIPLE_MY_CASE_MANAGEMENT_PDF_SUCCESS"
export const GET_USER_IR_INFO_CLAIM_LIST = "GET_USER_IR_INFO_CLAIM_LIST"
export const GET_USER_IR_INFO_CLAIM_LIST_SUCCESS = "GET_USER_IR_INFO_CLAIM_LIST_SUCCESS"
export const CHANGE_USER_IR_INFO_CLAIM_LIST = "CHANGE_USER_IR_INFO_CLAIM_LIST"
export const ADD_USER_IR_INFO_CLAIM = "ADD_USER_IR_INFO_CLAIM"
export const ADD_USER_IR_INFO_CLAIM_SUCCESS = "ADD_USER_IR_INFO_CLAIM_SUCCESS"
export const CONFIRM_USER_IR_INFO_CLAIM = "CONFIRM_USER_IR_INFO_CLAIM"
export const CONFIRM_USER_IR_INFO_CLAIM_SUCCESS = "CONFIRM_USER_IR_INFO_CLAIM_SUCCESS"
export const GET_TARGET_GROUP_DETAIL_LIST = "GET_TARGET_GROUP_DETAIL_LIST"
export const GET_TARGET_GROUP_DETAIL_LIST_SUCCESS = "GET_TARGET_GROUP_DETAIL_LIST_SUCCESS"
export const SET_SUCCESS = "SET_SUCCESS"
export const SET_RESULT = "SET_RESULT"
export const SET_LOADING = "SET_LOADING"