import { call, put, takeLatest} from "redux-saga/effects"

import { 
  GET_COMMUNITY_LIST,
  GET_COMMUNITY_DETAIL,
  CREATE_COMMUNITY,
  MODIFY_COMMUNITY,
  REMOVE_COMMUNITY,
  GET_COMMUNITY_ATTACH_FILE_LIST,
  ADD_COMMUNITY_ATTACH_FILE,
  REMOVE_COMMUNITY_ATTACH_FILE,
  GET_COMMUNITY_REPLY_LIST,
  CREATE_COMMUNITY_REPLY,
  MODIFY_COMMUNITY_REPLY,
  REMOVE_COMMUNITY_REPLY,
} from "./actionTypes"

import { 
  setCommunityLoading,
  setCommunityErrorMsg,
  // getCommunityList as getCommunityListAction,
  getCommunityListSuccess,
  getCommunityDetailSuccess,
  createCommunitySuccess,
  modifyCommunitySuccess,
  removeCommunitySuccess,
  getCommunityAttachFileList as getCommunityAttachFileListAction,
  getCommunityAttachFileListSuccess,
  addCommunityAttachFileSuccess,
  removeCommunityAttachFileSuccess,
  getCommunityReplyList as getCommunityReplyListAction,
  getCommunityReplyListSuccess,
  createCommunityReplySuccess,
  modifyCommunityReplySuccess,
  removeCommunityReplySuccess,
} from "./actions"

import {
  getCommunityList,
  getCommunityDetail,
  createCommunity,
  modifyCommunity,
  removeCommunity,
  getCommunityAttachFileList,
  addCommunityAttachFile,
  removeCommunityAttachFile,
  getCommunityReplyList,
  createCommunityReply,
  modifyCommunityReply,
  removeCommunityReply,
} from "../../helpers/backend_helper"

function* onGetCommunityList({ payload: data }) {
  try {
    const response = yield call(getCommunityList, data);
    yield put(getCommunityListSuccess(response));
  } catch (error) {
    console.log('onGetCommunityList error >> ' + error);
    yield put(setCommunityLoading(false));
  }
}

function* onGetCommunityDetail({ payload: data }) {
  try {
    const response = yield call(getCommunityDetail, data);
    yield put(getCommunityDetailSuccess(response));

  } catch (error) {
    console.log('onGetCommunityDetail error >> ' + error);
    yield put(setCommunityLoading(false));
  }
}

function* onCreateCommunity({ payload: data }) {
  try {
    const response = yield call(createCommunity, data);
    if (response.result === "Success") {
      yield put(createCommunitySuccess(response));
      // yield put(getCommunityListAction(data.filter));
    } else {
      yield put(setCommunityErrorMsg(response.result));
      yield put(setCommunityLoading(false));
    }
  } catch (error) {
    console.log('onCreateCommunity error >> ' + error);
    yield put(setCommunityLoading(false));
  }
}

function* onModifyCommunity({ payload: data }) {
  try {
    const response = yield call(modifyCommunity, data);
    if (response.result === "Success") {
      yield put(modifyCommunitySuccess(response, data));
      yield put(getCommunityAttachFileListAction({idx:data.get("idx")}));
      // yield put(getCommunityListAction(data.filter));
    } else {
      yield put(setCommunityErrorMsg(response.result));
      yield put(setCommunityLoading(false));
    }
  } catch (error) {
    console.log('onModifyCommunity error >> ' + error);
    yield put(setCommunityLoading(false));
  }
}

function* onRemoveCommunity({ payload: data }) {
  try {
    const response = yield call(removeCommunity, data);
    if (response.result === "Success") {
      yield put(removeCommunitySuccess(response, data));
      // yield put(getCommunityListAction(data.filter));
    } else {
      yield put(setCommunityErrorMsg(response.result));
      yield put(setCommunityLoading(false));
    }
  } catch (error) {
    console.log('onRemoveCommunity error >> ' + error);
    yield put(setCommunityLoading(false));
  }
}

function* onGetCommunityAttachFileList({ payload: data }) {
  try {
    const response = yield call(getCommunityAttachFileList, data);
    yield put(getCommunityAttachFileListSuccess(response));
  } catch (error) {
    console.log('onGetCommunityAttachFileList error >> ' + error);
    yield put(setCommunityLoading(false));
  }
}

function* onAddCommunityAttachFile({ payload: data }) {
  try {
    const response = yield call(addCommunityAttachFile, data);
    if (response.result === "Success") {
      yield put(addCommunityAttachFileSuccess(response));
      yield put(getCommunityAttachFileListAction(data.filter));
    } else {
      yield put(setCommunityErrorMsg(response.result));
      yield put(setCommunityLoading(false));
    }
  } catch (error) {
    console.log('onAddCommunityAttachFile error >> ' + error);
    yield put(setCommunityLoading(false));
  }
}

function* onRemoveCommunityAttachFile({ payload: data }) {
  try {
    const response = yield call(removeCommunityAttachFile, data.data);
    if (response.result === "Success") {
      yield put(removeCommunityAttachFileSuccess(response));
      yield put(getCommunityAttachFileListAction(data.filter));
    } else {
      yield put(setCommunityErrorMsg(response.result));
      yield put(setCommunityLoading(false));
    }
  } catch (error) {
    console.log('onRemoveCommunityAttachFile error >> ' + error);
  }
}

function* onGetCommunityReplyList({ payload: data }) {
  try {
    const response = yield call(getCommunityReplyList, data);
    yield put(getCommunityReplyListSuccess(response));
  } catch (error) {
    console.log('onGetCommunityReplyList error >> ' + error);
    yield put(setCommunityLoading(false));
    yield put(setCommunityLoading(false));
  }
}

function* onCreateCommunityReply({ payload: data }) {
  try {
    // console.log('data.data : ' + JSON.stringify(data.data));
    // console.log('data.filter : ' + JSON.stringify(data.filter));
    const response = yield call(createCommunityReply, data.data);
    if (response.result === "Success") {
      yield put(createCommunityReplySuccess(response));
      yield put(getCommunityReplyListAction(data.filter));
    } else {
      yield put(setCommunityErrorMsg(response.result));
      yield put(setCommunityLoading(false));
    }
  } catch (error) {
    console.log('onCreateCommunityReply error >> ' + error);
  }
}

function* onModifyCommunityReply({ payload: data }) {
  try {
    const response = yield call(modifyCommunityReply, data.data);
    if (response.result === "Success") {
      yield put(modifyCommunityReplySuccess(response));
      yield put(getCommunityReplyListAction(data.filter));
    } else {
      yield put(setCommunityErrorMsg(response.result));
      yield put(setCommunityLoading(false));
    }
  } catch (error) {
    console.log('onModifyCommunityReply error >> ' + error);
    yield put(setCommunityLoading(false));
  }
}

function* onRemoveCommunityReply({ payload: data }) {
  try {
    const response = yield call(removeCommunityReply, data.data);
    if (response.result === "Success") {
      yield put(removeCommunityReplySuccess(response));
      yield put(getCommunityReplyListAction(data.filter));
    } else {
      yield put(setCommunityErrorMsg(response.result));
      yield put(setCommunityLoading(false));
    }
  } catch (error) {
    console.log('onRemoveCommunityReply error >> ' + error);
    yield put(setCommunityLoading(false));
  }
}

function* CommunitySaga() {
  yield takeLatest(GET_COMMUNITY_LIST, onGetCommunityList);
  yield takeLatest(GET_COMMUNITY_DETAIL, onGetCommunityDetail);
  yield takeLatest(CREATE_COMMUNITY, onCreateCommunity);
  yield takeLatest(MODIFY_COMMUNITY, onModifyCommunity);
  yield takeLatest(REMOVE_COMMUNITY, onRemoveCommunity);
  yield takeLatest(GET_COMMUNITY_ATTACH_FILE_LIST, onGetCommunityAttachFileList);
  yield takeLatest(ADD_COMMUNITY_ATTACH_FILE, onAddCommunityAttachFile);
  yield takeLatest(REMOVE_COMMUNITY_ATTACH_FILE, onRemoveCommunityAttachFile);
  yield takeLatest(GET_COMMUNITY_REPLY_LIST, onGetCommunityReplyList);
  yield takeLatest(CREATE_COMMUNITY_REPLY, onCreateCommunityReply);
  yield takeLatest(MODIFY_COMMUNITY_REPLY, onModifyCommunityReply);
  yield takeLatest(REMOVE_COMMUNITY_REPLY, onRemoveCommunityReply);
}

export default CommunitySaga