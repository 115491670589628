import {
  GET_JURISDICTIONS_MONTHLY_PRICING,
  GET_JURISDICTIONS_MONTHLY_PRICING_SUCCESS,
  CHANGE_JURISDICTIONS_MONTHLY_PRICING,
  GET_USER_MEMBERSHIP_INFO,
  GET_USER_MEMBERSHIP_INFO_SUCCESS,
  SET_MEMBERSHIP_PLAN_VALUE,
  UPDATE_SUBSCRIPTION_TO_FREE,
  UPDATE_SUBSCRIPTION_TO_FREE_SUCCESS,
  UPDATE_GOLD_MEMBERSHIP_PLAN,
  UPDATE_GOLD_MEMBERSHIP_PLAN_SUCCESS,
  GET_PAYPAL_PLAN_ID,
  GET_PAYPAL_PLAN_ID_SUCCESS,
} from "./actionTypes"

const initialState = {
  loading: false,
  success: "",
  billedMonthFlag: false,
  jurisdictionList: [],
  orgUserSubscriptionList: [],
  userSubscriptionList: [],
  orgPaypalSubscriptionID: "",
  changeSubscriptionFlag: false,
  serverDate: "",
  userGrade: "",
  membershipPlanType: "",
  paypalClientID: "",
  wecrestProductID: "",
  paypalProductID: "",
  paypalPlanID: "",
  errorMsg: "",
}

const membershipPlan = (state = initialState, action) => {
  switch (action.type) {
    case GET_JURISDICTIONS_MONTHLY_PRICING:
      return {
        ...state,
        jurisdictionList: [],
      }
    case GET_JURISDICTIONS_MONTHLY_PRICING_SUCCESS:
      return {
        ...state,
        jurisdictionList: action.payload
      }
    case CHANGE_JURISDICTIONS_MONTHLY_PRICING:
      return {
        ...state,
        jurisdictionList: action.payload
      }
    case GET_USER_MEMBERSHIP_INFO:
      return {
        ...state,
        orgUserSubscriptionList: [],
        userSubscriptionList: [],
        serverDate: "",
        userGrade: "00",
      }
    case GET_USER_MEMBERSHIP_INFO_SUCCESS:
      return {
        ...state,
        orgUserSubscriptionList: action.payload.userSubscriptionList,
        userSubscriptionList: action.payload.userSubscriptionList,
        orgPaypalSubscriptionID: action.payload.orgPaypalSubscriptionID,
        serverDate: action.payload.serverDate,
        userGrade: action.payload.userGrade,
        membershipPlanType: action.payload.membershipPlanType,
        paypalClientID: action.payload.paypalClientID,
      }
    case UPDATE_SUBSCRIPTION_TO_FREE:
      return {
        ...state,
        loading: true
      }
    case UPDATE_SUBSCRIPTION_TO_FREE_SUCCESS:
      return {
        ...state,
        loading: false,
        success: "Switch plan Success",
        orgUserSubscriptionList: action.payload.userSubscriptionList,
        userSubscriptionList: action.payload.userSubscriptionList,
        orgPaypalSubscriptionID: action.payload.orgPaypalSubscriptionID,
        serverDate: action.payload.serverDate,
        userGrade: action.payload.userGrade,
        membershipPlanType: action.payload.membershipPlanType,
        changeSubscriptionFlag: false,
        wecrestProductID: "",
        paypalProductID: "",
        paypalPlanID: "",
      }
    case UPDATE_GOLD_MEMBERSHIP_PLAN:
      return {
        ...state,
        loading: true
      }
    case UPDATE_GOLD_MEMBERSHIP_PLAN_SUCCESS:
      return {
        ...state,
        loading: false,
        success: "Switch plan Success",
        orgUserSubscriptionList: action.payload.userSubscriptionList,
        userSubscriptionList: action.payload.userSubscriptionList,
        orgPaypalSubscriptionID: action.payload.orgPaypalSubscriptionID,
        serverDate: action.payload.serverDate,
        userGrade: action.payload.userGrade,
        membershipPlanType: action.payload.membershipPlanType,
        changeSubscriptionFlag: false,
        wecrestProductID: "",
        paypalProductID: "",
        paypalPlanID: "",
      }
    case GET_PAYPAL_PLAN_ID:
      return {
        ...state,
        loading: true
      }
    case GET_PAYPAL_PLAN_ID_SUCCESS:

      return {
        ...state,
        loading: false,
        wecrestProductID: action.payload.wecrestProductID,
        paypalProductID: action.payload.paypalProductID,
        paypalPlanID: action.payload.paypalPlanID,
      }
    case SET_MEMBERSHIP_PLAN_VALUE:
      if (action.payload.type === 'LOADING_FLAG') {
        state = {
          ...state,
          loading: action.payload.data,
        }
      } else if (action.payload.type === 'SUCCESS') {
        state = {
          ...state,
          success: action.payload.data,
        }
      } else if (action.payload.type === 'BILLED_MONTH_FLAG') {
        state = {
          ...state,
          billedMonthFlag: action.payload.data,
        }
      } else if (action.payload.type === 'USER_SUBSCRIPTION_LIST') {
        state = {
          ...state,
          userSubscriptionList: action.payload.data,
        }
      } else if (action.payload.type === 'CHANGE_SUBSCRIPTION_FLAG') {
        state = {
          ...state,
          changeSubscriptionFlag: action.payload.data,
        }
      } else if (action.payload.type === 'ERROR_MSG') {
        state = {
          ...state,
          errorMsg: action.payload.data,
        }
      }
      break
  }
  return state
}

export default membershipPlan
