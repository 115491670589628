import React, {useState} from 'react';
import {
    Table,
    Card,
    CardBody,
    Media,
    Collapse,
    Modal,
  } from "reactstrap"
import { Link } from "react-router-dom";

import '../../components/Common/Common.css';

const SendingAnalyticsStatus = props => {
 
  return (
    <Modal
      size="xl"
      isOpen={props.openInfomation}
      toggle={() => {
        props.toggleStatus()
      }}
    >
      <div className="modal-header" style={{borderBottom: "0px", padding: "0.8rem"}}>
        
        <button
          onClick={() => {
            props.toggleStatus()
          }}
          type="button"
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body">
        
        <Card className="mb-2">
          <CardBody className="p-1">
            <Table className="table mb-0">
              <tbody>
                <tr style={{borderTop: "transparent"}}>
                  <td width="15%"><label>Sent</label></td><td>Email sent to recipient, but not delivered yet</td>
                </tr>
                <tr>
                  <td width="15%"><label>Delivered</label></td><td>Email delivered to recipient, but not opened yet</td>
                </tr>
                <tr>
                  <td width="15%"><label>Opened</label></td><td>Email opened by recipient, but no links in email clicked yet</td>
                </tr>
                <tr>
                  <td width="15%"><label>Clicked</label></td><td>Email opened, and at least one link in email (except for unsubscribe link) clicked by recipient</td>
                </tr>
                <tr>
                  <td width="15%"><label>Soft bounced</label></td><td>Delivery to recipient failed, because receiving mail server temporarily unavailable or receiving mailbox full</td>
                </tr>
                <tr>
                  <td width="15%"><label>Hard bounced</label></td><td>Delivery to recipient failed due to permanent delivery issue such as very strict security filter</td>
                </tr>
                <tr>
                  <td width="15%"><label>Blocked</label></td><td>Email not sent, because prior email hard bounced or marked as spam or unsubscribed by recipient</td>
                </tr>
                <tr>
                  <td width="15%"><label>Complaint</label></td><td>Email marked as spam by recipient</td>
                </tr>
                <tr>
                  <td width="15%"><label>Unsubscribed</label></td><td>Recipient unsubscribed</td>
                </tr>
                <tr>
                  <td width="15%"><label>Invalid email</label></td><td>Email sent to email address that does not exist</td>
                </tr>
                <tr>
                  <td width="15%"><label>Error</label></td><td>Email not sent due to network error</td>
                </tr>
                <tr>
                  <td colspan="2" className="text-danger">* Sometimes the email status can change later on. That is, the status that you see now may not be the final status. For example, an email with the status "sent" can change later on to "opened" or "clicked".</td>
                </tr>
                <tr>
                  <td colspan="2" className="text-danger">** This data is best used for analyzing large volumes of emails, because inaccuracies may exist on an individual email basis.</td>
                </tr>
              </tbody>
            </Table>
          </CardBody>
        </Card>

      </div>
      <div className="modal-footer">
        <button
          type="button"
          onClick={() => {
            props.toggleStatus()
          }}
          className="btn btn-secondary waves-effect"
          data-dismiss="modal"
        >
          Close
        </button>
      </div>
    </Modal>

  )
}

export default SendingAnalyticsStatus;