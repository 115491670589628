export const LOGIN_USER = "LOGIN_USER"
export const LOGIN_SUCCESS = "LOGIN_SUCCESS"

export const LOGOUT_USER = "LOGOUT_USER"
export const LOGOUT_USER_SUCCESS = "LOGOUT_USER_SUCCESS"
export const OPEN_TC_AGREE_MODAL = "OPEN_TC_AGREE_MODAL"
export const CLOSE_TC_AGREE_MODAL = "CLOSE_TC_AGREE_MODAL"
export const API_ERROR = "LOGIN_API_ERROR"

export const SOCIAL_LOGIN = "SOCIAL_LOGIN"

export const SESSION_LOGOUT = "SESSION_LOGOUT"

export const CHECK_COOKIE_AGREE = "CHECK_COOKIE_AGREE"
export const CHECK_COOKIE_AGREE_SUCCESS = "CHECK_COOKIE_AGREE_SUCCESS"
export const UPDATE_COOKIE_AGREE = "UPDATE_COOKIE_AGREE"
export const UPDATE_COOKIE_AGREE_SUCCESS = "UPDATE_COOKIE_AGREE_SUCCESS"

export const SET_LOGIN_LOADING = "SET_LOGIN_LOADING"