import React from 'react';
import {
    Button,
    Input,
  } from "reactstrap"

function WeCrestPagination(props) {
    return (
        <div className="d-flex justify-content-between">
            <span className="d-flex align-items-center gap-2">
            <label
                htmlFor="pageSize"
                >
                Entries per page
            </label>
            <div style={{width: "100px"}}>
                <select 
                className="form-select" 
                name="pageSize"
                onChange={e => {props.viewList(null, 1, e.target.value);}}
                value={props.pageSize}
                >
                <option value="10">10</option>
                <option value="20">20</option>
                <option value="30">30</option>
                <option value="40">40</option>
                <option value="50">50</option>
                </select>
            </div>
            </span>
            <span className="d-flex align-items-center gap-2">
            <div className="d-flex gap-1">
                <Button
                type="button"
                color="primary"
                onClick={()=>{props.viewList(null, 1);}}
                disabled={(props.curPage === 1)}
                >
                {"<<"}
                </Button>
                <Button
                type="button"
                color="primary"
                onClick={()=>{props.viewList(null, props.curPage-1);}}
                disabled={(props.curPage === 1)}
                >
                {"<"}
                </Button>
            </div>
            Page{" "}
            <strong>
                {props.curPage} of {props.totalPage}
            </strong>
            
            <Input
                type="number"
                min={1}
                style={{ width: 70 }}
                max={props.totalPage}
                name="curPage"
                defaultValue={props.curPage}
                value={props.curPageInput}
                onChange={(e) => { props.setCurPageInput(e.target.value)}}
                onKeyPress={(e) => {
                if(e.key === 'Enter'){
                    props.viewList(null, e.target.value);
                }                      
                }}
            />
            <div className="d-flex gap-1">
                <Button 
                type="button"
                color="primary"
                onClick={()=>{props.viewList(null, props.curPage+1);}}
                disabled={(props.curPage === props.totalPage)}
                >
                {">"}
                </Button>
                <Button
                type="button"
                color="primary"
                onClick={()=>{props.viewList(null, props.totalPage);}}
                disabled={(props.curPage === props.totalPage)}
                >
                {">>"}
                </Button>
            </div>
            </span>
        </div>
    );
}

export default WeCrestPagination;
