import {
  GET_MY_CONNECTED_CASE_SETTINGS,
  GET_MY_CONNECTED_CASE_SETTINGS_SUCCESS,
  SAVE_MY_CONNECTED_CASE_SETTINGS,
  SAVE_MY_CONNECTED_CASE_SETTINGS_SUCCESS,
  GET_MY_CONNECTED_CASE_LIST,
  GET_MY_CONNECTED_CASE_LIST_SUCCESS,
  CHANGE_MY_CONNECTED_CASE_LIST,
  GET_MY_CASE_MANAGEMENT_SETTINGS,
  GET_MY_CASE_MANAGEMENT_SETTINGS_SUCCESS,
  SAVE_MY_CASE_MANAGEMENT_SETTINGS,
  SAVE_MY_CASE_MANAGEMENT_SETTINGS_SUCCESS,
  GET_MY_CASE_MANAGEMENT_LIST,
  GET_MY_CASE_MANAGEMENT_LIST_SUCCESS,
  CHANGE_MY_CASE_MANAGEMENT_LIST,
  DOWN_MY_CASE_MANAGEMENT_EXCEL,
  DOWN_MY_CASE_MANAGEMENT_EXCEL_SUCCESS,
  DOWN_MULTIPLE_MY_CASE_MANAGEMENT_EXCEL,
  DOWN_MULTIPLE_MY_CASE_MANAGEMENT_EXCEL_SUCCESS,
  DOWN_MY_CASE_MANAGEMENT_PDF,
  DOWN_MY_CASE_MANAGEMENT_PDF_SUCCESS,
  DOWN_MULTIPLE_MY_CASE_MANAGEMENT_PDF,
  DOWN_MULTIPLE_MY_CASE_MANAGEMENT_PDF_SUCCESS,
  GET_USER_IR_INFO_CLAIM_LIST,
  GET_USER_IR_INFO_CLAIM_LIST_SUCCESS,
  CHANGE_USER_IR_INFO_CLAIM_LIST,
  ADD_USER_IR_INFO_CLAIM,
  ADD_USER_IR_INFO_CLAIM_SUCCESS,
  CONFIRM_USER_IR_INFO_CLAIM,
  CONFIRM_USER_IR_INFO_CLAIM_SUCCESS,
  GET_TARGET_GROUP_DETAIL_LIST,
  GET_TARGET_GROUP_DETAIL_LIST_SUCCESS,
  SET_SUCCESS,
  SET_RESULT,
  SET_LOADING,
} from "./actionTypes"

const initialState = {
  loading: false,
  success: "",
  result: "",
  searchSettingsMyConnectedCase: {},
  myConnectedCaseListTotalPage: 1,
  myConnectedCaseListNoticeStr: "",
  myConnectedCaseList: [],
  isSearchMCC: false,
  searchSettings: {},
  myCaseManagementListTotalPage: 1,
  myCaseManagementListNoticeStr: "",
  myCaseManagementList: [],
  isSearch: false,
  userIrInfoClaimListTotalPage: 1,
  userIrInfoClaimList: [],
  targetGroupDetailList: [],
}

const MyCaseManagement = (state = initialState, action) => {
  switch (action.type) {
    case GET_MY_CONNECTED_CASE_SETTINGS:
      return {
        ...state,
      }
    case GET_MY_CONNECTED_CASE_SETTINGS_SUCCESS:
      return {
        ...state,
        searchSettingsMyConnectedCase: action.payload.searchSettings,
      }
    case SAVE_MY_CONNECTED_CASE_SETTINGS:
      return {
        ...state,
        loading: true,
      }
    case SAVE_MY_CONNECTED_CASE_SETTINGS_SUCCESS:
      return {
        ...state,
        loading: false,
        success: action.payload.result,
      }
    case GET_MY_CONNECTED_CASE_LIST:
      return {
        ...state,
        myConnectedCaseListTotalPage: 1,
        myConnectedCaseListNoticeStr: "",
        myConnectedCaseList: [],
        loading: true,
        isSearchMCC: false,
      }
    case GET_MY_CONNECTED_CASE_LIST_SUCCESS:
      return {
        ...state,
        myConnectedCaseListTotalPage: action.payload.totalPage,
        myConnectedCaseListNoticeStr: action.payload.noticeStr,
        myConnectedCaseList: action.payload.myCaseManagementList,
        loading: false,
        isSearchMCC: true,
      }
    case CHANGE_MY_CONNECTED_CASE_LIST:
      return {
        ...state,
        myConnectedCaseList: action.payload,
      }
    case GET_MY_CASE_MANAGEMENT_SETTINGS:
      return {
        ...state,
      }
    case GET_MY_CASE_MANAGEMENT_SETTINGS_SUCCESS:
      return {
        ...state,
        searchSettings: action.payload.searchSettings,
      }
    case SAVE_MY_CASE_MANAGEMENT_SETTINGS:
      return {
        ...state,
        loading: true,
      }
    case SAVE_MY_CASE_MANAGEMENT_SETTINGS_SUCCESS:
      return {
        ...state,
        loading: false,
        success: action.payload.result,
      }
    case GET_MY_CASE_MANAGEMENT_LIST:
      return {
        ...state,
        myCaseManagementListTotalPage: 1,
        myCaseManagementListNoticeStr: "",
        myCaseManagementList: [],
        loading: true,
        isSearch: false,
      }
    case GET_MY_CASE_MANAGEMENT_LIST_SUCCESS:
      return {
        ...state,
        myCaseManagementListTotalPage: action.payload.totalPage,
        myCaseManagementListNoticeStr: action.payload.noticeStr,
        myCaseManagementList: action.payload.myCaseManagementList,
        loading: false,
        isSearch: true,
      }
    case CHANGE_MY_CASE_MANAGEMENT_LIST:
      return {
        ...state,
        myCaseManagementList: action.payload,
      }
    case DOWN_MY_CASE_MANAGEMENT_EXCEL:
      return {
        ...state,
        loading: true
      }
    case DOWN_MY_CASE_MANAGEMENT_EXCEL_SUCCESS:
      return {
        ...state,
        loading: false
      }
    case DOWN_MULTIPLE_MY_CASE_MANAGEMENT_EXCEL:
      return {
        ...state,
        loading: true
      }
    case DOWN_MULTIPLE_MY_CASE_MANAGEMENT_EXCEL_SUCCESS:
      return {
        ...state,
        loading: false
      }
    case DOWN_MY_CASE_MANAGEMENT_PDF:
      return {
        ...state,
        loading: true
      }
    case DOWN_MY_CASE_MANAGEMENT_PDF_SUCCESS:
      return {
        ...state,
        loading: false
      }
    case DOWN_MULTIPLE_MY_CASE_MANAGEMENT_PDF:
      return {
        ...state,
        loading: true
      }
    case DOWN_MULTIPLE_MY_CASE_MANAGEMENT_PDF_SUCCESS:
      return {
        ...state,
        loading: false
      }
    case GET_USER_IR_INFO_CLAIM_LIST:
      return {
        ...state,
        userIrInfoClaimListTotalPage: 1,
        userIrInfoClaimList: [],
        loading: true,
      }
    case GET_USER_IR_INFO_CLAIM_LIST_SUCCESS:
      return {
        ...state,
        userIrInfoClaimListTotalPage: action.payload.totalPage,
        userIrInfoClaimList: action.payload.userIrInfoClaimList,
        loading: false,
      }
    case CHANGE_USER_IR_INFO_CLAIM_LIST: 
      return {
        ...state,
        userIrInfoClaimList: action.payload,
      }
    case ADD_USER_IR_INFO_CLAIM:
      return {
        ...state,
        loading: true,
      }
    case ADD_USER_IR_INFO_CLAIM_SUCCESS:
      return {
        ...state,
        loading: false,
        success: action.payload.result,
      }
    case CONFIRM_USER_IR_INFO_CLAIM:
      return {
        ...state,
        loading: true,
      }
    case CONFIRM_USER_IR_INFO_CLAIM_SUCCESS:
      return {
        ...state,
        loading: false,
        success: action.payload.result,
      }
    case GET_TARGET_GROUP_DETAIL_LIST:
      return {
        ...state,
        targetGroupDetailList: [],
        loading: true,
      }
    case GET_TARGET_GROUP_DETAIL_LIST_SUCCESS:
      return {
        ...state,
        targetGroupDetailList: action.payload.targetGroupDetailList,
        loading: false,
      }
    case SET_SUCCESS:
      return {
        ...state,
        success: action.payload
      }
    case SET_RESULT:
      return {
        ...state,
        result: action.payload
      }
    case SET_LOADING:
      return {
        ...state,
        loading: action.payload
      }
    default:
      state = { ...state }
      break
  }
  return state
}

export default MyCaseManagement
