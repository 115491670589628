import { call, put, takeEvery, takeLatest } from "redux-saga/effects"

// MadridNotifier Redux States
import { 
  GET_SENDING_ANALYTICS_MN_SETTINGS,
  SAVE_SENDING_ANALYTICS_MN_SETTINGS,
  GET_SENDING_ANALYTICS_MN_LIST,
  DOWN_SENDING_ANALYTICS_MN_EXCEL,
  GET_SENDING_ANALYTICS_MN,
  GET_SENDING_ANALYTICS_FR_SETTINGS,
  SAVE_SENDING_ANALYTICS_FR_SETTINGS,
  GET_SENDING_ANALYTICS_FR_LIST,
  DOWN_SENDING_ANALYTICS_FR_EXCEL,
  GET_MADRID_SENDING_ANALYTICS_STATISTICS_SETTINGS,
  GET_MADRID_SENDING_ANALYTICS_STATISTICS,
  GET_FILER_SENDING_ANALYTICS_STATISTICS_SETTINGS,
  GET_FILER_SENDING_ANALYTICS_STATISTICS,
} from "./actionTypes"
import { 
  getSendingAnalyticsMnSettingsSuccess,
  saveSendingAnalyticsMnSettingsSuccess,
  getSendingAnalyticsMnListSuccess,
  downSendingAnalyticsMnExcelSuccess,
  getSendingAnalyticsMnSuccess,
  getSendingAnalyticsFrSettingsSuccess,
  saveSendingAnalyticsFrSettingsSuccess,
  getSendingAnalyticsFrListSuccess,
  downSendingAnalyticsFrExcelSuccess,
  getMadridSendingAnalyticsStatisticsSettingsSuccess,
  getMadridSendingAnalyticsStatisticsSuccess,
  getFilerSendingAnalyticsStatisticsSettingsSuccess,
  getFilerSendingAnalyticsStatisticsSuccess,
} from "./actions"

//Include Both Helper File with needed methods
import {
  getSendingAnalyticsMnSettings,
  saveSendingAnalyticsMnSettings,
  getSendingAnalyticsMnList,
  downSendingAnalyticsMnExcel,
  getSendingAnalyticsMn,
  getSendingAnalyticsFrSettings,
  saveSendingAnalyticsFrSettings,
  getSendingAnalyticsFrList,
  downSendingAnalyticsFrExcel,
  getMadridSendingAnalyticsStatistics,
  getFilerSendingAnalyticsStatistics,
} from "../../helpers/backend_helper"

function* onGetSendingAnalyticsMnSettings({ payload : data }) {
  try {
    const response = yield call(getSendingAnalyticsMnSettings, data);
    yield put(getSendingAnalyticsMnSettingsSuccess(response));
    
  } catch (error) {
    console.log('onGetSendingAnalyticsMnSettings error >> ' + error);
  }
}

function* onSaveSendingAnalyticsMnSettings({ payload : data }) {
  try {
    const response = yield call(saveSendingAnalyticsMnSettings, data);
    yield put(saveSendingAnalyticsMnSettingsSuccess(response));
    
  } catch (error) {
    console.log('onSaveSendingAnalyticsMnSettings error >> ' + error);
  }
}

function* onGetSendingAnalyticsMnList({ payload : data }) {
  try {
    const response = yield call(getSendingAnalyticsMnList, data);
    yield put(getSendingAnalyticsMnListSuccess(response));
    
  } catch (error) {
    console.log('onGetSendingAnalyticsMnList error >> ' + error);
  }
}

function* onDownSendingAnalyticsMnExcel({ payload : data}) {
  try {
    yield call(downSendingAnalyticsMnExcel, data);
    yield put(downSendingAnalyticsMnExcelSuccess());

  } catch (error) {
    console.log('onDownSendingAnalyticsMnExcel error >> ' + error);
  }
}

function* onGetSendingAnalyticsMn({ payload : data }) {
  try {
    const response = yield call(getSendingAnalyticsMn, data);
    yield put(getSendingAnalyticsMnSuccess(response));
    
  } catch (error) {
    console.log('onGetSendingAnalyticsMn error >> ' + error);
  }
}

function* onGetSendingAnalyticsFrSettings({ payload : data }) {
  try {
    const response = yield call(getSendingAnalyticsFrSettings, data);
    yield put(getSendingAnalyticsFrSettingsSuccess(response));
    
  } catch (error) {
    console.log('onGetSendingAnalyticsFrSettings error >> ' + error);
  }
}

function* onSaveSendingAnalyticsFrSettings({ payload : data }) {
  try {
    const response = yield call(saveSendingAnalyticsFrSettings, data);
    yield put(saveSendingAnalyticsFrSettingsSuccess(response));
    
  } catch (error) {
    console.log('onSaveSendingAnalyticsFrSettings error >> ' + error);
  }
}

function* onGetSendingAnalyticsFrList({ payload : data }) {
  try {
    const response = yield call(getSendingAnalyticsFrList, data);
    yield put(getSendingAnalyticsFrListSuccess(response));
    
  } catch (error) {
    console.log('onGetSendingAnalyticsFrList error >> ' + error);
  }
}

function* onDownSendingAnalyticsFrExcel({ payload : data}) {
  try {
    yield call(downSendingAnalyticsFrExcel, data);
    yield put(downSendingAnalyticsFrExcelSuccess());

  } catch (error) {
    console.log('onDownSendingAnalyticsFrExcel error >> ' + error);
  }
}

function* onGetMadridSendingAnalyticsStatisticsSettings({ payload : data }) {
  try {
    const response = yield call(getSendingAnalyticsMnSettings, data);
    yield put(getMadridSendingAnalyticsStatisticsSettingsSuccess(response));
    
  } catch (error) {
    console.log('onGetMadridSendingAnalyticsStatisticsSettings error >> ' + error);
  }
}

function* onGetMadridSendingAnalyticsStatistics({ payload : data }) {
  try {
    const response = yield call(getMadridSendingAnalyticsStatistics, data);
    yield put(getMadridSendingAnalyticsStatisticsSuccess(response));
    
  } catch (error) {
    console.log('onGetMadridSendingAnalyticsStatistics error >> ' + error);
  }
}

function* onGetFilerSendingAnalyticsStatisticsSettings({ payload : data }) {
  try {
    const response = yield call(getSendingAnalyticsFrSettings, data);
    yield put(getFilerSendingAnalyticsStatisticsSettingsSuccess(response));
    
  } catch (error) {
    console.log('onGetFilerSendingAnalyticsStatisticsSettings error >> ' + error);
  }
}

function* onGetFilerSendingAnalyticsStatistics({ payload : data }) {
  try {
    const response = yield call(getFilerSendingAnalyticsStatistics, data);
    yield put(getFilerSendingAnalyticsStatisticsSuccess(response));
    
  } catch (error) {
    console.log('onGetFilerSendingAnalyticsStatistics error >> ' + error);
  }
}

function* SendingAnalyticsSaga() {
  yield takeLatest(GET_SENDING_ANALYTICS_MN_SETTINGS, onGetSendingAnalyticsMnSettings);
  yield takeLatest(SAVE_SENDING_ANALYTICS_MN_SETTINGS, onSaveSendingAnalyticsMnSettings);
  yield takeLatest(GET_SENDING_ANALYTICS_MN_LIST, onGetSendingAnalyticsMnList);
  yield takeLatest(DOWN_SENDING_ANALYTICS_MN_EXCEL, onDownSendingAnalyticsMnExcel);
  yield takeLatest(GET_SENDING_ANALYTICS_MN, onGetSendingAnalyticsMn);
  yield takeLatest(GET_SENDING_ANALYTICS_FR_SETTINGS, onGetSendingAnalyticsFrSettings);
  yield takeLatest(SAVE_SENDING_ANALYTICS_FR_SETTINGS, onSaveSendingAnalyticsFrSettings);
  yield takeLatest(GET_SENDING_ANALYTICS_FR_LIST, onGetSendingAnalyticsFrList);
  yield takeLatest(DOWN_SENDING_ANALYTICS_FR_EXCEL, onDownSendingAnalyticsFrExcel);
  yield takeLatest(GET_MADRID_SENDING_ANALYTICS_STATISTICS_SETTINGS, onGetMadridSendingAnalyticsStatisticsSettings);
  yield takeLatest(GET_MADRID_SENDING_ANALYTICS_STATISTICS, onGetMadridSendingAnalyticsStatistics);
  yield takeLatest(GET_FILER_SENDING_ANALYTICS_STATISTICS_SETTINGS, onGetFilerSendingAnalyticsStatisticsSettings);
  yield takeLatest(GET_FILER_SENDING_ANALYTICS_STATISTICS, onGetFilerSendingAnalyticsStatistics);
}

export default SendingAnalyticsSaga

