import {
  GET_SETTINGS_INFO,
  GET_SETTINGS_INFO_SUCCESS,
  UPDATE_ACCOUNT_INFO,
  UPDATE_ACCOUNT_INFO_SUCCESS,
  UPDATE_ACCOUNT_PASSWORD,
  UPDATE_ACCOUNT_INVOICE_INFO,
  UPDATE_ACCOUNT_UNSUBSCRIBE_INFO,
  UPDATE_ACCOUNT_UPTODATE_INFO,
  GET_SETTINGS_EMAIL_TEMPLATES_SUCCESS,
  DELETE_EMAIL_TEMPLATE,
  DELETE_EMAIL_TEMPLATE_SUCCESS,
  UPDATE_SENDER_EMAIL,
  UPDATE_SENDER_EMAIL_SUCCESS,
  UPDATE_SUBSCRIPTION_LIST,
  UPDATE_SUBSCRIPTION_LIST_SUCCESS,
  SET_REDUCER_VALUE,
  GET_USER_SIGNATURE_SAVE_POPUP_APPEAR_INFO_SUCCESS,
  GET_USER_SIGNATURE_INFO,
  GET_USER_SIGNATURE_INFO_SUCCESS,
  UPDATE_USER_SIGNATURE_INFO,
  UPDATE_USER_SIGNATURE_INFO_SUCCESS,

  GET_EMAILING_SETTINGS,
  GET_EMAILING_SETTINGS_SUCCESS,
  ADD_SENDER_EMAIL,
  ADD_SENDER_EMAIL_SUCCESS,
  DELETE_SENDER_EMAIL,
  DELETE_SENDER_EMAIL_SUCCESS,
  GET_USER_SIGNATURE,
  GET_USER_SIGNATURE_SUCCESS,
  ADD_USER_SIGNATURE,
  ADD_USER_SIGNATURE_SUCCESS,
  UPDATE_USER_SIGNATURE,
  UPDATE_USER_SIGNATURE_SUCCESS,
  DELETE_USER_SIGNATURE,
  DELETE_USER_SIGNATURE_SUCCESS,
  ADD_USER_JURISDICTION_EMAIL_SETTING,
  ADD_USER_JURISDICTION_EMAIL_SETTING_SUCCESS,
  UPDATE_USER_JURISDICTION_EMAIL_SETTING,
  UPDATE_USER_JURISDICTION_EMAIL_SETTING_SUCCESS,
  DELETE_USER_JURISDICTION_EMAIL_SETTING,
  DELETE_USER_JURISDICTION_EMAIL_SETTING_SUCCESS,
  UPDATE_DISPLAY_NAME_SUCCESS,
  UPDATE_SENDER_EMAIL_ADDRESS_SUCCESS,

  GET_OCMN_SHOW_STARTUP,
  GET_OCMN_SHOW_STARTUP_SUCCESS,
  SAVE_OCMN_SETTINGS_SUCCESS,

  ADD_USER_INVOICE_INFO,
  ADD_USER_INVOICE_INFO_SUCCESS,
  MODIFY_USER_INVOICE_INFO,
  MODIFY_USER_INVOICE_INFO_SUCCESS,
  REMOVE_USER_INVOICE_INFO,
  REMOVE_USER_INVOICE_INFO_SUCCESS,
  GET_USER_SUBSCRIPTION_LIST,
  GET_USER_SUBSCRIPTION_LIST_SUCCESS,
  MODIFY_USER_SUBSCRIPTION_INVOICE_INFO,
  MODIFY_USER_SUBSCRIPTION_INVOICE_INFO_SUCCESS,
} from "./actionTypes"

const initialState = {
  accountInfo: {},
  emailTemplates: [],
  subscriptionDetails: [],
  filerSettings: {},
  userSignatureSavePopupAppearInfo: {},
  userSignatureInfo: {},
  getUserSignatureInfoFlag: false,
  loading: false,
  success: "",
  errorMsg: "",
  updateToast: false,
  updateToastType: "",
  closeEditorFlag: false,
  modifiedSignatureFlag: false,
  modifiedSignatureNameFlag: false,

  userSendEmailAddressList: [],
  userSignatureList: [],
  userSignature: {},
  userJurisdictionEmailSettingList: [],
  updateUserJurisdictionEmailSettingInfo: {},
  userSignatureMenuItems: "",

  userOneClickMnSetting:{},
  userSubscriptionList: [],
}

const Settings = (state = initialState, action) => {
  switch (action.type) {
    case GET_SETTINGS_INFO:
      state = {
        ...state,
        loading: true,
      }
      break
    case GET_SETTINGS_INFO_SUCCESS:
      state =  {
        ...state,
        accountInfo: action.payload.accountInfo,
        // subscriptionDetails: action.payload.subscriptionDetails,
        // filerSettings: action.payload.filerSettings,
        // userSignatureInfo: action.payload.userSignatureInfo,
        loading: false,
      }
      break
    case GET_SETTINGS_EMAIL_TEMPLATES_SUCCESS:
      state =  {
        ...state,
        emailTemplates: action.payload.emailTemplates,
      }
      break
    case UPDATE_ACCOUNT_INFO:
      state = {
        ...state,
        loading: true,
      }
      break
    case UPDATE_ACCOUNT_INFO_SUCCESS:
      // console.log('action.payload.accountInfo : ' + JSON.stringify(action.payload.accountInfo));

      state = {
        ...state,
        loading: false,
        success: action.payload.ret,
        accountInfo: action.payload.accountInfo,
      }
      break
    case UPDATE_ACCOUNT_PASSWORD:
      state = {
        ...state,
        loading: true,
      }
      break
    case UPDATE_ACCOUNT_INVOICE_INFO:
      state = {
        ...state,
        loading: true,
      }
      break
    case UPDATE_ACCOUNT_UNSUBSCRIBE_INFO:
      state = {
        ...state,
        loading: true,
      }
      break
    case UPDATE_ACCOUNT_UPTODATE_INFO:
      state = {
        ...state,
        loading: true,
      }
      break
    case DELETE_EMAIL_TEMPLATE:
      state = {
        ...state,
        loading: true,
      }
      break
    case DELETE_EMAIL_TEMPLATE_SUCCESS:
      state =  {
        ...state,
        emailTemplates: action.payload.emailTemplates,
        loading: false,
      }
      break
    case UPDATE_SENDER_EMAIL:
      state = {
        ...state,
        loading: true,
      }
      break
    case UPDATE_SENDER_EMAIL_SUCCESS:
      state =  {
        ...state,
        subscriptionDetails: action.payload.subscriptionDetails,
        filerSettings: action.payload.filerSettings,
        loading: false,
      }
      break
    case UPDATE_SUBSCRIPTION_LIST:
      state = {
        ...state,
        // subscriptionDetails: action.payload.emailTemplates,
        loading: true,
      }
      break
    case UPDATE_SUBSCRIPTION_LIST_SUCCESS:
      state = {
        ...state,
        // subscriptionDetails: action.payload.emailTemplates,
        loading: false,
        success: action.payload.ret,
      }
      break
    case GET_USER_SIGNATURE_SAVE_POPUP_APPEAR_INFO_SUCCESS:
      state = {
        ...state,
        loading: false,
        userSignatureSavePopupAppearInfo: action.payload.userSignatureSavePopupAppearInfo,
      }
      break
    case GET_USER_SIGNATURE_INFO:
      state = {
        ...state,
        loading: true,
      }
      break
    case GET_USER_SIGNATURE_INFO_SUCCESS:
      state =  {
        ...state,
        userSignatureInfo: action.payload.userSignatureInfo,
        getUserSignatureInfoFlag: true,
        loading: false,
      }
      break
    case UPDATE_USER_SIGNATURE_INFO:
      state = {
        ...state,
        loading: true,
      }
      break
    case UPDATE_USER_SIGNATURE_INFO_SUCCESS:
      state = {
        ...state,
        loading: false,
        success: action.payload.ret,
      }
      break

    case GET_EMAILING_SETTINGS:
      state = {
        ...state,
        loading: true,
      }
      break
    case GET_EMAILING_SETTINGS_SUCCESS:

      let newMenuItems = '';
      action.payload.userSignatureList.forEach((userSignature, i) => {
        // console.log('userSignature' + (i+1) + ' add newMenuItems');
        newMenuItems += 'userSignature' + (i+1) + ' ';
      });
      // console.log('newMenuItems : ' + newMenuItems);
      
      state = {
        ...state,
        loading: false,
        accountInfo: action.payload.accountInfo,
        userSendEmailAddressList: action.payload.userSendEmailAddressList,
        userSignatureList: action.payload.userSignatureList,
        userJurisdictionEmailSettingList: action.payload.userJurisdictionEmailSettingList,
        userSignatureMenuItems: newMenuItems,
      }
      break
    case ADD_SENDER_EMAIL:
      state = {
        ...state,
        loading: true,
      }
      break
    case ADD_SENDER_EMAIL_SUCCESS:
      state = {
        ...state,
        loading: false,
        success: action.payload.ret,
        userSendEmailAddressList: action.payload.userSendEmailAddressList,
      }
      break
    case DELETE_SENDER_EMAIL:
      state = {
        ...state,
        loading: true,
      }
      break
    case DELETE_SENDER_EMAIL_SUCCESS:
      state = {
        ...state,
        loading: false,
        success: action.payload.ret,
        userSendEmailAddressList: action.payload.userSendEmailAddressList,
      }
      break
    // case UPDATE_DISPLAY_NAME:
    //   state = {
    //     ...state,
    //     // loading: true,
    //     // updateToast: false,
    //   }
    //   break
    case UPDATE_DISPLAY_NAME_SUCCESS:
      state = {
        ...state,
        // loading: false,
        updateToast: true,
        updateToastType: "DISPLAY_NAME",
        userSendEmailAddressList: action.payload.userSendEmailAddressList,
      }
      break
    case UPDATE_SENDER_EMAIL_ADDRESS_SUCCESS:
      state = {
        ...state,
        // loading: false,
        updateToast: true,
        updateToastType: "EMAIL",
        userSendEmailAddressList: action.payload.userSendEmailAddressList,
      }
      break
    case GET_USER_SIGNATURE:
      state = {
        ...state,
        loading: true,
      }
      break
    case GET_USER_SIGNATURE_SUCCESS:
      let newMenuItems2 = '';
      action.payload.userSignatureList.forEach((userSignature, i) => {
        // console.log('userSignature' + (i+1) + ' add newMenuItems');
        newMenuItems2 += 'userSignature' + (i+1) + ' ';
      });

      state = {
        ...state,
        loading: false,
        userSignatureList: action.payload.userSignatureList,
        userSignatureMenuItems: newMenuItems2,
      }
      break
    case ADD_USER_SIGNATURE:
      state = {
        ...state,
        loading: true,
      }
      break
    case ADD_USER_SIGNATURE_SUCCESS:
      let newMenuItems4 = '';
      action.payload.userSignatureList.forEach((userSignature, i) => {
        // console.log('userSignature' + (i+1) + ' add newMenuItems');
        newMenuItems4 += 'userSignature' + (i+1) + ' ';
      });

      state = {
        ...state,
        loading: false,
        closeEditorFlag: true,
        modifiedSignatureFlag: true,
        success: action.payload.ret,
        userSignatureList: action.payload.userSignatureList,
        userSignatureMenuItems: newMenuItems4,
      }
      break
    case UPDATE_USER_SIGNATURE:
      state = {
        ...state,
        loading: true,
      }
      break
    case UPDATE_USER_SIGNATURE_SUCCESS:
      let newMenuItems5 = '';
      action.payload.userSignatureList.forEach((userSignature, i) => {
        // console.log('userSignature' + (i+1) + ' add newMenuItems');
        newMenuItems5 += 'userSignature' + (i+1) + ' ';
      });

      let _modifiedSignatureNameFlag = state.modifiedSignatureNameFlag;

      state = {
        ...state,
        loading: false,
        closeEditorFlag: true,
        modifiedSignatureFlag: _modifiedSignatureNameFlag,
        modifiedSignatureNameFlag: false,
        success: action.payload.ret,
        userSignatureList: action.payload.userSignatureList,
        userSignatureMenuItems: newMenuItems5,
      }
      break
    case DELETE_USER_SIGNATURE:
      state = {
        ...state,
        loading: true,
      }
      break
    case DELETE_USER_SIGNATURE_SUCCESS:
      let newMenuItems3 = '';
      action.payload.userSignatureList.forEach((userSignature, i) => {
        // console.log('userSignature' + (i+1) + ' add newMenuItems');
        newMenuItems3 += 'userSignature' + (i+1) + ' ';
      });

      state = {
        ...state,
        loading: false,
        closeEditorFlag: true,
        modifiedSignatureFlag: true,
        success: action.payload.ret,
        userSignatureList: action.payload.userSignatureList,
        userSignatureMenuItems: newMenuItems3,
      }
      break
    case ADD_USER_JURISDICTION_EMAIL_SETTING:
      state = {
        ...state,
        loading: true,
      }
      break
    case ADD_USER_JURISDICTION_EMAIL_SETTING_SUCCESS:
      state = {
        ...state,
        loading: false,
        success: action.payload.ret,
        userJurisdictionEmailSettingList: action.payload.userJurisdictionEmailSettingList,
      }
      break
    case UPDATE_USER_JURISDICTION_EMAIL_SETTING:
      state = {
        ...state,
        loading: true,
      }
      break
    case UPDATE_USER_JURISDICTION_EMAIL_SETTING_SUCCESS:
      state = {
        ...state,
        loading: false,
        success: action.payload.ret,
        userJurisdictionEmailSettingList: action.payload.userJurisdictionEmailSettingList,
        updateUserJurisdictionEmailSettingInfo: {},
      }
      break
    case DELETE_USER_JURISDICTION_EMAIL_SETTING:
      state = {
        ...state,
        loading: true,
      }
      break
    case DELETE_USER_JURISDICTION_EMAIL_SETTING_SUCCESS:
      state = {
        ...state,
        loading: false,
        success: action.payload.ret,
        userJurisdictionEmailSettingList: action.payload.userJurisdictionEmailSettingList,
        updateUserJurisdictionEmailSettingInfo: {},
      }
      break
    case GET_OCMN_SHOW_STARTUP:
      state = {
        ...state,
        loading: true,
      }
      break
    case GET_OCMN_SHOW_STARTUP_SUCCESS:
      // console.log('userOneClickMnSetting payload : ' + JSON.stringify(action.payload.userOneClickMnSetting))
      state = {
        ...state,
        loading: false,
        userOneClickMnSetting: action.payload.userOneClickMnSetting,
      }
      break
    case SAVE_OCMN_SETTINGS_SUCCESS:
      state = {
        ...state,
        loading: false,
        success: action.payload.result,
      }
      break
    case ADD_USER_INVOICE_INFO:
      state = {
        ...state,
        loading: true,
      }
      break
    case ADD_USER_INVOICE_INFO_SUCCESS:
      return {
        ...state,
        loading: false,
        success: action.payload,
      }
    case MODIFY_USER_INVOICE_INFO:
      state = {
        ...state,
        loading: true,
      }
      break
    case MODIFY_USER_INVOICE_INFO_SUCCESS:
      return {
        ...state,
        loading: false,
        success: action.payload,
      }
    case REMOVE_USER_INVOICE_INFO:
      state = {
        ...state,
        loading: true,
      }
      break
    case REMOVE_USER_INVOICE_INFO_SUCCESS:
      return {
        ...state,
        loading: false,
        success: action.payload,
      }
    case GET_USER_SUBSCRIPTION_LIST:
      state = {
        ...state,
        loading: true,
        userSubscriptionList: [],
      }
      break
    case GET_USER_SUBSCRIPTION_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        userSubscriptionList: action.payload.userSubscriptionList,
      }
    case MODIFY_USER_SUBSCRIPTION_INVOICE_INFO:
      state = {
        ...state,
        loading: true,
      }
      break
    case MODIFY_USER_SUBSCRIPTION_INVOICE_INFO_SUCCESS:
      return {
        ...state,
        loading: false,
        success: action.payload.ret,
        userSubscriptionList: action.payload.userSubscriptionList,
      }
    case SET_REDUCER_VALUE:
        // console.log('SET_REDUCER_VALUE payload : ' + JSON.stringify(action.payload))
        // console.log('SET_REDUCER_VALUE payload.type : ' + action.payload.type)
        // console.log('SET_REDUCER_VALUE payload.data : ' + JSON.stringify(action.payload.data))
        if (action.payload.type === 'ACCOUNT_INFO') {
          state = {
            ...state,
            accountInfo: action.payload.data,
            loading: false,
          }
        } else if (action.payload.type === 'EMAIL_TEMPLATES') {
          state = {
            ...state,
            emailTemplates: action.payload.data
          }
        } else if (action.payload.type === 'SUBSCRIPTION_DETAILS') {
          state = {
            ...state,
            subscriptionDetails: action.payload.data,
          }
        } else if (action.payload.type === 'FILER_SETTINGS') {
          state = {
            ...state,
            filerSettings: action.payload.data
          }
        } else if (action.payload.type === 'SUCCESS') {
          state = {
            ...state,
            success: action.payload.data
          }
        } else if (action.payload.type === 'USER_SIGNATURE') {
          state = {
            ...state,
            userSignature: action.payload.data
          }
        } else if (action.payload.type === 'USER_JURISDICTION_EMAIL_SETTING_LIST') {
          state = {
            ...state,
            userJurisdictionEmailSettingList: action.payload.data
          }
        } else if (action.payload.type === 'UPDATE_USER_JURISDICTION_EMAIL_SETTING_INFO') {
          state = {
            ...state,
            updateUserJurisdictionEmailSettingInfo: action.payload.data
          }
        } else if (action.payload.type === 'GET_USER_SIGNATURE_INFO_FLAG') {
          state = {
            ...state,
            getUserSignatureInfoFlag: action.payload.data
          }
        } else if (action.payload.type === 'LOADING_FLAG') {
          state = {
            ...state,
            loading: action.payload.data
          }
        } else if (action.payload.type === 'ERROR_MSG') {
          state = {
            ...state,
            loading: false,
            errorMsg: action.payload.data
          }
        } else if (action.payload.type === 'USER_SENDEMAIL_ADDRESS_LIST') {
          state = {
            ...state,
            userSendEmailAddressList: action.payload.data
          } 
        } else if (action.payload.type === 'UPDATE_TOAST') {
          state = {
            ...state,
            updateToast: action.payload.data
          }
        } else if (action.payload.type === 'CLOSE_EDITOR_FLAG') {
          state = {
            ...state,
            closeEditorFlag: action.payload.data
          }
        } else if (action.payload.type === 'MODIFIED_SIGNATURE_FLAG') {
          state = {
            ...state,
            modifiedSignatureFlag: action.payload.data
          }
        } else if (action.payload.type === 'MODIFIED_SIGNATURE_NAME_FLAG') {
          state = {
            ...state,
            modifiedSignatureNameFlag: action.payload.data
          }
        }
        break
      
    default:
      state = { ...state }
      break
  }
  return state
}

export default Settings
