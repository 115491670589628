export const GET_EMAIL_TEMPLATES = "GET_EMAIL_TEMPLATES"
export const GET_EMAIL_TEMPLATES_SUCCESS = "GET_EMAIL_TEMPLATES_SUCCESS"
export const GET_EMAILFORM = "GET_EMAILFORM"
export const GET_EMAILFORM_SUCCESS = "GET_EMAILFORM_SUCCESS"
export const UPDATE_EMAILFORM = "UPDATE_EMAILFORM"
export const CHANGE_UPLOAD_RATE = "CHANGE_UPLOAD_RATE"
export const UPDATE_EMAILFORM_SUCCESS = "UPDATE_EMAILFORM_SUCCESS"
export const UPDATE_EMAILFORM_ERROR = "UPDATE_EMAILFORM_ERROR"
export const DELETE_EMAILFORM = "DELETE_EMAILFORM"
export const DELETE_EMAILFORM_SUCCESS = "DELETE_EMAILFORM_SUCCESS"
export const SET_REDUCER_VALUE = "SET_REDUCER_VALUE"