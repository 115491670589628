export const GET_SERVICE_MANUAL_LIST = "GET_SERVICE_MANUAL_LIST"
export const GET_SERVICE_MANUAL_LIST_SUCCESS = "GET_SERVICE_MANUAL_LIST_SUCCESS"
export const GET_SERVICE_MANUAL_INFO = "GET_SERVICE_MANUAL_INFO"
export const GET_SERVICE_MANUAL_INFO_SUCCESS = "GET_SERVICE_MANUAL_INFO_SUCCESS"
export const UPDATE_SERVICE_MANUAL = "UPDATE_SERVICE_MANUAL"
export const UPDATE_SERVICE_MANUAL_SUCCESS = "UPDATE_SERVICE_MANUAL_SUCCESS"
export const UPDATE_SERVICE_MANUAL_VIEW_CNT = "UPDATE_SERVICE_MANUAL_VIEW_CNT"
export const UPDATE_SERVICE_MANUAL_VIEW_CNT_SUCCESS = "UPDATE_SERVICE_MANUAL_VIEW_CNT_SUCCESS"
export const UPDATE_SERVICE_MANUAL_PRIORITY = "UPDATE_SERVICE_MANUAL_PRIORITY"
export const UPDATE_SERVICE_MANUAL_PRIORITY_SUCCESS = "UPDATE_SERVICE_MANUAL_PRIORITY_SUCCESS"
export const UPDATE_SERVICE_MANUAL_PDF = "UPDATE_SERVICE_MANUAL_PDF"
export const UPDATE_SERVICE_MANUAL_PDF_SUCCESS = "UPDATE_SERVICE_MANUAL_PDF_SUCCESS"
export const CHANGE_SERVICE_MANUAL_LIST = "CHANGE_SERVICE_MANUAL_LIST"
export const CHANGE_SERVICE_MANUAL_INFO = "CHANGE_SERVICE_MANUAL_INFO"
export const CHANGE_SERVICE_MANUAL_RESULT = "CHANGE_SERVICE_MANUAL_RESULT"
export const CHANGE_SERVICE_MANUAL_LOADING = "CHANGE_SERVICE_MANUAL_LOADING"
export const CHANGE_IS_RELOAD = "CHANGE_IS_RELOAD"
export const RESET_IDX = "RESET_IDX"
export const GET_PREVIEW_SERVICE_MANUAL = "GET_PREVIEW_SERVICE_MANUAL"
export const GET_PREVIEW_SERVICE_MANUAL_SUCCESS = "GET_PREVIEW_SERVICE_MANUAL_SUCCESS"
export const SET_PREVIEW_SERVICE_MANUAL = "SET_PREVIEW_SERVICE_MANUAL"
export const GET_SERVICE_MANUAL_PAGE_NAMELIST = "GET_SERVICE_MANUAL_PAGE_NAMELIST"
export const GET_SERVICE_MANUAL_PAGE_NAMELIST_SUCCESS = "GET_SERVICE_MANUAL_PAGE_NAMELIST_SUCCESS"