import { call, put, takeEvery, takeLatest } from "redux-saga/effects"

// MadridNotifier Redux States
import { 
  GET_DOMAIN_LIST,
  GET_DOMAIN_ACTION_HISTORY,
  SAVE_DOMAIN_INFO,
  GET_BLACK_LIST,
  ADD_BLACK_DOMAIN,
  DOWN_EXCEL_BLACK_LIST,
  GET_ENTITY_DETAIL,
  SAVE_ENTITY_EMAILS,
  DELETE_ENTITY_EMAILS,
} from "./actionTypes"
import { 
  getDomainListSuccess,
  getDomainActionHistorySuccess,
  saveDomainInfoSuccess,
  getBlackListSuccess,
  addBlackListSuccess,
  downExcelBlackListSuccess,
  getEntityDetailSuccess,
  saveEntityEmailsSuccess,
  deleteEntityEmailsSuccess,
} from "./actions"

//Include Both Helper File with needed methods
import {
  getDomainList,
  getDomainActionHistory,
  saveDomainInfo,
  getBlackList,
  addBlackList,
  downExcelBlackList,
  getEntityDetail,
  saveEntityEmails,
  deleteEntityEmails,
} from "../../helpers/backend_helper"

function* onGetDomainList({ payload : data }) {
  try {
    const response = yield call(getDomainList, data);
    yield put(getDomainListSuccess(response));
    
  } catch (error) {
    console.log('onGetDomainList error >> ' + error);
  }
}

function* onGetDomainActionHistory({ payload : data }) {
  try {
    const response = yield call(getDomainActionHistory, data);
    yield put(getDomainActionHistorySuccess(response));
    
  } catch (error) {
    console.log('onGetDomainActionList error >> ' + error);
  }
}

function* onSaveDomainInfo({ payload : data }) {
  try {
    const response = yield call(saveDomainInfo, data);
    yield put(saveDomainInfoSuccess(response));
    
  } catch (error) {
    console.log('onSaveDomainInfo error >> ' + error);
  }
}

function* onGetBlackList({ payload : data }) {
  try {
    const response = yield call(getBlackList, data);
    yield put(getBlackListSuccess(response));
    
  } catch (error) {
    console.log('onGetBlackList error >> ' + error);
  }
}

function* onAddBlackList({ payload : data }) {
  try {
    const response = yield call(addBlackList, data);
    yield put(addBlackListSuccess(response));
    
  } catch (error) {
    console.log('onAddBlackList error >> ' + error);
  }
}


function* onDownExcelBlackList({ payload : data}) {
  try {
    yield call(downExcelBlackList, data);
    yield put(downExcelBlackListSuccess());

  } catch (error) {
    console.log('onDownExcelBlackList error >> ' + error);
  }
}

function* onGetEntityDetail({ payload : data }) {
  try {
    const response = yield call(getEntityDetail, data);
    yield put(getEntityDetailSuccess(response));
    
  } catch (error) {
    console.log('getEntityDetail error >> ' + error);
  }
}

function* onSaveEntityEmails({ payload: data }) {
  try {
    const response = yield call(saveEntityEmails, data);
    yield put(saveEntityEmailsSuccess(response));
    
  } catch (error) {
    console.log('onSaveEntityEmails error >> ' + error);
  }
}

function* onDeleteEntityEmails({ payload: data }) {
  try {
    const response = yield call(deleteEntityEmails, data);
    yield put(deleteEntityEmailsSuccess(response));
    
  } catch (error) {
    console.log('onDeleteEntityEmails error >> ' + error);
  }
}

function* AdmDomainManagementSaga() {
  yield takeLatest(GET_DOMAIN_LIST, onGetDomainList);
  yield takeLatest(GET_DOMAIN_ACTION_HISTORY, onGetDomainActionHistory);
  yield takeLatest(SAVE_DOMAIN_INFO, onSaveDomainInfo);
  
  yield takeLatest(GET_BLACK_LIST, onGetBlackList);
  yield takeLatest(ADD_BLACK_DOMAIN, onAddBlackList);
  yield takeLatest(DOWN_EXCEL_BLACK_LIST, onDownExcelBlackList);

  yield takeLatest(GET_ENTITY_DETAIL, onGetEntityDetail);
  yield takeLatest(SAVE_ENTITY_EMAILS, onSaveEntityEmails);
  yield takeLatest(DELETE_ENTITY_EMAILS, onDeleteEntityEmails);
}

export default AdmDomainManagementSaga

