import {
  GET_USER_UNLOCK_INFO,
  GET_USER_UNLOCK_INFO_SUCCESS,
} from "./actionTypes"

export const getUserUnlockInfo = () => ({
    type: GET_USER_UNLOCK_INFO,
})

export const getUserUnlockInfoSuccess = data => {
  return {
    type: GET_USER_UNLOCK_INFO_SUCCESS,
    payload: data,
  }
}