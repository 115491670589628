import { call, put, takeEvery, takeLatest } from "redux-saga/effects"

// Login Redux States
import { 
  GET_COUNTRY_RANKINGS_LIST,
  DOWN_COUNTRY_RANKINGS_EXCEL,
  DOWN_COUNTRY_RANKINGS_PDF,
} from "./actionTypes"
import { 
  getCountryRankingsListSuccess,
  downCountryRankingsExcelSuccess,
  downCountryRankingsPdfSuccess,
} from "./actions"

//Include Both Helper File with needed methods
import {
  getCountryRankingsList,
  downCountryRankingsExcel,
  downCountryRankingsPdf,
} from "../../helpers/backend_helper"

function* onGetCountryRankingsList({ payload: data }) {
  try {
    const response = yield call(getCountryRankingsList, data);
    yield put(getCountryRankingsListSuccess(response));
    
  } catch (error) {
    console.log('onGetCountryRankingsList error >> ' + error);
    //yield put(apiError(error))
  }
}

function* onDownCountryRankingsExcel({ payload: data }) {
  try {
    yield call(downCountryRankingsExcel, data);
    yield put(downCountryRankingsExcelSuccess());

  } catch (error) {
    console.log('onDownCountryRankingsExcel error >> ' + error);
    //yield put(apiError(error))
  }
}

function* onDownCountryRankingsPdf({ payload: data }) {
  try {
    yield call(downCountryRankingsPdf, data);
    yield put(downCountryRankingsPdfSuccess());

  } catch (error) {
    console.log('onDownCountryRankingsPdf error >> ' + error);
    //yield put(apiError(error))
  }
}

function* countryRankingsSaga() {
  yield takeLatest(GET_COUNTRY_RANKINGS_LIST, onGetCountryRankingsList);
  yield takeLatest(DOWN_COUNTRY_RANKINGS_EXCEL, onDownCountryRankingsExcel);
  yield takeLatest(DOWN_COUNTRY_RANKINGS_PDF, onDownCountryRankingsPdf);
  
}

export default countryRankingsSaga

