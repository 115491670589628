import React, {useState, useEffect} from 'react';
import { useSelector, useDispatch } from "react-redux";
import {
    Row,
    Col,
    Card,
    Media,
    Collapse,
    Button,
    UncontrolledTooltip,
  } from "reactstrap"
import SweetAlert from "react-bootstrap-sweetalert";
import { Link } from "react-router-dom";

import {
  setMyCaseManagementSuccess as onSetSuccess,
  setMyCaseManagementResult as onSetResult,
  setMyCaseManagementLoading as onSetLoading,
  getMyEntityList as onGetMyEntityList,
} from "../../store/actions";

import '../../components/Common/Common.css';
import LoadingModal from "../../components/Common/LoadingModal"
import SearchMyCase from './SearchMyCase';
import "toastr/build/toastr.min.css"

const MyCaseManagement = props => {
  const dispatch = useDispatch();

  const { loading, success, result, myEntityList } = useSelector(state => ({
    loading: state.MyCaseManagement.loading,
    success: state.MyCaseManagement.success,
    result: state.MyCaseManagement.result,
    myEntityList: state.EntityConnectManagement.myEntityList,
  }));

  const [isOpen, setIsOpen] = useState(true);
  const toggle = () => {
    setIsOpen(!isOpen);
  }

  const setMyCaseManagementLoading = () => {
    dispatch(onSetLoading(!loading));
  }

  useEffect(() => { 
    dispatch(onGetMyEntityList({}));
  }, []);

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Row>
            <Col lg={12}>
              <Card>
                <Link onClick={toggle} className="text-dark" to="#">
                  <div className="p-4">
                    <Media className="d-flex align-items-center">
                      <div className="flex-shrink-0 me-3">
                        <i className="uil uil-layer-group text-primary h2"></i>
                      </div>
                      <div className="flex-grow-1 overflow-hidden">
                        <h5 className="font-size-16 mb-1">My Case Management</h5>
                      </div>
                      <i className="uil uil-filter font-size-24 text-muted"></i>
                    </Media>
                  </div>
                </Link>
                <Collapse isOpen={isOpen}>
                  <div className="p-4 border-top">
                    {myEntityList && myEntityList.length !== 0 ? 
                    <>
                    <Row className="mb-3">
                      <label
                        className="form-label"
                        htmlFor="Representative"
                      >
                        What can you do here?
                      </label>
                    </Row>
                    <Row className="mb-3">
                      <label
                        className="form-label"
                        htmlFor="Representative"
                      >
                        Check my cases
                      </label>
                    </Row>
                    <Row className="mb-3">
                      <label
                        className="form-label"
                        htmlFor="Representative"
                      >
                        Disconnect cases that are not mine
                      </label>
                    </Row>
                    <Row className="mb-6">
                      <label
                        className="form-label"
                        htmlFor="Representative"
                      >
                        Add email addresses that will receive email notifications from other WeCrest members (main email by default, add up to three email addresses)
                      </label>
                    </Row>
                    <Row>
                      <label
                        className="form-label"
                        htmlFor="Representative"
                      >
                        You can add your contacts for the cases <a href='/accountSettings#contactsOfMyCases'>here</a>
                      </label>
                    </Row>
                    </>
                    :
                    <Row>
                      <label
                        className="form-label"
                        htmlFor="Representative"
                      >
                        Please connect with your entities first to manage your cases <a href='/entityConnect'>here</a>.
                      </label>
                    </Row>
                    }
                  </div>
                </Collapse>
              </Card>
            </Col>
          </Row>
          
          {myEntityList && myEntityList.length !== 0 ? 
          <SearchMyCase/>
          : null}

          {success? (
          <SweetAlert
            title={success}
            timeout={2000}
            showCloseButton={false}
            showConfirm={false}
            success
            onConfirm={() => {
              dispatch(onSetSuccess(""));
            }}
          ></SweetAlert>
          ) : null}

          {result? (
          <SweetAlert
            warning
            onConfirm={() => {
              dispatch(onSetResult(""));
            }}
            onCancel={() => {
              dispatch(onSetResult(""));
            }}
          >
            {result}
          </SweetAlert>
          ) : null}

          {
            loading ?
            <LoadingModal loading={loading} setLoading={setMyCaseManagementLoading}></LoadingModal>
            : null
          }
        </div>
      </div>
    </React.Fragment>
  )
}

export default MyCaseManagement;