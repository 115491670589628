import {
  SET_LOADING,
  SET_SUCCESS,
  SET_RESULT,
  SET_ERROR,
  CHANGE_ADM_ACCOUNT_MANAGEMENT_SEARCH_LIST,
  GET_ADM_ACCOUNT_MANAGEMENT_SEARCH_LIST,
  GET_ADM_ACCOUNT_MANAGEMENT_SEARCH_LIST_SUCCESS,
  DOWN_EXCEL_ADM_ACCOUNT_MANAGEMENT,
  DOWN_EXCEL_ADM_ACCOUNT_MANAGEMENT_SUCCESS,
  UPDATE_ACCOUNT_BY_ADMIN,
  UPDATE_ACCOUNT_BY_ADMIN_SUCCESS,
  UPDATE_PASSWORD_BY_ADMIN,
  UPDATE_PASSWORD_BY_ADMIN_SUCCESS,
  RESET_SMTP_BY_ADMIN,
  RESET_SMTP_BY_ADMIN_SUCCESS,
  VERIFY_ACCOUNT_BY_ADMIN,
  VERIFY_ACCOUNT_BY_ADMIN_SUCCESS,
  VIRTUAL_LOG_IN_BY_ADMIN,
  VIRTUAL_LOG_IN_BY_ADMIN_SUCCESS,
  CREATE_ACCOUNT_BY_ADMIN,
  CREATE_ACCOUNT_BY_ADMIN_SUCCESS,
  ADD_BILLING_PROFILE_BY_ADMIN,
  CHANGE_BILLING_PROFILE_BY_ADMIN,
  REMOVE_BILLING_PROFILE_BY_ADMIN,
} from "./actionTypes"

export const setAdmAccountManagementLoading = data => {
  return {
    type: SET_LOADING,
    payload: data,
  }
}

export const setAdmAccountManagementSearchSuccess = data => {
  return {
    type: SET_SUCCESS,
    payload: data,
  }
}

export const setAdmAccountManagementSearchResult = data => {
  return {
    type: SET_RESULT,
    payload: data,
  }
}

export const setAdmAccountManagementSearchError = data => {
  return {
    type: SET_ERROR,
    payload: data,
  }
}

export const changeAdmAccountManagementSearchList = data => {
  return {
    type: CHANGE_ADM_ACCOUNT_MANAGEMENT_SEARCH_LIST,
    payload: data,
  }
}

export const getAdmAccountManagementSearchList = data => {
  return {
    type: GET_ADM_ACCOUNT_MANAGEMENT_SEARCH_LIST,
    payload: data,
  }
}

export const getAdmAccountManagementSearchListSuccess = data => {
  return {
    type: GET_ADM_ACCOUNT_MANAGEMENT_SEARCH_LIST_SUCCESS,
    payload: data,
  }
}

export const downExcelAdmAccountManagement = (data, fileName) => {
  return {
    type: DOWN_EXCEL_ADM_ACCOUNT_MANAGEMENT,
    payload: {data, fileName},
  }
}

export const downExcelAdmAccountManagementSuccess = () => {
  return {
    type: DOWN_EXCEL_ADM_ACCOUNT_MANAGEMENT_SUCCESS,
  }
}

export const updateAccountByAdmin = (filterParam, updateParam) => {
  return {
    type: UPDATE_ACCOUNT_BY_ADMIN,
    payload: {filterParam, updateParam},
  }
}

export const updateAccountByAdminSuccess = data => {
  return {
    type: UPDATE_ACCOUNT_BY_ADMIN_SUCCESS,
    payload: data,
  }
}

export const updatePasswordByAdmin = (filterParam, updateParam) => {
  return {
    type: UPDATE_PASSWORD_BY_ADMIN,
    payload: {filterParam, updateParam},
  }
}

export const updatePasswordByAdminSuccess = data => {
  return {
    type: UPDATE_PASSWORD_BY_ADMIN_SUCCESS,
    payload: data,
  }
}

export const resetSmtpByAdmin = data => {
  return {
    type: RESET_SMTP_BY_ADMIN,
    payload: data,
  }
}

export const resetSmtpByAdminSuccess = data => {
  return {
    type: RESET_SMTP_BY_ADMIN_SUCCESS,
    payload: data,
  }
}

export const verifyAccountByAdmin = (filterParam, updateParam) => {
  return {
    type: VERIFY_ACCOUNT_BY_ADMIN,
    payload: {filterParam, updateParam},
  }
}

export const verifyAccountByAdminSuccess = data => {
  return {
    type: VERIFY_ACCOUNT_BY_ADMIN_SUCCESS,
    payload: data,
  }
}

export const virtualLogInByAdmin = data => {
  return {
    type: VIRTUAL_LOG_IN_BY_ADMIN,
    payload: data,
  }
}

export const virtualLogInByAdminSuccess = data => {
  return {
    type: VIRTUAL_LOG_IN_BY_ADMIN_SUCCESS,
    payload: data,
  }
}

export const createAccountByAdmin = formData => {
  return {
    type: CREATE_ACCOUNT_BY_ADMIN,
    payload: formData,
  }
}

export const createAccountByAdminSuccess = data => {
  return {
    type: CREATE_ACCOUNT_BY_ADMIN_SUCCESS,
    payload: data,
  }
}

export const addAdmAccountManagementBillingProfile = data => {
  return {
    type: ADD_BILLING_PROFILE_BY_ADMIN,
    payload: data,
  }
}

export const changeAdmAccountManagementBillingProfile = data => {
  return {
    type: CHANGE_BILLING_PROFILE_BY_ADMIN,
    payload: data,
  }
}

export const removeAdmAccountManagementBillingProfile = data => {
  return {
    type: REMOVE_BILLING_PROFILE_BY_ADMIN,
    payload: data,
  }
}