import { takeLatest, fork, put, all, call } from "redux-saga/effects"

//Account Redux states
import { 
  REGISTER_USER,
  GET_HOME_COUNTRY_LIST,
} from "./actionTypes"
import { 
  registerUserSuccessful, 
  registerUserFailed,
  getHomeCountryListSuccess
} from "./actions"

import {
  postWeCrestRegist,
  getHomeCountryList,
} from "../../../helpers/backend_helper"

//Include Both Helper File with needed methods
// import { getFirebaseBackend } from "../../../helpers/firebase_helper"
// import {
//   postFakeRegister,
//   postJwtRegister,
// } from "../../../helpers/fakebackend_helper"

// initialize relavant method of both Auth
// const fireBaseBackend = getFirebaseBackend()

// Is user register successfull then direct plot user in redux.
function* registerUser({ payload: { user, history } }) {
  try {
    // if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
    //   const response = yield call(
    //     fireBaseBackend.registerUser,
    //     user.email,
    //     user.password
    //   )
    //   yield put(registerUserSuccessful(response))
    // } else if (process.env.REACT_APP_DEFAULTAUTH === "jwt") {
    //   const response = yield call(postJwtRegister, "/post-jwt-register", user)
    //   console.log(response)
    //   yield put(registerUserSuccessful(response))
    // } else if (process.env.REACT_APP_DEFAULTAUTH === "fake") {
    //   const response = yield call(postFakeRegister, user)
    //   yield put(registerUserSuccessful(response))z
    // }

    const response = yield call(postWeCrestRegist, user);

    console.log('response => ' + response);

    history.push("/register-confirmation");

  } catch (error) {
    // console.log('error => ' + error);
    // console.log('registerUser Error : ' + error);
    yield put(registerUserFailed(error))
  }
}

function* onGetHomeCountryList() {
  try {
    const response = yield call(getHomeCountryList);
    yield put(getHomeCountryListSuccess(response));
    
  } catch (error) {
    console.log('getHomeCountryList error >> ' + error);
  }
}

export function* accountSaga() {
  yield takeLatest(REGISTER_USER, registerUser)
  yield takeLatest(GET_HOME_COUNTRY_LIST, onGetHomeCountryList)
}

export default accountSaga
