import {
  GET_COUNTRY_RANKINGS_LIST,
  GET_COUNTRY_RANKINGS_LIST_SUCCESS,
  DOWN_COUNTRY_RANKINGS_EXCEL,
  DOWN_COUNTRY_RANKINGS_EXCEL_SUCCESS,
  DOWN_COUNTRY_RANKINGS_PDF,
  DOWN_COUNTRY_RANKINGS_PDF_SUCCESS,
  CLEAR_COUNTRY_RANKINGS_LIST,
} from "./actionTypes"

const initialState = {
  error: "",
  loading: false,
  countryRankingsList: [],
  noticeStr: "",
}

const countryRankings = (state = initialState, action) => {
  switch (action.type) {
    case GET_COUNTRY_RANKINGS_LIST:
      return {
        ...state,
        loading: true,
        countryRankingsList: [],
      }
    case GET_COUNTRY_RANKINGS_LIST_SUCCESS:
      return {
        ...state,
        countryRankingsList: action.payload.rankingList,
        noticeStr: action.payload.noticeStr,
        loading: false
      }
    case DOWN_COUNTRY_RANKINGS_EXCEL:
      return {
        ...state,
        loading: true
      }
    case DOWN_COUNTRY_RANKINGS_EXCEL_SUCCESS:
      return {
        ...state,
        loading: false
      }
    case DOWN_COUNTRY_RANKINGS_PDF:
      return {
        ...state,
        loading: true
      }
    case DOWN_COUNTRY_RANKINGS_PDF_SUCCESS:
      return {
        ...state,
        loading: false
      }
    case CLEAR_COUNTRY_RANKINGS_LIST:
      return {
        ...state,
        countryRankingsList: []
      }
  }
  return state
}

export default countryRankings
