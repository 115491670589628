import {
  GET_CONNECTABLE_ENTITY_LIST,
  GET_CONNECTABLE_ENTITY_LIST_SUCCESS,
  GET_MY_ENTITY_LIST,
  GET_MY_ENTITY_LIST_SUCCESS,
  GET_MAIN_DOMAIN,
  GET_MAIN_DOMAIN_SUCCESS,
  GET_SEARCH_ENTITY_LIST,
  GET_SEARCH_ENTITY_LIST_SUCCESS,
  GET_USER_ENTITY_CONNECT_LIST,
  GET_USER_ENTITY_CONNECT_LIST_SUCCESS,
  DOWN_EXCEL_USER_ENTITY_CONNECT_LIST,
  DOWN_EXCEL_USER_ENTITY_CONNECT_LIST_SUCCESS,
  CHANGE_USER_ENTITY_CONNECT_LIST,
  CONFIRM_USER_ENTITY_CONNECT,
  CONFIRM_USER_ENTITY_CONNECT_SUCCESS,
  DISCONNECT_USER_ENTITY,
  DISCONNECT_USER_ENTITY_SUCCESS,
  GET_USER_ENTITY_CONNECT_CLAIM_LIST,
  GET_USER_ENTITY_CONNECT_CLAIM_LIST_SUCCESS,
  DOWN_EXCEL_USER_ENTITY_CONNECT_CLAIM_LIST,
  DOWN_EXCEL_USER_ENTITY_CONNECT_CLAIM_LIST_SUCCESS,
  ADD_USER_ENTITY_CONNECT_CLAIM,
  ADD_USER_ENTITY_CONNECT_CLAIM_SUCCESS,
  MODIFY_USER_ENTITY_CONNECT_CLAIM_STATUS,
  MODIFY_USER_ENTITY_CONNECT_CLAIM_STATUS_SUCCESS,
  CONFIRM_USER_ENTITY_CONNECT_CLAIM,
  CONFIRM_USER_ENTITY_CONNECT_CLAIM_SUCCESS,
  CHANGE_USER_ENTITY_CONNECT_CLAIM_LIST,
  SET_SUCCESS,
  SET_RESULT,
  SET_LOADING,
  GET_REQUESTER_LIST,
  GET_REQUESTER_LIST_SUCCESS,
  CHANGE_REQUESTER_LIST,
  GET_USER_DOMAIN_LIST,
  GET_USER_DOMAIN_LIST_SUCCESS,
  ADD_USER_DOMAIN,
  ADD_USER_DOMAIN_SUCCESS,
  MODIFY_USER_DOMAIN,
  MODIFY_USER_DOMAIN_SUCCESS,
  REMOVE_USER_DOMAIN,
  REMOVE_USER_DOMAIN_SUCCESS,
  CHANGE_USER_DOMAIN_LIST,
} from "./actionTypes"

const initialState = {
  loading: false,
  success: "",
  result: "",
  mainDomain: "",
  connectableEntityTotalPage: 1,
  connectableEntityList: [],
  myEntityTotalPage: 1,
  myEntityList: [],
  totalPage: 1,
  userEntityConnectList: [],
  isSearch: false,
  userEntityConnectClaimList: [],
  requesterList: [],
  userDomainList: [],
}

const EntityConnectManagement = (state = initialState, action) => {
  switch (action.type) {
    case GET_CONNECTABLE_ENTITY_LIST:
      return {
        ...state,
        connectableEntityTotalPage: 1,
        connectableEntityList: [],
      }
    case GET_CONNECTABLE_ENTITY_LIST_SUCCESS:
      return {
        ...state,
        connectableEntityTotalPage: action.payload.connectableEntityTotalPage,
        connectableEntityList: action.payload.connectableEntityList,
      }
    case GET_MY_ENTITY_LIST:
      return {
        ...state,
        myEntityTotalPage: 1,
        myEntityList: [],
      }
    case GET_MY_ENTITY_LIST_SUCCESS:
      return {
        ...state,
        myEntityTotalPage: action.payload.myEntityTotalPage,
        myEntityList: action.payload.myEntityList,
      }
    case GET_MAIN_DOMAIN:
      return {
        ...state,
        mainDomain: "",
      }
    case GET_MAIN_DOMAIN_SUCCESS:
      return {
        ...state,
        mainDomain: action.payload.mainDomain,
      }
    case GET_SEARCH_ENTITY_LIST:
      return {
        ...state,
        loading: true,
        userEntityConnectList: [],
        isSearch: false,
      }
    case GET_SEARCH_ENTITY_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        totalPage: action.payload.totalPage,
        userEntityConnectList: action.payload.userEntityConnectList,
        isSearch: true,
      }
    case GET_USER_ENTITY_CONNECT_LIST:
      return {
        ...state,
        loading: true,
        userEntityConnectList: [],
      }
    case GET_USER_ENTITY_CONNECT_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        totalPage: action.payload.totalPage,
        userEntityConnectList: action.payload.userEntityConnectList,
      }
    case DOWN_EXCEL_USER_ENTITY_CONNECT_LIST:
      return {
        ...state,
        loading: true
      }
    case DOWN_EXCEL_USER_ENTITY_CONNECT_LIST_SUCCESS:
      return {
        ...state,
        loading: false
      }
    case CHANGE_USER_ENTITY_CONNECT_LIST:
      return {
        ...state,
        userEntityConnectList: action.payload,
      }
    case CONFIRM_USER_ENTITY_CONNECT:
      return {
        ...state,
        loading: true,
      }
    case CONFIRM_USER_ENTITY_CONNECT_SUCCESS:
      return {
        ...state,
        loading: false,
        success: action.payload.result,
      }
    case DISCONNECT_USER_ENTITY:
      return {
        ...state,
        loading: true,
      }
    case DISCONNECT_USER_ENTITY_SUCCESS:
      return {
        ...state,
        loading: false,
        success: action.payload.result,
      }
    case GET_USER_ENTITY_CONNECT_CLAIM_LIST:
      return {
        ...state,
        loading: true,
        domainList: [],
      }
    case GET_USER_ENTITY_CONNECT_CLAIM_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        totalPage: action.payload.totalPage,
        userEntityConnectClaimList: action.payload.userEntityConnectClaimList,
      }
    case DOWN_EXCEL_USER_ENTITY_CONNECT_CLAIM_LIST:
      return {
        ...state,
        loading: true
      }
    case DOWN_EXCEL_USER_ENTITY_CONNECT_CLAIM_LIST_SUCCESS:
      return {
        ...state,
        loading: false
      }
    case ADD_USER_ENTITY_CONNECT_CLAIM:
      return {
        ...state,
        loading: true,
      }
    case ADD_USER_ENTITY_CONNECT_CLAIM_SUCCESS:
      return {
        ...state,
        loading: false,
        success: action.payload.result,
      }
    case MODIFY_USER_ENTITY_CONNECT_CLAIM_STATUS:
      return {
        ...state,
        loading: true,
      }
    case MODIFY_USER_ENTITY_CONNECT_CLAIM_STATUS_SUCCESS:
      return {
        ...state,
        loading: false,
        success: action.payload.result,
      }
    case CONFIRM_USER_ENTITY_CONNECT_CLAIM:
      return {
        ...state,
        loading: true,
      }
    case CONFIRM_USER_ENTITY_CONNECT_CLAIM_SUCCESS:
      return {
        ...state,
        loading: false,
        success: action.payload.result,
      }
    case CHANGE_USER_ENTITY_CONNECT_CLAIM_LIST:
      return {
        ...state,
        userEntityConnectClaimList: action.payload,
      }
    case SET_SUCCESS:
      return {
        ...state,
        success: action.payload
      }
    case SET_RESULT:
      return {
        ...state,
        result: action.payload
      }
    case SET_LOADING:
      return {
        ...state,
        loading: action.payload
      }
    case GET_REQUESTER_LIST:
      return {
        ...state,
        loading: true,
        requesterList: [],
      }
    case GET_REQUESTER_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        requesterList: action.payload.requesterList,
      }
    case CHANGE_REQUESTER_LIST:
      return {
        ...state,
        requesterList: action.payload,
      }
    case GET_USER_DOMAIN_LIST:
      return {
        ...state,
        loading: true,
        userDomainList: [],
      }
    case GET_USER_DOMAIN_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        userDomainList: action.payload.userDomainList,
      }
    case ADD_USER_DOMAIN:
      return {
        ...state,
        loading: true,
      }
    case ADD_USER_DOMAIN_SUCCESS:
      return {
        ...state,
        loading: false,
        success: action.payload
      }
    case MODIFY_USER_DOMAIN:
      return {
        ...state,
        loading: true,
      }
    case MODIFY_USER_DOMAIN_SUCCESS:
      return {
        ...state,
        loading: false,
        success: action.payload
      }
    case REMOVE_USER_DOMAIN:
      return {
        ...state,
        loading: true,
      }
    case REMOVE_USER_DOMAIN_SUCCESS:
      return {
        ...state,
        loading: false,
        success: action.payload
      }
    case CHANGE_USER_DOMAIN_LIST:
      return {
        ...state,
        userDomainList: action.payload,
      }
    default:
      state = { ...state }
      break
  }
  return state
}

export default EntityConnectManagement
