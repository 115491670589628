import React, {useState} from 'react';
import { useSelector, useDispatch } from "react-redux";
import {
    TabContent,
    TabPane,
    NavLink,
    NavItem,
    Nav,
  } from "reactstrap"

import classnames from "classnames"
import SweetAlert from "react-bootstrap-sweetalert";
import LoadingModal from "../../components/Common/LoadingModal"
import AdmInvoiceManagementSearch from './AdmInvoiceManagementSearch';
import AdmCreateInvoice from './AdmCreateInvoice';
import '../../components/Common/Common.css';

import {
  setAdmInvoiceManagementSuccess,
  setAdmInvoiceManagementError,
  setAdmInvoiceManagementLoading as onSetAdmInvoiceManagementLoading,
} from "../../store/actions";

const AdmInvoiceManagement = props => {
  const dispatch = useDispatch();
  const { loading, error, success, } = useSelector(state => ({
    loading: state.AdmInvoiceManagement.loading,
    success: state.AdmInvoiceManagement.success,
    error: state.AdmInvoiceManagement.error,
  }));

  const [activeTab, setActiveTab] = useState("Search")
  function toggleActiveTab(tab) {
    if (activeTab !== tab) {
      setActiveTab(tab)
    }
  }

  const setAdmInvoiceManagementLoading = () => {
    dispatch(onSetAdmInvoiceManagementLoading(!loading))
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Nav tabs className="col-md-6 nav-tabs-custom nav-justified">
            <NavItem>
              <NavLink
                style={{ cursor: "pointer" }}
                className={classnames({
                  active: activeTab === "Search",
                })}
                onClick={() => {
                  toggleActiveTab("Search")
                }}
              >
                <span>Search</span>
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                style={{ cursor: "pointer" }}
                className={classnames({
                  active: activeTab === "Create",
                })}
                onClick={() => {
                  toggleActiveTab("Create")
                }}
              >
                <span>Create new invoice</span>
              </NavLink>
            </NavItem>
          </Nav>
          <TabContent
            activeTab={activeTab}
            className="text-muted"
          >
            <TabPane tabId="Search">
              <AdmInvoiceManagementSearch/>
            </TabPane>
            <TabPane tabId="Create">
              <AdmCreateInvoice toggleActiveTab={toggleActiveTab}/>
            </TabPane>
          </TabContent>

          {
            loading ?
            <LoadingModal loading={loading} setLoading={setAdmInvoiceManagementLoading}></LoadingModal>
            : null
          }

          {
            success ? 
            <SweetAlert
              title={success}
              timeout={2000}
              showCloseButton={false}
              showConfirm={false}
              success
              onConfirm={() => {
                dispatch(setAdmInvoiceManagementSuccess(""));
              }}
            ></SweetAlert>
            : null
          }

          {
            error ?
            <SweetAlert
              warning
              title={error}
              onConfirm={() => {
                dispatch(setAdmInvoiceManagementError(""));
              }}
            >
            </SweetAlert>
            : null
          }
        </div>
      </div>
    </React.Fragment>
  )
}

export default AdmInvoiceManagement;