import {
  SET_LOADING,
  SET_SUCCESS,
  SET_ERROR_MSG,
  SET_UP_FILES,
  SET_UPDATE_FLAG,
  SET_LOADING_EDITOR,
  GET_COMMUNITY_LIST,
  GET_COMMUNITY_LIST_SUCCESS,
  CHANGE_COMMUNITY_DETAIL,
  GET_COMMUNITY_DETAIL,
  GET_COMMUNITY_DETAIL_SUCCESS,
  CREATE_COMMUNITY,
  CREATE_COMMUNITY_SUCCESS,
  MODIFY_COMMUNITY,
  MODIFY_COMMUNITY_SUCCESS,
  REMOVE_COMMUNITY,
  REMOVE_COMMUNITY_SUCCESS,
  CHANGE_COMMUNITY_ATTACH_FILE_LIST,
  GET_COMMUNITY_ATTACH_FILE_LIST,
  GET_COMMUNITY_ATTACH_FILE_LIST_SUCCESS,
  ADD_COMMUNITY_ATTACH_FILE,
  ADD_COMMUNITY_ATTACH_FILE_SUCCESS,
  REMOVE_COMMUNITY_ATTACH_FILE,
  REMOVE_COMMUNITY_ATTACH_FILE_SUCCESS,
  CHANGE_COMMUNITY_REPLY_LIST,
  GET_COMMUNITY_REPLY_LIST,
  GET_COMMUNITY_REPLY_LIST_SUCCESS,
  CREATE_COMMUNITY_REPLY,
  CREATE_COMMUNITY_REPLY_SUCCESS,
  MODIFY_COMMUNITY_REPLY,
  MODIFY_COMMUNITY_REPLY_SUCCESS,
  REMOVE_COMMUNITY_REPLY,
  REMOVE_COMMUNITY_REPLY_SUCCESS,
} from "./actionTypes"

export const setCommunityLoading = data => {
  return {
    type: SET_LOADING,
    payload: data,
  }
}

export const setCommunitySuccess = data => {
  return {
    type: SET_SUCCESS,
    payload: data,
  }
}

export const setCommunityErrorMsg = data => {
  return {
    type: SET_ERROR_MSG,
    payload: data,
  }
}

export const setCommunityUpFiles = data => {
  return {
    type: SET_UP_FILES,
    payload: data,
  }
}

export const setCommunityLoadingEditor = data => {
  return {
    type: SET_LOADING_EDITOR,
    payload: data,
  }
}

export const setCommunityUpdateFlag = data => {
  return {
    type: SET_UPDATE_FLAG,
    payload: data,
  }
}

export const getCommunityList = data => {
  return {
    type: GET_COMMUNITY_LIST,
    payload: data,
  }
}

export const getCommunityListSuccess = data => {
  return {
    type: GET_COMMUNITY_LIST_SUCCESS,
    payload: data,
  }
}


export const changeCommunityDetail = data => {
  return {
    type: CHANGE_COMMUNITY_DETAIL,
    payload: data,
  }
}

export const getCommunityDetail = data => {
  return {
    type: GET_COMMUNITY_DETAIL,
    payload: data,
  }
}

export const getCommunityDetailSuccess = data => {
  return {
    type: GET_COMMUNITY_DETAIL_SUCCESS,
    payload: data,
  }
}

export const createCommunity = data => {
  return {
    type: CREATE_COMMUNITY,
    payload: data,
  }
}

export const createCommunitySuccess = response => {
  return {
    type: CREATE_COMMUNITY_SUCCESS,
    payload: response,
  }
}

export const modifyCommunity = data => {
  return {
    type: MODIFY_COMMUNITY,
    payload: data,
  }
}

export const modifyCommunitySuccess = (response, data) => {
  return {
    type: MODIFY_COMMUNITY_SUCCESS,
    payload: {response, data},
  }
}

export const removeCommunity = data => {
  return {
    type: REMOVE_COMMUNITY,
    payload: data,
  }
}

export const removeCommunitySuccess = (response, data) => {
  return {
    type: REMOVE_COMMUNITY_SUCCESS,
    payload: {response, data},
  }
}

export const changeCommunityAttachFileList = data => {
  return {
    type: CHANGE_COMMUNITY_ATTACH_FILE_LIST,
    payload: data,
  }
}

export const getCommunityAttachFileList = data => {
  return {
    type: GET_COMMUNITY_ATTACH_FILE_LIST,
    payload: data,
  }
}

export const getCommunityAttachFileListSuccess = data => {
  return {
    type: GET_COMMUNITY_ATTACH_FILE_LIST_SUCCESS,
    payload: data,
  }
}

export const addCommunityAttachFile = (data, filter) => {
  return {
    type: ADD_COMMUNITY_ATTACH_FILE,
    payload: {data, filter},
  }
}

export const addCommunityAttachFileSuccess = data => {
  return {
    type: ADD_COMMUNITY_ATTACH_FILE_SUCCESS,
    payload: data,
  }
}

export const removeCommunityAttachFile = (data, filter) => {
  return {
    type: REMOVE_COMMUNITY_ATTACH_FILE,
    payload: {data, filter},
  }
}

export const removeCommunityAttachFileSuccess = data => {
  return {
    type: REMOVE_COMMUNITY_ATTACH_FILE_SUCCESS,
    payload: data,
  }
}

export const changeCommunityReplyList = data => {
  return {
    type: CHANGE_COMMUNITY_REPLY_LIST,
    payload: data,
  }
}

export const getCommunityReplyList = data => {
  return {
    type: GET_COMMUNITY_REPLY_LIST,
    payload: data,
  }
}

export const getCommunityReplyListSuccess = data => {
  return {
    type: GET_COMMUNITY_REPLY_LIST_SUCCESS,
    payload: data,
  }
}

export const createCommunityReply = (data, filter) => {
  return {
    type: CREATE_COMMUNITY_REPLY,
    payload: {data, filter},
  }
}

export const createCommunityReplySuccess = data => {
  return {
    type: CREATE_COMMUNITY_REPLY_SUCCESS,
    payload: data,
  }
}

export const modifyCommunityReply = (data, filter) => {
  return {
    type: MODIFY_COMMUNITY_REPLY,
    payload: {data, filter},
  }
}

export const modifyCommunityReplySuccess = data => {
  return {
    type: MODIFY_COMMUNITY_REPLY_SUCCESS,
    payload: data,
  }
}

export const removeCommunityReply = (data, filter) => {
  return {
    type: REMOVE_COMMUNITY_REPLY,
    payload: {data, filter},
  }
}

export const removeCommunityReplySuccess = data => {
  return {
    type: REMOVE_COMMUNITY_REPLY_SUCCESS,
    payload: data,
  }
}