import React, { useState, useEffect, useRef } from "react"
import PropTypes from 'prop-types'
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledTooltip,
} from "reactstrap"

//i18n
import { withTranslation } from "react-i18next"
// Redux
import { connect, useSelector, useDispatch } from "react-redux"
import { withRouter, Link } from "react-router-dom"

// users
// import user4 from "../../../assets/images/users/avatar-4.jpg"

import {
  getUserUnlockInfo,
  changeProfileUserName,
  changeProfilePhotoUrl,
} from "../../../store/actions";

const ProfileMenu = props => {
  // Declare a new state variable, which we'll call "menu"
  const [menu, setMenu] = useState(false)

  const dispatch = useDispatch();

  const [tooltipOpen, setTooltipOpen] = useState(false);

  const { username, photoUrl, unlockCredit, unlockCnt} = useSelector(state => ({
    username: state.Profile.username,
    photoUrl: state.Profile.photoUrl,
    unlockCredit: state.Unlock.unlockCredit,
    unlockCnt: state.Unlock.unlockCnt,
  }));

  useEffect(() => {
    if (localStorage.getItem("authUser")) {
      
      // console.log(localStorage.getItem("authUser"));
      // console.log(JSON.stringify(localStorage.getItem("authUser")));
      // console.log(JSON.parse(localStorage.getItem("authUser")));
      // console.log(JSON.parse(JSON.stringify(localStorage.getItem("authUser"))));
      const obj = JSON.parse(localStorage.getItem("authUser"));
      dispatch(changeProfileUserName(obj.userName));
      dispatch(changeProfilePhotoUrl(obj.photoUrl));
      /*
      if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
        const obj = JSON.parse(localStorage.getItem("authUser"))
        setusername(obj.displayName)
      } else if (
        process.env.REACT_APP_DEFAULTAUTH === "fake" ||
        process.env.REACT_APP_DEFAULTAUTH === "jwt"
      ) {
        const obj = JSON.parse(localStorage.getItem("authUser"))
        setusername(obj.username)
      }
      */

      dispatch(getUserUnlockInfo());
    }
  }, [props.success])

  const creditTooltip = useRef(null);
  const handleCloseCreditTooltip = (e) => {
    // console.log('1. handleCloseCreditTooltip >> target.id : ' + e.target.id + ', creditTooltip.current : ' + creditTooltip.current);
    
    if (e.target.id !== 'creditTooltip' && !creditTooltip.current) {
      // console.log('3. handleCloseCreditTooltip')
      setTooltipOpen(false);
    }
  }

  useEffect(() => {
    window.addEventListener('click', handleCloseCreditTooltip);
  }, []);

  return (
    <React.Fragment>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="d-inline-block"
      >
        {
        JSON.parse(localStorage.getItem("authUser")).adminYn === 'N' || 
        (JSON.parse(localStorage.getItem("authUser")).adminYn === 'Y' && JSON.parse(localStorage.getItem("authUser")).virtualLogIn === 'Y') ?
        <>
        <button
          type="button"
          onClick={() => {
            window.location.href = "/membershipPlan";
          }}
          className="btn btn-primary btn-wcdark waves-effect"
          style={{width: "150px", padding: "3px", marginRight: "10px"}}
        >
          Upgrade plan
        </button>

        <a className={unlockCredit - unlockCnt > 1 ? "text-success" : "text-danger"} id="unlock_info" onMouseOver={()=>setTooltipOpen(true)} style={{cursor: "pointer"}}>
          {unlockCredit - unlockCnt} credits available <i className="uil uil-question-circle font-size-18"></i>
        </a>
        <UncontrolledTooltip placement="bottom" target="unlock_info" container="header" isOpen={tooltipOpen} id="creditTooltip" creditTooltip={creditTooltip} toggle={()=>{}}>
          Use 1 credit to unlock 1 case. Upgrade your WeCrest plan to receive unlimited access for your jurisdiction.
          <br/>
          <button
            type="button"
            onClick={() => {
              window.location.href = "/membershipPlan";
            }}
            className="btn btn-primary btn-wcdark waves-effect"
            style={{height: "30px", padding: "0.2em", width: "70px", marginTop: "5px"}}
          >
            Upgrade
          </button>
        </UncontrolledTooltip>
        </>
        : null
        }

        <DropdownToggle
          className="btn header-item waves-effect"
          id="page-header-user-dropdown"
          tag="button"
        >
          { photoUrl !== "" ?
            <img
              className="rounded-circle header-profile-user"
              src={photoUrl}
              alt="Header profile"
              referrerpolicy="no-referrer"
            />
            : <i className="uil uil-user font-size-18 me-1 text-muted"></i>
          }
          <span className="d-none d-xl-inline-block ms-1 fw-medium font-size-15">{username}</span>{" "}
          <i className="uil-angle-down d-none d-xl-inline-block font-size-15"></i>
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end">
          {/* 
          <DropdownItem tag="a" href="/profile">
            {" "}
            <i className="uil uil-user-circle font-size-18 align-middle text-muted me-1"></i>
            {props.t("View Profile")}{" "}
          </DropdownItem>
          <DropdownItem tag="a" href="/">
            <i className="uil uil-wallet font-size-18 align-middle me-1 text-muted"></i>
            {props.t("My Wallet")}
          </DropdownItem>
          */}
          <DropdownItem tag="a" href={JSON.parse(localStorage.getItem("authUser")).adminYn == 'Y' && JSON.parse(localStorage.getItem("authUser")).virtualLogIn == 'N' ? "/admSettings" : "/membershipPlan"}>
            <i className="uil uil-cog font-size-18 align-middle me-1 text-muted"></i>
            {props.t("Settings")}
            {/*
            <span className="badge bg-soft-success rounded-pill mt-1 ms-2">03</span>
            */}
          </DropdownItem>
          {/* 
          <DropdownItem tag="a" href="auth-lock-screen">
            <i className="uil uil-lock-alt font-size-18 align-middle me-1 text-muted"></i>
            {props.t("Lock screen")}
          </DropdownItem>
          */}
          <div className="dropdown-divider" />
          <Link to="/logout" className="dropdown-item">
            <i className="uil uil-sign-out-alt font-size-18 align-middle me-1 text-muted"></i>
            <span>{props.t("Logout")}</span>
          </Link>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  )
}

ProfileMenu.propTypes = {
  success: PropTypes.any,
  t: PropTypes.any
}

const mapStatetoProps = state => {
  const { error, success } = state.Profile
  return { error, success }
}

export default withRouter(
  connect(mapStatetoProps, {})(withTranslation()(ProfileMenu))
)
