import {
  GET_CONTACTS_LIST,
  GET_CONTACTS_LIST_SUCCESS,
  SET_UPLOAD_CONTACTS_OPEN,
  UPLOAD_CONTACTS,
  UPLOAD_CONTACTS_SUCCESS,
  SET_IS_UPLOAD_FALSE,
  CHANGE_CONTACTS_LIST,
  MULTIPLE_STAR_CONTACTS,
  MULTIPLE_UNSTAR_CONTACTS,
  MULTIPLE_HIDE_CONTACTS,
  MULTIPLE_UNHIDE_CONTACTS,
  MULTIPLE_UNSUBSCRIBE_CONTACTS,
  CHECK_EMAIL_UNSUBSCRIBE,
  CHECK_EMAIL_UNSUBSCRIBE_SUCCESS,
  SAVE_CONTACTS,
  SAVE_CONTACTS_SIMPLE,
  SAVE_CONTACTS_SIMPLE_SUCCESS,
  DELETE_CONTACTS,
  GET_IMPORTED_CONTACTS,
  GET_IMPORTED_CONTACTS_SUCCESS,
  GET_SUGGESTED_CONTACTS,
  GET_SUGGESTED_CONTACTS_SUCCESS,
  GET_CONTACTS_DETAILS,
  GET_CONTACTS_DETAILS_SUCCESS,
  GET_CONTACTS_DETAIL_LIST,
  GET_CONTACTS_DETAIL_LIST_SUCCESS,
  GET_CONTACTS_MEMO,
  GET_CONTACTS_MEMO_SUCCESS,
  CHANGE_CONTACTS_DETAILS,
  SAVE_MEMO,
  SAVE_MEMO_SUCCESS,
  CHANGE_IMPORTED_CONTACTS,
  CHANGE_SUGGESTED_CONTACTS,
  CHANGE_MEMBER_CONFIRMED_CONTACTS,
  SAVE_CONTACTS_SETTINGS,
  GET_CONTACTS_SETTINGS,
  GET_CONTACTS_SETTINGS_SUCCESS,
  DOWN_CONTACTS_EXCEL,
  DOWN_CONTACTS_EXCEL_SUCCESS,
  SET_SEARCH_SETTINGS,
  SET_CONTACS_RESULT,
  SET_CONTACS_DETAIL_RESULT,
  SET_CONTACS_LOADING,

  GET_UPLOADED_CONTACTS_LIST,
  GET_UPLOADED_CONTACTS_LIST_SUCCESS,
  SAVE_UPLOADED_CONTACTS,
  DELETE_UPLOADED_CONTACTS,
  DOWN_UPLOADED_CONTACTS_EXCEL,
  DOWN_UPLOADED_CONTACTS_EXCEL_SUCCESS,
  CHANGE_UPLOADED_CONTACTS_LIST,

  GET_DOMAIN_CORRECT_YN_COUNT,
  GET_DOMAIN_CORRECT_YN_COUNT_SUCCESS,
  SAVE_DOMAIN_CORRECT_YN,
  SAVE_DOMAIN_CORRECT_YN_SUCCESS,
  CHANGE_USER_SUGGEST_DOMAIN,
  CHANGE_SHOW_SUCCESS_ADD_TOAST_FLAG,
  CHANGE_CHECK_UNSUBS_EMAIL_ADDRESS_FLAG,

  GET_CONTACT_SEARCH_LIST,
  GET_CONTACT_SEARCH_LIST_SUCCESS,
  CHANGE_CONTACT_SEARCH_LIST,
  DOWN_SEARCH_ENTITY_EXCEL,
  DOWN_SEARCH_ENTITY_EXCEL_SUCCESS,
} from "./actionTypes"

export const getContactsList = data => {
  return {
    type: GET_CONTACTS_LIST,
    payload: data,
  }
}

export const getContactsListSuccess = data => {
  return {
    type: GET_CONTACTS_LIST_SUCCESS,
    payload: data,
  }
}

export const setUploadContactsOpen = data => {
  return {
    type: SET_UPLOAD_CONTACTS_OPEN,
    payload: data,
  }
}

export const uploadContacts = data => {
  return {
    type: UPLOAD_CONTACTS,
    payload: data,
  }
}

export const uploadContactsSuccess = data => {
  return {
    type: UPLOAD_CONTACTS_SUCCESS,
    payload: data,
  }
}

export const setIsUploadFalse = () => {
  return {
    type: SET_IS_UPLOAD_FALSE,
  }
}

export const changeContactsList = data => {
  return {
    type: CHANGE_CONTACTS_LIST,
    payload: data,
  }
}

export const multipleStarContacts = (chkList, filter, type) => {
  return {
    type: MULTIPLE_STAR_CONTACTS,
    payload: {chkList, filter, type},
  }
}

export const multipleUnstarContacts = (chkList, filter, type) => {
  return {
    type: MULTIPLE_UNSTAR_CONTACTS,
    payload: {chkList, filter, type},
  }
}

export const multipleHideContacts = (chkList, filter, type) => {
  return {
    type: MULTIPLE_HIDE_CONTACTS,
    payload: {chkList, filter, type},
  }
}

export const multipleUnhideContacts = (chkList, filter, type) => {
  return {
    type: MULTIPLE_UNHIDE_CONTACTS,
    payload: {chkList, filter, type},
  }
}

export const multipleUnsubscribeContacts = (chkList, filter) => {
  return {
    type: MULTIPLE_UNSUBSCRIBE_CONTACTS,
    payload: {chkList, filter},
  }
}

export const checkEmailUnsubscribe = (chkList, saveType, screenName) => {
  return {
    type: CHECK_EMAIL_UNSUBSCRIBE,
    payload: {chkList, saveType, screenName},
  }
}

export const checkEmailUnsubscribeSuccess = (data, screenName) => {
  return {
    type: CHECK_EMAIL_UNSUBSCRIBE_SUCCESS,
    payload: {data, screenName},
  }
}

export const saveContacts = (chkList, filter) => {
  return {
    type: SAVE_CONTACTS,
    payload: {chkList, filter},
  }
}

export const saveContactsSimple = data => {
  return {
    type: SAVE_CONTACTS_SIMPLE,
    payload: data,
  }
}

export const saveContactsSimpleSuccess = data => {
  return {
    type: SAVE_CONTACTS_SIMPLE_SUCCESS,
    payload: data,
  }
}

export const deleteContacts = (chkList, filter) => {
  return {
    type: DELETE_CONTACTS,
    payload: {chkList, filter},
  }
}

export const getImportedContacts = data => {
  return {
    type: GET_IMPORTED_CONTACTS,
    payload: data,
  }
}

export const getImportedContactsSuccess = data => {
  return {
    type: GET_IMPORTED_CONTACTS_SUCCESS,
    payload: data,
  }
}

export const getSuggestedContacts = data => {
  return {
    type: GET_SUGGESTED_CONTACTS,
    payload: data,
  }
}

export const getSuggestedContactsSuccess = data => {
  return {
    type: GET_SUGGESTED_CONTACTS_SUCCESS,
    payload: data,
  }
}

export const getContactsDetails = data => {
  return {
    type: GET_CONTACTS_DETAILS,
    payload: data,
  }
}

export const getContactsDetailsSuccess = data => {
  return {
    type: GET_CONTACTS_DETAILS_SUCCESS,
    payload: data,
  }
}

export const getContactsDetailList = data => {
  return {
    type: GET_CONTACTS_DETAIL_LIST,
    payload: data,
  }
}

export const getContactsDetailListSuccess = data => {
  return {
    type: GET_CONTACTS_DETAIL_LIST_SUCCESS,
    payload: data,
  }
}

export const getContactsMemo = data => {
  return {
    type: GET_CONTACTS_MEMO,
    payload: data,
  }
}

export const getContactsMemoSuccess = data => {
  return {
    type: GET_CONTACTS_MEMO_SUCCESS,
    payload: data,
  }
}

export const changeContactsDetails = data => {
  return {
    type: CHANGE_CONTACTS_DETAILS,
    payload: data,
  }
}

export const saveMemo = data => {
  return {
    type: SAVE_MEMO,
    payload: data,
  }
}

export const saveMemoSuccess = data => {
  return {
    type: SAVE_MEMO_SUCCESS,
    payload: data,
  }
}

export const changeImportedContacts = data => {
  return {
    type: CHANGE_IMPORTED_CONTACTS,
    payload: data,
  }
}

export const changeSuggestedContacts = data => {
  return {
    type: CHANGE_SUGGESTED_CONTACTS,
    payload: data,
  }
}

export const changeMemberConfirmedContacts = data => {
  return {
    type: CHANGE_MEMBER_CONFIRMED_CONTACTS,
    payload: data,
  }
}

export const saveContactsSettings = data => {
  return {
    type: SAVE_CONTACTS_SETTINGS,
    payload: data,
  }
}

export const getContactsSettings = () => {
  return {
    type: GET_CONTACTS_SETTINGS,
  }
}

export const getContactsSettingsSuccess = data => {
  return {
    type: GET_CONTACTS_SETTINGS_SUCCESS,
    payload: data,
  }
}

export const downContactsExcel = (data, fileName) => {
  return {
    type: DOWN_CONTACTS_EXCEL,
    payload: {data, fileName},
  }
}

export const downContactsExcelSuccess = () => {
  return {
    type: DOWN_CONTACTS_EXCEL_SUCCESS,
  }
}

export const setSearchSettings = data => {
  return {
    type: SET_SEARCH_SETTINGS,
    payload: data,
  }
}

export const setContacsResult = data => {
  return {
    type: SET_CONTACS_RESULT,
    payload: data,
  }
}

export const setContacsDetailResult = data => {
  return {
    type: SET_CONTACS_DETAIL_RESULT,
    payload: data,
  }
}

export const setContactsLoading = data => {
  return {
    type: SET_CONTACS_LOADING,
    payload: data,
  }
}

export const getUploadedContactsList = data => {
  return {
    type: GET_UPLOADED_CONTACTS_LIST,
    payload: data,
  }
}

export const getUploadedContactsListSuccess = data => {
  return {
    type: GET_UPLOADED_CONTACTS_LIST_SUCCESS,
    payload: data,
  }
}

export const saveUploadedContacts = (chkList, filter) => {
  return {
    type: SAVE_UPLOADED_CONTACTS,
    payload: {chkList, filter},
  }
}

export const deleteUploadedContacts = (chkList, filter) => {
  return {
    type: DELETE_UPLOADED_CONTACTS,
    payload: {chkList, filter},
  }
}

export const downUploadedContactsExcel = data => {
  return {
    type: DOWN_UPLOADED_CONTACTS_EXCEL,
    payload: data,
  }
}

export const downUploadedContactsExcelSuccess = () => {
  return {
    type: DOWN_UPLOADED_CONTACTS_EXCEL_SUCCESS,
  }
}

export const changeUploadedContactsList = data => {
  return {
    type: CHANGE_UPLOADED_CONTACTS_LIST,
    payload: data,
  }
}

export const getDomainCorrectYnCount = data => {
  return {
    type: GET_DOMAIN_CORRECT_YN_COUNT,
    payload: data,
  }
}

export const getDomainCorrectYnCountSuccess = data => {
  return {
    type: GET_DOMAIN_CORRECT_YN_COUNT_SUCCESS,
    payload: data,
  }
}

export const saveDomainCorrectYn = data => {
  return {
    type: SAVE_DOMAIN_CORRECT_YN,
    payload: data,
  }
}

export const saveDomainCorrectYnSuccess = data => {
  return {
    type: SAVE_DOMAIN_CORRECT_YN_SUCCESS,
    payload: data,
  }
}

export const changeUserSuggestDomain = data => {
  return {
    type: CHANGE_USER_SUGGEST_DOMAIN,
    payload: data,
  }
}

export const changeShowSuccessAddToastFlag = data => {
  return {
    type: CHANGE_SHOW_SUCCESS_ADD_TOAST_FLAG,
    payload: data,
  }
}

export const changeCheckUnsubsEmailAddressFlag = data => {
  return {
    type: CHANGE_CHECK_UNSUBS_EMAIL_ADDRESS_FLAG,
    payload: data,
  }
}

export const getContactSearchList = data => {
  return {
    type: GET_CONTACT_SEARCH_LIST,
    payload: data,
  }
}

export const getContactSearchListSuccess = data => {
  return {
    type: GET_CONTACT_SEARCH_LIST_SUCCESS,
    payload: data,
  }
}

export const changeContactSearchList = data => {
  return {
    type: CHANGE_CONTACT_SEARCH_LIST,
    payload: data,
  }
}

export const downSearchEntityExcel = (data, fileName) => {
  return {
    type: DOWN_SEARCH_ENTITY_EXCEL,
    payload: {data, fileName},
  }
}

export const downSearchEntityExcelSuccess = () => {
  return {
    type: DOWN_SEARCH_ENTITY_EXCEL_SUCCESS,
  }
}