import {
  GET_FILER_SETTINGS,
  GET_FILER_SETTINGS_SUCCESS,
  SAVE_FILER_SETTINGS,
  SAVE_FILER_SETTINGS_SUCCESS,
  SET_SUCCESS,
  SET_RESULT,
  GET_FILER_RANKINGS,
  GET_FILER_RANKINGS_SUCCESS,
  GET_FILER_RANKING_INFO,
  GET_FILER_RANKING_INFO_SUCCESS,
  CHANGE_FILER_RANKINGS,
  MULTIPLE_HIDE_FILER,
  MULTIPLE_UNHIDE_FILER,
  DOWN_FILER_EXCEL,
  DOWN_FILER_EXCEL_SUCCESS,
  DOWN_MULTIPLE_FILER_EXCEL,
  DOWN_MULTIPLE_FILER_EXCEL_SUCCESS,
  DOWN_FILER_PDF,
  DOWN_FILER_PDF_SUCCESS,
  DOWN_FILER_PDF_DATA_ONLY,
  DOWN_FILER_PDF_DATA_ONLY_SUCCESS,
  DOWN_MULTIPLE_FILER_PDF,
  DOWN_MULTIPLE_FILER_PDF_SUCCESS,
  FILER_SEND_EMAIL,
  FILER_PREVIEW_EMAIL,
  FILER_PREVIEW_EMAIL_SUCCESS,
  SET_PREVIEW_EMAIL,
  CLEAR_FILER_RANKINGS,
  SET_FILER_LOADING,
  CHANGE_UNSUBS_INFO,
  CHANGE_FILER_SEARCH_SETTINGS_COUNTRY,
} from "./actionTypes"

const initialState = {
  error: "",
  loading: false,
  success: "",
  result: -1,
  searchSettings: {},
  freeMemberAutosearch: "N",
  userGrade: "00",
  filerRankings: [],
  totalPage: 1,
  noticeStr: "",
  previewEmail: {},
  unsubsInfo: "",
}

const filerRankings = (state = initialState, action) => {
  switch (action.type) {
    case GET_FILER_SETTINGS:
      return {
        ...state,
        loading: true,
      }
    case GET_FILER_SETTINGS_SUCCESS:
      return {
        ...state,
        searchSettings: action.payload.searchSettings,
        freeMemberAutosearch: action.payload.freeMemberAutosearch,
        userGrade: action.payload.userGrade,
        loading: false,
      }
    case SAVE_FILER_SETTINGS:
      return {
        ...state,
        loading: true,
      }
    case SAVE_FILER_SETTINGS_SUCCESS:
      // console.log('searchSettings >> ' + JSON.stringify(action.payload.searchSettings));
      return {
        ...state,
        loading: false,
        // searchSettings: action.payload.searchSettings,
        success: action.payload.result,
        freeMemberAutosearch: "N",
      }
    case SET_SUCCESS:
      return {
        ...state,
        loading: false,
        success: action.payload,
      }
    case SET_RESULT:
      return {
        ...state,
        loading: false,
        result: action.payload,
      }
    case GET_FILER_RANKINGS:
      return {
        ...state,
        loading: true,
        filerRankings: [],
      }
    case GET_FILER_RANKINGS_SUCCESS:
      return {
        ...state,
        filerRankings: action.payload.filerRankings,
        noticeStr: action.payload.noticeStr,
        totalPage: action.payload.totalPage,
        userGrade: action.payload.userGrade,
        loading: false,
      }
    case GET_FILER_RANKING_INFO:
      return {
        ...state,
        loading: false
      }
    case GET_FILER_RANKING_INFO_SUCCESS:
      // console.log('state.madridNotifier : ' + JSON.stringify(state.madridNotifier));
      // console.log('action.payload.madridNotifier : ' + JSON.stringify(action.payload.madridNotifier));

      if (action.payload.filerRankings.length > 0) {
        let _newFilerRankings = [];
        state.filerRankings.map((ranking, index) => {
          let isChange = false;
          action.payload.filerRankings.map((newRanking, index2) => {
            if (ranking.targetGroupNo === newRanking.targetGroupNo && ranking.country === newRanking.country) {
              isChange = true;
              _newFilerRankings.push(newRanking);

              // console.log(isChange + ' : ' + newMadrid.irNumber + ', ' + madrid.ftpDate + ', ' + madrid.crCode);
            }
          });

          if (!isChange) {
            _newFilerRankings.push(ranking);
          }
        });

        return {
          ...state,
          filerRankings: _newFilerRankings,
          loading: false,
        }
      } else {
        return {
          ...state,
          loading: false,
        }
      }
    case CHANGE_FILER_RANKINGS:
      return {
        ...state,
        filerRankings: action.payload,
      }
    case MULTIPLE_HIDE_FILER:
      return {
        ...state,
        loading: true,
      }
    case MULTIPLE_UNHIDE_FILER:
      return {
        ...state,
        loading: true,
      }
    case DOWN_FILER_EXCEL:
      return {
        ...state,
        loading: true
      }
    case DOWN_FILER_EXCEL_SUCCESS:
      return {
        ...state,
        loading: false
      }
    case DOWN_MULTIPLE_FILER_EXCEL:
      return {
        ...state,
        loading: true
      }
    case DOWN_MULTIPLE_FILER_EXCEL_SUCCESS:
      return {
        ...state,
        loading: false
      }
    case DOWN_FILER_PDF:
      return {
        ...state,
        loading: true
      }
    case DOWN_FILER_PDF_SUCCESS:
      return {
        ...state,
        loading: false
      }
    case DOWN_FILER_PDF_DATA_ONLY:
      return {
        ...state,
        loading: true
      }
    case DOWN_FILER_PDF_DATA_ONLY_SUCCESS:
      return {
        ...state,
        loading: false
      }
    case DOWN_MULTIPLE_FILER_PDF:
      return {
        ...state,
        loading: true
      }
    case DOWN_MULTIPLE_FILER_PDF_SUCCESS:
      return {
        ...state,
        loading: false
      }
    case FILER_SEND_EMAIL:
      return {
        ...state,
        loading: true
      }
    case FILER_PREVIEW_EMAIL:
      return {
        ...state,
        loading: true
      }
    case FILER_PREVIEW_EMAIL_SUCCESS:
      return {
        ...state,
        previewEmail: action.payload.previewEmail,
        loading: false
      }
    case SET_PREVIEW_EMAIL:
      return {
        ...state,
        previewEmail: action.payload,
      }
    case CLEAR_FILER_RANKINGS:
      return {
        ...state,
        filerRankings: [],
      }
    case SET_FILER_LOADING:
      return {
        ...state,
        loading: action.payload,
      }
    case CHANGE_UNSUBS_INFO:
      return {
        ...state,
        unsubsInfo: action.payload,
      }
    // case CHANGE_FILER_SEARCH_SETTINGS_COUNTRY:
    //   let _searchSettings = state.searchSettings;
    //   _searchSettings.inOutType = action.payload.inOutType;
      
    //   if (action.payload.componentName && action.payload.componentName === "Jurisdictions") {
    //     _searchSettings.jurType = action.payload.crType;
    //     _searchSettings.jrCdList = action.payload.crList;
    //   } else {
    //     _searchSettings.crType = action.payload.crType;
    //     _searchSettings.crCdList = action.payload.crList;
    //   }

    //   return {
    //     ...state,
    //     searchSettings: _searchSettings,
    //   }
    default:
      return {
        ...state,
      }
  }
}

export default filerRankings
