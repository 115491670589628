import React, {useState, useEffect} from 'react';
import { useSelector, useDispatch } from "react-redux";

import {
    Row,
    Tooltip,
    Col,
    Label,
    Modal,
} from "reactstrap"
import SweetAlert from "react-bootstrap-sweetalert";

import {
    getSubscriptionItemAllList as onGetSubscriptionItemAllList,
    changeSubscriptionItemAllList as onChangeSubscriptionItemAllList,
    changeAdmInvoiceManagementItemList as onChangeAdmInvoiceManagementItemList,
} from "../../store/actions";
import { cloneDeep } from 'lodash';
  
const SearchSubscriptionItem = props => {
    const dispatch = useDispatch();

    const { subscriptionItemAllList, admInvoiceManagementItemList, cresterList, userSubscriptionList} = useSelector(state => ({
        subscriptionItemAllList: state.AdmInvoiceManagement.subscriptionItemAllList,
        admInvoiceManagementItemList: state.AdmInvoiceManagement.admInvoiceManagementItemList,
        cresterList: state.AdmInvoiceManagement.cresterList,

        userSubscriptionList: state.Settings.userSubscriptionList,
    }));

    const [tright, settright] = useState(false);
    const [modal_standard, setmodal_standard] = useState(false);
    const [keyword, setKeyword] = useState("");
    const [errorMsg, setErrorMsg] = useState("");

    function removeBodyCss() {
        document.body.classList.add("no_padding")
    }
    
    const openModal = () => {
        setmodal_standard(!modal_standard);
        removeBodyCss();
    }

    const search = (searchKeyword) => {
        let _subscriptionItemAllList = [];

        subscriptionItemAllList.map((target, index) => {
            let _target = cloneDeep(target);

            if(_target.item.toLowerCase().indexOf(searchKeyword.toLowerCase()) > -1 || _target.itemName.toLowerCase().indexOf(searchKeyword.toLowerCase()) > -1){
                _target.showFlag = true;
            } else {
                _target.showFlag = false;
            }

            _subscriptionItemAllList.push(_target);
        }); 

        dispatch(onChangeSubscriptionItemAllList(_subscriptionItemAllList));
    }

    const targetCheck = (chkIndex) => {
        let _subscriptionItemAllList = [];

        subscriptionItemAllList.map((target, index) => {
            let _target = cloneDeep(target);

            if(index === chkIndex){
                _target.checked = true;
            } else {
                _target.checked = false;
            }

            _subscriptionItemAllList.push(_target);
        }); 

        dispatch(onChangeSubscriptionItemAllList(_subscriptionItemAllList));
    }

    const showAll = () => {
        let _subscriptionItemAllList = [];

        subscriptionItemAllList.map((target, index) => {
            let _target = cloneDeep(target);
            _target.showFlag = true;
            _subscriptionItemAllList.push(_target);
        }); 

        dispatch(onChangeSubscriptionItemAllList(_subscriptionItemAllList));
    }

    const targetConfirm = () => {
        let _item = {};

        subscriptionItemAllList.map((target, index) => {
            if(target.checked === true){
                _item = cloneDeep(target);
            }
        });

        let isAlreadySelect = checkAddItem(_item);

        if (isAlreadySelect) {
            setErrorMsg("You already select this item.");
            return;
        }
        
        _item.type = props.item.type ? props.item.type : 'Member';
        _item.cresterInChargeNo = props.item.cresterInChargeNo ? props.item.cresterInChargeNo : cresterList && cresterList.length > 0 ? cresterList[0].userNo : '';
        _item.term = props.item.term ? props.item.term : 'annually';

        let _nowDate = new Date();
        _item.startDate = _nowDate.getFullYear() 
                        + '-' + (_nowDate.getMonth() + 1 < 10 ? '0' + (_nowDate.getMonth() + 1) : _nowDate.getMonth() + 1) 
                        + '-' + (_nowDate.getDate() < 10 ? '0' + _nowDate.getDate() : _nowDate.getDate());
        
        let _endDate = new Date();
        _endDate.setFullYear(_endDate.getFullYear() + 1);
        _endDate.setDate(_endDate.getDate() - 1);
        _item.endDate = _endDate.getFullYear() 
                    + '-' + (_endDate.getMonth() + 1 < 10 ? '0' + (_endDate.getMonth() + 1) : _endDate.getMonth() + 1) 
                    + '-' + (_endDate.getDate() < 10 ? '0' + _endDate.getDate() : _endDate.getDate());
        
        if (_item.term === 'annually') {
            _item.cost = _item.m1*12;
            _item.dcDesc = '12 months discount'
            _item.dcAmt = _item.m1*12 - _item.m12;
            _item.dcRate = 30;
            _item.addDcAmt = 0;
            _item.addDcRate = 0;
            _item.standardFee = _item.m12;
            _item.subtotal = _item.m12;
        } else if (_item.term === '6months') {
            _item.cost = _item.m1*6;
            _item.dcDesc = '6 months discount'
            _item.dcAmt = _item.m1*6 - _item.m6;
            _item.dcRate = 15;
            _item.addDcAmt = 0;
            _item.addDcRate = 0;
            _item.standardFee = _item.m6;
            _item.subtotal = _item.m6;
        } else if (_item.term === '3months') {
            _item.cost = _item.m1*3;
            _item.dcDesc = '3 months discount'
            _item.dcAmt = _item.m1*3 - _item.m3;
            _item.dcRate = 7.5;
            _item.addDcAmt = 0;
            _item.addDcRate = 0;
            _item.standardFee = _item.m3;
            _item.subtotal = _item.m3;
        } else {
            _item.cost = _item.m1;
            _item.dcDesc = ''
            _item.dcAmt = 0;
            _item.dcRate = 0;
            _item.addDcAmt = 0;
            _item.addDcRate = 0;
            _item.standardFee = _item.m1;
            _item.subtotal = _item.m1;
        }

        //Trial은 100% dc로 처리
        if(_item.type === 'Trial' || _item.type === 'WeCrest'){
            _item.addDcAmt = _item.standardFee;
            _item.addDcRate = 100;
            _item.standardFee = 0;
            _item.subtotal = 0;
        }
        
        _item.gradeStr = 'Gold';
        _item.inOutType = 'IN';

        props.changeItemList(props.index, _item);

        openModal();
    }

    // 기존 구독정보중에 등록된 아이템 있는지 체크 
    const checkSubscriptionItem = (_item) => {
        let isSubscriptionItem = false;
        userSubscriptionList.map((userSubscription, index) => {
            if(_item.item === userSubscription.crCode){
                isSubscriptionItem = true;
            } else {
                isSubscriptionItem = false;
            }
        })

        return isSubscriptionItem;
    }

    // 기존에 등록된 아이템 있는지 체크 
    const checkAddItem = (_item) => {
        let isAlreadySelect = false;
        if (props.itemList) {
            props.itemList.map((checkItem, index) => {
                if (checkItem.item === _item.item) {
                    isAlreadySelect = true;
                }
            })
        }

        return isAlreadySelect;
    }

    useEffect(() => {
        dispatch(onGetSubscriptionItemAllList());
    },[])

    return (
        <>
        <Row className="mb-3">
            <label className="col-md-3 col-form-label">
            Item Name
            </label>
            <div className="col-md-4">
            <input
                className="form-control"
                type="text"
                placeholder="-"
                name="itemName"
                id={"itemName_" + (props.index + 1)}
                onClick={() => {
                    openModal();
                }}
                value={props.item && props.item.item && props.item.itemName ? props.item.item + ' ' + props.item.itemName  : '-'}
            />
            </div>
            <Col md={3}>
                <Tooltip
                    placement="right"
                    isOpen={tright}
                    target="SubscriptionItem"
                    toggle={() => {
                        settright(!tright)
                    }}
                >
                    Search Item
                </Tooltip>
                <button
                    type="button"
                    className="btn btn-primary waves-effect waves-light"
                    style={{width : '148.58px'}}
                    id="SubscriptionItem"
                    onClick={() => {
                        openModal();
                    }}
                    data-toggle="modal"
                    data-target="#myModal"
                >
                    Search
                </button>
            </Col>
        </Row>

        <Modal
            isOpen={modal_standard}
            toggle={() => {
            openModal()
            }}
            scrollable={true}
        >
            <div className="modal-header" style={{marginRight: "20px"}}>
                <h6 className="modal-title mt-0 col-md-6" id="myModalLabel">
                Item {props.index + 1}
                </h6>
                <input
                    type="text"
                    className="form-control"
                    style={{border: "solid 1px"}}
                    placeholder="Search..."
                    value={keyword}
                    onChange={(e) => {setKeyword(e.target.value)}}
                    onKeyDown={(e) => {
                        if(e.code === 'Enter'){
                            search(e.target.value);
                        }                      
                    }}
                />
                <button
                    type="button"
                    onClick={() => {
                        search(keyword);
                    }}
                    className="btn btn-primary waves-effect waves-light"
                    style={{marginLeft:"5px", 
                        width: "100px",
                        padding: "0.3rem 0.3rem"}}
                >
                    <i className="uil-search" style={{fontSize:"18px"}}></i>
                </button>

                <button
                    type="button"
                    onClick={() => {
                        openModal(false)
                    }}
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                >
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div className="modal-body">
                {
                    subscriptionItemAllList.length ?
                    subscriptionItemAllList.map((subscriptionItem, index) => {
                        if (subscriptionItem.showFlag && !checkSubscriptionItem(subscriptionItem)) {
                            return (
                                <Row className="mb-3">
                                    <div className="col-md-12 form-check" style={{paddingLeft : '2em'}} onClick={(e)=>targetCheck(index)}>
                                        <input
                                        className="form-check-input"
                                        type="radio"
                                        name="admSubscriptionItem"
                                        id={"admSubscriptionItem-" + index}
                                        value="Invoice"
                                        checked={subscriptionItem.checked}
                                        />
                                        <label
                                        className="form-check-label"
                                        htmlFor="admSubscriptionItem"
                                        >
                                        {subscriptionItem.item}&nbsp;{subscriptionItem.itemName}
                                        </label>
                                        {
                                            subscriptionItem.subscriptionType === 'JN' && subscriptionItem.checked ?
                                            <label className="form-check-label" style={{marginLeft: "10px"}}>
                                                <span style={{color:"#264574"}}>Gold {subscriptionItem.premInSubsCnt}</span>
                                            </label>
                                            : null
                                        }
                                    </div>
                                </Row>
                            )
                        }
                    })
                    : null
                }
            </div>
            <Row style={{color: "#f5f6f8", borderTop: "1px solid", borderBottomRightRadius: "calc(0.4rem - 1px)", borderBottomLeftRadius: "calc(0.4rem - 1px)"}}>
                <div className="col-md-6">
                    <button
                        type="button"
                        className="waves-effect waves-light btn btn-primary"
                        style={{width : '148.58px'}}
                        onClick={()=>{
                            showAll()
                        }}
                    >
                        Show all
                    </button>
                </div>
                <div className="col-md-6" style={{textAlign: "right"}}>
                    <button
                        type="button"
                        onClick={() => {
                        openModal()
                        }}
                        className="btn btn-secondary waves-effect"
                        data-dismiss="modal"
                    >
                        Cancel
                    </button>
                    &nbsp;
                    <button
                        type="button"
                        className="btn btn-primary waves-effect waves-light"
                        onClick={()=> targetConfirm()}
                    >
                        Confirm
                    </button>
                </div>
            </Row>

            {
            errorMsg !== '' ?
            <SweetAlert
              warning
              onConfirm={() => {
                setErrorMsg('');
              }}
            >
              {errorMsg}
            </SweetAlert>
            : null
          }
        </Modal>
        </>
    )
};

export default SearchSubscriptionItem;