import React, {useState, useEffect} from 'react';
import { useSelector, useDispatch } from "react-redux";

import {
    Row,
    Tooltip,
    Col,
    Label,
    Modal,
} from "reactstrap"

import {
    getMultipleJurisdictionsList as onGetMultipleJurisdictionsList,
    changeMultipleJurisdictionsList as onChangeMultipleJurisdictionsList,
    // changeMadridSearchSettingsCountry as onChangeMadridSearchSettingsCountry,
    // changeFilerSearchSettingsCountry as onChangeFilerSearchSettingsCountry,
} from "../../store/actions";
import { cloneDeep } from 'lodash';
  
const SelectMultipleJurisdictions = props => {
    const dispatch = useDispatch();

    const { multipleJurisdictionList, subsJurisdictionCount, multipleJurisdictionList_MCC } = useSelector(state => ({
        multipleJurisdictionList: state.MultipleJurisdictions.multipleJurisdictionList,
        subsJurisdictionCount: state.MultipleJurisdictions.subsJurisdictionCount,
        multipleJurisdictionList_MCC: state.MultipleJurisdictions.multipleJurisdictionList_MCC,
    }));

    const [tright, settright] = useState(false);
  
    const [modal_standard, setmodal_standard] = useState(false);
    const [chkAllFlag, setChkAllFlag] = useState(false);
    const [nonEUFlag, setNonEUFlag] = useState(false);
    /**
     * selectedJurList[1] : {'jurType' : str}
     * selectedJurList[2] : {'jurList' : [{countryVO},{countryVO},{}...]}
     */
    const [selectedJurList, setSelectedJurList] = useState([]);
    let chkAll = "All";
    let allSubs = "All-subs";
    let nonEU = "Non-eu";
    let individual = "Individual";

    const getMultipleJurisdictionList = () => {
        let _multipleJurisdictionList = [];

        if (props.filterName && props.filterName == 'myConnectedCase') {
            _multipleJurisdictionList = cloneDeep(multipleJurisdictionList_MCC);
        } else {
            _multipleJurisdictionList = cloneDeep(multipleJurisdictionList);
        }

        // console.log('getSearchNameList :: ' + props.formik.values.screenName + " > " + JSON.stringify(_searchNameList));
        return _multipleJurisdictionList;
    }

    function removeBodyCss() {
        document.body.classList.add("no_padding")
    }
    
    const openCountryModal = () => {
        let _multipleJurisdictionList = getMultipleJurisdictionList();

        if(selectedJurList.length !== 0){
            let checkBoxCnt = 0;
            let chkCnt = 0;
            let nonEuCnt = 0;
            let chkNonEuCnt = 0;

            setChkAllFlag(false);
            setNonEUFlag(false);

            if(selectedJurList.length === 2){
                _multipleJurisdictionList = _multipleJurisdictionList.map((jur) => {
                    let checkedFlag = false;

                    selectedJurList[1].jurList.map((selectedJur, index) => {
                        if(jur.crCode === selectedJur.crCode){
                            checkedFlag = true;
                            if (jur.buttonType === 'CHECK') {
                                chkCnt++;
                                if(jur.euYn === 'N'){
                                    chkNonEuCnt++;
                                }
                            }
                        }
                    });

                    jur.checked = checkedFlag;

                    if (jur.buttonType === 'CHECK') {
                        if(jur.euYn === 'N'){
                            nonEuCnt++; 
                        }

                        checkBoxCnt++;
                    }
                    return jur;
                });

                if(nonEuCnt === chkNonEuCnt && chkNonEuCnt === chkCnt){
                    setNonEUFlag(true);
                }else{
                    setNonEUFlag(false);
                }
                
                if(checkBoxCnt === chkCnt){
                    setChkAllFlag(true);
                }else{
                    setChkAllFlag(false);
                }

                dispatch(onChangeMultipleJurisdictionsList(_multipleJurisdictionList, props.filterName));
            }
        }

        setmodal_standard(!modal_standard);
        removeBodyCss();
    }

    const search = (keyword) => {
        keyword = keyword.toLowerCase();

        let _chkMultipleJurisdictionList = getMultipleJurisdictionList();
        const _multipleJurisdictionList = [];
        _chkMultipleJurisdictionList.map((jur, index) =>{
            let _jur = cloneDeep(jur);

            if(jur.crCode.toLowerCase().includes(keyword) == true || (jur.crName && jur.crName.toLowerCase().includes(keyword) == true)){
            _jur.show = true;
            }else{
            _jur.show = false;
            }

            _multipleJurisdictionList.push(_jur);
        }); 

        dispatch(onChangeMultipleJurisdictionsList(_multipleJurisdictionList, props.filterName));
    }

    const jurCheck = (str, checked, isRadio) => {
        let checkBoxCnt = 0;
        let chkCnt = 0;
        let nonEuCnt = 0;
        let chkNonEuCnt = 0;

        let _chkMultipleJurisdictionList = getMultipleJurisdictionList();

        const _multipleJurisdictionList = [];
        _chkMultipleJurisdictionList.map((jur, index) =>{
            let _jur = cloneDeep(jur);

            if((str === chkAll 
                || (str === nonEU && checked === false))
                && jur.buttonType === 'CHECK' && jur.show){
                _jur.checked = checked;
            }else if(str === nonEU && checked === true && jur.buttonType === 'CHECK' && jur.show){
                if(jur.euYn === 'N'){
                    _jur.checked = true;
                }else{
                    _jur.checked = false;
                }
            }else{
                if(jur.crCode === str){
                    if (jur.buttonType === 'CHECK') {
                        _jur.checked = checked;
                    } else {
                        _jur.checked = !_jur.checked;
                    }
                }

                if (isRadio && jur.crCode !== str && jur.buttonType === 'RADIO') {
                    _jur.checked = false;
                }
            }

            if (jur.buttonType === 'CHECK'){
                if(_jur.checked === true){
                    chkCnt++;

                    if(jur.euYn === 'N'){
                        chkNonEuCnt++;
                    }
                }

                if(jur.euYn === 'N'){
                    nonEuCnt++;
                }

                checkBoxCnt++;
            }

            _multipleJurisdictionList.push(_jur);
        }); 

        if(nonEuCnt === chkNonEuCnt && chkNonEuCnt === chkCnt){
            setNonEUFlag(true);
        }else{
            setNonEUFlag(false);
        }
        
        if(checkBoxCnt === chkCnt){
            setChkAllFlag(true);
        }else{
            setChkAllFlag(false);
        }

        dispatch(onChangeMultipleJurisdictionsList(_multipleJurisdictionList, props.filterName));
    }

    const jurConfirm = () => {
        let _jrCdList = [];

        let _chkMultipleJurisdictionList = getMultipleJurisdictionList();
        _chkMultipleJurisdictionList.map((jur, index) =>{
            if(jur.checked === true){
                _jrCdList.push(jur.crCode);
            }
        });

        // let _values = cloneDeep(props.formik.values);
        // _values.jrCdList = _jrCdList;
        // props.formik.setValues(_values);

        //saveSetting 기능에 필요
        if (props.filterName && props.filterName == 'myConnectedCase') {
            if(props.formik.values.jrCdListMyConnectedCase !== undefined){
                props.formik.setFieldValue("jrCdListMyConnectedCase", _jrCdList);
            }
        } else {
            props.formik.setFieldValue("jrCdList", _jrCdList);
        }

        openCountryModal();
    }

    const htmlSetting = (jrCdList) => {
        if(jrCdList && jrCdList.length){
            let _selectedJurList = [];
            let _tempList = [];
            let jurType = "";
            let chkNonEuCnt = 0;
            let nonEuAllCnt = 0;
            let checkBoxCnt = 0;
            let checkBoxAllCnt = 0;
            let checkCnt = 0;

            let _chkMultipleJurisdictionList = getMultipleJurisdictionList();
            _chkMultipleJurisdictionList.map((jur, index) =>{
                if(jrCdList.includes(jur.crCode)){
                    _tempList.push(jur);
                    if (jur.buttonType === 'CHECK') {
                        if (jur.euYn === 'N'){
                            chkNonEuCnt++;
                        }
                        checkBoxCnt++;
                    }
                    checkCnt++;
                }
                
                if (jur.buttonType === 'CHECK') {
                    if(jur.euYn === 'N'){
                        nonEuAllCnt++;
                    }
    
                    checkBoxAllCnt++;
                }
            });
            
            //console.log("htmlSetting jrCdList.length : "+ jrCdList.length + "//// jurisdictionList.length : " + jurisdictionList.length + "//// props.formik.values.inOutType : "+ props.formik.values.inOutType);
            if(checkCnt === checkBoxCnt && checkBoxCnt === checkBoxAllCnt){
                if(props.formik.values.inOutType && "IN" === props.formik.values.inOutType){
                    jurType = allSubs;
                }else{
                    jurType = chkAll;
                }
            }else if(checkCnt === chkNonEuCnt && chkNonEuCnt === nonEuAllCnt){
                jurType = nonEU;
            }else {
                jurType = individual;
            }
            
            _selectedJurList.push({'jurType' : jurType});
            _selectedJurList.push({'jurList' : _tempList});

            //saveSetting 기능에 필요
            if (props.filterName && props.filterName == 'myConnectedCase') {
                if(props.formik.values.jurTypeMyConnectedCase !== undefined){
                    props.formik.setFieldValue("jurTypeMyConnectedCase", jurType);
                }
            } else {
                if(props.formik.values.jurType !== undefined){
                    props.formik.setFieldValue("jurType", jurType);
                }
            }

            setSelectedJurList(_selectedJurList);
            
        }else{
            let jurType = "";
            if (props.filterName && props.filterName == 'myConnectedCase') {
                if (props.formik.values.jurTypeMyConnectedCase && props.formik.values.jurTypeMyConnectedCase === nonEU) {
                    jurType = nonEU;
                } else {
                    if(props.formik.values.inOutType && "IN" === props.formik.values.inOutType){
                        jurType = allSubs;
                    }else{
                        jurType = chkAll;
                    }
                }

                if(props.formik.values.jurTypeMyConnectedCase !== undefined){
                    props.formik.setFieldValue("jurTypeMyConnectedCase", jurType);
                }
            } else {
                if (props.formik.values.jurType && props.formik.values.jurType === nonEU) {
                    jurType = nonEU;
                } else {
                    if(props.formik.values.inOutType && "IN" === props.formik.values.inOutType){
                        jurType = allSubs;
                    }else{
                        jurType = chkAll;
                    }
                }

                if(props.formik.values.jurType !== undefined){
                    props.formik.setFieldValue("jurType", jurType);
                }
            }
            

            if (jurType === nonEU) {
                let _selectedJurList = [];
                let _tempList = [];
                let _chkMultipleJurisdictionList = getMultipleJurisdictionList();
                _chkMultipleJurisdictionList.map((jur, index) => {
                    if(jur.euYn === 'N' && jur.buttonType === 'CHECK'){
                        _tempList.push(jur);
                    }
                });
                _selectedJurList.push({'jurType' : jurType});
                _selectedJurList.push({'jurList' : _tempList});
                setSelectedJurList(_selectedJurList);
            } else {
                setSelectedJurList([]);
            }
        }

        // if (props.formik.values.screenName === "MadridNotifier") {
        //     dispatch(onChangeMadridSearchSettingsCountry('Jurisdictions', props.formik.values.inOutType, props.formik.values.jurType, props.formik.values.jrCdList));
        // } else if (props.formik.values.screenName === "FilerRankings") {
        //     dispatch(onChangeFilerSearchSettingsCountry('Jurisdictions', props.formik.values.inOutType, props.formik.values.jurType, props.formik.values.jrCdList));
        // }
    }

    useEffect(()=> {
        dispatch(onGetMultipleJurisdictionsList(props.formik.values.inOutType, props.formik.values.screenGrade, props.filterName));
        //inOutType이 undefined여도 호출됨
        if (props.filterName && props.filterName == 'myConnectedCase') {
            if(multipleJurisdictionList_MCC && multipleJurisdictionList_MCC.length > 0){
                htmlSetting(props.formik.values.jrCdListMyConnectedCase);
            }
        } else {
            if(multipleJurisdictionList && multipleJurisdictionList.length > 0){
                htmlSetting(props.formik.values.jrCdList);
            }
        }
    },[props.formik.values.inOutType]);

    useEffect(()=> {
        if (!props.filterName || props.filterName !== 'myConnectedCase') {
            if(multipleJurisdictionList && multipleJurisdictionList.length > 0){
                htmlSetting(props.formik.values.jrCdList);
            }
        }
    },[JSON.stringify(props.formik.values.jrCdList)]);

    useEffect(()=> {
        //search settings 불러올때 inOutType하고 jrCdList가 동시에 변경됨
        //onGetJurisdictionsList()이 완료되기 전에 htmlSetting()이 호출되면서 html이 잘못 나오는 이슈 보완
        if (!props.filterName || props.filterName !== 'myConnectedCase') {
            if(multipleJurisdictionList && multipleJurisdictionList.length > 0){
                htmlSetting(props.formik.values.jrCdList);
            }
        }
    },[multipleJurisdictionList]);
    
    useEffect(()=> {
        if (props.filterName && props.filterName == 'myConnectedCase') {
            if(multipleJurisdictionList_MCC && multipleJurisdictionList_MCC.length > 0){
                // console.log('JSON.stringify(props.formik.values.jrCdListMyConnectedCase) : ' + JSON.stringify(props.formik.values.jrCdListMyConnectedCase));
                htmlSetting(props.formik.values.jrCdListMyConnectedCase);
            }
        }
    },[JSON.stringify(props.formik.values.jrCdListMyConnectedCase)]);

    useEffect(()=> {
        //search settings 불러올때 inOutType하고 jrCdList가 동시에 변경됨
        //onGetJurisdictionsList()이 완료되기 전에 htmlSetting()이 호출되면서 html이 잘못 나오는 이슈 보완
        if (props.filterName && props.filterName == 'myConnectedCase') {
            if(multipleJurisdictionList_MCC && multipleJurisdictionList_MCC.length > 0){
                htmlSetting(props.formik.values.jrCdListMyConnectedCase);
            }
        }
    },[multipleJurisdictionList_MCC]);

    return (
        <>
        <Row className="mb-3">
            <Col md={props.firstColMd ? props.firstColMd : 3}>
                <label
                    className="col-form-label"
                >
                    Jurisdictions
                </label>
            </Col>
            <Col md={3}>
                <label
                    className="col-form-label"
                >
                {
                    (()=> {
                        if (selectedJurList && selectedJurList.length != 0) {
                            if(selectedJurList[0].jurType === chkAll){
                                return 'All (default)'
                            } else if(selectedJurList[0].jurType === allSubs){
                                return 'All subscribed (default)'
                            } else if(selectedJurList[0].jurType === nonEU){
                                return 'Non-EU'
                            } else {
                                return selectedJurList[1].jurList.map((selectedJur, index) => {
                                    return (<>{selectedJur.crCode} {selectedJur.crName} <br/></>)
                                });
                            }
                        }else{
                            if(props.formik.values.inOutType && "IN" === props.formik.values.inOutType){
                                return 'All subscribed (default)'
                            }else{
                                return 'All (default)'
                            }
                        }
                    })()
                }
                </label>
            </Col>
            <Col md={3}>
                <Tooltip
                    placement="right"
                    isOpen={tright}
                    target="selectJurisdictions"
                    toggle={() => {
                        settright(!tright)
                    }}
                >
                    Jurisdictions where you<br/> practice
                </Tooltip>
                <button
                    type="button"
                    className="btn btn-primary waves-effect waves-light"
                    style={{width : '148.58px'}}
                    id="selectJurisdictions"
                    onClick={() => {
                        openCountryModal();
                    }}
                    data-toggle="modal"
                    data-target="#myModal"
                >
                    Select jurisdictions
                </button>
            </Col>
        </Row>

        <Modal
            isOpen={modal_standard}
            toggle={() => {
            openCountryModal()
            }}
            scrollable={true}
        >
            <div className="modal-header" style={{marginRight: "20px"}}>
                <h5 className="modal-title mt-0" id="myModalLabel">
                    Select jurisdictions
                </h5>
                <div className="app-search" style={{padding : '0'}}>
                    <div className="position-relative">
                    <input
                        type="text"
                        className="form-control"
                        style={{border: "solid 1px"}}
                        placeholder="Search..."
                        onKeyPress={(e) => {
                        if(e.key === 'Enter'){
                            search(e.target.value);
                        }                      
                        }}
                    />
                    <span className="uil-search"></span>
                    </div>
                </div>
                <button
                    type="button"
                    onClick={() => {
                        openCountryModal(false)
                    }}
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                >
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div className="modal-body">
                {"IN" === props.formik.values.inOutType ?
                    subsJurisdictionCount > 0 ?
                    <>
                    <Row style={{margin : 'auto'}}>
                        <div className="col-md-6 form-check mb-2">
                            <Label style={{color: '#5c8dc6', marginLeft: '-1.5em'}}>
                            <i className="uil uil-award"></i> {props.formik.values.screenGrade} jurisdictions
                            </Label>
                        </div>
                    </Row>
                    <Row style={{margin : 'auto'}}>
                        <div className="col-md-3 form-check mb-2">
                            <input
                            type="checkbox"
                            className="form-check-input"
                            id={chkAll}
                            checked={chkAllFlag}
                            onClick={(e)=>jurCheck(chkAll, e.target.checked, false)}
                            />
                            <Label
                            className="form-check-label"
                            htmlFor={chkAll}
                            >
                            Select all
                            </Label>
                        </div>
                        <div className="col-md-3 form-check mb-2">
                            <input
                            type="checkbox"
                            className="form-check-input"
                            id={nonEU}
                            checked={nonEUFlag}
                            onClick={(e)=>jurCheck(nonEU, e.target.checked, false)}
                            />
                            <Label
                            className="form-check-label"
                            htmlFor={nonEU}
                            >
                            Non-EU
                            </Label>
                        </div>
                    </Row>
                    </>
                    :
                    <>
                    <Row style={{margin : 'auto'}}>
                        <div className="col-md-6 form-check mb-2">
                            <Label style={{color: '#5c8dc6', marginLeft: '-1.5em'}}>
                            <i className="uil uil-award"></i> {props.formik.values.screenGrade == 'Gold' ? props.formik.values.screenGrade : 'Gold (' + props.formik.values.screenGrade + ')'} jurisdictions
                            </Label>
                        </div>
                    </Row>
                    <Row style={{margin : 'auto'}}>
                        <div className="col-md-12 mb-2">
                            <Label
                            className="form-check-label"
                            >
                            Contact us at <a href="mailto:global@wecrest.com">global@wecrest.com</a> to access all features.
                            </Label>
                        </div>
                    </Row>
                    </>
                :
                <Row style={{margin : 'auto'}}>
                    <div className="col-md-3 form-check mb-2">
                        <input
                        type="checkbox"
                        className="form-check-input"
                        id={chkAll}
                        checked={chkAllFlag}
                        onClick={(e)=>jurCheck(chkAll, e.target.checked, false)}
                        />
                        <Label
                        className="form-check-label"
                        htmlFor={chkAll}
                        >
                        Select all
                        </Label>
                    </div>
                    <div className="col-md-3 form-check mb-2">
                        <input
                        type="checkbox"
                        className="form-check-input"
                        id={nonEU}
                        checked={nonEUFlag}
                        onClick={(e)=>jurCheck(nonEU, e.target.checked, false)}
                        />
                        <Label
                        className="form-check-label"
                        htmlFor={nonEU}
                        >
                        Non-EU
                        </Label>
                    </div>
                </Row> 
                }
                {
                    getMultipleJurisdictionList() && getMultipleJurisdictionList().length ?
                    getMultipleJurisdictionList().map((jur, index) => {
                        return (
                            jur.show && jur.buttonType === 'CHECK' ? 
                            <div className="form-check mb-2">
                                <input
                                type="checkbox"
                                className="form-check-input"
                                id={jur.crCode}
                                checked={jur.checked}
                                onClick={(e)=>jurCheck(jur.crCode, e.target.checked, false)}
                                />
                                <Label
                                className="form-check-label"
                                htmlFor={jur.crCode}
                                >
                                {jur.crCode} {jur.crName}
                                </Label>
                            </div>
                            
                            : null
                        )
                    })
                    :
                    null
                }

                {"IN" === props.formik.values.inOutType ?
                <Row style={{margin : 'auto', borderTop: '1px solid #f5f6f8'}}>
                    <div className="col-md-6 form-check mb-2 mt-2">
                        <Label style={{color: '#5c8dc6', marginLeft: '-1.5em'}}>
                        <i className="uil uil-gift"></i> Free jurisdictions
                        </Label>
                    </div>
                </Row>
                : null}

                {
                    getMultipleJurisdictionList() && getMultipleJurisdictionList().length ?
                    getMultipleJurisdictionList().map((jur, index) => {
                        return (
                            jur.show && jur.buttonType === 'RADIO' ? 
                            <div className="form-check mb-2">
                                <input
                                type="radio"
                                className="form-check-input"
                                name="jurRadios"
                                id={jur.crCode}
                                checked={jur.checked}
                                onClick={(e)=>jurCheck(jur.crCode, e.target.checked, true)}
                                />
                                <Label
                                className="form-check-label"
                                htmlFor={jur.crCode}
                                >
                                {jur.crCode} {jur.crName}
                                </Label>
                            </div>
                            
                            : null
                        )
                    })
                    :
                    null
                }
            </div>

            <div className="modal-footer">
                <button
                    type="button"
                    onClick={() => {
                        openCountryModal()
                    }}
                    className="btn btn-secondary waves-effect"
                    data-dismiss="modal"
                >
                    Cancel
                </button>
                <button
                    type="button"
                    className="btn btn-primary waves-effect waves-light"
                    onClick={()=> jurConfirm()}
                >
                    Confirm
                </button>
            </div>
        </Modal>
        </>
    )
};

export default SelectMultipleJurisdictions;