import React, {useState, useEffect} from 'react';
import { useSelector, useDispatch } from "react-redux";

import {
    Row,
    Tooltip,
    Col,
    Label,
    Modal,
} from "reactstrap"

import {
    getRequesterList as onGetRequesterList,
    changeRequesterList as onChangeRequesterList,
} from "../../store/actions";
import { cloneDeep } from 'lodash';
  
const SearchRequester = props => {
    const dispatch = useDispatch();

    const {requesterList} = useSelector(state => ({
        requesterList: state.EntityConnectManagement.requesterList,
    }));

    const [tright, settright] = useState(false);
    const [modal_standard, setmodal_standard] = useState(false);
    const [keyword, setKeyword] = useState("");

    function removeBodyCss() {
        document.body.classList.add("no_padding")
    }
    
    const openModal = () => {
        setmodal_standard(!modal_standard);
        removeBodyCss();
    }

    const search = (searchKeyword) => {
        dispatch(onGetRequesterList({ memberKeyword : searchKeyword}));
    }

    const targetCheck = (userNo) => {
        let _requesterList = [];

        requesterList.map((target, index) => {
            let _target = cloneDeep(target);

            if(target.userNo === userNo){
                _target.checked = true;
            } else {
                _target.checked = false;
            }

            _requesterList.push(_target);
        }); 

        dispatch(onChangeRequesterList(_requesterList));
    }

    const targetConfirm = () => {
        let _selectedUserInfo = {};

        requesterList.map((target, index) => {
            if(target.checked === true){
                _selectedUserInfo = cloneDeep(target);
            }
        });

        props.filterValidation.setFieldValue("userNo", _selectedUserInfo.userNo);
        props.filterValidation.setFieldValue("userName", _selectedUserInfo.userName);

        openModal();
    }

    const targetClear = () => {
        props.filterValidation.setFieldValue("userNo", "");
        props.filterValidation.setFieldValue("userName", "");

        // openModal();
    }

    return (
        <>
        <Row className="mb-3">
            <label className="col-md-2 col-form-label">
            Requester
            </label>
            <div className="col-md-3">
                <input
                    className="form-control"
                    type="text"
                    placeholder="Username"
                    name="username"
                    onClick={() => {
                        openModal();
                    }}
                    value={props.filterValidation.values.userName ? props.filterValidation.values.userName : ''}
                />
            </div>
            <Col md={3}>
                <Row className="gap-2">
                    <button
                        type="button"
                        className="btn btn-primary waves-effect waves-light col-md-5"
                        id="SearchRequester"
                        onClick={() => {
                            openModal();
                        }}
                        data-toggle="modal"
                        data-target="#myModal"
                    >
                        Search
                    </button>
                    <button 
                        type="button" 
                        class="btn btn-warning waves-effect col-md-5"
                        onClick={()=> targetClear()}
                    >
                        Clear
                    </button>
                </Row>
            </Col>
        </Row>

        <Modal
            size="lg"
            isOpen={modal_standard}
            toggle={() => {
            openModal()
            }}
            scrollable={true}
        >
            <div className="modal-header" style={{marginRight: "20px"}}>
                <h6 className="modal-title mt-0 col-md-6" id="myModalLabel">
                Search existing or enter new username
                </h6>
                <div className="app-search col-md-6" style={{padding : '0', marginLeft: '0px'}}>
                    <div className="position-relative">
                        <input
                            type="text"
                            className="form-control"
                            style={{border: "solid 1px"}}
                            placeholder="Search..."
                            value={keyword}
                            onChange={(e) => {setKeyword(e.target.value)}}
                            onKeyDown={(e) => {
                                if(e.code === 'Enter'){
                                    search(e.target.value);
                                }                      
                            }}
                        />
                        <span className="uil-search"></span>
                    </div>
                </div>
                <button
                    type="button"
                    onClick={() => {
                        openModal()
                    }}
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                >
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div className="modal-body">
                {
                    requesterList.length ?
                    requesterList.map((userInfo, index) => {
                        return (
                            <Row className="mb-3">
                                <div className="col-md-12 form-check" style={{paddingLeft : '2em'}} onClick={(e)=>targetCheck(userInfo.userNo)}>
                                    <input
                                    className="form-check-input"
                                    type="radio"
                                    name="admSearchUser"
                                    id={"admSearchUser-" + userInfo.userNo}
                                    value="Invoice"
                                    checked={userInfo.checked}
                                    />
                                    <label
                                    className="form-check-label"
                                    htmlFor="admSearchUser"
                                    >
                                    {userInfo.userName}
                                    </label>
                                </div>
                            </Row>
                        )
                    })
                    : null
                }
            </div>
            <div className="modal-footer">
                <button
                    type="button"
                    onClick={() => {
                    openModal()
                    }}
                    className="btn btn-secondary waves-effect"
                    data-dismiss="modal"
                >
                    Cancel
                </button>
                {/*
                <button 
                    type="button" 
                    class="btn btn-warning waves-effect"
                    onClick={()=> targetClear()}
                >
                    Clear
                </button>
                */}
                <button
                    type="button"
                    className="btn btn-primary waves-effect waves-light"
                    onClick={()=> targetConfirm()}
                >
                    Confirm
                </button>
            </div>
        </Modal>
        </>
    )
};

export default SearchRequester;