import { GET_EMAIL_FORM } from "../../helpers/url_helper"
import {
  GET_EMAIL_TEMPLATES,
  GET_EMAIL_TEMPLATES_SUCCESS,
  GET_EMAILFORM,
  GET_EMAILFORM_SUCCESS,
  UPDATE_EMAILFORM,
  UPDATE_EMAILFORM_SUCCESS,
  UPDATE_EMAILFORM_ERROR,
  DELETE_EMAILFORM,
  DELETE_EMAILFORM_SUCCESS,
  SET_REDUCER_VALUE,
} from "./actionTypes"

export const getEmailTemplates = data => {
  // console.log('getEmailTemplates action : ' + JSON.stringify(data))
  return {
    type: GET_EMAIL_TEMPLATES,
    payload: data,
  }
}

export const getEmailTemplatesSuccess = data => {
  // console.log('getEmailTemplatesSuccess action : ' + data)
  
  return {
    type: GET_EMAIL_TEMPLATES_SUCCESS,
    payload: data,
  }
}

export const getEmailForm = data => {
  // console.log('getEmailForm action : ' + data)
  return {
    type: GET_EMAILFORM,
    payload: data,
  }
}

export const getEmailFormSuccess = data => {
  // console.log('getEmailFormSuccess action : ' + data)
  
  return {
    type: GET_EMAILFORM_SUCCESS,
    payload: data,
  }
}

export const updateEmailForm = formData => {
  return {
    type: UPDATE_EMAILFORM,
    payload: formData,
  }
}

export const updateEmailFormSuccess = data => {
  return {
    type: UPDATE_EMAILFORM_SUCCESS,
    payload: data,
  }
}

export const updateEmailFormError = data => {
  return {
    type: UPDATE_EMAILFORM_ERROR,
    payload: data,
  }
}

export const deleteEmailForm = data => {
  return {
    type: DELETE_EMAILFORM,
    payload: data,
  }
}

export const deleteEmailFormSuccess = data => {
  return {
    type: DELETE_EMAILFORM_SUCCESS,
    payload: data,
  }
}

export const setReducerValue = (type, data) => {
  return {
    type: SET_REDUCER_VALUE,
    payload: {type, data},
  }
}