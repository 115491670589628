import {
  SET_ADM_INVOICE_MANAGEMENT_LOADING,
  CHANGE_ADM_INVOICE_MANAGEMENT_SEARCH_LIST,
  GET_ADM_INVOICE_MANAGEMENT_SEARCH_LIST,
  GET_ADM_INVOICE_MANAGEMENT_SEARCH_LIST_SUCCESS,
  DOWN_INVOICE_PDF,
  DOWN_INVOICE_PDF_SUCCESS,
  UPDATE_INVOICE_STATUS,
  UPDATE_INVOICE_STATUS_SUCCESS,
  SET_SUCCESS,
  SET_RESULT,
  CONFIRM_PAYMENT,
  CONFIRM_PAYMENT_SUCCESS,
  GET_SEARCH_USER_LIST,
  GET_SEARCH_USER_LIST_SUCCESS,
  CHANGE_SEARCH_USER_LIST,
  CHANGE_ADM_INVOICE_MANAGEMENT_USER_INFO,
  CHANGE_ADM_INVOICE_MANAGEMENT_ITEM_LIST,
  CHANGE_USER_INVOICE_INFO_LIST,
  GET_SUBSCRIPTION_ITEM_ALL_LIST,
  GET_SUBSCRIPTION_ITEM_ALL_LIST_SUCCESS,
  CHANGE_SUBSCRIPTION_ITEM_ALL_LIST,
  GET_CRESTER_LIST,
  GET_CRESTER_LIST_SUCCESS,
  SET_ADM_INVOICE_MANAGEMENT_SUCCESS,
  SET_ADM_INVOICE_MANAGEMENT_ERROR,
  CREATE_ADMIN_INVOICE,
  CREATE_ADMIN_INVOICE_SUCCESS,
  GET_USER_INVOICE_INFO_LIST,
  GET_USER_INVOICE_INFO_LIST_SUCCESS,
  
  PREVIEW_INVOICE_ITEM,
  PREVIEW_INVOICE_ITEM_SUCCESS,
  GET_INVOICE_ITEM,
  GET_INVOICE_ITEM_SUCCESS,
} from "./actionTypes"

export const setAdmInvoiceManagementLoading = data => {
  return {
    type: SET_ADM_INVOICE_MANAGEMENT_LOADING,
    payload: data,
  }
}

export const changeAdmInvoiceManagementSearchList = data => {
  return {
    type: CHANGE_ADM_INVOICE_MANAGEMENT_SEARCH_LIST,
    payload: data,
  }
}

export const getAdmInvoiceManagementSearchList = data => {
  return {
    type: GET_ADM_INVOICE_MANAGEMENT_SEARCH_LIST,
    payload: data,
  }
}

export const getAdmInvoiceManagementSearchListSuccess = data => {
  return {
    type: GET_ADM_INVOICE_MANAGEMENT_SEARCH_LIST_SUCCESS,
    payload: data,
  }
}

export const downInvoicePdf = (data, fileName) => {
  return {
    type: DOWN_INVOICE_PDF,
    payload: {data, fileName},
  }
}

export const downInvoicePdfSuccess = () => {
  return {
    type: DOWN_INVOICE_PDF_SUCCESS,
  }
}

export const updateInvoiceStatus = (filterParam, updateParam) => {
  return {
    type: UPDATE_INVOICE_STATUS,
    payload: {filterParam, updateParam},
  }
}

export const updateInvoiceStatusSuccess = data => {
  return {
    type: UPDATE_INVOICE_STATUS_SUCCESS,
    payload: data,
  }
}

export const setAdmInvoiceManagementSearchSuccess = data => {
  return {
    type: SET_SUCCESS,
    payload: data,
  }
}

export const setAdmInvoiceManagementSearchResult = data => {
  return {
    type: SET_RESULT,
    payload: data,
  }
}

export const confirmPayment = (filterParam, confirmParam) => {
  return {
    type: CONFIRM_PAYMENT,
    payload: {filterParam, confirmParam},
  }
}

export const confirmPaymentSuccess = data => {
  return {
    type: CONFIRM_PAYMENT_SUCCESS,
    payload: data,
  }
}

export const getSearchUserList = data => {
  return {
    type: GET_SEARCH_USER_LIST,
    payload: data,
  }
}

export const getSearchUserListSuccess = data => {
  return {
    type: GET_SEARCH_USER_LIST_SUCCESS,
    payload: data,
  }
}

export const changeSearchUserList = data => {
  return {
    type: CHANGE_SEARCH_USER_LIST,
    payload: data,
  }
}

export const changeAdmInvoiceManagementUserInfo = data => {
  return {
    type: CHANGE_ADM_INVOICE_MANAGEMENT_USER_INFO,
    payload: data,
  }
}

export const changeAdmInvoiceManagementItemList = data => {
  return {
    type: CHANGE_ADM_INVOICE_MANAGEMENT_ITEM_LIST,
    payload: data,
  }
}

export const changeUserInvoiceInfoList = data => {
  return {
    type: CHANGE_USER_INVOICE_INFO_LIST,
    payload: data,
  }
}

export const getSubscriptionItemAllList = data => {
  return {
    type: GET_SUBSCRIPTION_ITEM_ALL_LIST,
    payload: data,
  }
}

export const getSubscriptionItemAllListSuccess = data => {
  return {
    type: GET_SUBSCRIPTION_ITEM_ALL_LIST_SUCCESS,
    payload: data,
  }
}

export const changeSubscriptionItemAllList = data => {
  return {
    type: CHANGE_SUBSCRIPTION_ITEM_ALL_LIST,
    payload: data,
  }
}

export const getCresterList = data => {
  return {
    type: GET_CRESTER_LIST,
    payload: data,
  }
}

export const getCresterListSuccess = data => {
  return {
    type: GET_CRESTER_LIST_SUCCESS,
    payload: data,
  }
}

export const setAdmInvoiceManagementSuccess = data => {
  return {
    type: SET_ADM_INVOICE_MANAGEMENT_SUCCESS,
    payload: data,
  }
}

export const setAdmInvoiceManagementError = data => {
  return {
    type: SET_ADM_INVOICE_MANAGEMENT_ERROR,
    payload: data,
  }
}

export const createAdminInvoice = formData => {
  return {
    type: CREATE_ADMIN_INVOICE,
    payload: formData,
  }
}

export const createAdminInvoiceSuccess = data => {
  return {
    type: CREATE_ADMIN_INVOICE_SUCCESS,
    payload: data,
  }
}


export const getUserInvoiceInfoList = data => {
  return {
    type: GET_USER_INVOICE_INFO_LIST,
    payload: data,
  }
}

export const getUserInvoiceInfoListSuccess = data => {
  return {
    type: GET_USER_INVOICE_INFO_LIST_SUCCESS,
    payload: data,
  }
}

export const previewInvoiceItem = data => {
  return {
    type: PREVIEW_INVOICE_ITEM,
    payload: data,
  }
}

export const previewInvoiceItemSuccess = data => {
  return {
    type: PREVIEW_INVOICE_ITEM_SUCCESS,
    payload: data,
  }
}

export const getInvoiceItem = data => {
  return {
    type: GET_INVOICE_ITEM,
    payload: data,
  }
}

export const getInvoiceItemSuccess = data => {
  return {
    type: GET_INVOICE_ITEM_SUCCESS,
    payload: data,
  }
}
