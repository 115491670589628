import PropTypes from "prop-types"
import React, { useState, useEffect } from "react";
import { Row, Col, CardBody, Card, Alert, Container, Form, Input, FormFeedback, Label, Modal } from "reactstrap";

// Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";

// action
import { 
  registerUser
  , registerUserFailed
  , closeRegisterUserSuccessfulModal
  , setRegisterLoading as onSetRegisterLoading 
} from "../../store/actions"

// Redux
import { useSelector, useDispatch } from "react-redux";

import { withRouter, Link, useHistory } from "react-router-dom";


// import images
import logo from "../../assets/images/logo-dark.png"
import logolight from "../../assets/images/logo-light.png"
// import adv from "../../assets/images/adv.png"

import SelectHomeCountry from '../../components/Filter/SelectHomeCountry';

import './Login.css';
import EmailSvg from './EmailSvg'
import LoadingModal from "../../components/Common/LoadingModal"

const Register = props => {
  // const history = useHistory();
  const dispatch = useDispatch();

  const validateForm = (values, props /* only available when using withFormik */) => {
    const errors = {};

    if (!values.userName || values.userName.trim() === "") {
      errors.userName = "Please enter a work email address";
    }
    if (!/^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*\.[a-zA-Z]{2,15}$/i.test(values.userName)) {
      errors.userName = "Please enter a valid work email address.";
    }
    if (!values.firstName || values.firstName.trim() === "") {
      errors.firstName = "Please enter your first name";
    }
    if (!values.lastName || values.lastName.trim() === "") {
      errors.lastName = "Please enter your last name";
    }
    if (!values.companyName || values.companyName.trim() === "") {
      errors.companyName = "Please enter the name of your organization";
    }
    if (!values.password || values.password.trim() === "") {
      errors.password = "Please enter a password";
    }
    if (values.crCdList.length === 0) {
      dispatch(registerUserFailed("Please select your home country"));
      errors.crCdList = "Please enter the name of your organization";
    }
    if (values.tncCheck === false) {
      dispatch(registerUserFailed("Please tick the box to agree to the WeCrest Terms & Conditions"));
      errors.tncCheck = "Please enter the name of your organization";
    }
  
    return errors;
  };

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      firstName: '',
      lastName: '',
      userName: '',
      companyName: '',
      password: '',
      passwordType: "password",
      passwordIcon: "col-md-1 uil-eye",
      crCdList: [],
      countryName: '',
      defaultIb: '',
      country: '',
      tncCheck: false,
      regUrl: '/register'
    },
    // validationSchema: Yup.object({
    //   firstName: Yup.string().required("Please enter your first name"),
    //   lastName: Yup.string().required("Please enter your last name"),
    //   userName: Yup.string().required("Please enter your work email address"),
    //   companyName: Yup.string().required("Please enter the name of your organization"),
    //   password: Yup.string().required("Please enter a password"),
    //   // crCdList: Yup.array().min(1, "Please select at least one jurisdiction"),
    //   // tncCheck: Yup.boolean().oneOf([true], 'Please check the box to agree to the WeCrest Terms & Conditions'),
    // }),
    onSubmit: (values, {setErrors}) => {
      let formError = validateForm(values);

      // console.log('formError : ' + JSON.stringify(formError));
      if (JSON.stringify(formError) != '{}') {
        setErrors(formError);
      } else {
        values.country = JSON.stringify(values.crCdList).replace('["', '').replace('"]', '');
        // console.log('onSubmit check end : ' + JSON.stringify(values));
        dispatch(registerUser(values, props.history));
      }
    }
  });

  const { registrationFlag, registrationError, registerUserSuccessfulModalOpen, loading } = useSelector(state => ({
    registrationFlag: state.Account.registrationFlag,
    registrationError: state.Account.registrationError,
    registerUserSuccessfulModalOpen: state.Account.registerUserSuccessfulModalOpen,
    loading: state.Account.loading,
  }));

  // handleValidSubmit
  // const handleValidSubmit = (event, values) => {
  //   dispatch(registerUser(values));
  // }

  function removeBodyCss() {
    document.body.classList.add("no_padding")
  }

  const setRegisterLoading = () => {
    dispatch(onSetRegisterLoading(!loading));
  }

  useEffect(() => {
    dispatch(registerUserFailed(""));
    document.body.className = "authentication-bg";
    // remove classname when component will unmount
    return function cleanup() {
      document.body.className = "";
    };
  }, [dispatch]);

  return (
    <React.Fragment>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row>
            <Col lg={12}>
              <div className="text-center">
                <a href="https://www.wecrest.com" target="_blank" className="mb-5 d-block auth-logo">
                  <img src={logo} alt="" height="50" className="logo logo-dark" />
                  <img src={logolight} alt="" height="50" className="logo logo-light" />
                </a>
              </div>
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={7}>
					    <iframe width="100%" className="reg-page-vod-height" src="https://www.youtube.com/embed/ldU_bVM6ynE?autoplay=1" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
              {/*
              <Card>
                <CardBody style={{textAlign: "center"}}>
                  <img src={adv} alt="" className="reg-adv" />
                </CardBody>
              </Card>
              */}
            </Col>
            <Col md={8} lg={6} xl={5}>
              <Card>
                <CardBody className="p-4">
                  <div className="text-center mt-2">
                    <h5 className="text-primary">Get access to pending trademark cases</h5>
                    <p className="mt-4"><a className="text-muted">Can you handle provisional</a> <b>trademark refusals</b> <a className="text-muted">from the</a> <b>Madrid System</b><a className="text-muted">?</a></p>
                    <p className="text-muted">Use the latest data technology to find new clients with many cases in your jurisdictions, or help those clients with pending cases immediately.</p>
                    <p><b>Open a Free Account</b> <a className="text-muted">and find a new client now!</a></p>
                  </div>
                  <div className="p-2 mt-4">
                  <Form
                      className="form-horizontal"
                      onSubmit={(e) => {
                        e.preventDefault();
                        validation.handleSubmit();
                        return false;
                      }}
                    >
                     {registrationFlag ? (
                        <Alert color="success">
                          Register User Successfully
                        </Alert>
                      ) : null}

                      {registrationError && registrationError ? (
                        <Alert color="danger">{registrationError}</Alert>
                      ) : null}

                      <div className="mb-3">
                      {/*<Label className="form-label">First name</Label>*/}
                        <Input
                          name="firstName"
                          type="text"
                          placeholder="First name"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.firstName || ""}
                          invalid={
                            validation.touched.firstName && validation.errors.firstName ? true : false
                          }
                        />
                        {validation.touched.firstName && validation.errors.firstName ? (
                          <FormFeedback type="invalid">{validation.errors.firstName}</FormFeedback>
                        ) : null}
                      </div>
                      
                      <div className="mb-3">
                      {/*<Label className="form-label">Last name</Label>*/}
                        <Input
                          name="lastName"
                          type="text"
                          placeholder="Enter last name"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.lastName || ""}
                          invalid={
                            validation.touched.lastName && validation.errors.lastName ? true : false
                          }
                        />
                        {validation.touched.lastName && validation.errors.lastName ? (
                          <FormFeedback type="invalid">{validation.errors.lastName}</FormFeedback>
                        ) : null}
                      </div>

                      <div className="mb-3">
                      {/*<Label className="form-label">Work email address</Label>*/}
                        <Input
                          id="userName"
                          name="userName"
                          className="form-control"
                          placeholder="Work email address"
                          type="email"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.userName || ""}
                          invalid={
                            validation.touched.userName && validation.errors.userName ? true : false
                          }
                        />
                        {validation.touched.userName && validation.errors.userName ? (
                          <FormFeedback type="invalid">{validation.errors.userName}</FormFeedback>
                        ) : null}
                      </div>
                      
                      <div className="mb-3">
                      {/*<Label className="form-label">Organization</Label>*/}
                        <Input
                          name="companyName"
                          type="text"
                          placeholder="Organization"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.companyName || ""}
                          invalid={
                            validation.touched.companyName && validation.errors.companyName ? true : false
                          }
                        />
                        {validation.touched.companyName && validation.errors.companyName ? (
                          <FormFeedback type="invalid">{validation.errors.companyName}</FormFeedback>
                        ) : null}
                      </div>

                      <div className="mb-3 d-flex vertical-align-center gap-2">
                      {/*<Label className="form-label">Password</Label>*/}
                        <div className="col-md-11">
                          <Input
                            name="password"
                            type={validation.values.passwordType}
                            placeholder="Password"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.password || ""}
                            invalid={
                              validation.touched.password && validation.errors.password ? true : false
                            }
                          />
                          {validation.touched.password && validation.errors.password ? (
                            <FormFeedback type="invalid">{validation.errors.password}</FormFeedback>
                          ) : null}
                        </div>
                        <i className={validation.values.passwordIcon} style={{color:"#5c8dc6", fontSize:"18px", cursor:"pointer"}} 
                          onClick={()=>{
                            if (validation.values.passwordType === "password") {
                              validation.setFieldValue("passwordType", "text");
                              validation.setFieldValue("passwordIcon", "col-md-1 uil-eye-slash");
                            } else {
                              validation.setFieldValue("passwordType", "password");
                              validation.setFieldValue("passwordIcon", "col-md-1 uil-eye");
                            }
                          }}></i>
                      </div>

                      <div className="mb-3">
                        <SelectHomeCountry
                          formik={validation}
                          componentName='RadioRegist'
                          firstColMd={4}
                        />
                      </div>

                      <div className="mb-3">
                        <label className="form-check-label" htmlFor="auth-terms-condition-check">I agree to the <a href="https://www.wecrest.com/terms-conditions" target="_blank" className="text-primary" rel="noreferrer">Terms and Conditions</a>.</label>&nbsp;
                        <input name="tncCheck" type="checkbox" className="form-check-input" id="auth-terms-condition-check" checked={validation.values.tncCheck} onChange={validation.handleChange}/>
                      </div>

                      <div className="mt-3 text-end">
                        <button
                          className="btn btn-primary w-sm waves-effect waves-light"
                          type="submit"
                        >
                          Get free access
                        </button>
                      </div>

                      {/*
                      <div className="mt-4 text-center">
                        <div className="signin-other-title">
                          <h5 className="font-size-14 mb-3 title">Sign up using</h5>
                        </div>

                        <ul className="list-inline">
                          <li className="list-inline-item">
                            <Link
                              to="#"
                              className="social-list-item bg-primary text-white border-primary"
                            >
                              <i className="mdi mdi-facebook" />
                            </Link>
                          </li>{" "}
                          <li className="list-inline-item">
                            <Link
                              to="#"
                              className="social-list-item bg-info text-white border-info"
                            >
                              <i className="mdi mdi-twitter" />
                            </Link>
                          </li>{" "}
                          <li className="list-inline-item">
                            <Link
                              to="#"
                              className="social-list-item bg-danger text-white border-danger"
                            >
                              <i className="mdi mdi-google" />
                            </Link>
                          </li>
                        </ul>
                      </div>
                      */}

                      <div className="mt-4 text-center">
                        <p className="text-muted mb-0">Already have an account? <Link to="/login" className="fw-medium text-primary"> Login</Link></p>
                        <Link to="/reset-password" className="fw-medium text-primary">Forgot password?</Link>
                      </div>

                    </Form>

                  </div>
                  </CardBody>
              </Card>
              
            </Col>
            <div className="mt-5 text-center">
                <p>© WeCrest Ltd 2017-{new Date().getFullYear()} all rights reserved</p>
              </div>
          </Row>
          {/*레이어 팝업 - 이메일 인증 완료 */}
          <Modal
            size="lg"
            isOpen={registerUserSuccessfulModalOpen}
            toggle={() => {
              dispatch(closeRegisterUserSuccessfulModal())
              removeBodyCss()
            }}
            id="activeEmailModal"
          >
            <div className="welcome--head">
                <EmailSvg/>
            </div>
            <div className="welcome--body">
              <p class="welcome--title">Thank you!</p>
              <p class="welcome--text">Please go to your inbox and confirm your email.</p>
            </div>
            <div class="modal-footer">
                <button 
                  type="button" 
                  className="waves-effect waves-light btn btn-primary" 
                  onClick={() => {
                    window.location.href='/'
                  }}>
                  Done
                </button>
            </div>
          </Modal>
          </Container>
      </div>
      {
        loading ?
        <LoadingModal loading={loading} setLoading={setRegisterLoading}></LoadingModal>
        : null
      }
    </React.Fragment>
  )
}
export default withRouter(Register);

Register.propTypes = {
  registerUser: PropTypes.func,
  // registerUserFailed: PropTypes.func,
  registrationError: PropTypes.any,
  history: PropTypes.object,
}



