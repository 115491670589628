import React, {useState, useEffect} from 'react';
import { useSelector, useDispatch } from "react-redux";
import {
    Table,
    Row,
    Col,
    Card,
    CardBody,
    CardTitle,
    Media,
    Collapse,
    Form,
    Input,
    Label,
    Button,
    FormFeedback,
  } from "reactstrap"
import SweetAlert from "react-bootstrap-sweetalert";
import LoadingModal from "../../components/Common/LoadingModal"
import { Link } from "react-router-dom";
import { useFormik } from "formik";
import classnames from "classnames"
import SearchRequester from "./SearchRequester"

import {
  getUserEntityConnectClaimList,
  downExcelUserEntityConnectClaimList,
  confirmUserEntityConnectClaim,
  modifyUserEntityConnectClaimStatus,
  changeUserEntityConnectClaimList,
  setEntityConnectManagementSuccess as onSetSuccess,
  setEntityConnectManagementLoading as onSetLoading,
} from "../../store/actions";
import { cloneDeep } from 'lodash';
import dateFormat from 'dateformat';

import '../../components/Common/Common.css';

const AdmEntityConnectManagement = props => {
  const dispatch = useDispatch();

  const { loading, success, totalPage, userEntityConnectClaimList } = useSelector(state => ({
    loading: state.EntityConnectManagement.loading,
    success: state.EntityConnectManagement.success,
    totalPage: state.EntityConnectManagement.totalPage,
    userEntityConnectClaimList: state.EntityConnectManagement.userEntityConnectClaimList,
  }));

  const [curPageInput, setCurPageInput] = useState(1);
  const [chkAllFlag, setChkAllFlag] = useState(false);
  const [alertMsg, setAlertMsg] = useState("");

  const [isOpen, setIsOpen] = useState(true);
  const toggle = () => {
    setIsOpen(!isOpen);
  }

  const [columns, setColumns] = useState([
    {label: "Gold + Trial Count", asc:"GACA", desc:"GACD", ascActive:null, descActive:null},
  ]);

  const viewList = (sortType, curPage, pageSize) => {
    //curPage 유효성 체크
    if(curPage){
      if(curPage > totalPage) {
        curPage = totalPage;
      } else if (curPage <= 0) {
        curPage = 1;
      }
    }

    //빨간색으로 변경
    if(sortType){
      const _columns = [];
      columns.map((col, index)=>{
        let _col = cloneDeep(col);
        if(col.asc && col.asc === sortType){
          _col.ascActive = "text-danger";
          _col.descActive = "";
        }else if(col.desc && col.desc === sortType ){
          _col.ascActive = "";
          _col.descActive = "text-danger";
        }else{
          _col.ascActive = "";
          _col.descActive = "";
        }
        _columns.push(_col);
      });

      setColumns(_columns);
    }

    // console.log("viewMadridNotifier [sortType: "+sortType+", curPage: "+curPage+", pageSize: "+pageSize);
    // let _values = cloneDeep(madridValidation.values);
    // _values.submitType = "view";
    filterValidation.setFieldValue("submitType", "view");
   
    if(curPage){
      // _values.curPage = curPage;
      filterValidation.setFieldValue("curPage", curPage);
      setCurPageInput(curPage);
    }else{
      // _values.curPage = 1;
      filterValidation.setFieldValue("curPage", 1);
      setCurPageInput(1);
    }
    if(pageSize){
      // _values.pageSize = pageSize;
      filterValidation.setFieldValue("pageSize", pageSize);
    }
    // madridValidation.setValues(_values);

    if(sortType){
      filterValidation.setFieldValue("sortType", sortType);
    }
    
    filterValidation.handleSubmit(filterValidation.values);
  }

  const filterValidation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      submitType: "view",
      sortType: "",
      userNo: "",
      userName: "",
      entity: "",
      domain: "",
      type: "",
      status: "",
      memo: "",
      fromDate: undefined,
      toDate: undefined,
      pageSize: 20,
      curPage: 1,
    },
    
    onSubmit: (values) => {
      // console.log(JSON.stringify(values));

      const filterParam = {
        userNo: values["userNo"],
        entity: values["entity"],
        domain: values["domain"],
        searchType: values["type"],
        status: values["status"],
        startDate: values["fromDate"] && values["fromDate"].replaceAll('-', ''),
        endDate: values["fromDate"] && values["fromDate"].replaceAll('-', ''),
        pageSize: values["pageSize"],
        curPage: values["curPage"],
        sortType: values["sortType"],
      };

      let submitType = values["submitType"];
      
      if(submitType === "view"){
        dispatch(getUserEntityConnectClaimList(filterParam));              
      } else if (submitType === "confirmSelected"){
        let _userEntityConnectClaimList = [];
        userEntityConnectClaimList.map((claimInfo, entityIdx) => {
          if (claimInfo.checked) {
            let _claimInfo = cloneDeep(claimInfo);
            _claimInfo.status = "Confirmed";
            _userEntityConnectClaimList.push(_claimInfo);
          }
        })

        let _alertMsg = [];
        _userEntityConnectClaimList.map((claimInfo, entityIdx) => {
          if ((claimInfo.type === 'Disconnect' || claimInfo.type === 'IDNM' || claimInfo.type === 'IDC' || claimInfo.type === 'SENBDC' || claimInfo.type === 'CWAA') && (claimInfo.domain === '' || claimInfo.url === '')) {
            let chkStr = "";
            if (claimInfo.domain === '') {
              chkStr = 'domain'
            }

            if (claimInfo.url === '') {
              chkStr += (chkStr !== '' ? ', ' : '') + 'url'
            }
              
            _alertMsg.push(claimInfo.currentTargetGroupName + "'s " + chkStr + " field is empty.");
          }
        })

        if (_alertMsg.length > 0) {
          setAlertMsg(_alertMsg.toString());
          setSelectedButton("");
        } else {
          dispatch(confirmUserEntityConnectClaim(_userEntityConnectClaimList, filterParam));
          setSelectedButton("");
          setChkAllFlag(false);
        }
      } else if (submitType === "denySelected"){
        let _userEntityConnectClaimList = [];
        userEntityConnectClaimList.map((claimInfo, entityIdx) => {
          if (claimInfo.checked) {
            let _claimInfo = cloneDeep(claimInfo);
            _claimInfo.status = "Denied";
            _claimInfo.memo = values["memo"];
            _userEntityConnectClaimList.push(_claimInfo);
          }
        })
        dispatch(modifyUserEntityConnectClaimStatus(_userEntityConnectClaimList, filterParam));
        setSelectedButton("");
        setChkAllFlag(false);
      } else if (submitType === "confirm"){
        dispatch(confirmUserEntityConnectClaim([cloneDeep(changeClaimInfo)], filterParam));
        setChangeClaimInfo({userNo:0})
      } else if (submitType === "deny"){
        dispatch(modifyUserEntityConnectClaimStatus([cloneDeep(changeClaimInfo)], filterParam));
        setChangeClaimInfo({userNo:0})
      }
    }
  })

  const [entityCheckCnt, setEntityCheckCnt] = useState(0);

  const entityCheck = (entityChkIdx, value) => {
    let _userEntityConnectClaimList = cloneDeep(userEntityConnectClaimList);
    let _entityCheckCnt = 0;

    if(entityChkIdx === "chkAll"){
      _userEntityConnectClaimList = _userEntityConnectClaimList.map((claimInfo, idx) => {
        claimInfo.checked = value;
        return claimInfo;
      })
    }else{
      _userEntityConnectClaimList[entityChkIdx].checked = value
    }

    _userEntityConnectClaimList.map((claimInfo, idx) => {
      if (claimInfo.checked) {
        _entityCheckCnt++;
      }
    })

    setChkAllFlag(_entityCheckCnt === _userEntityConnectClaimList.length);
    setEntityCheckCnt(_entityCheckCnt);

    dispatch(changeUserEntityConnectClaimList(_userEntityConnectClaimList));
  }

  const setLoading = () => {
    dispatch(onSetLoading(!loading));
  }

  const [changeClaimInfo, setChangeClaimInfo] = useState({userNo:0});
  const [selectedButton, setSelectedButton] = useState("");

  return (
    <React.Fragment>
      <Form onSubmit={filterValidation.handleSubmit}>
        <div className="page-content">
          <div className="container-fluid">
            <Row>
              <Col lg={12}>
                <Card>
                  <Link onClick={toggle} className="text-dark" to="#">
                    <div className="p-4">
                      <Media className="d-flex align-items-center">
                        <div className="flex-shrink-0 me-3">
                          <i className="uil uil-object-group text-primary h2"></i>
                        </div>
                        <div className="flex-grow-1 overflow-hidden">
                          <h5 className="font-size-16 mb-1">Entity management</h5>
                        </div>
                        <i className={isOpen ? "mdi mdi-chevron-up accor-down-icon font-size-24" : "mdi mdi-chevron-down accor-down-icon font-size-24"} style={{ cursor: "pointer"}}></i>
                      </Media>
                    </div>
                  </Link>
                  <Collapse isOpen={isOpen}>
                    <div className="p-4 border-top">
                      <div>
                        <SearchRequester filterValidation={filterValidation}/>
                        <Row className="mb-3">
                          <label
                          htmlFor="irNumber"
                          className="col-md-2 col-form-label"
                          >
                          Entity
                          </label>
                          <div className="col-md-3">
                          <Input
                              className="form-control"
                              type="text"
                              placeholder="Enter entity"
                              id="entity"
                              name="entity"
                              onChange={filterValidation.handleChange}
                              value={filterValidation.values.entity}
                              onKeyPress={(e) => {
                                if(e.key === 'Enter'){
                                  setCurPageInput(1);
                                  filterValidation.setFieldValue("curPage", 1);
                                  filterValidation.setFieldValue("submitType", "view");
                                  filterValidation.handleSubmit(filterValidation.values);
                                }                      
                              }}
                          />
                          </div>
                        </Row>
                        
                        <Row className="mb-3">
                          <label
                          htmlFor="toEmailMn"
                          className="col-md-2 col-form-label"
                          >
                          Requested Domain
                          </label>
                          <div className="col-md-3">
                          <Input
                              className="form-control"
                              type="text"
                              placeholder="Enter domain"
                              id="domain"
                              name="domain"
                              onChange={filterValidation.handleChange}
                              value={filterValidation.values.domain}
                              onKeyPress={(e) => {
                                if(e.key === 'Enter'){
                                  setCurPageInput(1);
                                  filterValidation.setFieldValue("curPage", 1);
                                  filterValidation.setFieldValue("submitType", "view");
                                  filterValidation.handleSubmit(filterValidation.values);
                                }                      
                              }}
                          />
                          </div>
                        </Row>

                        {/*
                        <Row className="mb-3 align-items-center">
                          <label htmlFor="entityStatus" className="col-md-2 col-form-label">
                          Type
                          </label>
                          <div className="col-md-4 d-flex" style={{justifyContent: "space-between"}}>
                            <div className="form-check">
                                <input type="radio" className="form-check-input"
                                  id="typeAll"
                                  name="type"
                                  onChange={filterValidation.handleChange}
                                  value=""
                                  checked={filterValidation.values.type === ""}
                                />
                                <Label className="form-check-label" htmlFor="typeAll">
                                All
                                </Label>
                            </div>
                            <div className="form-check">
                                <input type="radio" className="form-check-input"
                                  id="typeClaim"
                                  name="type"
                                  onChange={filterValidation.handleChange}
                                  value="Claim"
                                  checked={filterValidation.values.type === "Claim"}
                                />
                                <Label className="form-check-label" htmlFor="typeClaim">
                                Claim
                                </Label>
                            </div>
                            <div className="form-check">
                                <input type="radio" className="form-check-input"
                                  id="typeHolder"
                                  name="type"
                                  onChange={filterValidation.handleChange}
                                  value="Holder"
                                  checked={filterValidation.values.type === "Holder"}
                                />
                                <Label className="form-check-label" htmlFor="typeHolder">
                                Holder
                                </Label>
                            </div>
                            
                          </div>
                        </Row>
                        */}

                        <Row className="mb-3 align-items-center">
                          <label htmlFor="entityStatus" className="col-md-2 col-form-label">
                          Status
                          </label>
                          <div className="col-md-4 d-flex" style={{justifyContent: "space-between"}}>
                            <div className="form-check">
                                <input type="radio" className="form-check-input"
                                  id="statusAll"
                                  name="status"
                                  onChange={filterValidation.handleChange}
                                  value=""
                                  checked={filterValidation.values.status === ""}
                                />
                                <Label className="form-check-label" htmlFor="statusAll">
                                All
                                </Label>
                            </div>
                            <div className="form-check">
                                <input type="radio" className="form-check-input"
                                  id="statusPending"
                                  name="status"
                                  onChange={filterValidation.handleChange}
                                  value="Requested"
                                  checked={filterValidation.values.status === "Requested"}
                                />
                                <Label className="form-check-label" htmlFor="statusPending">
                                Pending
                                </Label>
                            </div>
                            <div className="form-check">
                                <input type="radio" className="form-check-input"
                                  id="statusConfirmed"
                                  name="status"
                                  onChange={filterValidation.handleChange}
                                  value="Confirmed"
                                  checked={filterValidation.values.status === "Confirmed"}
                                />
                                <Label className="form-check-label" htmlFor="statusConfirmed">
                                Confirmed
                                </Label>
                            </div>
                            <div className="form-check">
                                <input type="radio" className="form-check-input"
                                  id="statusDenied"
                                  name="status"
                                  onChange={filterValidation.handleChange}
                                  value="Denied"
                                  checked={filterValidation.values.status === "Denied"}
                                />
                                <Label className="form-check-label" htmlFor="statusDenied">
                                Denied
                                </Label>
                            </div>
                          </div>
                        </Row>
                      
                        <Row >
                          <Col md={2}>&nbsp;</Col>
                          <Col md={3}>&nbsp;</Col>
                          <Col md={3}>
                            <Row className="gap-2">
                              <button
                                type="button"
                                className="btn btn-primary waves-effect waves-light col-md-5"
                                onClick={()=>{
                                  setCurPageInput(1);
                                  filterValidation.setFieldValue("curPage", 1);
                                  filterValidation.setFieldValue("submitType", "view");
                                  filterValidation.handleSubmit(filterValidation.values);
                                }}
                              >
                                Search
                              </button>
                            </Row>
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </Collapse>
                </Card>
              </Col>
            </Row>
            {userEntityConnectClaimList && userEntityConnectClaimList.length ?
            <Row>
              <Col lg={12}>
                <Card>
                  <CardBody>
                    {
                    entityCheckCnt > 0 ?
                    <CardTitle className="text-muted mb-0">
                      <Row className="mb-3">
                        <span className="col-md-8 button-items">
                          <Button
                            type="button"
                            color="primary"
                            outline
                            className="waves-effect waves-light col-md-2"
                            onClick={()=>{
                              setSelectedButton("Confirm")
                            }}
                          >
                            Confirm selected
                          </Button>
                          <Button
                            type="button"
                            color="primary"
                            outline
                            className="waves-effect waves-light col-md-2"
                            onClick={()=>{
                              setSelectedButton("Deny")
                            }}
                          >
                            Deny selected
                          </Button>
                        </span>
                      </Row>
                    </CardTitle>
                    : null
                    }
                    <div className="table-responsive">
                      <Table className="table mb-0">
                        <thead>
                          <tr>
                          <th style={{verticalAlign: "middle", textAlign: "center"}}><input type="checkbox" checked={chkAllFlag} onClick={(e)=>entityCheck("chkAll", e.target.checked)} /></th>
                          <th>Requester</th>
                          <th>Entity</th>
                          <th>Current domain / url</th>
                          <th>Requested domain</th>
                          <th>Request</th>
                          <th>Admin action</th>
                          </tr>
                        </thead>
                        <tbody>
                        {
                          userEntityConnectClaimList.map((claimInfo, index) => {
                            return (
                                <tr key={index}>
                                  <td align="center">
                                      <input type="checkbox" 
                                        checked={claimInfo.checked}
                                        onClick={(e)=>entityCheck(index, e.target.checked)}
                                        />
                                    </td>
                                  <td>
                                    {claimInfo.userName}
                                  </td>
                                  <td>
                                    {claimInfo.currentTargetGroupName}
                                  </td>
                                  <td>
                                    {claimInfo.currentDomain}<br/>{claimInfo.currentUrl}
                                  </td>
                                  <td>
                                    {
                                      claimInfo.type === 'Disconnect' || claimInfo.type === 'IDNM' || claimInfo.type === 'IDC' || claimInfo.type === 'SENBDC' || claimInfo.type === 'CWAA' ?
                                      <>
                                      <Input
                                        className="form-control"
                                        type="text"
                                        placeholder="Enter domain"
                                        onChange={(e) => {
                                          if (e.target.value.indexOf("/") > -1) {
                                            setAlertMsg("Domain cannot include slash('/').");
                                          } else if (e.target.value.indexOf(",") > -1) {
                                            setAlertMsg("Domain cannot include comma(',').");
                                          } else {
                                            let _userEntityConnectClaimList = cloneDeep(userEntityConnectClaimList);
                                            _userEntityConnectClaimList[index].domain = e.target.value;
                                            dispatch(changeUserEntityConnectClaimList(_userEntityConnectClaimList));
                                          }
                                        }}
                                        value={claimInfo.domain}
                                      /><br/>
                                      <Input
                                        className="form-control"
                                        type="text"
                                        placeholder="Enter url"
                                        onChange={(e) => {
                                          let _userEntityConnectClaimList = cloneDeep(userEntityConnectClaimList);
                                          _userEntityConnectClaimList[index].url = e.target.value;
                                          dispatch(changeUserEntityConnectClaimList(_userEntityConnectClaimList));
                                        }}
                                        value={claimInfo.url}
                                      />
                                      </>
                                      :
                                      claimInfo.domain
                                    }
                                  </td>
                                  <td>
                                    {claimInfo.typeDesc}{claimInfo.type === 'CWAA' ? ": " + claimInfo.currentConnectedUserName : null}
                                  </td>
                                  <td>
                                    {
                                      claimInfo.status === 'Requested' ?
                                      <select 
                                        className="form-select" 
                                        name="status"
                                        onChange={e => {
                                          if (e.target.value !== 'Requested') {
                                            let _claimInfo = cloneDeep(claimInfo);
                                            _claimInfo.status = e.target.value;

                                            let _alertMsg = [];
                                            if ((_claimInfo.type === 'Disconnect' || _claimInfo.type === 'IDNM' || _claimInfo.type === 'IDC' || _claimInfo.type === 'SENBDC' || claimInfo.type === 'CWAA') && e.target.value === 'Confirmed' && (_claimInfo.domain === '' || _claimInfo.url === '')) {
                                              let chkStr = "";
                                              if (_claimInfo.domain === '') {
                                                chkStr = 'domain'
                                              }

                                              if (_claimInfo.url === '') {
                                                chkStr += (chkStr !== '' ? ', ' : '') + 'url'
                                              }
                                                
                                              _alertMsg.push(_claimInfo.currentTargetGroupName + "'s " + chkStr + " field is empty.");
                                            }

                                            if (_alertMsg.length > 0) {
                                              setAlertMsg(_alertMsg.toString());
                                            } else {
                                              setChangeClaimInfo(_claimInfo);
                                            }
                                          }
                                        }}
                                        value={claimInfo.status}
                                        style={{width:'100px'}}
                                      >
                                        <option value="Requested">Pending</option>
                                        <option value="Confirmed">Confirm</option>
                                        <option value="Denied">Deny</option>
                                      </select>
                                      :
                                      claimInfo.status
                                    }
                                  </td>
                                </tr>
                              )
                            })
                          }
                        </tbody>
                      </Table>
                    </div>
                    <div className="d-flex justify-content-between">
                      <span className="d-flex align-items-center gap-2">
                        <label
                          htmlFor="pageSize"
                          >
                          Entries per page
                        </label>
                        <div style={{width: "100px"}}>
                          <select 
                            className="form-select" 
                            name="pageSize"
                            onChange={e => {viewList(null, 1, e.target.value);}}
                            value={filterValidation.values.pageSize}
                          >
                            <option value="10">10</option>
                            <option value="20">20</option>
                            <option value="30">30</option>
                            <option value="40">40</option>
                            <option value="50">50</option>
                          </select>
                        </div>
                      </span>
                      <span className="d-flex align-items-center gap-2">
                        <div className="d-flex gap-1">
                          <Button
                            type="button"
                            color="primary"
                            onClick={()=>{viewList(null, 1);}}
                            disabled={(filterValidation.values.curPage === 1)}
                          >
                            {"<<"}
                          </Button>
                          <Button
                            type="button"
                            color="primary"
                            onClick={()=>{viewList(null, filterValidation.values.curPage-1);}}
                            disabled={(filterValidation.values.curPage === 1)}
                          >
                            {"<"}
                          </Button>
                        </div>
                        Page{" "}
                        <strong>
                          {filterValidation.values.curPage} of {totalPage}
                        </strong>
                        
                        <Input
                          type="number"
                          min={1}
                          style={{ width: 70 }}
                          max={totalPage}
                          name="curPage"
                          defaultValue={filterValidation.values.curPage}
                          value={curPageInput}
                          onChange={(e) => { setCurPageInput(e.target.value)}}
                          onKeyPress={(e) => {
                            if(e.key === 'Enter'){
                              viewList(null, e.target.value);
                            }                      
                          }}
                        />
                        <div className="d-flex gap-1">
                          <Button 
                            type="button"
                            color="primary"
                            onClick={()=>{viewList(null, filterValidation.values.curPage+1);}}
                            disabled={(filterValidation.values.curPage === totalPage)}
                          >
                            {">"}
                          </Button>
                          <Button
                            type="button"
                            color="primary"
                            onClick={()=>{viewList(null, totalPage);}}
                            disabled={(filterValidation.values.curPage === totalPage)}
                          >
                            {">>"}
                          </Button>
                        </div>
                      </span>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            :null}
          
            {
              loading ?
              <LoadingModal loading={loading} setLoading={setLoading}></LoadingModal>
              : null
            }

            {
              success ? 
              <SweetAlert
                title={success}
                timeout={2000}
                showCloseButton={false}
                showConfirm={false}
                success
                onConfirm={() => {
                  dispatch(onSetSuccess(""));
                }}
              ></SweetAlert>
              : null
            }

            {alertMsg !== '' ? (
              <SweetAlert
                warning
                onConfirm={() => {
                  setAlertMsg('');
                }}
                onCancel={() => {
                  setAlertMsg('');
                }}
              >{alertMsg}
              </SweetAlert>
            ) : null}

            {changeClaimInfo.userNo > 0 ? (
              <SweetAlert
                showCancel
                confirmBtnText="Confirm"
                cancelBtnText="Cancel"
                confirmBtnBsStyle="success"
                cancelBtnBsStyle="danger"
                title={"Are you sure you want to " + (changeClaimInfo.status === 'Confirmed' ? 'Confirm' : 'Deny') + " [" + changeClaimInfo.typeDesc + "]?"}
                onConfirm={() => {
                  if (changeClaimInfo.status === 'Confirmed') {
                    filterValidation.setFieldValue("submitType", "confirm");
                    filterValidation.handleSubmit(filterValidation.values);
                  } else {
                    if (filterValidation.values.memo !== '') {
                      filterValidation.setFieldValue("submitType", "deny");
                      filterValidation.handleSubmit(filterValidation.values);
                    }
                  }
                }}
                onCancel={() => {
                  setChangeClaimInfo({userNo:0});
                  filterValidation.setFieldValue("memo", "");
                }}
              >
                {
                changeClaimInfo.status === 'Denied' ? 
                <div>
                  <Input
                    className="form-control"
                    type="text"
                    placeholder="Enter deny reason"
                    name="memo"
                    onChange={filterValidation.handleChange}
                    value={filterValidation.values.memo}
                    invalid={
                      filterValidation.values.memo === '' ? true : false
                    }
                  />
                  {filterValidation.values.memo === '' ? (
                    <FormFeedback type="invalid">Please enter a reason for deny.</FormFeedback>
                  ) : null}
                </div>
                : null
                }
                
              </SweetAlert>
            ) : null}

            {selectedButton !== "" ? (
              <SweetAlert
                showCancel
                confirmBtnText="Confirm"
                cancelBtnText="Cancel"
                confirmBtnBsStyle="success"
                cancelBtnBsStyle="danger"
                title={"Are you sure you want to " + selectedButton + " selected item?"}
                onConfirm={() => {
                  if (selectedButton === 'Confirm') {
                    filterValidation.setFieldValue("submitType", "confirmSelected");
                    filterValidation.handleSubmit(filterValidation.values);
                  } else {
                    if (filterValidation.values.memo !== '') {
                      filterValidation.setFieldValue("submitType", "denySelected");
                      filterValidation.handleSubmit(filterValidation.values);
                    }
                  }
                }}
                onCancel={() => setSelectedButton("")}
              >
                {
                selectedButton === 'Deny' ? 
                <div>
                  <Input
                    className="form-control"
                    type="text"
                    placeholder="Enter a deny reason"
                    name="memo"
                    onChange={filterValidation.handleChange}
                    value={filterValidation.values.memo}
                    invalid={
                      filterValidation.values.memo === '' ? true : false
                    }
                  />
                  {filterValidation.values.memo === '' ? (
                    <FormFeedback type="invalid">Please enter a reason for deny.</FormFeedback>
                  ) : null}
                </div>
                : null
                }
              </SweetAlert>
            ) : null}


          </div>
        </div>
      </Form>
    </React.Fragment>
  )
}

export default AdmEntityConnectManagement;