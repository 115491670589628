import React from "react";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { DragHandle } from "./DragHandle";
import styled from "styled-components";
import { useSelector, useDispatch } from "react-redux";
import { changeServiceManualList, updateServiceManual, updateServiceManualPriority, updateServiceManualPDF } from "../../store/actions";
import { cloneDeep } from 'lodash';
import {
  Input,
  UncontrolledTooltip,
} from "reactstrap"
import { Link } from "react-router-dom";

const DraggingRow = styled.td`
  background: rgba(127, 207, 250, 0.3);
`;

const TableData = styled.td`
  background: white;
  &:first-of-type {
    min-width: 20ch;
  }
`;


export const DraggableTableRow = ({ manuName, key, row, rowIdx }) => {
  const dispatch = useDispatch();

  const {
    attributes,
    listeners,
    transform,
    transition,
    setNodeRef,
    isDragging
  } = useSortable({
    id: row.original.id
  });

  

  /*
    width: 100%;
    height: 35px;
    background: white;
    color: gray;
    padding-left: 5px;
    font-size: 14px;
    border: none;
    margin-left: 10px;

    onChange={(e) => { 
            if (e.target.value != '') {
              console.log('Select change : ' + e.target.value + ' / rowObj : ' + JSON.stringify(rowObj));
            }
          }}
  */

  const Select = styled.select`
    
    option {
      color: black;
      background: white;
      display: flex;
      white-space: pre;
      min-height: 20px;
      padding: 0px 2px 1px;
    }
  `;

  const style = {
    transform: CSS.Transform.toString(transform),
    transition: transition
  };

  const { serviceManualList, pageNames } = useSelector(state => ({
    serviceManualList: state.ServiceManual.serviceManualList,
    pageNames: state.ServiceManual.pageNames,
  }));

  const changeCell = (cellIdx, value) => {
    let _serviceManualList = cloneDeep(serviceManualList);
    if (cellIdx == 0) {
      _serviceManualList[rowIdx].pageName = value;
    } else if (cellIdx == 1) {
      _serviceManualList[rowIdx].functionName = value;
    } else if (cellIdx == 2) {
      _serviceManualList[rowIdx].vodLink = value;
    } else if (cellIdx == 5) {
      // console.log('cellIdx : ' + cellIdx + ' / value : ' + value)
      // console.log('_serviceManualList[rowIdx].priority 1 : ' + _serviceManualList[rowIdx].priority)
      if (Number(value)) {
        _serviceManualList[rowIdx].priority = Number(value);
      } else {
        console.log('Number error => ');
      }
    }

    // console.log('_serviceManualList[rowIdx].priority 2 : ' + _serviceManualList[rowIdx].priority)
    dispatch(changeServiceManualList(_serviceManualList));
  }

  const updateCell = (cellIdx, value) => {
    let _serviceManual = cloneDeep(serviceManualList[rowIdx]);
    if (cellIdx == 0 && _serviceManual.pageName != _serviceManual.oldPageName) {
      _serviceManual.oldPageName = _serviceManual.pageName;
      const formData = new FormData();
      formData.append('no', _serviceManual.no);
      formData.append('pageName', _serviceManual.pageName);
      formData.append('functionName', _serviceManual.functionName);
      formData.append('vodLink', _serviceManual.vodLink);
      formData.append('crudType', 'U');

      dispatch(updateServiceManual(formData, _serviceManual));
    } else if (cellIdx == 1 && _serviceManual.functionName != _serviceManual.oldFunctionName) {
      _serviceManual.oldFunctionName = _serviceManual.functionName;
      const formData = new FormData();
      formData.append('no', _serviceManual.no);
      formData.append('pageName', _serviceManual.pageName);
      formData.append('functionName', _serviceManual.functionName);
      formData.append('vodLink', _serviceManual.vodLink);
      formData.append('crudType', 'U');

      dispatch(updateServiceManual(formData, _serviceManual));
    } else if (cellIdx == 2 && _serviceManual.vodLink != _serviceManual.oldVodLink) {
      _serviceManual.crudType = 'U';
      _serviceManual.vodLink = _serviceManual.vodLink.trim();
      _serviceManual.oldVodLink = _serviceManual.vodLink;

      const formData = new FormData();
      formData.append('no', _serviceManual.no);
      formData.append('pageName', _serviceManual.pageName);
      formData.append('functionName', _serviceManual.functionName);
      formData.append('vodLink', _serviceManual.vodLink);
      formData.append('crudType', 'U');

      dispatch(updateServiceManual(formData, _serviceManual));
    } else if (cellIdx == 5 && _serviceManual.priority != _serviceManual.oldPriority) {
      // _serviceManual.oldPriority = _serviceManual.priority;
      dispatch(updateServiceManualPriority(_serviceManual, true))
    }
  }

  const deleteRow = () => {
    const _serviceManualList = [];
    serviceManualList.map((serviceManual, idx) =>{
      let _serviceManual = cloneDeep(serviceManual);
      if (idx == rowIdx) {
        const formData = new FormData();
        formData.append('no', _serviceManual.no);
        formData.append('priority', _serviceManual.priority);
        formData.append('crudType', 'D');

        dispatch(updateServiceManual(formData));
      } else {
        _serviceManualList.push(_serviceManual);
      }
    }); 
    
    dispatch(changeServiceManualList(_serviceManualList));
  }

  const updatePDF = (file) => {
    const formData = new FormData();
    formData.append('no', serviceManualList[rowIdx].no);
    formData.append('pdfFile', file);

    dispatch(updateServiceManualPDF(formData));
  }

  const makeCell = (cell, idx) => {
    if (manuName == 'admServiceManual') {
      if (idx == 0) {
        return (
          <span>
            <select 
              className="form-select" 
              onChange={e => {
                if (e.target.value != cell.value) {
                  
                  changeCell(idx, e.target.value);
                }
              }}
              value={cell.value}
              onBlur={(e) => { 
                updateCell(idx);
              }}
            >
              {pageNames.map((pageName,idx) => { 
                return (<option value={pageName}>{pageName}</option>);
              })}
            </select>
            {/*
            <Select 
              value={cell.value}
              onChange={(e) => { 
                if (e.target.value != cell.value) {
                  let _rowObj = cloneDeep(rowObj);
                  _rowObj['pageName'] = e.target.value;
                  dispatch(updateServiceManual(_rowObj))
                  // console.log('Select change : ' + e.target.value + ' / rowObj : ' + JSON.stringify(rowObj));
                }
              }}
            >
              {pageNames.map((pageName,idx) => {
                if(cell.value == pageName) {
                  console.log('cell.value : ' + cell.value + ' / pageName : ' + pageName);
                  return (<option value={cell.value} hidden>{cell.value}</option>);
                } else {
                  return (<option value={pageName}>{pageName}</option>);
                }
              })}
            </Select>
            */}
          </span>
        )
      } else if (idx == 3) {
        return (
          <div className="d-flex vertical-align-center gap-2">
            {
            cell.value ?
            <a href={cell.value} target="_blank" rel="noreferrer"><i className="mdi mdi-file-pdf text-danger font-size-22"></i></a> 
            : null
            }
            <input type="file" 
              className="form-control"
              accept="application/pdf"
              onChange={(e) => {
                // console.log(e.target.files[0]);
        
                if(e.target.files[0] !== undefined){
                  updatePDF(e.target.files[0]);
                }
              }}
              name="pdf"
              placeholder={cell.value}
            />
          </div>
        )
      } else if (idx == 1 || idx == 2) {
        return (
          <div className="d-flex vertical-align-center gap-2">
          <Input
              className="form-control"
              type="text"
              placeholder="Enter entity"
              value={cell.value}
              onChange={(e) => { 
                if (e.target.value !== cell.value) {
                  changeCell(idx, e.target.value);
                }
              }}
              onBlur={(e) => { 
                updateCell(idx);
              }}
          />
          </div>
        )
      } else if (idx === 5) {
        return (
          <div className="d-flex vertical-align-center gap-2">
          <Input
              className="form-control"
              type="number"
              placeholder="Enter entity"
              value={cell.value}
              onChange={(e) => { 
                // console.log('e.target.value : ' + e.target.value + ' / cell.value : ' + cell.value)
                if (e.target.value !== '' && e.target.value !== cell.value) {
                  changeCell(idx, e.target.value);
                }
              }}
              onBlur={(e) => { 
                updateCell(idx);
              }}
          />
          <Link to="#" onClick={() => {
              deleteRow();
            }}
          >
            <i className="uil-trash-alt" id={"deleteManualIco_"+rowIdx} style={{color:"red", fontSize:"18px"}}></i>
            <UncontrolledTooltip placement="top" target={"deleteManualIco_"+rowIdx}>
            Delete manual
            </UncontrolledTooltip>
          </Link>
          </div>
        )
      } else {
        return (<span>{cell.render("Cell")}</span>);
      }
    } else {
      return (<span>{cell.render("Cell")}</span>);
    }
  }
  

  return (
    <tr ref={setNodeRef} style={style} {...row.getRowProps()}>
      {isDragging ? (
        <DraggingRow colSpan={row.cells.length}>&nbsp;</DraggingRow>
      ) : (
        row.cells.map((cell, i) => {
          if (i === 0) {
            return (
              <TableData {...cell.getCellProps()}>
                <div className="d-flex vertical-align-center gap-2">
                <DragHandle {...attributes} {...listeners} />
                { makeCell(cell, i) }
                </div>
              </TableData>
            );
          } else {
            return (
              <TableData {...cell.getCellProps()}>
                {
                  makeCell(cell, i) 
                // cell.render("Cell")
                }
              </TableData>
            );
          }
          
        })
      )}
    </tr>
  );
};