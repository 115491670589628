import {
  GET_SENDING_ANALYTICS_MN_SETTINGS,
  GET_SENDING_ANALYTICS_MN_SETTINGS_SUCCESS,
  SAVE_SENDING_ANALYTICS_MN_SETTINGS,
  SAVE_SENDING_ANALYTICS_MN_SETTINGS_SUCCESS,
  GET_SENDING_ANALYTICS_MN_LIST,
  GET_SENDING_ANALYTICS_MN_LIST_SUCCESS,
  DOWN_SENDING_ANALYTICS_MN_EXCEL,
  DOWN_SENDING_ANALYTICS_MN_EXCEL_SUCCESS,
  GET_SENDING_ANALYTICS_MN,
  GET_SENDING_ANALYTICS_MN_SUCCESS,
  GET_SENDING_ANALYTICS_FR_SETTINGS,
  GET_SENDING_ANALYTICS_FR_SETTINGS_SUCCESS,
  SAVE_SENDING_ANALYTICS_FR_SETTINGS,
  SAVE_SENDING_ANALYTICS_FR_SETTINGS_SUCCESS,
  GET_SENDING_ANALYTICS_FR_LIST,
  GET_SENDING_ANALYTICS_FR_LIST_SUCCESS,
  DOWN_SENDING_ANALYTICS_FR_EXCEL,
  DOWN_SENDING_ANALYTICS_FR_EXCEL_SUCCESS,
  SET_SENDING_ANALYTICS_SUCCESS,
  SET_SENDING_ANALYTICS_ERROR,
  SET_SENDING_ANALYTICS_LOADING,
  SET_SENDING_ANALYTICS_MN,
  GET_MADRID_SENDING_ANALYTICS_STATISTICS_SETTINGS,
  GET_MADRID_SENDING_ANALYTICS_STATISTICS_SETTINGS_SUCCESS,
  GET_MADRID_SENDING_ANALYTICS_STATISTICS,
  GET_MADRID_SENDING_ANALYTICS_STATISTICS_SUCCESS,
  GET_FILER_SENDING_ANALYTICS_STATISTICS_SETTINGS,
  GET_FILER_SENDING_ANALYTICS_STATISTICS_SETTINGS_SUCCESS,
  GET_FILER_SENDING_ANALYTICS_STATISTICS,
  GET_FILER_SENDING_ANALYTICS_STATISTICS_SUCCESS,
  SET_SEARCH_SETTINGS_MN_LOADED,
  SET_SEARCH_SETTINGS_FR_LOADED,
} from "./actionTypes"

const initialState = {
  loading: false,
  error: "",
  success: "",
  searchSettingsMn: {},
  statisticsMapMn: {},
  sendingAnalyticsMnList: [],
  sendingAnalyticsMn: {},
  totalPageMn: 1,
  searchSettingsFr: {},
  statisticsMapFr: {},
  sendingAnalyticsFrList: [],
  totalPageFr: 1,
  searchSettingsMnStatistics: {},
  searchSettingsMnStatisticsLoaded: false,
  searchSettingsMnLogLoaded: false,
  madridSendingAnalyticsStatistics: {},
  searchSettingsFrStatistics: {},
  searchSettingsFrStatisticsLoaded: false,
  searchSettingsFrLogLoaded: false,
  filerSendingAnalyticsStatistics: {},
}

const SendingAnalytics = (state = initialState, action) => {
  switch (action.type) {
    case GET_SENDING_ANALYTICS_MN_SETTINGS:
      return {
        ...state,
        loading: true,
      }
    case GET_SENDING_ANALYTICS_MN_SETTINGS_SUCCESS:
      return {
        ...state,
        loading: false,
        searchSettingsMnLogLoaded: true,
        searchSettingsMn: action.payload.searchSettings,
      }
    case SAVE_SENDING_ANALYTICS_MN_SETTINGS:
      return {
        ...state,
        loading: true,
      }
    case SAVE_SENDING_ANALYTICS_MN_SETTINGS_SUCCESS:
      return {
        ...state,
        loading: false,
        success: action.payload.result,
      }
    case GET_SENDING_ANALYTICS_MN_LIST:
      return {
        ...state,
        loading: true,
        sendingAnalyticsMnList: [],
      }
    case GET_SENDING_ANALYTICS_MN_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        statisticsMapMn: action.payload.statisticsMap,
        sendingAnalyticsMnList: action.payload.sendingAnalyticsList,
        totalPageMn: action.payload.totalPage,
      }
    case DOWN_SENDING_ANALYTICS_MN_EXCEL:
      return {
        ...state,
        loading: true,
      }
    case DOWN_SENDING_ANALYTICS_MN_EXCEL_SUCCESS:
      return {
        ...state,
        loading: false,
      }
    case GET_SENDING_ANALYTICS_MN:
      return {
        ...state,
        loading: true,
        sendingAnalyticsMn: {},
      }
    case GET_SENDING_ANALYTICS_MN_SUCCESS:
      return {
        ...state,
        loading: false,
        sendingAnalyticsMn: action.payload.sendingAnalyticsMn,
      }
    case GET_SENDING_ANALYTICS_FR_SETTINGS:
      return {
        ...state,
        loading: true,
      }
    case GET_SENDING_ANALYTICS_FR_SETTINGS_SUCCESS:
      return {
        ...state,
        loading: false,
        searchSettingsFrLogLoaded: true,
        searchSettingsFr: action.payload.searchSettings,
      }
    case SAVE_SENDING_ANALYTICS_FR_SETTINGS:
      return {
        ...state,
        loading: true,
      }
    case SAVE_SENDING_ANALYTICS_FR_SETTINGS_SUCCESS:
      return {
        ...state,
        loading: false,
        success: action.payload.result,
      }
    case GET_SENDING_ANALYTICS_FR_LIST:
      return {
        ...state,
        loading: true,
        sendingAnalyticsFrList: [],
      }
    case GET_SENDING_ANALYTICS_FR_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        statisticsMapFr: action.payload.statisticsMap,
        sendingAnalyticsFrList: action.payload.sendingAnalyticsList,
        totalPageFr: action.payload.totalPage,
      }
    case DOWN_SENDING_ANALYTICS_FR_EXCEL:
      return {
        ...state,
        loading: true,
      }
    case DOWN_SENDING_ANALYTICS_FR_EXCEL_SUCCESS:
      return {
        ...state,
        loading: false,
      }
    case SET_SENDING_ANALYTICS_SUCCESS:
      return {
        ...state,
        success: action.payload,
      }
    case SET_SENDING_ANALYTICS_ERROR:
      return {
        ...state,
        error: action.payload,
      }
    case SET_SENDING_ANALYTICS_LOADING:
      return {
        ...state,
        loading: action.payload,
      }
    case SET_SENDING_ANALYTICS_MN:
      return {
        ...state,
        sendingAnalyticsMn: action.payload,
      }
    case GET_MADRID_SENDING_ANALYTICS_STATISTICS_SETTINGS:
      return {
        ...state,
        loading: true,
      }
    case GET_MADRID_SENDING_ANALYTICS_STATISTICS_SETTINGS_SUCCESS:
      return {
        ...state,
        loading: false,
        searchSettingsMnStatisticsLoaded: true,
        searchSettingsMnStatistics: action.payload.searchSettings,
      }
    case GET_MADRID_SENDING_ANALYTICS_STATISTICS:
      return {
        ...state,
        loading: true,
      }
    case GET_MADRID_SENDING_ANALYTICS_STATISTICS_SUCCESS:
      return {
        ...state,
        loading: false,
        madridSendingAnalyticsStatistics: action.payload.madridSendingAnalyticsStatistics,
      }
    case GET_FILER_SENDING_ANALYTICS_STATISTICS_SETTINGS:
      return {
        ...state,
        loading: true,
      }
    case GET_FILER_SENDING_ANALYTICS_STATISTICS_SETTINGS_SUCCESS:
      return {
        ...state,
        loading: false,
        searchSettingsFrStatisticsLoaded: true,
        searchSettingsFrStatistics: action.payload.searchSettings,
      }
    case GET_FILER_SENDING_ANALYTICS_STATISTICS:
      return {
        ...state,
        loading: true,
      }
    case GET_FILER_SENDING_ANALYTICS_STATISTICS_SUCCESS:
      return {
        ...state,
        loading: false,
        filerSendingAnalyticsStatistics: action.payload.filerSendingAnalyticsStatistics,
      }
    case SET_SEARCH_SETTINGS_MN_LOADED:
      if (action.payload.screenType === 'STATISTICS') {
        return {
          ...state,
          searchSettingsMnStatisticsLoaded: action.payload.data,
        }
      } else {
        return {
          ...state,
          searchSettingsMnLogLoaded: action.payload.data,
        }
      }
    case SET_SEARCH_SETTINGS_FR_LOADED:
      if (action.payload.screenType === 'STATISTICS') {
        return {
          ...state,
          searchSettingsFrStatisticsLoaded: action.payload.data,
        }
      } else {
        return {
          ...state,
          searchSettingsFrLogLoaded: action.payload.data,
        }
      }
    default:
      state = { ...state }
      break
  }
  return state
}

export default SendingAnalytics
