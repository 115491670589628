import { call, put, takeLatest} from "redux-saga/effects"

import { 
  GET_SETTINGS_INFO,
  UPDATE_ACCOUNT_INFO,
  UPDATE_ACCOUNT_PASSWORD,
  UPDATE_ACCOUNT_INVOICE_INFO,
  UPDATE_ACCOUNT_UNSUBSCRIBE_INFO,
  UPDATE_ACCOUNT_UPTODATE_INFO,
  GET_SETTINGS_EMAIL_TEMPLATES,
  DELETE_EMAIL_TEMPLATE,
  UPDATE_SENDER_EMAIL,
  UPDATE_SUBSCRIPTION_LIST,
  GET_USER_SIGNATURE_SAVE_POPUP_APPEAR_INFO,
  ADD_USER_SIGNATURE_SAVE_POPUP_APPEAR_LOG,
  GET_USER_SIGNATURE_INFO,
  UPDATE_USER_SIGNATURE_INFO,

  GET_EMAILING_SETTINGS,
  ADD_SENDER_EMAIL,
  DELETE_SENDER_EMAIL,
  GET_USER_SIGNATURE,
  ADD_USER_SIGNATURE,
  UPDATE_USER_SIGNATURE,
  DELETE_USER_SIGNATURE,
  ADD_USER_JURISDICTION_EMAIL_SETTING,
  UPDATE_USER_JURISDICTION_EMAIL_SETTING,
  DELETE_USER_JURISDICTION_EMAIL_SETTING,

  UPDATE_DISPLAY_NAME,
  UPDATE_SENDER_EMAIL_ADDRESS,

  GET_OCMN_SHOW_STARTUP,
  SAVE_OCMN_SETTINGS,

  ADD_USER_INVOICE_INFO,
  MODIFY_USER_INVOICE_INFO,
  REMOVE_USER_INVOICE_INFO,
  GET_USER_SUBSCRIPTION_LIST,
  MODIFY_USER_SUBSCRIPTION_INVOICE_INFO,
} from "./actionTypes"

import { 
  getSettingsInfoSuccess, 
  updateAccountInfoSuccess,
  getSettingsEmailTemplatesSuccess,
  deleteEmailTemplateSuccess,
  updateSubscriptionListSuccess,
  updateSenderEmailSuccess,
  getUserSignatureSavePopupAppearInfoSuccess,
  getUserSignatureInfoSuccess,
  // updateUserSignatureInfoSuccess,

  getEmailingSettingsSuccess,
  addSenderEmailSuccess,
  deleteSenderEmailSuccess,
  updateDisplayNameSuccess,
  updateSenderEmailAddressSuccess,
  getUserSignatureSuccess,
  addUserSignatureSuccess,
  updateUserSignatureSuccess,
  deleteUserSignatureSuccess,
  addUserJurisdictionEmailSettingSuccess,
  updateUserJurisdictionEmailSettingSuccess,
  deleteUserJurisdictionEmailSettingSuccess,

  setSettingsReducerValue,
  getOcmnShowStartup as getOcmnShowStartupAction,
  getOcmnShowStartupSuccess,
  saveOcmnSettingsSuccess,

  addUserInvoiceInfoSuccess,
  modifyUserInvoiceInfoSuccess,
  removeUserInvoiceInfoSuccess,
  getUserSubscriptionListSuccess,
  modifyUserSubscriptionInvoiceInfoSuccess,
} from "./actions"

import { 
  changeProfilePhotoUrl,
} from "../auth/profile/actions"

import { 
  getEmailTemplatesSuccess,
} from "../editor/actions"

import { 
  getUserInvoiceInfoList,
} from "../admInvoiceManagement/actions"

import {
  getEmailTemplates,
  getAccountInfo,
  updateAccountInfo,
  updateNewDashboard,
  updateAccountPassword,
  updateAccountInvoiceInfo,
  updateAccountUnsubscribeInfo,
  updateAccountUpToDateInfo,
  deleteEmailTemplate,
  updateSenderEmail,
  updateSubscriptionEmailTemplate,
  updateFilerEmailTemplate,
  getUserSignatureSavePopupAppearInfo,
  addUserSignatureSavePopupAppearLog,
  getUserSignatureInfo,
  updateUserSignatureInfo,

  getEmailingSettings,
  addSenderEmail,
  deleteSenderEmail,
  updateDisplayName,
  updateSenderEmailAddress,
  getUserSignature,
  addUserSignature,
  updateUserSignature,
  deleteUserSignature,
  addUserJurisdictionEmailSetting,
  updateUserJurisdictionEmailSetting,
  deleteUserJurisdictionEmailSetting,

  getOcmnShowStartup,
  saveOCMNSetting,

  addUserInvoiceInfo,
  modifyUserInvoiceInfo,
  removeUserInvoiceInfo,
  getUserSubscriptionList,
  modifyUserSubscriptionInvoiceInfo,
} from "../../helpers/backend_helper"

function* onGetSettingsInfo({ payload: data }) {
  try {
    const response2 = yield call(getAccountInfo);
    yield put(getSettingsInfoSuccess(response2));
  } catch (error) {
    console.log('onGetSettingsInfo error >> ' + error);
    
    //yield put(apiError(error))
  }
}

function* onGetSettingsEmailTemplates({ payload: data }) {
  try {
    const response1 = yield call(getEmailTemplates, data);
    yield put(getSettingsEmailTemplatesSuccess(response1));
    yield put(getEmailTemplatesSuccess(response1));
  } catch (error) {
    console.log('onEmailTemplates error >> ' + error);
    
    //yield put(apiError(error))
  }
}

function* onUpdateAccountInfo({ payload: data }) {
  try {
    const response = yield call(updateAccountInfo, data);

    if (response.accountInfo.photoUrl && response.accountInfo.photoUrl != '') {
      // console.log('response.photoUrl 2 : ' + response.photoUrl);
      const authUserObj = JSON.parse(localStorage.getItem("authUser"));

      const authUser = {
        userName: authUserObj.userName,
        adminYn: authUserObj.adminYn,
        photoUrl: response.accountInfo.photoUrl,
        virtualLogIn: authUserObj.virtualLogIn
      }

      // console.log('new authUser : ' + JSON.stringify(authUser));

      localStorage.setItem("authUser", JSON.stringify(authUser));

      yield put(changeProfilePhotoUrl(response.accountInfo.photoUrl));      
    }
    
    yield put(updateAccountInfoSuccess(response));

    // free account의 home country가 변경된 경우 Dashboard Refusal 데이터 변경 비동기로 처리 
    // console.log('response.accountInfo.grade : ' + response.accountInfo.grade);
    if (response.accountInfo.grade == '00' && data.get('country') != data.get('orgCountry')) {
      // console.log('home country가 변경된 경우 Dashboard Refusal 데이터 변경 비동기로 처리');
      yield call(updateNewDashboard, data);
    }
  } catch (error) {
    console.log('onUpdateAccountInfo error >> ' + error);
    
    //yield put(apiError(error))
  }
}

function* onUpdateAccountPassword({ payload: data }) {
  try {
    const response1 = yield call(updateAccountPassword, data);
    if (response1.ret === 'Success') {
      yield put(updateAccountInfoSuccess(response1));
    } else {
      yield put(setSettingsReducerValue('ERROR_MSG', response1.ret));
    }
  } catch (error) {
    console.log('onUpdateAccountPassword error >> ' + error);
    
    //yield put(apiError(error))
  }
}

function* onUpdateAccountInvoiceInfo({ payload: data }) {
  try {
    const response1 = yield call(updateAccountInvoiceInfo, data);
    yield put(updateAccountInfoSuccess(response1));
  } catch (error) {
    console.log('updateAccountInfoSuccess error >> ' + error);
    
    //yield put(apiError(error))
  }
}

function* onUpdateAccountUnsubscribeInfo({ payload: data }) {
  try {
    const response1 = yield call(updateAccountUnsubscribeInfo, data);
    console.log("response : " + response1)

    if(response1.error != undefined && response1.error >-1){
      yield put(setSettingsReducerValue('ERROR_MSG', response1.error));
    }else{
      yield put(updateAccountInfoSuccess(response1));
    }
  } catch (error) {
    console.log('onUpdateAccountUnsubscribeInfo error >> ' + error);
  }
}

function* onUpdateShowStartupOcmn({ payload: data }) {
  try {
    const response1 = yield call(updateAccountUnsubscribeInfo, data);
    console.log("response : " + response1)

    if(response1.error != undefined && response1.error >-1){
      yield put(setSettingsReducerValue('ERROR_MSG', response1.error));
    }else{
      yield put(updateAccountInfoSuccess(response1));
    }
  } catch (error) {
    console.log('onUpdateAccountUnsubscribeInfo error >> ' + error);
    
    //yield put(apiError(error))
  }
}

function* onUpdateAccountUpToDateInfo({ payload: data }) {
  try {
    const response1 = yield call(updateAccountUpToDateInfo, data);
    yield put(updateAccountInfoSuccess(response1));
  } catch (error) {
    console.log('updateAccountInfoSuccess error >> ' + error);
    
    //yield put(apiError(error))
  }
}

function* onDeleteEmailTemplate({ payload: data }) {
  try {
    const response = yield call(deleteEmailTemplate, data);
    if (response.result === 'Success') {
      yield put(deleteEmailTemplateSuccess(response));
      yield put(getEmailTemplatesSuccess(response));
    } else {
      yield put(setSettingsReducerValue('ERROR_MSG', response.errorMsg));
    }

    // console.log('onDeleteEmailTemplate response : ' + JSON.stringify(response))
  } catch (error) {
    console.log('onDeleteEmailTemplate error >> ' + error);
    
    //yield put(apiError(error))
  }
}

function* onUpdateSenderEmail({ payload: data }) {
  try {
    const response = yield call(updateSenderEmail, data);
    yield put(updateSenderEmailSuccess(response));
  } catch (error) {
    console.log('onUpdateSenderEmail error >> ' + error);
    
    //yield put(apiError(error))
  }
}

function* onUpdateSubscriptionList({ payload: {subscriptionDetails, filerSettings} }) {
  try {
    // console.log('onUpdateSubscriptionList subscriptionDetails : ' + JSON.stringify(subscriptionDetails))
    // console.log('onUpdateSubscriptionList filerSettings : ' + JSON.stringify(filerSettings))

    const response1 = yield call(updateSubscriptionEmailTemplate, subscriptionDetails);
    const response2 = yield call(updateFilerEmailTemplate, filerSettings);

    yield put(updateSubscriptionListSuccess(response1));
  } catch (error) {
    console.log('onUpdateSubscriptionList error >> ' + error);
    
    //yield put(apiError(error))
  }
}

function* onGetUserSignatureSavePopupAppearInfo() {
  try {
    const response = yield call(getUserSignatureSavePopupAppearInfo, {});
    yield put(getUserSignatureSavePopupAppearInfoSuccess(response));
  } catch (error) {
    console.log('onGetUserSignatureSavePopupAppearInfo error >> ' + error);
  }
}

function* onAddUserSignatureSavePopupAppearLog() {
  try {
    const response = yield call(addUserSignatureSavePopupAppearLog, {});
    // yield put(addUserSignatureSavePopupAppearLogSuccess(response));
  } catch (error) {
    console.log('onAddUserSignatureSavePopupAppearLog error >> ' + error);
  }
}

function* onGetUserSignatureInfo() {
  try {
    const response = yield call(getUserSignatureInfo);
    yield put(getUserSignatureInfoSuccess(response));
  } catch (error) {
    console.log('onGetUserSignatureInfo error >> ' + error);
  }
}

// function* onUpdateUserSignatureInfo({ payload: data }) {
//   try {
//     const response = yield call(updateUserSignatureInfo, data);
//     yield put(updateUserSignatureInfoSuccess(response));
//   } catch (error) {
//     console.log('onUpdateUserSignatureInfo error >> ' + error);
//   }
// }

function* onGetEmailingSettings() {
  try {
    const response = yield call(getEmailingSettings);
    // if(response.error != undefined && response.error >-1){
    //   yield put(setSettingsReducerValue('ERROR_MSG', response.error));
    // }else{
      yield put(getEmailingSettingsSuccess(response));
    // }
  } catch (error) {
    console.log('onGetEmailingSettings error >> ' + error);
  }
}

function* onAddSenderEmail({ payload: data }) {
  try {
    const response = yield call(addSenderEmail, data);
    if(response.error){
      yield put(setSettingsReducerValue('ERROR_MSG', response.error));
    }else{
      yield put(addSenderEmailSuccess(response));
    }
  } catch (error) {
    console.log('onAddSenderEmail error >> ' + error);
  }
}

function* onDeleteSenderEmail({ payload: data }) {
  try {
    const response = yield call(deleteSenderEmail, data);
    if(response.error){
      yield put(setSettingsReducerValue('ERROR_MSG', response.error));
    }else{
      yield put(deleteSenderEmailSuccess(response));
    }
  } catch (error) {
    console.log('onDeleteSenderEmail error >> ' + error);
  }
}

function* onUpdateDisplayName({ payload: data }) {
  try {
    const response = yield call(updateDisplayName, data);
    if(response.error){
      yield put(setSettingsReducerValue('ERROR_MSG', response.error));
    }else{
      yield put(updateDisplayNameSuccess(response));
    }
  } catch (error) {
    console.log('onUpdateDisplayName error >> ' + error);
  }
}

function* onUpdateSenderEmailAddress({ payload: data }) {
  try {
    const response = yield call(updateSenderEmailAddress, data);
    if(response.error){
      yield put(setSettingsReducerValue('ERROR_MSG', response.error));
    }else{
      yield put(updateSenderEmailAddressSuccess(response));
    }
  } catch (error) {
    console.log('onUpdateSenderEmailAddress error >> ' + error);
  }
}

function* onGetUserSignature({ payload: data }) {
  try {
    const response = yield call(getUserSignature, data);
    if(response.error){
      yield put(setSettingsReducerValue('ERROR_MSG', response.error));
    }else{
      yield put(getUserSignatureSuccess(response));
    }
  } catch (error) {
    console.log('onGetUserSignature error >> ' + error);
  }
}

function* onAddUserSignature({ payload: data }) {
  try {
    const response = yield call(addUserSignature, data);

    // console.log('response >> ' + JSON.stringify(response));
    
    if(response.error){
      yield put(setSettingsReducerValue('ERROR_MSG', response.error));
    }else{
      yield put(addUserSignatureSuccess(response));
    }
  } catch (error) {
    console.log('onAddUserSignature error >> ' + error);
  }
}

function* onUpdateUserSignature({ payload: data }) {
  try {
    const response = yield call(updateUserSignature, data);

    // console.log('response.error >> ' + response.error);

    if(response.error){
      yield put(setSettingsReducerValue('ERROR_MSG', response.error));
    }else{
      yield put(updateUserSignatureSuccess(response));
    }
  } catch (error) {
    console.log('onUpdateUserSignature error >> ' + error);
  }
}

function* onDeleteUserSignature({ payload: data }) {
  try {
    const response = yield call(deleteUserSignature, data);
    if(response.error){
      yield put(setSettingsReducerValue('ERROR_MSG', response.error));
    }else{
      yield put(deleteUserSignatureSuccess(response));
    }
  } catch (error) {
    console.log('onDeleteUserSignature error >> ' + error);
  }
}

function* onAddUserJurisdictionEmailSetting({ payload: data }) {
  // console.log('onAddUserJurisdictionEmailSetting start >> ');
  try {
    const response = yield call(addUserJurisdictionEmailSetting, data);
    if(response.error){
      yield put(setSettingsReducerValue('ERROR_MSG', response.error));
    }else{
      yield put(addUserJurisdictionEmailSettingSuccess(response));
    }
  } catch (error) {
    console.log('onAddUserJurisdictionEmailSetting error >> ' + error);
  }
}

function* onUpdateUserJurisdictionEmailSetting({ payload: data }) {
  // console.log('onUpdateUserJurisdictionEmailSetting start >> ');
  try {
    const response = yield call(updateUserJurisdictionEmailSetting, data);
    if(response.error){
      yield put(setSettingsReducerValue('ERROR_MSG', response.error));
    }else{
      yield put(updateUserJurisdictionEmailSettingSuccess(response));
    }
  } catch (error) {
    console.log('onUpdateUserJurisdictionEmailSetting error >> ' + error);
  }
}

function* onDeleteUserJurisdictionEmailSetting({ payload: data }) {
  // console.log('onDeleteUserJurisdictionEmailSetting start >> ');
  try {
    const response = yield call(deleteUserJurisdictionEmailSetting, data);
    if(response.error){
      yield put(setSettingsReducerValue('ERROR_MSG', response.error));
    }else{
      yield put(deleteUserJurisdictionEmailSettingSuccess(response));
    }
  } catch (error) {
    console.log('onDeleteUserJurisdictionEmailSetting error >> ' + error);
  }
}

function* onGetOcmnShowStartup() {
  try {
    const response = yield call(getOcmnShowStartup);
    if(response.error){
      yield put(setSettingsReducerValue('ERROR_MSG', response.error));
    }else{
      yield put(getOcmnShowStartupSuccess(response));
    }
  } catch (error) {
    console.log('onGetOcmnShowStartup error >> ' + error);
  }
}

function* onSaveOCMNSetting({ payload: data }) {
  try {
    const response = yield call(saveOCMNSetting, data);
    if(response.error){
      yield put(setSettingsReducerValue('ERROR_MSG', response.error));
    }else{
      yield put(getOcmnShowStartupAction());
      yield put(saveOcmnSettingsSuccess(response));
    }
  } catch (error) {
    console.log('onSaveSettingsOcmnShowStartup error >> ' + error);
  }
}

function* onAddUserInvoiceInfo({ payload: formData }) {
  try {
    const response = yield call(addUserInvoiceInfo, formData);
    if (response.errorMsg) {
      yield put(setSettingsReducerValue('ERROR_MSG', response.errorMsg));
    } else {
      yield put(addUserInvoiceInfoSuccess(response.ret));
      yield put(getUserInvoiceInfoList());
    }
  } catch (error) {
    console.log('onAddUserInvoiceInfo error >> ' + error);
  }
}

function* onModifyUserInvoiceInfo({ payload: formData }) {
  try {
    const response = yield call(modifyUserInvoiceInfo, formData);
    if (response.errorMsg) {
      yield put(setSettingsReducerValue('ERROR_MSG', response.errorMsg));
    } else {
      yield put(modifyUserInvoiceInfoSuccess(response.ret));
      yield put(getUserInvoiceInfoList());
    }
  } catch (error) {
    console.log('onModifyUserInvoiceInfo error >> ' + error);
  }
}

function* onRemoveUserInvoiceInfo({ payload: formData }) {
  try {
    const response = yield call(removeUserInvoiceInfo, formData);
    if (response.errorMsg) {
      yield put(setSettingsReducerValue('ERROR_MSG', response.errorMsg));
    } else {
      yield put(removeUserInvoiceInfoSuccess(response.ret));
      yield put(getUserInvoiceInfoList());
    }
  } catch (error) {
    console.log('onRemoveUserInvoiceInfo error >> ' + error);
  }
}

function* onGetUserSubscriptionList({ payload: formData }) {
  try {
    const response = yield call(getUserSubscriptionList, formData);
    yield put(getUserSubscriptionListSuccess(response));
  } catch (error) {
    console.log('onGetUserSubscriptionList error >> ' + error);
  }
}

function* onModifyUserSubscriptionInvoiceInfo({ payload: formData }) {
  try {
    const response = yield call(modifyUserSubscriptionInvoiceInfo, formData);
    if (response.errorMsg) {
      yield put(setSettingsReducerValue('ERROR_MSG', response.errorMsg));
    } else {
      yield put(modifyUserSubscriptionInvoiceInfoSuccess(response));
    }
  } catch (error) {
    console.log('onModifyUserSubscriptionInvoiceInfo error >> ' + error);
  }
}

function* settingsSaga() {
  yield takeLatest(GET_SETTINGS_INFO, onGetSettingsInfo);
  yield takeLatest(GET_SETTINGS_EMAIL_TEMPLATES, onGetSettingsEmailTemplates);
  yield takeLatest(UPDATE_ACCOUNT_INFO, onUpdateAccountInfo);
  yield takeLatest(UPDATE_ACCOUNT_PASSWORD, onUpdateAccountPassword);
  yield takeLatest(UPDATE_ACCOUNT_INVOICE_INFO, onUpdateAccountInvoiceInfo);
  yield takeLatest(UPDATE_ACCOUNT_UNSUBSCRIBE_INFO, onUpdateAccountUnsubscribeInfo);
  yield takeLatest(UPDATE_ACCOUNT_UPTODATE_INFO, onUpdateAccountUpToDateInfo);
  yield takeLatest(DELETE_EMAIL_TEMPLATE, onDeleteEmailTemplate);
  yield takeLatest(UPDATE_SENDER_EMAIL, onUpdateSenderEmail);
  yield takeLatest(UPDATE_SUBSCRIPTION_LIST, onUpdateSubscriptionList);
  yield takeLatest(GET_USER_SIGNATURE_SAVE_POPUP_APPEAR_INFO, onGetUserSignatureSavePopupAppearInfo);
  yield takeLatest(ADD_USER_SIGNATURE_SAVE_POPUP_APPEAR_LOG, onAddUserSignatureSavePopupAppearLog);
  yield takeLatest(GET_USER_SIGNATURE_INFO, onGetUserSignatureInfo);
  // yield takeLatest(UPDATE_USER_SIGNATURE_INFO, onUpdateUserSignatureInfo);

  yield takeLatest(GET_EMAILING_SETTINGS, onGetEmailingSettings);
  yield takeLatest(ADD_SENDER_EMAIL, onAddSenderEmail);
  yield takeLatest(DELETE_SENDER_EMAIL, onDeleteSenderEmail);
  yield takeLatest(UPDATE_DISPLAY_NAME, onUpdateDisplayName);
  yield takeLatest(UPDATE_SENDER_EMAIL_ADDRESS, onUpdateSenderEmailAddress);
  yield takeLatest(GET_USER_SIGNATURE, onGetUserSignature);
  yield takeLatest(ADD_USER_SIGNATURE, onAddUserSignature);
  yield takeLatest(UPDATE_USER_SIGNATURE, onUpdateUserSignature);
  yield takeLatest(DELETE_USER_SIGNATURE, onDeleteUserSignature);
  yield takeLatest(ADD_USER_JURISDICTION_EMAIL_SETTING, onAddUserJurisdictionEmailSetting);
  yield takeLatest(UPDATE_USER_JURISDICTION_EMAIL_SETTING, onUpdateUserJurisdictionEmailSetting);
  yield takeLatest(DELETE_USER_JURISDICTION_EMAIL_SETTING, onDeleteUserJurisdictionEmailSetting);

  yield takeLatest(GET_OCMN_SHOW_STARTUP, onGetOcmnShowStartup);
  yield takeLatest(SAVE_OCMN_SETTINGS, onSaveOCMNSetting);
  
  yield takeLatest(ADD_USER_INVOICE_INFO, onAddUserInvoiceInfo);
  yield takeLatest(MODIFY_USER_INVOICE_INFO, onModifyUserInvoiceInfo);
  yield takeLatest(REMOVE_USER_INVOICE_INFO, onRemoveUserInvoiceInfo);
  yield takeLatest(GET_USER_SUBSCRIPTION_LIST, onGetUserSubscriptionList);
  yield takeLatest(MODIFY_USER_SUBSCRIPTION_INVOICE_INFO, onModifyUserSubscriptionInvoiceInfo);
}

export default settingsSaga

