import { call, put, takeEvery, takeLatest } from "redux-saga/effects"

// Login Redux States
import { 
  GET_MULTIPLE_COUNTRY_LIST,
} from "./actionTypes"
import { 
  getMultipleCountryListSuccess,
} from "./actions"

//Include Both Helper File with needed methods
import {
  getMultipleCountryList,
} from "../../helpers/backend_helper"

function* onGetMultipleCountryList({ payload: data }) {
  try {
    const response = yield call(getMultipleCountryList, data);
    yield put(getMultipleCountryListSuccess(response, data.filterName));
    
  } catch (error) {
    console.log('onGetMultipleCountryList error >> ' + error);
    //yield put(apiError(error))
  }
}

function* multipleCountrySaga() {
  yield takeEvery(GET_MULTIPLE_COUNTRY_LIST, onGetMultipleCountryList);
}

export default multipleCountrySaga

