import PropTypes from 'prop-types'
import React, {useState, useEffect, useRef } from "react"

import { Row, Col, Alert, Container, CardBody, Card, Modal, FormFeedback, Input} from "reactstrap"
import SweetAlert from "react-bootstrap-sweetalert";
// Redux
import { connect } from "react-redux"
import { withRouter, Link } from "react-router-dom"

// availity-reactstrap-validation
import { AvForm, AvField } from "availity-reactstrap-validation"

//Social Media Imports
// import { GoogleLogin } from "react-google-login"
// import TwitterLogin from "react-twitter-auth"
// import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props"

// actions
import { loginUser, apiError, socialLogin, closeTcAgreeModal, checkCookieAgree, updateCookieAgree, setLoginLoading as onSetLoginLoading } from "../../store/actions"

// import images
import logo from "../../assets/images/logo-dark.png"
import logolight from "../../assets/images/logo-light.png"

import './Login.css';
import EmailSvg from './EmailSvg'
import LoadingModal from "../../components/Common/LoadingModal"
//Import config
// import { facebook, google } from "../../config"

//import userManager from "../../managers/user";

const Login = (props) => {
  const [refererUrl, setRefererUrl] = useState("");
  const [tcAgree, setTcAgree] = useState("N");
  const [activeEmailModalOpen, setActiveEmailModalOpen] = useState(false);
  const [activeEmailFail, setActiveEmailFail] = useState(false);
  const [resetPasswordFail, setResetPasswordFail] = useState(false);
  const [welcomeModalOpen, setWelcomeModalOpen] = useState(false);
  const [isBottom, setIsBottom] = useState(false);
  const [passwordValue, setPasswordValue] = useState("");
  const [passwordType, setPasswordType] = useState("password");
  const [passwordIcon, setPasswordIcon] = useState("col-md-1 uil-eye");
  const [passwordValidation, setPasswordValidation] = useState("");

  const btnLogin = useRef();

  // handleValidSubmit
  const handleValidSubmit = async (event, values) => {
    // console.log('values : ' + JSON.stringify(values));
    // console.log('passwordValue : ' + passwordValue);
    if (passwordValue === "") {
      setPasswordValidation('This field is invalid');

      console.log('PasswordValidation False');
      return;
    }

    values.password = passwordValue;
    values.tcAgree = tcAgree;
    // console.log(values);
    props.loginUser(values, props.history)
  }

  /*
  const signIn = (res, type) => {
    const { socialLogin } = props
    if (type === "google" && res) {
      const postData = {
        name: res.profileObj.name,
        email: res.profileObj.email,
        token: res.tokenObj.access_token,
        idToken: res.tokenId,
      }
      socialLogin(postData, props.history, type)
    } else if (type === "facebook" && res) {
      const postData = {
        name: res.name,
        email: res.email,
        token: res.accessToken,
        idToken: res.tokenId,
      }
      socialLogin(postData, props.history, type)
    }
  }

  //handleGoogleLoginResponse
  const googleResponse = response => {
    signIn(response, "google")
  }

  //handleTwitterLoginResponse
  // const twitterResponse = e => {}

  //handleFacebookLoginResponse
  const facebookResponse = response => {
    signIn(response, "facebook")
  }
  */

  const getCookie = (name) => {
    let matches = document.cookie.match(new RegExp(
      "(?:^|; )" + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + "=([^;]*)"
    ));
    return matches ? decodeURIComponent(matches[1]) : undefined;
  }

  function setCookie(name, value, options = {}) {

    options = {
      path: '/',
      // 필요한 경우, 옵션 기본값을 설정할 수도 있습니다.
      ...options
    };
  
    if (options.expires instanceof Date) {
      options.expires = options.expires.toUTCString();
    }
  
    let updatedCookie = encodeURIComponent(name) + "=" + encodeURIComponent(value);
  
    for (let optionKey in options) {
      updatedCookie += "; " + optionKey;
      let optionValue = options[optionKey];
      if (optionValue !== true) {
        updatedCookie += "=" + optionValue;
      }
    }
  
    document.cookie = updatedCookie;
  }
  
  function deleteCookie(name) {
    setCookie(name, "", {'max-age': -1})
  }

  function removeBodyCss() {
    document.body.classList.add("no_padding")
  }

  function closeActiveEmailModal() {
    setActiveEmailModalOpen(!activeEmailModalOpen)
    removeBodyCss()
  }

  function closeWelcomeModal() {
    setWelcomeModalOpen(!welcomeModalOpen)
    removeBodyCss()
  }

  const toggleBottomCanvas = () => {
    setIsBottom(!isBottom);
  };

  const setLoginLoading = () => {
    props.onSetLoginLoading(!props.loading);
  }

  useEffect(() => {

    let checkAvailableEmail = getCookie("checkAvailableEmail");
    if (checkAvailableEmail) {
      console.log('checkAvailableEmail => ' + checkAvailableEmail);
      let confirmYn = checkAvailableEmail.split('^')[0];
      if ('Y' === confirmYn) {
        setActiveEmailModalOpen(true);
      } else {
        setActiveEmailFail(true);
      }
      deleteCookie("checkAvailableEmail");
    }

    let confirmResetPassword = getCookie("confirmResetPassword");
    if (confirmResetPassword) {
      console.log('confirmResetPassword => ' + confirmResetPassword);
      if ('N' === confirmResetPassword) {
        setResetPasswordFail(true);
      }
      deleteCookie("confirmResetPassword");
    }

    let confirmEmail = getCookie("confirmEmail");
    if (confirmEmail) {
      console.log('confirmEmail => ' + confirmEmail);
      if ('Y' === confirmEmail) {
        setWelcomeModalOpen(true);
      }
      deleteCookie("confirmEmail");
    }

    let search = window.location.search;
    // console.log('search 1.=> ' + search);
    if (search.indexOf('_gl=') == -1) {
      // console.log('search 2.=> ' + decodeURIComponent(search));

      setRefererUrl(decodeURIComponent(search).replace("?refererUrl=", ""));
    } else {
      // console.log('gl don\'t setting RefererUrl');
    }
    
    props.checkCookieAgree();

    document.body.className = "authentication-bg";
    // remove classname when component will unmount
    return function cleanup() {
      document.body.className = "";
    };
  },[]);

  return (
    <React.Fragment>
      {/*
      <div className="home-btn d-none d-sm-block">
        <Link to="/" className="text-dark">
          <i className="mdi mdi-home-variant h2"></i>
        </Link>
      </div>
      */}
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row>
            <Col lg={12}>
              <div className="text-center">
                <a href="https://www.wecrest.com" target="_blank" className="mb-5 d-block auth-logo">
                  <img src={logo} alt="" height="50" className="logo logo-dark" />
                  <img src={logolight} alt="" height="50" className="logo logo-light" />
                </a>
              </div>
            </Col>
          </Row>
          <Row className="align-items-center justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card>

                <CardBody className="p-4">
                  <div className="text-center mt-2">
                    <h5 className="text-primary">Welcome Back !</h5>
                    <p className="text-muted">Log in to continue to WeCrest.</p>
                  </div>
                  <div className="p-2 mt-4">
                    <AvForm
                      className="form-horizontal"
                      onValidSubmit={(e, v) => {
                        handleValidSubmit(e, v)
                      }}
                    >
                      {props.error && typeof props.error === "string" ? (
                        <Alert color="danger">{props.error}</Alert>
                      ) : null}
                      <AvField
                          name="refererUrl"
                          value={refererUrl}
                          type="hidden"
                        />
                      <div className="mb-3">
                        <AvField
                          name="userName"
                          label="Email"
                          value=""
                          className="form-control"
                          placeholder="Enter email"
                          type="text"
                          required
                        />
                      </div>

                      <div className="mb-3">
                        <div className="float-end">
                          <Link to="/reset-password" className="text-muted">Forgot password?</Link>
                        </div>
                        <label for="password" className={passwordValidation !== "" ? "text-danger" : ""}>Password</label>
                        <div className="d-flex vertical-align-center gap-2">
                          <div className="col-md-11">
                            <Input
                              name="password"
                              id="password"
                              className="form-control"
                              placeholder="Enter Password"
                              type={passwordType}
                              onChange={(e) => {setPasswordValue(e.target.value)}}
                              value={passwordValue}
                              invalid={
                                passwordValidation !== "" ? true : false
                              }
                            />
                            {passwordValidation !== "" ? (
                              <FormFeedback type="invalid">{passwordValidation}</FormFeedback>
                            ) : null}
                          </div>
                          <i className={passwordIcon} style={{color:"#5c8dc6", fontSize:"18px", cursor:"pointer"}} 
                            onClick={()=>{
                              if (passwordType === "password") {
                                setPasswordType("text");
                                setPasswordIcon("col-md-1 uil-eye-slash");
                              } else {
                                setPasswordType("password");
                                setPasswordIcon("col-md-1 uil-eye");
                              }
                            }}></i>
                        {/*
                          <div className="col-md-11">
                            <AvField
                              name="password"
                              label="Password"
                              value=""
                              type={passwordType}
                              required
                              placeholder="Enter Password"
                            />
                          </div>
                        */}
                        </div>
                      </div>

                      {/*
                      <div className="form-check">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          id="customControlInline"
                        />
                        <label
                          className="form-check-label"
                          htmlFor="customControlInline"
                        >
                          Remember me
                        </label>
                      </div>
                      */}

                      <div className="mt-3">
                        <button
                          className="btn btn-primary w-100 waves-effect waves-light"
                          type="submit"
                          ref={btnLogin}
                        >
                          Log In
                        </button>
                      </div>

                      {/*
                      <div className="mt-4 text-center">
                        <h5 className="font-size-14 mb-3">Sign in with</h5>

                        <ul className="list-inline">
                          <li className="list-inline-item">
                            <FacebookLogin
                              appId={facebook.APP_ID}
                              autoLoad={false}
                              callback={facebookResponse}
                              render={renderProps => (
                                <Link
                                to="#"
                                  className="social-list-item bg-primary text-white border-primary"
                                  onClick={renderProps.onClick}
                                >
                                  <i className="mdi mdi-facebook" />
                                </Link>
                              )}
                            />
                          </li>
                              */}
                          {/*<li className="list-inline-item">*/}
                          {/*  <TwitterLogin*/}
                          {/*    loginUrl={*/}
                          {/*      "http://localhost:4000/api/v1/auth/twitter"*/}
                          {/*    }*/}
                          {/*    onSuccess={this.twitterResponse}*/}
                          {/*    onFailure={this.onFailure}*/}
                          {/*    requestTokenUrl={*/}
                          {/*      "http://localhost:4000/api/v1/auth/twitter/revers"*/}
                          {/*    }*/}
                          {/*    showIcon={false}*/}
                          {/*    tag={"div"}*/}
                          {/*  >*/}
                          {/*    <a*/}
                          {/*      href=""*/}
                          {/*      className="social-list-item bg-info text-white border-info"*/}
                          {/*    >*/}
                          {/*      <i className="mdi mdi-twitter"/>*/}
                          {/*    </a>*/}
                          {/*  </TwitterLogin>*/}
                          {/*</li>*/}
                          {/*
                          <li className="list-inline-item">
                            <GoogleLogin
                              clientId={google.CLIENT_ID}
                              render={renderProps => (
                                <Link
                                to="#"
                                  className="social-list-item bg-danger text-white border-danger"
                                  onClick={renderProps.onClick}
                                >
                                  <i className="mdi mdi-google" />
                                </Link>
                              )}
                              onSuccess={googleResponse}
                              onFailure={() => {}}
                            />
                          </li>
                        </ul>
                      </div>
                      */}

                     

                      <div className="mt-4 text-center">
                        <p className="mb-0">Don't have an account ? <Link to="/register" className="fw-medium text-primary"> Create new account </Link> </p>
                        <p className="mb-0"><a href="https://www.wecrest.com/contact-us" className="fw-medium text-primary"> Contact us </a> </p>
                      </div>

                    </AvForm>

                  </div>
                </CardBody>
              </Card>
              <div className="mt-5 text-center">
                <p>© WeCrest Ltd 2017-{new Date().getFullYear()} all rights reserved</p>
              </div>
            </Col>
          </Row>
          {resetPasswordFail? (
            <SweetAlert
              onConfirm={() => {
                setResetPasswordFail(false);
              }}
            >Reset Password is Fail!</SweetAlert>
            ) : null}
          {/*레이어 팝업 - 이메일 인증 완료 */}
          <Modal
            size="lg"
            isOpen={activeEmailModalOpen}
            toggle={() => {
              closeActiveEmailModal()
            }}
            id="activeEmailModal"
          >
            <div className="welcome--head">
                <EmailSvg/>
            </div>
            <div className="welcome--body">
              <p class="welcome--title">Confirmed!</p>
              <p class="welcome--text">You're ready to send emails from your WeCrest account. Please send a test email to yourself.</p>
            </div>
            <div class="modal-footer">
                <button 
                  type="button" 
                  className="waves-effect waves-light btn btn-primary" 
                  onClick={() => {
                    setActiveEmailModalOpen(false)
                  }}>
                  Done
                </button>
            </div>
          </Modal>
          {activeEmailFail? (
            <SweetAlert
              onConfirm={() => {
                setActiveEmailFail(false);
              }}
            >This sender email has been expired. Please check again!</SweetAlert>
            ) : null}
          {/*레이어 팝업 - 회원 가입 이메일 인증 완료 */}
          <Modal
            size="lg"
            isOpen={welcomeModalOpen}
            toggle={() => {
              closeWelcomeModal()
            }}
            id="welcomeModal"
          >
            <div className="welcome--head">
              <img src="https://app.wecrest.com/resources/images/logo.png" alt="wecrest logo"/>
            </div>
            <div className="welcome--body">
              <p class="welcome--title">Welcome to WeCrest!</p>
              <p class="welcome--text">Feel free to send us a message at <a href="mailto:global@wecrest.com">global@wecrest.com</a>
                <br/>if you have any questions or are looking for any features.
                <br/>You can upgrade your account at any time. <a href="https://www.wecrest.com/contact-us">Contact us</a>
              </p>
            </div>
            <div class="modal-footer">
                <button 
                  type="button" 
                  className="waves-effect waves-light btn btn-primary" 
                  onClick={() => {
                    closeWelcomeModal()
                  }}>
                  Done
                </button>
            </div>
          </Modal>

          {props.tcAgreeModal ? (
            <SweetAlert
              showCancel
              confirmBtnText="Yes, proceed!"
              cancelBtnText="No, go back"
              confirmBtnBsStyle="success"
              cancelBtnBsStyle="danger"
              onConfirm={() => {
                setTcAgree("Y");
                props.closeTcAgreeModal();
                btnLogin.current.click();
              }}
              onCancel={() => props.closeTcAgreeModal()}
            >
              By proceeding, you agree to our <a href="https://www.wecrest.com/terms-conditions" target="_blank" rel="noreferrer">Terms & Conditions</a>.<br/>Do you wish to proceed?
            </SweetAlert>
            ) : null}
        </Container>
      </div>

      {!props.agreeCookie ? 
        <>
        <div id="cp-overlay" className="cp-overlay" style={{display : 'block'}}></div>
              
        <div id="cp-dialog" className="cp-wrapper" style={{display : 'block'}}>
          <div id="cp-inner" className="cp-layer">
            <div>
                <div className="cp-inner">
                    <h5 id="layer-headline">We use cookies</h5>
                    <div className="description">
                        <div>
                            <p id="layer-text"><p>We want to make our website more user-friendly and continuously improve it. If you continue to use our website, you agree to the use of cookies.&nbsp;</p></p>
                        </div>
                    </div>
                    <div className="links">
                      &#62; For more information, please refer to our <a href="https://www.wecrest.com/privacy-policy" target="_blank" rel="noreferrer">Privacy Policy</a>.
                    </div>
                    <p className="close">
                      <a className="close-button-text" onClick={() => {
                        props.updateCookieAgree();
                      }}>Agree</a>
                    </p>
                </div>
            </div>
              </div>
          </div>
          </>
        : null}
      {
        props.loading ?
        <LoadingModal loading={props.loading} setLoading={setLoginLoading}></LoadingModal>
        : null
      }
    </React.Fragment>
  )
}

const mapStateToProps = state => {
  const { error, loading, tcAgreeModal, agreeCookie } = state.Login
  return { error, loading, tcAgreeModal, agreeCookie }
}

export default withRouter(
  connect(mapStateToProps, { loginUser, apiError, socialLogin, closeTcAgreeModal, checkCookieAgree, updateCookieAgree, onSetLoginLoading })(Login)
)

Login.propTypes = {
  error: PropTypes.any,
  history: PropTypes.object,
  loginUser: PropTypes.func,
  socialLogin: PropTypes.func,
  checkCookieAgree: PropTypes.func,
  updateCookieAgree: PropTypes.func,
  onSetLoginLoading: PropTypes.func,
}