export const SET_LOADING = "SET_LOADING"
export const SET_SUCCESS = "SET_SUCCESS"
export const SET_ERROR_MSG = "SET_ERROR_MSG"
export const SET_UP_FILES = "SET_UP_FILES"
export const SET_UPDATE_FLAG = "SET_UPDATE_FLAG"
export const SET_LOADING_EDITOR = "SET_LOADING_EDITOR"
export const GET_COMMUNITY_LIST = "GET_COMMUNITY_LIST"
export const GET_COMMUNITY_LIST_SUCCESS = "GET_COMMUNITY_LIST_SUCCESS"
export const CHANGE_COMMUNITY_DETAIL = "CHANGE_COMMUNITY_DETAIL"
export const GET_COMMUNITY_DETAIL = "GET_COMMUNITY_DETAIL"
export const GET_COMMUNITY_DETAIL_SUCCESS = "GET_COMMUNITY_DETAIL_SUCCESS"
export const CREATE_COMMUNITY = "CREATE_COMMUNITY"
export const CREATE_COMMUNITY_SUCCESS = "CREATE_COMMUNITY_SUCCESS"
export const MODIFY_COMMUNITY = "MODIFY_COMMUNITY"
export const MODIFY_COMMUNITY_SUCCESS = "MODIFY_COMMUNITY_SUCCESS"
export const REMOVE_COMMUNITY = "REMOVE_COMMUNITY"
export const REMOVE_COMMUNITY_SUCCESS = "REMOVE_COMMUNITY_SUCCESS"
export const CHANGE_COMMUNITY_ATTACH_FILE_LIST = "CHANGE_COMMUNITY_ATTACH_FILE_LIST"
export const GET_COMMUNITY_ATTACH_FILE_LIST = "GET_COMMUNITY_ATTACH_FILE_LIST"
export const GET_COMMUNITY_ATTACH_FILE_LIST_SUCCESS = "GET_COMMUNITY_ATTACH_FILE_LIST_SUCCESS"
export const ADD_COMMUNITY_ATTACH_FILE = "ADD_COMMUNITY_ATTACH_FILE"
export const ADD_COMMUNITY_ATTACH_FILE_SUCCESS = "ADD_COMMUNITY_ATTACH_FILE_SUCCESS"
export const REMOVE_COMMUNITY_ATTACH_FILE = "REMOVE_COMMUNITY_ATTACH_FILE"
export const REMOVE_COMMUNITY_ATTACH_FILE_SUCCESS = "REMOVE_COMMUNITY_ATTACH_FILE_SUCCESS"
export const CHANGE_COMMUNITY_REPLY_LIST = "CHANGE_COMMUNITY_REPLY_LIST"
export const GET_COMMUNITY_REPLY_LIST = "GET_COMMUNITY_REPLY_LIST"
export const GET_COMMUNITY_REPLY_LIST_SUCCESS = "GET_COMMUNITY_REPLY_LIST_SUCCESS"
export const CREATE_COMMUNITY_REPLY = "CREATE_COMMUNITY_REPLY"
export const CREATE_COMMUNITY_REPLY_SUCCESS = "CREATE_COMMUNITY_REPLY_SUCCESS"
export const MODIFY_COMMUNITY_REPLY = "MODIFY_COMMUNITY_REPLY"
export const MODIFY_COMMUNITY_REPLY_SUCCESS = "MODIFY_COMMUNITY_REPLY_SUCCESS"
export const REMOVE_COMMUNITY_REPLY = "REMOVE_COMMUNITY_REPLY"
export const REMOVE_COMMUNITY_REPLY_SUCCESS = "REMOVE_COMMUNITY_REPLY_SUCCESS"