import {
  GET_DOMAIN_LIST,
  GET_DOMAIN_LIST_SUCCESS,
  CHANGE_DOMAIN_LIST,
  GET_DOMAIN_ACTION_HISTORY,
  GET_DOMAIN_ACTION_HISTORY_SUCCESS,
  SET_VIEW_DOMAIN_ACTION_HISTORY,
  SAVE_DOMAIN_INFO,
  SAVE_DOMAIN_INFO_SUCCESS,
  GET_BLACK_LIST,
  GET_BLACK_LIST_SUCCESS,
  ADD_BLACK_DOMAIN,
  ADD_BLACK_DOMAIN_SUCCESS,
  DOWN_EXCEL_BLACK_LIST,
  DOWN_EXCEL_BLACK_LIST_SUCCESS,
  SET_ADM_DOMAIN_MANAGEMENT_SUCCESS,
  SET_ADM_DOMAIN_MANAGEMENT_ERROR,
  SET_ADM_DOMAIN_LOADING,
  GET_ENTITY_DETAIL,
  GET_ENTITY_DETAIL_SUCCESS,
  SAVE_ENTITY_EMAILS,
  SAVE_ENTITY_EMAILS_SUCCESS,
  DELETE_ENTITY_EMAILS,
  DELETE_ENTITY_EMAILS_SUCCESS,
  CHANGE_ENTITY_DETAILS,
  SET_ENTITY_DETAIL_RESULT,
  SET_ENTITY_DETAIL_SUCCESS,
} from "./actionTypes"

const initialState = {
  loading: false,
  error: "",
  success: "",
  searchSettingsDm: {},
  domainList: [],
  entityDetail: {},
  targetGroupAddressList: [],
  totalPageDm: 1,
  viewDomainActionHistory: false,
  domainActionHistory: [],
  searchSettingsBl: {},
  blackList: [],
  totalPageBl: 1,
  result: -1,
  dupeEmailAddresses: [],
}

const AdmDomainManagement = (state = initialState, action) => {
  switch (action.type) {
    case GET_DOMAIN_LIST:
      return {
        ...state,
        loading: true,
        domainList: [],
      }
    case GET_DOMAIN_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        domainList: action.payload.domainList,
        totalPageDm: action.payload.totalPageDm,
      }
    case CHANGE_DOMAIN_LIST:
      return {
        ...state,
        domainList: action.payload,
      }
    case GET_DOMAIN_ACTION_HISTORY:
      return {
        ...state,
        loading: true,
        domainActionHistory: [],
      }
    case GET_DOMAIN_ACTION_HISTORY_SUCCESS:
      return {
        ...state,
        loading: false,
        viewDomainActionHistory: true,
        domainActionHistory: action.payload.domainActionHistory,
      }
    case SET_VIEW_DOMAIN_ACTION_HISTORY:
      return {
        ...state,
        viewDomainActionHistory: action.payload,
      }
    case SAVE_DOMAIN_INFO:
      return {
        ...state,
        loading: true,
      }
    case SAVE_DOMAIN_INFO_SUCCESS:
      return {
        ...state,
        loading: false,
        success: action.payload.result,
      }
    case GET_BLACK_LIST:
      return {
        ...state,
        loading: true,
        blackList: [],
      }
    case GET_BLACK_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        blackList: action.payload.blackList,
        totalPageBl: action.payload.totalPageBl,
      }
    case ADD_BLACK_DOMAIN:
      return {
        ...state,
        loading: true,
      }
    case ADD_BLACK_DOMAIN_SUCCESS:
      return {
        ...state,
        loading: false,
        success: action.payload.result,
      }
    case DOWN_EXCEL_BLACK_LIST:
      return {
        ...state,
        loading: true
      }
    case DOWN_EXCEL_BLACK_LIST_SUCCESS:
      return {
        ...state,
        loading: false
      }
    case SET_ADM_DOMAIN_MANAGEMENT_SUCCESS:
      return {
        ...state,
        success: action.payload
      }
    case SET_ADM_DOMAIN_MANAGEMENT_ERROR:
      return {
        ...state,
        error: action.payload
      }
    case SET_ADM_DOMAIN_LOADING:
      return {
        ...state,
        loading: action.payload
      }
    case GET_ENTITY_DETAIL:
      return {
        ...state,
        loading: true,
        entityDetail: {},
        targetGroupAddressList: [],
      }
    case GET_ENTITY_DETAIL_SUCCESS:
      return {
        ...state,
        loading: false,
        entityDetail: action.payload.entityDetail,
        targetGroupAddressList: action.payload.targetGroupAddressList,
      }
    case SAVE_ENTITY_EMAILS:
      return {
        ...state,
        loading: true,
      }
    case SAVE_ENTITY_EMAILS_SUCCESS:
      // console.log('dupeEmailAddresses : ' + JSON.stringify(action.payload.dupeEmailAddresses))
      // console.log('dupeEmailAddresses.length : ' + JSON.stringify(action.payload.dupeEmailAddresses.length))
      if (action.payload.dupeEmailAddresses.length > 0) {
        return {
          ...state,
          loading: false,
          entityDetail: action.payload.entityDetail,
          result: 7,
          dupeEmailAddresses: action.payload.dupeEmailAddresses
        }
      } else {
        return {
          ...state,
          loading: false,
          entityDetail: action.payload.entityDetail,
          success: action.payload.result,
        }
      }
    case DELETE_ENTITY_EMAILS:
      return {
        ...state,
        loading: true,
      }
    case DELETE_ENTITY_EMAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        entityDetail: action.payload.entityDetail,
        success: action.payload.result,
      }
    case CHANGE_ENTITY_DETAILS:
      return {
        ...state,
        entityDetail: action.payload,
      }
    case SET_ENTITY_DETAIL_RESULT:
      return {
        ...state,
        result:action.payload,
        dupeEmailAddresses: [],
      }
    case SET_ENTITY_DETAIL_SUCCESS:
      return {
        ...state,
        success:action.payload,
      }
    default:
      state = { ...state }
      break
  }
  return state
}

export default AdmDomainManagement
