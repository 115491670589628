import PropTypes from "prop-types"
import React, { useState, useEffect } from "react";
import { Row, Col, CardBody, Card, Alert, Container, Form, Input, FormFeedback, Label, Modal } from "reactstrap";

// Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";

// action
import { registerUser, registerUserFailed, closeRegisterUserSuccessfulModal } from "../../store/actions"

// Redux
import { useSelector, useDispatch } from "react-redux";

import { withRouter, Link } from "react-router-dom";


// import images
import logo from "../../assets/images/logo-dark.png"
import logolight from "../../assets/images/logo-light.png"
import adv from "../../assets/images/adv.png"

import './Login.css';
import EmailSvg from './EmailSvg'

const RegisterConfirm = props => {
  const dispatch = useDispatch();

  const [registerUserSuccessfulModalOpen, setRegisterUserSuccessfulModalOpen] = useState(false);

  function removeBodyCss() {
    document.body.classList.add("no_padding")
  }

  useEffect(() => {
    document.body.className = "authentication-bg";
    // remove classname when component will unmount
    return function cleanup() {
      document.body.className = "";
    };
  }, [dispatch]);

  return (
    <React.Fragment>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row>
            <Col lg={12}>
              <div className="text-center">
                <a href="https://www.wecrest.com" target="_blank"  className="mb-5 d-block auth-logo">
                  <img src={logo} alt="" height="50" className="logo logo-dark" />
                  <img src={logolight} alt="" height="50" className="logo logo-light" />
                </a>
              </div>
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col lg={8}>
            <div class="modal-content">
              <div className="welcome--head">
                  <EmailSvg/>
              </div>
              <div className="welcome--body">
                <p class="welcome--title">Thank you!</p>
                <p class="welcome--text">Please go to your inbox and confirm your email. If you do not see an email from us in your inbox, please check your spam folder or contact us at <a href="mailto:global@wecrest.com">global@wecrest.com</a>.</p>
              </div>
            </div>
            </Col>
            <div className="mt-5 text-center">
                <p>© WeCrest Ltd 2017-{new Date().getFullYear()} all rights reserved</p>
              </div>
          </Row>
          </Container>
      </div>
    </React.Fragment>
  )
}
export default RegisterConfirm;

