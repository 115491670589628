import {
  GET_EMAIL_TEMPLATES,
  GET_EMAIL_TEMPLATES_SUCCESS,
  GET_EMAILFORM,
  GET_EMAILFORM_SUCCESS,
  UPDATE_EMAILFORM,
  CHANGE_UPLOAD_RATE,
  UPDATE_EMAILFORM_SUCCESS,
  UPDATE_EMAILFORM_ERROR,
  DELETE_EMAILFORM,
  DELETE_EMAILFORM_SUCCESS,
  SET_REDUCER_VALUE,
} from "./actionTypes"

const initialState = {
  emailTemplates: [],
  menuItems: "",
  tmpTitle: "",
  emailSubject: "",
  emailCcItems: [],
  emailCcError: "",
  emailBccItems: [],
  emailBccError: "",
  emailForm: "",
  attachFiles: [],
  copyFiles: [],
  upFiles: [],
  isUpload: false,
  progressColor: "primary",
  uploadRate: 0,
  loadingEditor: false,
  updateFlag: false,
  errorMsg: "",
}

const Editor = (state = initialState, action) => {
  switch (action.type) {
    case GET_EMAIL_TEMPLATES:
      state = {
        ...state,
        // loading: true,
      }
      break
    case GET_EMAIL_TEMPLATES_SUCCESS:
      // console.log('emailTemplates : ' + action.payload.emailTemplates);

      let newMenuItems = '';
      action.payload.emailTemplates.forEach((emailTemplate, i) => {
        // console.log('emailTemplate' + (i+1) + ' add newMenuItems');
        newMenuItems += 'emailTemplate' + (i+1) + ' ';
      });
      // console.log('newMenuItems : ' + newMenuItems);
      
      state =  {
        ...state,
        emailTemplates: action.payload.emailTemplates,
        menuItems: newMenuItems,
        // loading: false,
      }
      break
    case GET_EMAILFORM:
      state = {
        ...state,
        loadingEditor: true,
      }
      break
    case GET_EMAILFORM_SUCCESS:
      // console.log('editorForm : ' + action.payload.editorForm);
      // console.log('attachFiles : ' + action.payload.editorForm.attachFiles);

      state =  {
        ...state,
        tmpTitle: action.payload.editorForm.tmpTitle,
        emailSubject: action.payload.editorForm.subject,
        emailCcItems: action.payload.editorForm.emailCC && action.payload.editorForm.emailCC.split(",").length > 0 ? action.payload.editorForm.emailCC.split(",").map(item => item.trim()) : [],
        emailCcError: "",
        emailBccItems: action.payload.editorForm.emailBCC && action.payload.editorForm.emailBCC.split(",").length > 0 ? action.payload.editorForm.emailBCC.split(",").map(item => item.trim()) : [],
        emailBccError: "",
        emailForm: action.payload.editorForm.emailForm,
        attachFiles: action.payload.editorForm.attachFiles,
        copyFiles: action.payload.editorForm.copyFiles,
        upFiles: [],
        loadingEditor: false,
      }
      break
    case UPDATE_EMAILFORM:
      state = {
        ...state,
        isUpload: true,
        loadingEditor: true,
        data: action.payload
      }
      break
    // case CHANGE_UPLOAD_RATE:
    //   state = {
    //     ...state,
    //     isUpload: true,
    //     uploadRate: action.payload,
    //   }
    //   break
    case UPDATE_EMAILFORM_SUCCESS:
      // console.log('UPDATE_EMAILFORM_SUCCESS payload : ' + JSON.stringify(action.payload));
      // console.log('UPDATE_EMAILFORM_SUCCESS editorForm : ' + JSON.stringify(action.payload.editorForm));
      state =  {
        ...state,
        tmpTitle: action.payload.editorForm.tmpTitle,
        emailSubject: action.payload.editorForm.subject,
        emailCcItems: action.payload.editorForm.emailCC && action.payload.editorForm.emailCC.split(",").length > 0 ? action.payload.editorForm.emailCC.split(",").map(item => item.trim()) : [],
        emailCcError: "",
        emailBccItems: action.payload.editorForm.emailBCC && action.payload.editorForm.emailBCC.split(",").length > 0 ? action.payload.editorForm.emailBCC.split(",").map(item => item.trim()) : [],
        emailBccError: "",
        emailForm: action.payload.editorForm.emailForm,
        attachFiles: action.payload.editorForm.attachFiles,
        copyFiles: [],
        upFiles: [],
        loadingEditor: false,
        updateFlag: true,
      }
      break
    case UPDATE_EMAILFORM_ERROR:
      state = {
        ...state,
        loadingEditor: false,
        errorMsg: action.payload.errorMsg
      }
      break
    case DELETE_EMAILFORM:
      state = {
        ...state,
        data: action.payload
      }
      break
    case DELETE_EMAILFORM_SUCCESS:
      state = {
        ...state,
      }
      break
    case SET_REDUCER_VALUE:
      // console.log('SET_REDUCER_VALUE payload : ' + JSON.stringify(action.payload))
      // console.log('SET_REDUCER_VALUE payload.type : ' + action.payload.type)
      // console.log('SET_REDUCER_VALUE payload.data : ' + JSON.stringify(action.payload.data))
      if (action.payload.type === 'TMP_TITLE') {
        state = {
          ...state,
          tmpTitle: action.payload.data
        }
      } else if (action.payload.type === 'SUBJECT') {
        state = {
          ...state,
           emailSubject: action.payload.data
        }
      } else if (action.payload.type === 'EMAIL_CC_ITEMS') {
        state = {
          ...state,
          emailCcItems: action.payload.data,
        }
      } else if (action.payload.type === 'EMAIL_CC_ERROR') {
        state = {
          ...state,
          emailCcError: action.payload.data
        }
      } else if (action.payload.type === 'EMAIL_BCC_ITEMS') {
        state = {
          ...state,
          emailBccItems: action.payload.data,
        }
      } else if (action.payload.type === 'EMAIL_BCC_ERROR') {
        state = {
          ...state,
          emailBccError: action.payload.data
        }
      } else if (action.payload.type === 'CHANGE_TEMPLATE') {
        state = {
          ...state,
          tmpTitle: action.payload.data.tmpTitle,
          emailSubject: action.payload.data.subject,
          emailCcItems: action.payload.data.emailCC && action.payload.data.emailCC.split(",").length > 0 ? action.payload.data.emailCC.split(",").map(item => item.trim()) : [],
          emailCcError: "",
          emailBccItems: action.payload.data.emailBCC && action.payload.data.emailBCC.split(",").length > 0 ? action.payload.data.emailBCC.split(",").map(item => item.trim()) : [],
          emailBccError: "",
          attachFiles: [],
          copyFiles: action.payload.data.attachFiles,
        }
      } else if (action.payload.type === 'ATTACH_FILES') {
        state = {
          ...state,
          attachFiles: action.payload.data
        }
      } else if (action.payload.type === 'COPY_FILES') {
        state = {
          ...state,
          copyFiles: action.payload.data
        }
      } else if (action.payload.type === 'UP_FILES') {
        state = {
          ...state,
          upFiles: action.payload.data
        }
      } else if (action.payload.type === 'IS_UPLOAD') {
        state = {
          ...state,
          isUpload: action.payload.data
        }
      } else if (action.payload.type === 'UPLOAD_RATE') {
        state = {
          ...state,
          uploadRate: action.payload.data
        }
      } else if (action.payload.type === 'UPDATE_FLAG') {
        state = {
          ...state,
          updateFlag: false,
        }
      } else if (action.payload.type === 'ERROR_MSG') {
        state = {
          ...state,
          errorMsg: action.payload.data,
        }
      } else if (action.payload.type === 'EMAIL_FORM') {
        state = {
          ...state,
          emailForm: action.payload.data,
        }
      }
      break
    default:
      state = { ...state }
      break
  }
  return state
}

export default Editor
