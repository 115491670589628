export const GET_DOMAIN_LIST = "GET_DOMAIN_LIST"
export const GET_DOMAIN_LIST_SUCCESS = "GET_DOMAIN_LIST_SUCCESS"
export const CHANGE_DOMAIN_LIST = "CHANGE_DOMAIN_LIST"
export const GET_DOMAIN_ACTION_HISTORY = "GET_DOMAIN_ACTION_HISTORY"
export const GET_DOMAIN_ACTION_HISTORY_SUCCESS = "GET_DOMAIN_ACTION_HISTORY_SUCCESS"
export const SET_VIEW_DOMAIN_ACTION_HISTORY = "SET_VIEW_DOMAIN_ACTION_HISTORY"
export const SAVE_DOMAIN_INFO = "SAVE_DOMAIN_INFO"
export const SAVE_DOMAIN_INFO_SUCCESS = "SAVE_DOMAIN_INFO_SUCCESS"

export const GET_BLACK_LIST = "GET_BLACK_LIST"
export const GET_BLACK_LIST_SUCCESS = "GET_BLACK_LIST_SUCCESS"
export const ADD_BLACK_DOMAIN = "ADD_BLACK_DOMAIN"
export const ADD_BLACK_DOMAIN_SUCCESS = "ADD_BLACK_DOMAIN_SUCCESS"
export const DOWN_EXCEL_BLACK_LIST = "DOWN_EXCEL_BLACK_LIST"
export const DOWN_EXCEL_BLACK_LIST_SUCCESS = "DOWN_EXCEL_BLACK_LIST_SUCCESS"

export const SET_ADM_DOMAIN_MANAGEMENT_SUCCESS = "SET_ADM_DOMAIN_MANAGEMENT_SUCCESS"
export const SET_ADM_DOMAIN_MANAGEMENT_ERROR = "SET_ADM_DOMAIN_MANAGEMENT_ERROR"
export const SET_ADM_DOMAIN_LOADING = "SET_ADM_DOMAIN_LOADING"

export const GET_ENTITY_DETAIL = "GET_ENTITY_DETAIL"
export const GET_ENTITY_DETAIL_SUCCESS = "GET_ENTITY_DETAIL_SUCCESS"
export const SAVE_ENTITY_EMAILS = "SAVE_ENTITY_EMAILS"
export const SAVE_ENTITY_EMAILS_SUCCESS = "SAVE_ENTITY_EMAILS_SUCCESS"
export const DELETE_ENTITY_EMAILS = "DELETE_ENTITY_EMAILS"
export const DELETE_ENTITY_EMAILS_SUCCESS = "DELETE_ENTITY_EMAILS_SUCCESS"
export const CHANGE_ENTITY_DETAILS = "CHANGE_ENTITY_DETAILS"
export const SET_ENTITY_DETAIL_RESULT = "SET_ENTITY_DETAIL_RESULT"
export const SET_ENTITY_DETAIL_SUCCESS = "SET_ENTITY_DETAIL_SUCCESS"