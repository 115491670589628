export const GET_CONTACTS_LIST = "GET_CONTACTS_LIST"
export const GET_CONTACTS_LIST_SUCCESS = "GET_CONTACTS_LIST_SUCCESS"
export const SET_UPLOAD_CONTACTS_OPEN = "SET_UPLOAD_CONTACTS_OPEN"
export const UPLOAD_CONTACTS = "UPLOAD_CONTACTS"
export const UPLOAD_CONTACTS_SUCCESS = "UPLOAD_CONTACTS_SUCCESS"
export const SET_IS_UPLOAD_FALSE = "SET_IS_UPLOAD_FALSE"
export const CHANGE_CONTACTS_LIST = "CHANGE_CONTACTS_LIST"
export const MULTIPLE_STAR_CONTACTS = "MULTIPLE_STAR_CONTACTS"
export const MULTIPLE_UNSTAR_CONTACTS = "MULTIPLE_UNSTAR_CONTACTS"
export const MULTIPLE_HIDE_CONTACTS = "MULTIPLE_HIDE_CONTACTS"
export const MULTIPLE_UNHIDE_CONTACTS = "MULTIPLE_UNHIDE_CONTACTS"
export const MULTIPLE_UNSUBSCRIBE_CONTACTS = "MULTIPLE_UNSUBSCRIBE_CONTACTS"
export const CHECK_EMAIL_UNSUBSCRIBE = "CHECK_EMAIL_UNSUBSCRIBE"
export const CHECK_EMAIL_UNSUBSCRIBE_SUCCESS = "CHECK_EMAIL_UNSUBSCRIB_SUCCESS"
export const SAVE_CONTACTS = "SAVE_CONTACTS"
export const SAVE_CONTACTS_SIMPLE = "SAVE_CONTACTS_SIMPLE"
export const SAVE_CONTACTS_SIMPLE_SUCCESS = "SAVE_CONTACTS_SIMPLE_SUCCESS"
export const DELETE_CONTACTS = "DELETE_CONTACTS"
export const GET_IMPORTED_CONTACTS = "GET_IMPORTED_CONTACTS"
export const GET_IMPORTED_CONTACTS_SUCCESS = "GET_IMPORTED_CONTACTS_SUCCESS"
export const GET_SUGGESTED_CONTACTS = "GET_SUGGESTED_CONTACTS"
export const GET_SUGGESTED_CONTACTS_SUCCESS = "GET_SUGGESTED_CONTACTS_SUCCESS"
export const GET_CONTACTS_DETAILS = "GET_CONTACTS_DETAILS"
export const GET_CONTACTS_DETAILS_SUCCESS = "GET_CONTACTS_DETAILS_SUCCESS"
export const GET_CONTACTS_DETAIL_LIST = "GET_CONTACTS_DETAIL_LIST"
export const GET_CONTACTS_DETAIL_LIST_SUCCESS = "GET_CONTACTS_DETAIL_LIST_SUCCESS"
export const GET_CONTACTS_MEMO = "GET_CONTACTS_MEMO"
export const GET_CONTACTS_MEMO_SUCCESS = "GET_CONTACTS_MEMO_SUCCESS"
export const CHANGE_CONTACTS_DETAILS = "CHANGE_CONTACTS_DETAILS"
export const SAVE_MEMO = "SAVE_MEMO"
export const SAVE_MEMO_SUCCESS = "SAVE_MEMO_SUCCESS"
export const CHANGE_IMPORTED_CONTACTS = "CHANGE_IMPORTED_CONTACTS"
export const CHANGE_SUGGESTED_CONTACTS = "CHANGE_SUGGESTED_CONTACTS"
export const CHANGE_MEMBER_CONFIRMED_CONTACTS = "CHANGE_MEMBER_CONFIRMED_CONTACTS"
export const SAVE_CONTACTS_SETTINGS = "SAVE_CONTACTS_SETTINGS"
export const GET_CONTACTS_SETTINGS = "GET_CONTACTS_SETTINGS"
export const GET_CONTACTS_SETTINGS_SUCCESS = "GET_CONTACTS_SETTINGS_SUCCESS"
export const DOWN_CONTACTS_EXCEL = "DOWN_CONTACTS_EXCEL"
export const DOWN_CONTACTS_EXCEL_SUCCESS = "DOWN_CONTACTS_EXCEL_SUCCESS"
export const SET_SEARCH_SETTINGS = "SET_SEARCH_SETTINGS"
export const SET_CONTACS_RESULT = "SET_CONTACS_RESULT"
export const SET_CONTACS_DETAIL_RESULT = "SET_CONTACS_DETAIL_RESULT"
export const SET_CONTACS_LOADING = "SET_CONTACS_LOADING"

export const GET_UPLOADED_CONTACTS_LIST = "GET_UPLOADED_CONTACTS_LIST"
export const GET_UPLOADED_CONTACTS_LIST_SUCCESS = "GET_UPLOADED_CONTACTS_LIST_SUCCESS"
export const SAVE_UPLOADED_CONTACTS = "SAVE_UPLOADED_CONTACTS"
export const DELETE_UPLOADED_CONTACTS = "DELETE_UPLOADED_CONTACTS"
export const DOWN_UPLOADED_CONTACTS_EXCEL = "DOWN_UPLOADED_CONTACTS_EXCEL"
export const DOWN_UPLOADED_CONTACTS_EXCEL_SUCCESS = "DOWN_UPLOADED_CONTACTS_EXCEL_SUCCESS"
export const CHANGE_UPLOADED_CONTACTS_LIST = "CHANGE_UPLOADED_CONTACTS_LIST"

export const GET_DOMAIN_CORRECT_YN_COUNT = "GET_DOMAIN_CORRECT_YN_COUNT"
export const GET_DOMAIN_CORRECT_YN_COUNT_SUCCESS = "GET_DOMAIN_CORRECT_YN_COUNT_SUCCESS"
export const SAVE_DOMAIN_CORRECT_YN = "SAVE_DOMAIN_CORRECT_YN"
export const SAVE_DOMAIN_CORRECT_YN_SUCCESS = "SAVE_DOMAIN_CORRECT_YN_SUCCESS"
export const CHANGE_USER_SUGGEST_DOMAIN = "CHANGE_USER_SUGGEST_DOMAIN"
export const CHANGE_SHOW_SUCCESS_ADD_TOAST_FLAG = "CHANGE_SHOW_SUCCESS_ADD_TOAST_FLAG"
export const CHANGE_CHECK_UNSUBS_EMAIL_ADDRESS_FLAG = "CHANGE_CHECK_UNSUBS_EMAIL_ADDRESS_FLAG"

export const GET_CONTACT_SEARCH_LIST = "GET_CONTACT_SEARCH_LIST"
export const GET_CONTACT_SEARCH_LIST_SUCCESS = "GET_CONTACT_SEARCH_LIST_SUCCESS"
export const CHANGE_CONTACT_SEARCH_LIST = "CHANGE_CONTACT_SEARCH_LIST"
export const DOWN_SEARCH_ENTITY_EXCEL = "DOWN_SEARCH_ENTITY_EXCEL"
export const DOWN_SEARCH_ENTITY_EXCEL_SUCCESS = "DOWN_SEARCH_ENTITY_EXCEL_SUCCESS"