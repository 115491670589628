import React, {useState, useEffect} from 'react';
import { useSelector, useDispatch } from "react-redux";
import {
    Row,
    Col,
    Card,
    CardBody,
    Media,
    Collapse,
    Modal,
    Spinner,
    UncontrolledTooltip,
  } from "reactstrap"
import SweetAlert from "react-bootstrap-sweetalert";
import LoadingModal from "../../components/Common/LoadingModal"
import { Link } from "react-router-dom";

import {
  getOCMNList as onGetOCMNList,
  setOcmnLoading as onSetOcmnLoading,
  setOcmnOpen as onSetOcmnOpen,
  setMadridSentSuccess as onSetSentSuccess,
  setOcmnIndex as onSetOcmnIndex,
  ocmnPreviewMadridEmail as onOcmnPreviewMadridEmail,
  getOCMNSuggestedContacts as onGetOCMNSuggestedContacts,
  sendOCMNEmail as onSendOCMNEmail,
  unlockOcmn as onUnlockOcmn,
  setUpgradeAlertMessage as onSetUpgradeAlertMessage,
  setMadridResult as onSetResult,
  addOcmnLog as onAddOcmnLog,
  saveOCMNShowStartup as onSaveOCMNShowStartup,
  saveOCMNAutoSendYn as onSaveOCMNAutoSendYn,
  setOcmnPreviewEmail as onSetOcmnPreviewEmail,
  changeOcmnSuggestedContactsList as onChangeOcmnSuggestedContactsList,
} from "../../store/actions";

import '../../components/Common/Common.css';
import "toastr/build/toastr.min.css"

import { Document, Page, pdfjs } from 'react-pdf';
import pdfjsWorker from "pdfjs-dist/build/pdf.worker.entry";
import styled from "styled-components";

const PDFDocumentWrapper = styled.div`
  canvas {
    width: 100% !important;
    height: auto !important;
  }
`;

const OneClickMadridNotifier = props => {
  pdfjs.GlobalWorkerOptions.workerSrc = pdfjsWorker;

  const dispatch = useDispatch();

  const errMsg = [
    <span>You are one small step away from being able to use the Madrid Notifier. Please complete the setup process by entering your email settings <a href='/settings#subscriptionSettings'>here</a>. Once you have completed this step, you will be able to send emails using the Madrid Notifier.</span>
    , "Please select a case."
    , <span>Your email is blank. Please edit your email or select a template in your <a href='/settings#emailTemplateSetting'>Settings.</a></span>
    , "No remaining credits"
    , "No slots remaining"
    , "Unlocking is not possible for this case. The sending limit of 10 emails has been reached already."
    , "Please select cases to unlock"
    , "Unlock Fail!"
    , "Select up to 50 cases to download a report in PDF format"
    , "Email sending is not possible for this case. The sending limit of 10 emails has been reached already."
  ];

  const { ocmnOpen, ocmnLoading, ocmnIndex, ocmnList, ocmnPreviousStartDate, ocmnStartDate, ocmnEndDate, success, sentCnt, unsubsEmails, disableEmails, sentSuccess
    , ocmnPreviewEmail, ocmnPreviewPdf, ocmnPreviewLoading, ocmnSuggestedContactsList, ocmnCurPage, ocmnTotalPage, ocmnDataPosition, unlockCredit, unlockCnt, upgradeAlertMessage, result, showStartup, ocmnAutoSendYn } = useSelector(state => ({
    ocmnOpen: state.MadridNotifier.ocmnOpen,
    ocmnLoading: state.MadridNotifier.ocmnLoading,
    ocmnIndex: state.MadridNotifier.ocmnIndex,
    ocmnList: state.MadridNotifier.ocmnList,
    ocmnPreviousStartDate: state.MadridNotifier.ocmnPreviousStartDate,
    ocmnStartDate: state.MadridNotifier.ocmnStartDate,
    ocmnEndDate: state.MadridNotifier.ocmnEndDate,
    ocmnIsFirstReload: state.MadridNotifier.ocmnIsFirstReload,
    success: state.MadridNotifier.success,
    sentCnt: state.MadridNotifier.sentCnt,
    unsubsEmails: state.MadridNotifier.unsubsEmails,
    disableEmails: state.MadridNotifier.disableEmails,
    sentSuccess: state.MadridNotifier.sentSuccess,
    ocmnPreviewEmail: state.MadridNotifier.ocmnPreviewEmail,
    ocmnPreviewPdf: state.MadridNotifier.ocmnPreviewPdf,
    ocmnPreviewLoading: state.MadridNotifier.ocmnPreviewLoading,
    ocmnSuggestedContactsList: state.MadridNotifier.ocmnSuggestedContactsList,
    ocmnCurPage: state.MadridNotifier.ocmnCurPage,
    ocmnTotalPage: state.MadridNotifier.ocmnTotalPage,
    ocmnDataPosition: state.MadridNotifier.ocmnDataPosition,
    unlockCredit: state.Unlock.unlockCredit,
    unlockCnt: state.Unlock.unlockCnt,
    upgradeAlertMessage: state.MadridNotifier.upgradeAlertMessage,
    result: state.MadridNotifier.result,
    showStartup: state.MadridNotifier.showStartup,
    ocmnAutoSendYn: state.MadridNotifier.ocmnAutoSendYn,
  }));

  // const [ocmnOpen, setOcmnOpen] = useState(true);
  const [confirmMsg, setConfirmMsg] = useState("");
  const [confirmAlert, setConfirmAlert] = useState(false);
  const [confirmBtnText, setConfirmBtnText] = useState("");
  const [cancelBtnText, setCancelBtnText] = useState("");

  const [sendConfirmAlert, setSendConfirmAlert] = useState(false);
  const [dontShowAgainSendConfirmAlert, setDontShowAgainSendConfirmAlert] = useState(false);

  const [isOpen, setIsOpen] = useState(true);
  const [numPages, setNumPages] = useState(null);
  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }
  
  const [isEmailSetting, setIsEmailSetting] = useState(true);

  const toggle = () => {
    dispatch(onAddOcmnLog({actionName:"Preview" + (isOpen ? " close" : " open")}));
    setIsOpen(!isOpen);
  }

  const setOcmnLoading = () => {
    dispatch(onSetOcmnLoading(!ocmnLoading));
  }

  useEffect(() => { 
    if (ocmnList.length > 0) {
      if (ocmnList[ocmnIndex].premSubsYn === 'Y' || ocmnList[ocmnIndex].unlockYn === 'Y') {
        dispatch(onGetOCMNSuggestedContacts({targetGroupNo:ocmnList[ocmnIndex].representativeGroupNo}));
      }

      if ((ocmnList[ocmnIndex].exceptJurSetYn === 'Y' && (ocmnList[ocmnIndex].irEmlTmpSetYn === 'Y' || ocmnList[ocmnIndex].exceptJurEmlTmpSetYn === 'Y') && ocmnList[ocmnIndex].exceptJurSendEmailConfirmStatus === 'Confirmed')
          || (ocmnList[ocmnIndex].exceptJurSetYn === 'N' && (ocmnList[ocmnIndex].irEmlTmpSetYn === 'Y' || ocmnList[ocmnIndex].defaultJurEmlTmpSetYn === 'Y') && ocmnList[ocmnIndex].defaultJurSendEmailConfirmStatus === 'Confirmed')) {
        const _madridNotifier = [];
        _madridNotifier.push(ocmnList[ocmnIndex]);

        setIsEmailSetting(true);
        dispatch(onOcmnPreviewMadridEmail(_madridNotifier, "IN"));
      } else {
        setIsEmailSetting(false);
      }
    }
  }, [ocmnList]);

  useEffect(() => { 
    // console.log("pathname : " + window.location.pathname);
    if (window.location.pathname === '/dashboard') {
      dispatch(onSetOcmnOpen(true));
    }
  }, []);

  useEffect(() => { 
    if (ocmnOpen) {
      // console.log('ocmnOpen : ' + ocmnOpen + ', showStartup : ' + showStartup);
      dispatch(onGetOCMNList({showStartup:showStartup}));
    }
  }, [ocmnOpen]);

  useEffect(() => { 
    // console.log('showStartup : ' + showStartup);
    if (showStartup === 'Y') {
      // console.log('showStartup onSetOcmnOpen : ' + showStartup);
      if (ocmnOpen) {
        dispatch(onGetOCMNList({showStartup:showStartup}));
      } else {
        dispatch(onSetOcmnOpen(true));
      }
    }
  }, [showStartup]);

  const numberWithCommas = (x) => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  const dontShowAgain = () => {
    // console.log("dontShowAgain >>>");
    dispatch(onSaveOCMNShowStartup({showStartup:"M"}));
    dispatch(onAddOcmnLog({actionName:"DontShowAgain"}));
    dispatch(onSetOcmnPreviewEmail({}));
    dispatch(onChangeOcmnSuggestedContactsList([]));
    dispatch(onSetOcmnOpen(false));
  }

  const [alertMsg, setAlertMsg] = useState("");

  const changeOcmnData = (changeFlag, paramStartDate) => {
    // console.log("changeOcmnData >>> index : " + ocmnIndex + ", changeFlag : " + changeFlag + ", startDate : " + paramStartDate + ", ocmnDataPosition : " + ocmnDataPosition);
    dispatch(onAddOcmnLog({actionName:changeFlag}));

    if (changeFlag === 'Previous') {
      if (ocmnIndex > 0) {
        dispatch(onChangeOcmnSuggestedContactsList([]));
        dispatch(onSetOcmnIndex(ocmnIndex-1));
        
        if (ocmnList[ocmnIndex-1].premSubsYn === 'Y' || ocmnList[ocmnIndex-1].unlockYn === 'Y') {
          dispatch(onGetOCMNSuggestedContacts({targetGroupNo:ocmnList[ocmnIndex-1].representativeGroupNo})); 
        }

        if (isEmailSetting) {
          const _madridNotifier = [];
          _madridNotifier.push(ocmnList[ocmnIndex-1]);
          dispatch(onOcmnPreviewMadridEmail(_madridNotifier, "IN"));
        }
      } else {
        if (ocmnPreviousStartDate === '00000000' || ocmnDataPosition === "IS_FIRST") {
          setAlertMsg("This is the first case.");
        } else {
          dispatch(onChangeOcmnSuggestedContactsList([]));
          dispatch(onGetOCMNList({previousStartDate: ocmnPreviousStartDate, startDate: paramStartDate, curPage: (Number(ocmnCurPage)-1), totalPage: ocmnTotalPage, changeFlag: changeFlag, showStartup : showStartup}));
        }
      }
    } else {
      if (ocmnIndex < ocmnList.length-1) {
        dispatch(onChangeOcmnSuggestedContactsList([]));
        dispatch(onSetOcmnIndex(ocmnIndex+1));
        
        if (ocmnList[ocmnIndex+1].premSubsYn === 'Y' || ocmnList[ocmnIndex+1].unlockYn === 'Y') {
          dispatch(onGetOCMNSuggestedContacts({targetGroupNo:ocmnList[ocmnIndex+1].representativeGroupNo}));
        }

        if (isEmailSetting) {
          const _madridNotifier = [];
          _madridNotifier.push(ocmnList[ocmnIndex+1]);
          dispatch(onOcmnPreviewMadridEmail(_madridNotifier, "IN"));
        }
      } else {
        if (ocmnDataPosition === "IS_LAST") {
          setAlertMsg("This is the last case.");
        } else {
          dispatch(onChangeOcmnSuggestedContactsList([]));

          let sentCnt = 0;
          ocmnList.map((ocmn, index) => {
            if (ocmn.lastContactDate !== '0000-00-00') {
              sentCnt++;
            }
          });

          // console.log("ocmnList.length : " + ocmnList.length + ", sentCnt : " + sentCnt);

          dispatch(onGetOCMNList({previousStartDate: ocmnPreviousStartDate, startDate: paramStartDate, curPage: (ocmnList.length === sentCnt ? Number(ocmnCurPage) : (Number(ocmnCurPage)+1)), totalPage: ocmnTotalPage, changeFlag: changeFlag, showStartup : showStartup}));
        }
      }
    }
  }

  const sendOCMNEmail = () => {
    if (isEmailSetting) {
      // console.log("sendOCMNEmail >>> " + ocmnIndex);

      const _madridNotifier = [];
      _madridNotifier.push(ocmnList[ocmnIndex]);

      dispatch(onSendOCMNEmail(_madridNotifier, "IN", ocmnSuggestedContactsList));
    } else {
      setAlertMsg(<span>Please edit your "Jurisdiction defaults" in your <a href='/emailingSettings' className='a-href-underline'>emailing settings</a>.</span>);
    }

    dispatch(onAddOcmnLog({actionName:"Send"}));
  }

  const unlockOcmn = () => {
    // console.log("unlockOcmn >>> " + ocmnIndex);

    const _madridNotifier = [];
    _madridNotifier.push(ocmnList[ocmnIndex]);

    dispatch(onUnlockOcmn(_madridNotifier, "", "IN"));
  }

  const suggestedContactsList = ocmnSuggestedContactsList.map((suggestedContact) =>
    <li>{suggestedContact.emailAddress}</li>
  );

  const getSuggestedContactsToEmailList = () => {
    let toEmail = "";

    ocmnSuggestedContactsList.map((suggestedContact) => {
      toEmail += suggestedContact.emailAddress + ", "
    });
    
    if (toEmail !== "") {
      toEmail = toEmail.substring(0, toEmail.length-2);
    }

    return toEmail;
  }

  const openMadridNotifierPage = () => {
    let madridFilterParam = {
      inOutType: 'IN',
      jurType: "",
      jurList: [ocmnList[ocmnIndex].crCode],
      groupNoList: [],
      irNumber: ocmnList[ocmnIndex].irNumber,
      datePeriod: "",
      startDate: ocmnStartDate,
      endDate: ocmnEndDate,
      entityStatus: "onlyNonHidden",
      hasRep: "Y",
      emailSent: "BOTH",
      dateType: "Inscribed",
    };

    localStorage.setItem("ocmnMadridFilterParam", JSON.stringify(madridFilterParam))

    window.open("/madridNotifier", "_blank");
  }

  return (
    <>
    <Modal
      size="xl"
      isOpen={ocmnOpen && ocmnList.length > 0}
      toggle={() => {
        dispatch(onAddOcmnLog({actionName:"Toggle"}));
        dispatch(onSetOcmnPreviewEmail({}));
        dispatch(onChangeOcmnSuggestedContactsList([]));
        dispatch(onSetOcmnOpen(false));
      }}
    >
      <div className="modal-header">
        <button
          onClick={() => {
            dispatch(onAddOcmnLog({actionName:"HeaderClose"}));
            dispatch(onSetOcmnPreviewEmail({}));
            dispatch(onChangeOcmnSuggestedContactsList([]));
            dispatch(onSetOcmnOpen(false));
          }}
          type="button"
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body">
        {ocmnList && ocmnList.length !== 0? 
        <>
        <Card className="mb-2">
          <CardBody className="p-1">
            <Row>
              <h5
                className="modal-title mt-0"
                id="myExtraLargeModalLabel"
                style={{textAlign: "center"}}
              >
              Land your next big client with just one click: <br/>
              send a Madrid notification for the following case now!
              </h5>
            </Row>
            <Row>
              <div className="d-flex gap-5 horizon-align-center vertical-align-center">
                <Link to='#' onClick={()=> {changeOcmnData("Previous", ocmnStartDate)}} className={ocmnDataPosition === "IS_FIRST" && ocmnIndex === 0 ? "text-danger" : ""} style={{fontSize: "20px"}}>{"< Previous"}</Link>
                
                {ocmnList[ocmnIndex].premSubsYn === 'Y' || ocmnList[ocmnIndex].unlockYn === 'Y' ?
                  ocmnList[ocmnIndex].lastContactDate === '0000-00-00' ?
                    <Link to="#" onClick={()=>{
                      if (ocmnAutoSendYn === 'N') {
                        setSendConfirmAlert(true);
                      } else {
                        // console.log('sendOCMNEmail >>');
                        sendOCMNEmail();
                      }
                      }}>
                    <i className={isEmailSetting ? "uil uil-message text-success" : "uil uil-message text-danger"} style={{fontSize: "40px"}}></i>
                    </Link>
                  :
                  <div class="text-align-center">
                    <h5 class="text-success" style={{marginBottom: "0px"}}>Sent</h5>
                    <i className="uil uil-check-circle text-success" style={{fontSize: "40px"}}></i>
                  </div>
                :
                  <Link to="#" className={unlockCredit - unlockCnt > 0 ? ocmnList[ocmnIndex].wecrestSendCount >= 10 ? "text-danger" : "text-success" : "text-danger"}
                    onClick={()=>{
                      if (unlockCredit - unlockCnt > 0) {
                        if (ocmnList[ocmnIndex].wecrestSendCount < 10) {
                          setConfirmMsg(<>
                            Unlock to view the details and send emails for this case.<br/>
                            You cannot unlock a case, if sending limit of 10 emails per case has been reached already.
                            </>);
                          setConfirmBtnText("Confirm");
                          setCancelBtnText("Cancel");
                          setConfirmAlert(true);
                        }
                      } else {
                        dispatch(onSetUpgradeAlertMessage({title:"Sorry, unfortunately you do not have any credits remaining.",text:"Upgrade your WeCrest plan to receive unlimited access for your jurisdiction, or wait until you receive more free credits on Monday."}))
                      }

                      dispatch(onAddOcmnLog({actionName:"Unlock"}));
                    }}
                  >
                  <i className={ocmnList[ocmnIndex].wecrestSendCount >= 10 ? "uil uil-lock-alt" : "uil uil-unlock-alt"} style={{fontSize: "40px"}} id={"unlock_"+ocmnIndex}></i>
                  <UncontrolledTooltip placement="top" target={"unlock_"+ocmnIndex}>
                    {unlockCredit - unlockCnt > 0 ? 
                      ocmnList[ocmnIndex].wecrestSendCount >= 10 ? 
                        errMsg[5] 
                      : 
                      ocmnList[ocmnIndex].wecrestSendCount >= 5 ? 
                          <>Heads up! 5 or more WeCrest members already sent an email for this case. You can go ahead and unlock or click cancel and find a green or blue case.<br/>Green: 0 email sent,<br/>Blue: 1-4 emails sent,<br/>Orange: 5-9 emails sent,<br/>Red: 10 emails sent</> 
                        :
                          "Unlock to view the details and send emails for this case." 
                    : errMsg[3]}
                  </UncontrolledTooltip>
                  </Link>
                }
                <Link to='#' onClick={()=> {changeOcmnData("Next", ocmnStartDate)}} className={ocmnDataPosition === "IS_LAST" && ocmnIndex === ocmnList.length-1 ? "text-danger" : ""} style={{fontSize: "20px"}}>{"Next >"}</Link>
              </div>
            </Row>
          </CardBody>
        </Card>
        <Card className="mb-2">
          <CardBody>
            <Row>
              <Col xl={9}>
                <Row className="mb-3">
                  {/*
                  <div className="d-flex gap-1">
                    <b>Representative: </b>
                    <p>{ocmnList[ocmnIndex].representativeInfo}</p>
                  </div>
                  */}
                  <label
                    className="form-check-label col-md-3"
                    htmlFor="Representative"
                  >
                    Representative:
                  </label>
                  <div className="col-md-9">
                    {ocmnList[ocmnIndex].representativeInfo}
                    {ocmnSuggestedContactsList && ocmnSuggestedContactsList.length > 0 ?
                    <ul style={{marginBottom: "0rem"}}>{suggestedContactsList}</ul>
                    : null
                    }
                  </div>
                </Row>
                <Row className="mb-3">
                  {/*
                  <div className="d-flex gap-1">
                    <b>Holder: </b>
                    <p>{ocmnList[ocmnIndex].holderInfo}</p>
                  </div>
                  */}
                  <label
                    className="form-check-label col-md-3"
                    htmlFor="Holder"
                  >
                    Holder:
                  </label>
                  <div className="col-md-9">
                  {ocmnList[ocmnIndex].holderInfo}
                  </div>
                </Row>
                <Row className="mb-3">
                {/*
                  <div className="d-flex gap-1">
                    <b>IR number: </b>
                    <p>{ocmnList[ocmnIndex].irNumber}</p>
                  </div>
                  */}
                  <label
                    className="form-check-label col-md-3"
                    htmlFor="IRnumber"
                  >
                    IR number:
                  </label>
                  <div className="col-md-9">
                  {ocmnList[ocmnIndex].irNumber}
                  {
                    ocmnList[ocmnIndex].premSubsYn === 'Y' || ocmnList[ocmnIndex].unlockYn === 'Y' ?
                    <>&nbsp;(<Link to='#' onClick={()=> {openMadridNotifierPage()}}>Open case in Madrid Notifier</Link>)</>
                    :
                    null
                  }
                  </div>
                </Row>
                <Row className="mb-3">
                {/*
                  <div className="d-flex gap-1">
                    <b>Jurisdiction: </b>
                    <p>{ocmnList[ocmnIndex].crCode} {ocmnList[ocmnIndex].crName}</p>
                  </div>
                  */}
                  <label
                    className="form-check-label col-md-3"
                    htmlFor="Jurisdiction"
                  >
                    Jurisdiction:
                  </label>
                  <div className="col-md-9">
                  {ocmnList[ocmnIndex].crCode} {ocmnList[ocmnIndex].crName}
                  </div>
                </Row>
                <Row className="mb-3">
                {/*
                  <div className="d-flex gap-1">
                    <b>WIPO inscription date: </b>
                    <p>{ocmnList[ocmnIndex].recordedDate}</p>
                  </div>
                  */}
                  <label
                    className="form-check-label col-md-3"
                    htmlFor="Inscribeddate"
                  >
                    Inscription date:
                  </label>
                  <div className="col-md-9">
                  {ocmnList[ocmnIndex].recordedDate}
                  </div>
                </Row>
                <Row className="mb-3">
                {/*
                  <div className="d-flex gap-1">
                    <b>Date uploaded to WeCrest: </b>
                    <p>{ocmnList[ocmnIndex].druSendDate}</p>
                  </div>
                  */}
                  <label
                    className="form-check-label col-md-3"
                    htmlFor="Uploadeddate"
                  >
                    Uploaded date:
                  </label>
                  <div className="col-md-9">
                  {ocmnList[ocmnIndex].druSendDate}
                  </div>
                </Row>
              </Col>
              <Col xl={3} className="d-flex horizon-align-center">
                <div>
                <Row className="mb-3">
                  <label
                      className="form-check-label d-flex horizon-align-center"
                      htmlFor="Trademark"
                    >
                  Trademark
                  </label>
                </Row>
                <Row className="mb-3 d-flex horizon-align-center">
                  {ocmnList[ocmnIndex].tmStorageUrl && ocmnList[ocmnIndex].tmStorageUrl.startsWith('https:') ? 
                  <img
                    id={"ocmn_tm_img"}
                    src={ocmnList[ocmnIndex].tmStorageUrl}
                    alt=""
                    className="img-fluid mx-auto d-block tab-img rounded"
                    referrerpolicy="no-referrer"
                  />
                  : ocmnList[ocmnIndex].tmTxt.replace('&#36;', '$')}
                </Row>
                </div>
              </Col>
            </Row>
          </CardBody>
        </Card>
        </>
        : null}

        <Card className="mb-2">
          <CardBody>
            <Row>
              <div className="col-md-8">
                {/*
              {ocmnIndex+1} / {ocmnList.length}
                */}
              </div>
              <div className="col-md-3 d-flex vertical-align-center">
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    value=""
                    id="dontShowAgain"
                    onChange={e => dontShowAgain()}
                    checked={false}
                  />
                  <label
                    className="form-check-label"
                    htmlFor="dontShowAgain"
                  >
                    Don’t show again for one month
                  </label>
                </div>
              </div>
              <button
                type="button"
                onClick={() => {
                  dispatch(onAddOcmnLog({actionName:"BottomClose"}));
                  dispatch(onSetOcmnPreviewEmail({}));
                  dispatch(onChangeOcmnSuggestedContactsList([]));
                  dispatch(onSetOcmnOpen(false));
                }}
                className="col-md-1 btn btn-secondary waves-effect"
                data-dismiss="modal"
              >
                Close
              </button>
            </Row>
          </CardBody>
        </Card>

        {
          !ocmnPreviewLoading && !isEmailSetting?
          <Card style={{marginBottom: "0rem"}}>
            <CardBody>
              <span>Please edit your "Jurisdiction defaults" in your <a href='/emailingSettings' className='a-href-underline'>emailing settings</a>.</span>
            </CardBody>
          </Card>
          : null
        }

        {
          ocmnPreviewLoading?
          <Card style={{marginBottom: "0rem"}}>
              <div class="d-flex justify-content-center">
              <Spinner className="m-1" color="primary" />
              </div>
              <p className="mb-0 text-center">
              Please wait while data loads…
              </p>
          </Card>
          : null
        }
        
        {ocmnList && ocmnList.length !== 0 && (ocmnList[ocmnIndex].premSubsYn === 'Y' || ocmnList[ocmnIndex].unlockYn === 'Y') && ocmnPreviewEmail && Object.keys(ocmnPreviewEmail).length !== 0? 
        <Card style={{marginBottom: "0rem"}}>
          <Link onClick={toggle} className="text-dark" to="#">
            <div className="p-4">
              <Media className="d-flex align-items-center">
                <div className="flex-shrink-0 me-3">
                  <i className="uil uil-file-search-alt text-primary h2"></i>
                </div>
                <div className="flex-grow-1 overflow-hidden">
                  <h5 className="font-size-16 mb-1">Preview</h5>
                </div>
                <i className={isOpen ? "mdi mdi-chevron-up accor-down-icon font-size-24" : "mdi mdi-chevron-down accor-down-icon font-size-24"}></i>
              </Media>
            </div>
          </Link>
          <Collapse isOpen={isOpen}>
            <Card className="mb-2">
              <CardBody>
                <Row className="mb-3">
                  <label
                    className="form-check-label col-md-1"
                    htmlFor="To"
                  >
                    To:
                  </label>
                  <div className="col-md-11">
                  {
                  ocmnSuggestedContactsList && ocmnSuggestedContactsList.length > 0?
                  getSuggestedContactsToEmailList()
                  : null
                  }
                  </div>
                </Row>
                <Row className="mb-3">
                  <label
                    className="form-check-label col-md-1"
                    htmlFor="CC"
                  >
                    CC:
                  </label>
                  <div className="col-md-11">
                  {ocmnPreviewEmail.emailCC? ocmnPreviewEmail.emailCC : null}
                  </div>
                </Row>
                <Row className="mb-3">
                  <label
                    className="form-check-label col-md-1"
                    htmlFor="BCC"
                  >
                    BCC:
                  </label>
                  <div className="col-md-11">
                  {ocmnPreviewEmail.emailBCC? ocmnPreviewEmail.emailBCC : null}
                  </div>
                </Row>
                <Row className="mb-3">
                  <label
                    className="form-check-label col-md-1"
                    htmlFor="Subject"
                  >
                    Subject:
                  </label>
                  <div className="col-md-11">
                  {ocmnPreviewEmail.subject? ocmnPreviewEmail.subject : null}
                  </div>
                </Row>
              </CardBody>  
            </Card>
            <Card className="mb-2">
              <CardBody style={{overflow:"scroll", height:"500px"}} dangerouslySetInnerHTML={{__html:ocmnPreviewEmail.emailForm? ocmnPreviewEmail.emailForm : null}}></CardBody>
            </Card>
            <Card className={ocmnPreviewPdf !== "" ? "mb-2" : "mb-0"}>
              <CardBody>
                {ocmnPreviewPdf !== "" ?
                <Row className={ocmnPreviewEmail.attachFiles && ocmnPreviewEmail.attachFiles.length ? "mb-3" : ""}>
                  <div>
                  <a href={ocmnPreviewEmail.pdfStorageUrl} target="_blank" rel="noreferrer"><i className="uil uil-google-drive text-primary font-size-22"></i>Google drive</a>&nbsp;
                  <a href={ocmnPreviewPdf} target="_blank" rel="noreferrer"><i className="mdi mdi-file-pdf text-danger font-size-22"></i>WeCrest drive</a>
                  </div>
                </Row>
                :
                <Row className={ocmnPreviewEmail.attachFiles && ocmnPreviewEmail.attachFiles.length ? "mb-3" : ""}>
                  <div>
                  <a href={ocmnPreviewEmail.pdfStorageUrl} target="_blank" rel="noreferrer"><i className="mdi mdi-file-pdf text-danger font-size-22"></i>{ocmnPreviewEmail.irNumber}</a>
                  </div>
                </Row>
                }
                {
                  ocmnPreviewEmail.attachFiles && ocmnPreviewEmail.attachFiles.length?
                  ocmnPreviewEmail.attachFiles.map((attach, index)=>{
                      return (
                        <Row className={ocmnPreviewEmail.attachFiles.length == index + 1 ? "" : "mb-3"}>
                          <div>
                          {attach.fileName}
                          </div>
                        </Row>
                      )
                    })
                  :
                  null
                }
              </CardBody>
            </Card>

            {ocmnPreviewPdf !== "" ?
            <Card className="mb-0">
              <CardBody className="p-1 d-flex horizon-align-center" style={{overflow:"scroll", height:"500px"}}>
                <PDFDocumentWrapper>
                  <Document file={ocmnPreviewPdf} onLoadSuccess={onDocumentLoadSuccess}>
                  {Array.apply(null, Array(numPages))
                    .map((x, i)=>i+1)
                    .map(page => <Page pageNumber={page}/>)}
                  </Document>
                </PDFDocumentWrapper>
              </CardBody>
            </Card>
            : null
            }
          </Collapse>
        </Card>
        : null}

      </div>

      {sentSuccess? (
        <SweetAlert
        timeout={2000}
        showCloseButton={false}
        showConfirm={false}
        success
        onConfirm={() => {
          if (sentSuccess === 'Next') {
            changeOcmnData("Next", ocmnStartDate);
          }
          dispatch(onSetSentSuccess(""));
        }}
      ></SweetAlert>
      ) : null}
      
      {alertMsg !== '' ? (
        <SweetAlert
          onConfirm={() => {
            setAlertMsg('');
          }}
          onCancel={() => {
            setAlertMsg('');
          }}
        >{alertMsg}
        </SweetAlert>
        ) : null}

        {result !== -1 ? (
          <SweetAlert
            title={errMsg[result]}
            onConfirm={() => {
              dispatch(onSetResult(-1));
            }}
          >{result === 3 ? "Credits will be topped up next month." : null}
          </SweetAlert>
          ) : null}

        {confirmAlert ? (
          <SweetAlert
            showCancel
            confirmBtnText={confirmBtnText}
            cancelBtnText={cancelBtnText}
            confirmBtnBsStyle="success"
            cancelBtnBsStyle="danger"
            onConfirm={() => {
              unlockOcmn();
              setConfirmAlert(false);
            }}
            onCancel={() => setConfirmAlert(false)}
          >
            {confirmMsg}
          </SweetAlert>
        ) : null}

        {upgradeAlertMessage.title != "" ? (
          <SweetAlert
            warning
            showCancel
            confirmBtnText="Upgrade"
            cancelBtnText="No thanks"
            confirmBtnBsStyle="wcdark"
            cancelBtnBsStyle="secondary"
            onConfirm={() => {
              window.location.href = "/membershipPlan";
            }}
            onCancel={() => {
              dispatch(onSetUpgradeAlertMessage({title:"",text:""}))
              // madridValidation.setFieldValue("submitType", "view");
              // madridValidation.handleSubmit(madridValidation.values);
            }}
            title={upgradeAlertMessage.title}
          >
            {upgradeAlertMessage.text}
          </SweetAlert>
        ) : null}

        {sendConfirmAlert ? (
          <SweetAlert
            showCancel
            confirmBtnText="Yes, send!"
            cancelBtnText="Cancel"
            confirmBtnBsStyle="success"
            cancelBtnBsStyle="danger"
            onConfirm={() => {
              if (dontShowAgainSendConfirmAlert) {
                // console.log('onSaveOCMNAutoSendYn >> Y');
                dispatch(onSaveOCMNAutoSendYn({autoSendYn:"Y"}));
              }
              sendOCMNEmail();
              // console.log('sendOCMNEmail >>');
              setSendConfirmAlert(false);
            }}
            onCancel={() => setSendConfirmAlert(false)}
          >
            <label
              className="form-label"
              htmlFor="rdoSendTargetRep"
              style={{fontSize:"20px"}}
            >
              Are you sure you want to send this email?
            </label>
            <br/>
            <div style={{marginLeft:"20px"}}>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  value=""
                  id="dontShowAgainSendConfirmAlert"
                  onChange={(e)=>setDontShowAgainSendConfirmAlert(e.target.checked)}
                  checked={dontShowAgainSendConfirmAlert}
                />
                <label
                  className="form-check-label"
                  htmlFor="dontShowAgainSendConfirmAlert"
                >
                  Don’t show again
                </label>
              </div>
            </div>
          </SweetAlert>
        ) : null}   
    </Modal>

    {
    ocmnLoading?
    <LoadingModal loading={ocmnLoading} setLoading={setOcmnLoading}></LoadingModal>
    :
    null
    }
    
    {
    /*
    <SweetAlert
      title={success}
      showCloseButton={false}
      success
      onConfirm={() => {
        dispatch(onSetSentSuccess(""));
      }}
    >
      <div style={{textAlign: 'left'}}>
        <div style={{marginBottom:'1rem'}}>Emails have only been sent for entities with enabled contacts.</div>
        <div><label className='text-success' style={{marginBottom:'1rem'}}>Sent: {numberWithCommas(sentCnt)} {sentCnt > 1 ? "emails" : "email"}</label></div>
        {disableEmails.length > 0 || unsubsEmails.length > 0 ?
        <>
        <div className='text-danger'>
        <label>Not sent</label><br/>
        <ul>
        {disableEmails.length > 0 ? <li>{numberWithCommas(disableEmails.length)} disabled {disableEmails.length > 1 ? "emails" : "email"}</li> : null}
        {unsubsEmails.length > 0 ? <li>{numberWithCommas(unsubsEmails.length)} unsubscribed {unsubsEmails.length > 1 ? "emails" : "email"}</li> : null}
        </ul>
        </div>
        </>
        : null}
        {disableEmails.length?
        <>
        <div>
        <label>Disabled</label><br/>
        <ul>
        {disableEmails.map((emailAddress, index) => {
          return (
            <li>{emailAddress}</li>
          )
        })}
        </ul>
        </div>
        </>
        : null}
        {unsubsEmails.length?
        <>
        <div>
        <label>Unsubscribed</label><br/>
        <ul>
        {unsubsEmails.map((emailAddress, index) => {
          return (
            <li>{emailAddress}</li>
          )
        })}
        </ul>
        </div>
        </>
        : null}
      </div>
    </SweetAlert>
    */
    }

    
    </>
  )
}

export default OneClickMadridNotifier;