import { PROFILE_ERROR, PROFILE_SUCCESS, EDIT_PROFILE, RESET_PROFILE_FLAG, CHANGE_USER_NAME, CHANGE_PHOTO_URL } from "./actionTypes"

const initialState = {
  error: "",
  success: "",
  username: "",
  photoUrl: "",
}

const profile = (state = initialState, action) => {
  switch (action.type) {
    case EDIT_PROFILE:
      state = { ...state }
      break
    case PROFILE_SUCCESS:
      state = { ...state, success: action.payload }
      break
    case PROFILE_ERROR:
      state = { ...state, error: action.payload }
      break
    case RESET_PROFILE_FLAG :
      state = { ...state, success: null }
      break
    case CHANGE_USER_NAME:
      return {
        ...state,
        username: action.payload,
      }
    case CHANGE_PHOTO_URL:
      return {
        ...state,
        photoUrl: action.payload,
      }
    default:
      state = { ...state }
      break
  }
  return state
}

export default profile
