import React, {useState, useEffect, useRef} from 'react';
import { useSelector, useDispatch } from "react-redux";
import {
    Col,
    Row,
    Card,
    CardBody,
    Form,
    Label,
    Input,
    Button,
    FormFeedback,
  } from "reactstrap"
import EditorModal from "../../components/Common/EditorModal"
import { useFormik } from "formik";
import SearchUser from "./SearchUser"
import SearchSubscriptionItem from "./SearchSubscriptionItem"
import PreviewInvoice from "./PreviewInvoice"
import { Link } from "react-router-dom";
import SweetAlert from "react-bootstrap-sweetalert";

import {
  getCresterList as onGetCresterList,
  setAdmInvoiceManagementError as onSetAdmInvoiceManagementError,
  createAdminInvoice as onCreateAdminInvoice,
  getInvoiceItem as onGetInvoiceItem,
  getUserInvoiceInfoList as onGetUserInvoiceInfoList,
  getUserSubscriptionList as onGetUserSubscriptionList,
} from "../../store/actions";
import { cloneDeep } from 'lodash';

const AdmCreateInvoice = props => {
  const dispatch = useDispatch();

  const { admInvoiceManagementUserInfo, admInvoiceManagementItemList, cresterList, userInvoiceInfoList, emailSubject, emailCcItems, emailBccItems, attachFiles, copyFiles, upFiles, userSubscriptionList } = useSelector(state => ({
    admInvoiceManagementUserInfo: state.AdmInvoiceManagement.admInvoiceManagementUserInfo,
    admInvoiceManagementItemList: state.AdmInvoiceManagement.admInvoiceManagementItemList,
    cresterList: state.AdmInvoiceManagement.cresterList,
    userInvoiceInfoList: state.AdmInvoiceManagement.userInvoiceInfoList,

    emailSubject: state.Editor.emailSubject,
    emailCcItems: state.Editor.emailCcItems,
    emailBccItems: state.Editor.emailBccItems,
    attachFiles: state.Editor.attachFiles,
    copyFiles: state.Editor.copyFiles,
    upFiles: state.Editor.upFiles,

    userSubscriptionList: state.Settings.userSubscriptionList,
  }));

  const [errorMsg, setErrorMsg] = useState('');
  const [isOpenPreview, setIsOpenPreview] = useState(false);
  const emailType = 'ADM_INVOICE';
  // const [isUpdateInvoiceInfo, setIsUpdateInvoiceInfo] = useState(false);
  // const [alertUpdateInvoiceInfo, setAlertUpdateInvoiceInfo] = useState(false);

  // [S] Email Editor
  const editorModalRef = useRef(null);
  const editorModal = useRef(null);

  const [isOpenEditor, setIsOpenEditor] = useState(false);
  const [isInitEditor, setIsInitEditor] = useState(false);
  const [loadedEditor, setLoadedEditor] = useState(false);
  // const [emailCode, setEmailCode] = useState('010');
  const emailCode = '010';
  const [createFlag, setCreateFlag] = useState(false);
  
  function openEditor() {
    if (!isInitEditor) {
      // console.log('editor init start');
      setIsInitEditor(true);
    } else {
      // console.log('editor inited');
    }
    
    setIsOpenEditor(true);
    removeBodyCss();
  }

  function closeEditor() {
    // console.log('closeEditor')
    setIsOpenEditor(false);
    // editorModalRef.current.clearEditorContent();
    document.body.classList.remove("modal-open");
    document.body.classList.remove("no_padding");
    document.body.style.overflow = 'visible';
    document.body.style.paddingRight = '';
  }

  function removeBodyCss() {
    document.body.classList.add("no_padding");
    document.body.classList.add("modal-open");
    document.body.style.overflow = 'hidden';
  }
  
  const handleCloseEmailSettingModal = (e) => {
    if (e.target.id === 'EditorModal' && (!editorModal.current || !editorModal.current.contains(e.target))) {
      closeEditor()
    }
  }
  // [E] Editor

  const previewInvoice = () => {
    if (!filterValidation.values.invoiceInfo) {
      dispatch(onSetAdmInvoiceManagementError("Search user!"));  
      return;
    }

    if (filterValidation.values.invoiceInfo.invoiceCompany === "") {
      dispatch(onSetAdmInvoiceManagementError("Input Invoicing company name!"));  
      return;
    }
    
    if (filterValidation.values.invoiceInfo.invoiceAddress === "") {
      dispatch(onSetAdmInvoiceManagementError("Input Invoicing address!"));   
      return;
    }
    
    if (filterValidation.values.invoiceInfo.accountingEmail === "") {
      dispatch(onSetAdmInvoiceManagementError("Input Accounting email!"));   
      return;
    }

    setIsOpenPreview(true);
  }

  const createAdminInvoice = (emailForm) => {
    // console.log("createAdminInvoice emailForm:" + emailForm);

    closeEditor();

    filterValidation.setFieldValue("emailForm", emailForm);
    filterValidation.setFieldValue("submitType", "createAdminInvoice");

    filterValidation.handleSubmit(filterValidation.values);
  }

  const changeInvoiceInfo = (fieldValue) => {
    // console.log("changeInvoiceInfo : " + fieldValue);

    // console.log("invoiceName 1 : " + filterValidation.values.invoiceInfo.invoiceName);

    if (Number(fieldValue) === -1) {
      filterValidation.setFieldValue("invoiceInfo.userInvoiceInfoSeq", Number(fieldValue));
      filterValidation.setFieldValue("invoiceInfo.invoiceTitle", "");
      filterValidation.setFieldValue("invoiceInfo.invoiceFirstName", "");
      filterValidation.setFieldValue("invoiceInfo.invoiceLastName", "");
      filterValidation.setFieldValue("invoiceInfo.invoiceName", "");
      filterValidation.setFieldValue("invoiceInfo.invoiceCompany", "");
      filterValidation.setFieldValue("invoiceInfo.invoiceAddress", "");
      filterValidation.setFieldValue("invoiceInfo.invoiceEmail", "");
      filterValidation.setFieldValue("invoiceInfo.invoiceTaxId", "");
      filterValidation.setFieldValue("invoiceInfo.invoiceInfo", "");
      
      // console.log("Clear InvoiceInfo : " + fieldValue);

      // console.log("invoiceName 2 : " + filterValidation.values.invoiceInfo.invoiceName);
    } else {
      userInvoiceInfoList.map((userInvoiceInfo) => {
        // console.log(">>> userInvoiceInfo.seq : " + userInvoiceInfo.seq + ", fieldValue : " + fieldValue);
        if (userInvoiceInfo.seq === Number(fieldValue)) {
          // console.log("changeInvoiceInfo userInvoiceInfoSeq : " + fieldValue);
          // console.log("changeInvoiceInfo invoiceName : " + userInvoiceInfo.invoiceFirstName + " " + userInvoiceInfo.invoiceLastName);
          filterValidation.setFieldValue("invoiceInfo.userInvoiceInfoSeq", Number(fieldValue));
          filterValidation.setFieldValue("invoiceInfo.invoiceTitle", userInvoiceInfo.invoiceTitle);
          filterValidation.setFieldValue("invoiceInfo.invoiceFirstName", userInvoiceInfo.invoiceFirstName);
          filterValidation.setFieldValue("invoiceInfo.invoiceLastName", userInvoiceInfo.invoiceLastName);
          filterValidation.setFieldValue("invoiceInfo.invoiceName", (userInvoiceInfo.invoiceFirstName + " " + userInvoiceInfo.invoiceLastName).trim());
          filterValidation.setFieldValue("invoiceInfo.invoiceCompany", userInvoiceInfo.invoiceCompanyName);
          filterValidation.setFieldValue("invoiceInfo.invoiceAddress", userInvoiceInfo.invoiceAddress);
          filterValidation.setFieldValue("invoiceInfo.invoiceEmail", userInvoiceInfo.invoiceEmail);
          filterValidation.setFieldValue("invoiceInfo.invoiceTaxId", userInvoiceInfo.taxId);
          filterValidation.setFieldValue("invoiceInfo.invoiceInfo", userInvoiceInfo.invoiceInfo);
          filterValidation.setFieldValue("invoiceInfo.overwriteBillingProfile", "N");
  
          return false;
        }
      })
    }
  }

  const changeItemList = (index, item) => {
    const _itemList = cloneDeep(filterValidation.values.itemList);
    _itemList[index] = item;

    changeFinalCost('finalDcAmt', filterValidation.values.invoiceInfo.finalDcAmt, _itemList);
  }

  const changeTypeOrTerm = (_itemList, index) => {
    if(_itemList[index].term === 'annually'){
      _itemList[index].cost = _itemList[index].m1*12;
      _itemList[index].dcDesc = '12 months discount'
      _itemList[index].dcAmt = _itemList[index].cost - _itemList[index].m12;
      _itemList[index].dcRate = 30;
      _itemList[index].standardFee = _itemList[index].m12;
    } else if(_itemList[index].term === '6months'){
      _itemList[index].cost = _itemList[index].m1*6;
      _itemList[index].dcDesc = '6 months discount'
      _itemList[index].dcAmt = _itemList[index].cost - _itemList[index].m6;
      _itemList[index].dcRate = 15;
      _itemList[index].standardFee = _itemList[index].m6;
    } else if(_itemList[index].term === '3months'){
      _itemList[index].cost = _itemList[index].m1*3;
      _itemList[index].dcDesc = '3 months discount'
      _itemList[index].dcAmt = _itemList[index].cost - _itemList[index].m3;
      _itemList[index].dcRate = 7.5;
      _itemList[index].standardFee = _itemList[index].m3;    
    } else if(_itemList[index].term === 'monthly'){
      _itemList[index].cost = _itemList[index].m1;
      _itemList[index].dcDesc = ''
      _itemList[index].dcAmt = 0;
      _itemList[index].dcRate = ''
      _itemList[index].standardFee = _itemList[index].m1;
    }

    //Trial은 100% dc로 처리
    if(_itemList[index].type === 'Trial' || _itemList[index].type === 'WeCrest'){
      _itemList[index].addDcAmt = _itemList[index].standardFee;
      _itemList[index].addDcRate = 100;
    }else {
      _itemList[index].addDcAmt = 0
      _itemList[index].addDcRate = 0;
    }

    _itemList[index].endDate = calcEndDate(_itemList[index].startDate, _itemList[index].type, _itemList[index].term);
    _itemList[index].subtotal = Number(_itemList[index].standardFee) - Number(_itemList[index].addDcAmt);
  }

  const changeType = (index, fieldValue) => {
    const _itemList = cloneDeep(filterValidation.values.itemList);
    _itemList[index].type = fieldValue;

    changeTypeOrTerm(_itemList, index);

    changeFinalCost('finalDcAmt', filterValidation.values.invoiceInfo.finalDcAmt, _itemList);
  }

  const changeTerm = (index, fieldValue) => {
    const _itemList = cloneDeep(filterValidation.values.itemList);
    _itemList[index].term = fieldValue;
        
    changeTypeOrTerm(_itemList, index);

    changeFinalCost('finalDcAmt', filterValidation.values.invoiceInfo.finalDcAmt, _itemList);
  }

  const changeCost = (index, fieldName, fieldValue) => {
    const _itemList = cloneDeep(filterValidation.values.itemList);

    if (fieldName === 'cost') {
      _itemList[index].cost = fieldValue ? fieldValue : 0;
      _itemList[index].dcAmt = _itemList[index].dcAmt ? _itemList[index].dcAmt : 0;
      _itemList[index].dcRate = Math.round((Number(_itemList[index].dcAmt) / Number(_itemList[index].cost)) * 100);
      _itemList[index].standardFee = Number(_itemList[index].cost) - Number(_itemList[index].dcAmt);

      _itemList[index].addDcAmt = _itemList[index].addDcAmt ? _itemList[index].addDcAmt : 0;
      _itemList[index].addDcRate = Math.round((Number(_itemList[index].addDcAmt) / Number(_itemList[index].standardFee)) * 100);
      _itemList[index].subtotal = Number(_itemList[index].standardFee) - Number(_itemList[index].addDcAmt);
    } else if (fieldName === 'dcAmt') {
      _itemList[index].dcAmt = fieldValue ? fieldValue : 0;
      _itemList[index].dcRate = Math.round((Number(_itemList[index].dcAmt) / Number(_itemList[index].cost)) * 100);
      _itemList[index].standardFee = Number(_itemList[index].cost) - Number(_itemList[index].dcAmt);

      _itemList[index].addDcAmt = _itemList[index].addDcAmt ? _itemList[index].addDcAmt : 0;
      _itemList[index].addDcRate = Math.round((Number(_itemList[index].addDcAmt) / Number(_itemList[index].standardFee)) * 100);
      _itemList[index].subtotal = Number(_itemList[index].standardFee) - Number(_itemList[index].addDcAmt);
    } else if (fieldName === 'dcRate') {
      _itemList[index].dcRate = fieldValue ? fieldValue : 0;
      _itemList[index].cost = _itemList[index].cost ? _itemList[index].cost : 0;
      _itemList[index].dcAmt = Math.round(Number(_itemList[index].cost) * Number(_itemList[index].dcRate) / 100);
      _itemList[index].standardFee = Number(_itemList[index].cost) - Number(_itemList[index].dcAmt);

      _itemList[index].addDcAmt = _itemList[index].addDcAmt ? _itemList[index].addDcAmt : 0;
      _itemList[index].addDcRate = Math.round((Number(_itemList[index].addDcAmt) / Number(_itemList[index].standardFee)) * 100);
      _itemList[index].subtotal = Number(_itemList[index].standardFee) - Number(_itemList[index].addDcAmt);
    } else if (fieldName === 'standardFee') {
      _itemList[index].standardFee = fieldValue ? fieldValue : 0;
      _itemList[index].cost = _itemList[index].cost ? _itemList[index].cost : 0;
      _itemList[index].dcAmt = Number(_itemList[index].cost) - Number(_itemList[index].standardFee);
      _itemList[index].dcRate = Math.round((Number(_itemList[index].dcAmt) / Number(_itemList[index].cost)) * 100);

      _itemList[index].addDcAmt = _itemList[index].addDcAmt ? _itemList[index].addDcAmt : 0;
      _itemList[index].addDcRate = Math.round((Number(_itemList[index].addDcAmt) / Number(_itemList[index].standardFee)) * 100);
      _itemList[index].subtotal = Number(_itemList[index].standardFee) - Number(_itemList[index].addDcAmt);
    } else if (fieldName === 'addDcAmt') {
      _itemList[index].addDcAmt = fieldValue ? fieldValue : 0;
      _itemList[index].addDcRate = Math.round((Number(_itemList[index].addDcAmt) / Number(_itemList[index].standardFee)) * 100);
      _itemList[index].subtotal = Number(_itemList[index].standardFee) - Number(_itemList[index].addDcAmt);
    } else if (fieldName === 'addDcRate') {
      _itemList[index].addDcRate = fieldValue ? fieldValue : 0;
      _itemList[index].addDcAmt = Math.round(Number(_itemList[index].standardFee) * Number(_itemList[index].addDcRate) / 100);
      _itemList[index].subtotal = Number(_itemList[index].standardFee) - Number(_itemList[index].addDcAmt);
    } else if (fieldName === 'subtotal') {
      _itemList[index].subtotal = fieldValue ? fieldValue : 0;

      _itemList[index].addDcAmt = Number(_itemList[index].standardFee) - Number(_itemList[index].subtotal);
      _itemList[index].addDcRate = Math.round((Number(_itemList[index].addDcAmt) / Number(_itemList[index].standardFee)) * 100);
    }

    // console.log("changeCost itemList[" + index + "] : " + JSON.stringify(_itemList[index]));

    changeFinalCost('finalDcAmt', filterValidation.values.invoiceInfo.finalDcAmt, _itemList);
  }

  const changeStartDate = (index, fieldValue) => {
    const _itemList = cloneDeep(filterValidation.values.itemList);
    _itemList[index].startDate = fieldValue;

    if (fieldValue !== '') {
      _itemList[index].endDate = calcEndDate(fieldValue, _itemList[index].type, _itemList[index].term);
      filterValidation.setFieldValue("itemList", _itemList);
    } 
  }

  const calcEndDate = (_startDate, _type, _term) => {
    let yyyy = _startDate.substr(0,4);
    let mm = _startDate.substr(5,2);
    let dd = _startDate.substr(8,2);                        
    
    let _endDate = new Date(yyyy, mm-1, dd);

    // Trial은 무조건 2주
    if(_type === 'Trial'){
      _endDate.setDate(_endDate.getDate() + 13);
      
      return _endDate.getFullYear() 
              + '-' + (_endDate.getMonth() + 1 < 10 ? '0' + (_endDate.getMonth() + 1) : _endDate.getMonth() + 1) 
              + '-' + (_endDate.getDate() < 10 ? '0' + _endDate.getDate() : _endDate.getDate());
    } else {
      if (_term === 'annually') {
        _endDate.setFullYear(_endDate.getFullYear() + 1);
      } else if (_term === '6months') {
        _endDate.setMonth(_endDate.getMonth() + 6);
      } else if (_term === '3months') {
        _endDate.setMonth(_endDate.getMonth() + 3);
      } else {
        _endDate.setMonth(_endDate.getMonth() + 1);
      }

      _endDate.setDate(_endDate.getDate() - 1);

      return _endDate.getFullYear() 
              + '-' + (_endDate.getMonth() + 1 < 10 ? '0' + (_endDate.getMonth() + 1) : _endDate.getMonth() + 1) 
              + '-' + (_endDate.getDate() < 10 ? '0' + _endDate.getDate() : _endDate.getDate());
    }
  } 

  const changeFinalCost = (fieldName, fieldValue, _itemList) => {
    // console.log('changeFinalCost[' + fieldName + '] : ' + fieldValue);

    let sumOfSubtotal = 0;
    _itemList.map((item, index) => {
      sumOfSubtotal += Number(item.subtotal);
    })

    const _invoiceInfo = cloneDeep(filterValidation.values.invoiceInfo);

    // console.log('sumOfSubtotal : ' + sumOfSubtotal);
  
    if (fieldName === 'finalDcAmt') {
      if(sumOfSubtotal === 0) {
        _invoiceInfo.finalDcDesc = '';
        _invoiceInfo.finalDcAmt = 0;
        _invoiceInfo.finalDcRate = 0;
      } else {
        _invoiceInfo.finalDcAmt = fieldValue ? fieldValue : 0;
        _invoiceInfo.finalDcRate = Math.round(Number(_invoiceInfo.finalDcAmt) / sumOfSubtotal * 100);
      }

      _invoiceInfo.addFee = _invoiceInfo.addFee ? _invoiceInfo.addFee : 0;
      _invoiceInfo.total = (sumOfSubtotal - Number(_invoiceInfo.finalDcAmt) + Number(_invoiceInfo.addFee)).toFixed(2);
      _invoiceInfo.balance = (_invoiceInfo.total - Number(_invoiceInfo.amountPaid)).toFixed(2);
    } else if (fieldName === 'finalDcRate') {
      if(sumOfSubtotal === 0) {
        _invoiceInfo.finalDcDesc = '';
        _invoiceInfo.finalDcAmt = 0;
        _invoiceInfo.finalDcRate = 0;
      } else {
        _invoiceInfo.finalDcRate = fieldValue ? fieldValue : 0;
        _invoiceInfo.finalDcAmt = Math.round(sumOfSubtotal * Number(_invoiceInfo.finalDcRate) / 100);
      }

      _invoiceInfo.addFee = _invoiceInfo.addFee ? _invoiceInfo.addFee : 0;
      _invoiceInfo.total = (sumOfSubtotal - Number(_invoiceInfo.finalDcAmt) + Number(_invoiceInfo.addFee)).toFixed(2);
      _invoiceInfo.balance = (_invoiceInfo.total - Number(_invoiceInfo.amountPaid)).toFixed(2);
    } else if (fieldName === 'addFee') {
      _invoiceInfo.addFee = fieldValue ? fieldValue : 0;
      _invoiceInfo.total = (sumOfSubtotal - Number(_invoiceInfo.finalDcAmt) + Number(_invoiceInfo.addFee)).toFixed(2);
    } else if (fieldName === 'total') {
      _invoiceInfo.total = fieldValue ? fieldValue : 0;

      _invoiceInfo.addFee = (_invoiceInfo.total - sumOfSubtotal + Number(_invoiceInfo.finalDcAmt)).toFixed(2);
    } else if (fieldName === 'amountPaid') {
      _invoiceInfo.amountPaid = fieldValue ? fieldValue : 0;

      _invoiceInfo.balance = (_invoiceInfo.total - Number(_invoiceInfo.amountPaid)).toFixed(2);
    } else if (fieldName === 'balance') {
      _invoiceInfo.balance = fieldValue ? fieldValue : 0;

      _invoiceInfo.amountPaid = (_invoiceInfo.total - Number(_invoiceInfo.balance)).toFixed(2);
    }

    // console.log('total : ' + _invoiceInfo.total);

    filterValidation.setFieldValue("itemList", _itemList);
    filterValidation.setFieldValue("invoiceInfo", _invoiceInfo);
  }

  const changeInvoiceDate = (invoiceDate) => {
    filterValidation.setFieldValue("invoiceInfo.invoiceDate", invoiceDate);

    let yyyy = invoiceDate.substring(0,4);
    let mm = invoiceDate.substring(5,7);
    let dd = invoiceDate.substring(8,10);         
    
    // console.log(yyyy + ' ' + mm + ' ' + dd);
    
    let invoiceDeadline = new Date(yyyy, mm-1, dd);
    invoiceDeadline.setDate(invoiceDeadline.getDate() + 7);

    filterValidation.setFieldValue("invoiceInfo.deadline", 
                                  invoiceDeadline.getFullYear() 
                                  + '-' + (invoiceDeadline.getMonth() + 1 < 10 ? '0' + (invoiceDeadline.getMonth() + 1) : invoiceDeadline.getMonth() + 1) 
                                  + '-' + (invoiceDeadline.getDate() < 10 ? '0' + invoiceDeadline.getDate() : invoiceDeadline.getDate())
                                                        );
  }

  const addInvoiceItem = () => {
    let _itemList = cloneDeep(filterValidation.values.itemList);
    _itemList.push({
      gradeStr:'Gold'
      , type:'Member'
      , cresterInChargeNo : cresterList && cresterList.length > 0 ? cresterList[0].userNo : ''
      , inOutType:'IN'
      , crCode:''
      , term:'annually'
      , startDate:''
      , endDate:''
      , cost:''
      , dcDesc:''
      , dcAmt:''
      , standardFee:''
      , addDcDesc:''
      , addDcAmt:''
      , subtotal:''
    })

    filterValidation.setFieldValue("itemList", _itemList);
  } 

  const removeInvoiceItem = (index) => {
    let _itemList = cloneDeep(filterValidation.values.itemList);
    _itemList.splice(index, 1); 
    filterValidation.setFieldValue("itemList", _itemList);
    changeFinalCost('finalDcAmt', filterValidation.values.invoiceInfo.finalDcAmt, _itemList);
  }

  const validateForm= (values, props /* only available when using withFormik */) => {
    const errors = {};
    let regex = /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*\.[a-zA-Z]{2,15}$/i;

    if (Number(values.invoiceInfo.userInvoiceInfoSeq) === -1 && !values.invoiceInfo.invoiceTitle) {
      errors.invoiceTitle = 'Please enter your invoicing info title';
    }

    if (!values.invoiceInfo.invoiceCompany) {
      errors.invoiceCompany = 'Please enter your invoicing company name';
    } 
    
    if (!values.invoiceInfo.invoiceAddress) {
      errors.invoiceAddress = 'Please enter your invoicing address';
    }

    if (!values.invoiceInfo.invoiceEmail) {
      errors.invoiceEmail = 'Please enter your accounting email';
    } else {
      let emailAddressList = values.invoiceInfo.invoiceEmail.split(",");
      for (var i=0; i<emailAddressList.length; i++) { 
        if (!regex.test(emailAddressList[i].trim())) {
          errors.invoiceEmail = 'Please enter a valid accounting email';
        }
      }
    }

    if (values.invoiceInfo.invoiceStatus === 'Paid' && (values.invoiceInfo.dateOfPayment == '' || !values.invoiceInfo.dateOfPayment)) {
      errors.dateOfPayment = 'Please enter a date of payment';
    }

    if (values.invoiceInfo.paymentMethod === 'Paypal') {
      if (values.invoiceInfo.paymentType === 'Onetime') {
        if (!values.invoiceInfo.paypalOnetimeLink) {
          errors.paypalOnetimeLink = 'Please enter your Paypal Onetime link';
        }
      } else {
        if (!values.invoiceInfo.paypalPlanID) {
          errors.paypalPlanID = 'Please enter your Paypal Plan ID';
        }

        if (!values.invoiceInfo.paypalSubscriptionID) {
          errors.paypalSubscriptionID = 'Please enter your Paypal Subscription ID';
        }
      }
    }

    return errors;
  };

  const filterValidation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      submitType: "",
      invoiceInfo: admInvoiceManagementUserInfo,
      itemList: admInvoiceManagementItemList,
      emailForm: "",
    },
    
    onSubmit: (values, {setErrors}) => {
      let submitType = values["submitType"];

      if(submitType === "createAdminInvoice"){
        let sigError = validateForm(values);
        if (JSON.stringify(sigError) !== '{}') {
          // console.log("sigError", sigError);
          setErrors(sigError);

          if (JSON.stringify(sigError).indexOf('invoiceName') > -1) {
            document.getElementById("invoiceName").focus();
          } else if (JSON.stringify(sigError).indexOf('invoiceTitle') > -1) {
            document.getElementById("invoiceTitle").focus();
          } else if (JSON.stringify(sigError).indexOf('invoiceFirstName') > -1) {
            document.getElementById("invoiceFirstName").focus();
          } else if (JSON.stringify(sigError).indexOf('invoiceLastName') > -1) {
            document.getElementById("invoiceLastName").focus();
          } else if (JSON.stringify(sigError).indexOf('invoiceCompany') > -1) {
            document.getElementById("invoiceCompany").focus();
          } else if (JSON.stringify(sigError).indexOf('invoiceAddress') > -1) {
            document.getElementById("invoiceAddress").focus();
          } else if (JSON.stringify(sigError).indexOf('invoiceEmail') > -1) {
            document.getElementById("invoiceEmail").focus();
          } else if (JSON.stringify(sigError).indexOf('paypalPlanID') > -1) {
            document.getElementById("paypalPlanID").focus();
          } else if (JSON.stringify(sigError).indexOf('paypalOnetimeLink') > -1) {
            document.getElementById("paypalOnetimeLink").focus();
          } else if (JSON.stringify(sigError).indexOf('paypalSubscriptionID') > -1) {
            document.getElementById("paypalSubscriptionID").focus();
          }
        } else {
          if (values.invoiceInfo.userInvoiceInfoSeq === -1) {
            let isDuplicateTitle = false;
            userInvoiceInfoList.map((userInvoiceInfo, index) => {
              if (userInvoiceInfo.invoiceTitle === values.invoiceInfo.invoiceTitle) {
                isDuplicateTitle = true;
                return false;
              }
            })

            if (isDuplicateTitle) {
              setErrorMsg(<>The following invoice titles are duplicates<br/>[{values.invoiceInfo.invoiceTitle}]</>);
              document.getElementById("invoiceTitle").focus();
              return false;
            }  
          }

          const formData = new FormData();
          formData.append('emailCode', emailCode);
          formData.append('pricingType', 'NEW');

          Object.keys(values["invoiceInfo"]).forEach(fieldName => {
            if (fieldName === 'invoiceNo'
              || fieldName === 'userNo'
              || fieldName === 'userInvoiceInfoSeq'
              || fieldName === 'userName'
              || (values.invoiceInfo.userInvoiceInfoSeq === -1 && fieldName === 'invoiceTitle')
              || fieldName === 'invoiceFirstName'
              || fieldName === 'invoiceLastName'
              || fieldName === 'invoiceName' 
              || fieldName === 'invoiceCompany' 
              || fieldName === 'invoiceAddress' 
              || fieldName === 'invoiceEmail' 
              || fieldName === 'invoiceTaxId' 
              || fieldName === 'invoiceInfo' 
              || fieldName === 'invoiceDate' 
              || fieldName === 'deadline' 
              || fieldName === 'invoiceMemo' 
              || fieldName === 'currency' 
              || fieldName === 'finalDcDesc' 
              || fieldName === 'finalDcAmt' 
              || fieldName === 'finalDcRate' 
              || fieldName === 'addFeeDesc' 
              || fieldName === 'addFee' 
              || fieldName === 'total' 
              || fieldName === 'amountPaid' 
              || fieldName === 'dateOfPayment' 
              || fieldName === 'balance' 
              || fieldName === 'invoiceStatus' 
              || fieldName === 'paymentMethod' 
              || fieldName === 'paymentType' 
              || fieldName === 'paypalPlanID' 
              || fieldName === 'paypalSubscriptionID' 
              || fieldName === 'paypalOnetimeLink'
              || fieldName === 'sendEmailFlag' 
              || fieldName === 'downloadInvoiceFlag' 
              || fieldName === 'sendInvoiceFlag' 
              || fieldName === 'overwriteBillingProfile'
              ) {
              // console.log(fieldName, values["invoiceInfo"][fieldName]);
              formData.append(fieldName, values["invoiceInfo"][fieldName]);
            }
          })

          let dontHaveItemArray = [];
          // let itemId = "";
          Object.keys(values["itemList"]).forEach(itemIndex => {
            let haveItem = false
            Object.keys(values["itemList"][itemIndex]).forEach(fieldName => {
              if (fieldName === 'crCode' && values["itemList"][itemIndex][fieldName] !== '') haveItem = true;
              // console.log("itemList[" + itemIndex + "]." + fieldName, values["itemList"][itemIndex][fieldName]);
              formData.append("itemList[" + itemIndex + "]." + fieldName, values["itemList"][itemIndex][fieldName]);
            })

            if (!haveItem) {
              dontHaveItemArray.push("Item " + (Number(itemIndex)+1));
              // if (itemId === "") {
              //   itemId = "itemName_" + (Number(itemIndex)+1)
              // }
            }
          })

          if (dontHaveItemArray.length > 0) {
            // console.log("itemId : " + itemId);
            // document.getElementById(itemId).focus();
            setErrorMsg(<>Please select following item:<br/>{JSON.stringify(dontHaveItemArray)}.</>);
            return;
          }

          formData.append('subject', emailSubject);
          if (emailCcItems) {
              formData.append('emailCC', emailCcItems.join(", "));
          }
          if (emailBccItems) {
              formData.append('emailBCC', emailBccItems.join(", "));
          }

          // console.log("emailForm : " + values["emailForm"]);
          formData.append('emailForm', values["emailForm"]);

          attachFiles.forEach((element, index) => {
              for (const [key, value] of Object.entries(element)) {
                  if (value && value !== 'null') {
                      formData.append('attachFiles['+index+'].'+key, value);
                  }
              }              
          });

          copyFiles.forEach((element, index) => {
              for (const [key, value] of Object.entries(element)) {
                  if (value && value !== 'null') {
                      formData.append('copyFiles['+index+'].'+key, value);
                  }
              }    
          });
          
          upFiles.forEach((element, index) => {
              formData.append('upFiles['+index+']', element);
          });

          dispatch(onCreateAdminInvoice(formData));
        }
      }
    },
  });

  useEffect(() => {
    // console.log("AdmCreateInvoice Creation >>>>>>");
    if (props.invoiceNo && props.userNo) {
      // console.log("getInvoiceItem : " + props.invoiceN +  " / " + props.userNo);
      dispatch(onGetInvoiceItem({invoiceNo: props.invoiceNo}));
      dispatch(onGetUserInvoiceInfoList({userNo: props.userNo}));
    }

    dispatch(onGetCresterList());
    dispatch(onGetUserSubscriptionList());

    window.addEventListener('click', handleCloseEmailSettingModal);
    return () => {
      window.removeEventListener('click', handleCloseEmailSettingModal);
    }
  },[])

  useEffect(() => {
    if (userInvoiceInfoList && userInvoiceInfoList.length > 0) {
      if (!props.invoiceNo) {
        changeInvoiceInfo(userInvoiceInfoList[0].seq);
      }
    } else {
      changeInvoiceInfo(-1);
    }
  },[userInvoiceInfoList])

  return (
    <React.Fragment>
      <Row>
        <Col lg={12}>
          <Form 
            onSubmit={(e) => {
              e.preventDefault();
              filterValidation.handleSubmit();
              return false;
            }}
          >
          
          <Row>
            <Card>
              <CardBody>
                {props.invoiceNo ?
                <Row className="mb-3">
                  <label className="col-md-3 col-form-label">
                  Username
                  </label>
                  <div className="col-md-4">
                  <input
                      className="form-control"
                      type="text"
                      placeholder="Username"
                      name="username"
                      value={filterValidation.values.invoiceInfo ? filterValidation.values.invoiceInfo.userName : ''}
                  />
                  </div>
                </Row>
                :
                <SearchUser/>
                }
                
                {
                userInvoiceInfoList && userInvoiceInfoList.length > 0 ?
                <Row className="mb-3">
                  <label className="col-md-3 col-form-label">
                  Billing profile
                  </label>
                  <div className="col-md-4">
                    <select 
                      className="form-select" 
                      name="invoiceInfo.userInvoiceInfoSeq"
                      onChange={(e) => {changeInvoiceInfo(e.target.value)}}
                      value={filterValidation.values.invoiceInfo ? filterValidation.values.invoiceInfo.userInvoiceInfoSeq : 1}
                    >
                    {
                      userInvoiceInfoList.map((userInvoiceInfo, index) => {
                        return (
                          <option value={userInvoiceInfo.seq}>{userInvoiceInfo.invoiceTitle}</option>
                        ) 
                      })
                    }
                    <option value="-1">NEW</option>
                    </select>
                  </div>
                </Row>
                : null
                }
                
                {
                  filterValidation.values.invoiceInfo && filterValidation.values.invoiceInfo.userInvoiceInfoSeq === -1 ?
                  <Row className="mb-3">
                    <label className="col-md-3 col-form-label">
                    Billing profile title *
                    </label>
                    <div className="col-md-4">
                      <Input
                        className="form-control"
                        type="text"
                        placeholder="Billing info title"
                        name="invoiceInfo.invoiceTitle"
                        id="invoiceTitle"
                        onChange={filterValidation.handleChange}
                        value={filterValidation.values.invoiceInfo ? filterValidation.values.invoiceInfo.invoiceTitle : ''}
                        invalid={
                          filterValidation.errors.invoiceTitle ? true : false
                        }
                      />
                      {filterValidation.errors.invoiceTitle ? (
                        <FormFeedback type="invalid">{filterValidation.errors.invoiceTitle}</FormFeedback>
                      ) : null}
                    </div>
                  </Row>
                  : null
                }

                <Row className="mb-3">
                  <label className="col-md-3 col-form-label">
                  Invoicing first name
                  </label>
                  <div className="col-md-4">
                    <Input
                      className="form-control"
                      type="text"
                      placeholder="Invoicing first name"
                      name="invoiceInfo.invoiceFirstName"
                      id="invoiceFirstName"
                      onChange={filterValidation.handleChange}
                      value={filterValidation.values.invoiceInfo ? filterValidation.values.invoiceInfo.invoiceFirstName : ''}
                      invalid={
                        filterValidation.errors.invoiceFirstName ? true : false
                      }
                    />
                    {filterValidation.errors.invoiceFirstName ? (
                      <FormFeedback type="invalid">{filterValidation.errors.invoiceFirstName}</FormFeedback>
                    ) : null}
                  </div>
                </Row>
                <Row className="mb-3">
                  <label className="col-md-3 col-form-label">
                  Invoicing last name
                  </label>
                  <div className="col-md-4">
                    <Input
                      className="form-control"
                      type="text"
                      placeholder="Invoicing last name"
                      name="invoiceInfo.invoiceLastName"
                      id="invoiceLastName"
                      onChange={filterValidation.handleChange}
                      value={filterValidation.values.invoiceInfo ? filterValidation.values.invoiceInfo.invoiceLastName : ''}
                      invalid={
                        filterValidation.errors.invoiceLastName ? true : false
                      }
                    />
                    {filterValidation.errors.invoiceLastName ? (
                      <FormFeedback type="invalid">{filterValidation.errors.invoiceLastName}</FormFeedback>
                    ) : null}
                  </div>
                </Row>
                  
                <Row className="mb-3">
                  <label className="col-md-3 col-form-label">
                    Invoicing company name *
                  </label>
                  <div className="col-md-4">
                    <Input
                      className="form-control"
                      type="text"
                      placeholder="Invoicing company name"
                      name="invoiceInfo.invoiceCompany"
                      id="invoiceCompany"
                      onChange={filterValidation.handleChange}
                      value={filterValidation.values.invoiceInfo ? filterValidation.values.invoiceInfo.invoiceCompany : ''}
                      invalid={
                        filterValidation.errors.invoiceCompany ? true : false
                      }
                    />
                    {filterValidation.errors.invoiceCompany ? (
                      <FormFeedback type="invalid">{filterValidation.errors.invoiceCompany}</FormFeedback>
                    ) : null}
                  </div>
                </Row>
                <Row className="mb-3">
                  <label className="col-md-3 col-form-label">
                    Invoicing address *
                  </label>
                  <div className="col-md-4">
                    <textarea
                      className={filterValidation.errors.invoiceAddress ? "form-control is-invalid" : "form-control"}
                      rows="3"
                      placeholder="Invoicing address..."
                      name="invoiceInfo.invoiceAddress"
                      id="invoiceAddress"
                      onChange={filterValidation.handleChange}
                      value={filterValidation.values.invoiceInfo ? filterValidation.values.invoiceInfo.invoiceAddress : ''}
                    />
                    {filterValidation.errors.invoiceAddress ? (
                      <FormFeedback type="invalid">{filterValidation.errors.invoiceAddress}</FormFeedback>
                    ) : null}
                  </div>
                </Row>
                <Row className="mb-3">
                  <label className="col-md-3 col-form-label">
                    Accounting email *
                  </label>
                  <div className="col-md-4">
                    <Input
                      type="email"
                      placeholder="Accounting email"
                      className="form-control"
                      name="invoiceInfo.invoiceEmail"
                      id="invoiceEmail"
                      onChange={filterValidation.handleChange}
                      value={filterValidation.values.invoiceInfo ? filterValidation.values.invoiceInfo.invoiceEmail : ''}
                      invalid={filterValidation.errors.invoiceEmail ? true : false}
                    />
                    {filterValidation.errors.invoiceEmail ? (
                      <FormFeedback type="invalid">{filterValidation.errors.invoiceEmail}</FormFeedback>
                    ) : null}
                  </div>
                </Row>
                <Row className="mb-3">
                  <label className="col-md-3 col-form-label">
                    Invoicing name
                  </label>
                  <div className="col-md-4">
                    <Input
                      className="form-control"
                      type="text"
                      placeholder="Invoicing name"
                      name="invoiceInfo.invoiceName"
                      id="invoiceName"
                      onChange={filterValidation.handleChange}
                      value={filterValidation.values.invoiceInfo ? filterValidation.values.invoiceInfo.invoiceName : ''}
                      invalid={
                        filterValidation.errors.invoiceName ? true : false
                      }
                    />
                    {filterValidation.errors.invoiceName ? (
                        <FormFeedback type="invalid">{filterValidation.errors.invoiceName}</FormFeedback>
                      ) : null}
                  </div>
                </Row>
                <Row className="mb-3">
                  <label className="col-md-3 col-form-label">
                    Tax ID
                  </label>
                  <div className="col-md-4">
                    <input
                      className="form-control"
                      type="text"
                      placeholder="Tax ID"
                      name="invoiceInfo.invoiceTaxId"
                      onChange={filterValidation.handleChange}
                      value={filterValidation.values.invoiceInfo ? filterValidation.values.invoiceInfo.invoiceTaxId : ''}
                    />
                  </div>
                </Row>
                <Row className="mb-3">
                  <label className="col-md-3 col-form-label">
                    Important info
                  </label>
                  <div className="col-md-4">
                    <textarea
                      className="form-control"
                      rows="3"
                      placeholder="Important info..."
                      name="invoiceInfo.invoiceInfo"
                      onChange={filterValidation.handleChange}
                      value={filterValidation.values.invoiceInfo ? filterValidation.values.invoiceInfo.invoiceInfo : ''}
                    />
                  </div>
                </Row>
                {
                filterValidation.values.invoiceInfo && filterValidation.values.invoiceInfo.userInvoiceInfoSeq !== -1 ?
                <Row className="mb-3">
                  <label className="col-md-3 col-form-label">
                  Overwrite existing billing profile?
                  </label>
                  <Row className="col-md-9">
                    <Row className="vertical-align-center" style={{padding: "0.47rem 1.25rem"}}>
                      <div className="col-md-3 form-check">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="invoiceInfo.overwriteBillingProfile"
                          onChange={filterValidation.handleChange}
                          value="Y"
                          checked={filterValidation.values.invoiceInfo ? filterValidation.values.invoiceInfo.overwriteBillingProfile === 'Y' : false}
                        />
                        <label
                          className="form-check-label"
                        >
                          Yes
                        </label>
                      </div>
                      <div className="col-md-3 form-check">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="invoiceInfo.overwriteBillingProfile"
                          onChange={filterValidation.handleChange}
                          value="N"
                          checked={filterValidation.values.invoiceInfo ? filterValidation.values.invoiceInfo.overwriteBillingProfile === 'N' : false}
                        />
                        <label
                          className="form-check-label"
                        >
                          No
                        </label>
                      </div>
                    </Row>
                  </Row>
                </Row>
                : null
                }
                <Row className="mb-3">
                  <label
                  htmlFor="invoiceDate"
                  className="col-md-3 col-form-label"
                  >
                  Invoice date
                  </label>
                  <div className="col-md-4">
                  <Input
                      className="form-control"
                      type="date"
                      id="invoiceDate"
                      name="invoiceInfo.invoiceDate"
                      onChange={(e) => {
                        changeInvoiceDate(e.target.value)
                      }}
                      value={filterValidation.values.invoiceInfo ? filterValidation.values.invoiceInfo.invoiceDate : ''}
                      onPaste={(e) => {
                          try {
                              let checkDate = String(e.clipboardData.getData('Text'));
                              if (checkDate.indexOf('-') > -1 && checkDate.length === 10) {
                                  filterValidation.setFieldValue("invoiceInfo.invoiceDate", checkDate);
                              } else if (checkDate.length === 8) {
                                  filterValidation.setFieldValue("invoiceInfo.invoiceDate", checkDate.substring(0,4) + '-' + checkDate.substring(4,6) + '-' + checkDate.substring(6,8));
                              }
                          } catch (error) {
                              console.log("error :: " + error);
                          }
                      }}
                      />
                  </div>
                </Row>
                <Row className="mb-3">
                  <label
                  htmlFor="deadline"
                  className="col-md-3 col-form-label"
                  >
                  Invoice deadline
                  </label>
                  <div className="col-md-4">
                  <Input
                      className="form-control"
                      type="date"
                      id="deadline"
                      name="invoiceInfo.deadline"
                      onChange={(e) => {
                          filterValidation.setFieldValue("invoiceInfo.deadline", e.target.value);
                      }}
                      value={filterValidation.values.invoiceInfo ? filterValidation.values.invoiceInfo.deadline : ''}
                      onPaste={(e) => {
                          try {
                              let checkDate = String(e.clipboardData.getData('Text'));
                              if (checkDate.indexOf('-') > -1 && checkDate.length === 10) {
                                  filterValidation.setFieldValue("invoiceInfo.deadline", checkDate);
                              } else if (checkDate.length === 8) {
                                  filterValidation.setFieldValue("invoiceInfo.deadline", checkDate.substring(0,4) + '-' + checkDate.substring(4,6) + '-' + checkDate.substring(6,8));
                              }
                          } catch (error) {
                              console.log("error :: " + error);
                          }
                      }}
                      />
                  </div>
                </Row>
                <Row className="mb-3">
                  <label className="col-md-3 col-form-label">
                  Invoice memo
                  </label>
                  <div className="col-md-4">
                    <textarea
                      className="form-control"
                      rows="3"
                      placeholder=""
                      name="invoiceInfo.invoiceMemo"
                      onChange={filterValidation.handleChange}
                      value={filterValidation.values.invoiceInfo ? filterValidation.values.invoiceInfo.invoiceMemo : ''}
                    />
                  </div>
                </Row>
                <Row>
                  <label className="col-md-3 col-form-label">
                  Currency
                  </label>
                  <div className="col-md-4">
                    <select 
                      className="form-select" 
                      name="invoiceInfo.currency"
                      onChange={filterValidation.handleChange}
                      value={filterValidation.values.invoiceInfo ? filterValidation.values.invoiceInfo.currency : 'USD'}
                    >
                      <option value="USD">USD</option>
                      <option value="KRW">KRW</option>
                    </select>
                  </div>
                </Row>
              </CardBody>
            </Card>
            {
              filterValidation.values.itemList ?
              filterValidation.values.itemList.map((item, index) => {
                return (
                  <Card>
                    <CardBody>
                      <Row className="mb-3">
                        <label className="col-md-3 col-form-label">
                        Item {index+1}
                        </label>
                        {
                        !props.invoiceNo && index > 0 ?
                        <Link className="col-md-4" to="#" onClick={() => {
                          removeInvoiceItem(index)
                          }}
                        >
                          <i className="uil uil-times font-size-22 text-danger"></i>
                        </Link>
                        : null
                        }
                      </Row>
                      <Row className="mb-3">
                        <label className="col-md-3 col-form-label">
                        Crester in charge
                        </label>
                        <div className="col-md-4">
                          <select 
                            className="form-select" 
                            name={"itemList[" + index + "].cresterInChargeNo"}
                            onChange={filterValidation.handleChange}
                            value={item.cresterInChargeNo && item.cresterInChargeNo !== '' ? item.cresterInChargeNo : cresterList && cresterList.length > 0 ? cresterList[0].userNo : 1}
                          >
                            {
                              cresterList && cresterList.length > 0 ?
                              cresterList.map((crester, index) => {
                                return (
                                  <option value={crester.userNo}>{crester.userName}</option>
                                )
                              })
                              : null
                            }
                          </select>
                        </div>
                      </Row>
                      <Row className="mb-3">
                        <label className="col-md-3 col-form-label">
                        Type
                        </label>
                        <Row className="col-md-9">
                          {props.invoiceNo ?
                          item.type
                          :
                          <Row className="vertical-align-center" style={{padding: "0.47rem 1.25rem"}}>
                            <div className="col-md-3 form-check">
                              <input
                                className="form-check-input"
                                type="radio"
                                name={"itemList[" + index + "].type"}
                                id={"item" + index + "_Member"}
                                onChange={(e) => {changeType(index, e.target.value)}}
                                value="Member"
                                checked={item.type === 'Member'}
                              />
                              <label
                                className="form-check-label"
                                onClick={() => {changeType(index, 'Member')}}
                              >
                                Member
                              </label>
                            </div>
                            <div className="col-md-3 form-check">
                              <input
                                className="form-check-input"
                                type="radio"
                                name={"itemList[" + index + "].type"}
                                id={"item" + index + "_Trial"}
                                onChange={(e) => {changeType(index, e.target.value)}}
                                value="Trial"
                                checked={item.type === 'Trial'}
                              />
                              <label
                                className="form-check-label"
                                onClick={() => {changeType(index, 'Trial')}}
                              >
                                Trial
                              </label>
                            </div>
                            <div className="col-md-3 form-check">
                              <input
                                className="form-check-input"
                                type="radio"
                                name={"itemList[" + index + "].type"}
                                id={"item" + index + "_WeCrest"}
                                onChange={(e) => {changeType(index, e.target.value)}}
                                value="WeCrest"
                                checked={item.type === 'WeCrest'}
                              />
                              <label
                                className="form-check-label"
                                onClick={() => {changeType(index, 'WeCrest')}}
                              >
                                WeCrest
                              </label>
                            </div>
                          </Row>
                          }
                        </Row>
                      </Row>
                      {props.invoiceNo ?
                      <Row className="mb-3">
                        <label className="col-md-3 col-form-label">
                        Item Name
                        </label>
                        <div className="col-md-4">
                        {item.crCode}
                        </div>
                      </Row>
                      :
                      <SearchSubscriptionItem index={index} item={item} itemList={filterValidation.values.itemList} changeItemList={changeItemList}/>
                      }
                      <Row className="mb-3">
                        <label className="col-md-3 col-form-label">
                        Term
                        </label>
                        <Row className="col-md-9">
                          {props.invoiceNo ?
                          item.term
                          :
                          <Row className="vertical-align-center" style={{padding: "0.47rem 1.25rem"}}>
                            <div className="col-md-2 form-check">
                              <input
                                className="form-check-input"
                                type="radio"
                                name={"itemList[" + index + "].term"}
                                id={"item" + index + "_annually"}
                                onChange={(e) => {changeTerm(index, e.target.value)}}
                                value="annually"
                                checked={item.term === 'annually'}
                              />
                              <label
                                className="form-check-label"
                                onClick={() => {changeTerm(index, 'annually')}}
                              >
                                annually
                              </label>
                            </div>
                            <div className="col-md-2 form-check">
                              <input
                                className="form-check-input"
                                type="radio"
                                name={"itemList[" + index + "].term"}
                                id={"item" + index + "_6months"}
                                onChange={(e) => {changeTerm(index, e.target.value)}}
                                value="6months"
                                checked={item.term === '6months'}
                              />
                              <label
                                className="form-check-label"
                                onClick={() => {changeTerm(index, '6months')}}
                              >
                                6 months
                              </label>
                            </div>
                            <div className="col-md-2 form-check" style={{paddingLeft : '2em'}}>
                              <input
                                className="form-check-input"
                                type="radio"
                                name={"itemList[" + index + "].term"}
                                id={"item" + index + "_3months"}
                                onChange={(e) => {changeTerm(index, e.target.value)}}
                                value="3months"
                                checked={item.term === '3months'}
                              />
                              <label
                                className="form-check-label"
                                onClick={() => {changeTerm(index, '3months')}}
                              >
                                3 months
                              </label>
                            </div>
                            <div className="col-md-2 form-check" style={{paddingLeft : '2em'}}>
                              <input
                                className="form-check-input"
                                type="radio"
                                name={"itemList[" + index + "].term"}
                                id={"item" + index + "_monthly"}
                                onChange={(e) => {changeTerm(index, e.target.value)}}
                                value="monthly"
                                checked={item.term === 'monthly'}
                              />
                              <label
                                className="form-check-label"
                                onClick={() => {changeTerm(index, 'monthly')}}
                              >
                                monthly
                              </label>
                            </div>
                          </Row>
                          }
                        </Row>
                      </Row>
                      <Row className="mb-3">
                        <label
                        className="col-md-3 col-form-label"
                        >
                        Start date
                        </label>
                        <div className="col-md-3">
                          {props.invoiceNo ?
                          item.startDate
                          :
                          <Input
                            className="form-control"
                            type="date"
                            name={"itemList[" + index + "].startDate"}
                            onChange={(e) => {
                                changeStartDate(index, e.target.value)
                            }}
                            value={item.startDate}
                            onPaste={(e) => {
                                try {
                                    let checkDate = String(e.clipboardData.getData('Text'));
                                    if (checkDate.indexOf('-') > -1 && checkDate.length === 10) {
                                      changeStartDate(index, checkDate)
                                    } else if (checkDate.length === 8) {
                                      changeStartDate(index, checkDate.substring(0,4) + '-' + checkDate.substring(4,6) + '-' + checkDate.substring(6,8))
                                    }
                                } catch (error) {
                                    console.log("error :: " + error);
                                }
                            }}
                            />
                          }
                        </div>
                      </Row>
                      <Row className="mb-3">
                        <label className="col-md-3 col-form-label">
                        End date
                        </label>
                        <div className="col-md-3">
                        {props.invoiceNo ?
                          item.endDate
                          :
                          <Input
                            className="form-control"
                            type="date"
                            name={"itemList[" + index + "].endDate"}
                            onChange={(e) => {
                              filterValidation.setFieldValue("itemList[" + index + "].endDate", e.target.value);
                            }}
                            value={item.endDate}
                            onPaste={(e) => {
                                try {
                                    let checkDate = String(e.clipboardData.getData('Text'));
                                    if (checkDate.indexOf('-') > -1 && checkDate.length === 10) {
                                      filterValidation.setFieldValue("itemList[" + index + "].endDate", checkDate);
                                    } else if (checkDate.length === 8) {
                                      filterValidation.setFieldValue("itemList[" + index + "].endDate", checkDate.substring(0,4) + '-' + checkDate.substring(4,6) + '-' + checkDate.substring(6,8));
                                    }
                                } catch (error) {
                                    console.log("error :: " + error);
                                }
                            }}
                            />
                          }
                        </div>
                      </Row>
                      <Row className="mb-3">
                        <label className="col-md-3 col-form-label">
                        Cost
                        </label>
                        <div className="col-md-4">
                          <input
                            className="form-control"
                            type="number"
                            placeholder="Cost"
                            name={"itemList[" + index + "].cost"}
                            onChange={(e) => {changeCost(index, "cost", e.target.value)}}
                            value={item.cost}
                          />
                        </div>
                      </Row>
                      <Row className="mb-3">
                        <label className="col-md-3 col-form-label">
                        Discount description
                        </label>
                        <div className="col-md-4">
                          <input
                            className="form-control"
                            type="text"
                            placeholder="Tax ID"
                            name={"itemList[" + index + "].dcDesc"}
                            onChange={filterValidation.handleChange}
                            value={item.dcDesc}
                          />
                        </div>
                      </Row>
                      <Row className="mb-3">
                        <label className="col-md-3 col-form-label">
                        Discount
                        </label>
                        <div className="col-md-2">
                          <input
                            className="form-control"
                            type="number"
                            placeholder="Discount"
                            name={"itemList[" + index + "].dcAmt"}
                            onChange={(e) => {changeCost(index, "dcAmt", e.target.value)}}
                            value={item.dcAmt}
                          />
                        </div>
                        <div className="col-md-2" style={{display: "flex"}}>
                          <input
                            className="form-control"
                            type="number"
                            placeholder="Discount Rate"
                            name={"itemList[" + index + "].dcRate"}
                            onChange={(e) => {changeCost(index, "dcRate", e.target.value)}}
                            value={item.dcRate}
                          />
                          <label className="col-form-label">
                          &nbsp;%
                          </label>
                        </div>
                      </Row>
                      <Row className="mb-3">
                        <label className="col-md-3 col-form-label">
                        Standard fee
                        </label>
                        <div className="col-md-4">
                          <input
                            className="form-control"
                            type="number"
                            placeholder="Standard fee"
                            name={"itemList[" + index + "].standardFee"}
                            onChange={(e) => {changeCost(index, "standardFee", e.target.value)}}
                            value={item.standardFee}
                          />
                        </div>
                      </Row>
                      <Row className="mb-3">
                        <label className="col-md-3 col-form-label">
                        Additional discount description
                        </label>
                        <div className="col-md-4">
                          <input
                            className="form-control"
                            type="text"
                            placeholder="Additional discount description"
                            name={"itemList[" + index + "].addDcDesc"}
                            onChange={filterValidation.handleChange}
                            value={item.addDcDesc}
                          />
                        </div>
                      </Row>
                      <Row className="mb-3">
                        <label className="col-md-3 col-form-label">
                        Additional discount
                        </label>
                        <div className="col-md-2">
                          <input
                            className="form-control"
                            type="number"
                            placeholder="Additional discount"
                            name={"itemList[" + index + "].addDcAmt"}
                            onChange={(e) => {changeCost(index, "addDcAmt", e.target.value)}}
                            value={item.addDcAmt}
                          />
                        </div>
                        <div className="col-md-2" style={{display: "flex"}}>
                          <input
                            className="form-control"
                            type="number"
                            placeholder="Discount Rate"
                            name={"itemList[" + index + "].addDcRate"}
                            onChange={(e) => {changeCost(index, "addDcRate", e.target.value)}}
                            value={item.addDcRate}
                          />
                          <label className="col-form-label">
                          &nbsp;%
                          </label>
                        </div>
                      </Row>
                      <Row className={props.invoiceNo && (index === filterValidation.values.itemList.length-1) ? "mb-3" : ""}>
                        <label className="col-md-3 col-form-label">
                        Subtotal
                        </label>
                        <div className="col-md-4">
                          <input
                            className="form-control"
                            type="number"
                            placeholder="Subtotal"
                            name={"itemList[" + index + "].subtotal"}
                            onChange={(e) => {changeCost(index, "subtotal", e.target.value)}}
                            value={item.subtotal}
                          />
                        </div>
                      </Row>
                      {
                      !props.invoiceNo && (index === filterValidation.values.itemList.length-1) ?
                      <div className="d-flex flex-wrap gap-3 mt-3">
                        <Button
                          type="button"
                          color="primary"
                          className="w-md"
                          onClick={()=>{
                            addInvoiceItem()
                          }}
                        >
                          Add item
                        </Button>
                      </div>
                      : null
                      }
                    </CardBody>
                  </Card>
                )
              })
              : null
            }
            {
              filterValidation.values.itemList && filterValidation.values.itemList.length > 0 ?
              <>
              <Card>
                <CardBody>
                  <Row className="mb-3">
                    <label className="col-md-3 col-form-label">
                    Final discount description
                    </label>
                    <div className="col-md-4">
                      <input
                        className="form-control"
                        type="text"
                        placeholder="Final discount description"
                        name="invoiceInfo.finalDcDesc"
                        onChange={filterValidation.handleChange}
                        value={filterValidation.values.invoiceInfo ? filterValidation.values.invoiceInfo.finalDcDesc : ''}
                      />
                    </div>
                  </Row>
                  <Row className="mb-3">
                    <label className="col-md-3 col-form-label">
                    Final discount
                    </label>
                    <div className="col-md-2">
                      <input
                        className="form-control"
                        type="number"
                        placeholder="Final discount"
                        name="invoiceInfo.finalDcAmt"
                        onChange={(e) => {changeFinalCost("finalDcAmt", e.target.value, cloneDeep(filterValidation.values.itemList))}}
                        value={filterValidation.values.invoiceInfo ? filterValidation.values.invoiceInfo.finalDcAmt : '0'}
                      />
                    </div>
                    <div className="col-md-2" style={{display: "flex"}}>
                      <input
                        className="form-control"
                        type="number"
                        placeholder="Discount Rate"
                        name="invoiceInfo.finalDcRate"
                        onChange={(e) => {changeFinalCost("finalDcRate", e.target.value, cloneDeep(filterValidation.values.itemList))}}
                        value={filterValidation.values.invoiceInfo ? filterValidation.values.invoiceInfo.finalDcRate : ''}
                      />
                      <label className="col-form-label">
                      &nbsp;%
                      </label>
                    </div>
                  </Row>
                  <Row className="mb-3">
                    <label className="col-md-3 col-form-label">
                    Additional fee description
                    </label>
                    <div className="col-md-4">
                      <input
                        className="form-control"
                        type="text"
                        placeholder="Additional fee description"
                        name="invoiceInfo.addFeeDesc"
                        onChange={filterValidation.handleChange}
                        value={filterValidation.values.invoiceInfo ? filterValidation.values.invoiceInfo.addFeeDesc : ''}
                      />
                    </div>
                  </Row>
                  <Row className="mb-3">
                    <label className="col-md-3 col-form-label">
                    Additional fee
                    </label>
                    <div className="col-md-4">
                      
                      <input
                        className="form-control"
                        type="number"
                        placeholder="Additional fee"
                        name="invoiceInfo.addFee"
                        onChange={(e) => {changeFinalCost("addFee", e.target.value, cloneDeep(filterValidation.values.itemList))}}
                        value={filterValidation.values.invoiceInfo ? filterValidation.values.invoiceInfo.addFee : '0'}
                      />
                    </div>
                  </Row>
                  <Row>
                    <label className="col-md-3 col-form-label">
                    Total
                    </label>
                    <div className="col-md-4">
                      <input
                        className="form-control"
                        type="number"
                        placeholder="Total"
                        name="invoiceInfo.total"
                        onChange={(e) => {changeFinalCost("total", e.target.value, cloneDeep(filterValidation.values.itemList))}}
                        value={filterValidation.values.invoiceInfo ? filterValidation.values.invoiceInfo.total : '0'}
                      />
                    </div>
                  </Row>
                </CardBody>
              </Card>
              <Card>
                <CardBody>
                  <Row className="mb-3">
                    <label className="col-md-3 col-form-label">
                    Amount paid
                    </label>
                    <div className="col-md-4">
                      <input
                        className="form-control"
                        type="number"
                        placeholder="Amount paid"
                        name="invoiceInfo.amountPaid"
                        onChange={(e) => {changeFinalCost("amountPaid", e.target.value, cloneDeep(filterValidation.values.itemList))}}
                        value={filterValidation.values.invoiceInfo ? filterValidation.values.invoiceInfo.amountPaid : '0'}
                      />
                    </div>
                  </Row>
                  <Row className="mb-3">
                    <label
                    htmlFor="deadline"
                    className="col-md-3 col-form-label"
                    >
                    Date of payment {filterValidation.values.invoiceInfo && filterValidation.values.invoiceInfo.invoiceStatus === 'Paid' ? '*' : ''}
                    </label>
                    <div className="col-md-4">
                    <Input
                        className="form-control"
                        type="date"
                        id="deadline"
                        name="invoiceInfo.dateOfPayment"
                        onChange={(e) => {
                            filterValidation.setFieldValue("invoiceInfo.dateOfPayment", e.target.value);
                        }}
                        value={filterValidation.values.invoiceInfo ? filterValidation.values.invoiceInfo.dateOfPayment : ''}
                        onPaste={(e) => {
                            try {
                                let checkDate = String(e.clipboardData.getData('Text'));
                                if (checkDate.indexOf('-') > -1 && checkDate.length === 10) {
                                    filterValidation.setFieldValue("invoiceInfo.dateOfPayment", checkDate);
                                } else if (checkDate.length === 8) {
                                    filterValidation.setFieldValue("invoiceInfo.dateOfPayment", checkDate.substring(0,4) + '-' + checkDate.substring(4,6) + '-' + checkDate.substring(6,8));
                                }
                            } catch (error) {
                                console.log("error :: " + error);
                            }
                        }}
                        invalid={filterValidation.errors.dateOfPayment ? true : false}
                        />
                        {filterValidation.errors.dateOfPayment ? (
                        <FormFeedback type="invalid">{filterValidation.errors.dateOfPayment}</FormFeedback>
                        ) : null}
                    </div>
                  </Row>
                  <Row className="mb-3">
                    <label className="col-md-3 col-form-label">
                    Balance
                    </label>
                    <div className="col-md-4">
                      <input
                        className="form-control"
                        type="number"
                        placeholder="Balance"
                        name="invoiceInfo.balance"
                        onChange={(e) => {changeFinalCost("balance", e.target.value, cloneDeep(filterValidation.values.itemList))}}
                        value={filterValidation.values.invoiceInfo ? filterValidation.values.invoiceInfo.balance : '0'}
                      />
                    </div>
                  </Row>
                  <Row className="mb-3">
                    <label className="col-md-3 col-form-label">
                    Invoice status
                    </label>
                    <div className="col-md-4">
                      <select 
                        className="form-select" 
                        name="invoiceInfo.invoiceStatus"
                        onChange={filterValidation.handleChange}
                        value={filterValidation.values.invoiceInfo ? filterValidation.values.invoiceInfo.invoiceStatus : ''}
                      >
                        <option value="Paid">Paid</option>
                        <option value="PartiallyPaid">PartiallyPaid</option>
                        <option value="Outstanding">Outstanding</option>
                        <option value="Pending">Pending</option>
                        <option value="Overdue">Overdue</option>
                        <option value="NotYetIssued">NotYetIssued</option>
                        <option value="Tentative">Tentative</option>
                        <option value="Cancelled">Cancelled</option>
                        <option value="Refunded">Refunded</option>
                      </select>
                    </div>
                  </Row>
                  <Row className="mb-3">
                    <label className="col-md-3 col-form-label">
                    Payment method
                    </label>
                    <div className="col-md-4">
                      <select 
                        className="form-select" 
                        name="invoiceInfo.paymentMethod"
                        onChange={filterValidation.handleChange}
                        value={filterValidation.values.invoiceInfo ? filterValidation.values.invoiceInfo.paymentMethod : ''}
                      >
                        <option value="Paypal">Paypal</option>
                        <option value="BankTransfer">BankTransfer</option>
                      </select>
                    </div>
                  </Row>
                  <Row className="mb-3">
                    <label className="col-md-3 col-form-label">
                    Payment type
                    </label>
                    <div className="col-md-4">
                      <select 
                        className="form-select" 
                        name="invoiceInfo.paymentType"
                        onChange={filterValidation.handleChange}
                        value={filterValidation.values.invoiceInfo ? filterValidation.values.invoiceInfo.paymentType : ''}
                      >
                        <option value="Onetime">Onetime</option>
                        <option value="Subscription">Subscription</option>
                      </select>
                    </div>
                  </Row>
                  {
                  filterValidation.values.invoiceInfo && filterValidation.values.invoiceInfo.paymentMethod === 'Paypal'?
                  filterValidation.values.invoiceInfo.paymentType === 'Onetime' ?
                    <Row className="mb-3">
                      <label className="col-md-3 col-form-label">
                      Paypal Onetime link *
                      </label>
                      <div className="col-md-4">
                        <Input
                          className="form-control"
                          type="text"
                          placeholder="Paypal Onetime link"
                          name="invoiceInfo.paypalOnetimeLink"
                          id="paypalOnetimeLink"
                          onChange={filterValidation.handleChange}
                          value={filterValidation.values.invoiceInfo ? filterValidation.values.invoiceInfo.paypalOnetimeLink : ''}
                          invalid={filterValidation.errors.paypalOnetimeLink ? true : false}
                        />
                        {filterValidation.errors.paypalOnetimeLink ? (
                        <FormFeedback type="invalid">{filterValidation.errors.paypalOnetimeLink}</FormFeedback>
                      ) : null}
                      </div>
                    </Row>
                  :
                  <>
                    <Row className="mb-3">
                      <label className="col-md-3 col-form-label">
                      Paypal Plan ID *
                      </label>
                      <div className="col-md-4">
                        <Input
                          className="form-control"
                          type="text"
                          placeholder="Paypal Plan ID"
                          name="invoiceInfo.paypalPlanID"
                          id="paypalPlanID"
                          onChange={filterValidation.handleChange}
                          value={filterValidation.values.invoiceInfo ? filterValidation.values.invoiceInfo.paypalPlanID : ''}
                          invalid={filterValidation.errors.paypalPlanID ? true : false}
                        />
                        {filterValidation.errors.paypalPlanID ? (
                        <FormFeedback type="invalid">{filterValidation.errors.paypalPlanID}</FormFeedback>
                      ) : null}
                      </div>
                    </Row>
                    <Row>
                      <label className="col-md-3 col-form-label">
                      Paypal Subscription ID *
                      </label>
                      <div className="col-md-4">
                        <Input
                          className="form-control"
                          type="text"
                          placeholder="Paypal Subscription ID"
                          name="invoiceInfo.paypalSubscriptionID"
                          id="paypalSubscriptionID"
                          onChange={filterValidation.handleChange}
                          value={filterValidation.values.invoiceInfo ? filterValidation.values.invoiceInfo.paypalSubscriptionID : ''}
                          invalid={filterValidation.errors.paypalSubscriptionID ? true : false}
                        />
                        {filterValidation.errors.paypalSubscriptionID ? (
                        <FormFeedback type="invalid">{filterValidation.errors.paypalSubscriptionID}</FormFeedback>
                      ) : null}
                      </div>
                    </Row> 
                  </>
                  : null
                  }
                </CardBody>
              </Card>
              <Card>
                <CardBody>
                  <Row>
                    <div className="col-md-2">
                      <Button
                        type="button"
                        color="primary"
                        className="w-md"
                        onClick={() => {
                          setCreateFlag(false);
                          openEditor()
                        }}
                      >
                        Edit email
                      </Button>
                    </div>
                    <div className="col-md-2 display-flex vertical-align-center">
                      <div className="form-check">
                        <input type="checkbox" className="form-check-input"
                          id="sendEmailFlag"
                          name="invoiceInfo.sendEmailFlag"
                          onChange={(e) => {
                            filterValidation.setFieldValue("invoiceInfo.sendEmailFlag", e.target.checked ? "Y" : "N");
                            if (!e.target.checked) {
                              filterValidation.setFieldValue("invoiceInfo.sendInvoiceFlag", "N");
                            }
                          }}
                          checked={filterValidation.values.invoiceInfo.sendEmailFlag === 'Y'}
                        />
                        <Label className="form-check-label">
                        Send email
                        </Label>
                      </div>
                    </div>
                    <div className="col-md-2 display-flex vertical-align-center">
                      <div className="form-check">
                        <input type="checkbox" className="form-check-input"
                          id="downloadInvoiceFlag"
                          name="invoiceInfo.downloadInvoiceFlag"
                          onChange={(e) => {filterValidation.setFieldValue("invoiceInfo.downloadInvoiceFlag", e.target.checked ? "Y" : "N");}}
                          checked={filterValidation.values.invoiceInfo.downloadInvoiceFlag === 'Y'}
                        />
                        <Label className="form-check-label">
                        Download invoice
                        </Label>
                      </div>
                    </div>
                    <div className="col-md-2 display-flex vertical-align-center">
                      <div className="form-check">
                        <input type="checkbox" className="form-check-input"
                          id="sendInvoiceFlag"
                          name="invoiceInfo.sendInvoiceFlag"
                          onChange={(e) => {filterValidation.setFieldValue("invoiceInfo.sendInvoiceFlag", e.target.checked ? "Y" : "N");}}
                          checked={filterValidation.values.invoiceInfo.sendEmailFlag === 'Y' && filterValidation.values.invoiceInfo.sendInvoiceFlag === 'Y'}
                          disabled={filterValidation.values.invoiceInfo.sendEmailFlag === 'N' && filterValidation.values.invoiceInfo.sendInvoiceFlag === 'N'}
                          readOnly={filterValidation.values.invoiceInfo.sendEmailFlag === 'N' && filterValidation.values.invoiceInfo.sendInvoiceFlag === 'N'}
                        />
                        <Label className="form-check-label">
                        Send invoice
                        </Label>
                      </div>
                    </div>
                  </Row>
                </CardBody>
              </Card>
              <Card>
                <CardBody>
                  <div className="d-flex flex-wrap gap-3">
                    <Button
                      type="button"
                      color="primary"
                      className="w-md"
                      onClick={()=>{
                        previewInvoice();
                      }}
                    >
                      Preview invoice
                    </Button>
                    <Button
                      type="button"
                      color="primary"
                      className="w-md"
                      onClick={()=>{
                        if (filterValidation.values.invoiceInfo.sendEmailFlag === 'Y') {
                          setCreateFlag(true);
                          openEditor();
                        } else {
                          filterValidation.setFieldValue("emailSubject", "");
                          filterValidation.setFieldValue("emailCcItems", []);
                          filterValidation.setFieldValue("emailBccItems", []);
                          filterValidation.setFieldValue("emailForm", "");
                          filterValidation.setFieldValue("attachFiles", []);
                          filterValidation.setFieldValue("copyFiles", []);
                          filterValidation.setFieldValue("upFiles", []);
                          filterValidation.setFieldValue("submitType", "createAdminInvoice");
                          filterValidation.handleSubmit(filterValidation.values);
                        }
                      }}
                    >
                      Save
                    </Button>
                    <Button
                      type="button"
                      color="danger"
                      outline
                      className="w-md"
                      onClick={()=>{
                        props.toggleActiveTab("Search")
                      }}
                    >
                      Cancel
                    </Button>
                  </div>
                </CardBody>
              </Card>
            </>
            :null
            }
          </Row>

          </Form>

          <EditorModal isOpenEditor={isOpenEditor} isInitEditor={isInitEditor} setIsInitEditor={setIsInitEditor} closeEditor={closeEditor} loadedEditor={loadedEditor} setLoadedEditor={setLoadedEditor} 
            editorModal={editorModal} ref={editorModalRef} emailType={emailType} emailCode={emailCode} createAdminInvoice={createAdminInvoice} createFlag={createFlag}/>

          <PreviewInvoice isOpenPreview={isOpenPreview} setIsOpenPreview={setIsOpenPreview} invoiceInfo={filterValidation.values.invoiceInfo} itemList={filterValidation.values.itemList}/>

          {
            errorMsg !== '' ?
            <SweetAlert
              warning
              title="Warning!"
              confirmBtnBsStyle="success"
              onConfirm={() => {
                setErrorMsg('');
              }}
            >
              {errorMsg}
            </SweetAlert>
            : null
          }
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default AdmCreateInvoice;