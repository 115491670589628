import {
  GET_DASHBOARD_CARD_INFO,
  GET_DASHBOARD_CARD_INFO_SUCCESS,
  GET_WECREST_INFO_SUCCESS,
  SET_DASHBOARD_CARD_LOADING,
} from "./actionTypes"

const initialState = {
  error: "",
  loading: true,
  monthData: [],
  wecrestMax: {},
  emailCnt: {},
  monthlyData: [],
  subsDetails: [],
  rankingList: [],
  userGrade: "00",
  wecrestInfo: {},
}

const dashboard = (state = initialState, action) => {
  switch (action.type) {
    case GET_DASHBOARD_CARD_INFO:
      return {
        ...state,
        loading: true,
      }
    case GET_DASHBOARD_CARD_INFO_SUCCESS:
      // console.log('GET_DASHBOARD_CARD_INFO_SUCCESS >> ');
      return {
        ...state,
        loading: false,
        monthData: action.payload.monthData,
        wecrestMax: action.payload.wecrestMax,
        emailCnt: action.payload.emailCnt,
        monthlyData: action.payload.monthlyData,
        subsDetails: action.payload.subsDetails,
        rankingList: action.payload.rankingList,
        userGrade: action.payload.userGrade,
      }
    case GET_WECREST_INFO_SUCCESS:
      return {
        ...state,
        wecrestInfo: action.payload.wecrestInfo,
      }
    case SET_DASHBOARD_CARD_LOADING:
      // console.log('SET_DASHBOARD_CARD_LOADING >> ' + action.payload);
      return {
        ...state,
        loading: action.payload,
      }
    default:
      return {
        ...state,
      }
  }
}

export default dashboard
