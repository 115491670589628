import {
  GET_CONTACTS_LIST,
  GET_CONTACTS_LIST_SUCCESS,
  SET_UPLOAD_CONTACTS_OPEN,
  UPLOAD_CONTACTS,
  UPLOAD_CONTACTS_SUCCESS,
  SET_IS_UPLOAD_FALSE,
  CHANGE_CONTACTS_LIST,
  MULTIPLE_STAR_CONTACTS,
  MULTIPLE_UNSTAR_CONTACTS,
  MULTIPLE_HIDE_CONTACTS,
  MULTIPLE_UNHIDE_CONTACTS,
  MULTIPLE_UNSUBSCRIBE_CONTACTS,
  CHECK_EMAIL_UNSUBSCRIBE,
  CHECK_EMAIL_UNSUBSCRIBE_SUCCESS,
  SAVE_CONTACTS,
  SAVE_CONTACTS_SIMPLE,
  SAVE_CONTACTS_SIMPLE_SUCCESS,
  DELETE_CONTACTS,
  GET_IMPORTED_CONTACTS,
  GET_IMPORTED_CONTACTS_SUCCESS,
  GET_SUGGESTED_CONTACTS,
  GET_SUGGESTED_CONTACTS_SUCCESS,
  GET_CONTACTS_DETAILS,
  GET_CONTACTS_DETAILS_SUCCESS,
  GET_CONTACTS_DETAIL_LIST,
  GET_CONTACTS_DETAIL_LIST_SUCCESS,
  GET_CONTACTS_MEMO,
  GET_CONTACTS_MEMO_SUCCESS,
  CHANGE_CONTACTS_DETAILS,
  SAVE_MEMO,
  SAVE_MEMO_SUCCESS,
  CHANGE_IMPORTED_CONTACTS,
  CHANGE_SUGGESTED_CONTACTS,
  CHANGE_MEMBER_CONFIRMED_CONTACTS,
  GET_CONTACTS_SETTINGS,
  GET_CONTACTS_SETTINGS_SUCCESS,
  DOWN_CONTACTS_EXCEL,
  DOWN_CONTACTS_EXCEL_SUCCESS,
  SET_SEARCH_SETTINGS,
  SET_CONTACS_RESULT,
  SET_CONTACS_DETAIL_RESULT,
  SET_CONTACS_LOADING,

  GET_UPLOADED_CONTACTS_LIST,
  GET_UPLOADED_CONTACTS_LIST_SUCCESS,
  SAVE_UPLOADED_CONTACTS,
  DELETE_UPLOADED_CONTACTS,
  DOWN_UPLOADED_CONTACTS_EXCEL,
  DOWN_UPLOADED_CONTACTS_EXCEL_SUCCESS,
  CHANGE_UPLOADED_CONTACTS_LIST,

  GET_DOMAIN_CORRECT_YN_COUNT,
  GET_DOMAIN_CORRECT_YN_COUNT_SUCCESS,
  SAVE_DOMAIN_CORRECT_YN,
  SAVE_DOMAIN_CORRECT_YN_SUCCESS,
  CHANGE_USER_SUGGEST_DOMAIN,
  CHANGE_SHOW_SUCCESS_ADD_TOAST_FLAG,
  CHANGE_CHECK_UNSUBS_EMAIL_ADDRESS_FLAG,

  GET_CONTACT_SEARCH_LIST,
  GET_CONTACT_SEARCH_LIST_SUCCESS,
  CHANGE_CONTACT_SEARCH_LIST,
  DOWN_SEARCH_ENTITY_EXCEL,
  DOWN_SEARCH_ENTITY_EXCEL_SUCCESS,
} from "./actionTypes"

const initialState = {
  error: "",
  loading: false,
  noticeStr: "",
  contactsList: [],
  uploadContactsOpen: false,
  successCnt: 0,
  failCnt: 0,
  isUpload: false,
  isSearched: false,
  importedContacts: [],
  importedTotalPage: 1,
  suggestedContacts: [],
  suggestedTotalPage: 1,
  suggestedContactsExist: false,
  contactsDetails: {},
  memo: {},
  searchSettings: {},
  result: -1,
  totalPage: 1,
  uploadedContactsList: [],
  totalPageUp: 1,
  domainCorrectYnCount: {},
  userSuggestDomain: {},
  userGrade: "00",
  showSuccessAddToastFlag: false,
  unsubsEmailAddress: "",
  checkUnsubsEmailAddressFlag: false,
  toastMessage: "",
  saveType: "",
  screenName: "",
  searchList: [],
  totalPageSearch: 1,
  detailResult: -1,
  memberConfirmedContacts: [],
}

const contacts = (state = initialState, action) => {
  switch (action.type) {
    case GET_CONTACTS_LIST:
      return {
        ...state,
        loading: true
      }
    case GET_CONTACTS_LIST_SUCCESS:
      return {
        ...state,
        noticeStr: action.payload.noticeStr,
        contactsList: action.payload.contactsList,
        totalPage: action.payload.totalPage,
        isSearched: true,
        loading: false
      }
    case SET_UPLOAD_CONTACTS_OPEN:
      return {
        ...state,
        uploadContactsOpen: action.payload,
      }
    case UPLOAD_CONTACTS:
      return {
        ...state,
        loading: true,
      }
    case UPLOAD_CONTACTS_SUCCESS:
      return {
        ...state,
        successCnt: action.payload.successCnt,
        failCnt: action.payload.failCnt,
        isUpload: true,
        loading: false,
      }
    case SET_IS_UPLOAD_FALSE:
      return {
        ...state,
        successCnt: 0,
        failCnt: 0,
        isUpload: false,
      }
    case CHANGE_CONTACTS_LIST:
      return {
        ...state,
        contactsList: action.payload,
      }
    case MULTIPLE_STAR_CONTACTS:
      return {
        ...state,
        loading: true,
      }
    case MULTIPLE_UNSTAR_CONTACTS:
      return {
        ...state,
        loading: true,
      }
    case MULTIPLE_HIDE_CONTACTS:
      return {
        ...state,
        loading: true,
      }
    case MULTIPLE_UNHIDE_CONTACTS:
      return {
        ...state,
        loading: true,
      }
    case MULTIPLE_UNSUBSCRIBE_CONTACTS:
      return {
        ...state,
        loading: true,
      }
    case CHECK_EMAIL_UNSUBSCRIBE:
      return {
        ...state,
        loading: true,
        saveType: action.payload.saveType,
        screenName: action.payload.screenName,
      }
    case CHECK_EMAIL_UNSUBSCRIBE_SUCCESS:
      if (action.payload.screenName == 'List') {
        return {
          ...state,
          loading: false,
          unsubsEmailAddress: action.payload.data.result == '0' ? "" : action.payload.data.result,
          result: action.payload.data.result == '0' ? -1 : 10,
          checkUnsubsEmailAddressFlag: action.payload.data.result == '0' ? true : false,
        }
      } else {
        return {
          ...state,
          loading: false,
          unsubsEmailAddress: action.payload.data.result == '0' ? "": action.payload.data.result,
          detailResult: action.payload.data.result == '0' ? -1 : 10,
          checkUnsubsEmailAddressFlag: action.payload.data.result == '0' ? true: false,
        }
      }
    case SAVE_CONTACTS:
      return {
        ...state,
        loading: true,
      }
    case SAVE_CONTACTS_SIMPLE:
      return {
        ...state,
        loading: true,
      }
    case SAVE_CONTACTS_SIMPLE_SUCCESS:
      return {
        ...state,
        loading: false,
        contactsDetails: action.payload.contactsDetails,
        showSuccessAddToastFlag: true,
        toastMessage: "Add contacts!"
      }
    case DELETE_CONTACTS:
      return {
        ...state,
        loading: true,
      }
    case GET_IMPORTED_CONTACTS:
      return {
        ...state,
        loading: true,
      }
    case GET_IMPORTED_CONTACTS_SUCCESS:
      return {
        ...state,
        importedContacts: action.payload.uploadedContactsList,
        importedTotalPage: action.payload.totalPageUp,
        loading: false,
      }
    case GET_SUGGESTED_CONTACTS:
      return {
        ...state,
        loading: true,
      }
    case GET_SUGGESTED_CONTACTS_SUCCESS:
      return {
        ...state,
        suggestedContacts: action.payload.suggestedContacts,
        suggestedTotalPage: action.payload.suggestedTotalPage,
        loading: false,
      }
    case GET_CONTACTS_DETAILS:
      return {
        ...state,
        contactsDetails: {},
        memo: {},
        suggestedContacts: [],
        suggestedTotalPage: 1,
        importedContacts: [],
        importedTotalPage: 1,
        domainCorrectYnCount: {},
        userSuggestDomain: {},
        loading: true,
      }
    case GET_CONTACTS_DETAILS_SUCCESS:
      return {
        ...state,
        contactsDetails: action.payload.contactsDetails,
        memo: action.payload.memo,
        suggestedContacts: action.payload.suggestedContacts,
        suggestedTotalPage: action.payload.suggestedTotalPage,
        suggestedContactsExist: action.payload.suggestedContacts && action.payload.suggestedContacts.length > 0 ? true : false,
        importedContacts: action.payload.importedContacts,
        importedTotalPage: action.payload.totalPageUp,
        domainCorrectYnCount: action.payload.domainCorrectYnCount,
        userSuggestDomain: action.payload.userSuggestDomain,
        memberConfirmedContacts: action.payload.memberConfirmedContacts,
        loading: false,
      }
    case GET_CONTACTS_DETAIL_LIST:
      return {
        ...state,
        loading: true,
      }
    case GET_CONTACTS_DETAIL_LIST_SUCCESS:
      return {
        ...state,
        contactsDetails: action.payload.contactsDetails,
        loading: false,
      }
    case GET_CONTACTS_MEMO:
      return {
        ...state,
        loading: true,
      }
    case GET_CONTACTS_MEMO_SUCCESS:
      return {
        ...state,
        memo: action.payload.memo,
        loading: false,
      }
    case CHANGE_CONTACTS_DETAILS:
      return {
        ...state,
        contactsDetails: action.payload,
      }
    case SAVE_MEMO:
      return {
        ...state,
      }
    case SAVE_MEMO_SUCCESS:
      return {
        ...state,
        showSuccessAddToastFlag: true,
        toastMessage: "Saved memo!"
      }
    case CHANGE_IMPORTED_CONTACTS:
      return {
        ...state,
        importedContacts: action.payload,
      }
    case CHANGE_SUGGESTED_CONTACTS:
      return {
        ...state,
        suggestedContacts: action.payload,
      }
    case CHANGE_MEMBER_CONFIRMED_CONTACTS:
      return {
        ...state,
        memberConfirmedContacts: action.payload,
      }
    case GET_CONTACTS_SETTINGS:
      return {
        ...state,
        loading: true,
      }
    case GET_CONTACTS_SETTINGS_SUCCESS:
      return {
        ...state,
        loading: false,
        searchSettings: action.payload.searchSettings,
        userGrade: action.payload.userGrade
      }
    case DOWN_CONTACTS_EXCEL:
      return {
        ...state,
        loading: true,
      }
    case DOWN_CONTACTS_EXCEL_SUCCESS:
      return {
        ...state,
        loading: false,
      }
    case SET_SEARCH_SETTINGS:
      return {
        ...state,
        searchSettings:action.payload
      }
    case SET_CONTACS_RESULT:
      return {
        ...state,
        result:action.payload
      }
    case SET_CONTACS_DETAIL_RESULT:
      return {
        ...state,
        detailResult:action.payload
      }
    case SET_CONTACS_LOADING:
      return {
        ...state,
        loading: action.payload,
      }

    case GET_UPLOADED_CONTACTS_LIST:
      return {
        ...state,
        loading: true
      }
    case GET_UPLOADED_CONTACTS_LIST_SUCCESS:
      return {
        ...state,
        uploadedContactsList: action.payload.uploadedContactsList,
        totalPageUp: action.payload.totalPageUp,
        isSearched: true,
        loading: false
      }
    case SAVE_UPLOADED_CONTACTS:
      return {
        ...state,
        loading: true,
      }
    case DELETE_UPLOADED_CONTACTS:
      return {
        ...state,
        loading: true,
      }
    case CHANGE_UPLOADED_CONTACTS_LIST:
      return {
        ...state,
        uploadedContactsList: action.payload,
      }
    case DOWN_UPLOADED_CONTACTS_EXCEL:
      return {
        ...state,
        loading: true,
      }
    case DOWN_UPLOADED_CONTACTS_EXCEL_SUCCESS:
      return {
        ...state,
        loading: false,
      }
    case GET_DOMAIN_CORRECT_YN_COUNT:
      return {
        ...state,
        loading: true,
      }
    case GET_DOMAIN_CORRECT_YN_COUNT_SUCCESS:
      return {
        ...state,
        domainCorrectYnCount: action.payload.domainCorrectYnCount,
        userSuggestDomain: action.payload.userSuggestDomain,
        loading: false
      }
    case SAVE_DOMAIN_CORRECT_YN:
      return {
        ...state,
        loading: true,
      }
    case SAVE_DOMAIN_CORRECT_YN_SUCCESS:
      return {
        ...state,
        domainCorrectYnCount: action.payload.domainCorrectYnCount,
        userSuggestDomain: action.payload.userSuggestDomain,
        loading: false
      }
    case CHANGE_USER_SUGGEST_DOMAIN:
      return {
        ...state,
        userSuggestDomain: action.payload
      }
    case CHANGE_SHOW_SUCCESS_ADD_TOAST_FLAG:
      return {
        ...state,
        showSuccessAddToastFlag: action.payload
      }
    case CHANGE_CHECK_UNSUBS_EMAIL_ADDRESS_FLAG:
      return {
        ...state,
        checkUnsubsEmailAddressFlag: action.payload
      }
    case GET_CONTACT_SEARCH_LIST:
      return {
        ...state,
        loading: true
      }
    case GET_CONTACT_SEARCH_LIST_SUCCESS:
      return {
        ...state,
        searchList: action.payload.contactsList ? action.payload.contactsList : [],
        totalPageSearch: action.payload.totalPage,
        loading: false
      }
    case CHANGE_CONTACT_SEARCH_LIST:
      return {
        ...state,
        searchList: action.payload,
      }
    case DOWN_SEARCH_ENTITY_EXCEL:
      return {
        ...state,
        loading: true,
      }
    case DOWN_SEARCH_ENTITY_EXCEL_SUCCESS:
      return {
        ...state,
        loading: false,
      }
    default:
      return {
        ...state,
      }
  }
}

export default contacts
