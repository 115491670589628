import React, {useState, useEffect} from 'react';
import { useSelector, useDispatch } from "react-redux";
import {
    Table,
    Row,
    Col,
    Card,
    CardTitle,
    CardBody,
    Media,
    Collapse,
    Form,
    Input,
    Label,
    Button,
    UncontrolledTooltip,
  } from "reactstrap"
import Period4MBtn from '../../components/Filter/Period4MBtn';
import { Link } from "react-router-dom";
import * as Yup from "yup";
import { useFormik } from "formik";
import SearchName from '../../components/Filter/SearchName';
import SendEmailHistory from '../../components/Common/SendEmailHistory';

import {
  getSendingAnalyticsFrSettings,
  saveSendingAnalyticsFrSettings,
  getSendingAnalyticsFrList,
  downSendingAnalyticsFrExcel,
  setSendingAnalyticsError,
  changeSearchNameList as onChangeSearchNameList,
  changeSearchNameSelectedList as onChangeSearchNameSelectedList,
  setSearchSettingsFrLoaded as onSetSearchSettingsFrLoaded,
} from "../../store/actions";
import { cloneDeep } from 'lodash';
import dateFormat from 'dateformat';

import '../../components/Common/Common.css';

const FilerSendingAnalyticsLog = props => {
  const dispatch = useDispatch();

  const { searchSettingsFr, searchSettingsFrLogLoaded, sendingAnalyticsFrList, totalPageFr } = useSelector(state => ({
    searchSettingsFr: state.SendingAnalytics.searchSettingsFr,
    searchSettingsFrLogLoaded: state.SendingAnalytics.searchSettingsFrLogLoaded,
    sendingAnalyticsFrList: state.SendingAnalytics.sendingAnalyticsFrList,
    totalPageFr: state.SendingAnalytics.totalPageFr,
  }));

  const [curPageInputFr, setCurPageInputFr] = useState(1);

  const [isOpen, setIsOpen] = useState(true);
  const toggle = () => {
    setIsOpen(!isOpen);
  }
  const [isSearched, setIsSearched] = useState(false);

  const [preview, setPreview] = useState({});
  
  const [frColumns, setFrColumns] = useState([
    {label: "Status", asc:"STA", desc:"STD", ascActive:"", descActive:""},
    {label: "Sent date (Date and time)", asc:"SDA", desc:"SDD", ascActive:"", descActive:"text-danger"},
    {label: "To email", asc:"TEA", desc:"TED", ascActive:"", descActive:""},
    {label: "Entity", asc:"ETA", desc:"ETD", ascActive:"", descActive:""},
    {label: "Type(IN/OUT)", asc:null, desc:null, ascActive:null, descActive:null},
  ]);

  const viewSendingAnalyticsFrList = (sortType, curPage, perPage) => {
    //curPage 유효성 체크
    if(curPage){
      if(curPage > totalPageFr) {
        curPage = totalPageFr;
      } else if (curPage <= 0) {
        curPage = 1;
      }
    }
    //빨간색으로 변경
    if(sortType){
      const _columns = [];
      frColumns.map((col, index)=>{
        let _col = cloneDeep(col);
        if(col.asc && col.asc === sortType){
          _col.ascActive = "text-danger";
          _col.descActive = "";
        }else if(col.desc && col.desc === sortType ){
          _col.ascActive = "";
          _col.descActive = "text-danger";
        }else{
          _col.ascActive = "";
          _col.descActive = "";
        }
        _columns.push(_col);
      });

      setFrColumns(_columns);
    }

    // console.log("viewMadridNotifier [sortType: "+sortType+", curPage: "+curPage+", perPage: "+perPage);
    // let _values = cloneDeep(madridValidation.values);
    // _values.submitType = "view";
    filterValidation.setFieldValue("submitType", "viewFr");
    if(sortType){
      // _values.sortType = sortType;
      filterValidation.setFieldValue("sortTypeFr", sortType);
    }
    if(curPage){
      // _values.curPage = curPage;
      filterValidation.setFieldValue("curPageFr", curPage);
      setCurPageInputFr(curPage);
    }else{
      // _values.curPage = 1;
      filterValidation.setFieldValue("curPageFr", 1);
      setCurPageInputFr(1);
    }
    if(perPage){
      // _values.perPage = perPage;
      filterValidation.setFieldValue("perPageFr", perPage);
    }
    // madridValidation.setValues(_values);
    
    filterValidation.handleSubmit(filterValidation.values);
  }

  const filterValidation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      inOutType: "IN",

      submitType: "view",
      // inOutType: searchSettingsFr && searchSettingsFr.inOutType? searchSettingsFr.inOutType : "IN",
      inOutTypeFr: searchSettingsFr && searchSettingsFr.inOutType? searchSettingsFr.inOutType : "IN",
      statusListFr: searchSettingsFr && searchSettingsFr.statusList? searchSettingsFr.statusList : [],
      searchNoList: searchSettingsFr && searchSettingsFr.entityList? searchSettingsFr.entityList : [],
      searchKeyword: searchSettingsFr && searchSettingsFr.searchKeyword? searchSettingsFr.searchKeyword : "",
      searchNameList: [],
      toEmailFr: searchSettingsFr && searchSettingsFr.toEmail? searchSettingsFr.toEmail : "",
      datePeriod: searchSettingsFr && searchSettingsFr.datePeriod? searchSettingsFr.datePeriod : "",
      startDate: searchSettingsFr && searchSettingsFr.startDate? searchSettingsFr.startDate : "",
      endDate: searchSettingsFr && searchSettingsFr.endDate? searchSettingsFr.endDate : "",
      sortTypeFr: "SDD",
      perPageFr: 20,
      curPageFr: 1,

      screenGrade: "Free",
      screenName: "FilerSendingAnalyticsLog",
    },
    validationSchema: Yup.object().shape({
      startDate: Yup.date()
              .max(Yup.ref('endDate'), "From date must be earlier than to date")
              .required("Please Select From date")
      ,
      endDate: Yup.date()
              .max(new Date(), "Date cannot be in the future")
              .required("Please Select to date")
      ,
    }),
    validateOnChange: false, // this one
    validateOnBlur: false, // and this one
    onSubmit: (values) => {
      // console.log(JSON.stringify(values));

      const filterParam = {
        inOutType: values["inOutTypeFr"],
        statusList: values["statusListFr"],
        entityList: (values["searchNoList"] === '' ? [] : values["searchNoList"]),
        toEmail: values["toEmailFr"],
        startDate: values["startDate"],
        endDate: values["endDate"],
        sortType: values["sortTypeFr"],
        perPage: values["perPageFr"],
        curPage: values["curPageFr"],
        screenType : 'LOG',
      };

      if (!filterParam.startDate || filterParam.startDate === '' || !filterParam.endDate || filterParam.endDate === '') {}

      let chkStartDate = new Date(filterParam.startDate);
      let chkEndDate = new Date(filterParam.endDate);

      if ((chkEndDate.getTime() - chkStartDate.getTime()) / (1000*60*60*24) > 125) {
        dispatch(setSendingAnalyticsError("Select up to 125 days per search"));
        return;
      }
      
      let fileName = dateFormat(new Date(), "yymmdd") + " WeCrest Madrid Notifier Sending Analytics (" + values["startDate"].replace(/-/gi,"") + "-" + values["endDate"].replace(/-/gi,"") + ")"; 
      
      let submitType = values["submitType"];
      
      if(submitType === "viewFr"){

        // console.log('filterParam >> ' + JSON.stringify(filterParam));

        dispatch(getSendingAnalyticsFrList(filterParam));
        setIsSearched(true);
        
      }else if(submitType === "excelFr"){
        dispatch(downSendingAnalyticsFrExcel(filterParam, fileName));

      }else if(submitType === "saveSettingsFr"){

        const settingsParam = {
          inOutType: values["inOutTypeFr"],
          statusList: values["statusListFr"],
          entityList: (values["searchNoList"] === '' ? [] : values["searchNoList"]),
          searchKeyword: values["searchKeyword"],
          toEmail: values["toEmailFr"],
          datePeriod: values["datePeriod"],
          startDate: values["datePeriod"] === '' ? values["startDate"] : '',
          endDate: values["datePeriod"] === '' ? values["endDate"] : '',
          sortType: values["sortTypeFr"],
          perPage: values["perPageFr"],
          curPage: values["curPageFr"],
          screenType : 'LOG',
        };

        dispatch(saveSendingAnalyticsFrSettings(settingsParam));
      }
    },
  });

  
  useEffect(() => { 
    dispatch(getSendingAnalyticsFrSettings({screenType : 'LOG'}))

    return () => {
      dispatch(onChangeSearchNameList([], filterValidation.values.screenName));
      dispatch(onChangeSearchNameSelectedList([], filterValidation.values.screenName));
    };
  }, []);

  useEffect(() => {
    // console.log("LOG filterValidation.values.startDate change >>> " + filterValidation.values.startDate + ", searchSettingsFrLogLoaded : " + searchSettingsFrLogLoaded);
    if (searchSettingsFrLogLoaded && filterValidation.values.startDate != undefined && filterValidation.values.startDate != '') {
      dispatch(onSetSearchSettingsFrLoaded(false, 'LOG'));

      filterValidation.setFieldValue("submitType", "viewFr");
      filterValidation.handleSubmit(filterValidation.values);
    }
    
  },[filterValidation.values.startDate])

  const changeStatusList = (status, checked) => {
    let _statusList = filterValidation.values.statusListFr.toString();

    if (status === "_sent_") {
      if (checked && !_statusList.includes("_sent_")) {
        _statusList += ",_sent_";

        if (!_statusList.includes("_delivered_")) {
          _statusList += ",_delivered_";
        }
        if (!_statusList.includes("open")) {
          _statusList += ",open";
        }
        if (!_statusList.includes("click")) {
          _statusList += ",click";
        }
        if (!_statusList.includes("complaint")) {
          _statusList += ",complaint";
        }
        if (!_statusList.includes("unsubscribe")) {
          _statusList += ",unsubscribe";
        }

        if (!_statusList.includes("not_delivered")) {
          _statusList += ",not_delivered";
        }
        if (!_statusList.includes("soft_bounce")) {
          _statusList += ",soft_bounce";
        }
        if (!_statusList.includes("hard_bounce")) {
          _statusList += ",hard_bounce";
        }
        if (!_statusList.includes("invalid_email")) {
          _statusList += ",invalid_email";
        }
      } else {
        _statusList = _statusList.replace(",_sent_", "")
        .replace(",_delivered_", "")
        .replace(",open", "")
        .replace(",click", "")
        .replace(",complaint", "")
        .replace(",unsubscribe", "")
        .replace(",not_delivered", "")
        .replace(",soft_bounce", "")
        .replace(",hard_bounce", "")
        .replace(",invalid_email", "")
        ;
      }
    }

    if (status === "_delivered_") {
      if (checked) {
        _statusList += ",_delivered_";

        if (!_statusList.includes("open")) {
          _statusList += ",open";
        }
        if (!_statusList.includes("click")) {
          _statusList += ",click";
        }
        if (!_statusList.includes("complaint")) {
          _statusList += ",complaint";
        }
        if (!_statusList.includes("unsubscribe")) {
          _statusList += ",unsubscribe";
        }

        if (_statusList.includes("not_delivered")) {
          _statusList += ",_sent_";
        }
      } else {
        _statusList = _statusList.replace(",_sent_", "")
        .replace(",_delivered_", "")
        .replace(",open", "")
        .replace(",click", "")
        .replace(",complaint", "")
        .replace(",unsubscribe", "")
        ;
      }
    }

    if (status === "open" || status === "click" || status === "complaint" || status === "unsubscribe") {
      if (checked) {
        _statusList += "," + status;

        if (_statusList.includes("open") && _statusList.includes("click") && _statusList.includes("complaint") && _statusList.includes("unsubscribe")) {
          _statusList += ",_delivered_";
        }

        if (_statusList.includes("_delivered_") && _statusList.includes("not_delivered")) {
          _statusList += ",_sent_";
        }
      } else {
        _statusList = _statusList.replace(",_sent_", "")
        .replace(",_delivered_", "")
        .replace("," + status, "");
      }
    }

    if (status === "not_delivered") {
      if (checked) {
        _statusList += ",not_delivered";

        if (!_statusList.includes("soft_bounce")) {
          _statusList += ",soft_bounce";
        }
        if (!_statusList.includes("hard_bounce")) {
          _statusList += ",hard_bounce";
        }
        if (!_statusList.includes("invalid_email")) {
          _statusList += ",invalid_email";
        }

        if (_statusList.includes("_delivered_")) {
          _statusList += ",_sent_";
        }
      } else {
        _statusList = _statusList.replace(",_sent_", "")
        .replace(",not_delivered", "")
        .replace(",soft_bounce", "")
        .replace(",hard_bounce", "")
        .replace(",invalid_email", "")
        ;
      }
    }

    if (status === "soft_bounce" || status === "hard_bounce" || status === "invalid_email") {
      if (checked) {
        _statusList += "," + status;


        if (_statusList.includes("soft_bounce") && _statusList.includes("hard_bounce") && _statusList.includes("invalid_email")) {
          _statusList += ",not_delivered";
        }

        if (_statusList.includes("_delivered_") && _statusList.includes("not_delivered")) {
          _statusList += ",_sent_";
        }
      } else {
        _statusList = _statusList.replace(",_sent_", "")
        .replace(",not_delivered", "")
        .replace("," + status, "");
      }
    }

    if (status === "not_sent") {
      if (checked) {
        _statusList += ",not_sent";

        if (!_statusList.includes("pending")) {
          _statusList += ",pending";
        }

        if (!_statusList.includes("blocked")) {
          _statusList += ",blocked";
        }

        if (!_statusList.includes("error")) {
          _statusList += ",error";
        }
      } else {
        _statusList = _statusList.replace(",not_sent", "").replace(",pending", "").replace(",blocked", "").replace(",error", "");
      }
    }

    if (status === "pending" || status === "blocked" || status === "error") {
      if (checked) {
        _statusList += "," + status;

        if (_statusList.includes("pending") && _statusList.includes("blocked") && _statusList.includes("error")) {
          _statusList += ",not_sent";
        }
      } else {
        _statusList = _statusList.replace(",not_sent", "").replace("," + status, "");
      }
    }

    filterValidation.setFieldValue("statusListFr", _statusList.split(","));
  };

  
  return (
    <React.Fragment>
      <Form onSubmit={filterValidation.handleSubmit}>
        <Card>
          <Link onClick={toggle} className="text-dark" to="#">
            <div className="p-4">
              <Media className="d-flex align-items-center">
                <div className="flex-shrink-0 me-3">
                  <i className="uil uil-envelope-info text-primary h2"></i>
                </div>
                <div className="flex-grow-1 overflow-hidden">
                  <h5 className="font-size-16 mb-1">Log</h5>
                  {/*
                  <p className="text-warning mb-0">Sending Analytics is currently under maintenance and will not be available until further notice. We apologize for the inconvenience and will inform you when Sending Analytics is available again.</p>
                  */}
                  <p className="text-muted mb-0">Access detailed logs of your sent emails with WeCrest. Use this information to troubleshoot any issues and ensure your emails are successfully reaching your intended audience.</p>
                  
                </div>
                <i className="uil uil-filter font-size-24 text-muted" style={{ cursor: "pointer"}}></i>
              </Media>
            </div>
          </Link>
          <Collapse isOpen={isOpen}>
            <Row>
              <div className="p-4 border-top col-md-7" style={{borderRight : "1px solid #f5f6f8"}}>
                <Row className="mb-3">
                  <div className="col-md-2">
                    <div className="form-check">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="inOutTypeFr"
                      id="showInboundFr"
                      onClick={()=>{
                        filterValidation.setFieldValue("inOutType", "IN");
                        filterValidation.setFieldValue("inOutTypeFr", "IN");
                      }}
                      value="IN"
                      checked={filterValidation.values.inOutTypeFr === 'IN'}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="showInboundFr"
                    >
                      Inbound
                    </label>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-check">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="inOutTypeFr"
                      id="showOutboundFr"
                      onClick={()=>{
                        filterValidation.setFieldValue("inOutType", "OUT");
                        filterValidation.setFieldValue("inOutTypeFr", "OUT");
                      }}
                      value="OUT"
                      checked={filterValidation.values.inOutTypeFr === 'OUT'}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="showOutboundFr"
                    >
                      Outbound
                    </label>
                    </div>
                  </div>
                </Row>
                
                <SearchName 
                  formik={filterValidation} 
                  firstColMd={2}
                  labelName='Entity'
                />
                
                <Row className="mb-3">
                  <label
                  htmlFor="toEmailFr"
                  className="col-md-2 col-form-label"
                  >
                  TO email
                  </label>
                  <div className="col-md-3">
                  <Input
                      className="form-control"
                      type="text"
                      placeholder="Enter to email"
                      id="toEmailFr"
                      name="toEmailFr"
                      onChange={filterValidation.handleChange}
                      value={filterValidation.values.toEmailFr}
                  />
                  </div>
                </Row>
                
                <Row>
                  <label htmlFor="sentDate" className="col-md-2 col-form-label" style={{paddingTop : '0px'}}>
                    Sent date
                  </label>
                  <div className="col-md-9">
                    <Period4MBtn
                      formik={filterValidation}
                    />
                  </div>
                </Row>
                
                <Row>
                  <label
                    htmlFor="perPageFilter"
                    className="col-md-2 col-form-label" style={{paddingTop : '0px'}}
                    >
                    Entries per page
                  </label>
                  <div className="col-md-2">
                    <select 
                      className="form-select" 
                      name="perPageFilter"
                      onChange={e => {viewSendingAnalyticsFrList(null, 1, e.target.value);}}
                      value={filterValidation.values.perPageFr}
                    >
                      <option value="10">10</option>
                      <option value="20">20</option>
                      <option value="30">30</option>
                      <option value="40">40</option>
                      <option value="50">50</option>
                    </select>
                  </div>
                </Row>
              </div>
              <div className="p-4 border-top col-md-5" style={{color: "#495057"}}>
                <Row>
                  <div className="col-md-2">
                    <div className="form-check form-check-right">
                      <input type="checkbox" className="form-check-input form-check-input-right"
                        id="entityStatusSent"
                        name="statusListFr"
                        onChange={e => changeStatusList(e.target.value, e.target.checked)}
                        value="_sent_"
                        checked={filterValidation.values.statusListFr.includes("_sent_")}
                      />
                      <Label className="form-check-label text-danger" htmlFor="entityStatusSent">
                      Sent
                      </Label>
                    </div>
                  </div>
                  <div className="col-md-10">
                  </div>
                </Row>
                <Row >
                  <div className="col-md-2 text-align-center text-success">
                  -
                  </div>
                  <div className="col-md-3">
                    <div className="form-check form-check-right">
                      <input type="checkbox" className="form-check-input form-check-input-right"
                        id="entityStatusDelivered"
                        name="statusListFr"
                        onChange={e => changeStatusList(e.target.value, e.target.checked)}
                        value="_delivered_"
                        checked={filterValidation.values.statusListFr.includes("_delivered_")}
                      />
                      <Label className="form-check-label text-success" htmlFor="entityStatusDelivered">
                      Delivered
                      </Label>
                    </div>
                  </div>
                  <div className="col-md-8">
                  </div>
                </Row>
                <Row >
                  <label className="col-md-2">
                  </label>
                  <label className="col-md-2 text-align-center">
                  -
                  </label>
                  <div className="col-md-3">
                    <div className="form-check form-check-right">
                      <input type="checkbox" className="form-check-input form-check-input-right"
                        id="entityStatusOpened"
                        name="statusListFr"
                        onChange={e => changeStatusList(e.target.value, e.target.checked)}
                        value="open"
                        checked={filterValidation.values.statusListFr.includes("open")}
                      />
                      <Label className="form-check-label" htmlFor="entityStatusOpened">
                      Opened
                      </Label>
                    </div>
                  </div>
                  <div className="col-md-6">
                  </div>
                </Row>
                <Row >
                  <label className="col-md-2">
                  </label>
                  <label className="col-md-2 text-align-center">
                  -
                  </label>
                  <div className="col-md-3">
                    <div className="form-check form-check-right">
                      <input type="checkbox" className="form-check-input form-check-input-right"
                        id="entityStatusClicked"
                        name="statusListFr"
                        onChange={e => changeStatusList(e.target.value, e.target.checked)}
                        value="click"
                        checked={filterValidation.values.statusListFr.includes("click")}
                      />
                      <Label className="form-check-label" htmlFor="entityStatusClicked">
                      Clicked
                      </Label>
                    </div>
                  </div>
                  <div className="col-md-6">
                  </div>
                </Row>
                <Row >
                  <label className="col-md-2">
                  </label>
                  <label className="col-md-2 text-align-center">
                  -
                  </label>
                  <div className="col-md-3">
                    <div className="form-check form-check-right">
                      <input type="checkbox" className="form-check-input form-check-input-right"
                        id="entityStatusComplaint"
                        name="statusListFr"
                        onChange={e => changeStatusList(e.target.value, e.target.checked)}
                        value="complaint"
                        checked={filterValidation.values.statusListFr.includes("complaint")}
                      />
                      <Label className="form-check-label" htmlFor="entityStatusComplaint">
                      Complaint
                      </Label>
                    </div>
                  </div>
                  <div className="col-md-6">
                  </div>
                </Row>
                <Row >
                  <label className="col-md-2">
                  </label>
                  <label className="col-md-2 text-align-center">
                  -
                  </label>
                  <div className="col-md-3">
                    <div className="form-check form-check-right">
                      <input type="checkbox" className="form-check-input form-check-input-right"
                        id="entityStatusUnsubscribed"
                        name="statusListFr"
                        onChange={e => changeStatusList(e.target.value, e.target.checked)}
                        value="unsubscribe"
                        checked={filterValidation.values.statusListFr.includes("unsubscribe")}
                      />
                      <Label className="form-check-label" htmlFor="entityStatusUnsubscribed">
                      Unsubscribed
                      </Label>
                    </div>
                  </div>
                  <div className="col-md-6">
                  </div>
                </Row>
                <Row >
                  <div className="col-md-2 text-align-center text-success">
                  -
                  </div>
                  <div className="col-md-3">
                    <div className="form-check form-check-right">
                      <input type="checkbox" className="form-check-input form-check-input-right"
                        id="entityStatusNotDelivered"
                        name="statusListFr"
                        onChange={e => changeStatusList(e.target.value, e.target.checked)}
                        value="not_delivered"
                        checked={filterValidation.values.statusListFr.includes("not_delivered")}
                      />
                      <Label className="form-check-label text-success" htmlFor="entityStatusNotDelivered">
                      Not delivered
                      </Label>
                    </div>
                  </div>
                  <div className="col-md-7">
                  </div>
                </Row>
                <Row >
                  <label className="col-md-2">
                  </label>
                  <label className="col-md-2 text-align-center">
                  -
                  </label>
                  <div className="col-md-3">
                    <div className="form-check form-check-right">
                      <input type="checkbox" className="form-check-input form-check-input-right"
                        id="entityStatusSoftbounce"
                        name="statusListFr"
                        onChange={e => changeStatusList(e.target.value, e.target.checked)}
                        value="soft_bounce"
                        checked={filterValidation.values.statusListFr.includes("soft_bounce")}
                      />
                      <Label className="form-check-label" htmlFor="entityStatusSoftbounce">
                      Soft bounce
                      </Label>
                    </div>
                  </div>
                  <div className="col-md-6">
                  </div>
                </Row>
                <Row >
                  <label className="col-md-2">
                  </label>
                  <label className="col-md-2 text-align-center">
                  -
                  </label>
                  <div className="col-md-3">
                    <div className="form-check form-check-right">
                      <input type="checkbox" className="form-check-input form-check-input-right"
                        id="entityStatusHardbounce"
                        name="statusListFr"
                        onChange={e => changeStatusList(e.target.value, e.target.checked)}
                        value="hard_bounce"
                        checked={filterValidation.values.statusListFr.includes("hard_bounce")}
                      />
                      <Label className="form-check-label" htmlFor="entityStatusHardbounce">
                      Hard bounce
                      </Label>
                    </div>
                  </div>
                  <div className="col-md-6">
                  </div>
                </Row>
                <Row >
                  <label className="col-md-2">
                  </label>
                  <label className="col-md-2 text-align-center">
                  -
                  </label>
                  <div className="col-md-3">
                    <div className="form-check form-check-right">
                      <input type="checkbox" className="form-check-input form-check-input-right"
                        id="entityStatusInvalidEmail"
                        name="statusListFr"
                        onChange={e => changeStatusList(e.target.value, e.target.checked)}
                        value="invalid_email"
                        checked={filterValidation.values.statusListFr.includes("invalid_email")}
                      />
                      <Label className="form-check-label" htmlFor="entityStatusInvalidEmail">
                      Invalid email
                      </Label>
                    </div>
                  </div>
                  <div className="col-md-6">
                  </div>
                </Row>
                <Row>
                  <div className="col-md-2">
                    <div className="form-check form-check-right">
                      <input type="checkbox" className="form-check-input form-check-input-right"
                        id="entityStatusNotSent"
                        name="statusListFr"
                        onChange={e => changeStatusList(e.target.value, e.target.checked)}
                        value="not_sent"
                        checked={filterValidation.values.statusListFr.includes("not_sent")}
                      />
                      <Label className="form-check-label text-danger" htmlFor="entityStatusNotSent">
                      Not sent
                      </Label>
                    </div>
                  </div>
                  <div className="col-md-10">
                  </div>
                </Row>
                <Row >
                  <div className="col-md-2 text-align-center text-success">
                  -
                  </div>
                  <div className="col-md-2">
                    <div className="form-check form-check-right">
                      <input type="checkbox" className="form-check-input form-check-input-right"
                        id="entityStatusPending"
                        name="statusListFr"
                        onChange={e => changeStatusList(e.target.value, e.target.checked)}
                        value="pending"
                        checked={filterValidation.values.statusListFr.includes("pending")}
                      />
                      <Label className="form-check-label text-success" htmlFor="entityStatusPending">
                      Pending
                      </Label>
                    </div>
                  </div>
                  <div className="col-md-8">
                  </div>
                </Row>
                <Row >
                  <div className="col-md-2 text-align-center text-success">
                  -
                  </div>
                  <div className="col-md-2">
                    <div className="form-check form-check-right">
                      <input type="checkbox" className="form-check-input form-check-input-right"
                        id="entityStatusBlocked"
                        name="statusListFr"
                        onChange={e => changeStatusList(e.target.value, e.target.checked)}
                        value="blocked"
                        checked={filterValidation.values.statusListFr.includes("blocked")}
                      />
                      <Label className="form-check-label text-success" htmlFor="entityStatusBlocked">
                      Blocked
                      </Label>
                    </div>
                  </div>
                  <div className="col-md-8">
                  </div>
                </Row>
                <Row >
                  <div className="col-md-2 text-align-center text-success">
                  -
                  </div>
                  <div className="col-md-2">
                    <div className="form-check form-check-right">
                      <input type="checkbox" className="form-check-input form-check-input-right"
                        id="entityStatusError"
                        name="statusListFr"
                        onChange={e => changeStatusList(e.target.value, e.target.checked)}
                        value="error"
                        checked={filterValidation.values.statusListFr.includes("error")}
                      />
                      <Label className="form-check-label text-success" htmlFor="entityStatusError">
                      Error
                      </Label>
                    </div>
                  </div>
                  <div className="col-md-8">
                  </div>
                </Row>

                <br/>
                
                <Row className="mb-6 text-danger">
                <div className="col-md-12 col-form-label">
                To track this data, you can use our new mailing system. Please complete the setup process by entering your email <a href='/emailingSettings' className='a-href-underline'>settings</a> here. Once you have completed this step, you will be able to track this data.
                <br/>
                Please note that this data is updated once every day.
                </div>
                </Row>
              </div>
            </Row>
            <Row>
              <div className="p-4 border-top col-md-12" style={{borderRight : "1px solid #f5f6f8!important"}}>
                <Row className="mb-3">
                  <span className="col-md-6 button-items">
                    <Button
                      type="button"
                      color="primary"
                      outline
                      className="waves-effect waves-light"
                      onClick={()=>{
                        filterValidation.setFieldValue("submitType", "saveSettingsFr");
                        filterValidation.handleSubmit(filterValidation.values);
                      }}
                    >
                      Save settings
                    </Button>
                    <Button
                      type="button"
                      color="primary"
                      outline
                      className="waves-effect waves-light"
                      onClick={()=>{
                        filterValidation.setFieldValue("inOutType", "IN");
                        filterValidation.setFieldValue("inOutTypeFr", "IN");
                        filterValidation.setFieldValue("searchNoList", []);
                        filterValidation.setFieldValue("searchKeyword", "");
                        filterValidation.setFieldValue("toEmailFr", "");
                        filterValidation.setFieldValue("datePeriod", "");
                        filterValidation.setFieldValue("startDate", "");
                        filterValidation.setFieldValue("endDate", "");
                        filterValidation.setFieldValue("perPageFr", "20");
                        filterValidation.setFieldValue("statusListFr", []);
                        dispatch(onChangeSearchNameList([], filterValidation.values.screenName));
                        dispatch(onChangeSearchNameSelectedList([], filterValidation.values.screenName));
                      }}
                    >
                      Clear filters
                    </Button>
                    <Button
                      type="button"
                      color="primary"
                      outline
                      className="waves-effect waves-light"
                      onClick={()=>{
                        filterValidation.setFieldValue("submitType", "excelFr");
                        filterValidation.handleSubmit(filterValidation.values);
                      }}
                      id="btn_down_excel"
                    >
                      Download Excel
                    </Button>
                    <UncontrolledTooltip placement="top" target="btn_down_excel">
                    Download Excel limit 10,000 row
                    </UncontrolledTooltip>
                  </span>
                  <Col md={3} style={{paddingLeft : '1.3em'}}>
                    <button
                      type="button"
                      className="waves-effect waves-light btn btn-primary"
                      style={{width : '148.58px'}}
                      onClick={()=>{
                        // let _values = cloneDeep(madridValidation.values);
                        // _values.submitType = "view";
                        // madridValidation.setValues(_values);
                        setCurPageInputFr(1);
                        filterValidation.setFieldValue("curPageFr", 1);
                        filterValidation.setFieldValue("submitType", "viewFr");
                        filterValidation.handleSubmit(filterValidation.values);
                      }}
                    >
                      Search
                    </button>
                  </Col>
                </Row>
              </div>
            </Row>
          </Collapse>
        </Card>
        {isSearched ?
        <Row>
          <Col lg={12}>
            <Card>
              <CardBody>
                {!sendingAnalyticsFrList || sendingAnalyticsFrList.length == 0 ?
                  <CardTitle className="text-muted mb-0">
                    <p style={{whiteSpace : "pre"}}>
                    Data for selected time period not available
                    </p>
                  </CardTitle>
                  : null
                }
                <div className="table-responsive">
                  <Table className="table mb-0">
                    <thead>
                      <tr>
                      {
                        frColumns && frColumns.length ?
                        frColumns.map((col, index) => {
                            return (
                              <th style={{position: "relative", whiteSpace: "pre-line", textAlign: "center"}}>
                                {col.label}
                                {col.asc && col.desc? <>
                                <Link to='#' onClick={()=> {viewSendingAnalyticsFrList(col.asc)}} className={col.ascActive}><i className="btn-asc-30 uil uil-angle-up font-size-18"></i></Link>
                                <Link to='#' onClick={()=> {viewSendingAnalyticsFrList(col.desc)}} className={col.descActive}><i className="btn-desc-30 uil uil-angle-down font-size-18"></i></Link>
                                </>
                              :null}
                              </th>
                            )
                        })
                        :null
                      }
                      </tr>
                    </thead>
                    <tbody>
                    {
                      sendingAnalyticsFrList && sendingAnalyticsFrList.length ?
                      sendingAnalyticsFrList.map((filer, index) => {
                        return (
                          <tr key={index} onClick={() => {setPreview(filer)}} style={{ cursor: "pointer"}}>
                            <td align="center">{filer.sendinblueStatusDesc}</td>
                            <td align="center">{filer.sentDate}</td>
                            <td align="center">{filer.toEmail}</td>
                            <td align="center">{filer.targetGroupName}</td>
                            <td align="center">{filer.inOutTypeStr}</td>
                          </tr>
                          )
                        })
                      : null
                      }
                    </tbody>
                  </Table>
                </div>
                <div className="d-flex justify-content-between">
                  <span className="d-flex align-items-center gap-2">
                    <label
                      htmlFor="perPage"
                      >
                      Entries per page
                    </label>
                    <div style={{width: "100px"}}>
                      <select 
                        className="form-select" 
                        name="perPage"
                        onChange={e => {viewSendingAnalyticsFrList(null, 1, e.target.value);}}
                        value={filterValidation.values.perPageFr}
                      >
                        <option value="10">10</option>
                        <option value="20">20</option>
                        <option value="30">30</option>
                        <option value="40">40</option>
                        <option value="50">50</option>
                      </select>
                    </div>
                  </span>
                  <span className="d-flex align-items-center gap-2">
                    <div className="d-flex gap-1">
                      <Button
                        type="button"
                        color="primary"
                        onClick={()=>{viewSendingAnalyticsFrList(null, 1);}}
                        disabled={(filterValidation.values.curPageFr === 1)}
                      >
                        {"<<"}
                      </Button>
                      <Button
                        type="button"
                        color="primary"
                        onClick={()=>{viewSendingAnalyticsFrList(null, filterValidation.values.curPageFr-1);}}
                        disabled={(filterValidation.values.curPageFr === 1)}
                      >
                        {"<"}
                      </Button>
                    </div>
                    Page{" "}
                    <strong>
                      {filterValidation.values.curPageFr} of {totalPageFr}
                    </strong>
                    
                    <Input
                      type="number"
                      min={1}
                      style={{ width: 70 }}
                      max={totalPageFr}
                      name="curPage"
                      defaultValue={filterValidation.values.curPageFr}
                      value={curPageInputFr}
                      onChange={(e) => { setCurPageInputFr(e.target.value)}}
                      onKeyPress={(e) => {
                        if(e.key === 'Enter'){
                          viewSendingAnalyticsFrList(null, e.target.value);
                        }                      
                      }}
                    />
                    <div className="d-flex gap-1">
                      <Button 
                        type="button"
                        color="primary"
                        onClick={()=>{viewSendingAnalyticsFrList(null, filterValidation.values.curPageFr+1);}}
                        disabled={(filterValidation.values.curPageFr === totalPageFr)}
                      >
                        {">"}
                      </Button>
                      <Button
                        type="button"
                        color="primary"
                        onClick={()=>{viewSendingAnalyticsFrList(null, totalPageFr);}}
                        disabled={(filterValidation.values.curPageFr === totalPageFr)}
                      >
                        {">>"}
                      </Button>
                    </div>
                  </span>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
        : null}
      </Form>
      <SendEmailHistory preview={preview} setPreview={setPreview}/>
    </React.Fragment>
  )
}

export default FilerSendingAnalyticsLog;