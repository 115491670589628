import {
  GET_DOMAIN_LIST,
  GET_DOMAIN_LIST_SUCCESS,
  CHANGE_DOMAIN_LIST,
  GET_DOMAIN_ACTION_HISTORY,
  GET_DOMAIN_ACTION_HISTORY_SUCCESS,
  SET_VIEW_DOMAIN_ACTION_HISTORY,
  SAVE_DOMAIN_INFO,
  SAVE_DOMAIN_INFO_SUCCESS,
  GET_BLACK_LIST,
  GET_BLACK_LIST_SUCCESS,
  ADD_BLACK_DOMAIN,
  ADD_BLACK_DOMAIN_SUCCESS,
  DOWN_EXCEL_BLACK_LIST,
  DOWN_EXCEL_BLACK_LIST_SUCCESS,
  SET_ADM_DOMAIN_MANAGEMENT_SUCCESS,
  SET_ADM_DOMAIN_MANAGEMENT_ERROR,
  SET_ADM_DOMAIN_LOADING,
  GET_ENTITY_DETAIL,
  GET_ENTITY_DETAIL_SUCCESS,
  SAVE_ENTITY_EMAILS,
  SAVE_ENTITY_EMAILS_SUCCESS,
  DELETE_ENTITY_EMAILS,
  DELETE_ENTITY_EMAILS_SUCCESS,
  CHANGE_ENTITY_DETAILS,
  SET_ENTITY_DETAIL_RESULT,
  SET_ENTITY_DETAIL_SUCCESS,
} from "./actionTypes"

export const getDomainList = data => {
  return {
    type: GET_DOMAIN_LIST,
    payload: data,
  }
}

export const getDomainListSuccess = data => {
  return {
    type: GET_DOMAIN_LIST_SUCCESS,
    payload: data,
  }
}

export const changeDomainList = data => {
  return {
    type: CHANGE_DOMAIN_LIST,
    payload: data,
  }
}

export const getDomainActionHistory = data => {
  return {
    type: GET_DOMAIN_ACTION_HISTORY,
    payload: data,
  }
}

export const getDomainActionHistorySuccess = data => {
  return {
    type: GET_DOMAIN_ACTION_HISTORY_SUCCESS,
    payload: data,
  }
}

export const setViewDomainActionHistory = data => {
  return {
    type: SET_VIEW_DOMAIN_ACTION_HISTORY,
    payload: data,
  }
}

export const saveDomainInfo = data => {
  return {
    type: SAVE_DOMAIN_INFO,
    payload: data,
  }
}

export const saveDomainInfoSuccess = data => {
  return {
    type: SAVE_DOMAIN_INFO_SUCCESS,
    payload: data,
  }
}

export const getBlackList = data => {
  return {
    type: GET_BLACK_LIST,
    payload: data,
  }
}

export const getBlackListSuccess = data => {
  return {
    type: GET_BLACK_LIST_SUCCESS,
    payload: data,
  }
}

export const addBlackList = data => {
  return {
    type: ADD_BLACK_DOMAIN,
    payload: data,
  }
}

export const addBlackListSuccess = data => {
  return {
    type: ADD_BLACK_DOMAIN_SUCCESS,
    payload: data,
  }
}

export const downExcelBlackList = (data, fileName) => {
  return {
    type: DOWN_EXCEL_BLACK_LIST,
    payload: {data, fileName},
  }
}

export const downExcelBlackListSuccess = data => {
  return {
    type: DOWN_EXCEL_BLACK_LIST_SUCCESS,
    payload: data,
  }
}

export const setAdmDomainManagementSuccess = data => {
  return {
    type: SET_ADM_DOMAIN_MANAGEMENT_SUCCESS,
    payload: data,
  }
}

export const setAdmDomainManagementError = data => {
  return {
    type: SET_ADM_DOMAIN_MANAGEMENT_ERROR,
    payload: data,
  }
}

export const setAdmDomainLoading = data => {
  return {
    type: SET_ADM_DOMAIN_LOADING,
    payload: data,
  }
}

export const getEntityDetail = data => {
  return {
    type: GET_ENTITY_DETAIL,
    payload: data,
  }
}

export const getEntityDetailSuccess = data => {
  return {
    type: GET_ENTITY_DETAIL_SUCCESS,
    payload: data,
  }
}

export const saveEntityEmails = data => {
  return {
    type: SAVE_ENTITY_EMAILS,
    payload: data,
  }
}

export const saveEntityEmailsSuccess = data => {
  return {
    type: SAVE_ENTITY_EMAILS_SUCCESS,
    payload: data,
  }
}

export const deleteEntityEmails = data => {
  return {
    type: DELETE_ENTITY_EMAILS,
    payload: data,
  }
}

export const deleteEntityEmailsSuccess = data => {
  return {
    type: DELETE_ENTITY_EMAILS_SUCCESS,
    payload: data,
  }
}

export const changeEntityDetails = data => {
  return {
    type: CHANGE_ENTITY_DETAILS,
    payload: data,
  }
}

export const setEntityDetailResult = data => {
  return {
    type: SET_ENTITY_DETAIL_RESULT,
    payload: data,
  }
}

export const setEntityDetailSuccess = data => {
  return {
    type: SET_ENTITY_DETAIL_SUCCESS,
    payload: data,
  }
}