export const GET_JURISDICTIONS_MONTHLY_PRICING = "GET_JURISDICTIONS_MONTHLY_PRICING"
export const GET_JURISDICTIONS_MONTHLY_PRICING_SUCCESS = "GET_JURISDICTIONS_MONTHLY_PRICING_SUCCESS"
export const CHANGE_JURISDICTIONS_MONTHLY_PRICING = "CHANGE_JURISDICTIONS_MONTHLY_PRICING"
export const GET_USER_MEMBERSHIP_INFO = "GET_USER_MEMBERSHIP_INFO"
export const GET_USER_MEMBERSHIP_INFO_SUCCESS = "GET_USER_MEMBERSHIP_INFO_SUCCESS"
export const SET_MEMBERSHIP_PLAN_VALUE = "SET_MEMBERSHIP_PLAN_VALUE"
export const UPDATE_SUBSCRIPTION_TO_FREE = "UPDATE_SUBSCRIPTION_TO_FREE"
export const UPDATE_SUBSCRIPTION_TO_FREE_SUCCESS = "UPDATE_SUBSCRIPTION_TO_FREE_SUCCESS"
export const UPDATE_GOLD_MEMBERSHIP_PLAN = "UPDATE_GOLD_MEMBERSHIP_PLAN"
export const UPDATE_GOLD_MEMBERSHIP_PLAN_SUCCESS = "UPDATE_GOLD_MEMBERSHIP_PLAN_SUCCESS"
export const GET_PAYPAL_PLAN_ID = "GET_PAYPAL_PLAN_ID"
export const GET_PAYPAL_PLAN_ID_SUCCESS = "GET_PAYPAL_PLAN_ID_SUCCESS"