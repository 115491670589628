import { call, put, takeEvery, takeLatest } from "redux-saga/effects"

// Login Redux States
import { 
  LOGIN_USER, 
  LOGOUT_USER, 
  SOCIAL_LOGIN, 
  SESSION_LOGOUT,
  CHECK_COOKIE_AGREE,
  CHECK_COOKIE_AGREE_SUCCESS,
  UPDATE_COOKIE_AGREE,
  UPDATE_COOKIE_AGREE_SUCCESS,
} from "./actionTypes"
import { apiError, loginSuccess, logoutUserSuccess, openTcAgreeModal, checkCookieAgreeSuccess, updateCookieAgreeSuccess } from "./actions"

//Include Both Helper File with needed methods
// import { getFirebaseBackend } from "../../../helpers/firebase_helper"
// import {
//   // postFakeLogin,
//   // postJwtLogin,
//   postSocialLogin,
// } from "../../../helpers/fakebackend_helper"

import {
  postWeCrestLogin,
  postWeCrestLogout,
  checkCookieAgree,
  updateCookieAgree,
} from "../../../helpers/backend_helper"

//import userManager from "../../../managers/user";

//const fireBaseBackend = getFirebaseBackend()

function* loginUser({ payload: { user, history } }) {
  try {
    /*
    if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
      const response = yield call(
        fireBaseBackend.loginUser,
        user.email,
        user.password
      )
      yield put(loginSuccess(response))
    } else if (process.env.REACT_APP_DEFAULTAUTH === "jwt") {
      const response = yield call(postJwtLogin, {
        email: user.email,
        password: user.password,
      })
      localStorage.setItem("authUser", JSON.stringify(response))
      yield put(loginSuccess(response))
    } else if (process.env.REACT_APP_DEFAULTAUTH === "fake") {
      const response = yield call(postFakeLogin, {
        email: user.email,
        password: user.password,
      })
      localStorage.setItem("authUser", JSON.stringify(response))
      yield put(loginSuccess(response))
    }
    */

    // console.log('userName > ' + user.userName);
    // console.log('password > ' + user.password);

    const response = yield call(postWeCrestLogin, {
      userName : user.userName,
      password : user.password,
      tcAgree: user.tcAgree
    })
    let responseObj = JSON.parse(JSON.stringify(response));

    // console.log('responseObj => ' + JSON.stringify(responseObj));

    if (responseObj.result === 'tcAgreeN') {
      yield put(openTcAgreeModal());
    } else {
      const authUser = {
        userName: responseObj.userInfo.userName,
        adminYn: responseObj.userInfo.adminYn,
        photoUrl: responseObj.userInfo.photoUrl,
        virtualLogIn: 'N'
      }

      localStorage.removeItem("loginKey")
      localStorage.removeItem("authUser")

      localStorage.setItem("loginKey", responseObj.loginKey)
      localStorage.setItem("authUser", JSON.stringify(authUser))
      yield put(loginSuccess(response));

      if (responseObj.userInfo.adminYn === 'Y') {
        window.location.href='/admMyHome';
      } else {
        // console.log('refererUrl => ' + user.refererUrl);

        if (!user.refererUrl || user.refererUrl === '' || user.refererUrl === '/') {
          history.push("/dashboard");
        } else {
          if (user.refererUrl === '/admMyHome' && responseObj.userInfo.adminYn !== 'Y') {
            history.push("/dashboard");
          } else {
            window.location.href=user.refererUrl;
          }
          // console.log('refererUrl 2 => ' + user.refererUrl);
          // history.push(user.refererUrl);
        }
      }
    }
  } catch (error) {
    console.log('error => ' + error);
    yield put(apiError(error))
  }
}

function* logoutUser({ payload: { history } }) {
  try {
    const userObj = JSON.parse(localStorage.getItem("authUser"));
    if (userObj.virtualLogIn === 'Y') {
      const response  = yield call(postWeCrestLogout, {});

      let responseObj = JSON.parse(JSON.stringify(response));

      const authUser = {
        userName: responseObj.userInfo.userName,
        adminYn: responseObj.userInfo.adminYn,
        photoUrl: responseObj.userInfo.photoUrl,
        virtualLogIn: 'N'
      }
      localStorage.setItem("authUser", JSON.stringify(authUser))
      localStorage.setItem("loginKey", responseObj.loginKey)
      
      window.location.href='/admAccountManagement';
    } else {
      localStorage.removeItem("loginKey")
      localStorage.removeItem("authUser")
      const response  = yield call(postWeCrestLogout, {});
      yield put(logoutUserSuccess(response))
      history.push("/login")
    }
  } catch (error) {
    yield put(apiError(error))
  }
}

// function* socialLogin({ payload: { data, history, type } }) {
//   try {
//     if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
//       const fireBaseBackend = getFirebaseBackend()
//       const response = yield call(
//         fireBaseBackend.socialLoginUser,
//         data,
//         type,
//       )
//       localStorage.setItem("authUser", JSON.stringify(response))
//       yield put(loginSuccess(response))
//     } else {
//       const response = yield call(postSocialLogin, data)
//       localStorage.setItem("authUser", JSON.stringify(response))
//       yield put(loginSuccess(response))
//     }
//     history.push("/dashboard")
//   } catch (error) {
//     yield put(apiError(error))
//   }
// }

function* sessionLogout(data) {
  try {
    localStorage.removeItem("authUser")
    const response  = yield call(postWeCrestLogout, {});
    yield put(logoutUserSuccess(response))
   
    window.location.href = "/login";
  } catch (error) {
    yield put(apiError(error))
  }
}

function* onCheckCookieAgree() {
  try {
    
    const response = yield call(checkCookieAgree, {});

    if(response.cookieAgreeYn != undefined && response.cookieAgreeYn == 'Y'){
      yield put(checkCookieAgreeSuccess(true));
    }else{
      yield put(checkCookieAgreeSuccess(false));
    }

  } catch (error) {
    console.log('onCheckCookieAgree error >> ' + error);
  }
}

function* onUpdateCookieAgree() {
  try {
    const response = yield call(updateCookieAgree, {});
    if(response.cookieAgreeYn != undefined && response.cookieAgreeYn == 'Y'){
      yield put(updateCookieAgreeSuccess(true));
    }else{
      yield put(updateCookieAgreeSuccess(false));
    }

  } catch (error) {
    console.log('onUpdateCookieAgree error >> ' + error);
  }
}

function* authSaga() {
  yield takeEvery(LOGIN_USER, loginUser)
  // yield takeLatest(SOCIAL_LOGIN, socialLogin)
  yield takeEvery(LOGOUT_USER, logoutUser)
  yield takeLatest(SESSION_LOGOUT, sessionLogout)
  yield takeEvery(CHECK_COOKIE_AGREE, onCheckCookieAgree)
  yield takeEvery(UPDATE_COOKIE_AGREE, onUpdateCookieAgree)
}

export default authSaga
