import { call, put, takeEvery, takeLatest } from "redux-saga/effects"

// MyCaseManagement Redux States
import { 
  GET_MY_CONNECTED_CASE_SETTINGS,
  SAVE_MY_CONNECTED_CASE_SETTINGS,
  GET_MY_CONNECTED_CASE_LIST,
  GET_MY_CASE_MANAGEMENT_SETTINGS,
  SAVE_MY_CASE_MANAGEMENT_SETTINGS,
  GET_MY_CASE_MANAGEMENT_LIST,
  DOWN_MY_CASE_MANAGEMENT_EXCEL,
  DOWN_MULTIPLE_MY_CASE_MANAGEMENT_EXCEL,
  DOWN_MY_CASE_MANAGEMENT_PDF,
  DOWN_MULTIPLE_MY_CASE_MANAGEMENT_PDF,
  GET_USER_IR_INFO_CLAIM_LIST,
  ADD_USER_IR_INFO_CLAIM,
  CONFIRM_USER_IR_INFO_CLAIM,
  GET_TARGET_GROUP_DETAIL_LIST,
} from "./actionTypes"

import { 
  getMyConnectedCaseSettingsSuccess,
  saveMyConnectedCaseSettingSuccess,
  getMyConnectedCaseList as getMyConnectedCaseListAction,
  getMyConnectedCaseListSuccess,
  getMyCaseManagementSettingsSuccess,
  saveMyCaseManagementSettingSuccess,
  getMyCaseManagementList as getMyCaseManagementListAction,
  getMyCaseManagementListSuccess,
  downMyCaseManagementExcelSuccess,
  downMultipleMyCaseManagementExcelSuccess,
  downMyCaseManagementPdfSuccess,
  downMultipleMyCaseManagementPdfSuccess,
  getUserIrInfoClaimList as getUserIrInfoClaimListAction,
  getUserIrInfoClaimListSuccess,
  addUserIrInfoClaimSuccess,
  confirmUserIrInfoClaimSuccess,
  getTargetGroupDetailListSuccess,
} from "./actions"

//Include Both Helper File with needed methods
import {
  getMyConnectedCaseSettings,
  saveMyConnectedCaseSetting,
  getMyCaseManagementSettings,
  saveMyCaseManagementSetting,
  getMyCaseManagementList,
  downMyCaseManagementExcel,
  downMultipleMyCaseManagementExcel,
  downMyCaseManagementPdf,
  downMultipleMyCaseManagementPdf,
  getUserIrInfoClaimList,
  addUserIrInfoClaim,
  confirmUserIrInfoClaim,
  getTargetGroupDetailList,
} from "../../helpers/backend_helper"

function* onGetMyConnectedCaseSettings() {
  try {
    const response = yield call(getMyConnectedCaseSettings);
    yield put(getMyConnectedCaseSettingsSuccess(response));
    
  } catch (error) {
    console.log('onGetMyConnectedCaseSettings error >> ' + error);
  }
}

function* onSaveMyConnectedCaseSettings({ payload : data }) {
  try {
    const response = yield call(saveMyConnectedCaseSetting, data);
    yield put(saveMyConnectedCaseSettingSuccess(response));
    
  } catch (error) {
    console.log('onSaveMyConnectedCaseSettings error >> ' + error);
  }
}

function* onGetMyConnectedCaseList({ payload : data }) {
  try {
    const response = yield call(getMyCaseManagementList, data);
    yield put(getMyConnectedCaseListSuccess(response));
  } catch (error) {
    console.log('onGetMyConnectedCaseList error >> ' + error);
  }
}

function* onGetMyCaseManagementSettings() {
  try {
    const response = yield call(getMyCaseManagementSettings);
    yield put(getMyCaseManagementSettingsSuccess(response));
    
  } catch (error) {
    console.log('onGetMyCaseManagementSettings error >> ' + error);
  }
}

function* onSaveMyCaseManagementSettings({ payload : data }) {
  try {
    const response = yield call(saveMyCaseManagementSetting, data);
    yield put(saveMyCaseManagementSettingSuccess(response));
    
  } catch (error) {
    console.log('onSaveMyCaseManagementSettings error >> ' + error);
  }
}

function* onGetMyCaseManagementList({ payload : data }) {
  try {
    const response = yield call(getMyCaseManagementList, data);
    yield put(getMyCaseManagementListSuccess(response));
  } catch (error) {
    console.log('onGetMyCaseManagementList error >> ' + error);
  }
}

function* onDownMyCaseManagementExcel({ payload : data}) {
  try {
    yield call(downMyCaseManagementExcel, data);
    yield put(downMyCaseManagementExcelSuccess());
  } catch (error) {
    console.log('onDownMyCaseManagementExcel error >> ' + error);
  }
}

function* onDownMultipleMyCaseManagementExcel({ payload : data }) {
  try {
    yield call(downMultipleMyCaseManagementExcel, data);
    yield put(downMultipleMyCaseManagementExcelSuccess());

  } catch (error) {
    console.log('onDownMultipleMyCaseManagementExcel error >> ' + error);
  }
}

function* onDownMyCaseManagementPdf({ payload : data}) {
  try {
    yield call(downMyCaseManagementPdf, data);
    yield put(downMyCaseManagementPdfSuccess());
  } catch (error) {
    console.log('onDownMyCaseManagementPdf error >> ' + error);
  }
}

function* onDownMultipleMyCaseManagementPdf({ payload : data }) {
  try {
    yield call(downMultipleMyCaseManagementPdf, data);
    yield put(downMultipleMyCaseManagementPdfSuccess());
  } catch (error) {
    console.log('onDownMultipleMyCaseManagementPdf error >> ' + error);
  }
}

function* onGetUserIrInfoClaimList({ payload : data }) {
  try {
    const response = yield call(getUserIrInfoClaimList, data);
    yield put(getUserIrInfoClaimListSuccess(response));
  } catch (error) {
    console.log('onGetUserIrInfoClaimList error >> ' + error);
  }
}

function* onAddUserIrInfoClaim({ payload : data }) {
  try {
    const response = yield call(addUserIrInfoClaim, data.data);
    yield put(addUserIrInfoClaimSuccess(response));

    if (data.claimType === 'NotMycase') {  
      yield put(getMyConnectedCaseListAction(data.filterParamMyConnectedCase));
    } else {
      yield put(getMyCaseManagementListAction(data.filter));
    }
  } catch (error) {
    console.log('onAddUserIrInfoClaim error >> ' + error);
  }
}

function* onConfirmUserIrInfoClaim({ payload: data }) {
  try {
    const response = yield call(confirmUserIrInfoClaim, data.data);
    yield put(confirmUserIrInfoClaimSuccess(response));

    if (response.result === "Success") {
      yield put(getUserIrInfoClaimListAction(data.filter));
    }
  } catch (error) {
    console.log('onConfirmUserIrInfoClaim error >> ' + error);
  }
}

function* onGetTargetGroupDetailList({ payload : data }) {
  try {
    const response = yield call(getTargetGroupDetailList, data);
    yield put(getTargetGroupDetailListSuccess(response));
  } catch (error) {
    console.log('onGetTargetGroupDetailList error >> ' + error);
  }
}

function* MyCaseManagementSaga() {
  yield takeLatest(GET_MY_CONNECTED_CASE_SETTINGS, onGetMyConnectedCaseSettings);
  yield takeLatest(SAVE_MY_CONNECTED_CASE_SETTINGS, onSaveMyConnectedCaseSettings);
  yield takeLatest(GET_MY_CONNECTED_CASE_LIST, onGetMyConnectedCaseList);
  yield takeLatest(GET_MY_CASE_MANAGEMENT_SETTINGS, onGetMyCaseManagementSettings);
  yield takeLatest(SAVE_MY_CASE_MANAGEMENT_SETTINGS, onSaveMyCaseManagementSettings);
  yield takeLatest(GET_MY_CASE_MANAGEMENT_LIST, onGetMyCaseManagementList);
  yield takeLatest(DOWN_MY_CASE_MANAGEMENT_EXCEL, onDownMyCaseManagementExcel);
  yield takeLatest(DOWN_MULTIPLE_MY_CASE_MANAGEMENT_EXCEL, onDownMultipleMyCaseManagementExcel);
  yield takeLatest(DOWN_MY_CASE_MANAGEMENT_PDF, onDownMyCaseManagementPdf);
  yield takeLatest(DOWN_MULTIPLE_MY_CASE_MANAGEMENT_PDF, onDownMultipleMyCaseManagementPdf);
  yield takeLatest(GET_USER_IR_INFO_CLAIM_LIST, onGetUserIrInfoClaimList);
  yield takeLatest(ADD_USER_IR_INFO_CLAIM, onAddUserIrInfoClaim);
  yield takeLatest(CONFIRM_USER_IR_INFO_CLAIM, onConfirmUserIrInfoClaim);
  yield takeLatest(GET_TARGET_GROUP_DETAIL_LIST, onGetTargetGroupDetailList);
}

export default MyCaseManagementSaga