export const GET_SENDING_ANALYTICS_MN_SETTINGS = "GET_SENDING_ANALYTICS_MN_SETTINGS"
export const GET_SENDING_ANALYTICS_MN_SETTINGS_SUCCESS = "GET_SENDING_ANALYTICS_MN_SETTINGS_SUCCESS"
export const SAVE_SENDING_ANALYTICS_MN_SETTINGS = "SAVE_SENDING_ANALYTICS_MN_SETTINGS"
export const SAVE_SENDING_ANALYTICS_MN_SETTINGS_SUCCESS = "SAVE_SENDING_ANALYTICS_MN_SETTINGS_SUCCESS"
export const GET_SENDING_ANALYTICS_MN_LIST = "GET_SENDING_ANALYTICS_MN_LIST"
export const GET_SENDING_ANALYTICS_MN_LIST_SUCCESS = "GET_SENDING_ANALYTICS_MN_LIST_SUCCESS"
export const DOWN_SENDING_ANALYTICS_MN_EXCEL = "DOWN_SENDING_ANALYTICS_MN_EXCEL"
export const DOWN_SENDING_ANALYTICS_MN_EXCEL_SUCCESS = "DOWN_SENDING_ANALYTICS_MN_EXCEL_SUCCESS"
export const GET_SENDING_ANALYTICS_MN = "GET_SENDING_ANALYTICS_MN"
export const GET_SENDING_ANALYTICS_MN_SUCCESS = "GET_SENDING_ANALYTICS_MN_SUCCESS"

export const GET_SENDING_ANALYTICS_FR_SETTINGS = "GET_SENDING_ANALYTICS_FR_SETTINGS"
export const GET_SENDING_ANALYTICS_FR_SETTINGS_SUCCESS = "GET_SENDING_ANALYTICS_FR_SETTINGS_SUCCESS"
export const SAVE_SENDING_ANALYTICS_FR_SETTINGS = "SAVE_SENDING_ANALYTICS_FR_SETTINGS"
export const SAVE_SENDING_ANALYTICS_FR_SETTINGS_SUCCESS = "SAVE_SENDING_ANALYTICS_FR_SETTINGS_SUCCESS"
export const GET_SENDING_ANALYTICS_FR_LIST = "GET_SENDING_ANALYTICS_FR_LIST"
export const GET_SENDING_ANALYTICS_FR_LIST_SUCCESS = "GET_SENDING_ANALYTICS_FR_LIST_SUCCESS"
export const DOWN_SENDING_ANALYTICS_FR_EXCEL = "DOWN_SENDING_ANALYTICS_FR_EXCEL"
export const DOWN_SENDING_ANALYTICS_FR_EXCEL_SUCCESS = "DOWN_SENDING_ANALYTICS_FR_EXCEL_SUCCESS"

export const SET_SENDING_ANALYTICS_SUCCESS = "SET_SENDING_ANALYTICS_SUCCESS"
export const SET_SENDING_ANALYTICS_ERROR = "SET_SENDING_ANALYTICS_ERROR"

export const SET_SENDING_ANALYTICS_LOADING = "SET_SENDING_ANALYTICS_LOADING"
export const SET_SENDING_ANALYTICS_MN = "SET_SENDING_ANALYTICS_MN"

export const GET_MADRID_SENDING_ANALYTICS_STATISTICS_SETTINGS = "GET_MADRID_SENDING_ANALYTICS_STATISTICS_SETTINGS"
export const GET_MADRID_SENDING_ANALYTICS_STATISTICS_SETTINGS_SUCCESS = "GET_MADRID_SENDING_ANALYTICS_STATISTICS_SETTINGS_SUCCESS"
export const GET_MADRID_SENDING_ANALYTICS_STATISTICS = "GET_MADRID_SENDING_ANALYTICS_STATISTICS"
export const GET_MADRID_SENDING_ANALYTICS_STATISTICS_SUCCESS = "GET_MADRID_SENDING_ANALYTICS_STATISTICS_SUCCESS"
export const GET_FILER_SENDING_ANALYTICS_STATISTICS_SETTINGS = "GET_FILER_SENDING_ANALYTICS_STATISTICS_SETTINGS"
export const GET_FILER_SENDING_ANALYTICS_STATISTICS_SETTINGS_SUCCESS = "GET_FILER_SENDING_ANALYTICS_STATISTICS_SETTINGS_SUCCESS"
export const GET_FILER_SENDING_ANALYTICS_STATISTICS = "GET_FILER_SENDING_ANALYTICS_STATISTICS"
export const GET_FILER_SENDING_ANALYTICS_STATISTICS_SUCCESS = "GET_FILER_SENDING_ANALYTICS_STATISTICS_SUCCESS"

export const SET_SEARCH_SETTINGS_MN_LOADED = "SET_SEARCH_SETTINGS_MN_LOADED"
export const SET_SEARCH_SETTINGS_FR_LOADED = "SET_SEARCH_SETTINGS_FR_LOADED"