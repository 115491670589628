import {
  GET_FILER_SETTINGS,
  GET_FILER_SETTINGS_SUCCESS,
  SAVE_FILER_SETTINGS,
  SAVE_FILER_SETTINGS_SUCCESS,
  SET_SUCCESS,
  SET_RESULT,
  GET_FILER_RANKINGS,
  GET_FILER_RANKINGS_SUCCESS,
  GET_FILER_RANKING_INFO,
  GET_FILER_RANKING_INFO_SUCCESS,
  CHANGE_FILER_RANKINGS,
  MULTIPLE_HIDE_FILER,
  MULTIPLE_UNHIDE_FILER,
  MULTIPLE_STAR_FILER,
  MULTIPLE_UNSTAR_FILER,
  DOWN_FILER_EXCEL,
  DOWN_FILER_EXCEL_SUCCESS,
  DOWN_MULTIPLE_FILER_EXCEL,
  DOWN_MULTIPLE_FILER_EXCEL_SUCCESS,
  DOWN_FILER_PDF,
  DOWN_FILER_PDF_SUCCESS,
  DOWN_FILER_PDF_DATA_ONLY,
  DOWN_FILER_PDF_DATA_ONLY_SUCCESS,
  DOWN_MULTIPLE_FILER_PDF,
  DOWN_MULTIPLE_FILER_PDF_SUCCESS,
  FILER_SEND_EMAIL,
  FILER_PREVIEW_EMAIL,
  FILER_PREVIEW_EMAIL_SUCCESS,
  SET_PREVIEW_EMAIL,
  CLEAR_FILER_RANKINGS,
  UNLOCK_FILER,
  SET_FILER_LOADING,
  STAR_FILER_TARGET,
  UN_STAR_FILER_TARGET,
  HIDE_FILER_TARGET,
  UN_HIDE_FILER_TARGET,
  CHANGE_UNSUBS_INFO,
  CHANGE_FILER_RELOAD_PARAM,
  CHANGE_FILER_SEARCH_SETTINGS_COUNTRY,
} from "./actionTypes"

export const getFilerSettings = () => ({
  type: GET_FILER_SETTINGS,
})

export const getFilerSettingsSuccess = data => {
  return {
    type: GET_FILER_SETTINGS_SUCCESS,
    payload: data,
  }
}

export const saveFilerSettings = data => {
  return {
    type: SAVE_FILER_SETTINGS,
    payload: data,
  }
}

export const saveFilerSettingsSuccess = data => {
  return {
    type: SAVE_FILER_SETTINGS_SUCCESS,
    payload: data,
    
  }
}

export const setSuccess = data => {
  return {
    type: SET_SUCCESS,
    payload: data,
  }
}

export const setResult = data => {
  return {
    type: SET_RESULT,
    payload: data,
  }
}

export const getFilerRankings = data => {
  return {
    type: GET_FILER_RANKINGS,
    payload: data,
  }
}

export const getFilerRankingsSuccess = data => {
  return {
    type: GET_FILER_RANKINGS_SUCCESS,
    payload: data,
  }
}

export const getFilerRankingInfo = data => {
  return {
    type: GET_FILER_RANKING_INFO,
    payload: data,
  }
}

export const getFilerRankingInfoSuccess = data => {
  return {
    type: GET_FILER_RANKING_INFO_SUCCESS,
    payload: data,
  }
}

export const changefilerRankings = data => {
  return {
    type: CHANGE_FILER_RANKINGS,
    payload: data,
  }
}

export const multipleHideFiler = (chkList, filter) => {
  return {
    type: MULTIPLE_HIDE_FILER,
    payload: {chkList, filter},
  }
}

export const multipleUnhideFiler = (chkList, filter) => {
  return {
    type: MULTIPLE_UNHIDE_FILER,
    payload: {chkList, filter},
  }
}

export const multipleStarFiler = (chkList, filter) => {
  return {
    type: MULTIPLE_STAR_FILER,
    payload: {chkList, filter},
  }
}

export const multipleUnstarFiler = (chkList, filter) => {
  return {
    type: MULTIPLE_UNSTAR_FILER,
    payload: {chkList, filter},
  }
}

export const downFilerExcel = (data, fileName) => {
  return {
    type: DOWN_FILER_EXCEL,
    payload: {data, fileName},
  }
}

export const downFilerExcelSuccess = () => {
  return {
    type: DOWN_FILER_EXCEL_SUCCESS,
  }
}

export const downMultipleFilerExcel = (rankingList, noticeStr, fileName) => {
  return {
    type: DOWN_MULTIPLE_FILER_EXCEL,
    payload: {rankingList, noticeStr, fileName},
  }
}

export const downMultipleFilerExcelSuccess = () => {
  return {
    type: DOWN_MULTIPLE_FILER_EXCEL_SUCCESS,
  }
}

export const downFilerPdf = (data, fileName) => {
  return {
    type: DOWN_FILER_PDF,
    payload: {data, fileName},
  }
}

export const downFilerPdfSuccess = () => {
  return {
    type: DOWN_FILER_PDF_SUCCESS,
  }
}

export const downFilerPdfDataOnly = (data, fileName) => {
  return {
    type: DOWN_FILER_PDF_DATA_ONLY,
    payload: {data, fileName},
  }
}

export const downFilerPdfDataOnlySuccess = () => {
  return {
    type: DOWN_FILER_PDF_DATA_ONLY_SUCCESS,
  }
}

export const downMultipleFilerPdf = (rankingList, fileName) => {
  return {
    type: DOWN_MULTIPLE_FILER_PDF,
    payload: {rankingList, fileName},
  }
}

export const downMultipleFilerPdfSuccess = () => {
  return {
    type: DOWN_MULTIPLE_FILER_PDF_SUCCESS,
  }
}

export const filerSendEmail = (rankingList, period, filter) => {
  return {
    type: FILER_SEND_EMAIL,
    payload: {chkList : {rankingList, period}, filter},
  }
}

export const filerPreviewEmail = (rankingList, period) => {
  return {
    type: FILER_PREVIEW_EMAIL,
    payload: {rankingList, period},
  }
}

export const filerPreviewEmailSuccess = data => {
  return {
    type: FILER_PREVIEW_EMAIL_SUCCESS,
    payload: data,
  }
}

export const setPreviewEmail = data => {
  return {
    type: SET_PREVIEW_EMAIL,
    payload: data,
  }
}

export const clearFilerRankings = () => {
  return {
    type: CLEAR_FILER_RANKINGS,
  }
}

export const unlockFiler = (rankingList, filter) => {
  return {
    type: UNLOCK_FILER,
    payload: {rankingList, filter},
  }
}

export const setFilerLoading = data => {
  return {
    type: SET_FILER_LOADING,
    payload: data,
  }
}

export const starFilerTarget = (targetGroupNo, filter) => {
  return {
    type: STAR_FILER_TARGET,
    payload: {targetGroupNo, filter},
  }
}

export const unStarFilerTarget = (targetGroupNo, filter) => {
  return {
    type: UN_STAR_FILER_TARGET,
    payload: {targetGroupNo, filter},
  }
}

export const hideFilerTarget = (targetGroupNo, filter) => {
  return {
    type: HIDE_FILER_TARGET,
    payload: {targetGroupNo, filter},
  }
}

export const unHideFilerTarget = (targetGroupNo, filter) => {
  return {
    type: UN_HIDE_FILER_TARGET,
    payload: {targetGroupNo, filter},
  }
}

export const changeUnsubsInfo = data => {
  return {
    type: CHANGE_UNSUBS_INFO,
    payload: data,
  }
}

// export const changeFilerSearchSettingsCountry = (componentName, inOutType, crType, crList) => {
//   return {
//     type: CHANGE_FILER_SEARCH_SETTINGS_COUNTRY,
//     payload: {componentName, inOutType, crType, crList},
//   }
// }