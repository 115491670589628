import {
  GET_DASHBOARD_CARD_INFO,
  GET_DASHBOARD_CARD_INFO_SUCCESS,
  SEND_FEEDBACK,
  GET_WECREST_INFO,
  GET_WECREST_INFO_SUCCESS,
  SET_DASHBOARD_CARD_LOADING,
} from "./actionTypes"

export const getDashboardCardInfo = () => {
  return {
    type: GET_DASHBOARD_CARD_INFO,
  }
}

export const getDashboardCardInfoSuccess = data => {
  return {
    type: GET_DASHBOARD_CARD_INFO_SUCCESS,
    payload: data,
  }
}

export const sendFeedback = data => {
  return {
    type: SEND_FEEDBACK,
    payload: data,
  }
}

export const getWeCrestInfo = () => {
  return {
    type: GET_WECREST_INFO,
  }
}

export const getWeCrestSuccess = data => {
  return {
    type: GET_WECREST_INFO_SUCCESS,
    payload: data,
  }
}

export const setDashboardCardLoading = data => {
  return {
    type: SET_DASHBOARD_CARD_LOADING,
    payload: data,
  }
}