import React, {useEffect, useState} from 'react';
import { cloneDeep, join } from 'lodash';

import {
    Row,
    Button,
    FormFeedback,
    Input,
} from "reactstrap"
  
const PeriodBtn = props => {
    const [dateBtnList, setDateBtnList] = useState([
        {label: '1W', value: '1 weeks'},
        {label: '1M', value: '1 months'},
        {label: '3M', value: '3 months'},
        {label: '6M', value: '6 months'},
        {label: '1Y', value: '1 years'},
        {label: '2Y', value: '2 years'},
        ]);

    useEffect(() => {
        if (props.formik.values.startDatePeriod) {
            dateSetting(props.formik.values.startDatePeriod);
        }
    }, [props.formik.values.startDatePeriod]);

    useEffect(() => {
        if (props.formik.values.endDatePeriod) {
            if (props.formik.values.changeDatePeriodIsFirst) {
                setTimeout(() => dateSetting(props.formik.values.endDatePeriod), 1000);
                props.formik.setFieldValue("changeDatePeriodIsFirst", false);
            } else {
                dateSetting(props.formik.values.endDatePeriod)
            }
        }
    }, [props.formik.values.endDatePeriod]);

    useEffect(() => {
        if (props.filterName && props.filterName == 'myConnectedCase' && props.formik.values.datePeriodMyConnectedCase) {
            if (props.formik.values.changeDatePeriodIsFirst) {
                setTimeout(() => dateSetting(props.formik.values.datePeriodMyConnectedCase), 1000);
                props.formik.setFieldValue("changeDatePeriodIsFirst", false);
            } else {
                dateSetting(props.formik.values.datePeriodMyConnectedCase);
            }
        }
    }, [props.formik.values.datePeriodMyConnectedCase]);

    useEffect(() => {
        if (props.formik.values.datePeriod) {
            dateSetting(props.formik.values.datePeriod)
        }
    }, [props.formik.values.datePeriod]);

    const dateSetting = (period) => {
        let today = new Date();

        let toDate = today.getFullYear() + "-" + ("0" + (today.getMonth() + 1)).slice(-2) + "-" + ("0" +today.getDate()).slice(-2);
        
        let fromDate;
        
        if (period.slice(2,3) == 'd') {
            //today.setDate(today.getDate() - parseInt(period.slice(0,1)));
            fromDate  = today.getFullYear() + "-" + ("0" + (today.getMonth() + 1)).slice(-2) + "-" + ("0" + (today.getDate())).slice(-2);
        } else if (period.slice(2,3) == 'w') {
            today.setDate(today.getDate() - (parseInt(period.slice(0,1)*7)) + 1);
            fromDate  = today.getFullYear() + "-" + ("0" + (today.getMonth() + 1)).slice(-2) + "-" + ("0" + (today.getDate())).slice(-2);
        } else if (period.slice(2,3) == 'm') {
            today.setMonth(today.getMonth() - parseInt(period.slice(0,1)));
            today.setDate(today.getDate() + 1);
            fromDate  = today.getFullYear() + "-" + ("0" + (today.getMonth() + 1)).slice(-2) + "-" + ("0" + (today.getDate())).slice(-2);
        } else if (period.slice(2,3) == 'y'){
            today.setFullYear(today.getFullYear() - parseInt(period.slice(0,1)));
            today.setDate(today.getDate() + 1);
            fromDate  = today.getFullYear() + "-" + ("0" + (today.getMonth() + 1)).slice(-2) + "-" + ("0" + (today.getDate())).slice(-2);
        }    
        
        if (props.periodType && props.periodType === 'SubscriptionStartDate') {
            props.formik.setFieldValue("startBeginDate", fromDate);
            props.formik.setFieldValue("startEndDate", toDate);
        } else if (props.periodType && props.periodType === 'SubscriptionEndDate') {
            props.formik.setFieldValue("endBeginDate", fromDate);
            props.formik.setFieldValue("endEndDate", toDate);
        } else if (props.filterName && props.filterName === 'myConnectedCase') {
            props.formik.setFieldValue("fromDateMyConnectedCase", fromDate);
            props.formik.setFieldValue("toDateMyConnectedCase", toDate);
            // console.log('dateSetting myConnectedCase fromDate : ' + fromDate + ', toDate : ' + toDate);
            // console.log('props.formik.values.fromDate : ' + props.formik.values.fromDate + ', props.formik.values.toDate : ' + props.formik.values.toDate);
        } else {
            props.formik.setFieldValue("fromDate", fromDate);
            props.formik.setFieldValue("toDate", toDate);
            // console.log('dateSetting default date : ' + fromDate);
            // console.log('props.formik.values.fromDateMyConnectedCase : ' + props.formik.values.fromDateMyConnectedCase + ', props.formik.values.toDateMyConnectedCase : ' + props.formik.values.toDateMyConnectedCase);
        }
    }

    return (
        <>
            <Row className="mb-3">
                <div className="button-items" style={{paddingRight : '0'}}>
                    {
                        dateBtnList && dateBtnList.length ?
                        dateBtnList.map((btn, index) => {
                                return (
                                    <Button 
                                    type="button"
                                    color="secondary" outline 
                                    className="waves-effect"
                                    style={{width: "117px"}}
                                    active={(props.periodType && props.periodType === 'SubscriptionStartDate') ? props.formik.values.startDatePeriod === btn.value
                                            : (props.periodType && props.periodType === 'SubscriptionEndDate') ? props.formik.values.endDatePeriod === btn.value
                                            : (props.filterName && props.filterName === 'myConnectedCase') ? props.formik.values.datePeriodMyConnectedCase === btn.value
                                            : props.formik.values.datePeriod === btn.value}
                                    onClick={() => {
                                        if (props.periodType && props.periodType === 'SubscriptionStartDate') {
                                            props.formik.setFieldValue("startDatePeriod", btn.value);
                                        } else if (props.periodType && props.periodType === 'SubscriptionEndDate') {
                                            props.formik.setFieldValue("endDatePeriod", btn.value);
                                        } else if(props.filterName && props.filterName === 'myConnectedCase') {
                                            props.formik.setFieldValue("datePeriodMyConnectedCase", btn.value);
                                        } else {
                                            props.formik.setFieldValue("datePeriod", btn.value);
                                        }
                                    }}>
                                        {btn.label}
                                    </Button>
                                )
                            })
                            :
                            null
                    }
                </div>
            </Row>
            <Row className="mb-3">
                <label
                htmlFor="fromDate"
                className="col-md-1 col-form-label"
                >
                From
                </label>
                <div className="col-md-2">
                <Input
                    className="form-control"
                    type="date"
                    id={props.periodType && props.periodType === 'SubscriptionStartDate' ? "startBeginDate" : props.periodType && props.periodType === 'SubscriptionEndDate' ? "endBeginDate" : props.filterName && props.filterName === 'myConnectedCase' ? "fromDateMyConnectedCase" : "fromDate"}
                    name={props.periodType && props.periodType === 'SubscriptionStartDate' ? "startBeginDate" : props.periodType && props.periodType === 'SubscriptionEndDate' ? "endBeginDate" : props.filterName && props.filterName === 'myConnectedCase' ? "fromDateMyConnectedCase" : "fromDate"}
                    onChange={(e) => {
                        if (props.periodType && props.periodType === 'SubscriptionStartDate') {
                            props.formik.setFieldValue("startBeginDate", e.target.value);
                            props.formik.setFieldValue("startDatePeriod", "");
                        } else if (props.periodType && props.periodType === 'SubscriptionEndDate') {
                            props.formik.setFieldValue("endBeginDate", e.target.value);
                            props.formik.setFieldValue("endDatePeriod", "");
                        } else if (props.filterName && props.filterName === 'myConnectedCase') {
                            props.formik.setFieldValue("fromDateMyConnectedCase", e.target.value);
                            props.formik.setFieldValue("datePeriodMyConnectedCase", "");
                        } else {
                            props.formik.setFieldValue("fromDate", e.target.value);
                            props.formik.setFieldValue("datePeriod", "");
                        }
                    }}
                    value={props.periodType && props.periodType === 'SubscriptionStartDate' ? props.formik.values.startBeginDate : props.periodType && props.periodType === 'SubscriptionEndDate' ? props.formik.values.endBeginDate : props.filterName && props.filterName === 'myConnectedCase' ? props.formik.values.fromDateMyConnectedCase : props.formik.values.fromDate}
                    invalid={
                        (props.formik.touched.startBeginDate && props.formik.errors.startBeginDate)
                        || (props.formik.touched.endBeginDate && props.formik.errors.endBeginDate)
                        || (props.filterName && props.filterName === 'myConnectedCase' && props.formik.touched.fromDateMyConnectedCase && props.formik.errors.fromDateMyConnectedCase)
                        || ((!props.filterName || props.filterName !== 'myConnectedCase') && props.formik.touched.fromDate && props.formik.errors.fromDate)
                          ? true
                          : false
                      }
                    onPaste={(e) => {
                        // console.log("onpaste :: " + e.clipboardData.getData('Text'));
                        try {
                            let checkDate = String(e.clipboardData.getData('Text'));
                            if (checkDate.indexOf('-') > -1 && checkDate.length == 10) {
                                if (props.periodType && props.periodType === 'SubscriptionStartDate') {
                                    props.formik.setFieldValue("startBeginDate", checkDate);
                                } else if (props.periodType && props.periodType === 'SubscriptionEndDate') {
                                    props.formik.setFieldValue("endBeginDate", checkDate);
                                } else if (props.filterName && props.filterName === 'myConnectedCase') {
                                    props.formik.setFieldValue("fromDateMyConnectedCase", checkDate);
                                } else {
                                    props.formik.setFieldValue("fromDate", checkDate);
                                }
                            } else if (checkDate.length == 8) {
                                if (props.periodType && props.periodType === 'SubscriptionStartDate') {
                                    props.formik.setFieldValue("startBeginDate", checkDate.substring(0,4) + '-' + checkDate.substring(4,6) + '-' + checkDate.substring(6,8));
                                } else if (props.periodType && props.periodType === 'SubscriptionEndDate') {
                                    props.formik.setFieldValue("endBeginDate", checkDate.substring(0,4) + '-' + checkDate.substring(4,6) + '-' + checkDate.substring(6,8));
                                } else if (props.filterName && props.filterName === 'myConnectedCase') {
                                    props.formik.setFieldValue("fromDateMyConnectedCase", checkDate.substring(0,4) + '-' + checkDate.substring(4,6) + '-' + checkDate.substring(6,8));
                                } else {
                                    props.formik.setFieldValue("fromDate", checkDate.substring(0,4) + '-' + checkDate.substring(4,6) + '-' + checkDate.substring(6,8));
                                }
                            }
                        } catch (error) {
                            console.log("error :: " + error);
                        }
                    }}
                    />
                    {props.formik.touched.startBeginDate &&
                    props.formik.errors.startBeginDate ? (
                      <FormFeedback type="invalid">
                        {props.formik.errors.startBeginDate}
                      </FormFeedback>
                    ) : null}
                    {props.formik.touched.endBeginDate &&
                    props.formik.errors.endBeginDate ? (
                      <FormFeedback type="invalid">
                        {props.formik.errors.endBeginDate}
                      </FormFeedback>
                    ) : null}
                    {props.filterName && props.filterName === 'myConnectedCase' &&
                    props.formik.touched.fromDateMyConnectedCase &&
                    props.formik.errors.fromDateMyConnectedCase ? (
                      <FormFeedback type="invalid">
                        {props.formik.errors.fromDateMyConnectedCase}
                      </FormFeedback>
                    ) : null}
                    {(!props.filterName || props.filterName !== 'myConnectedCase') &&
                    props.formik.touched.fromDate &&
                    props.formik.errors.fromDate ? (
                      <FormFeedback type="invalid">
                        {props.formik.errors.fromDate}
                      </FormFeedback>
                    ) : null}
                </div>
                <label
                htmlFor="toDate"
                className="col-md-1 col-form-label"
                >
                To
                </label>
                <div className="col-md-2">
                <Input
                    className="form-control"
                    type="date"
                    id={props.periodType && props.periodType === 'SubscriptionStartDate' ? "startEndDate" : props.periodType && props.periodType === 'SubscriptionEndDate' ? "endEndDate" : props.filterName && props.filterName === 'myConnectedCase' ? "toDateMyConnectedCase" : "toDate"}
                    name={props.periodType && props.periodType === 'SubscriptionStartDate' ? "startEndDate" : props.periodType && props.periodType === 'SubscriptionEndDate' ? "endEndDate" : props.filterName && props.filterName === 'myConnectedCase' ? "toDateMyConnectedCase" : "toDate"}
                    onChange={(e) => {
                        if (props.periodType && props.periodType === 'SubscriptionStartDate') {
                            props.formik.setFieldValue("startEndDate", e.target.value);
                            props.formik.setFieldValue("startDatePeriod", "");
                        } else if (props.periodType && props.periodType === 'SubscriptionEndDate') {
                            props.formik.setFieldValue("endEndDate", e.target.value);
                            props.formik.setFieldValue("endDatePeriod", "");
                        } else if (props.filterName && props.filterName === 'myConnectedCase') {
                            props.formik.setFieldValue("toDateMyConnectedCase", e.target.value);
                            props.formik.setFieldValue("datePeriodMyConnectedCase", "");
                        } else {
                            props.formik.setFieldValue("toDate", e.target.value);
                            props.formik.setFieldValue("datePeriod", "");
                        }
                    }}
                    value={props.periodType && props.periodType === 'SubscriptionStartDate' ? props.formik.values.startEndDate : props.periodType && props.periodType === 'SubscriptionEndDate' ? props.formik.values.endEndDate : props.filterName && props.filterName === 'myConnectedCase' ? props.formik.values.toDateMyConnectedCase : props.formik.values.toDate}
                    invalid={
                        (props.formik.touched.startEndDate && props.formik.errors.startEndDate)
                        || (props.formik.touched.endEndDate && props.formik.errors.endEndDate)
                        || (props.filterName && props.filterName === 'myConnectedCase' && props.formik.touched.toDateMyConnectedCase && props.formik.errors.toDateMyConnectedCase)
                        || ((!props.filterName || props.filterName !== 'myConnectedCase') && props.formik.touched.toDate && props.formik.errors.toDate)
                          ? true
                          : false
                      }
                    onPaste={(e) => {
                        try {
                            let checkDate = String(e.clipboardData.getData('Text'));
                            if (checkDate.indexOf('-') > -1 && checkDate.length == 10) {
                                if (props.periodType && props.periodType === 'SubscriptionStartDate') {
                                    props.formik.setFieldValue("startEndDate", checkDate);
                                } else if (props.periodType && props.periodType === 'SubscriptionEndDate') {
                                    props.formik.setFieldValue("endEndDate", checkDate);
                                } else if (props.filterName && props.filterName === 'myConnectedCase') {
                                    props.formik.setFieldValue("toDateMyConnectedCase", checkDate);
                                } else {
                                    props.formik.setFieldValue("toDate", checkDate);
                                }
                            } else if (checkDate.length == 8) {
                                if (props.periodType && props.periodType === 'SubscriptionStartDate') {
                                    props.formik.setFieldValue("startEndDate", checkDate.substring(0,4) + '-' + checkDate.substring(4,6) + '-' + checkDate.substring(6,8));
                                } else if (props.periodType && props.periodType === 'SubscriptionEndDate') {
                                    props.formik.setFieldValue("endEndDate", checkDate.substring(0,4) + '-' + checkDate.substring(4,6) + '-' + checkDate.substring(6,8));
                                } else if (props.filterName && props.filterName === 'myConnectedCase') {
                                    props.formik.setFieldValue("toDateMyConnectedCase", checkDate.substring(0,4) + '-' + checkDate.substring(4,6) + '-' + checkDate.substring(6,8));
                                } else {
                                    props.formik.setFieldValue("toDate", checkDate.substring(0,4) + '-' + checkDate.substring(4,6) + '-' + checkDate.substring(6,8));
                                }
                            }
                        } catch (error) {
                            console.log("error :: " + error);
                        }
                    }}
                />
                    {props.formik.touched.startEndDate &&
                    props.formik.errors.startEndDate ? (
                      <FormFeedback type="invalid">
                        {props.formik.errors.startEndDate}
                      </FormFeedback>
                    ) : null}
                    {props.formik.touched.endEndDate &&
                    props.formik.errors.endEndDate ? (
                      <FormFeedback type="invalid">
                        {props.formik.errors.endEndDate}
                      </FormFeedback>
                    ) : null}
                    {props.filterName && props.filterName === 'myConnectedCase' &&
                    props.formik.touched.toDateMyConnectedCase &&
                    props.formik.errors.toDateMyConnectedCase ? (
                      <FormFeedback type="invalid">
                        {props.formik.errors.toDateMyConnectedCase}
                      </FormFeedback>
                    ) : null}
                    {(!props.filterName || props.filterName !== 'myConnectedCase') &&
                    props.formik.touched.toDate &&
                    props.formik.errors.toDate ? (
                      <FormFeedback type="invalid">
                        {props.formik.errors.toDate}
                      </FormFeedback>
                    ) : null}
                </div>
            </Row>
        </>
    )
};

export default PeriodBtn;