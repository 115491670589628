import {
  GET_SPECIFIC_SEARCH_LIST,
  GET_SPECIFIC_SEARCH_LIST_SUCCESS,
  DOWN_SPECIFIC_SEARCH_EXCEL,
  DOWN_SPECIFIC_SEARCH_EXCEL_SUCCESS,
  DOWN_SPECIFIC_SEARCH_PDF,
  DOWN_SPECIFIC_SEARCH_PDF_SUCCESS,
  CLEAR_SPECIFIC_SEARCH,
} from "./actionTypes"

const initialState = {
  error: "",
  loading: false,
  yearIntervalList: [],
  monthIntervalList: [],
  crList: [],
  intervalCrMap: new Map(),
  noticeStr: "",
}

const specificSearch = (state = initialState, action) => {
  switch (action.type) {
    case GET_SPECIFIC_SEARCH_LIST:
      return {
        ...state,
        loading: true,
        yearIntervalList: [],
        monthIntervalList: [],
      }
    case GET_SPECIFIC_SEARCH_LIST_SUCCESS:
      return {
        ...state,
        yearIntervalList: action.payload.yearIntervalList,
        monthIntervalList: action.payload.monthIntervalList,
        crList: action.payload.crList,
        intervalCrMap: action.payload.intervalCrMap,
        noticeStr: action.payload.noticeStr,
        loading: false
      }
    case DOWN_SPECIFIC_SEARCH_EXCEL:
      return {
        ...state,
        loading: true
      }
    case DOWN_SPECIFIC_SEARCH_EXCEL_SUCCESS:
      return {
        ...state,
        loading: false
      }
    case DOWN_SPECIFIC_SEARCH_PDF:
      return {
        ...state,
        loading: true
      }
    case DOWN_SPECIFIC_SEARCH_PDF_SUCCESS:
      return {
        ...state,
        loading: false
      }
    case CLEAR_SPECIFIC_SEARCH:
      return {
        ...state,
        yearIntervalList: [],
        monthIntervalList: [],
        crList: [],
        intervalCrMap: new Map(),
        noticeStr: "",
      }
  }
  return state
}

export default specificSearch
