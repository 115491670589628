import { call, put, takeEvery, takeLatest } from "redux-saga/effects"

// MadridNotifier Redux States
import { 
  GET_CONNECTABLE_ENTITY_LIST,
  GET_MY_ENTITY_LIST,
  GET_MAIN_DOMAIN,
  GET_SEARCH_ENTITY_LIST,
  GET_USER_ENTITY_CONNECT_LIST,
  DOWN_EXCEL_USER_ENTITY_CONNECT_LIST,
  CONFIRM_USER_ENTITY_CONNECT,
  DISCONNECT_USER_ENTITY,
  GET_USER_ENTITY_CONNECT_CLAIM_LIST,
  DOWN_EXCEL_USER_ENTITY_CONNECT_CLAIM_LIST,
  ADD_USER_ENTITY_CONNECT_CLAIM,
  MODIFY_USER_ENTITY_CONNECT_CLAIM_STATUS,
  CONFIRM_USER_ENTITY_CONNECT_CLAIM,
  GET_REQUESTER_LIST,
  GET_USER_DOMAIN_LIST,
  ADD_USER_DOMAIN,
  MODIFY_USER_DOMAIN,
  REMOVE_USER_DOMAIN,
} from "./actionTypes"
import { 
  getConnectableEntityListSuccess,
  getConnectableEntityList as getConnectableEntityListAction,
  getMyEntityList as getMyEntityListAction,
  getMyEntityListSuccess,
  getMainDomain as getMainDomainAction,
  getMainDomainSuccess,
  getSearchEntityListSuccess,
  getSearchEntityList as getSearchEntityListAction,
  getUserEntityConnectList as getUserEntityConnectListAction,
  getUserEntityConnectListSuccess,
  downExcelUserEntityConnectListSuccess,
  confirmUserEntityConnectSuccess,
  disConnectUserEntitySuccess,
  getUserEntityConnectClaimList as getUserEntityConnectClaimListAction,
  getUserEntityConnectClaimListSuccess,
  downExcelUserEntityConnectClaimListSuccess,
  addUserEntityConnectClaimSuccess,
  modifyUserEntityConnectClaimStatusSuccess,
  confirmUserEntityConnectClaimSuccess,
  getRequesterListSuccess,
  getUserDomainList as getUserDomainListAction,
  getUserDomainListSuccess,
  addUserDomainSuccess,
  modifyUserDomainSuccess,
  removeUserDomainSuccess,
  setEntityConnectManagementResult,
  setEntityConnectManagementLoading,
} from "./actions"


//Include Both Helper File with needed methods
import {
  getConnectableEntityList,
  getMyEntityList,
  getMainDomain,
  getSearchEntityList,
  getUserEntityConnectList,
  downExcelUserEntityConnectList,
  confirmUserEntityConnect,
  disConnectUserEntity,
  getUserEntityConnectClaimList,
  downExcelUserEntityConnectClaimList,
  addUserEntityConnectClaim,
  modifyUserEntityConnectClaimStatus,
  confirmUserEntityConnectClaim,
  getRequester,
  getUserDomainList,
  addUserDomain,
  modifyUserDomain,
  removeUserDomain,
} from "../../helpers/backend_helper"

function* onGetConnectableEntityList({ payload : data }) {
  try {
    const response = yield call(getConnectableEntityList, data);
    yield put(getConnectableEntityListSuccess(response));
    
  } catch (error) {
    console.log('onGetConnectableEntityList error >> ' + error);
  }
}

function* onGetMyEntityList({ payload : data }) {
  try {
    const response = yield call(getMyEntityList, data);
    yield put(getMyEntityListSuccess(response));
    
  } catch (error) {
    console.log('onGetMyEntityList error >> ' + error);
  }
}

function* onGetMainDomain({ payload : data }) {
  try {
    const response = yield call(getMainDomain, data);
    yield put(getMainDomainSuccess(response));
    
  } catch (error) {
    console.log('onGetMainDomain error >> ' + error);
  }
}

function* onGetSearchEntityList({ payload : data }) {
  try {
    const response = yield call(getSearchEntityList, data);
    yield put(getSearchEntityListSuccess(response));
    
  } catch (error) {
    console.log('onGetSearchEntityList error >> ' + error);
  }
}

function* onGetUserEntityConnectList({ payload : data }) {
  try {
    const response = yield call(getUserEntityConnectList, data);
    yield put(getUserEntityConnectListSuccess(response));
    
  } catch (error) {
    console.log('onGetUserEntityConnectList error >> ' + error);
  }
}

function* onDownExcelUserEntityConnectList({ payload : data}) {
  try {
    yield call(downExcelUserEntityConnectList, data);
    yield put(downExcelUserEntityConnectListSuccess());

  } catch (error) {
    console.log('onDownExcelUserEntityConnectList error >> ' + error);
  }
}

function* onConfirmUserEntityConnect({ payload : data }) {
  try {
    const response = yield call(confirmUserEntityConnect, data);
    yield put(confirmUserEntityConnectSuccess(response));
    if (response.result === 'Success') {
      yield put(getConnectableEntityListAction());
    }
    
  } catch (error) {
    console.log('onConfirmUserEntityConnect error >> ' + error);
  }
}

function* onDisConnectUserEntity({ payload : data }) {
  try {
    const response = yield call(disConnectUserEntity, data);
    yield put(disConnectUserEntitySuccess(response));
    if (response.result === 'Success') {
      yield put(getConnectableEntityListAction());
    }
    
  } catch (error) {
    console.log('onDisConnectUserEntity error >> ' + error);
  }
}

function* onGetUserEntityConnectClaimList({ payload : data }) {
  try {
    const response = yield call(getUserEntityConnectClaimList, data);
    yield put(getUserEntityConnectClaimListSuccess(response));
    
  } catch (error) {
    console.log('onGetUserEntityConnectClaimList error >> ' + error);
  }
}

function* onDownExcelUserEntityConnectClaimList({ payload : data}) {
  try {
    yield call(downExcelUserEntityConnectClaimList, data);
    yield put(downExcelUserEntityConnectClaimListSuccess());

  } catch (error) {
    console.log('onDownExcelUserEntityConnectClaimList error >> ' + error);
  }
}

function* onAddUserEntityConnectClaim({ payload : data }) {
  try {
    const response = yield call(addUserEntityConnectClaim, data.data);
    yield put(addUserEntityConnectClaimSuccess(response));

    if (data.data.type === 'Connect' || data.data.type === 'IDNM' || data.data.type === 'IDC' || data.data.type === 'CWAA') {  
      yield put(getConnectableEntityListAction());
      yield put(getSearchEntityListAction(data.filter));
    } else if (data.data.type === 'CUD') {
      yield put(getUserDomainListAction());
    } else if (data.data.type === 'Disconnect') {
      if (data.data.claimListName === 'connectableEntityList') {
        yield put(getConnectableEntityListAction());
      } else if (data.data.claimListName === 'userEntityConnectList') {
        yield put(getSearchEntityListAction(data.filter));
      } else {
        yield put(getMyEntityListAction(data.myEntityFilterParam));
      }
    }
  } catch (error) {
    console.log('onAddUserEntityConnectClaim error >> ' + error);
  }
}

function* onModifyUserEntityConnectClaimStatus({ payload : data }) {
  try {
    const response = yield call(modifyUserEntityConnectClaimStatus, data.data);
    yield put(modifyUserEntityConnectClaimStatusSuccess(response));

    if (response.result === "Success") {
      yield put(getUserEntityConnectClaimListAction(data.filter));
    }
    
  } catch (error) {
    console.log('onModifyUserEntityConnectClaimStatus error >> ' + error);
  }
}

function* onConfirmUserEntityConnectClaim({ payload: data }) {
  try {
    const response = yield call(confirmUserEntityConnectClaim, data.data);
    yield put(confirmUserEntityConnectClaimSuccess(response));

    if (response.result === "Success") {
      yield put(getUserEntityConnectClaimListAction(data.filter));
    }
    
  } catch (error) {
    console.log('onConfirmUserEntityConnectClaim error >> ' + error);
  }
}

function* onGetRequesterList({ payload: data }) {
  try {
    const response = yield call(getRequester, data);
    yield put(getRequesterListSuccess(response));
  } catch (error) {
    console.log('onGetRequesterList error >> ' + error);
  }
}

function* onGetUserDomainList({ payload : data }) {
  try {
    const response = yield call(getUserDomainList, data);
    yield put(getUserDomainListSuccess(response));
    
  } catch (error) {
    console.log('onGetUserDomainList error >> ' + error);
  }
}

function* onAddUserDomain({ payload : data }) {
  try {
    const response = yield call(addUserDomain, data);

    if (response.result === 'Success') {
      yield put(addUserDomainSuccess(response.result));
      yield put(getUserDomainListAction());
      yield put(getConnectableEntityListAction());
    } else {
      yield put(setEntityConnectManagementLoading(false));
      yield put(setEntityConnectManagementResult(response.result));
    }
  } catch (error) {
    console.log('onAddUserDomain error >> ' + error);
  }
}

function* onModifyUserDomain({ payload : data }) {
  try {
    const response = yield call(modifyUserDomain, data);

    if (response.result === 'Success') {
      yield put(modifyUserDomainSuccess(response.result));
      yield put(getUserDomainListAction());
      yield put(getConnectableEntityListAction());
    } else {
      yield put(setEntityConnectManagementLoading(false));
      yield put(setEntityConnectManagementResult(response.result));
    }
  } catch (error) {
    console.log('onModifyUserDomain error >> ' + error);
  }
}

function* onRemoveUserDomain({ payload : data }) {
  try {
    const response = yield call(removeUserDomain, data);

    if (response.result === 'Success') {
      yield put(removeUserDomainSuccess(response.result));
      yield put(getUserDomainListAction());
      yield put(getConnectableEntityListAction());
    } else {
      yield put(setEntityConnectManagementLoading(false));
      yield put(setEntityConnectManagementResult(response.result));
    }
  } catch (error) {
    console.log('onModifyUserDomain error >> ' + error);
  }
}

function* EntityConnectManagementSaga() {
  yield takeLatest(GET_CONNECTABLE_ENTITY_LIST, onGetConnectableEntityList);
  yield takeLatest(GET_MY_ENTITY_LIST, onGetMyEntityList);
  yield takeLatest(GET_MAIN_DOMAIN, onGetMainDomain);
  yield takeLatest(GET_SEARCH_ENTITY_LIST, onGetSearchEntityList);
  yield takeLatest(GET_USER_ENTITY_CONNECT_LIST, onGetUserEntityConnectList);
  yield takeLatest(DOWN_EXCEL_USER_ENTITY_CONNECT_LIST, onDownExcelUserEntityConnectList);
  yield takeLatest(CONFIRM_USER_ENTITY_CONNECT, onConfirmUserEntityConnect);
  yield takeLatest(DISCONNECT_USER_ENTITY, onDisConnectUserEntity);
  yield takeLatest(GET_USER_ENTITY_CONNECT_CLAIM_LIST, onGetUserEntityConnectClaimList);
  yield takeLatest(DOWN_EXCEL_USER_ENTITY_CONNECT_CLAIM_LIST, onDownExcelUserEntityConnectClaimList);
  yield takeLatest(ADD_USER_ENTITY_CONNECT_CLAIM, onAddUserEntityConnectClaim);
  yield takeLatest(MODIFY_USER_ENTITY_CONNECT_CLAIM_STATUS, onModifyUserEntityConnectClaimStatus);
  yield takeLatest(CONFIRM_USER_ENTITY_CONNECT_CLAIM, onConfirmUserEntityConnectClaim);
  yield takeLatest(GET_REQUESTER_LIST, onGetRequesterList);
  yield takeLatest(GET_USER_DOMAIN_LIST, onGetUserDomainList);
  yield takeLatest(ADD_USER_DOMAIN, onAddUserDomain);
  yield takeLatest(MODIFY_USER_DOMAIN, onModifyUserDomain);
  yield takeLatest(REMOVE_USER_DOMAIN, onRemoveUserDomain);
}

export default EntityConnectManagementSaga