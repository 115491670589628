import {
  LOGIN_USER,
  LOGIN_SUCCESS,
  LOGOUT_USER,
  LOGOUT_USER_SUCCESS,
  OPEN_TC_AGREE_MODAL,
  CLOSE_TC_AGREE_MODAL,
  API_ERROR,
  SOCIAL_LOGIN,
  SESSION_LOGOUT,
  CHECK_COOKIE_AGREE,
  CHECK_COOKIE_AGREE_SUCCESS,
  UPDATE_COOKIE_AGREE,
  UPDATE_COOKIE_AGREE_SUCCESS,
  SET_LOGIN_LOADING,
} from "./actionTypes"

export const loginUser = (user, history) => {
  // console.log('action loginUser');
  return {
    type: LOGIN_USER,
    payload: { user, history },
  }
}

export const loginSuccess = (status, data, history) => {
  // console.log('action loginSuccess');
  return {
    type: LOGIN_SUCCESS,
    payload: {status, data, history},
  }
}

export const logoutUser = history => {
  return {
    type: LOGOUT_USER,
    payload: { history },
  }
}

export const logoutUserSuccess = () => {
  return {
    type: LOGOUT_USER_SUCCESS,
    payload: {},
  }
}

export const apiError = error => {
  return {
    type: API_ERROR,
    payload: error,
  }
}

export const socialLogin = (data, history, type) => {
  return {
    type: SOCIAL_LOGIN,
    payload: { data, history, type },
  }
}

export const sessionLogout = () => {
  return {
    type: SESSION_LOGOUT,
  }
}

export const openTcAgreeModal = () => {
  return {
    type: OPEN_TC_AGREE_MODAL,
  }
}

export const closeTcAgreeModal = () => {
  return {
    type: CLOSE_TC_AGREE_MODAL,
  }
}

export const checkCookieAgree = () => {
  return {
    type: CHECK_COOKIE_AGREE,
  }
}

export const checkCookieAgreeSuccess = (agreeCookie) => {
  return {
    type: CHECK_COOKIE_AGREE_SUCCESS,
    payload: agreeCookie,
  }
}

export const updateCookieAgree = user => {
  return {
    type: UPDATE_COOKIE_AGREE,
  }
}

export const updateCookieAgreeSuccess = (agreeCookie) => {
  return {
    type: UPDATE_COOKIE_AGREE_SUCCESS,
    payload: agreeCookie,
  }
}

export const setLoginLoading = data => {
  return {
    type: SET_LOGIN_LOADING,
    payload: data,
  }
}