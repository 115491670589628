import PropTypes from 'prop-types';
import React from "react";
import { Col, Card, CardBody, Row } from "reactstrap";

const DashboardCard = props => {
  return (
    <React.Fragment>
      {props.reports.map((report, key) => (
        <Col md={4} xl={2} key={key}>
          <Card style={{height : '90%'}}>
            <CardBody style={{textAlign : 'center'}}>
              <div>
                <h4 className="mb-1 mt-1"><span>{report.prefix}{report.value}{report.suffix}</span></h4>
                <p className="text-muted mb-0">{report.title}</p>
              </div>
              <p className="text-muted mt-3 mb-0">
                <span className={"text-" + report.color + " me-1"}><i className={report.icon + " me-1"}></i>{report.badgeValue}</span><br/>
                {report.desc}
              </p>
            </CardBody>
          </Card>
        </Col>
      ))}
      <Col md={4} xl={2} >
          <Card style={{height : '90%'}}>
            <CardBody style={{textAlign : 'center'}}>
              <Row>
                <Col className="col-6">
                    <div className="social-source text-center">
                        <div className="avatar-xs mx-auto mb-2">
                            <span className="avatar-title rounded-circle font-size-16" style={{backgroundColor : '#0e76a8'}}>
                                <a href="https://www.linkedin.com/company/wecrest" target="_blank" rel="noreferrer"><i className="mdi mdi-linkedin text-white"></i></a>
                            </span>
                        </div>
                        <a href="https://www.linkedin.com/company/wecrest" target="_blank" rel="noreferrer"><h5 className="font-size-13">LinkedIn</h5></a>
                    </div>
                </Col>
                <Col className="col-6">
                    <div className="social-source text-center">
                        <div className="avatar-xs mx-auto mb-2">
                            <span className="avatar-title rounded-circle font-size-16" style={{backgroundColor : '#FF0000'}}>
                                <a href="https://www.youtube.com/channel/UC664URSE4rYqCmFXkqD0eww" target="_blank" rel="noreferrer"><i className="mdi mdi-youtube text-white"></i></a>
                            </span>
                        </div>
                        <a href="https://www.youtube.com/channel/UC664URSE4rYqCmFXkqD0eww" target="_blank" rel="noreferrer"><h5 className="font-size-13">YouTube</h5></a>
                    </div>
                </Col>
              </Row>
              <Row>
                <Col className="col-6">
                    <div className="social-source text-center mt-3">
                        <div className="avatar-xs mx-auto mb-2">
                            <span className="avatar-title rounded-circle bg-pink font-size-16">
                                <a href="https://www.instagram.com/wecrest" target="_blank" rel="noreferrer"><i className="mdi mdi-instagram text-white"></i></a>
                            </span>
                        </div>
                        <a href="https://www.instagram.com/wecrest" target="_blank" rel="noreferrer"><h5 className="font-size-13">Instagram</h5></a>
                    </div>
                </Col>
                <Col className="col-6">
                    <div className="social-source text-center mt-3">
                        <div className="avatar-xs mx-auto mb-2">
                            <span className="avatar-title rounded-circle font-size-16" style={{backgroundColor : '#3b5998'}}>
                                <a href="https://www.facebook.com/wecrest" target="_blank" rel="noreferrer"><i className="mdi mdi-facebook text-white"></i></a>
                            </span>
                        </div>
                        <a href="https://www.facebook.com/wecrest" target="_blank" rel="noreferrer"><h5 className="font-size-13">Facebook</h5></a>
                    </div>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
    </React.Fragment>
  );
};

export default DashboardCard;

DashboardCard.propTypes = {
  reports: PropTypes.array
};