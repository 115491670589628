import React, {useState, useEffect} from 'react';
import { useSelector, useDispatch } from "react-redux";
import {
    Table,
    Row,
    Col,
    Card,
    CardBody,
    Media,
    Collapse,
    Button,
    UncontrolledTooltip,
  } from "reactstrap"
import Period4MBtn from '../../components/Filter/Period4MBtn';
import * as Yup from "yup";
import { useFormik } from "formik";
import SearchName from '../../components/Filter/SearchName';

import {
  getFilerSendingAnalyticsStatisticsSettings,
  saveSendingAnalyticsFrSettings,
  getFilerSendingAnalyticsStatistics,
  setSendingAnalyticsError,
  changeSearchNameList as onChangeSearchNameList,
  changeSearchNameSelectedList as onChangeSearchNameSelectedList,
  setSearchSettingsFrLoaded as onSetSearchSettingsFrLoaded,
} from "../../store/actions";
import '../../components/Common/Common.css';

const FilerSendingAnalyticsStatistics = props => {
  const dispatch = useDispatch();

  const { searchSettingsFrStatistics, searchSettingsFrStatisticsLoaded, filerSendingAnalyticsStatistics, } = useSelector(state => ({
    searchSettingsFrStatistics: state.SendingAnalytics.searchSettingsFrStatistics,
    searchSettingsFrStatisticsLoaded: state.SendingAnalytics.searchSettingsFrStatisticsLoaded,
    filerSendingAnalyticsStatistics: state.SendingAnalytics.filerSendingAnalyticsStatistics,
  }));

  const [isOpen, setIsOpen] = useState(true);
  const toggle = () => {
    setIsOpen(!isOpen);
  }

  const filterValidation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      inOutType: "IN",
      submitType: "view",
      inOutTypeFr: searchSettingsFrStatistics && searchSettingsFrStatistics.inOutType? searchSettingsFrStatistics.inOutType : "IN",
      searchNoList: searchSettingsFrStatistics && searchSettingsFrStatistics.entityList? searchSettingsFrStatistics.entityList : [],
      searchKeyword: searchSettingsFrStatistics && searchSettingsFrStatistics.searchKeyword? searchSettingsFrStatistics.searchKeyword : "",
      searchNameList: [],
      datePeriod: searchSettingsFrStatistics && searchSettingsFrStatistics.datePeriod? searchSettingsFrStatistics.datePeriod : "",
      startDate: searchSettingsFrStatistics && searchSettingsFrStatistics.startDate? searchSettingsFrStatistics.startDate : undefined,
      endDate: searchSettingsFrStatistics && searchSettingsFrStatistics.endDate? searchSettingsFrStatistics.endDate : undefined,
      screenGrade: "Free",
      screenName: "FilerSendingAnalyticsStatistics",
    },
    validationSchema: Yup.object().shape({
      startDate:Yup.date()
              .max(Yup.ref('endDate'), "From date must be earlier than to date")
              .required("Please Select From date")
      ,
      endDate: Yup.date()
              .max(new Date(), "Date cannot be in the future")
              .required("Please Select to date"),
    }),
    validateOnChange: false, // this one
    validateOnBlur: false, // and this one
    onSubmit: (values) => {

      const filterParam = {
        inOutType: values["inOutTypeFr"],
        entityList: (values["searchNoList"] === '' ? [] : values["searchNoList"]),
        datePeriod: values["datePeriod"],
        startDate: values["startDate"],
        endDate: values["endDate"],
        screenType : 'STATISTICS',
      };

      if (!filterParam.startDate || filterParam.startDate === '' || !filterParam.endDate || filterParam.endDate === '') {
        console.log("onSubmit >>> filterValidation.values.startDate : " + filterValidation.values.startDate + ", filterValidation.values.endDate : " + filterValidation.values.endDate)
        return;
      }

      let chkStartDate = new Date(filterParam.startDate);
      let chkEndDate = new Date(filterParam.endDate);

      if ((chkEndDate.getTime() - chkStartDate.getTime()) / (1000*60*60*24) > 125) {
        dispatch(setSendingAnalyticsError("Select up to 125 days per search"));
        return;
      }
      
      let submitType = values["submitType"];
      
      if(submitType === "view"){
        dispatch(getFilerSendingAnalyticsStatistics(filterParam));
      } else if(submitType === "saveSettings"){

        // console.log('datePeriod : ' + values["datePeriod"]);

        const settingsParam = {
          inOutType: values["inOutTypeFr"],
          entityList: (values["searchNoList"] === '' ? [] : values["searchNoList"]),
          searchKeyword: values["searchKeyword"],
          datePeriod: values["datePeriod"],
          startDate: values["datePeriod"] === '' ? values["startDate"] : '',
          endDate: values["datePeriod"] === '' ? values["endDate"] : '',
          screenType : 'STATISTICS',
        };
        dispatch(saveSendingAnalyticsFrSettings(settingsParam));
      }
    },
  })

  useEffect(() => { 
    dispatch(getFilerSendingAnalyticsStatisticsSettings({screenType : 'STATISTICS'}))

    return () => {
      dispatch(onChangeSearchNameList([], filterValidation.values.screenName));
      dispatch(onChangeSearchNameSelectedList([], filterValidation.values.screenName));
    };
  }, []);

  useEffect(() => {
    // console.log("STATISTICS filterValidation.values.startDate change >>> " + filterValidation.values.startDate + ", searchSettingsFrStatisticsLoaded : " + searchSettingsFrStatisticsLoaded);
    if (searchSettingsFrStatisticsLoaded && filterValidation.values.startDate != undefined && filterValidation.values.startDate != '') {
      // console.log("filterValidation.values.startDate change >>> ");
      dispatch(onSetSearchSettingsFrLoaded(false, 'STATISTICS'));

      filterValidation.setFieldValue("submitType", "view");
      filterValidation.handleSubmit(filterValidation.values);
    }
    
  },[filterValidation.values.startDate])

  // useEffect(() => {
  //   console.log("check >>> searchSettingsFrStatisticsLoaded : " + searchSettingsFrStatisticsLoaded + ", filterValidation.values.startDate : " + filterValidation.values.startDate + ", filterValidation.values.endDate : " + filterValidation.values.endDate)
  //   if (searchSettingsFrStatisticsLoaded && filterValidation.values.startDate != undefined && filterValidation.values.startDate != '') {
  //     console.log("searchSettingsFrStatisticsLoaded change >>> searchSettingsFrStatisticsLoaded : " + searchSettingsFrStatisticsLoaded + ", filterValidation.values.startDate : " + filterValidation.values.startDate + ", filterValidation.values.endDate : " + filterValidation.values.endDate)
  //     dispatch(onSetSearchSettingsFrLoaded(false));

  //     filterValidation.setFieldValue("submitType", "view");
  //     filterValidation.handleSubmit(filterValidation.values);
  //   }
    
  // },[searchSettingsFrStatisticsLoaded])

  return (
    <React.Fragment>
      <Row>
        <Col lg={12}>
          <Card>
            <div className="p-4">
              <Media className="d-flex align-items-center">
                <div className="flex-shrink-0 me-3">
                  <i className="uil uil-envelope-info text-primary h2"></i>
                </div>
                <div className="flex-grow-1 overflow-hidden">
                  <h5 className="font-size-16 mb-1">Statistics</h5>
                  {/*
                  <p className="text-warning mb-0">Sending Analytics is currently under maintenance and will not be available until further notice. We apologize for the inconvenience and will inform you when Sending Analytics is available again.</p>
                  */}
                  <p className="text-muted mb-0">View a summary of your email performance with WeCrest. Use this information to measure the effectiveness of your email campaigns and make data-driven decisions to improve your future emails. 
                  <br/>In order to improve the your email deliverability, please visit <a href="https://www.wecrest.com/insights" target="_blank" rel="noreferrer">our blog</a> for more ideas.</p>
                </div>
                <i onClick={toggle} className="uil uil-filter font-size-24 text-muted" style={{ cursor: "pointer"}}></i>
              </Media>
            </div>
            <Collapse isOpen={isOpen}>
            <div className="p-4 border-top">
              <Row className="mb-3">
                <div className="col-md-2">
                  <div className="form-check">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="inOutTypeFr"
                    id="showInboundMn"
                    onClick={()=>{
                      filterValidation.setFieldValue("inOutType", "IN");
                      filterValidation.setFieldValue("inOutTypeFr", "IN");
                    }}
                    value="IN"
                    checked={filterValidation.values.inOutTypeFr === 'IN'}
                  />
                  <label
                    className="form-check-label"
                    htmlFor="showInboundMn"
                  >
                    Inbound
                  </label>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="form-check">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="inOutTypeFr"
                    id="showOutboundMn"
                    onClick={()=>{
                      filterValidation.setFieldValue("inOutType", "OUT");
                      filterValidation.setFieldValue("inOutTypeFr", "OUT");
                    }}
                    value="OUT"
                    checked={filterValidation.values.inOutTypeFr === 'OUT'}
                  />
                  <label
                    className="form-check-label"
                    htmlFor="showOutboundMn"
                  >
                    Outbound
                  </label>
                  </div>
                </div>
              </Row>
              
              <SearchName 
                formik={filterValidation} 
                firstColMd={2}
                labelName='Entity'
              />

              <Row>
                <label htmlFor="inscribedDate" className="col-md-2 col-form-label" style={{paddingTop : '0px'}}>
                  Sent date
                </label>
                <div className="col-md-9">
                  <Period4MBtn
                    formik={filterValidation}
                  />
                </div>
              </Row>
              <Row>
                <div className="p-4 border-top col-md-12" style={{borderRight : "1px solid #f5f6f8!important"}}>
                  <Row className="mb-3">
                    <span className="col-md-6 button-items">
                      <Button
                        type="button"
                        color="primary"
                        outline
                        className="waves-effect waves-light"
                        onClick={()=>{
                          filterValidation.setFieldValue("submitType", "saveSettings");
                          filterValidation.handleSubmit(filterValidation.values);
                        }}
                      >
                        Save settings
                      </Button>
                      <Button
                        type="button"
                        color="primary"
                        outline
                        className="waves-effect waves-light"
                        onClick={()=>{
                          filterValidation.setFieldValue("inOutType", "IN");
                          filterValidation.setFieldValue("inOutTypeFr", "IN");
                          filterValidation.setFieldValue("searchNoList", []);
                          filterValidation.setFieldValue("searchKeyword", "");
                          filterValidation.setFieldValue("datePeriod", "");
                          filterValidation.setFieldValue("startDate", "");
                          filterValidation.setFieldValue("endDate", "");
                          dispatch(onChangeSearchNameList([], filterValidation.values.screenName));
                          dispatch(onChangeSearchNameSelectedList([], filterValidation.values.screenName));
                        }}
                      >
                        Clear filters
                      </Button>
                    </span>
                    <Col md={3} style={{paddingLeft : '1.3em'}}>
                      <button
                        type="button"
                        className="waves-effect waves-light btn btn-primary"
                        style={{width : '148.58px'}}
                        onClick={()=>{
                          filterValidation.setFieldValue("submitType", "view");
                          filterValidation.handleSubmit(filterValidation.values);
                        }}
                      >
                        Search
                      </button>
                    </Col>
                  </Row>
                </div>
              </Row>
              </div>
            </Collapse>
          </Card>
        </Col>
      </Row>
      {filerSendingAnalyticsStatistics && Object.keys(filerSendingAnalyticsStatistics).length !== 0 ?
      <Row>
        <Col lg={12}>
          <Card>
            <CardBody>
              <div className="table-responsive">
                <Table className="table mb-0">
                  <thead>
                    <tr>
                      <th style={{position: "relative", whiteSpace: "pre-line", textAlign: "center"}}>
                      Last updated date (UTC+9)
                      </th>
                      <th colSpan={2} style={{position: "relative", whiteSpace: "pre-line", textAlign: "center"}}>
                      {filerSendingAnalyticsStatistics.LAST_UPDATED_DATE}
                      </th>
                    </tr>
                    <tr>
                      <th style={{position: "relative", whiteSpace: "pre-line", textAlign: "center"}}>
                      Status
                      </th>
                      <th style={{position: "relative", whiteSpace: "pre-line", textAlign: "center", width: "25%"}}>
                      Emails
                      </th>
                      <th style={{position: "relative", whiteSpace: "pre-line", textAlign: "center", width: "25%"}}>
                      Percentage
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="text-danger fw-bold">Sent <br/>** All percentages based on the the number of “Sent” emails.</td>
                      <td className="text-danger fw-bold" align="center">{filerSendingAnalyticsStatistics.SENT_CNT}</td>
                      <td className="text-danger fw-bold" align="center">{filerSendingAnalyticsStatistics.SENT_RATE}%</td>
                    </tr>
                    <tr>
                      <td className="text-success fw-bold">
                        <a id="Delivered">
                        Delivered <i className="uil uil-question-circle font-size-18"></i>
                        </a>
                        <UncontrolledTooltip placement="right" target="Delivered">
                        Delivered + Opened + Clicked + Complaint + Unsubscribed
                        </UncontrolledTooltip>
                      </td>
                      <td className="text-success fw-bold" align="center">{filerSendingAnalyticsStatistics.DELIVERED_CNT}</td>
                      <td className="text-success fw-bold" align="center">{filerSendingAnalyticsStatistics.DELIVERED_RATE}%</td>
                    </tr>
                    <tr>
                      <td>
                        <a id="Opened">- Opened <i className="uil uil-question-circle font-size-18"></i>
                        </a>
                        <UncontrolledTooltip placement="right" target="Opened">
                        Opened + Clicked + Complaint + Unsubscribed
                        </UncontrolledTooltip>
                      </td>
                      <td align="center">{filerSendingAnalyticsStatistics.OPEN_CNT}</td>
                      <td align="center">{filerSendingAnalyticsStatistics.OPEN_RATE}%</td>
                    </tr>
                    <tr>
                      <td>
                      - Clicked
                      </td>
                      <td align="center">{filerSendingAnalyticsStatistics.CLICK_CNT}</td>
                      <td align="center">{filerSendingAnalyticsStatistics.CLICK_RATE}%</td>
                    </tr>
                    <tr>
                      <td>
                      - Complaint
                      </td>
                      <td align="center">{filerSendingAnalyticsStatistics.COMPLAINT_CNT}</td>
                      <td align="center">{filerSendingAnalyticsStatistics.COMPLAINT_RATE}%</td>
                    </tr>
                    <tr>
                      <td>
                      - Unsubscribed
                      </td>
                      <td align="center">{filerSendingAnalyticsStatistics.UNSUBSCRIBE_CNT}</td>
                      <td align="center">{filerSendingAnalyticsStatistics.UNSUBSCRIBE_RATE}%</td>
                    </tr>
                    <tr>
                      <td className="text-success fw-bold">
                      Not delivered
                      </td>
                      <td className="text-success fw-bold" align="center">{filerSendingAnalyticsStatistics.NOT_DELIVERED_CNT}</td>
                      <td className="text-success fw-bold" align="center">{filerSendingAnalyticsStatistics.NOT_DELIVERED_RATE}%</td>
                    </tr>
                    <tr>
                      <td>
                      - Soft bounced
                      </td>
                      <td align="center">{filerSendingAnalyticsStatistics.SOFT_BOUNCE_CNT}</td>
                      <td align="center">{filerSendingAnalyticsStatistics.SOFT_BOUNCE_RATE}%</td>
                    </tr>
                    <tr>
                      <td>
                      - Hard bounced
                      </td>
                      <td align="center">{filerSendingAnalyticsStatistics.HARD_BOUNCE_CNT}</td>
                      <td align="center">{filerSendingAnalyticsStatistics.HARD_BOUNCE_RATE}%</td>
                    </tr>
                    <tr>
                      <td>
                      - Invalid email
                      </td>
                      <td align="center">{filerSendingAnalyticsStatistics.INVALID_EMAIL_CNT}</td>
                      <td align="center">{filerSendingAnalyticsStatistics.INVALID_EMAIL_RATE}%</td>
                    </tr>
                    <tr>
                      <td className="text-success fw-bold">
                      Pending
                      </td>
                      <td className="text-success fw-bold" align="center">{filerSendingAnalyticsStatistics.PENDING_CNT}</td>
                      <td className="text-success fw-bold" align="center">{filerSendingAnalyticsStatistics.PENDING_RATE}%</td>
                    </tr>
                    <tr><td colSpan={3}>&nbsp;</td></tr>
                    <tr>
                      <td className="text-danger fw-bold">Not Sent</td>
                      <td className="text-danger fw-bold" align="center">{filerSendingAnalyticsStatistics.NOT_SENT_CNT}</td>
                      <td className="text-danger fw-bold" align="center"></td>
                    </tr>
                    <tr>
                      <td className="text-success fw-bold">
                      Pending
                      </td>
                      <td className="text-success fw-bold" align="center">{filerSendingAnalyticsStatistics.NOT_SENT_PENDING_CNT}</td>
                      <td className="text-success fw-bold" align="center"></td>
                    </tr>
                    <tr>
                      <td className="text-success fw-bold">
                      Blocked
                      </td>
                      <td className="text-success fw-bold" align="center">{filerSendingAnalyticsStatistics.BLOCKED_CNT}</td>
                      <td className="text-success fw-bold" align="center"></td>
                    </tr>
                    <tr>
                      <td className="text-success fw-bold">
                      Error
                      </td>
                      <td className="text-success fw-bold" align="center">{filerSendingAnalyticsStatistics.ERROR_CNT}</td>
                      <td className="text-success fw-bold" align="center"></td>
                    </tr>
                  </tbody>
                </Table>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
      :null}

    </React.Fragment>
  )
}

export default FilerSendingAnalyticsStatistics;