import React, {useState, useEffect, forwardRef, useImperativeHandle} from 'react';
import { useSelector, useDispatch } from "react-redux";

import {
    Row,
    Tooltip,
    Col,
    Label,
    Modal,
} from "reactstrap"

import {
    getJurisdictionsMonthlyPricing as onGetJurisdictionsMonthlyPricing,
    changeJurisdictionsMonthlyPricing as onChangeJurisdictionsMonthlyPricing,
    setMembershipPlanValue,
} from "../../store/actions";
import { cloneDeep } from 'lodash';
  
const JurisdictionsMonthlyPricing = forwardRef((props, ref) => {
    const dispatch = useDispatch();

    const { jurisdictionList, orgUserSubscriptionList} = useSelector(state => ({
        jurisdictionList: state.MembershipPlan.jurisdictionList,
        orgUserSubscriptionList: state.MembershipPlan.orgUserSubscriptionList,
    }));

    const [firstCheckJur, setFirstCheckJur] = useState(false);
    const [modal_standard, setmodal_standard] = useState(false);
    const [chkAllFlag, setChkAllFlag] = useState(false);
    const [nonEUFlag, setNonEUFlag] = useState(false);
    let chkAll = "All";
    let allSubs = "All-subs";
    let nonEU = "Non-eu";
    let individual = "Individual";

    function removeBodyCss() {
        document.body.classList.add("no_padding")
    }
    
    const openCountryModal = () => {
        if (!firstCheckJur) {
            firstJurCheck();
        }

        setmodal_standard(!modal_standard);
        removeBodyCss();
    }

    const search = (keyword) => {
        keyword = keyword.toLowerCase();

        const _jurisdictionList = [];
        jurisdictionList.map((jur, index) =>{
            let _jur = cloneDeep(jur);

            if(jur.crCode.toLowerCase().includes(keyword) == true || jur.crName.toLowerCase().includes(keyword) == true){
            _jur.show = true;
            }else{
            _jur.show = false;
            }

            _jurisdictionList.push(_jur);
        }); 

        dispatch(onChangeJurisdictionsMonthlyPricing(_jurisdictionList));
    }

    const firstJurCheck = () => {
        // console.log('firstJurCheck running');

        let chkCnt = 0;
        let nonEuCnt = 0;
        let chkNonEuCnt = 0;

        const _jurisdictionList = [];
        jurisdictionList.map((jur, index) =>{
            let _jur = cloneDeep(jur);

            orgUserSubscriptionList.map((subsJur, index) =>{
                if (_jur.crCode == subsJur.crCode) {
                    _jur.checked = true;
                    return;
                }
            });

            if(_jur.checked == true){
                chkCnt++;
                if(jur.euYn == 'N'){
                    chkNonEuCnt++;
                }
            }

            if(jur.euYn == 'N'){
                nonEuCnt++;
            }

            _jurisdictionList.push(_jur);
        }); 

        if(nonEuCnt == chkNonEuCnt && chkNonEuCnt == chkCnt){
            setNonEUFlag(true);
        }else{
            setNonEUFlag(false);
        }
        
        if(jurisdictionList.length == chkCnt){
            setChkAllFlag(true);
        }else{
            setChkAllFlag(false);
        }

        setFirstCheckJur(true);

        dispatch(onChangeJurisdictionsMonthlyPricing(_jurisdictionList));
    }

    const jurCheck = (str, checked) => {
        let chkCnt = 0;
        let nonEuCnt = 0;
        let chkNonEuCnt = 0;

        const _jurisdictionList = [];
        jurisdictionList.map((jur, index) =>{
            let _jur = cloneDeep(jur);

            if((str == chkAll 
                || (str == nonEU && checked == false))
                && jur.show){
                _jur.checked = checked;
            }else if(str == nonEU && checked == true && jur.show){
                if(jur.euYn == 'N'){
                    _jur.checked = true;
                }else{
                    _jur.checked = false;
                }
            }else{
                if(jur.crCode == str){
                    _jur.checked = checked;
                }
            }

            if(_jur.checked == true){
                chkCnt++;
                if(jur.euYn == 'N'){
                    chkNonEuCnt++;
                }
            }

            if(jur.euYn == 'N'){
                nonEuCnt++;
            }

            _jurisdictionList.push(_jur);
        }); 

        if(nonEuCnt == chkNonEuCnt && chkNonEuCnt == chkCnt){
            setNonEUFlag(true);
        }else{
            setNonEUFlag(false);
        }
        
        if(jurisdictionList.length == chkCnt){
            setChkAllFlag(true);
        }else{
            setChkAllFlag(false);
        }

        dispatch(onChangeJurisdictionsMonthlyPricing(_jurisdictionList));
    }

    const jurConfirm = () => {
        let jurCheckCnt = 0;
        let sameCrCnt = 0;
        let _newUserSubscriptionList = [];

        jurisdictionList.map((jur, index) =>{
            if(jur.checked == true){
                _newUserSubscriptionList.push({'crCode' : jur.crCode, 'crName' : jur.crName, 'monthly_1m' : jur.monthly_1m, 'annually_1m' : jur.annually_1m,});
                jurCheckCnt++;

                orgUserSubscriptionList.map((subs, index2) =>{
                    if (jur.crCode == subs.crCode) {
                        sameCrCnt++;
                        return;
                    }
                });
            }
        });

        // console.log('sameCrCnt : ' + sameCrCnt + ', orgUserSubscriptionList.length : ' + orgUserSubscriptionList.length);

        dispatch(setMembershipPlanValue('USER_SUBSCRIPTION_LIST', _newUserSubscriptionList));
        dispatch(setMembershipPlanValue('CHANGE_SUBSCRIPTION_FLAG', jurCheckCnt != sameCrCnt ? true : false));
        
        openCountryModal();
    }

    useEffect(()=> {
        dispatch(onGetJurisdictionsMonthlyPricing({inOutType : 'IN'}));
    },[]);

    useImperativeHandle(ref, () => ({
        jurCheck,
    }));

    return (
        <>
        {
            props.manageGoldPlan && props.paypalPlanId == '' ?
            <Row className="mb-3" style={{textAlign: "center"}}>
                <a className="a-href-underline" style={{ cursor: "pointer"}}
                    onClick={() => {
                            openCountryModal();
                        }}>
                Change jurisdictions</a>
            </Row>
            : null
        }

        <Modal
            isOpen={modal_standard}
            toggle={() => {
            openCountryModal()
            }}
            scrollable={true}
        >
            <div className="modal-header" style={{marginRight: "20px"}}>
                <h5 className="modal-title mt-0" id="myModalLabel">
                    Select jurisdictions
                </h5>
                <div className="app-search" style={{padding : '0'}}>
                    <div className="position-relative">
                    <input
                        type="text"
                        className="form-control"
                        style={{border: "solid 1px"}}
                        placeholder="Search..."
                        onKeyPress={(e) => {
                            if(e.key === 'Enter'){
                                search(e.target.value);
                            }                      
                        }}
                    />
                    <span className="uil-search"></span>
                    </div>
                </div>
                <button
                    type="button"
                    onClick={() => {
                        openCountryModal(false)
                    }}
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                >
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div className="modal-body">
                <Row style={{margin : 'auto'}}>
                    <div className="col-md-3 form-check mb-2">
                        <input
                        type="checkbox"
                        className="form-check-input"
                        id={chkAll}
                        checked={chkAllFlag}
                        onClick={(e)=>jurCheck(chkAll, e.target.checked)}
                        />
                        <Label
                        className="form-check-label"
                        htmlFor={chkAll}
                        >
                        Select all
                        </Label>
                    </div>
                    <div className="col-md-3 form-check mb-2">
                        <input
                        type="checkbox"
                        className="form-check-input"
                        id={nonEU}
                        checked={nonEUFlag}
                        onClick={(e)=>jurCheck(nonEU, e.target.checked)}
                        />
                        <Label
                        className="form-check-label"
                        htmlFor={nonEU}
                        >
                        Non-EU
                        </Label>
                    </div>
                </Row>
                {
                    jurisdictionList && jurisdictionList.length ?
                    jurisdictionList.map((jur, index) => {
                        return (
                            jur.show? 
                            <div className="form-check mb-2">
                                <input
                                type="checkbox"
                                className="form-check-input"
                                id={jur.crCode}
                                checked={jur.checked}
                                onClick={(e)=>jurCheck(jur.crCode, e.target.checked)}
                                />
                                <Label
                                className="form-check-label"
                                htmlFor={jur.crCode}
                                >
                                {jur.crCode} {jur.crName}
                                </Label>
                            </div>
                            
                            : null
                        )
                    })
                    :
                    null
                }
            
            </div>
            <div className="modal-footer">
                <button
                    type="button"
                    onClick={() => {
                        openCountryModal()
                    }}
                    className="btn btn-secondary waves-effect"
                    data-dismiss="modal"
                >
                    Cancel
                </button>
                <button
                    type="button"
                    className="btn btn-primary waves-effect waves-light"
                    onClick={()=> jurConfirm()}
                >
                    Confirm
                </button>
            </div>
        </Modal>
        </>
    )
});

export default JurisdictionsMonthlyPricing;