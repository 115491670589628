import {
  GET_SENDING_ANALYTICS_MN_SETTINGS,
  GET_SENDING_ANALYTICS_MN_SETTINGS_SUCCESS,
  SAVE_SENDING_ANALYTICS_MN_SETTINGS,
  SAVE_SENDING_ANALYTICS_MN_SETTINGS_SUCCESS,
  GET_SENDING_ANALYTICS_MN_LIST,
  GET_SENDING_ANALYTICS_MN_LIST_SUCCESS,
  DOWN_SENDING_ANALYTICS_MN_EXCEL,
  DOWN_SENDING_ANALYTICS_MN_EXCEL_SUCCESS,
  GET_SENDING_ANALYTICS_MN,
  GET_SENDING_ANALYTICS_MN_SUCCESS,
  GET_SENDING_ANALYTICS_FR_SETTINGS,
  GET_SENDING_ANALYTICS_FR_SETTINGS_SUCCESS,
  SAVE_SENDING_ANALYTICS_FR_SETTINGS,
  SAVE_SENDING_ANALYTICS_FR_SETTINGS_SUCCESS,
  GET_SENDING_ANALYTICS_FR_LIST,
  GET_SENDING_ANALYTICS_FR_LIST_SUCCESS,
  DOWN_SENDING_ANALYTICS_FR_EXCEL,
  DOWN_SENDING_ANALYTICS_FR_EXCEL_SUCCESS,
  SET_SENDING_ANALYTICS_SUCCESS,
  SET_SENDING_ANALYTICS_ERROR,
  SET_SENDING_ANALYTICS_LOADING,
  SET_SENDING_ANALYTICS_MN,
  GET_MADRID_SENDING_ANALYTICS_STATISTICS_SETTINGS,
  GET_MADRID_SENDING_ANALYTICS_STATISTICS_SETTINGS_SUCCESS,
  GET_MADRID_SENDING_ANALYTICS_STATISTICS,
  GET_MADRID_SENDING_ANALYTICS_STATISTICS_SUCCESS,
  GET_FILER_SENDING_ANALYTICS_STATISTICS_SETTINGS,
  GET_FILER_SENDING_ANALYTICS_STATISTICS_SETTINGS_SUCCESS,
  GET_FILER_SENDING_ANALYTICS_STATISTICS,
  GET_FILER_SENDING_ANALYTICS_STATISTICS_SUCCESS,
  SET_SEARCH_SETTINGS_MN_LOADED,
  SET_SEARCH_SETTINGS_FR_LOADED,
} from "./actionTypes"

export const getSendingAnalyticsMnSettings = data => ({
  type: GET_SENDING_ANALYTICS_MN_SETTINGS,
  payload: data,
})

export const getSendingAnalyticsMnSettingsSuccess = data => {
  return {
    type: GET_SENDING_ANALYTICS_MN_SETTINGS_SUCCESS,
    payload: data,
  }
}

export const saveSendingAnalyticsMnSettings = data => {
  return {
    type: SAVE_SENDING_ANALYTICS_MN_SETTINGS,
    payload: data,
  }
}

export const saveSendingAnalyticsMnSettingsSuccess = data => {
  return {
    type: SAVE_SENDING_ANALYTICS_MN_SETTINGS_SUCCESS,
    payload: data,
  }
}

export const getSendingAnalyticsMnList = data => {
  return {
    type: GET_SENDING_ANALYTICS_MN_LIST,
    payload: data,
  }
}

export const getSendingAnalyticsMnListSuccess = data => {
  return {
    type: GET_SENDING_ANALYTICS_MN_LIST_SUCCESS,
    payload: data,
  }
}

export const downSendingAnalyticsMnExcel = (data, fileName) => {
  return {
    type: DOWN_SENDING_ANALYTICS_MN_EXCEL,
    payload: {data, fileName},
  }
}

export const downSendingAnalyticsMnExcelSuccess = data => {
  return {
    type: DOWN_SENDING_ANALYTICS_MN_EXCEL_SUCCESS,
    payload: data,
  }
}

export const getSendingAnalyticsMn = data => {
  return {
    type: GET_SENDING_ANALYTICS_MN,
    payload: data,
  }
}

export const getSendingAnalyticsMnSuccess = data => {
  return {
    type: GET_SENDING_ANALYTICS_MN_SUCCESS,
    payload: data,
  }
}

export const getSendingAnalyticsFrSettings = data => ({
  type: GET_SENDING_ANALYTICS_FR_SETTINGS,
  payload: data,
})

export const getSendingAnalyticsFrSettingsSuccess = data => {
  return {
    type: GET_SENDING_ANALYTICS_FR_SETTINGS_SUCCESS,
    payload: data,
  }
}

export const saveSendingAnalyticsFrSettings = data => {
  return {
    type: SAVE_SENDING_ANALYTICS_FR_SETTINGS,
    payload: data,
  }
}

export const saveSendingAnalyticsFrSettingsSuccess = data => {
  return {
    type: SAVE_SENDING_ANALYTICS_FR_SETTINGS_SUCCESS,
    payload: data,
  }
}

export const getSendingAnalyticsFrList = data => {
  return {
    type: GET_SENDING_ANALYTICS_FR_LIST,
    payload: data,
  }
}

export const getSendingAnalyticsFrListSuccess = data => {
  return {
    type: GET_SENDING_ANALYTICS_FR_LIST_SUCCESS,
    payload: data,
  }
}

export const downSendingAnalyticsFrExcel = (data, fileName) => {
  return {
    type: DOWN_SENDING_ANALYTICS_FR_EXCEL,
    payload: {data, fileName},
  }
}

export const downSendingAnalyticsFrExcelSuccess = data => {
  return {
    type: DOWN_SENDING_ANALYTICS_FR_EXCEL_SUCCESS,
    payload: data,
  }
}

export const setSendingAnalyticsSuccess = data => {
  return {
    type: SET_SENDING_ANALYTICS_SUCCESS,
    payload: data,
  }
}

export const setSendingAnalyticsError = data => {
  return {
    type: SET_SENDING_ANALYTICS_ERROR,
    payload: data,
  }
}

export const setSendingAnalyticsLoading = data => {
  return {
    type: SET_SENDING_ANALYTICS_LOADING,
    payload: data,
  }
}

export const setSendingAnalyticsMn = data => {
  return {
    type: SET_SENDING_ANALYTICS_MN,
    payload: data,
  }
}

export const getMadridSendingAnalyticsStatisticsSettings = data => ({
  type: GET_MADRID_SENDING_ANALYTICS_STATISTICS_SETTINGS,
  payload: data,
})

export const getMadridSendingAnalyticsStatisticsSettingsSuccess = data => {
  return {
    type: GET_MADRID_SENDING_ANALYTICS_STATISTICS_SETTINGS_SUCCESS,
    payload: data,
  }
}

export const getMadridSendingAnalyticsStatistics = data => {
  return {
    type: GET_MADRID_SENDING_ANALYTICS_STATISTICS,
    payload: data,
  }
}

export const getMadridSendingAnalyticsStatisticsSuccess = data => {
  return {
    type: GET_MADRID_SENDING_ANALYTICS_STATISTICS_SUCCESS,
    payload: data,
  }
}

export const getFilerSendingAnalyticsStatisticsSettings = data => ({
  type: GET_FILER_SENDING_ANALYTICS_STATISTICS_SETTINGS,
  payload: data,
})

export const getFilerSendingAnalyticsStatisticsSettingsSuccess = data => {
  return {
    type: GET_FILER_SENDING_ANALYTICS_STATISTICS_SETTINGS_SUCCESS,
    payload: data,
  }
}

export const getFilerSendingAnalyticsStatistics = data => {
  return {
    type: GET_FILER_SENDING_ANALYTICS_STATISTICS,
    payload: data,
  }
}

export const getFilerSendingAnalyticsStatisticsSuccess = data => {
  return {
    type: GET_FILER_SENDING_ANALYTICS_STATISTICS_SUCCESS,
    payload: data,
  }
}

export const setSearchSettingsMnLoaded = (data, screenType) => {
  return {
    type: SET_SEARCH_SETTINGS_MN_LOADED,
    payload: {data, screenType},
  }
}

export const setSearchSettingsFrLoaded = (data, screenType) => {
  return {
    type: SET_SEARCH_SETTINGS_FR_LOADED,
    payload: {data, screenType},
  }
}