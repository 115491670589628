import React, {useState, useEffect} from 'react';
import { useSelector, useDispatch } from "react-redux";

import {
    Row,
    Tooltip,
    Col,
    Label,
    Modal,
} from "reactstrap"

import {
    getSearchUserList as onGetSearchUserList,
    changeSearchUserList as onChangeSearchUserList,
    changeAdmInvoiceManagementUserInfo as onChangeAdmInvoiceManagementUserInfo,
    changeAdmInvoiceManagementItemList as onChangeAdmInvoiceManagementItemList,
    getUserInvoiceInfoList as onGetUserInvoiceInfoList,
} from "../../store/actions";
import { cloneDeep } from 'lodash';
  
const SearchUser = props => {
    const dispatch = useDispatch();

    const { searchUserList, searchUserMessage, admInvoiceManagementUserInfo} = useSelector(state => ({
        searchUserList: state.AdmInvoiceManagement.searchUserList,
        searchUserMessage: state.AdmInvoiceManagement.searchUserMessage,
        admInvoiceManagementUserInfo: state.AdmInvoiceManagement.admInvoiceManagementUserInfo,
    }));

    const [tright, settright] = useState(false);
    const [modal_standard, setmodal_standard] = useState(false);
    const [keyword, setKeyword] = useState("");

    function removeBodyCss() {
        document.body.classList.add("no_padding")
    }
    
    const openModal = () => {
        setmodal_standard(!modal_standard);
        removeBodyCss();
    }

    const search = (searchKeyword) => {
        dispatch(onGetSearchUserList({ memberKeyword : searchKeyword}));
    }

    const targetCheck = (userNo) => {
        let _searchUserList = [];

        searchUserList.map((target, index) => {
            let _target = cloneDeep(target);

            if(target.userNo === userNo){
                _target.checked = true;
            } else {
                _target.checked = false;
            }

            _searchUserList.push(_target);
        }); 

        dispatch(onChangeSearchUserList(_searchUserList));
    }

    const targetConfirm = () => {
        let _selectedUserInfo = {};

        searchUserList.map((target, index) => {
            if(target.checked === true){
                _selectedUserInfo = cloneDeep(target);
            }
        });

        let _nowDate = new Date();

        _selectedUserInfo.invoiceNo = "000000000";
        _selectedUserInfo.invoiceName = "";// _selectedUserInfo.invoiceFirstName + " " + _selectedUserInfo.invoiceLastName;
        _selectedUserInfo.invoiceFirstName = "";
        _selectedUserInfo.invoiceLastName = "";
        _selectedUserInfo.invoiceCompany = "";
        _selectedUserInfo.invoiceAddress = "";
        _selectedUserInfo.invoiceEmail = "";
        _selectedUserInfo.taxId = "";
        _selectedUserInfo.importantInfo = "";
        _selectedUserInfo.invoiceDate = _nowDate.getFullYear() 
        + '-' + (_nowDate.getMonth() + 1 < 10 ? '0' + (_nowDate.getMonth() + 1) : _nowDate.getMonth() + 1) 
        + '-' + (_nowDate.getDate() < 10 ? '0' + _nowDate.getDate() : _nowDate.getDate());

        let invoiceDeadline = new Date();
        invoiceDeadline.setDate(invoiceDeadline.getDate() + 7);

        _selectedUserInfo.deadline = invoiceDeadline.getFullYear() 
        + '-' + (invoiceDeadline.getMonth() + 1 < 10 ? '0' + (invoiceDeadline.getMonth() + 1) : invoiceDeadline.getMonth() + 1) 
        + '-' + (invoiceDeadline.getDate() < 10 ? '0' + invoiceDeadline.getDate() : invoiceDeadline.getDate());
        
        _selectedUserInfo.pricingType = "NEW";
        _selectedUserInfo.currency = "USD";

        _selectedUserInfo.finalDcDesc = '';
        _selectedUserInfo.finalDcAmt = 0;
        _selectedUserInfo.finalDcRate = 0;
        _selectedUserInfo.addFeeDesc = '';
        _selectedUserInfo.addFee = 0;
        _selectedUserInfo.total = 0;

        _selectedUserInfo.amountPaid = '';
        _selectedUserInfo.dateOfPayment = '';
        _selectedUserInfo.balance = '';
        _selectedUserInfo.invoiceStatus = 'Outstanding';
        _selectedUserInfo.paymentMethod = 'Paypal';
        _selectedUserInfo.paymentType = 'Onetime';
        _selectedUserInfo.paypalPlanID = '';
        _selectedUserInfo.paypalSubscriptionID = '';
        _selectedUserInfo.sendEmailFlag = 'Y';
        _selectedUserInfo.downloadInvoiceFlag = 'Y';
        _selectedUserInfo.sendInvoiceFlag = 'Y';

        dispatch(onGetUserInvoiceInfoList({userNo:_selectedUserInfo.userNo}));
        dispatch(onChangeAdmInvoiceManagementUserInfo(_selectedUserInfo));
        dispatch(onChangeAdmInvoiceManagementItemList([{
            gradeStr:'Gold'
            , type:'Member'
            , inOutType:'IN'
            , crCode:''
            , term:'annually'
            , startDate:''
            , endDate:''
            , cost:''
            , dcDesc:''
            , dcAmt:''
            , standardFee:''
            , addDcDesc:''
            , addDcAmt:''
            , subtotal:''
        }]))

        openModal();
    }

    return (
        <>
        <Row className="mb-3">
            <label className="col-md-3 col-form-label">
            Username
            </label>
            <div className="col-md-4">
            <input
                className="form-control"
                type="text"
                placeholder="Username"
                name="username"
                onClick={() => {
                    openModal();
                }}
                value={admInvoiceManagementUserInfo.userName ? admInvoiceManagementUserInfo.userName : ''}
            />
            </div>
            <Col md={3}>
                <Tooltip
                    placement="right"
                    isOpen={tright}
                    target="SearchUser"
                    toggle={() => {
                        settright(!tright)
                    }}
                >
                    Search User
                </Tooltip>
                <button
                    type="button"
                    className="btn btn-primary waves-effect waves-light"
                    style={{width : '148.58px'}}
                    id="SearchUser"
                    onClick={() => {
                        openModal();
                    }}
                    data-toggle="modal"
                    data-target="#myModal"
                >
                    Search
                </button>
            </Col>
        </Row>

        <Modal
            size="lg"
            isOpen={modal_standard}
            toggle={() => {
            openModal()
            }}
            scrollable={true}
        >
            <div className="modal-header" style={{marginRight: "20px"}}>
                <h6 className="modal-title mt-0 col-md-6" id="myModalLabel">
                Search existing or enter new username
                </h6>
                <input
                    type="text"
                    className="form-control"
                    style={{border: "solid 1px"}}
                    placeholder="Search..."
                    value={keyword}
                    onChange={(e) => {setKeyword(e.target.value)}}
                    onKeyDown={(e) => {
                        if(e.code === 'Enter'){
                            search(e.target.value);
                        }                      
                    }}
                />
                <button
                    type="button"
                    onClick={() => {
                        search(keyword);
                    }}
                    className="btn btn-primary waves-effect waves-light"
                    style={{marginLeft:"5px", 
                        width: "100px",
                        padding: "0.3rem 0.3rem"}}
                >
                    <i className="uil-search" style={{fontSize:"18px"}}></i>
                </button>

                <button
                    type="button"
                    onClick={() => {
                        openModal(false)
                    }}
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                >
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div className="modal-body">
                {
                    searchUserMessage && searchUserMessage !== ''?
                    <Row className="mb-3">{searchUserMessage}</Row>
                    : null
                }
                {
                    searchUserList.length ?
                    searchUserList.map((userInfo, index) => {
                        return (
                            <Row className="mb-3">
                                <div className="col-md-12 form-check" style={{paddingLeft : '2em'}} onClick={(e)=>targetCheck(userInfo.userNo)}>
                                    <input
                                    className="form-check-input"
                                    type="radio"
                                    name="admSearchUser"
                                    id={"admSearchUser-" + userInfo.userNo}
                                    value="Invoice"
                                    checked={userInfo.checked}
                                    />
                                    <label
                                    className="form-check-label"
                                    htmlFor="admSearchUser"
                                    >
                                    {userInfo.userName}
                                    </label>
                                </div>
                            </Row>
                        )
                    })
                    : null
                }
            </div>
            <div className="modal-footer">
                <button
                    type="button"
                    onClick={() => {
                    openModal()
                    }}
                    className="btn btn-secondary waves-effect"
                    data-dismiss="modal"
                >
                    Cancel
                </button>
                <button
                    type="button"
                    className="btn btn-primary waves-effect waves-light"
                    onClick={()=> targetConfirm()}
                >
                    Confirm
                </button>
            </div>
        </Modal>
        </>
    )
};

export default SearchUser;