import axios from "axios"
import store from '../store';
import { 
  sessionLogout,
} from "../store/auth/login/actions";

// import accessToken from "./jwt-token-access/accessToken"

//pass new generated access token here
// const token = accessToken

//apply base url for axios
const API_URL = ""

const axiosApi = axios.create({
  baseURL: API_URL,
})

// axiosApi.defaults.headers.common["Authorization"] = token

axiosApi.interceptors.response.use(
  response => {
    if (response.data.sessionLogout && response.data.sessionLogout === 'Y') {
      // console.info(response);
      // console.log('go sessionLogout');
      store.dispatch(sessionLogout());
    } else {
      return response;
    }
  },
  error => Promise.reject(error)
)

export async function get(url, config = {}) {
  return await axiosApi.get(url, { ...config, headers: {'loginKey' : localStorage.getItem("loginKey")} }).then(response => response.data)
}

export async function post(url, data, config = {}) {
  return axiosApi
    .post(url, { ...data }, { ...config, headers: {'loginKey' : localStorage.getItem("loginKey")} })
    .then(response => response.data)
}

export async function postRawData(url, rawData, config = {}) {
  return axiosApi
    .post(url, rawData, { ...config, headers: {'loginKey' : localStorage.getItem("loginKey")} })
    .then(response => response.data)
}

export async function put(url, data, config = {}) {
  return axiosApi
    .put(url, { ...data }, { ...config })
    .then(response => response.data)
}

export async function del(url, config = {}) {
  return await axiosApi
    .delete(url, { ...config })
    .then(response => response.data)
}

export async function downExcel(url, data, fileName) {
  return axios
  .post(url, data, {
    method: 'GET',
    responseType: 'blob' //Force to receive data in a Blob Format
  })
  .then(response => {
    if (response.request.responseURL.indexOf('/login') > -1) {
      store.dispatch(sessionLogout());
    } else {
      //Create a Blob from the EXCEL Stream
      const file = new Blob(
        [response.data], 
        {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
      //Build a URL from the file
      const fileURL = URL.createObjectURL(file);
      //Open the URL on new Window
      //window.open(fileURL);
      let a = document.createElement("a");
      document.body.appendChild(a);
      a.style = "display: none";
      a.href = fileURL;
      a.download = fileName;
      a.click();
    }
  })
  .catch(error => {
      console.log(error);
  });
  
}

export async function downPdf(url, data, fileName) {
  return axios
  .post(url, data, {
    method: 'GET',
    responseType: 'blob' //Force to receive data in a Blob Format
  })
  .then(response => {
    // console.info(response);
    if (response.request.responseURL.indexOf('/login') > -1) {
      store.dispatch(sessionLogout());
    } else {
      //Create a Blob from the PDF Stream
      const file = new Blob(
        [response.data], 
        {type: 'application/pdf'});
      //Build a URL from the file
      const fileURL = URL.createObjectURL(file);
      //Open the URL on new Window
      //window.open(fileURL);
      let a = document.createElement("a");
      document.body.appendChild(a);
      a.style = "display: none";
      a.href = fileURL;
      a.download = fileName;
      a.click();
    }
  })
  .catch(error => {
      console.log(error);
  });
  
}

export async function downZip(url, data, fileName) {
  return axios
  .post(url, data, {
    method: 'GET',
    responseType: 'blob' //Force to receive data in a Blob Format
  })
  .then(response => {
    // console.info(response);
    if (response.request.responseURL.indexOf('/login') > -1) {
      store.dispatch(sessionLogout());
    } else {
      //Create a Blob from the PDF Stream
      const file = new Blob(
        [response.data], 
        {type: 'application/zip'});
      //Build a URL from the file
      const fileURL = URL.createObjectURL(file);
      //Open the URL on new Window
      //window.open(fileURL);
      let a = document.createElement("a");
      document.body.appendChild(a);
      a.style = "display: none";
      a.href = fileURL;
      a.download = fileName;
      a.click();
    }
  })
  .catch(error => {
      console.log(error);
  });
  
}