import { call, put, takeEvery, takeLatest } from "redux-saga/effects"

// Login Redux States
import { 
  GET_ALL_JURISDICTIONS_LIST,
  GET_JURISDICTIONS_LIST,
  GET_COUNTRY_LIST,
  GET_ALL_COUNTRY_LIST,
  GET_SEARCH_NAME_LIST,
  GET_SEARCH_NAME_LIST2,
  GET_SEARCH_TARGET_GROUP_LIST,
  GET_GENERALSTATISTICS_LIST,
  DOWN_STATISTICS_EXCEL,
  DOWN_STATISTICS_PDF,
} from "./actionTypes"
import { 
  getAllJurisdictionsListSuccess,
  getJurisdictionsListSuccess,
  getCountryListSuccess,
  getAllCountryListSuccess,
  getSearchNameListSuccess,
  getSearchTargetGroupListSuccess,
  getGeneralStatisticsListSuccess,
  downStatisticsExcelSuccess,
  downStatisticsPdfSuccess,
  setSearchNameList,
} from "./actions"

//Include Both Helper File with needed methods
import {
  getAllJurisdictionsList,
  getJurisdictionsList,
  getCountryList,
  getAllCountryList,
  getSearchNameList,
  getSearchTargetGroupList,
  getGeneralStatisticsList,
  downStatisticsExcel,
  downStatisticsPdf,
} from "../../helpers/backend_helper"

function* onGetAllJurisdictionsList({ payload: inOutType }) {
  try {
    const response = yield call(getAllJurisdictionsList, inOutType);
    yield put(getAllJurisdictionsListSuccess(response));
    
  } catch (error) {
    console.log('onGetAllJurisdictionsList error >> ' + error);
    //yield put(apiError(error))
  }
}

function* onGetJurisdictionsList({ payload: data }) {
  try {
    const response = yield call(getJurisdictionsList, data);
    yield put(getJurisdictionsListSuccess(response));
    
  } catch (error) {
    console.log('onGetJurisdictionsList error >> ' + error);
    //yield put(apiError(error))
  }
}

function* onGetCountryList({ payload: inOutType }) {
  try {
    const response = yield call(getCountryList, inOutType);
    yield put(getCountryListSuccess(response));
    
  } catch (error) {
    console.log('onGetCountryList error >> ' + error);
    //yield put(apiError(error))
  }
}

function* onGetAllCountryList({ payload: inOutType }) {
  try {
    const response = yield call(getAllCountryList, inOutType);
    yield put(getAllCountryListSuccess(response));
    
  } catch (error) {
    console.log('onGetAllCountryList error >> ' + error);
  }
}

function* onGetSearchNameList({ payload: data }) {
  try {

    // console.log('onGetSearchNameList >>> data : ' + JSON.stringify(data));

    // console.log('onGetSearchNameList >>> searchName : ' + JSON.stringify(data.searchName) + ', screenName : ' + JSON.stringify(data.screenName));

    const response = yield call(getSearchNameList, {searchName: data.searchName});
    yield put(getSearchNameListSuccess(response, data.screenName, data.labelName, data.filterName));
    
  } catch (error) {
    console.log('onGetSearchNameList error >> ' + error);
    //yield put(apiError(error))
  }
}

function* onGetSearchNameList2({ payload: data }) {
  try {

    // console.log('onGetSearchNameList2 >>> data : ' + JSON.stringify(data));

    // console.log('onGetSearchNameList2 >>> searchName : ' + JSON.stringify(data.searchName) + ', screenName : ' + JSON.stringify(data.screenName));

    const response = yield call(getSearchNameList, {searchName: data.searchName});
    yield put(getSearchNameListSuccess(response, data.screenName, data.labelName, data.filterName));
    
  } catch (error) {
    console.log('onGetSearchNameList2 error >> ' + error);
    //yield put(apiError(error))
  }
}

function* onGetSearchTargetGroupList({ payload: targetGroupNo }) {
  try {
    const response = yield call(getSearchTargetGroupList, targetGroupNo);
    yield put(getSearchTargetGroupListSuccess(response));
    
  } catch (error) {
    console.log('onGetSearchTargetGroupList error >> ' + error);
    //yield put(apiError(error))
  }
}

function* onGetGeneralStatisticsList({ payload: data }) {
  try {
    const response = yield call(getGeneralStatisticsList, data);
    yield put(getGeneralStatisticsListSuccess(response));
    
  } catch (error) {
    console.log('onGetGeneralStatisticsList error >> ' + error);
    //yield put(apiError(error))
  }
}

function* onDownStatisticsExcel({ payload: data }) {
  try {
    yield call(downStatisticsExcel, data);
    yield put(downStatisticsExcelSuccess());

  } catch (error) {
    console.log('onDownStatisticsExcel error >> ' + error);
    //yield put(apiError(error))
  }
}

function* onDownStatisticsPdf({ payload: data }) {
  try {
    yield call(downStatisticsPdf, data);
    yield put(downStatisticsPdfSuccess());

  } catch (error) {
    console.log('onDownStatisticsPdf error >> ' + error);
    //yield put(apiError(error))
  }
}

function* generalStatisticsSaga() {
  yield takeEvery(GET_ALL_JURISDICTIONS_LIST, onGetAllJurisdictionsList);
  yield takeEvery(GET_JURISDICTIONS_LIST, onGetJurisdictionsList);
  yield takeEvery(GET_COUNTRY_LIST, onGetCountryList);
  yield takeEvery(GET_ALL_COUNTRY_LIST, onGetAllCountryList);
  yield takeEvery(GET_SEARCH_NAME_LIST, onGetSearchNameList);
  yield takeLatest(GET_SEARCH_NAME_LIST2, onGetSearchNameList2);
  yield takeLatest(GET_SEARCH_TARGET_GROUP_LIST, onGetSearchTargetGroupList);
  yield takeLatest(GET_GENERALSTATISTICS_LIST, onGetGeneralStatisticsList);
  yield takeLatest(DOWN_STATISTICS_EXCEL, onDownStatisticsExcel);
  yield takeLatest(DOWN_STATISTICS_PDF, onDownStatisticsPdf);
  
}

export default generalStatisticsSaga

