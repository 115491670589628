import {
  SET_LOADING,
  SET_SUCCESS,
  SET_ERROR_MSG,
  SET_UP_FILES,
  SET_UPDATE_FLAG,
  SET_LOADING_EDITOR,
  GET_COMMUNITY_LIST,
  GET_COMMUNITY_LIST_SUCCESS,
  CHANGE_COMMUNITY_DETAIL,
  GET_COMMUNITY_DETAIL,
  GET_COMMUNITY_DETAIL_SUCCESS,
  CREATE_COMMUNITY,
  CREATE_COMMUNITY_SUCCESS,
  MODIFY_COMMUNITY,
  MODIFY_COMMUNITY_SUCCESS,
  REMOVE_COMMUNITY,
  REMOVE_COMMUNITY_SUCCESS,
  CHANGE_COMMUNITY_ATTACH_FILE_LIST,
  GET_COMMUNITY_ATTACH_FILE_LIST,
  GET_COMMUNITY_ATTACH_FILE_LIST_SUCCESS,
  ADD_COMMUNITY_ATTACH_FILE,
  ADD_COMMUNITY_ATTACH_FILE_SUCCESS,
  REMOVE_COMMUNITY_ATTACH_FILE,
  REMOVE_COMMUNITY_ATTACH_FILE_SUCCESS,
  CHANGE_COMMUNITY_REPLY_LIST,
  GET_COMMUNITY_REPLY_LIST,
  GET_COMMUNITY_REPLY_LIST_SUCCESS,
  CREATE_COMMUNITY_REPLY,
  CREATE_COMMUNITY_REPLY_SUCCESS,
  MODIFY_COMMUNITY_REPLY,
  MODIFY_COMMUNITY_REPLY_SUCCESS,
  REMOVE_COMMUNITY_REPLY,
  REMOVE_COMMUNITY_REPLY_SUCCESS,
} from "./actionTypes"

const initialState = {
  error: "",
  loading: false,
  editorLoading: false,
  success: "",
  errorMsg: "",
  totalPage: 1,
  communityList: [],
  communityDetail: {idx:-1, communityCtg:"Chitchat", title:"", content:""},
  communityAttachList: [],
  upFiles: [],
  replyTotalPage: 1,
  communityReplyList: [],
  loadingEditor: false,
  updateFlag: false,
}

const Community = (state = initialState, action) => {
  switch (action.type) {
    case SET_LOADING:
      state = {
        ...state,
        loading: action.payload,
      }
      break
    case SET_SUCCESS:
      return {
        ...state,
        success: action.payload,
      }
    case SET_ERROR_MSG:
      return {
        ...state,
        errorMsg: action.payload,
      }
    case SET_UP_FILES:
      return {
        ...state,
        upFiles: action.payload,
      }
    case SET_UPDATE_FLAG:
      return {
        ...state,
        updateFlag: action.payload,
      }
    case SET_LOADING_EDITOR:
      return {
        ...state,
        loadingEditor: action.payload,
      }
    case GET_COMMUNITY_LIST:
      state = {
        ...state,
        loading: true,
      }
      break
    case GET_COMMUNITY_LIST_SUCCESS:
      state =  {
        ...state,
        loading: false,
        totalPage: action.payload.totalPage,
        communityList: action.payload.communityList ? action.payload.communityList : [],
      }
      break
    case CHANGE_COMMUNITY_DETAIL:
      state =  {
        ...state,
        communityDetail: action.payload,
      }
      break
    case GET_COMMUNITY_DETAIL:
      state = {
        ...state,
        loading: true,
      }
      break
    case GET_COMMUNITY_DETAIL_SUCCESS:
      state =  {
        ...state,
        loading: false,
        communityDetail: action.payload.communityDetail,
      }
      break
    case CREATE_COMMUNITY:
      return {
        ...state,
        loading: true
      }
    case CREATE_COMMUNITY_SUCCESS:
      let _communityList1 = [];
      _communityList1.push(action.payload.communityDetail);
      state.communityList.map((community, index) => {
        _communityList1.push(community);
      });

      return {
        ...state,
        loading: false,
        updateFlag: true,
        success: action.payload.result,
        communityList: _communityList1,
        upFiles: [],
      }
    case MODIFY_COMMUNITY:
      state = {
        ...state,
        loading: true,
      }
      break
    case MODIFY_COMMUNITY_SUCCESS:
      let _communityList2 = [];
      state.communityList.map((community, index) => {
        if (Number(community.idx) === Number(action.payload.data.get("idx"))) {
          let _modCommunity = community;
          _modCommunity.communityCtg = action.payload.data.get("communityCtg");
          _modCommunity.title = action.payload.data.get("title");
          _communityList2.push(_modCommunity);
        } else {
          _communityList2.push(community);
        }
      });

      state =  {
        ...state,
        loading: false,
        // updateFlag: true,
        success: action.payload.response.result,
        communityList: _communityList2,
        upFiles: [],
      }
      break
    case REMOVE_COMMUNITY:
      state = {
        ...state,
        loading: true,
      }
      break
    case REMOVE_COMMUNITY_SUCCESS:
      let _communityList3 = [];
      state.communityList.map((community, index) => {
        if (community.idx !== action.payload.response.data.idx) {
          _communityList3.push(community);
        }
      });

      state =  {
        ...state,
        loading: false,
        updateFlag: true,
        success: action.payload.response.result,
        communityList: _communityList3,
        upFiles: [],
      }
      break
    case CHANGE_COMMUNITY_ATTACH_FILE_LIST:
      state =  {
        ...state,
        communityAttachList: action.payload,
      }
      break
    case GET_COMMUNITY_ATTACH_FILE_LIST:
      state = {
        ...state,
        loading: true,
      }
      break
    case GET_COMMUNITY_ATTACH_FILE_LIST_SUCCESS:
      state =  {
        ...state,
        loading: false,
        communityAttachList: action.payload.communityAttachList,
      }
      break
    case ADD_COMMUNITY_ATTACH_FILE:
      state = {
        ...state,
        loading: true,
      }
      break
    case ADD_COMMUNITY_ATTACH_FILE_SUCCESS:
      state =  {
        ...state,
        loading: false,
        success: action.payload.result,
      }
      break
    case REMOVE_COMMUNITY_ATTACH_FILE:
      state = {
        ...state,
        loading: true,
      }
      break
    case REMOVE_COMMUNITY_ATTACH_FILE_SUCCESS:
      state =  {
        ...state,
        loading: false,
        success: action.payload.result,
      }
      break
    case CHANGE_COMMUNITY_REPLY_LIST:
      state =  {
        ...state,
        communityReplyList: action.payload,
      }
      break
    case GET_COMMUNITY_REPLY_LIST:
      state = {
        ...state,
        loading: true,
      }
      break
    case GET_COMMUNITY_REPLY_LIST_SUCCESS:
      state =  {
        ...state,
        loading: false,
        replyTotalPage: action.payload.totalPage,
        communityReplyList: action.payload.communityReplyList ? action.payload.communityReplyList : [],
      }
      break
    case CREATE_COMMUNITY_REPLY:
      state = {
        ...state,
        loading: true,
      }
      break
    case CREATE_COMMUNITY_REPLY_SUCCESS:
      state =  {
        ...state,
        loading: false,
        success: action.payload.result,
      }
      break
    case MODIFY_COMMUNITY_REPLY:
      state = {
        ...state,
        loading: true,
      }
      break
    case MODIFY_COMMUNITY_REPLY_SUCCESS:
      state =  {
        ...state,
        loading: false,
        success: action.payload.result,
      }
      break
    case REMOVE_COMMUNITY_REPLY:
      state = {
        ...state,
        loading: true,
      }
      break
    case REMOVE_COMMUNITY_REPLY_SUCCESS:
      state =  {
        ...state,
        loading: false,
        success: action.payload.result,
      }
      break
    default:
      state = { ...state }
      break
  }
  return state
}

export default Community
