import { call, put, takeEvery } from "redux-saga/effects"

// Login Redux States
import { GET_USER_LIST } from "./actionTypes"
import { getUserListSuccess } from "./actions"

//Include Both Helper File with needed methods
import {
  getWeCrestUsers,
} from "../../helpers/backend_helper"

function* onGetUserList() {
  try {
    // console.log('onGetUserList Start');
    const response = yield call(getWeCrestUsers)
    yield put(getUserListSuccess(response))
  } catch (error) {
    console.log('onGetUserList error >> ' + error);
    //yield put(apiError(error))
  }
}

function* userSaga() {
  yield takeEvery(GET_USER_LIST, onGetUserList)
}

export default userSaga
