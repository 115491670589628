import {
  GET_MY_CONNECTED_CASE_SETTINGS,
  GET_MY_CONNECTED_CASE_SETTINGS_SUCCESS,
  SAVE_MY_CONNECTED_CASE_SETTINGS,
  SAVE_MY_CONNECTED_CASE_SETTINGS_SUCCESS,
  GET_MY_CONNECTED_CASE_LIST,
  GET_MY_CONNECTED_CASE_LIST_SUCCESS,
  CHANGE_MY_CONNECTED_CASE_LIST,
  GET_MY_CASE_MANAGEMENT_SETTINGS,
  GET_MY_CASE_MANAGEMENT_SETTINGS_SUCCESS,
  SAVE_MY_CASE_MANAGEMENT_SETTINGS,
  SAVE_MY_CASE_MANAGEMENT_SETTINGS_SUCCESS,
  GET_MY_CASE_MANAGEMENT_LIST,
  GET_MY_CASE_MANAGEMENT_LIST_SUCCESS,
  CHANGE_MY_CASE_MANAGEMENT_LIST,
  DOWN_MY_CASE_MANAGEMENT_EXCEL,
  DOWN_MY_CASE_MANAGEMENT_EXCEL_SUCCESS,
  DOWN_MULTIPLE_MY_CASE_MANAGEMENT_EXCEL,
  DOWN_MULTIPLE_MY_CASE_MANAGEMENT_EXCEL_SUCCESS,
  DOWN_MY_CASE_MANAGEMENT_PDF,
  DOWN_MY_CASE_MANAGEMENT_PDF_SUCCESS,
  DOWN_MULTIPLE_MY_CASE_MANAGEMENT_PDF,
  DOWN_MULTIPLE_MY_CASE_MANAGEMENT_PDF_SUCCESS,
  GET_USER_IR_INFO_CLAIM_LIST,
  GET_USER_IR_INFO_CLAIM_LIST_SUCCESS,
  CHANGE_USER_IR_INFO_CLAIM_LIST,
  ADD_USER_IR_INFO_CLAIM,
  ADD_USER_IR_INFO_CLAIM_SUCCESS,
  CONFIRM_USER_IR_INFO_CLAIM,
  CONFIRM_USER_IR_INFO_CLAIM_SUCCESS,
  GET_TARGET_GROUP_DETAIL_LIST,
  GET_TARGET_GROUP_DETAIL_LIST_SUCCESS,
  SET_SUCCESS,
  SET_RESULT,
  SET_LOADING,
} from "./actionTypes"

export const getMyConnectedCaseSettings = () => {
  return {
    type: GET_MY_CONNECTED_CASE_SETTINGS,
  }
}

export const getMyConnectedCaseSettingsSuccess = data => {
  return {
    type: GET_MY_CONNECTED_CASE_SETTINGS_SUCCESS,
    payload: data,
  }
}

export const saveMyConnectedCaseSetting = data => {
  return {
    type: SAVE_MY_CONNECTED_CASE_SETTINGS,
    payload: data,
  }
}

export const saveMyConnectedCaseSettingSuccess = data => {
  return {
    type: SAVE_MY_CONNECTED_CASE_SETTINGS_SUCCESS,
    payload: data,
    
  }
}

export const getMyConnectedCaseList = data => {
  return {
    type: GET_MY_CONNECTED_CASE_LIST,
    payload: data,
  }
}

export const getMyConnectedCaseListSuccess = data => {
  return {
    type: GET_MY_CONNECTED_CASE_LIST_SUCCESS,
    payload: data,
  }
}

export const changeMyConnectedCaseList = data => {
  return {
    type: CHANGE_MY_CONNECTED_CASE_LIST,
    payload: data,
  }
}

export const getMyCaseManagementSettings = () => {
  return {
    type: GET_MY_CASE_MANAGEMENT_SETTINGS,
  }
}

export const getMyCaseManagementSettingsSuccess = data => {
  return {
    type: GET_MY_CASE_MANAGEMENT_SETTINGS_SUCCESS,
    payload: data,
  }
}

export const saveMyCaseManagementSetting = data => {
  return {
    type: SAVE_MY_CASE_MANAGEMENT_SETTINGS,
    payload: data,
  }
}

export const saveMyCaseManagementSettingSuccess = data => {
  return {
    type: SAVE_MY_CASE_MANAGEMENT_SETTINGS_SUCCESS,
    payload: data,
    
  }
}

export const getMyCaseManagementList = data => {
  return {
    type: GET_MY_CASE_MANAGEMENT_LIST,
    payload: data,
  }
}

export const getMyCaseManagementListSuccess = data => {
  return {
    type: GET_MY_CASE_MANAGEMENT_LIST_SUCCESS,
    payload: data,
  }
}

export const changeMyCaseManagementList = data => {
  return {
    type: CHANGE_MY_CASE_MANAGEMENT_LIST,
    payload: data,
  }
}

export const downMyCaseManagementExcel = (data, fileName) => {
  return {
    type: DOWN_MY_CASE_MANAGEMENT_EXCEL,
    payload: {data, fileName},
  }
}

export const downMyCaseManagementExcelSuccess = () => {
  return {
    type: DOWN_MY_CASE_MANAGEMENT_EXCEL_SUCCESS,
  }
}

export const downMultipleMyCaseManagementExcel = (madridNotifierList, noticeStr, fileName) => {
  return {
    type: DOWN_MULTIPLE_MY_CASE_MANAGEMENT_EXCEL,
    payload: {madridNotifierList, noticeStr, fileName},
  }
}

export const downMultipleMyCaseManagementExcelSuccess = () => {
  return {
    type: DOWN_MULTIPLE_MY_CASE_MANAGEMENT_EXCEL_SUCCESS,
  }
}

export const downMyCaseManagementPdf = (data, fileName) => {
  return {
    type: DOWN_MY_CASE_MANAGEMENT_PDF,
    payload: {data, fileName},
  }
}

export const downMyCaseManagementPdfSuccess = () => {
  return {
    type: DOWN_MY_CASE_MANAGEMENT_PDF_SUCCESS,
  }
}

export const downMultipleMyCaseManagementPdf = (data, fileName) => {
  return {
    type: DOWN_MULTIPLE_MY_CASE_MANAGEMENT_PDF,
    payload: {data, fileName},
  }
}

export const downMultipleMyCaseManagementPdfSuccess = () => {
  return {
    type: DOWN_MULTIPLE_MY_CASE_MANAGEMENT_PDF_SUCCESS,
  }
}

export const getUserIrInfoClaimList = data => {
  return {
    type: GET_USER_IR_INFO_CLAIM_LIST,
    payload: data,
  }
}

export const getUserIrInfoClaimListSuccess = data => {
  return {
    type: GET_USER_IR_INFO_CLAIM_LIST_SUCCESS,
    payload: data,
  }
}

export const changeUserIrInfoClaimList = data => {
  return {
    type: CHANGE_USER_IR_INFO_CLAIM_LIST,
    payload: data,
  }
}

export const addUserIrInfoClaim = (data, claimType, filter, filterParamMyConnectedCase) => {
  return {
    type: ADD_USER_IR_INFO_CLAIM,
    payload: {data, claimType, filter, filterParamMyConnectedCase},
  }
}

export const addUserIrInfoClaimSuccess = data => {
  return {
    type: ADD_USER_IR_INFO_CLAIM_SUCCESS,
    payload: data,
  }
}

export const confirmUserIrInfoClaim = (data, filter) => {
  return {
    type: CONFIRM_USER_IR_INFO_CLAIM,
    payload: {data, filter},
  }
}

export const confirmUserIrInfoClaimSuccess = data => {
  return {
    type: CONFIRM_USER_IR_INFO_CLAIM_SUCCESS,
    payload: data,
  }
}

export const getTargetGroupDetailList = data => {
  return {
    type: GET_TARGET_GROUP_DETAIL_LIST,
    payload: data,
  }
}

export const getTargetGroupDetailListSuccess = data => {
  return {
    type: GET_TARGET_GROUP_DETAIL_LIST_SUCCESS,
    payload: data,
  }
}

export const setMyCaseManagementSuccess = data => {
  return {
    type: SET_SUCCESS,
    payload: data,
  }
}

export const setMyCaseManagementResult = data => {
  return {
    type: SET_RESULT,
    payload: data,
  }
}

export const setMyCaseManagementLoading = data => {
  return {
    type: SET_LOADING,
    payload: data,
  }
}