import React, {useState, useEffect} from 'react';
import { useSelector, useDispatch } from "react-redux";
import {
    Table,
    Row,
    Col,
    Card,
    CardBody,
    CardTitle,
    Media,
    Collapse,
    Form,
    Input,
    Label,
    Button,
    TabContent,
    TabPane,
    NavLink,
    NavItem,
    Nav,
    FormFeedback,
    UncontrolledTooltip,
    Modal,
  } from "reactstrap"
import SweetAlert from "react-bootstrap-sweetalert";
import LoadingModal from "../../components/Common/LoadingModal"
import PeriodBtn from '../../components/Filter/PeriodBtn';
import { Link } from "react-router-dom";
import * as Yup from "yup";
import { useFormik } from "formik";
import classnames from "classnames"
import SelectAllJurisdictions from '../../components/Filter/SelectAllJurisdictions';
import SelectAllCountry from '../../components/Filter/SelectAllCountry';
import SearchName from '../../components/Filter/SearchName';
import SendEmailHistory from '../../components/Common/SendEmailHistory';
import SendingAnalyticsStatus from './SendingAnalyticsStatus';

import {
  getSendingAnalyticsMnSettings,
  saveSendingAnalyticsMnSettings,
  getSendingAnalyticsMnList,
  downSendingAnalyticsMnExcel,
  getSendingAnalyticsMn,
  getSendingAnalyticsFrSettings,
  saveSendingAnalyticsFrSettings,
  getSendingAnalyticsFrList,
  downSendingAnalyticsFrExcel,
  setSendingAnalyticsSuccess,
  setSendingAnalyticsError,
  setSendingAnalyticsLoading as onSetSendingAnalyticsLoading,
  setSendingAnalyticsMn as onSetSendingAnalyticsMn,
} from "../../store/actions";
import { cloneDeep } from 'lodash';
import dateFormat from 'dateformat';

import '../../components/Common/Common.css';

const SendingAnalytics = props => {
  const dispatch = useDispatch();

  const { loading, error, success, searchSettingsMn, statisticsMapMn, sendingAnalyticsMnList, sendingAnalyticsMn, totalPageMn, searchSettingsFr, statisticsMapFr, sendingAnalyticsFrList, totalPageFr } = useSelector(state => ({
    loading: state.SendingAnalytics.loading,
    error: state.SendingAnalytics.error,
    success: state.SendingAnalytics.success,
    searchSettingsMn: state.SendingAnalytics.searchSettingsMn,
    statisticsMapMn: state.SendingAnalytics.statisticsMapMn,
    sendingAnalyticsMnList: state.SendingAnalytics.sendingAnalyticsMnList,
    sendingAnalyticsMn: state.SendingAnalytics.sendingAnalyticsMn,
    totalPageMn: state.SendingAnalytics.totalPageMn,
    searchSettingsFr: state.SendingAnalytics.searchSettingsFr,
    statisticsMapFr: state.SendingAnalytics.statisticsMapFr,
    sendingAnalyticsFrList: state.SendingAnalytics.sendingAnalyticsFrList,
    totalPageFr: state.SendingAnalytics.totalPageFr,
  }));

  const [curPageInputMn, setCurPageInputMn] = useState(1);
  const [curPageInputFr, setCurPageInputFr] = useState(1);

  const [activeTab, setactiveTab] = useState("Madrid")
  function toggleActiveTab(tab) {
    if (activeTab !== tab) {
      setactiveTab(tab)

      // if (tab === 'Madrid') {
      //   filterValidation.setFieldValue("inOutType", searchSettingsMn && searchSettingsMn.inOutType? searchSettingsMn.inOutType : "IN");
      // } else {
      //   filterValidation.setFieldValue("inOutType", searchSettingsFr && searchSettingsFr.inOutType? searchSettingsFr.inOutType : "IN");
      // }
    }
  }

  const [isOpen, setIsOpen] = useState(true);
  const toggle = () => {
    setIsOpen(!isOpen);
  }

  const [preview, setPreview] = useState({});
  const [openInfomation, setOpenInfomation] = useState(false);
  const toggleStatus = () => {
    setOpenInfomation(!openInfomation);
  }

  const [mnColumns, setMnColumns] = useState([
    {label: "Status", asc:"STA", desc:"STD", ascActive:"", descActive:""},
    {label: "Sent date (Date and time)", asc:"SDA", desc:"SDD", ascActive:"", descActive:"text-danger"},
    {label: "To email", asc:"TEA", desc:"TED", ascActive:"", descActive:""},
    {label: "Jurisdiction / Holder’s country", asc:"CRA", desc:"CRD", ascActive:"", descActive:""},
    {label: "IR number", asc:"IRA", desc:"IRD", ascActive:"", descActive:""},
    {label: "Reminder", asc:null, desc:null, ascActive:null, descActive:null},
    {label: "Type(IN/OUT)", asc:null, desc:null, ascActive:null, descActive:null},
  ]);

  const viewSendingAnalyticsMnList = (sortType, curPage, perPage) => {
    //curPage 유효성 체크
    if(curPage){
      if(curPage > totalPageMn) {
        curPage = totalPageMn;
      } else if (curPage <= 0) {
        curPage = 1;
      }
    }
    //빨간색으로 변경
    if(sortType){
      const _columns = [];
      mnColumns.map((col, index)=>{
        let _col = cloneDeep(col);
        if(col.asc && col.asc === sortType){
          _col.ascActive = "text-danger";
          _col.descActive = "";
        }else if(col.desc && col.desc === sortType ){
          _col.ascActive = "";
          _col.descActive = "text-danger";
        }else{
          _col.ascActive = "";
          _col.descActive = "";
        }
        _columns.push(_col);
      });

      setMnColumns(_columns);
    }

    // console.log("viewMadridNotifier [sortType: "+sortType+", curPage: "+curPage+", perPage: "+perPage);
    // let _values = cloneDeep(madridValidation.values);
    // _values.submitType = "view";
    filterValidation.setFieldValue("submitType", "viewMn");
    if(sortType){
      // _values.sortType = sortType;
      filterValidation.setFieldValue("sortTypeMn", sortType);
    }
    if(curPage){
      // _values.curPage = curPage;
      filterValidation.setFieldValue("curPageMn", curPage);
      setCurPageInputMn(curPage);
    }else{
      // _values.curPage = 1;
      filterValidation.setFieldValue("curPageMn", 1);
      setCurPageInputMn(1);
    }
    if(perPage){
      // _values.perPage = perPage;
      filterValidation.setFieldValue("perPageMn", perPage);
    }
    // madridValidation.setValues(_values);
    
    filterValidation.handleSubmit(filterValidation.values);
  }

  const [frColumns, setFrColumns] = useState([
    {label: "Status", asc:"STA", desc:"STD", ascActive:"", descActive:""},
    {label: "Sent date (Date and time)", asc:"SDA", desc:"SDD", ascActive:"", descActive:"text-danger"},
    {label: "To email", asc:"TEA", desc:"TED", ascActive:"", descActive:""},
    {label: "Entity", asc:"ETA", desc:"ETD", ascActive:"", descActive:""},
    {label: "Reminder", asc:null, desc:null, ascActive:null, descActive:null},
    {label: "Type(IN/OUT)", asc:null, desc:null, ascActive:null, descActive:null},
  ]);

  const viewSendingAnalyticsFrList = (sortType, curPage, perPage) => {
    //curPage 유효성 체크
    if(curPage){
      if(curPage > totalPageMn) {
        curPage = totalPageMn;
      } else if (curPage <= 0) {
        curPage = 1;
      }
    }
    //빨간색으로 변경
    if(sortType){
      const _columns = [];
      frColumns.map((col, index)=>{
        let _col = cloneDeep(col);
        if(col.asc && col.asc === sortType){
          _col.ascActive = "text-danger";
          _col.descActive = "";
        }else if(col.desc && col.desc === sortType ){
          _col.ascActive = "";
          _col.descActive = "text-danger";
        }else{
          _col.ascActive = "";
          _col.descActive = "";
        }
        _columns.push(_col);
      });

      setFrColumns(_columns);
    }

    // console.log("viewMadridNotifier [sortType: "+sortType+", curPage: "+curPage+", perPage: "+perPage);
    // let _values = cloneDeep(madridValidation.values);
    // _values.submitType = "view";
    filterValidation.setFieldValue("submitType", "viewFr");
    if(sortType){
      // _values.sortType = sortType;
      filterValidation.setFieldValue("sortTypeFr", sortType);
    }
    if(curPage){
      // _values.curPage = curPage;
      filterValidation.setFieldValue("curPageFr", curPage);
      setCurPageInputMn(curPage);
    }else{
      // _values.curPage = 1;
      filterValidation.setFieldValue("curPageFr", 1);
      setCurPageInputMn(1);
    }
    if(perPage){
      // _values.perPage = perPage;
      filterValidation.setFieldValue("perPageFr", perPage);
    }
    // madridValidation.setValues(_values);
    
    filterValidation.handleSubmit(filterValidation.values);
  }

  const filterValidation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      inOutType: "IN",

      submitType: "view",
      // inOutType: searchSettingsMn && searchSettingsMn.inOutType? searchSettingsMn.inOutType : "IN",
      inOutTypeMn: searchSettingsMn && searchSettingsMn.inOutType? searchSettingsMn.inOutType : "IN",
      statusListMn: searchSettingsMn && searchSettingsMn.statusList? searchSettingsMn.statusList : [],
      jurType: searchSettingsMn && searchSettingsMn.crType? searchSettingsMn.crType : "",
      jrCdList: searchSettingsMn && searchSettingsMn.crList? searchSettingsMn.crList : [],
      holderType: searchSettingsMn && searchSettingsMn.crType? searchSettingsMn.crType : "",
      holderCdList: searchSettingsMn && searchSettingsMn.crList? searchSettingsMn.crList : [],
      irNumber: searchSettingsMn && searchSettingsMn.irNumber? searchSettingsMn.irNumber : "",
      toEmailMn: searchSettingsMn && searchSettingsMn.toEmail? searchSettingsMn.toEmail : "",
      startDateMn: searchSettingsMn && searchSettingsMn.startDate? searchSettingsMn.startDate : "",
      endDateMn: searchSettingsMn && searchSettingsMn.endDate? searchSettingsMn.endDate : "",
      sortTypeMn: "SDD",
      perPageMn: 20,
      curPageMn: 1,

      inOutTypeFr: searchSettingsFr && searchSettingsFr.inOutType? searchSettingsFr.inOutType : "IN",
      statusListFr: searchSettingsFr && searchSettingsFr.statusList? searchSettingsFr.statusList : [],
      searchNoList: searchSettingsFr && searchSettingsFr.entityList? searchSettingsFr.entityList : [],
      searchKeyword: searchSettingsFr && searchSettingsFr.searchKeyword? searchSettingsFr.searchKeyword : "",
      searchNameList: [],
      toEmailFr: searchSettingsFr && searchSettingsFr.toEmail? searchSettingsFr.toEmail : "",
      startDateFr: searchSettingsFr && searchSettingsFr.startDate? searchSettingsFr.startDate : "",
      endDateFr: searchSettingsFr && searchSettingsFr.endDate? searchSettingsFr.endDate : "",
      sortTypeFr: "SDD",
      perPageFr: 20,
      curPageFr: 1,

      screenGrade: "Free",
    },
    validationSchema: Yup.object().shape({
      startDateMn: Yup.date().when('submitType', {
        is: 'viewMn',
        then: Yup.date()
              .max(Yup.ref('endDateMn'), "From date must be earlier than to date")
              .required("Please Select From date")
      }),
      endDateMn: Yup.date().when('submitType', {
        is: 'viewMn',
        then: Yup.date()
              .max(new Date(), "Date cannot be in the future")
              .required("Please Select to date"),
      }),
      startDateFr: Yup.date().when('submitType', {
        is: 'viewFr',
        then: Yup.date()
              .max(Yup.ref('endDateFr'), "From date must be earlier than to date")
              .required("Please Select From date"),
      }),
      endDateFr: Yup.date().when('submitType', {
        is: 'viewFr',
        then: Yup.date()
              .max(new Date(), "Date cannot be in the future")
              .required("Please Select to date"),
      }),
    }),
    validateOnChange: false, // this one
    validateOnBlur: false, // and this one
    onSubmit: (values) => {
      // console.log(JSON.stringify(values));

      if (activeTab === "Madrid") {
        const filterParam = {
          inOutType: values["inOutTypeMn"],
          statusList: values["statusListMn"],
          crType: (values["inOutTypeMn"] === 'IN' ? values["jurType"] : values["holderType"]),
          crList: (values["inOutTypeMn"] === 'IN' ? (values["jurType"] === "All" || values["jurType"] === "All-subs"? [] : values["jrCdList"]) : (values["holderType"] === "All" || values["holderType"] === "All-subs"? [] : values["holderCdList"])),
          irNumber: values["irNumber"],
          toEmail: values["toEmailMn"],
          startDate: values["startDateMn"],
          endDate: values["endDateMn"],
          sortType: values["sortTypeMn"],
          perPage: values["perPageMn"],
          curPage: values["curPageMn"],
          screenType : 'LOG',
        };
  
        if (!filterParam.startDate || filterParam.startDate === '' || !filterParam.endDate || filterParam.endDate === '') return;

        let chkStartDate = new Date(filterParam.startDate);
        let chkEndDate = new Date(filterParam.endDate);

        // console.log("filterParam : " + JSON.stringify(filterParam));

        if ((chkEndDate.getTime() - chkStartDate.getTime()) / (1000*60*60*24) > 125) {
          dispatch(setSendingAnalyticsError("Select up to 125 days per search"));
          return;
        }
        
        let fileName = dateFormat(new Date(), "yymmdd") + " WeCrest Madrid Notifier Sending Analytics (" + values["startDateMn"].replace(/-/gi,"") + "-" + values["endDateMn"].replace(/-/gi,"") + ")"; 
        
        let submitType = values["submitType"];
        
        if(submitType === "viewMn"){
  
          // console.log('filterParam >> ' + JSON.stringify(filterParam));
  
          dispatch(getSendingAnalyticsMnList(filterParam));
          
        }else if(submitType === "excelMn"){
          dispatch(downSendingAnalyticsMnExcel(filterParam, fileName));
  
        }else if(submitType === "saveSettingsMn"){
  
          dispatch(saveSendingAnalyticsMnSettings(filterParam));
        }
      } else {
        // console.log("searchNoList : " + JSON.stringify(values["searchNoList"]));

        const filterParam = {
          inOutType: values["inOutTypeFr"],
          statusList: values["statusListFr"],
          entityList: (values["searchNoList"] === '' ? [] : values["searchNoList"]),
          toEmail: values["toEmailFr"],
          startDate: values["startDateFr"],
          endDate: values["endDateFr"],
          sortType: values["sortTypeFr"],
          perPage: values["perPageFr"],
          curPage: values["curPageFr"],
          screenType : 'LOG',
        };

        if (!filterParam.startDate || filterParam.startDate === '' || !filterParam.endDate || filterParam.endDate === '') return;

        let chkStartDate = new Date(filterParam.startDate);
        let chkEndDate = new Date(filterParam.endDate);

        // console.log("filterParam : " + JSON.stringify(filterParam));

        if ((chkEndDate.getTime() - chkStartDate.getTime()) / (1000*60*60*24) > 125) {
          dispatch(setSendingAnalyticsError("Select up to 125 days per search"));
          return;
        }

        let fileName = dateFormat(new Date(), "yymmdd") + " WeCrest Filer Rankings Sending Analytics (" + values["startDateFr"].replace(/-/gi,"") + "-" + values["endDateFr"].replace(/-/gi,"") + ")"; 
        
        let submitType = values["submitType"];
        
        if(submitType === "viewFr"){
  
          // console.log('filterParam >> ' + JSON.stringify(filterParam));
  
          dispatch(getSendingAnalyticsFrList(filterParam));
          
        }else if(submitType === "excelFr"){
          dispatch(downSendingAnalyticsFrExcel(filterParam, fileName));
  
        }else if(submitType === "saveSettingsFr"){
  
          dispatch(saveSendingAnalyticsFrSettings(filterParam));
        }

      }
      
    },
  });

  const getStatisticsData = (statisticsObj) => {
    return (statisticsObj && statisticsObj.CNT ? statisticsObj.CNT.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : 0) + ' emails (' + (statisticsObj && statisticsObj.RATE ? statisticsObj.RATE : 0) + '%)';
  }

  const setSendingAnalyticsLoading = () => {
    dispatch(onSetSendingAnalyticsLoading(!loading))
  }

  const setSendingAnalyticsMn = (data) => {
    dispatch(onSetSendingAnalyticsMn(data))
  }

  useEffect(() => { 
    dispatch(getSendingAnalyticsMnSettings({screenType : 'LOG'}))
    dispatch(getSendingAnalyticsFrSettings({screenType : 'LOG'}))

    /*
    return () => {
      // dispatch(onClearMadridNotifier());
    };
    */
  }, []);

  return (
    <React.Fragment>
      <Form onSubmit={filterValidation.handleSubmit}>
        <div className="page-content">
          <div className="container-fluid">
            <Nav tabs className="col-md-6 nav-tabs-custom nav-justified">
            <NavItem>
              <NavLink
                style={{ cursor: "pointer" }}
                className={classnames({
                  active: activeTab === "Madrid",
                })}
                onClick={() => {
                  toggleActiveTab("Madrid")
                }}
              >
                <span>Madrid Notifier</span>
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                style={{ cursor: "pointer" }}
                className={classnames({
                  active: activeTab === "Filer",
                })}
                onClick={() => {
                  toggleActiveTab("Filer")
                }}
              >
                <span>Filer Rankings</span>
              </NavLink>
            </NavItem>
          </Nav>
          <TabContent
            activeTab={activeTab}
            className="text-muted"
          >
            <TabPane tabId="Madrid">
              <Row>
                <Col lg={12}>
                  <Card>
                    <div className="p-4">
                      <Media className="d-flex align-items-center">
                        <div className="flex-shrink-0 me-3">
                          <i className="uil uil-envelope-info text-primary h2"></i>
                        </div>
                        <div className="flex-grow-1 overflow-hidden">
                          <h5 className="font-size-16 mb-1">Sending Analytics</h5>
                          <p className="text-muted mb-0">Use this data to evaluate the performance of your emails sent through WeCrest and to optimize your future emails for a higher deliverability. 
                          In order to improve the your email deliverability, please visit <a href="https://www.wecrest.com/insights" target="_blank" rel="noreferrer">our blog</a> for more ideas.
                          *Please note that this data is available only after you have updated your sender email in the <a href="/emailingSettings">Settings</a> page.</p>
                        </div>
                        <i onClick={toggle} className="uil uil-filter font-size-24 text-muted" style={{ cursor: "pointer"}}></i>
                        {/* 
                        <i onClick={toggle} className={isOpen ? "mdi mdi-chevron-up accor-down-icon font-size-24" : "mdi mdi-chevron-down accor-down-icon font-size-24"} style={{ cursor: "pointer"}}></i>
                        */}
                      </Media>
                    </div>
                    <Collapse isOpen={isOpen}>
                      <Row>
                        <div className="p-4 border-top col-md-7" style={{borderRight : "1px solid #f5f6f8"}}>
                          <Row className="mb-3">
                            <div className="col-md-2">
                              <div className="form-check">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="inOutTypeMn"
                                id="showInboundMn"
                                onClick={()=>{
                                  filterValidation.setFieldValue("inOutType", "IN");
                                  filterValidation.setFieldValue("inOutTypeMn", "IN");
                                  filterValidation.setFieldValue("jrCdList", []);
                                  filterValidation.setFieldValue("holderCdList", []);
                                }}
                                value="IN"
                                checked={filterValidation.values.inOutTypeMn === 'IN'}
                              />
                              <label
                                className="form-check-label"
                                htmlFor="showInboundMn"
                              >
                                Inbound
                              </label>
                              </div>
                            </div>
                            <div className="col-md-3">
                              <div className="form-check">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="inOutTypeMn"
                                id="showOutboundMn"
                                onClick={()=>{
                                  filterValidation.setFieldValue("inOutType", "OUT");
                                  filterValidation.setFieldValue("inOutTypeMn", "OUT");
                                  filterValidation.setFieldValue("jrCdList", []);
                                  filterValidation.setFieldValue("holderCdList", []);
                                }}
                                value="OUT"
                                checked={filterValidation.values.inOutTypeMn === 'OUT'}
                              />
                              <label
                                className="form-check-label"
                                htmlFor="showOutboundMn"
                              >
                                Outbound
                              </label>
                              </div>
                            </div>
                          </Row>
                          
                          <Row className="mb-3 align-items-center">
                            <label htmlFor="statusListMn" className="col-md-2 col-form-label">
                              Status
                            </label>
                            <div className="col-md-3">
                              <div className="form-check">
                                <input type="checkbox" className="form-check-input"
                                  id="entityStatusSent"
                                  name="statusListMn"
                                  onChange={filterValidation.handleChange}
                                  value="sent"
                                  checked={filterValidation.values.statusListMn.includes("sent")}
                                />
                                <Label className="form-check-label" htmlFor="entityStatusSent">
                                Sent
                                </Label>
                              </div>
                            </div>
                            <div className="col-md-3">
                              <div className="form-check">
                                <input type="checkbox" className="form-check-input"
                                  id="entityStatusDelivered"
                                  name="statusListMn"
                                  onChange={filterValidation.handleChange}
                                  value="delivered"
                                  checked={filterValidation.values.statusListMn.includes("delivered")}
                                />
                                <Label className="form-check-label" htmlFor="entityStatusDelivered">
                                Delivered
                                </Label>
                              </div>
                            </div>
                            <div className="col-md-2">
                              <div className="form-check">
                                <input type="checkbox" className="form-check-input"
                                  id="entityStatusOpened"
                                  name="statusListMn"
                                  onChange={filterValidation.handleChange}
                                  value="open"
                                  checked={filterValidation.values.statusListMn.includes("open")}
                                />
                                <Label className="form-check-label" htmlFor="entityStatusOpened">
                                Opened
                                </Label>
                              </div>
                            </div>
                            <div className="col-md-2">
                              <div className="form-check">
                                <input type="checkbox" className="form-check-input"
                                  id="entityStatusClicked"
                                  name="statusListMn"
                                  onChange={filterValidation.handleChange}
                                  value="click"
                                  checked={filterValidation.values.statusListMn.includes("click")}
                                />
                                <Label className="form-check-label" htmlFor="entityStatusClicked">
                                Clicked
                                </Label>
                              </div>
                            </div>
                          </Row>
                          <Row className="mb-3 align-items-center">
                            <div class="col-md-2"><label class="col-form-label">&nbsp;</label></div>
                            
                            <div className="col-md-3">
                              <div className="form-check">
                                <input type="checkbox" className="form-check-input"
                                  id="entityStatusHardbounce"
                                  name="statusListMn"
                                  onChange={filterValidation.handleChange}
                                  value="hard_bounce"
                                  checked={filterValidation.values.statusListMn.includes("hard_bounce")}
                                />
                                <Label className="form-check-label" htmlFor="entityStatusHardbounce">
                                Hard bounce
                                </Label>
                              </div>
                            </div>
                            <div className="col-md-3">
                              <div className="form-check">
                                <input type="checkbox" className="form-check-input"
                                  id="entityStatusSoftbounce"
                                  name="statusListMn"
                                  onChange={filterValidation.handleChange}
                                  value="soft_bounce"
                                  checked={filterValidation.values.statusListMn.includes("soft_bounce")}
                                />
                                <Label className="form-check-label" htmlFor="entityStatusSoftbounce">
                                Soft bounce
                                </Label>
                              </div>
                            </div>
                            <div className="col-md-2">
                              <div className="form-check">
                                <input type="checkbox" className="form-check-input"
                                  id="entityStatusBlocked"
                                  name="statusListMn"
                                  onChange={filterValidation.handleChange}
                                  value="blocked"
                                  checked={filterValidation.values.statusListMn.includes("blocked")}
                                />
                                <Label className="form-check-label" htmlFor="entityStatusBlocked">
                                Blocked
                                </Label>
                              </div>
                            </div>
                            <div className="col-md-2">
                              <div className="form-check">
                                <input type="checkbox" className="form-check-input"
                                  id="entityStatusComplaint"
                                  name="statusListMn"
                                  onChange={filterValidation.handleChange}
                                  value="complaint"
                                  checked={filterValidation.values.statusListMn.includes("complaint")}
                                />
                                <Label className="form-check-label" htmlFor="entityStatusComplaint">
                                Complaint
                                </Label>
                              </div>
                            </div>
                          </Row>
                          <Row className="mb-3 align-items-center">
                            <div class="col-md-2"><label class="col-form-label">&nbsp;</label></div>
                            <div className="col-md-3">
                              <div className="form-check">
                                <input type="checkbox" className="form-check-input"
                                  id="entityStatusUnsubscribed"
                                  name="statusListMn"
                                  onChange={filterValidation.handleChange}
                                  value="unsubscribe"
                                  checked={filterValidation.values.statusListMn.includes("unsubscribe")}
                                />
                                <Label className="form-check-label" htmlFor="entityStatusUnsubscribed">
                                Unsubscribed
                                </Label>
                              </div>
                            </div>
                            <div className="col-md-3">
                              <div className="form-check">
                                <input type="checkbox" className="form-check-input"
                                  id="entityStatusInvalidEmail"
                                  name="statusListMn"
                                  onChange={filterValidation.handleChange}
                                  value="invalid_email"
                                  checked={filterValidation.values.statusListMn.includes("invalid_email")}
                                />
                                <Label className="form-check-label" htmlFor="entityStatusInvalidEmail">
                                Invalid email
                                </Label>
                              </div>
                            </div>
                            <div className="col-md-2">
                              <div className="form-check">
                                <input type="checkbox" className="form-check-input"
                                  id="entityStatusError"
                                  name="statusListMn"
                                  onChange={filterValidation.handleChange}
                                  value="error"
                                  checked={filterValidation.values.statusListMn.includes("error")}
                                />
                                <Label className="form-check-label" htmlFor="entityStatusError">
                                Error
                                </Label>
                              </div>
                            </div>
                          </Row>
                          
                          {filterValidation.values.inOutTypeMn === 'IN'?
                          
                          <SelectAllJurisdictions
                            formik={filterValidation}
                            firstColMd={2}
                          />
                          :
                          <SelectAllCountry
                            formik={filterValidation}
                            componentName='Holder'
                            firstColMd={2}
                          />
                          }

                          <Row className="mb-3">
                            <label
                            htmlFor="irNumber"
                            className="col-md-2 col-form-label"
                            >
                            IR number
                            </label>
                            <div className="col-md-3">
                            <Input
                                className="form-control"
                                type="text"
                                placeholder="Enter ir number"
                                id="irNumber"
                                name="irNumber"
                                onChange={filterValidation.handleChange}
                                value={filterValidation.values.irNumber}
                            />
                            </div>
                          </Row>
                          
                          <Row className="mb-3">
                            <label
                            htmlFor="toEmailMn"
                            className="col-md-2 col-form-label"
                            >
                            TO email
                            </label>
                            <div className="col-md-3">
                            <Input
                                className="form-control"
                                type="text"
                                placeholder="Enter to email"
                                id="toEmailMn"
                                name="toEmailMn"
                                onChange={filterValidation.handleChange}
                                value={filterValidation.values.toEmailMn}
                            />
                            </div>
                          </Row>
                          
                          <Row className="mb-3">
                            <label
                            htmlFor="startDateMn"
                            className="col-md-2 col-form-label"
                            >
                            From
                            </label>
                            <div className="col-md-3">
                            <Input
                                className="form-control"
                                type="date"
                                id="startDateMn"
                                name="startDateMn"
                                onChange={filterValidation.handleChange}
                                value={filterValidation.values.startDateMn}
                                invalid={
                                  filterValidation.touched.startDateMn &&
                                  filterValidation.errors.startDateMn
                                  ? true
                                  : false
                                  }
                                onPaste={(e) => {
                                  try {
                                      let checkDate = String(e.clipboardData.getData('Text'));
                                      if (checkDate.indexOf('-') > -1 && checkDate.length == 10) {
                                        filterValidation.setFieldValue("startDateMn", checkDate);
                                      } else if (checkDate.length == 8) {
                                        filterValidation.setFieldValue("startDateMn", checkDate.substring(0,4) + '-' + checkDate.substring(4,6) + '-' + checkDate.substring(6,8));
                                      }
                                  } catch (error) {
                                      console.log("error :: " + error);
                                  }
                                }}
                            />
                                {filterValidation.touched.startDateMn &&
                                filterValidation.errors.startDateMn ? (
                                  <FormFeedback type="invalid">
                                    {filterValidation.errors.startDateMn}
                                  </FormFeedback>
                                ) : null}
                            </div>
                            <label
                            htmlFor="endDateMn"
                            className="col-md-1 col-form-label"
                            >
                            To
                            </label>
                            <div className="col-md-3">
                            <Input
                                className="form-control"
                                type="date"
                                id="endDateMn"
                                name="endDateMn"
                                onChange={filterValidation.handleChange}
                                value={filterValidation.values.endDateMn}
                                invalid={
                                  filterValidation.touched.endDateMn &&
                                  filterValidation.errors.endDateMn
                                      ? true
                                      : false
                                  }
                                onPaste={(e) => {
                                  try {
                                      let checkDate = String(e.clipboardData.getData('Text'));
                                      if (checkDate.indexOf('-') > -1 && checkDate.length == 10) {
                                        filterValidation.setFieldValue("endDateMn", checkDate);
                                      } else if (checkDate.length == 8) {
                                        filterValidation.setFieldValue("endDateMn", checkDate.substring(0,4) + '-' + checkDate.substring(4,6) + '-' + checkDate.substring(6,8));
                                      }
                                  } catch (error) {
                                      console.log("error :: " + error);
                                  }
                                }}
                            />
                                {filterValidation.touched.endDateMn &&
                                filterValidation.errors.endDateMn ? (
                                  <FormFeedback type="invalid">
                                    {filterValidation.errors.endDateMn}
                                  </FormFeedback>
                                ) : null}
                            </div>
                          </Row>
                        </div>
                        <div className="p-4 border-top col-md-5" style={{color: "#495057"}}>
                          <Row >
                            <label className="col-md-3 col-form-label">
                            All
                            </label>
                            <div className="col-md-9 col-form-label">
                            {statisticsMapMn.all && statisticsMapMn.all.CNT ? statisticsMapMn.all.CNT.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : 0} emails
                            </div>
                          </Row>
                          <Row >
                            <label className="col-md-3 col-form-label">
                            Sent
                            </label>
                            <div className="col-md-9 col-form-label">
                            {getStatisticsData(statisticsMapMn.sent)}
                            </div>
                          </Row>
                          <Row >
                            <label className="col-md-3 col-form-label">
                            Delivered
                            </label>
                            <div className="col-md-9 col-form-label">
                            {getStatisticsData(statisticsMapMn.delivered)}
                            </div>
                          </Row>
                          <Row >
                            <label className="col-md-3 col-form-label">
                            Opened
                            </label>
                            <div className="col-md-9 col-form-label">
                            {getStatisticsData(statisticsMapMn.open)}
                            </div>
                          </Row>
                          <Row >
                            <label className="col-md-3 col-form-label">
                            Clicked
                            </label>
                            <div className="col-md-9 col-form-label">
                            {getStatisticsData(statisticsMapMn.click)}
                            </div>
                          </Row>
                          <Row >
                            <label className="col-md-3 col-form-label">
                            Soft bounced
                            </label>
                            <div className="col-md-9 col-form-label">
                            {getStatisticsData(statisticsMapMn.soft_bounce)}
                            </div>
                          </Row>
                          <Row >
                            <label className="col-md-3 col-form-label">
                            Hard bounced
                            </label>
                            <div className="col-md-9 col-form-label">
                            {getStatisticsData(statisticsMapMn.hard_bounce)}
                            </div>
                          </Row>
                          <Row >
                            <label className="col-md-3 col-form-label">
                            Blocked
                            </label>
                            <div className="col-md-9 col-form-label">
                            {getStatisticsData(statisticsMapMn.blocked)}
                            </div>
                          </Row>
                          <Row >
                            <label className="col-md-3 col-form-label">
                            Complaint
                            </label>
                            <div className="col-md-9 col-form-label">
                            {getStatisticsData(statisticsMapMn.complaint)}
                            </div>
                          </Row>
                          <Row >
                            <label className="col-md-3 col-form-label">
                            Unsubscribed
                            </label>
                            <div className="col-md-9 col-form-label">
                            {getStatisticsData(statisticsMapMn.unsubscribe)}
                            </div>
                          </Row>
                          <Row >
                            <label className="col-md-3 col-form-label">
                            Invalid email
                            </label>
                            <div className="col-md-9 col-form-label">
                            {getStatisticsData(statisticsMapMn.invalid_email)}
                            </div>
                          </Row>
                          <Row >
                            <label className="col-md-3 col-form-label">
                            Error
                            </label>
                            <div className="col-md-9 col-form-label">
                            {getStatisticsData(statisticsMapMn.error)}
                            </div>
                          </Row>
                          <br/>
                          <Row >
                            <div className="col-md-12 text-muted" style={{ cursor: "pointer"}} onClick={()=>toggleStatus()}>
                            <i className="uil uil-question-circle font-size-18"></i>Explain these metrics
                            </div>
                          </Row>
                          <Row className="mb-6 text-danger">
                          <div className="col-md-12 col-form-label">
                          To track this data, you can use our new mailing system. Please complete the setup process by entering your email <a href='/emailingSettings' className='a-href-underline'>settings</a> here. Once you have completed this step, you will be able to track this data.
                          </div>
                          </Row>
                        </div>
                      </Row>
                      <Row>
                        <div className="p-4 border-top col-md-12" style={{borderRight : "1px solid #f5f6f8!important"}}>
                          <Row className="mb-3">
                            <span className="col-md-6 button-items">
                              <Button
                                type="button"
                                color="primary"
                                outline
                                className="waves-effect waves-light"
                                onClick={()=>{
                                  filterValidation.setFieldValue("submitType", "saveSettingsMn");
                                  filterValidation.handleSubmit(filterValidation.values);
                                }}
                              >
                                Save settings
                              </Button>
                              <Button
                                type="button"
                                color="primary"
                                outline
                                className="waves-effect waves-light"
                                onClick={()=>{
                                  filterValidation.setFieldValue("submitType", "excelMn");
                                  filterValidation.handleSubmit(filterValidation.values);
                                }}
                                id="btn_down_excel"
                              >
                                Download Excel
                              </Button>
                              <UncontrolledTooltip placement="top" target="btn_down_excel">
                              Download Excel limit 10,000 row
                              </UncontrolledTooltip>
                            </span>
                            <Col md={3} style={{paddingLeft : '1.3em'}}>
                              <button
                                type="button"
                                className="waves-effect waves-light btn btn-primary"
                                style={{width : '148.58px'}}
                                onClick={()=>{
                                  // let _values = cloneDeep(madridValidation.values);
                                  // _values.submitType = "view";
                                  // madridValidation.setValues(_values);
                                  setCurPageInputMn(1);
                                  filterValidation.setFieldValue("curPageMn", 1);
                                  filterValidation.setFieldValue("submitType", "viewMn");
                                  filterValidation.handleSubmit(filterValidation.values);
                                }}
                              >
                                Search
                              </button>
                            </Col>
                          </Row>
                        </div>
                      </Row>
                    </Collapse>
                  </Card>
                </Col>
              </Row>
              {sendingAnalyticsMnList && sendingAnalyticsMnList.length ?
              <Row>
                <Col lg={12}>
                  <Card>
                    <CardBody>
                      <div className="table-responsive">
                        <Table className="table mb-0">
                          <thead>
                            <tr>
                            {
                              mnColumns && mnColumns.length ?
                              mnColumns.map((col, index) => {
                                  return (
                                    <th style={{position: "relative", whiteSpace: "pre-line", textAlign: "center"}}>
                                      {col.label}
                                      {col.asc && col.desc? <>
                                      <Link to='#' onClick={()=> {viewSendingAnalyticsMnList(col.asc)}} className={col.ascActive}><i className="btn-asc-30 uil uil-angle-up font-size-18"></i></Link>
                                      <Link to='#' onClick={()=> {viewSendingAnalyticsMnList(col.desc)}} className={col.descActive}><i className="btn-desc-30 uil uil-angle-down font-size-18"></i></Link>
                                      </>
                                    :null}
                                    </th>
                                  )
                              })
                              :null
                            }
                            </tr>
                          </thead>
                          <tbody>
                          {
                            sendingAnalyticsMnList.map((madrid, index) => {
                              return (
                                  <tr key={index} onClick={() => {dispatch(getSendingAnalyticsMn(madrid))}} style={{ cursor: "pointer"}}>
                                    <td align="center">{madrid.sendinblueStatusDesc}</td>
                                    <td align="center">{madrid.sentDate}</td>
                                    <td align="center">{madrid.toEmail}</td>
                                    <td align="center">{'[' + madrid.crCode + '] ' + madrid.crName}</td>
                                    <td align="center">{madrid.irNumber}</td>
                                    <td align="center">{madrid.sendCount - 1}</td>
                                    <td align="center">{madrid.inOutTypeStr}</td>
                                  </tr>
                                )
                              })
                            }
                          </tbody>
                        </Table>
                      </div>
                      <div className="d-flex justify-content-between">
                        <span className="d-flex align-items-center gap-2">
                          <label
                            htmlFor="perPage"
                            >
                            Entries per page
                          </label>
                          <div style={{width: "100px"}}>
                            <select 
                              className="form-select" 
                              name="perPage"
                              onChange={e => {viewSendingAnalyticsMnList(null, 1, e.target.value);}}
                              value={filterValidation.values.perPageMn}
                            >
                              <option value="10">10</option>
                              <option value="20">20</option>
                              <option value="30">30</option>
                              <option value="40">40</option>
                              <option value="50">50</option>
                            </select>
                          </div>
                        </span>
                        <span className="d-flex align-items-center gap-2">
                          <div className="d-flex gap-1">
                            <Button
                              type="button"
                              color="primary"
                              onClick={()=>{viewSendingAnalyticsMnList(null, 1);}}
                              disabled={(filterValidation.values.curPageMn === 1)}
                            >
                              {"<<"}
                            </Button>
                            <Button
                              type="button"
                              color="primary"
                              onClick={()=>{viewSendingAnalyticsMnList(null, filterValidation.values.curPageMn-1);}}
                              disabled={(filterValidation.values.curPageMn === 1)}
                            >
                              {"<"}
                            </Button>
                          </div>
                          Page{" "}
                          <strong>
                            {filterValidation.values.curPageMn} of {totalPageMn}
                          </strong>
                          
                          <Input
                            type="number"
                            min={1}
                            style={{ width: 70 }}
                            max={totalPageMn}
                            name="curPage"
                            defaultValue={filterValidation.values.curPageMn}
                            value={curPageInputMn}
                            onChange={(e) => { setCurPageInputMn(e.target.value)}}
                            onKeyPress={(e) => {
                              if(e.key === 'Enter'){
                                viewSendingAnalyticsMnList(null, e.target.value);
                              }                      
                            }}
                          />
                          <div className="d-flex gap-1">
                            <Button 
                              type="button"
                              color="primary"
                              onClick={()=>{viewSendingAnalyticsMnList(null, filterValidation.values.curPageMn+1);}}
                              disabled={(filterValidation.values.curPageMn === totalPageMn)}
                            >
                              {">"}
                            </Button>
                            <Button
                              type="button"
                              color="primary"
                              onClick={()=>{viewSendingAnalyticsMnList(null, totalPageMn);}}
                              disabled={(filterValidation.values.curPageMn === totalPageMn)}
                            >
                              {">>"}
                            </Button>
                          </div>
                        </span>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
              :null}
            </TabPane>
            <TabPane tabId="Filer">
              <Row>
                <Col lg={12}>
                  <Card>
                    <div className="p-4">
                      <Media className="d-flex align-items-center">
                        <div className="flex-shrink-0 me-3">
                          <i className="uil uil-envelope-info text-primary h2"></i>
                        </div>
                        <div className="flex-grow-1 overflow-hidden">
                          <h5 className="font-size-16 mb-1">Sending Analytics</h5>
                          <p className="text-muted mb-0">Use this data to evaluate the performance of your emails sent through WeCrest and to optimize your future emails for a higher deliverability. 
                          In order to improve the your email deliverability, please visit <a href="https://www.wecrest.com/insights" target="_blank" rel="noreferrer">our blog</a> for more ideas.
                          *Please note that this data is available only after you have updated your sender email in the <a href="/emailingSettings">Settings</a> page.</p>
                        </div>
                        <i onClick={toggle} className="uil uil-filter font-size-24 text-muted" style={{ cursor: "pointer"}}></i>
                        {/* 
                        <i onClick={toggle} className={isOpen ? "mdi mdi-chevron-up accor-down-icon font-size-24" : "mdi mdi-chevron-down accor-down-icon font-size-24"} style={{ cursor: "pointer"}}></i>
                        */}
                      </Media>
                    </div>
                    <Collapse isOpen={isOpen}>
                      <Row>
                        <div className="p-4 border-top col-md-7" style={{borderRight : "1px solid #f5f6f8"}}>
                          <Row className="mb-3">
                            <div className="col-md-2">
                              <div className="form-check">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="inOutTypeFr"
                                id="showInboundFr"
                                onClick={()=>{
                                  filterValidation.setFieldValue("inOutTypeFr", "IN");
                                }}
                                value="IN"
                                checked={filterValidation.values.inOutTypeFr === 'IN'}
                              />
                              <label
                                className="form-check-label"
                                htmlFor="showInboundFr"
                              >
                                Inbound
                              </label>
                              </div>
                            </div>
                            <div className="col-md-3">
                              <div className="form-check">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="inOutTypeFr"
                                id="showOutboundFr"
                                onClick={()=>{
                                  filterValidation.setFieldValue("inOutTypeFr", "OUT");
                                }}
                                value="OUT"
                                checked={filterValidation.values.inOutTypeFr === 'OUT'}
                              />
                              <label
                                className="form-check-label"
                                htmlFor="showOutboundFr"
                              >
                                Outbound
                              </label>
                              </div>
                            </div>
                          </Row>
                          
                          <Row className="mb-3 align-items-center">
                            <label htmlFor="statusListMn" className="col-md-2 col-form-label">
                              Status
                            </label>
                            <div className="col-md-3">
                              <div className="form-check">
                                <input type="checkbox" className="form-check-input"
                                  id="statusSentFr"
                                  name="statusListFr"
                                  onChange={filterValidation.handleChange}
                                  value="sent"
                                  checked={filterValidation.values.statusListFr.includes("sent")}
                                />
                                <Label className="form-check-label" htmlFor="statusSentFr">
                                Sent
                                </Label>
                              </div>
                            </div>
                            <div className="col-md-3">
                              <div className="form-check">
                                <input type="checkbox" className="form-check-input"
                                  id="statusDeliveredFr"
                                  name="statusListFr"
                                  onChange={filterValidation.handleChange}
                                  value="delivered"
                                  checked={filterValidation.values.statusListFr.includes("delivered")}
                                />
                                <Label className="form-check-label" htmlFor="statusDeliveredFr">
                                Delivered
                                </Label>
                              </div>
                            </div>
                            <div className="col-md-2">
                              <div className="form-check">
                                <input type="checkbox" className="form-check-input"
                                  id="statusOpenedFr"
                                  name="statusListFr"
                                  onChange={filterValidation.handleChange}
                                  value="open"
                                  checked={filterValidation.values.statusListFr.includes("open")}
                                />
                                <Label className="form-check-label" htmlFor="statusOpenedFr">
                                Opened
                                </Label>
                              </div>
                            </div>
                            <div className="col-md-2">
                              <div className="form-check">
                                <input type="checkbox" className="form-check-input"
                                  id="statusClickedFr"
                                  name="statusListFr"
                                  onChange={filterValidation.handleChange}
                                  value="click"
                                  checked={filterValidation.values.statusListFr.includes("click")}
                                />
                                <Label className="form-check-label" htmlFor="statusClickedFr">
                                Clicked
                                </Label>
                              </div>
                            </div>
                          </Row>
                          <Row className="mb-3 align-items-center">
                            <div class="col-md-2"><label class="col-form-label">&nbsp;</label></div>
                            
                            <div className="col-md-3">
                              <div className="form-check">
                                <input type="checkbox" className="form-check-input"
                                  id="statusHardbounceFr"
                                  name="statusListFr"
                                  onChange={filterValidation.handleChange}
                                  value="hard_bounce"
                                  checked={filterValidation.values.statusListFr.includes("hard_bounce")}
                                />
                                <Label className="form-check-label" htmlFor="statusHardbounceFr">
                                Hard bounce
                                </Label>
                              </div>
                            </div>
                            <div className="col-md-3">
                              <div className="form-check">
                                <input type="checkbox" className="form-check-input"
                                  id="statusSoftbounceFr"
                                  name="statusListFr"
                                  onChange={filterValidation.handleChange}
                                  value="soft_bounce"
                                  checked={filterValidation.values.statusListFr.includes("soft_bounce")}
                                />
                                <Label className="form-check-label" htmlFor="statusSoftbounceFr">
                                Soft bounce
                                </Label>
                              </div>
                            </div>
                            <div className="col-md-2">
                              <div className="form-check">
                                <input type="checkbox" className="form-check-input"
                                  id="statusBlockedFr"
                                  name="statusListFr"
                                  onChange={filterValidation.handleChange}
                                  value="blocked"
                                  checked={filterValidation.values.statusListFr.includes("blocked")}
                                />
                                <Label className="form-check-label" htmlFor="statusBlockedFr">
                                Blocked
                                </Label>
                              </div>
                            </div>
                            <div className="col-md-2">
                              <div className="form-check">
                                <input type="checkbox" className="form-check-input"
                                  id="statusComplaintFr"
                                  name="statusListFr"
                                  onChange={filterValidation.handleChange}
                                  value="complaint"
                                  checked={filterValidation.values.statusListFr.includes("complaint")}
                                />
                                <Label className="form-check-label" htmlFor="statusComplaintFr">
                                Complaint
                                </Label>
                              </div>
                            </div>
                          </Row>
                          <Row className="mb-3 align-items-center">
                            <div class="col-md-2"><label class="col-form-label">&nbsp;</label></div>
                            <div className="col-md-3">
                              <div className="form-check">
                                <input type="checkbox" className="form-check-input"
                                  id="statusUnsubscribedFr"
                                  name="statusListFr"
                                  onChange={filterValidation.handleChange}
                                  value="unsubscribe"
                                  checked={filterValidation.values.statusListFr.includes("unsubscribe")}
                                />
                                <Label className="form-check-label" htmlFor="statusUnsubscribedFr">
                                Unsubscribed
                                </Label>
                              </div>
                            </div>
                            <div className="col-md-3">
                              <div className="form-check">
                                <input type="checkbox" className="form-check-input"
                                  id="statusInvalidEmailFr"
                                  name="statusListFr"
                                  onChange={filterValidation.handleChange}
                                  value="invalid_email"
                                  checked={filterValidation.values.statusListFr.includes("invalid_email")}
                                />
                                <Label className="form-check-label" htmlFor="statusInvalidEmailFr">
                                Invalid email
                                </Label>
                              </div>
                            </div>
                            <div className="col-md-2">
                              <div className="form-check">
                                <input type="checkbox" className="form-check-input"
                                  id="statusErrorFr"
                                  name="statusListFr"
                                  onChange={filterValidation.handleChange}
                                  value="error"
                                  checked={filterValidation.values.statusListFr.includes("error")}
                                />
                                <Label className="form-check-label" htmlFor="statusErrorFr">
                                Error
                                </Label>
                              </div>
                            </div>
                          </Row>

                          <SearchName 
                            formik={filterValidation} 
                            firstColMd={2}
                            labelName='Entity'
                          />
                          
                          <Row className="mb-3">
                            <label
                            htmlFor="toEmailFr"
                            className="col-md-2 col-form-label"
                            >
                            TO email
                            </label>
                            <div className="col-md-3">
                            <Input
                                className="form-control"
                                type="text"
                                placeholder="Enter to email"
                                id="toEmailFr"
                                name="toEmailFr"
                                onChange={filterValidation.handleChange}
                                value={filterValidation.values.toEmailFr}
                            />
                            </div>
                          </Row>
                          
                          <Row className="mb-3">
                            <label
                            htmlFor="startDateFr"
                            className="col-md-2 col-form-label"
                            >
                            From
                            </label>
                            <div className="col-md-3">
                            <Input
                                className="form-control"
                                type="date"
                                id="startDateFr"
                                name="startDateFr"
                                onChange={filterValidation.handleChange}
                                value={filterValidation.values.startDateFr}
                                invalid={
                                  filterValidation.touched.startDateFr &&
                                  filterValidation.errors.startDateFr
                                  ? true
                                  : false
                                  }
                                onPaste={(e) => {
                                  try {
                                      let checkDate = String(e.clipboardData.getData('Text'));
                                      if (checkDate.indexOf('-') > -1 && checkDate.length == 10) {
                                        filterValidation.setFieldValue("startDateFr", checkDate);
                                      } else if (checkDate.length == 8) {
                                        filterValidation.setFieldValue("startDateFr", checkDate.substring(0,4) + '-' + checkDate.substring(4,6) + '-' + checkDate.substring(6,8));
                                      }
                                  } catch (error) {
                                      console.log("error :: " + error);
                                  }
                                }}
                            />
                                {filterValidation.touched.startDateFr &&
                                filterValidation.errors.startDateFr ? (
                                  <FormFeedback type="invalid">
                                    {filterValidation.errors.startDateFr}
                                  </FormFeedback>
                                ) : null}
                            </div>
                            <label
                            htmlFor="endDateFr"
                            className="col-md-1 col-form-label"
                            >
                            To
                            </label>
                            <div className="col-md-3">
                            <Input
                                className="form-control"
                                type="date"
                                id="endDateFr"
                                name="endDateFr"
                                onChange={filterValidation.handleChange}
                                value={filterValidation.values.endDateFr}
                                invalid={
                                  filterValidation.touched.endDateFr &&
                                  filterValidation.errors.endDateFr
                                      ? true
                                      : false
                                  }
                                onPaste={(e) => {
                                  try {
                                      let checkDate = String(e.clipboardData.getData('Text'));
                                      if (checkDate.indexOf('-') > -1 && checkDate.length == 10) {
                                        filterValidation.setFieldValue("endDateFr", checkDate);
                                      } else if (checkDate.length == 8) {
                                        filterValidation.setFieldValue("endDateFr", checkDate.substring(0,4) + '-' + checkDate.substring(4,6) + '-' + checkDate.substring(6,8));
                                      }
                                  } catch (error) {
                                      console.log("error :: " + error);
                                  }
                                }}
                            />
                                {filterValidation.touched.endDateFr &&
                                filterValidation.errors.endDateFr ? (
                                  <FormFeedback type="invalid">
                                    {filterValidation.errors.endDateFr}
                                  </FormFeedback>
                                ) : null}
                            </div>
                          </Row>
                        </div>
                        <div className="p-4 border-top col-md-5" style={{color: "#495057"}}>
                          <Row >
                            <label className="col-md-3 col-form-label">
                            All
                            </label>
                            <div className="col-md-9 col-form-label">
                            {statisticsMapFr.all && statisticsMapFr.all.CNT ? statisticsMapFr.all.CNT.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : 0} emails
                            </div>
                          </Row>
                          <Row >
                            <label className="col-md-3 col-form-label">
                            Sent
                            </label>
                            <div className="col-md-9 col-form-label">
                            {getStatisticsData(statisticsMapFr.sent)}
                            </div>
                          </Row>
                          <Row >
                            <label className="col-md-3 col-form-label">
                            Delivered
                            </label>
                            <div className="col-md-9 col-form-label">
                            {getStatisticsData(statisticsMapFr.delivered)}
                            </div>
                          </Row>
                          <Row >
                            <label className="col-md-3 col-form-label">
                            Opened
                            </label>
                            <div className="col-md-9 col-form-label">
                            {getStatisticsData(statisticsMapFr.open)}
                            </div>
                          </Row>
                          <Row >
                            <label className="col-md-3 col-form-label">
                            Clicked
                            </label>
                            <div className="col-md-9 col-form-label">
                            {getStatisticsData(statisticsMapFr.click)}
                            </div>
                          </Row>
                          <Row >
                            <label className="col-md-3 col-form-label">
                            Soft bounced
                            </label>
                            <div className="col-md-9 col-form-label">
                            {getStatisticsData(statisticsMapFr.soft_bounce)}
                            </div>
                          </Row>
                          <Row >
                            <label className="col-md-3 col-form-label">
                            Hard bounced
                            </label>
                            <div className="col-md-9 col-form-label">
                            {getStatisticsData(statisticsMapFr.hard_bounce)}
                            </div>
                          </Row>
                          <Row >
                            <label className="col-md-3 col-form-label">
                            Blocked
                            </label>
                            <div className="col-md-9 col-form-label">
                            {getStatisticsData(statisticsMapFr.blocked)}
                            </div>
                          </Row>
                          <Row >
                            <label className="col-md-3 col-form-label">
                            Complaint
                            </label>
                            <div className="col-md-9 col-form-label">
                            {getStatisticsData(statisticsMapFr.complaint)}
                            </div>
                          </Row>
                          <Row >
                            <label className="col-md-3 col-form-label">
                            Unsubscribed
                            </label>
                            <div className="col-md-9 col-form-label">
                            {getStatisticsData(statisticsMapFr.unsubscribe)}
                            </div>
                          </Row>
                          <Row >
                            <label className="col-md-3 col-form-label">
                            Invalid email
                            </label>
                            <div className="col-md-9 col-form-label">
                            {getStatisticsData(statisticsMapFr.invalid_email)}
                            </div>
                          </Row>
                          <Row >
                            <label className="col-md-3 col-form-label">
                            Error
                            </label>
                            <div className="col-md-9 col-form-label">
                            {getStatisticsData(statisticsMapFr.error)}
                            </div>
                          </Row>
                          <br/>
                          <Row >
                            <div className="col-md-12 text-muted" style={{ cursor: "pointer"}} onClick={()=>toggleStatus()}>
                            <i className="uil uil-question-circle font-size-18"></i>Explain these metrics
                            </div>
                          </Row>
                          <Row className="mb-6 text-danger">
                          <div className="col-md-12 col-form-label">
                          To track this data, you can use our new mailing system. Please complete the setup process by entering your email <a href='/emailingSettings' className='a-href-underline'>settings</a> here. Once you have completed this step, you will be able to track this data.
                          </div>
                          </Row>
                        </div>
                      </Row>
                      <Row>
                        <div className="p-4 border-top col-md-12" style={{borderRight : "1px solid #f5f6f8!important"}}>
                          <Row className="mb-3">
                            <span className="col-md-6 button-items">
                              <Button
                                type="button"
                                color="primary"
                                outline
                                className="waves-effect waves-light"
                                onClick={()=>{
                                  filterValidation.setFieldValue("submitType", "saveSettingsFr");
                                  filterValidation.handleSubmit(filterValidation.values);
                                }}
                              >
                                Save settings
                              </Button>
                              <Button
                                type="button"
                                color="primary"
                                outline
                                className="waves-effect waves-light"
                                onClick={()=>{
                                  filterValidation.setFieldValue("submitType", "excelFr");
                                  filterValidation.handleSubmit(filterValidation.values);
                                }}
                              >
                                Download Excel
                              </Button>
                            </span>
                            <Col md={3} style={{paddingLeft : '1.3em'}}>
                              <button
                                type="button"
                                className="waves-effect waves-light btn btn-primary"
                                style={{width : '148.58px'}}
                                onClick={()=>{
                                  // let _values = cloneDeep(madridValidation.values);
                                  // _values.submitType = "view";
                                  // madridValidation.setValues(_values);
                                  setCurPageInputFr(1);
                                  filterValidation.setFieldValue("curPageFr", 1);
                                  filterValidation.setFieldValue("submitType", "viewFr");
                                  filterValidation.handleSubmit(filterValidation.values);
                                }}
                              >
                                Search
                              </button>
                            </Col>
                          </Row>
                        </div>
                      </Row>
                    </Collapse>
                  </Card>
                </Col>
              </Row>
              {sendingAnalyticsFrList && sendingAnalyticsFrList.length ?
              <Row>
                <Col lg={12}>
                  <Card>
                    <CardBody>
                      <div className="table-responsive">
                        <Table className="table mb-0">
                          <thead>
                            <tr>
                            {
                              frColumns && frColumns.length ?
                              frColumns.map((col, index) => {
                                  return (
                                    <th style={{position: "relative", whiteSpace: "pre-line", textAlign: "center"}}>
                                      {col.label}
                                      {col.asc && col.desc? <>
                                      <Link to='#' onClick={()=> {viewSendingAnalyticsFrList(col.asc)}} className={col.ascActive}><i className="btn-asc-30 uil uil-angle-up font-size-18"></i></Link>
                                      <Link to='#' onClick={()=> {viewSendingAnalyticsFrList(col.desc)}} className={col.descActive}><i className="btn-desc-30 uil uil-angle-down font-size-18"></i></Link>
                                      </>
                                    :null}
                                    </th>
                                  )
                              })
                              :null
                            }
                            </tr>
                          </thead>
                          <tbody>
                          {
                            sendingAnalyticsFrList.map((filer, index) => {
                              return (
                                  <tr key={index} onClick={() => {setPreview(filer)}} style={{ cursor: "pointer"}}>
                                    <td align="center">{filer.sendinblueStatusDesc}</td>
                                    <td align="center">{filer.sentDate}</td>
                                    <td align="center">{filer.toEmail}</td>
                                    <td align="center">{filer.targetGroupName}</td>
                                    <td align="center">{filer.mailSeq - 1}</td>
                                    <td align="center">{filer.inOutTypeStr}</td>
                                  </tr>
                                )
                              })
                            }
                          </tbody>
                        </Table>
                      </div>
                      <div className="d-flex justify-content-between">
                        <span className="d-flex align-items-center gap-2">
                          <label
                            htmlFor="perPage"
                            >
                            Entries per page
                          </label>
                          <div style={{width: "100px"}}>
                            <select 
                              className="form-select" 
                              name="perPage"
                              onChange={e => {viewSendingAnalyticsFrList(null, 1, e.target.value);}}
                              value={filterValidation.values.perPageFr}
                            >
                              <option value="10">10</option>
                              <option value="20">20</option>
                              <option value="30">30</option>
                              <option value="40">40</option>
                              <option value="50">50</option>
                            </select>
                          </div>
                        </span>
                        <span className="d-flex align-items-center gap-2">
                          <div className="d-flex gap-1">
                            <Button
                              type="button"
                              color="primary"
                              onClick={()=>{viewSendingAnalyticsFrList(null, 1);}}
                              disabled={(filterValidation.values.curPageFr === 1)}
                            >
                              {"<<"}
                            </Button>
                            <Button
                              type="button"
                              color="primary"
                              onClick={()=>{viewSendingAnalyticsFrList(null, filterValidation.values.curPageFr-1);}}
                              disabled={(filterValidation.values.curPageFr === 1)}
                            >
                              {"<"}
                            </Button>
                          </div>
                          Page{" "}
                          <strong>
                            {filterValidation.values.curPageFr} of {totalPageFr}
                          </strong>
                          
                          <Input
                            type="number"
                            min={1}
                            style={{ width: 70 }}
                            max={totalPageFr}
                            name="curPage"
                            defaultValue={filterValidation.values.curPageFr}
                            value={curPageInputFr}
                            onChange={(e) => { setCurPageInputFr(e.target.value)}}
                            onKeyPress={(e) => {
                              if(e.key === 'Enter'){
                                viewSendingAnalyticsFrList(null, e.target.value);
                              }                      
                            }}
                          />
                          <div className="d-flex gap-1">
                            <Button 
                              type="button"
                              color="primary"
                              onClick={()=>{viewSendingAnalyticsFrList(null, filterValidation.values.curPageFr+1);}}
                              disabled={(filterValidation.values.curPageFr === totalPageFr)}
                            >
                              {">"}
                            </Button>
                            <Button
                              type="button"
                              color="primary"
                              onClick={()=>{viewSendingAnalyticsFrList(null, totalPageFr);}}
                              disabled={(filterValidation.values.curPageFr === totalPageFr)}
                            >
                              {">>"}
                            </Button>
                          </div>
                        </span>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
              :null}

            </TabPane>
          </TabContent>

            {
              loading ?
              <LoadingModal loading={loading} setLoading={setSendingAnalyticsLoading}></LoadingModal>
              : null
            }

            {
              success ? 
              <SweetAlert
                title={success}
                timeout={2000}
                showCloseButton={false}
                showConfirm={false}
                success
                onConfirm={() => {
                  dispatch(setSendingAnalyticsSuccess(""));
                }}
              ></SweetAlert>
              : null
            }

            {
              error ?
              <SweetAlert
                warning
                title={error}
                onConfirm={() => {
                  dispatch(setSendingAnalyticsError(""));
                }}
              >
              </SweetAlert>
              : null
            }

            <SendEmailHistory preview={preview} setPreview={setPreview}/>

            <SendEmailHistory preview={sendingAnalyticsMn} setPreview={setSendingAnalyticsMn}/>

            <SendingAnalyticsStatus openInfomation={openInfomation} toggleStatus={toggleStatus}/>
           
          </div>
        </div>
      </Form>
    </React.Fragment>
  )
}

export default SendingAnalytics;