import React, {useEffect} from 'react';
import { Container, Row, Col } from "reactstrap"
import { useSelector, useDispatch } from "react-redux";

import {
  getWeCrestInfo
} from "../../store/actions";

const Footer = () => {
  const dispatch = useDispatch();

  const { wecrestInfo } = useSelector(state => ({
    wecrestInfo: state.Dashboard.wecrestInfo,
  }));

  useEffect(() => {
    if (!wecrestInfo.addressLine1 || wecrestInfo.addressLine1 == '') {
      dispatch(getWeCrestInfo());
    }
}, []);

  return (
    <React.Fragment>
      <footer className="footer" style={{paddingTop: "7px"}}>
        <Container fluid={true}>
          <Row>
            <Col sm={12}>
              <div className="text-sm-center d-none d-sm-block">
                © WeCrest Ltd 2017-{new Date().getFullYear()} all rights reserved | {wecrestInfo.addressLine1}, {wecrestInfo.addressLine2}, {wecrestInfo.addressLine3} <br/>
                <a href="mailto:global@wecrest.com">global@wecrest.com</a> | {wecrestInfo.officeNumber} | Representative Director: Benjamin Morris | Registration Number: 462-86-00861 | <a href="https://www.wecrest.com/terms-conditions" target="_blank" rel="noreferrer">Terms & Conditions</a> | <a href="https://www.wecrest.com/privacy-policy" target="_blank" rel="noreferrer">Privacy Policy</a>
              </div>
            </Col>
          </Row>
        </Container>
      </footer>
    </React.Fragment>
    /*© Minible. Design & Develop <i className="mdi mdi-heart text-danger"></i> by Themesbrand*/
  )
}

export default Footer
